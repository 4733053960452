import React from 'react';

export default function Emoji({ onClick, label, value, style, size = null }) {
  const customStyle = {
    ...style
  };
  if (onClick) customStyle.cursor = 'pointer';
  if (size) customStyle.fontSize = `${size}px`;

  return (
    <span onClick={onClick} style={customStyle} role="img" aria-label={label}>
      {value}
    </span>
  );
}
