export const squirrelFormula = {
  carb: 0.40518754837369975,
  fat: 0.5336492175259094,
  protein: 0.06116323410039076
};

export function gramsToCalories({ fat, carb, protein }) {
  return {
    fat: parseFloat(fat) * 9.0,
    carb: parseFloat(carb) * 4.0,
    protein: parseFloat(protein) * 4.0
  };
}

export function getScoreFromGrams(macros) {
  return getScoreFromPercentages(getData(gramsToCalories(macros)).decimals);
}

/*function getDistance(actual, reference) {
    let diff =  Math.abs(reference - actual)
    
    if (actual > reference) return (diff / (1.0 - reference)) / 2.0
    return diff / reference / 2.0
}*/

export function getScoreFromPercentages(macros, formula = squirrelFormula) {
  //need to just use carb+fat ratios and minus protein
  //const fatDistance = getDistance(fat, squirrelFormula.fat)
  //const carbDistance = getDistance(carb, squirrelFormula.carb)
  //const defowDistance = fatDistance + carbDistance
  //return !fat && !carb && !protein ? 0 : Math.max(0.00, Math.min(1.0, 1.0 - (defowDistance / 2) - (protein / 2)))

  const { fat, carb, protein } = macros;
  if (!fat && !carb && !protein) return 0;

  //so close
  // get the maximum distance
  /*const maxDist = Math.max(1-formula.fat, formula.fat) + 
        Math.max(1-formula.carb, formula.carb) + 
        Math.max(1-formula.protein, formula.protein)
    // get individual distances
    const fatDist = Math.abs(formula.fat - fat)
    const carbDist = Math.abs(formula.carb - carb)
    //we give an edge to protein so it can drive to 0
    const proteinDist = formula.defow ? Math.abs(formula.protein - protein) * 1.25 : Math.abs(formula.protein - protein)
    // get distance as a percentage of the max
    const defowDist = (fatDist + carbDist + proteinDist) / maxDist
    // return how far away we are from 100%

    return Math.max(0.00, Math.min(1.0, (1.0 - defowDist)))*/

  // this is genius
  const m = pointsToAngles(vectorsToPoints({ x: 400, y: 400 }, 400, macros));
  const r = pointsToAngles(vectorsToPoints({ x: 400, y: 400 }, 400, formula));

  const cfDiff = Math.abs(m.cf - r.cf);
  const pfDiff = Math.abs(m.pf - r.pf);
  const pcDiff = Math.abs(m.pc - r.pc);

  const defowDiff = (cfDiff + pcDiff + pfDiff) / 180;
  return Math.max(0.0, 1 - defowDiff);

  /* best so far
    const maxDist = Math.max(1-squirrelFormula.fat, squirrelFormula.fat) + Math.max(1-squirrelFormula.carb, squirrelFormula.carb)
    const fatDist = Math.abs(squirrelFormula.fat - fat)
    const carbDist = Math.abs(squirrelFormula.carb - carb)
    const actualDist = fatDist + carbDist

    let defowDist = (actualDist / maxDist) / 2 
    
    return !fat && !carb && !protein ? 0 : Math.max(0.00, Math.min(1.0, (1.0 - defowDist) * (1 - protein)))
    */
}

function asDecimal(cals, totalCals) {
  return cals / totalCals || 0;
}

function asPercent(cals, totalCals) {
  return Math.round(asDecimal(cals, totalCals) * 100.0) || 0;
}

export function getAsDecimals({ fat, carb, protein }) {
  const total = fat + carb + protein;
  return {
    fat: asDecimal(fat, total),
    carb: asDecimal(carb, total),
    protein: asDecimal(protein, total)
  };
}

export function getAsPercentages({ fat, carb, protein }) {
  const total = fat + carb + protein;
  return {
    fat: asPercent(fat, total),
    carb: asPercent(carb, total),
    protein: asPercent(protein, total)
  };
}

function getTotal({ fat, carb, protein }) {
  return fat + carb + protein;
}

export function getDataFromGrams(grams) {
  return getData(gramsToCalories(grams));
}

export function getData(calories) {
  return {
    calories,
    total: getTotal(calories),
    decimals: getAsDecimals(calories),
    percentages: getAsPercentages(calories)
  };
}

export function vectorsToPoints(
  centre,
  radius,
  { fat, carb, protein },
  max = 0.99
) {
  //LIMIT MAX LINE LENGTH for visuals only
  const _fat = Math.max(0.01, Math.min(0.99, fat));
  const _carb = Math.max(0.01, Math.min(0.99, carb));
  const _protein = Math.max(0.01, Math.min(0.99, protein));

  return {
    fat: {
      x: centre.x + _fat * radius * Math.cos(-0.52),
      y: centre.y - _fat * radius * Math.sin(-0.52)
    },
    carb: {
      x: centre.x - _carb * radius * Math.cos(-0.52),
      y: centre.y - _carb * radius * Math.sin(-0.52)
    },
    protein: {
      x: radius, // no rotation
      y: centre.y - _protein * radius
    }
  };
}

function squareOfDiff(p1, p2) {
  const xDiff = p1.x - p2.x;
  const yDiff = p1.y - p2.y;
  return xDiff * xDiff + yDiff * yDiff;
}

export function pointsToAngles({ fat, carb, protein }) {
  // needed for cosine law
  const a2 = squareOfDiff(protein, fat);
  const b2 = squareOfDiff(protein, carb);
  const c2 = squareOfDiff(carb, fat);

  // line distances
  const a = Math.sqrt(a2);
  const b = Math.sqrt(b2);
  const c = Math.sqrt(c2);

  // cosine law to get angles in radians
  let cf = Math.acos((a2 + b2 - c2) / (2.0 * a * b));
  let pc = Math.acos((a2 + c2 - b2) / (2.0 * a * c));
  let pf = Math.acos((b2 + c2 - a2) / (2.0 * b * c));

  // convert to degrees
  cf = (cf * 180.0) / Math.PI;
  pc = (pc * 180.0) / Math.PI;
  pf = (pf * 180.0) / Math.PI;

  // boom
  return { cf, pc, pf };
}
