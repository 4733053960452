import React, {createContext, useContext} from "react";
import { BrowserRouter, Route } from "react-router-dom";

const RouterContext = createContext({});

// wrap your app in this
export default function Router ({ children }) {
    return (
        <BrowserRouter>
            <Route>
                {(props) => (
                    <RouterContext.Provider value={props}>
                        {children}
                    </RouterContext.Provider>
                )}
            </Route>
        </BrowserRouter>
    )
}

// use this
export function useRouter() {
  return useContext(RouterContext)
}