import { useEffect, useRef, useState } from 'react'

export default function useCanvas(width, height) {
    const ref = useRef()
    const [context, setContext] = useState(null)
    const dpr = window.devicePixelRatio || 1

    useEffect(() => {
      const canvas = ref.current

      canvas.setAttribute('height', width * dpr)
      canvas.setAttribute('width', height * dpr)   

      const context = canvas.getContext("2d")
      context.webkitImageSmoothingEnabled = false;
      context.mozImageSmoothingEnabled = false;
      context.imageSmoothingEnabled = false;   
      
      context.scale(dpr, dpr)
      //forces re-render when we have context :-)
      setContext(context)
    }, [dpr, height, width])

    function paint(fn) {
         context && requestAnimationFrame(fn) 
    }

    return [
        {ref, width, height}, // props to be used by canvas 
        context, // context to paint with
        paint // function that does the drawing
    ]
}
