import React, { useState, useEffect } from 'react';
import DefowRadar from './DefowRadar';
import getData from './data';
import { getScoreFromPercentages } from './defowScore';
import Emoji from './Emoji';

const data = getData();

const itemsPerPage = 6;
export default function FoodSearch({ searchTerm, onClick, macroQuery }) {
  const [selection, setSelection] = useState(null);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('closest');

  useEffect(() => {
    async function filter() {
      setPage(1);

      let results = [];

      const query = searchTerm
        .trim()
        .toLocaleLowerCase()
        .replace(/\s\s+/g, ' ');
      const queryWords = query.split(' ');
      if (!query && !macroQuery) {
        setSelection(null);
        return;
      }

      if (query) {
        if (query === '*') {
          results = [...data]; // clone it
        } else {
          results = data.filter(({ qname }) => {
            return queryWords.every(word => qname.includes(word));
          });
        }

        // e.g. for low carb it might be < 10% carb, >40% fat (protein looks after self), order by anti-autumnal
        if (sort === 'closest') {
          results = results.sort((a, b) => b.score - a.score);
        } else {
          results = results.sort((a, b) => a.score - b.score);
        }

        if (!results || !results.length) {
          setSelection(null);
          return;
        }
      } else {
        // macroquery
        results = data.map(element => {
          return {
            ...element,
            mScore: getScoreFromPercentages(element.percentages, macroQuery)
          };
        });
        results = results.filter(element => element.mScore > 0.75);

        if (sort === 'closest') {
            results = results.sort((a, b) => b.mScore - a.mScore);
          } else {
            results = results.sort((a, b) => a.mScore - b.mScore);
          }
      }

      setSelection(results);
    }

    filter();
  }, [macroQuery, searchTerm, sort]);

  if (!searchTerm && !macroQuery) return null;

  const numPages = selection ? Math.ceil(selection.length / itemsPerPage) : 0;
  const startIndex = itemsPerPage * (page - 1);
  const endIndex = itemsPerPage * (page - 1) + itemsPerPage;

  let columns = '';
  if (selection) {
    columns = 'auto auto auto';
    if (selection.length === 2) columns = 'auto auto';
    if (selection.length === 1) columns = 'auto';
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '5px'
        }}
      >
        <div className="Pagination">
          {!selection ? (
            <div>
              Sorry, no results... <Emoji label="sad" emoji="😢" />
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: '5px' }}>
                Pg. {page} of {numPages}
              </span>
              <button
                style={{ marginRight: '5px' }}
                onClick={() => setPage(page - 1)}
                disabled={page === 1 || null}
              >
                ⇦
              </button>{' '}
              <button
                onClick={() => setPage(page + 1)}
                disabled={numPages <= page || null}
              >
                ⇨
              </button>
            </div>
          )}
        </div>
        {selection && (
          <div className="Pagination">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: '5px' }}>Sort:</span>
              <button
                onClick={() => setSort('closest')}
                disabled={sort === 'closest' || null}
              >
              ⇩
              </button>
              <button
                style={{ marginLeft: '5px' }}
                onClick={() => setSort('furthest')}
                disabled={sort === 'furthest' || null}
              >
              ⇧
              </button>
            </div>
          </div>
        )}
      </div>
      {selection && (
        <div
          style={{
            display: 'grid',
            padding: '20px',
            gridGap: '20px',
            gridTemplateColumns: columns
          }}
        >
          {selection.slice(startIndex, endIndex).map((item, i) => {
            return (
              <div
                onClick={() => onClick(item)}
                key={item.name}
                style={{
                  minWidth: '80px',
                  textAlign: 'center',
                  cursor: selection.length > 1 ? 'pointer' : 'auto'
                }}
              >
                <DefowRadar
                  macros={{ ...item.cals }}
                  fiber={item.other.fiber}
                  sugars={item.other.sugars}
                  thumb={selection.length > 1 || null}
                />
                <div style={{ fontSize: '12px', textAlign: 'center' }}>
                  {item.name}
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div
        style={{
          fontSize: '9px',
          textAlign: 'center',
          paddingTop: '10px',
          marginTop: '10px',
          borderTop: '1px solid #444444'
        }}
      >
        This experimental search feature uses data from the USDA food
        composition database. US Department of Agriculture, Agricultural
        Research Service, Nutrient Data Laboratory. USDA National Nutrient
        Database for Standard Reference, Release 28. Version Current: September
        2015. Internet: https://www.ars.usda.gov/nea/bhnrc/ndl
      </div>
    </div>
  );
}
