import React, { useRef } from 'react';
import useCanvas from './useCanvas';
import defowImage from './images/defow.png';
import {
  getScoreFromPercentages,
  squirrelFormula,
  getData,
  vectorsToPoints
} from './defowScore';

export default function DefowRadar({
  macros: { fat, carb, protein },
  sugars = null,
  fiber = null,
  logo = true,
  thumb = false
}) {
  const width = thumb ? 450 : 600;
  const scale = thumb ? 0.75 : 1;
  const height = width;

  const [canvasProps, ctx, paint] = useCanvas(width, height);
  const imageRef = useRef(null);
  //const sharingImageRef = useRef(null);
  //let dataURL

  function getColor(value, alpha) {
    //value from 0 to 1
    var hue = ((1 - value) * 120).toString(10);
    return `hsla(${hue}, 100%, 50%, ${alpha})`;
  }

  paint(() => {
    const centre = { x: width / 2, y: height / 2 };
    const radius = width / 2;

    const data = getData({ fat, carb, protein });

    const points = vectorsToPoints(centre, radius, data.decimals);
    const gridPoints = vectorsToPoints(centre, radius, {
      fat: 0.9,
      carb: 0.9,
      protein: 0.9
    });

    //background
    ctx.beginPath();
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, width, height);

    // branding
    imageRef.current &&
      ctx.drawImage(
        imageRef.current,
        width - 154 * scale,
        20 * scale,
        153 * scale,
        81 * scale
      );

    // graph lines
    const gridColor = 'silver';

    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(centre.x, centre.y);
    ctx.lineTo(gridPoints.fat.x, gridPoints.fat.y);
    ctx.strokeStyle = 'white';
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(centre.x, centre.y);
    ctx.lineTo(gridPoints.carb.x, gridPoints.carb.y);
    ctx.strokeStyle = 'white';
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(centre.x, centre.y);
    ctx.lineTo(gridPoints.protein.x, gridPoints.protein.y);
    ctx.strokeStyle = 'white';
    ctx.stroke();

    // circles
    for (let i = 1; i <= 4; i++) {
      ctx.beginPath();
      ctx.arc(centre.x, centre.y, (radius * i) / 5 - 1, 0, 2 * Math.PI);
      ctx.strokeStyle = gridColor;
      ctx.stroke();
    }

    //heat map
    ctx.globalAlpha = 0.8;
    const gradient = ctx.createLinearGradient(0, height - 20, width, height);
    gradient.addColorStop(0, 'green');
    gradient.addColorStop(1 / 3, 'yellow');
    gradient.addColorStop(2 / 3, 'orange');
    gradient.addColorStop(1, 'red');
    ctx.fillStyle = gradient;
    ctx.fillRect(0, height - 20, width, 10);
    ctx.globalAlpha = 1;

    const sugarPercentage = (sugars * 4.0) / carb;
    const sugarFactor = data.decimals.carb * sugarPercentage * 0.5;

    const fiberPercentage = (fiber * 4.0) / carb;
    const fiberFactor = data.decimals.carb * fiberPercentage * 0.5;

    const defowScore = getScoreFromPercentages(data.decimals);

    let sugarX;
    if (sugarFactor) {
      const sugarScore =
        defowScore + sugarFactor > 1.0 ? 1.0 : defowScore + sugarFactor;
      sugarX = sugarScore > 0 ? sugarScore * (width - 10) : 0;
    }

    let fiberX;
    if (fiberFactor) {
      const fiberScore =
        defowScore - fiberFactor < 0.0 ? 0.0 : defowScore - fiberFactor;
      fiberX = fiberScore < 0.0 ? 0.01 : fiberScore * (width - 10);
    }

    const defowX = defowScore < 0.0 ? 0.0 : defowScore * (width - 10);

    ctx.font = '18px Arial';
    ctx.fillStyle = 'white';
    ctx.globalAlpha = (carb || fat || protein) && 1 - defowScore;
    ctx.fillText('Anti-Autumnal Eating Zone', 10, height - 25);
    ctx.globalAlpha = (carb || fat || protein) && defowScore;
    ctx.fillText('Autumnal Eating Zone', width - 190, height - 25);

    ctx.font = `${11 * scale}px Arial`;
    ctx.fillStyle = 'white';
    ctx.globalAlpha = 0.3;
    ctx.fillText(
      `Copyright © 2019, Cian Foley - Don't Eat for Winter®`,
      170 * scale,
      height - 105 * scale
    );
    ctx.globalAlpha = 0.6;
    ctx.fillText(`www.defowdiet.com`, 250 * scale, height - 90 * scale);
    ctx.globalAlpha = 1;

    ctx.beginPath();
    ctx.strokeStyle = 'silver';
    ctx.setLineDash([3, 3]);
    ctx.moveTo(centre.x, height - 0);
    ctx.lineTo(centre.x, height - 35);
    ctx.stroke();
    ctx.setLineDash([]);

    if (sugarPercentage && Math.abs(sugarX - defowX) > 10) {
      ctx.fillText(`🍬`, sugarX, height + 1);
    }

    if (fiberPercentage && Math.abs(fiberX - defowX) > 10) {
      ctx.fillText(`🥦`, fiberX, height + 1);
    }

    // triangle under heat showing score
    ctx.beginPath();
    ctx.moveTo(defowX + 5, height - 5);
    ctx.lineTo(defowX + 10, height);
    ctx.lineTo(defowX, height);
    ctx.lineTo(defowX + 5, height - 5);
    ctx.fillStyle = 'white';
    ctx.fill();

    ctx.fillStyle = 'white';

    // DEFoW area
    const referencePoints = vectorsToPoints(centre, radius, squirrelFormula);
    ctx.beginPath();
    ctx.strokeStyle = 'silver';
    ctx.setLineDash([3, 3]);
    ctx.moveTo(referencePoints.fat.x, referencePoints.fat.y);
    ctx.lineTo(referencePoints.carb.x, referencePoints.carb.y);
    ctx.lineTo(referencePoints.protein.x, referencePoints.protein.y);
    ctx.lineTo(referencePoints.fat.x, referencePoints.fat.y);
    ctx.stroke();
    ctx.setLineDash([]);

    ctx.font = '10px Arial';
    ctx.fillStyle = 'silver';
    ctx.fillText(`The`, centre.x - 28, centre.y + 28);
    ctx.font = '14px Arial';
    ctx.fillText(`DEFoW`, centre.x - 27, centre.y + 41);
    ctx.font = '10px Arial';
    ctx.fillText(`Zone`, centre.x, centre.y + 50);

    // area
    ctx.beginPath();
    ctx.lineWidth = 3;
    ctx.strokeStyle = '#ffffff44';
    ctx.fillStyle = getColor(defowScore, 0.8);
    ctx.moveTo(points.fat.x, points.fat.y);
    ctx.lineTo(points.carb.x, points.carb.y);
    ctx.lineTo(points.protein.x, points.protein.y);
    ctx.lineTo(points.fat.x, points.fat.y);
    ctx.stroke();
    ctx.fill();

    ctx.shadowBlur = 8;
    ctx.shadowColor = 'white';
    // carb
    ctx.beginPath();
    ctx.lineWidth = 3;
    ctx.moveTo(centre.x, centre.y);
    ctx.lineTo(points.carb.x, points.carb.y);
    ctx.strokeStyle =
      data.decimals.carb > 0.97 ? getColor(defowScore, 1) : 'white';
    ctx.stroke();

    // protein
    ctx.beginPath();
    ctx.moveTo(centre.x, centre.y);
    ctx.lineTo(points.protein.x, points.protein.y);
    ctx.strokeStyle =
      data.decimals.protein > 0.97 ? getColor(defowScore, 1) : 'white';
    ctx.stroke();

    //fat
    ctx.beginPath();
    ctx.moveTo(centre.x, centre.y);
    ctx.lineTo(points.fat.x, points.fat.y);
    ctx.strokeStyle =
      data.decimals.fat > 0.97 ? getColor(defowScore, 1) : 'white';
    ctx.stroke();

    ctx.shadowBlur = 0;

    ctx.font = '16px Arial';
    ctx.fillStyle = 'white';
    ctx.fillText(
      `Fat ${data.percentages.fat}%`,
      gridPoints.fat.x - 30,
      gridPoints.fat.y + 30
    );
    ctx.fillText(
      `Carb ${data.percentages.carb}%`,
      gridPoints.carb.x - 35,
      gridPoints.carb.y + 30
    );
    ctx.fillText(
      `Protein ${data.percentages.protein}%`,
      gridPoints.protein.x + 10,
      gridPoints.protein.y + 20
    );
    ctx.font = '12px Arial';
    ctx.fillStyle = 'red';
    sugarPercentage &&
      ctx.fillText(
        `🍬 sugar ${Math.round(sugarPercentage * 100)}%`,
        gridPoints.carb.x - 35,
        gridPoints.carb.y + 45
      );
    ctx.fillStyle = 'green';
    fiberPercentage &&
      ctx.fillText(
        `🥦 fiber ${Math.round(fiberPercentage * 100)}%`,
        gridPoints.carb.x - 35,
        gridPoints.carb.y + (sugarPercentage ? 60 : 45)
      );

    //ctx.fillText(`f: ${fat}, c: ${carb}, p: ${protein}, defow: ${0.12}, a: ${actualDist}, diff: ${diff}, dv: ${defowVector}`, 0, height - 25);
    ctx.globalAlpha = 1;
    //sharingImageRef.current.src = canvasProps.ref.current.toDataURL();
  });

  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <canvas {...canvasProps} className="Canvas" />
      {/*<img className='Canvas' ref={sharingImageRef} alt='DEFoW Autumnal Diet Avoidance Radar' />*/}
      {logo && (
        <img
          src={defowImage}
          alt="defow logo"
          ref={imageRef}
          style={{ display: 'none' }}
        />
      )}
    </div>
  );
}
