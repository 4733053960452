import Papa from 'papaparse'
import { getScoreFromPercentages, gramsToCalories, getAsDecimals } from './defowScore'

import csv from './foods.csv.js'
import countryMacros from './country-macros.csv.js'

export function getCountryData() {

    let { data } = Papa.parse(countryMacros, { header: true })

     data = data.map(element => {
        const macros = {
            fat: parseFloat(element['fat']) / 9,
            carb: parseFloat(element['carb']) / 4,
            protein: parseFloat(element['protein']) / 4
        }

        const cals = gramsToCalories(macros)
        const percentages = getAsDecimals(cals)
        const score = getScoreFromPercentages(percentages)
        const qname = element.country.toLocaleLowerCase()

        const name = `${element.country}: ${element.calsPerDay}`

        return { original: {...element}, qname, name, cals, percentages, score, other: {fiber: 0, sugar: 0 }}      
    })
    
    const ordered = data.sort((a, b) => b.score - a.score)
    const result = Papa.unparse(ordered.map(item => {
        return {
            ...item.original,
            score: item.score
        }
    }))
    console.log(result)

    return data;    
}

export default function getData() {
    let { data } = Papa.parse(csv, { header: true })

    /* groups
    Baby Foods: 327
    Baked Products: 496
    Beef Products: 619
    Beverages: 278
    Breakfast Cereals: 403
    Cereal Grains and Pasta: 183
    Dairy and Egg Products: 239
    Ethnic Foods: 165
    Fast Foods: 365
    Fats and Oils: 215
    Finfish and Shellfish Products: 255
    Fruits and Fruit Juices: 328
    Lamb, Veal, and Game Products: 345
    Legumes and Legume Products: 365
    Meals, Entrees, and Sidedishes: 57
    Nut and Seed Products: 128
    Pork Products: 328
    Poultry Products: 381
    Restaurant Foods: 51
    Sausages and Luncheon Meats: 234
    Snacks: 162
    Soups, Sauces, and Gravies: 499
    Spices and Herbs: 61
    Sweets: 341
    Vegetables and Vegetable Products: 812
    */

    // remove companies
    const matches = []
    data.forEach(element => {
        const rMatches = element.name.match(/[A-Z][A-Z]+/)
        if(rMatches) {
            rMatches.forEach(rMatch => !matches.includes(rMatch) && matches.push(rMatch))
        }
    })
    
    data = data.filter(element => !matches.find(match => element.name.includes(match)))

    data = data.map(element => {
        const { name, group } = element

        const macros = {
            fat: parseFloat(element['fat (g)']),
            carb: parseFloat(element['carbohydrate (g)']),
            protein: parseFloat(element['protein (g)'])
        }

        const sugars = parseFloat(element['sugars (g)'])

        const other = {
            sugars,
            sugarPercentage: sugars / macros.carb,
            fiber: parseFloat(element['fiber (g)']),
            sodium: parseFloat(element['sodium (g)']),
            calcium: parseFloat(element['calcium (g)']),
            potassium: parseFloat(element['potassium (g)']), 
            vitc: parseFloat(element['vitaminc (g)'])
        }

        const cals = gramsToCalories(macros)
        const percentages = getAsDecimals(cals)
        const score = getScoreFromPercentages(percentages)
        const qname = name.toLocaleLowerCase()
        return { name, group, qname, macros, cals, percentages, other, score }      
    })
    
    data = data.filter(food => (food.cals.carb + food.cals.fat + food.cals.protein) > 0)
    
    const ordered = data.sort((a, b) => b.score - a.score)
    ordered.forEach(item => 
        {
            //console.log(item.name + '\t' + Math.round(item.score * 100)/100);
        }
    );


    return data;    
}
