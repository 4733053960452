import React, { useState } from 'react';
import Router from './router';
import Home from './Home';

function App({ config }) {
  const [updated, setUpdated] = useState(null);
  config.onUpdate = () => {
    console.log('we got an update');
    setUpdated(true);
  };
  config.onSuccess = () => setUpdated(null);

  return (
    <Router>
      {updated && (
        <div
          style={{
            backgroundColor: '#E71C2C',
            color: 'white',
            padding: '10px',
            textAlign: 'center'
          }}
        >
          <strong>Update available:</strong> please close all instances and
          re-open to refresh.
        </div>
      )}
      <Home />
    </Router>
  );
}

export default App;
