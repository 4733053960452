import React, { useRef, useState, useEffect } from 'react'
import {
    FacebookIcon,
    TwitterShareButton, TwitterIcon,
    WhatsappShareButton, WhatsappIcon,
    LinkedinIcon
  } from 'react-share';
import { useRouter } from './router'
import Disclaimer from './Disclaimer'
import Donate from './Donate'
import Emoji from './Emoji'
import DefowRadar from './DefowRadar'
import FoodSelector from './FoodSearch'
import { squirrelFormula, getDataFromGrams } from './defowScore'
import nutritionLabel from './images/nutrition-label.png'
import dadar from './images/dadar.png'
import defowFormula from './images/squirrel-formula.png'
import dontEatForWinterCover from './images/dont-eat-for-winter-cover.png'
import cianProfile from './images/cian-profile-body.jpg'
import './App.css'

export default function Home() {
    const { location: { search }, history } = useRouter();
    const params = new URLSearchParams(search)
    const [fat, setFat] = useState(params.get('fat') || 24)
    const [carb, setCarb] = useState(params.get('carb') || 41)
    const [protein, setProtein] = useState(params.get('protein') || 6)
    const [macroQuery, setMacroQuery] = useState(null)
    const [query, setQuery] = useState(decodeURIComponent(params.get('query') || ''));
    const timerRef = useRef(null)
    const data = getDataFromGrams({fat, carb, protein});

    useEffect (() => {
        const params = new URLSearchParams()

        params.set('carb', carb)
        params.set('fat', fat)
        params.set('protein', protein)
        query && params.set('query', encodeURIComponent(query))

        async function replaceHistory() {
            history.replace({search: params.toString()})   
        }
        
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(replaceHistory, 500) 
    }, [fat, carb, protein, history, query])

    const disabled = !!query.trim() || macroQuery;

    function handleClick(item) {
        setFat(item.macros.fat)
        setCarb(item.macros.carb)
        setProtein(item.macros.protein)
        setQuery(item.name)
    }

    function handleMacroQuery(formula) {
        setFat(Math.round(formula.fat / 9 * 400 * 100) / 100)
        setCarb(Math.round(formula.carb / 4 * 400 * 100) / 100)
        setProtein(Math.round(formula.protein / 4 * 400 * 100) / 100)
        setMacroQuery(formula)
        setQuery('');
    }

    function handleFat(fat) {
        setFat(fat);
        setMacroQuery(null);
        setQuery('');
    }

    function handleProtein(protein) {
        setProtein(protein);
        setMacroQuery(null);
        setQuery('');    
    }

    function handleCarb(carb) {
        setCarb(carb);
        setMacroQuery(null);
        setQuery('');
    }

    let title = `Find out how autumnal foods are with a macro ratio of `
    title += `${data.percentages.fat}% fat, ${data.percentages.carb}% carb, and ${data.percentages.protein}% protein, `
    title += `with Don't Eat For Winter's D.A.D.A.R`

    return (
        <div className='Page'>
            {/* eslint-disable-next-line */}
            <a name='dadar' />
            <h1 style={{textAlign: 'center', color: 'white', margin: 0, padding: 0}}>
                D<span className='dot'>.</span>
                A<span className='dot'>.</span>
                D<span className='dot'>.</span>
                A<span className='dot'>.</span>
                R <a href='#what-is-dadar' title='What is DADAR?'>[?]</a>
            </h1>
            <div className='Controls' style={{margin: '10px 0px 0px 0px'}}>            
                <input className='Controls-search' placeholder='Experimental: Search USDA Food Database'
                    style={!disabled ? { backgroundColor: 'silver' } : null}
                    type='text' value={query} onChange={(e)=>{setQuery(e.target.value); setMacroQuery(null)}}/>  
                {(query || macroQuery) && <button className='Controls-button' onClick={()=>{setQuery('');setMacroQuery(null);}}>Close</button>}
            </div>
            <FoodSelector searchTerm={query} macroQuery={macroQuery} onClick={handleClick} searchMacros={macroQuery} />
            {!disabled && <DefowRadar macros={data.calories} />}
            <div style={{padding: 0, marginTop: '10px', border: 'none'}}>
                <table>
                    <thead>
                        <tr>
                            <th colSpan={2} style={{textAlign: 'left'}}>Grams</th>
                            <th width='99%'>Macronutrients</th>
                            <th style={{textAlign: 'left'}}>%</th>
                            <th style={{textAlign: 'right'}}>Cals</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>               
                            <td><input type='text' size={3} className='Controls-grams'
                                value={fat} onChange={(e)=>handleFat(e.target.value)}/>
                            </td>
                            <td style={{textAlign: 'right'}}>Fat</td>     
                            <td><input type='range' className='Controls-slider'
                                value={fat} min={0} max={100} onChange={(e)=>handleFat(e.target.value)}/>
                            </td>
                            <td style={{textAlign: 'left'}}>{data.percentages.fat}</td> 
                            <td style={{textAlign: 'right'}}>{Math.round(data.calories.fat)}</td>                
                        </tr>
                        <tr>              
                            <td><input type='text' size={3} className='Controls-grams'
                                value={carb} onChange={(e)=>handleCarb(e.target.value)}/>
                            </td>
                            <td style={{textAlign: 'right'}}>Carbs</td>      
                            <td><input type='range' className='Controls-slider'
                                value={carb} min={0} max={100} onChange={(e)=>handleCarb(e.target.value)}/>
                            </td>
                            <td style={{textAlign: 'left'}}>{data.percentages.carb}</td> 
                            <td style={{textAlign: 'right'}}>{Math.round(data.calories.carb)}</td>
                        </tr>
                        <tr>      
                            <td><input type='text' size={3} className='Controls-grams'
                                value={protein} onChange={(e)=>handleProtein(e.target.value)}/>
                            </td> 
                            <td style={{textAlign: 'right'}}>Protein</td>
                            <td><input type='range' className='Controls-slider'
                                value={protein} min={0} max={100} onChange={(e)=>handleProtein(e.target.value)}/>
                            </td>
                            <td >{data.percentages.protein}</td>   
                            <td style={{textAlign: 'right'}}>{Math.round(data.calories.protein)}</td>                
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{textAlign: 'center'}}>
                <button style={{marginRight: '10px'}} onClick={()=>setMacroQuery(data.decimals)}>⇧⇧⇧ Find Foods with these Macros ⇧⇧⇧</button>
            </div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px', fontSize: '12px'}}>
                OR BY SEASON:
                <Emoji style={{marginLeft: '10px'}} onClick={()=>handleMacroQuery({fat: 0.3, carb: 0.0, protein: 0.7})} size='25' label='spring' value='🌱'/>
                <Emoji style={{marginLeft: '10px'}}onClick={()=>handleMacroQuery({fat: 0.0, carb: 0.7, protein: 0.3})} size='25' label='summer' value='☀️'/>
                <Emoji style={{marginLeft: '10px'}}onClick={()=>handleMacroQuery(squirrelFormula)} size='25' label='autumn' value='🍁'/>
                <Emoji style={{marginLeft: '10px'}}onClick={()=>handleMacroQuery({fat: 0.5, carb: 0.0, protein: 0.5})} size='25' label='winter' value='❄️'/>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                <div style={{marginRight: '10px'}}>
                    <TwitterShareButton url={window.location.href} 
                        title={title}
                        via="wellboy"
                        hashtags={['donteatforwinter', 'DEFoW']}>
                        <TwitterIcon size={32} />
                    </TwitterShareButton>
                </div>
                <div>
                    <WhatsappShareButton url={window.location.href}
                        title={title}>
                        <WhatsappIcon size={32} />
                    </WhatsappShareButton>
                </div>
            </div>
            <Donate />
            <div className='Content'>
                {/* eslint-disable-next-line */}
                <a name='what-is-dadar'><h2 style={{marginTop: '50px'}}>What is D.A.D.A.R?</h2></a> 
                <img alt='nutrition label' src={nutritionLabel} 
                    style={{float: 'right', width:'45%', paddingLeft: '20px', paddingBottom: '20px'}} />
                <p>
                    The <em>DEFoW Autumnal Diet Avoidance Radar</em>, <strong>D.A.D.A.R</strong>, 
                    is a free, experimental tool to evaluate how 'autumnal' foods are, and is intended as a companion tool for my book
                    '<a href="https://www.amazon.com/Dont-Eat-Winter-Infinite-Natures-ebook/dp/B06XG1LY25">Don't Eat for Winter</a>.
                    This is the first release of the software, so it likely contains bugs and glitches. Please report any issues and feedback to 
                    me <a href="https://twitter.com/wellboy?lang=en">@wellboy</a> on Twitter.
                </p>
                <p>
                   <em><strong>Note:</strong> this tool is for informational purposes only and is not intended as medical advice. 
                   Food choices should always be part of a well balanced diet containing adequate energy and protein, as
                   well as an array of fibrous, nutritious colourful veg.  Supporting your body with adequate energy, protein, 
                   micronutrients, fibre, hydration etc. gives your body the best chance at becoming a thriving biological machine. </em>
                </p>
                <p>
                  <strong style={{color: 'white'}}>By using this tool you are agreeing to, and acknowledge all parts of 
                   the <a href='#disclaimer'>disclaimer</a> shown at the bottom of this page.</strong>  Please read it, and if you do not agree 
                   with it, close the site now and do not use the tool.
                </p>
                <h3>Quick Start:</h3> 
                <p>
                    To evaluate a food, inspect its nutritional label. Enter the fat, carb and protein 
                    amounts (in grams) into the boxes or using the sliders.  Instantly, D.A.D.A.R will show 
                    how close the food is to the Don't Eat for Winter (DEFoW), autumnal 'squirrel formula' 
                    outlined in the book, '<a href="https://www.amazon.com/Dont-Eat-Winter-Infinite-Natures-ebook/dp/B06XG1LY25">
                    Don't Eat for Winter</a>' and on the <a href="https://www.donteatforwinter.com">website</a>
                </p>
                <p><a href='#instructions'>Click here to jump to detailed instructions.</a></p>
                <h2>Background</h2>
                <p>
                    According to <a href="https://www.cell.com/cell-metabolism/fulltext/S1550-4131(18)30325-5">research</a>, 
                    carb+fat food combinations are valued more by humans, than any other macronutrient combination,
                    They are energy dense, taste incredible, and are typical of the types of foods that are front-loaded in 
                    stores worldwide. Think doughnuts, chocolate bars, ice-cream, fries, chips / crisps, pastries, 
                    sweet creamy coffees etc.
                </p>
                <p>
                    Animals also value natural versions of this macronutrient combination, which makes some of them hyperphagic (uncontrollable 
                    appetite) during autumn. This allows them put on fat in order to survive the oncoming winter, even though they are still very active. 
                    In nature, acorns are a primary source of this combination and are used by bears, pigs, deers and squirrels to fatten
                    up for winter. I believe people are stimulated by the same formula because we are&nbsp; 
                    <a href="https://www.donteatforwinter.com/dont-eat-winter-bat-layer-hypothesis/">seasonal creatures too</a>. 
                    It is highly sought after because it was once necessary for winter survival. It is a fundamental desire.
                </p>
                <p>
                    For this reason, I've used the macronutrient ratio of the humble acorn as the rosetta stone 
                    of 'autumnal' eating in terms of macronutrient ratios. Only one other natural food matches this formula 
                    almost exactly: <em>human breast milk</em>. 
                    Milk is necessary for infant survival before weaning onto solids. It makes sense that milk should prime a baby's 
                    instincts in order to stimulate regular feeding. During autumn, it is easy to access 'the squirrel formula' through 
                    combinations of high sugar and starch fruits, vegetables and grains and fatty nuts, seeds and animal fats. I believe this 
                    combination stimulates our instincts to overeat because we have been programmed by nature to know when winter is coming. 
                    In winter, fat would be useful for both insulation from cold, and as a back up energy supply to compensate for dwindling 
                    food availability (especially high energy foods). Also, the primary fuel for cold-activated brown adipose tissue (BAT), 
                    an amazing substance that keeps creatures warm and that can be activated in adult humans, is <em>fat</em>. 
                </p>
                <p>
                    It didn't take long for humans to use these ingredients to come up with delectable recipes at the harvest 
                    such as granny's apple pie with sweetened cream. However, in our world today it is worse, with highly processed versions 
                    of this formula made with refined sugars, starches and fats both low in nutrients, and often with a long list of 
                    additives.  These are now available 24/7/365 at the front of every shop because they sell like hot cakes (which, incidentally 
                    are high in both carbs and fats). I term this tragedy, 'The Infinite Autumn' (on steroids), which prepares our bodies for a winter that 
                    never comes. We can never out-run it no more than a seasonally adapted animal can out-scurry the process of fattening up for winter.
                </p>
                <p>The current stats on obesity signal the fact that there is something very wrong with our environment.
                    We cannot adapt to it. This free tool is a labour of love and is designed to help people escape  
                    this infinite autumn where possible.
                    Eating autumnally is part of who we are but we need to be cognissant of it and take some care, especially
                    if susceptible to overeating or gaining weight.  I believe humans suffer from hyperphagia, just like other seasonal 
                    creatures and some of us are more talented at gaining weight (winter adaptees) than others. I am one of these people and have 
                    escaped my obesity for many years and am now competing in natural physique compeitions at 43. I achieved this through avoiding 
                    the squirrel formula where possible. <a href="https://www.donteatforwinter.com/author-bio/">Read my story here</a>.
                </p>

                {/* eslint-disable-next-line */}
                <a name='instructions'><h2>Detailed Instructions</h2></a> 
                <img alt='the defow zone' src={dadar} 
                    style={{float: 'right', width:'45%', paddingLeft: '20px', paddingBottom: '20px'}} />
                <p>
                    The objective of D.A.D.A.R is to show how similar a food's macronutrient ratio is to the 
                    autumnal 'Squirrel Formula'.  This is represented by the triangle in the centre of the radar chart 
                    labelled 'The DEFoW Zone'.  The overarching concept 'Dont Eat for Winter', suggests avoiding 
                    autumnal food combinations made from roughly equal amounts of carbs+fat (by calorie percentage) 
                    with low amounts of protein. This formula is highly available in nature around the harvest and 
                    I believe prepares our bodies for the following season: <em>winter</em>.  
                    In our world, this signature matches lots of junk foods in an uncanny way. You will notice
                    this as you use it. Processed junk foods are far worse than their natural counterparts because 
                    they are often made with refined sugars, starches and fats, without the nutrition present in whole foods.
                </p>
                <p>
                    To use D.A.D.A.R, enter the macronutrients of a single food or a recipe (in grams) using the boxes provided.
                    The sliders are there to help quickly adjust these numbers and see the chart updating in real-time. 
                    The sliders only go from 0-100 grams, so for larger quantities, please use the text boxes.  Remember, fat
                    is 9 calories per gram, where as protein and carbohydrates are 4 calories per gram.  These figures are also
                    calculated, and displayed for convenience, as you adjust the gram amounts.
                </p>
                <img alt='the defow zone' src={defowFormula} 
                    style={{float: 'left', width:'45%', paddingRight: '20px', paddingBottom: '20px'}} />
                <p>
                    Once macronutrients are entered, you'll see the ratio of protein/carbs/fat represented as a triangle 
                    on the radar chart. The colour of the triangle represents how close it is to the autumnal formula, 
                    red being more autumnal, green being less so.  The score is also represented on the heat-bar at the 
                    bottom of the chart. This makes it even more obvious when something is in the <strong>'Autumnal Eating Zone'</strong>.
                </p>
                <p>
                    The closer a food's macronutrient ratio is to this triangle (The DEFoW Zone), the more autumnal it is.  
                    It's that simple.
                </p>
                <p><a href='#dadar'>Try it out now...</a></p>
                <h3>Search Feature</h3> 
                <p>
                    It is worth trying out the search function at the top of the page. 
                    Simply enter a food name in the search box <em>eg. doughnut</em>, and it it will perform a search 
                    against the USDA food database.  This will list D.A.D.A.R chart thumbnails for all foods that match the 
                    search query.  After searching you can click close to go back to entering in macronutrient information.
                </p>
                <p>
                    Under D.A.D.A.R, there is also a feature to 'search for foods with these macros'.  This can be useful to
                    list other foods in the USDA food composition database with a similar signature to that entered. 
                    For conventience you can also search by spring, summer, autumn and winter pre-configured macros.  These
                    are an educated guesses as to what stone-age macros may have been like in seasonal climates eg:<br/>
                <Emoji style={{marginLeft: '10px'}} size='25' label='summer' value='🌱'/> spring might have been high 
                    protein, low fat and very low carb, as few carbs exist in nature in springtime and animals would have thinned after winer;<br/>
                <Emoji style={{marginLeft: '10px'}} size='25' label='autumn' value='☀️'/> summer might
                    have been high carb and low fat, as carbs re-appear and animals have not fattened just yet for winter;<br/>
                <Emoji style={{marginLeft: '10px'}} size='25' label='winter' value='🍁'/> autumn is based on the acorn formula i.e. the convergence
                    of carbs+fat; and finally,<br/>
                <Emoji style={{marginLeft: '10px'}} size='25' label='spring' value='❄️'/> winter might have been
                    high fat, high protein and low carb i.e. when carbs expired over winter and animals are still quite fattened.
                </p>
                <p>Adjust the macro nutrient ratio to your desired ratio using the sliders and then find similar foods.</p>

                <h2>The DEFoW Diet - Strategic Anti-Autumnal Eating</h2> 
                <p>
                    My approach to avoiding autumnal eating is to approximating macro ratios of seasons, other than autumn, during each day.
                    For example, winter into spring would perhaps have a lower carb / higher fat nutrient ratio as carbohydrates become less available 
                    in nature after the harvest. Spring into summer, on the other hand, would transition to a higher carb/lower fat nutrient ratio 
                    due to photosynthesis producing more natural vegetation. Based on this, my daily eating pattern transitions from a 
                    high fat/low carb to a low fat/high carb focus, keeping protein constant. Low GI/GL fibrous vegetables can be part of any meal.
                    This way I can work within nutritional guidelines, and get in a wide array of natural whole foods, while avoiding over stimulating 
                    my appetite. I believe in mimicking our ancestors way of living as best as possible, by eating an all-natural diet and by getting in plenty 
                    of daily exercise including resistance training and various types of cardiovascular activities indoor and outdoor.
                </p>
                <h3>An Example Day of DEFoW</h3>
                <p>
                    I typically have a protein and fat breakfast eg. eggs and smoked salmon, or avocado and nuts; I then have a high fibre 
                    and lower fat lunch with with a good source of protein eg. leaner meat, poultry or fish with vegetables/salad; and 
                    finally, I would have a low fat meal in the evenings with some carbohydrates eg. breast of chicken, turkey, lean meat or fish and rice, 
                    tubours, root veg etc.  This is my preferred way of eating and is sustainable for me, providing a good array of nutrients 
                    to support both my work and exercise, and I feel satisfied.  You can find out more about this approach in my book, '
                    <a href="https://www.amazon.com/Dont-Eat-Winter-Infinite-Natures-ebook/dp/B06XG1LY25">Don't Eat for Winter</a>' with foreword 
                    by Dr. Mark Rowe, <em>author of 'A Prescription for Happiness'</em>.
                </p>
                <p>
                    <a href="https://www.amazon.com/Dont-Eat-Winter-Infinite-Natures-ebook/dp/B06XG1LY25">
                        <img alt="Don't Eat for Winter - The Anti-Autumnal Diet by Cian Foley" src={dontEatForWinterCover} style={{width:'100%'}} />
                    </a>
                </p>
                <h2>Other Anti-Autumnal Approaches</h2>
                <p>There are many other approaches that inadvertantly avoid autumnal eating including popular low carb diets like Atkins, 
                    Ketogenic (keto) diets. There are also low fat diets, which have been the mainstream approach for decades.
                    One must take care with both approaches, as many foods labelled as low-fat contain a lot of sugars and processed ingredients and
                    high fat approaches can often be abused too.  Both approaches require some knowledge of nutrition and neither are a license to overeat.  
                    Talk to an expert and always read food labels.  I believe there is merit to both low carb and low fat approaches and that they
                    don't need to be mutually exclusive. The ideal diet for me should mimic nature and include an array of natural, in-season foods.
                    Nature is dynamic, perhaps the ideal diet should be dynamic too.
                </p>
                <p>
                    Most of these diets have energy and protein at the heart of them, but have one other major thing in common: 
                    <em>They avoid carb+fat combinations</em>. At a simple level, I believe this, above all else, is why they work for many.
                    They control appetitie, preventing overeating. Perhaps this is why many experts are at loggerheads about what approach works best. 
                </p>
                <p>
                    <strong>Warning:</strong> one could infer that the most anti-autumnal way of eating is to eat lean protein and fibrous veg all day. 
                    This is something like what a bodybuilder might do temporarily while cutting in the lead up to a competition.  From a sports nutrition point of view
                    this may be OK in the short term, once an athlete, and/or their coach, has a good understanding of nutrition and the athletes needs. 
                    In this environment, immediate action can be taken if issues arise, but it is neither healthy nor sustainable long-term for average people
                    without a depth of knowledge in nutrition.  It is OK to have a high protein, low-fat meal with fibrous veg/salad on a daily basis, 
                    but do be sure to get an adequate whole-food energy supply in your diet over the day to support your body, mind, hormones etc.  
                    Besides conditions such as the female triad, which can be exercise nutrition related, there is a condition known as 
                    '<a href="https://en.wikipedia.org/wiki/Protein_poisoning">rabbit starvation</a>', which can occur from diets overly high in lean 
                    protein and extremely low in fat and carbohydrates.  It is not a good idea to simulate this type of diet. 
                    I believe balance is important, as nature is balance. Nature provides a range of carbs, fats, proteins, fibre and micronutrients.
                    Eating seasonal vegetables, fruits, berries, nuts, seeds, grains, poultry, meat, eggs, sea foods, dairy etc., while avoiding high 
                    carbs+fat combos that are low in protein, especially processed ones, seems like a smart thing to do.
                </p>
                <p>Don't Eat for Winter suggests avoiding autumnal foods but it does not mean eradicate them completely from your diet.
                    Autumnal foods taste amazing and can be both healthy and wholesome and have a place in a balanced diet. When eating them, 
                    enjoy them and savour them. Do not feel guilty when you do indulge, they are part of who we are and there to be enjoyed. 
                    My advice is to go for real food versions and avoid junk where possible. Junk food is not sold to make you healty, 
                    it is sold to make a profit. My favourite autumnal food is apple tart, a little cinnamon, and a quality vanilla ice-cream. 
                    We all have our favourites.  Make it worth it when you do indulge. Just be aware of the effects these types of foods have on you.  
                    If they cause you to overeat, and thwart your goals, it is probably best to limit them to special occasions. Become aware and 
                    consider the mantra 'don't start if you can't stop'. Acknowledge their abundance in shops, in the workplace and at home
                    feel empowered when you run the gauntlet, knowing you are in full control. Once you're in full control, autumn can a good time to
                    enjoy what the harvest has to offer.
                </p>
                <p style={{marginBottom: '20px'}}><strong>Remember... Don't Eat for Winter!</strong></p>

                <img alt='the defow zone' src={cianProfile} 
                    style={{float: 'left', width:'45%', paddingRight: '20px', paddingBottom: '20px'}} />
                <p>
                    Cian Foley B.Sc., Pg. Dip., is a software engineer with over 20 years experience. By 35 years of age, he had become obese, weighing 256lbs.
                    With a young family he decided to change and took up kettlebell sport. Shortly after this, he became interested in learning about sports nutrition
                    and more natural ways of eating. After some time, he lost a lot of the unhealthy weight and won several international kettlebell competitions including
                    a Pan-American Championships at the Arnold's in Columbus Ohio, 2 European Championships in Bulgaria and Poland, and a World Championships
                    in Ireland. All of these were in the amateur men's long-cycle category i.e. cleaning and jerking 2x24kg kettlebells for 10 minute sets.  
                    After years of practical learning, he discovered DEFoW, the Anti-Autumnal eating approach, applied it, and dropped another 12kg bodyfat effortlessly
                    (after competing for years with kettlebells in the 85kg category). He now competes in natural physique competitions and placed
                    3rd in the Men's Physique category of the Natural Bodybuilding Federation of Ireland (NBFI) nationals in Cork in September 2018.
                    Cian is the author of Don't Eat for Winter, which details his hypothesis and anti-autumnal approach to diet and exercise nutrition.  He has a level
                    5 QQI certificate in Nutrition and a level 6 QQI certificate in Sports Nutrition along and a wealth of practical experience perfecting body
                    composition and fuelling exercise for 2 very different, but equally gruelling sports. He is proud of the fact that he achieved 90lbs
                    fat loss, and has maintained a high level of fitness using a natural eating approach. His wish is that this approach might 
                    have an impact on others who have lost hope like he once had, which is the motivation behind the creation of this tool.
                </p>
                <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: '10px'}}>
                <div style={{color: '#ffffff', marginRight: '10px'}}>Connect with Cian:</div>
                <div style={{marginRight: '10px'}}><a href="https://twitter.com/wellboy?lang=en"><TwitterIcon size={32}/></a></div>
                <div style={{marginRight: '10px'}}><a href="https://facebook.com/donteatforwinter"><FacebookIcon size={32}/></a></div>
                <div><a href="https://www.linkedin.com/in/cianfoley"><LinkedinIcon size={32}/></a></div>
                </div>
            </div>
            <Disclaimer />
            <p style={{textAlign: 'center'}}><a href='#dadar'>Thanks for reading, jump back to top...</a></p>
        </div>
    )
} 