const csv = `name,group,protein (g),calcium (g),sodium (g),fiber (g),vitaminc (g),potassium (g),carbohydrate (g),sugars (g),fat (g),water (g),calories,saturated (g),monounsat (g),polyunsat (g),id
"Beverage, instant breakfast powder, chocolate, not reconstituted",Dairy and Egg Products,19.9,0.285,0.385,0.4,0.07690000000000001,0.947,66.2,65.8,1.4,7.4,357,0.56,0.314,0.278,27481
"Beverage, instant breakfast powder, chocolate, sugar-free, not reconstituted",Dairy and Egg Products,35.8,0.5,0.717,2,0.138,1.705,41,39,5.1,7.4,358,2.162,1.189,1.027,27482
"Beverage, milkshake mix, dry, not chocolate",Dairy and Egg Products,23.5,0.88,0.78,1.6,0.0012,2.2,52.9,51.3,2.6,12.8,329,2.059,0.332,0.06,27483
"Butter oil, anhydrous",Dairy and Egg Products,0.28,0.004,0.002,0,0,0.005,0,0,99.48,0.24,876,61.924,28.732,3.694,27484
"Butter, salted",Dairy and Egg Products,0.85,0.024,0.714,0,0,0.024,0.06,0.06,81.11,15.87,717,51.368,21.021,3.043,27485
"Butter, whipped, with salt",Dairy and Egg Products,0.85,0.024,0.827,0,0,0.026,0.06,0.06,81.11,15.87,717,50.489,23.426,3.012,27486
"Butter, without salt",Dairy and Egg Products,0.85,0.024,0.011,0,0,0.024,0.06,0.06,81.11,17.94,717,51.368,21.021,3.043,27487
Cheese fondue,Dairy and Egg Products,14.23,0.476,0.132,0,0,0.105,3.77,0,13.47,61.61,229,8.721,3.563,0.484,27488
"Cheese food, cold pack, american",Dairy and Egg Products,19.66,0.497,0.966,0,0,0.363,8.32,0,24.46,43.12,331,15.355,7.165,0.719,27489
"Cheese food, imitation",Dairy and Egg Products,22.4,0.552,1.239,0,0,0.336,8.8,8.21,1.3,63.8,137,0.81,0.38,0.048,27490
"Cheese food, pasteurized process, american, with di sodium phosphate",Dairy and Egg Products,19.61,0.574,1.596,0,0,0.279,7.29,7.43,24.6,43.15,328,15.443,7.206,0.723,27491
"Cheese food, pasteurized process, american, without di sodium phosphate",Dairy and Egg Products,18.4,0.57,1.265,0,0,0.291,7.83,7.43,25.18,43.21,330,14.895,7.214,1.108,27492
"Cheese food, pasteurized process, swiss",Dairy and Egg Products,21.92,0.723,1.552,0,0,0.284,4.5,0,24.14,43.67,323,15.487,6.801,0.6,27493
"Cheese product, pasteurized process, american, reduced fat, fortified with vitamin D",Dairy and Egg Products,17.6,0.529,1.587,0,0,0.33,10.6,8.02,14.1,51.8,240,8.85,4.13,0.41,27494
"Cheese product, pasteurized process, cheddar or american, reduced fat",Dairy and Egg Products,17.6,0.529,1.587,0,0,0.33,10.6,8.02,14.1,51.8,240,8.85,4.13,0.41,27495
"Cheese sauce, prepared from recipe",Dairy and Egg Products,10.33,0.311,0.493,0.1,0.0006,0.142,5.48,0.19,14.92,66.86,197,8.034,4.735,1.397,27496
"Cheese spread, cream cheese base",Dairy and Egg Products,7.1,0.071,0.673,0,0,0.112,3.5,3.5,28.6,58.5,295,18.02,8.071,1.033,27497
"Cheese spread, pasteurized process, american, with di sodium phosphate",Dairy and Egg Products,16.41,0.562,1.625,0,0,0.242,8.73,0,21.23,47.65,290,13.327,6.219,0.624,27498
"Cheese spread, pasteurized process, american, without di sodium phosphate",Dairy and Egg Products,16.41,0.562,1.345,0,0,0.242,8.73,7.32,21.23,47.65,290,13.327,6.219,0.624,27499
"Cheese substitute, mozzarella",Dairy and Egg Products,11.47,0.61,0.685,0,0.0001,0.455,23.67,23.67,12.22,47.36,248,3.711,6.243,1.738,27500
"Cheese, Mexican, blend, reduced fat",Dairy and Egg Products,24.69,1.146,0.776,0,0,0.093,3.41,0.56,19.4,48.2,282,11.58,5.02,0.75,27501
"Cheese, american cheddar, imitation",Dairy and Egg Products,16.7,0.562,1.345,0,0,0.242,11.6,7.74,14,53.1,239,8.79,4.102,0.409,27502
"Cheese, blue",Dairy and Egg Products,21.4,0.528,1.395,0,0,0.256,2.34,0.5,28.74,42.41,353,18.669,7.778,0.8,27503
"Cheese, brick",Dairy and Egg Products,23.24,0.674,0.56,0,0,0.136,2.79,0.51,29.68,41.11,371,18.764,8.598,0.784,27504
"Cheese, brie",Dairy and Egg Products,20.75,0.184,0.629,0,0,0.152,0.45,0.45,27.68,48.42,334,17.41,8.013,0.826,27505
"Cheese, camembert",Dairy and Egg Products,19.8,0.388,0.842,0,0,0.187,0.46,0.46,24.26,51.8,300,15.259,7.023,0.724,27506
"Cheese, caraway",Dairy and Egg Products,25.18,0.673,0.69,0,0,0.093,3.06,0,29.2,39.28,376,18.584,8.275,0.83,27507
"Cheese, cheddar",Dairy and Egg Products,24.9,0.721,0.621,0,0,0.098,1.28,0.52,33.14,36.75,403,21.092,9.391,0.942,27508
"Cheese, cheshire",Dairy and Egg Products,23.37,0.643,0.7,0,0,0.095,4.78,0,30.6,37.65,387,19.475,8.671,0.87,27509
"Cheese, colby",Dairy and Egg Products,23.76,0.685,0.604,0,0,0.127,2.57,0.52,32.11,38.2,394,20.218,9.28,0.953,27510
"Cheese, cottage, creamed, large or small curd",Dairy and Egg Products,11.12,0.083,0.364,0,0,0.104,3.38,2.67,4.3,79.79,98,1.718,0.778,0.123,27511
"Cheese, cottage, creamed, with fruit",Dairy and Egg Products,10.69,0.053,0.344,0.2,0.0014,0.09,4.61,2.38,3.85,79.64,97,2.311,1.036,0.124,27512
"Cheese, cottage, lowfat, 1% milkfat",Dairy and Egg Products,12.39,0.061,0.406,0,0,0.086,2.72,2.72,1.02,82.48,72,0.645,0.291,0.031,27513
"Cheese, cottage, lowfat, 1% milkfat, lactose reduced",Dairy and Egg Products,12.4,0.053,0.22,0.6,0,0.086,3.2,2.6,1,82.5,74,0.64,0.29,0.03,27514
"Cheese, cottage, lowfat, 1% milkfat, no sodium added",Dairy and Egg Products,12.4,0.061,0.013,0,0,0.086,2.7,2.7,1,83.5,72,0.632,0.284,0.031,27515
"Cheese, cottage, lowfat, 1% milkfat, with vegetables",Dairy and Egg Products,10.9,0.056,0.403,0,0.004,0.086,3,3,1,83.5,67,0.619,0.282,0.039,27516
"Cheese, cottage, lowfat, 2% milkfat",Dairy and Egg Products,11.83,0.091,0.33,0,0,0.084,3.66,3.67,2.45,80.69,86,0.979,0.443,0.07,27517
"Cheese, cottage, nonfat, uncreamed, dry, large or small curd",Dairy and Egg Products,10.34,0.086,0.33,0,0,0.137,6.66,1.85,0.29,81.01,72,0.169,0.079,0.003,27518
"Cheese, cottage, with vegetables",Dairy and Egg Products,10.9,0.056,0.403,0.1,0.004,0.086,3,0.37,4.2,80.3,95,2.646,1.189,0.14,27519
"Cheese, cream",Dairy and Egg Products,5.93,0.098,0.321,0,0,0.138,4.07,3.21,34.24,54.44,342,19.292,8.62,1.437,27520
"Cheese, cream, fat free",Dairy and Egg Products,15.69,0.351,0.702,0,0,0.278,7.66,5.48,1,71.87,105,0.644,0.25,0.057,27521
"Cheese, cream, low fat",Dairy and Egg Products,7.85,0.148,0.47,0,0,0.247,8.13,5.82,15.28,66.72,201,9.098,3.996,0.658,27522
"Cheese, dry white, queso seco",Dairy and Egg Products,24.51,0.661,1.808,0,0,0.116,2.04,0.55,24.35,42.17,325,13.718,6.418,1.244,27523
"Cheese, edam",Dairy and Egg Products,24.99,0.731,0.965,0,0,0.188,1.43,1.43,27.8,41.56,357,17.572,8.125,0.665,27524
"Cheese, feta",Dairy and Egg Products,14.21,0.493,1.116,0,0,0.062,4.09,4.09,21.28,55.22,264,14.946,4.623,0.591,27525
"Cheese, fontina",Dairy and Egg Products,25.6,0.55,0.8,0,0,0.064,1.55,1.55,31.14,37.92,389,19.196,8.687,1.654,27526
"Cheese, fresh, queso fresco",Dairy and Egg Products,18.09,0.566,0.751,0,0,0.129,2.98,2.32,23.82,51.42,299,12.94,5.966,1.106,27527
"Cheese, gjetost",Dairy and Egg Products,9.65,0.4,0.6,0,0,1.409,42.65,0,29.51,13.44,466,19.16,7.879,0.938,27528
"Cheese, goat, hard type",Dairy and Egg Products,30.52,0.895,0.346,0,0,0.048,2.17,2.17,35.59,29.01,452,24.609,8.117,0.845,27529
"Cheese, goat, semisoft type",Dairy and Egg Products,21.58,0.298,0.515,0,0,0.158,2.54,2.54,29.84,45.52,364,20.639,6.808,0.709,27530
"Cheese, goat, soft type",Dairy and Egg Products,18.52,0.14,0.368,0,0,0.026,0.89,0.89,21.08,60.75,268,14.575,4.807,0.501,27531
"Cheese, gouda",Dairy and Egg Products,24.94,0.7,0.819,0,0,0.121,2.22,2.22,27.44,41.46,356,17.614,7.747,0.657,27532
"Cheese, gruyere",Dairy and Egg Products,29.81,1.011,0.336,0,0,0.081,0.36,0.36,32.34,33.19,413,18.913,10.043,1.733,27533
"Cheese, limburger",Dairy and Egg Products,20.05,0.497,0.8,0,0,0.128,0.49,0.49,27.25,48.42,327,16.746,8.606,0.495,27534
"Cheese, low fat, cheddar or colby",Dairy and Egg Products,24.35,0.415,0.612,0,0,0.066,1.91,0.52,7,63.1,173,4.342,2.082,0.222,27535
"Cheese, low-sodium, cheddar or colby",Dairy and Egg Products,24.35,0.703,0.021,0,0,0.112,1.91,0.49,32.62,38.98,398,20.768,9.189,0.972,27536
"Cheese, mexican, queso anejo",Dairy and Egg Products,21.44,0.68,1.131,0,0,0.087,4.63,4.63,29.98,38.06,373,19.033,8.528,0.901,27537
"Cheese, mexican, queso asadero",Dairy and Egg Products,22.6,0.661,0.655,0,0,0.086,2.87,2.87,28.26,42.16,356,17.939,8.038,0.85,27538
"Cheese, mexican, queso chihuahua",Dairy and Egg Products,21.56,0.651,0.617,0,0,0.052,5.56,5.56,29.68,39.13,374,18.843,8.443,0.892,27539
"Cheese, monterey",Dairy and Egg Products,24.48,0.746,0.536,0,0,0.081,0.68,0.5,30.28,41.01,373,19.066,8.751,0.899,27540
"Cheese, monterey, low fat",Dairy and Egg Products,28.2,0.705,0.564,0,0,0.081,0.7,0.56,21.6,46,310,14.04,5.64,0.84,27541
"Cheese, mozzarella, low sodium",Dairy and Egg Products,27.5,0.731,0.016,0,0,0.095,3.1,1.23,17.1,49.9,280,10.867,4.844,0.509,27542
"Cheese, mozzarella, nonfat",Dairy and Egg Products,31.7,0.961,0.743,1.8,0,0.106,3.5,1.48,0,60.2,141,0,0,0,27543
"Cheese, mozzarella, part skim milk",Dairy and Egg Products,24.26,0.782,0.619,0,0,0.084,2.77,1.13,15.92,53.78,254,10.114,4.51,0.472,27544
"Cheese, mozzarella, part skim milk, low moisture",Dairy and Egg Products,25.96,0.731,0.652,0,0,0.095,3.83,0.6,20.03,46.46,302,10.877,4.85,0.508,27545
"Cheese, mozzarella, whole milk",Dairy and Egg Products,22.17,0.505,0.627,0,0,0.076,2.19,1.03,22.35,50.01,300,13.152,6.573,0.765,27546
"Cheese, mozzarella, whole milk, low moisture",Dairy and Egg Products,21.6,0.575,0.415,0,0,0.075,2.47,1.01,24.64,48.38,318,15.561,7.027,0.778,27547
"Cheese, muenster",Dairy and Egg Products,23.41,0.717,0.628,0,0,0.134,1.12,1.12,30.04,41.77,368,19.113,8.711,0.661,27548
"Cheese, muenster, low fat",Dairy and Egg Products,24.7,0.529,0.6,0,0,0.134,3.5,3.5,17.6,50.5,271,10.95,5.09,0.65,27549
"Cheese, neufchatel",Dairy and Egg Products,9.15,0.117,0.334,0,0,0.152,3.59,3.19,22.78,63.11,253,12.79,5.784,0.97,27550
"Cheese, parmesan, dry grated, reduced fat",Dairy and Egg Products,20,1.109,1.529,0,0,0.125,1.37,0,20,50.6,265,13.317,6.098,0.462,27551
"Cheese, parmesan, grated",Dairy and Egg Products,38.46,1.109,1.529,0,0,0.125,4.06,0.9,28.61,20.84,431,17.301,8.375,1.173,27552
"Cheese, parmesan, hard",Dairy and Egg Products,35.75,1.184,1.602,0,0,0.092,3.22,0.8,25.83,29.16,392,16.41,7.515,0.569,27553
"Cheese, parmesan, low sodium",Dairy and Egg Products,41.6,1.376,0.063,0,0,0.107,3.7,0.85,29.99,22.2,451,19.051,8.721,0.659,27554
"Cheese, parmesan, shredded",Dairy and Egg Products,37.86,1.253,1.696,0,0,0.097,3.41,0.9,27.34,25,415,17.37,8.734,0.661,27555
"Cheese, pasteurized process, american, low fat",Dairy and Egg Products,24.6,0.684,1.43,0,0,0.18,3.5,0.59,7,58.9,180,4.41,2.005,0.222,27556
"Cheese, pasteurized process, american, with di sodium phosphate",Dairy and Egg Products,22.15,0.552,1.254,0,0,0.169,1.6,0.51,31.25,39.16,375,19.694,8.951,0.99,27557
"Cheese, pasteurized process, american, without di sodium phosphate",Dairy and Egg Products,22.15,0.616,0.65,0,0,0.162,1.6,0.51,31.25,39.16,375,19.694,8.951,0.99,27558
"Cheese, pasteurized process, cheddar or american, fat-free",Dairy and Egg Products,22.5,0.689,1.528,0,0,0.288,13.4,9.95,0.8,56.9,148,0.504,0.229,0.025,27559
"Cheese, pasteurized process, cheddar or american, low sodium",Dairy and Egg Products,22.2,0.616,0.007,0,0,0.162,1.6,0.45,31.19,42.8,376,19.651,8.932,0.988,27560
"Cheese, pasteurized process, pimento",Dairy and Egg Products,22.13,0.614,1.428,0.1,0.0023,0.162,1.73,0.62,31.2,39.08,375,19.663,8.937,0.988,27561
"Cheese, pasteurized process, swiss, low fat",Dairy and Egg Products,25.5,0.684,1.43,0,0,0.18,4.3,1.35,5.1,59.1,165,3.303,1.351,0.18,27562
"Cheese, pasteurized process, swiss, with di sodium phosphate",Dairy and Egg Products,24.73,0.772,1.37,0,0,0.216,2.1,1.23,25.01,42.31,334,16.045,7.046,0.622,27563
"Cheese, pasteurized process, swiss, without di sodium phosphate",Dairy and Egg Products,24.73,0.772,0.681,0,0,0.216,2.1,0,25.01,42.31,334,16.045,7.046,0.622,27564
"Cheese, port de salut",Dairy and Egg Products,23.78,0.65,0.534,0,0,0.136,0.57,0.57,28.2,45.45,352,16.691,9.338,0.729,27565
"Cheese, provolone",Dairy and Egg Products,25.58,0.756,0.876,0,0,0.138,2.14,0.56,26.62,40.95,351,17.078,7.393,0.769,27566
"Cheese, provolone, reduced fat",Dairy and Egg Products,24.7,0.756,0.876,0,0,0.138,3.5,0.55,17.6,50.6,274,11.3,4.89,0.51,27567
"Cheese, ricotta, part skim milk",Dairy and Egg Products,11.39,0.272,0.125,0,0,0.125,5.14,0.31,7.91,74.41,138,4.927,2.314,0.26,27568
"Cheese, ricotta, whole milk",Dairy and Egg Products,11.26,0.207,0.084,0,0,0.105,3.04,0.27,12.98,71.7,174,8.295,3.627,0.385,27569
"Cheese, romano",Dairy and Egg Products,31.8,1.064,1.2,0,0,0.086,3.63,0.73,26.94,30.91,387,17.115,7.838,0.593,27570
"Cheese, roquefort",Dairy and Egg Products,21.54,0.662,1.809,0,0,0.091,2,0,30.64,39.38,369,19.263,8.474,1.32,27571
"Cheese, swiss",Dairy and Egg Products,26.93,0.791,0.192,0,0,0.077,5.38,1.32,27.8,37.12,380,17.779,7.274,0.972,27572
"Cheese, swiss, low fat",Dairy and Egg Products,28.4,0.961,0.26,0,0,0.111,3.4,1.33,5.1,59.6,173,3.304,1.351,0.18,27573
"Cheese, swiss, low sodium",Dairy and Egg Products,28.4,0.961,0.014,0,0,0.111,3.4,1.31,27.4,37.8,374,17.744,7.256,0.968,27574
"Cheese, tilsit",Dairy and Egg Products,24.41,0.7,0.753,0,0,0.065,1.88,0,25.98,42.86,340,16.775,7.136,0.721,27575
"Cheese, white, queso blanco",Dairy and Egg Products,20.38,0.69,0.704,0,0,0.126,2.53,1.76,24.31,48.7,310,13.661,6.459,1.149,27576
"Cream substitute, flavored, liquid",Dairy and Egg Products,0.69,0.006,0.08,1.1,0,0.096,35.07,33.04,13.5,50.06,251,2.635,4.002,6.269,27577
"Cream substitute, flavored, powdered",Dairy and Egg Products,0.68,0.005,0.196,1.2,0,0.09,75.42,58.01,21.47,1.52,498,19.463,0.618,0.229,27578
"Cream substitute, liquid, light",Dairy and Egg Products,0.8,0.001,0.06,0,0,0.177,9.1,9.1,3.5,86.2,71,0.896,2.03,0.42,27579
"Cream substitute, liquid, with hydrogenated vegetable oil and soy protein",Dairy and Egg Products,1,0.009,0.079,0,0,0.191,11.38,11.38,9.97,77.27,136,1.937,7.551,0.027,27580
"Cream substitute, liquid, with lauric acid oil and sodium caseinate",Dairy and Egg Products,1,0.009,0.079,0,0,0.191,11.38,0,9.97,77.27,136,9.304,0.106,0.003,27581
"Cream substitute, powdered",Dairy and Egg Products,4.79,0.022,0.181,0,0,0.812,54.88,54.88,35.48,2.21,545,32.525,0.968,0.014,27582
"Cream substitute, powdered, light",Dairy and Egg Products,1.9,0.001,0.229,0,0,0.902,73.4,73.4,15.7,6.2,431,3.8,11.5,0.198,27583
"Cream, fluid, half and half",Dairy and Egg Products,2.96,0.105,0.041,0,0.0009,0.13,4.3,0.16,11.5,80.57,130,7.158,3.321,0.427,27584
"Cream, fluid, heavy whipping",Dairy and Egg Products,2.05,0.065,0.038,0,0.0006,0.075,2.79,0.11,37,57.71,345,23.032,10.686,1.374,27585
"Cream, fluid, light (coffee cream or table cream)",Dairy and Egg Products,2.7,0.096,0.04,0,0.0008,0.122,3.66,0.14,19.31,73.75,195,12.02,5.577,0.717,27586
"Cream, fluid, light whipping",Dairy and Egg Products,2.17,0.069,0.034,0,0.0006,0.097,2.96,0.11,30.91,63.5,292,19.337,9.093,0.884,27587
"Cream, half and half, fat free",Dairy and Egg Products,2.6,0.096,0.144,0,0.0007,0.206,9,5,1.4,86,59,0.841,0.384,0.052,27588
"Cream, sour, cultured",Dairy and Egg Products,2.07,0.11,0.08,0,0.0009,0.141,2.88,3.5,19.73,74.46,193,11.507,5.068,0.84,27589
"Cream, sour, reduced fat, cultured",Dairy and Egg Products,2.94,0.104,0.041,0,0.0009,0.129,4.26,0.16,12,80.14,135,7.47,3.466,0.446,27590
"Cream, whipped, cream topping, pressurized",Dairy and Egg Products,3.2,0.101,0.13,0,0,0.147,12.49,8,22.22,61.33,257,13.831,6.418,0.825,27591
"Dessert topping, powdered",Dairy and Egg Products,4.9,0.017,0.122,0,0,0.166,52.54,52.54,39.92,1.47,577,36.723,0.6,0.447,27592
"Dessert topping, powdered, 1.5 ounce prepared with 1/2 cup milk",Dairy and Egg Products,3.61,0.09,0.066,0,0.0007,0.151,17.13,17.47,12.72,65.74,194,10.684,0.843,0.201,27593
"Dessert topping, pressurized",Dairy and Egg Products,0.98,0.005,0.062,0,0,0.019,16.07,16.07,22.3,60.37,264,18.912,1.927,0.241,27594
"Dessert topping, semi solid, frozen",Dairy and Egg Products,1.25,0.006,0.025,0,0,0.018,23.05,23.05,25.31,50.21,318,21.783,1.616,0.523,27595
Dulce de Leche,Dairy and Egg Products,6.84,0.251,0.129,0,0,0.35,55.35,49.74,7.35,28.71,315,4.534,2.143,0.375,27596
"Egg Mix, USDA Commodity",Dairy and Egg Products,35.6,0.171,0.576,0,0,0.373,23.97,2.46,34.5,2.78,555,10.305,13.745,7.555,27597
"Egg substitute, liquid or frozen, fat free",Dairy and Egg Products,10,0.073,0.199,0,0.0005,0.213,2,2,0,87,48,0,0,0,27598
"Egg substitute, powder",Dairy and Egg Products,55.5,0.326,0.8,0,0.0008,0.744,21.8,21.8,13,3.86,444,3.766,5.341,1.683,27599
"Egg, duck, whole, fresh, raw",Dairy and Egg Products,12.81,0.064,0.146,0,0,0.222,1.45,0.93,13.77,70.83,185,3.681,6.525,1.223,27600
"Egg, goose, whole, fresh, raw",Dairy and Egg Products,13.87,0.06,0.138,0,0,0.21,1.35,0.94,13.27,70.43,185,3.595,5.747,1.672,27601
"Egg, quail, whole, fresh, raw",Dairy and Egg Products,13.05,0.064,0.141,0,0,0.132,0.41,0.4,11.09,74.35,158,3.557,4.324,1.324,27602
"Egg, turkey, whole, fresh, raw",Dairy and Egg Products,13.68,0.099,0.151,0,0,0.142,1.15,0,11.88,72.5,171,3.632,4.571,1.658,27603
"Egg, white, dried",Dairy and Egg Products,81.1,0.062,1.28,0,0,1.125,7.8,5.4,0,5.8,382,0,0,0,27604
"Egg, white, dried, flakes, glucose reduced",Dairy and Egg Products,76.92,0.083,1.156,0,0,1.042,4.17,4.17,0.04,14.62,351,0,0,0,27605
"Egg, white, dried, powder, glucose reduced",Dairy and Egg Products,82.4,0.089,1.238,0,0,1.116,4.47,4.47,0.04,8.54,376,0,0,0,27606
"Egg, white, raw, fresh",Dairy and Egg Products,10.9,0.007,0.166,0,0,0.163,0.73,0.71,0.17,87.57,52,0,0,0,27607
"Egg, white, raw, frozen",Dairy and Egg Products,9.8,0.007,0.158,0,0,0.136,1.05,0,0,88.55,47,0,0,0,27608
"Egg, whole, cooked, fried",Dairy and Egg Products,13.61,0.062,0.207,0,0,0.152,0.83,0.4,14.84,69.47,196,4.323,6.182,3.251,27609
"Egg, whole, cooked, hard-boiled",Dairy and Egg Products,12.58,0.05,0.124,0,0,0.126,1.12,1.12,10.61,74.62,155,3.267,4.077,1.414,27610
"Egg, whole, cooked, omelet",Dairy and Egg Products,10.57,0.048,0.155,0,0,0.117,0.64,0.31,11.66,76.13,154,3.319,4.843,2.712,27611
"Egg, whole, cooked, poached",Dairy and Egg Products,12.51,0.056,0.297,0,0,0.138,0.71,0.37,9.47,75.85,138,3.113,3.643,1.904,27612
"Egg, whole, cooked, scrambled",Dairy and Egg Products,9.99,0.066,0.145,0,0,0.132,1.61,1.43,10.98,76.4,149,3.331,4.441,2.429,27613
"Egg, whole, dried",Dairy and Egg Products,47.35,0.231,0.523,0,0,0.493,4.95,3.08,40.95,3.1,594,12.727,15.337,5.804,27614
"Egg, whole, dried, stabilized, glucose reduced",Dairy and Egg Products,48.17,0.222,0.548,0,0,0.515,2.38,0,43.95,1.87,615,13.198,17.564,5.713,27615
"Egg, whole, raw, fresh",Dairy and Egg Products,12.56,0.056,0.142,0,0,0.138,0.72,0.37,9.51,76.15,143,3.126,3.658,1.911,27616
"Egg, whole, raw, frozen",Dairy and Egg Products,11.95,0.059,0.133,0,0,0.13,1.05,0,10.2,75.85,148,3.147,3.886,1.412,27617
"Egg, yolk, dried",Dairy and Egg Products,34.25,0.284,0.135,0,0,0.244,3.6,3.6,55.8,2.95,666,17.154,21.129,7.895,27618
"Egg, yolk, raw, fresh",Dairy and Egg Products,15.86,0.129,0.048,0,0,0.109,3.59,0.56,26.54,52.31,322,9.551,11.738,4.204,27619
"Egg, yolk, raw, frozen",Dairy and Egg Products,15.5,0.138,0.067,0,0,0.118,1.15,0.51,25.6,56.2,303,7.82,9.747,3.628,27620
"Egg, yolk, raw, frozen, salted",Dairy and Egg Products,14,0.114,3.78,0,0,0.117,1.6,0,23,50.8,274,7.028,8.849,3.15,27621
"Egg, yolk, raw, frozen, sugared",Dairy and Egg Products,13.8,0.123,0.067,0,0,0.103,10.8,0,22.75,51.25,307,6.97,8.614,3.244,27622
Eggnog,Dairy and Egg Products,4.55,0.13,0.054,0,0.0015,0.165,8.05,8.41,4.19,82.54,88,2.591,1.302,0.198,27623
"Eggs, scrambled, frozen mixture",Dairy and Egg Products,13.1,0.017,0.162,0,0,0.147,7.5,7.5,5.6,72.7,135,1.052,2.339,1.778,27624
"Ensure plus, liquid nutrition",Dairy and Egg Products,5.16,0.079,0.095,0,0.0119,0.175,19.88,19.88,4.52,69.84,141,0.673,1.283,3.111,27625
"Imitation cheese, american or cheddar, low cholesterol",Dairy and Egg Products,25,0.705,0.67,0,0,0.055,1,1,32,38,390,5.815,14.127,10.615,27626
KRAFT BREAKSTONE'S FREE Fat Free Sour Cream,Dairy and Egg Products,4.7,0.141,0.072,0,0.0012,0.219,15.1,7.2,1.3,77.7,91,0.8,0,0,27627
KRAFT BREAKSTONE'S Reduced Fat Sour Cream,Dairy and Egg Products,4.5,0.161,0.059,0.1,0.0011,0.21,6.5,6.4,12,76.2,152,7.6,0,0,27628
KRAFT BREYERS LIGHT N' LIVELY Lowfat Strawberry Yogurt (1% Milkfat),Dairy and Egg Products,3.2,0.091,0.045,0.2,0,0.151,21.9,19.6,0.8,73.3,108,0.5,0,0,27629
KRAFT BREYERS LIGHT Nonfat Strawberry Yogurt (with Aspartame and Fructose Sweeteners),Dairy and Egg Products,3.4,0.095,0.045,0,0.0006,0.146,9.9,7.7,0.2,86,55,0.1,0,0,27630
KRAFT BREYERS Lowfat Strawberry Yogurt (1% Milkfat),Dairy and Egg Products,3.8,0.125,0.052,0.2,0,0.192,18.2,17.4,0.8,76.5,96,0.5,0,0,27631
KRAFT BREYERS Smooth & Creamy Lowfat Strawberry Yogurt (1% Milkfat),Dairy and Egg Products,3.8,0.108,0.055,0.3,0,0.177,19.9,17.2,0.9,74.8,102,0.5,0,0,27632
KRAFT CHEEZ WHIZ LIGHT Pasteurized Process Cheese Product,Dairy and Egg Products,16.3,0.418,1.705,0.2,0.0004,0.297,16.2,8.2,9.5,51.5,215,6.4,0,0,27633
KRAFT CHEEZ WHIZ Pasteurized Process Cheese Sauce,Dairy and Egg Products,12,0.359,1.638,0.3,0.0004,0.24,9.2,6.7,21,51.5,276,13.1,0,0,27634
KRAFT FREE Singles American Nonfat Pasteurized Process Cheese Product,Dairy and Egg Products,22.7,0.712,1.298,0.2,0.0002,0.236,11.7,6.7,1,58,148,0.7,0,0,27635
KRAFT VELVEETA LIGHT Reduced Fat Pasteurized Process Cheese Product,Dairy and Egg Products,19.6,0.574,1.586,0,0.0001,0.345,11.8,8.5,10.6,51.3,222,7.1,0,0,27636
KRAFT VELVEETA Pasteurized Process Cheese Spread,Dairy and Egg Products,16.3,0.466,1.499,0,0.0002,0.335,9.8,8.1,22,45.8,303,14.4,0,0,27637
"Milk shakes, thick chocolate",Dairy and Egg Products,3.05,0.132,0.111,0.3,0,0.224,21.15,20.85,2.7,72.2,119,1.681,0.78,0.1,27638
"Milk shakes, thick vanilla",Dairy and Egg Products,3.86,0.146,0.095,0,0,0.183,17.75,17.75,3.03,74.45,112,1.886,0.875,0.113,27639
"Milk substitutes, fluid, with lauric acid oil",Dairy and Egg Products,1.75,0.033,0.078,0,0,0.114,6.16,0,3.41,88.18,61,3.037,0.176,0.008,27640
"Milk, buttermilk, dried",Dairy and Egg Products,34.3,1.184,0.517,0,0.0057,1.592,49,49,5.78,2.97,387,3.598,1.669,0.215,27641
"Milk, buttermilk, fluid, cultured, lowfat",Dairy and Egg Products,3.31,0.116,0.105,0,0.001,0.151,4.79,4.79,0.88,90.13,40,0.548,0.254,0.033,27642
"Milk, buttermilk, fluid, cultured, reduced fat",Dairy and Egg Products,4.1,0.143,0.086,0,0.0015,0.18,5.3,5.3,2,87.7,56,1.242,0.576,0.071,27643
"Milk, canned, condensed, sweetened",Dairy and Egg Products,7.91,0.284,0.127,0,0.0026,0.371,54.4,54.4,8.7,27.16,321,5.486,2.427,0.337,27644
"Milk, canned, evaporated, nonfat, with added vitamin A and vitamin D",Dairy and Egg Products,7.55,0.29,0.115,0,0.0012,0.332,11.35,11.35,0.2,79.4,78,0.121,0.062,0.006,27645
"Milk, canned, evaporated, with added vitamin A",Dairy and Egg Products,6.81,0.261,0.106,0,0.0019,0.303,10.04,0,7.56,74.04,134,4.591,2.335,0.245,27646
"Milk, canned, evaporated, with added vitamin D and without added vitamin A",Dairy and Egg Products,6.81,0.261,0.106,0,0.0019,0.303,10.04,10.04,7.56,74.04,134,4.591,2.335,0.245,27647
"Milk, canned, evaporated, without added vitamin A and vitamin D",Dairy and Egg Products,6.81,0.261,0.106,0,0.0019,0.303,10.04,10.04,7.56,74.04,135,4.591,2.335,0.245,27648
"Milk, chocolate beverage, hot cocoa, homemade",Dairy and Egg Products,3.52,0.114,0.044,1,0.0002,0.197,10.74,9.66,2.34,82.45,77,1.431,0.677,0.084,27649
"Milk, chocolate, fluid, commercial, lowfat, with added vitamin A and vitamin D",Dairy and Egg Products,3.24,0.116,0.061,0.5,0.0009,0.17,10.44,9.94,1,82.34,63,0.616,0.3,0.035,27650
"Milk, chocolate, fluid, commercial, reduced fat, with added calcium",Dairy and Egg Products,2.99,0.194,0.066,0.7,0,0.123,12.13,9.55,1.9,82.17,78,1.177,0.455,0.089,27651
"Milk, chocolate, fluid, commercial, reduced fat, with added vitamin A and vitamin D",Dairy and Egg Products,2.99,0.109,0.066,0.7,0,0.169,12.13,9.55,1.9,82.17,76,1.177,0.455,0.089,27652
"Milk, chocolate, fluid, commercial, whole, with added vitamin A and vitamin D",Dairy and Egg Products,3.17,0.112,0.06,0.8,0.0009,0.167,10.34,9.54,3.39,82.3,83,2.104,0.99,0.124,27653
"Milk, dry, nonfat, calcium reduced",Dairy and Egg Products,35.5,0.28,2.28,0,0.0067,0.68,51.8,0,0.2,4.9,354,0.124,0.058,0.007,27654
"Milk, dry, nonfat, instant, with added vitamin A and vitamin D",Dairy and Egg Products,35.1,1.231,0.549,0,0.0056,1.705,52.19,52.19,0.72,3.96,358,0.467,0.187,0.028,27655
"Milk, dry, nonfat, instant, without added vitamin A and vitamin D",Dairy and Egg Products,35.1,1.231,0.549,0,0.0056,1.705,52.19,52.19,0.72,3.96,358,0.47,0.19,0.03,27656
"Milk, dry, nonfat, regular, with added vitamin A and vitamin D",Dairy and Egg Products,36.16,1.257,0.535,0,0.0068,1.794,51.98,51.98,0.77,3.16,362,0.499,0.2,0.03,27657
"Milk, dry, nonfat, regular, without added vitamin A and vitamin D",Dairy and Egg Products,36.16,1.257,0.535,0,0.0068,1.794,51.98,51.98,0.77,3.16,362,0.499,0.201,0.03,27658
"Milk, dry, whole, with added vitamin D",Dairy and Egg Products,26.32,0.912,0.371,0,0.0086,1.33,38.42,38.42,26.71,2.47,496,16.742,7.924,0.665,27659
"Milk, dry, whole, without added vitamin D",Dairy and Egg Products,26.32,0.912,0.371,0,0.0086,1.33,38.42,38.42,26.71,2.47,496,16.742,7.924,0.665,27660
"Milk, filled, fluid, with blend of hydrogenated vegetable oils",Dairy and Egg Products,3.33,0.128,0.057,0,0.0009,0.139,4.74,0,3.46,87.67,63,0.768,1.783,0.75,27661
"Milk, filled, fluid, with lauric acid oil",Dairy and Egg Products,3.33,0.128,0.057,0,0.0009,0.139,4.74,4.74,3.4,87.73,63,3.101,0.1,0.01,27662
"Milk, fluid, 1% fat, without added vitamin A and vitamin D",Dairy and Egg Products,3.37,0.125,0.044,0,0,0.15,4.99,5.2,0.97,89.92,42,0.633,0.277,0.035,27663
"Milk, fluid, nonfat, calcium fortified (fat free or skim)",Dairy and Egg Products,3.4,0.204,0.052,0,0.001,0.166,4.85,4.85,0.18,90.8,35,0.117,0.047,0.007,27664
"Milk, goat, fluid, with added vitamin D",Dairy and Egg Products,3.56,0.134,0.05,0,0.0013,0.204,4.45,4.45,4.14,87.03,69,2.667,1.109,0.149,27665
"Milk, human, mature, fluid",Dairy and Egg Products,1.03,0.032,0.017,0,0.005,0.051,6.89,6.89,4.38,87.5,70,2.009,1.658,0.497,27666
"Milk, imitation, non-soy",Dairy and Egg Products,1.6,0.082,0.055,0,0,0.15,5.3,5.3,2,90,46,0.254,0.484,1.174,27667
"Milk, indian buffalo, fluid",Dairy and Egg Products,3.75,0.169,0.052,0,0.0023,0.178,5.18,0,6.89,83.39,97,4.597,1.787,0.146,27668
"Milk, low sodium, fluid",Dairy and Egg Products,3.1,0.101,0.003,0,0.0009,0.253,4.46,4.46,3.46,88.2,61,2.154,0.999,0.128,27669
"Milk, lowfat, fluid, 1% milkfat, protein fortified, with added vitamin A and vitamin D",Dairy and Egg Products,3.93,0.142,0.058,0,0.0012,0.18,5.52,0,1.17,88.74,48,0.728,0.338,0.043,27670
"Milk, lowfat, fluid, 1% milkfat, with added nonfat milk solids, vitamin A and vitamin D",Dairy and Egg Products,3.48,0.128,0.052,0,0.001,0.162,4.97,0,0.97,89.81,43,0.604,0.28,0.036,27671
"Milk, lowfat, fluid, 1% milkfat, with added vitamin A and vitamin D",Dairy and Egg Products,3.37,0.125,0.044,0,0,0.15,4.99,5.2,0.97,89.92,42,0.633,0.277,0.035,27672
"Milk, nonfat, fluid, protein fortified, with added vitamin A and vitamin D (fat free and skim)",Dairy and Egg Products,3.96,0.143,0.059,0,0.0011,0.182,5.56,0,0.25,89.36,41,0.162,0.065,0.009,27673
"Milk, nonfat, fluid, with added nonfat milk solids, vitamin A and vitamin D (fat free or skim)",Dairy and Egg Products,3.57,0.129,0.053,0,0.001,0.171,5.02,5.02,0.25,90.38,37,0.162,0.065,0.009,27674
"Milk, nonfat, fluid, with added vitamin A and vitamin D (fat free or skim)",Dairy and Egg Products,3.37,0.122,0.042,0,0,0.156,4.96,5.09,0.08,90.84,34,0.056,0.022,0.003,27675
"Milk, nonfat, fluid, without added vitamin A and vitamin D (fat free or skim)",Dairy and Egg Products,3.37,0.122,0.042,0,0,0.156,4.96,5.09,0.08,90.84,34,0.051,0.021,0.003,27676
"Milk, producer, fluid, 3.7% milkfat",Dairy and Egg Products,3.28,0.119,0.049,0,0.0015,0.151,4.65,0,3.66,87.69,64,2.278,1.057,0.136,27677
"Milk, reduced fat, fluid, 2% milkfat, protein fortified, with added vitamin A and vitamin D",Dairy and Egg Products,3.95,0.143,0.059,0,0.0011,0.182,5.49,5.26,1.98,87.71,56,1.232,0.572,0.074,27678
"Milk, reduced fat, fluid, 2% milkfat, with added nonfat milk solids and vitamin A and vitamin D",Dairy and Egg Products,3.48,0.128,0.052,0,0.001,0.162,4.97,0,1.92,88.86,51,1.195,0.555,0.071,27679
"Milk, reduced fat, fluid, 2% milkfat, with added nonfat milk solids, without added vitamin A",Dairy and Egg Products,3.95,0.143,0.059,0,0.0011,0.182,5.49,0,1.98,87.71,56,1.232,0.065,0.009,27680
"Milk, reduced fat, fluid, 2% milkfat, with added vitamin A and vitamin D",Dairy and Egg Products,3.3,0.12,0.047,0,0.0002,0.14,4.8,5.06,1.98,89.21,50,1.257,0.56,0.073,27681
"Milk, reduced fat, fluid, 2% milkfat, without added vitamin A and vitamin D",Dairy and Egg Products,3.3,0.12,0.047,0,0.0002,0.14,4.8,5.06,1.98,89.21,50,1.257,0.56,0.073,27682
"Milk, sheep, fluid",Dairy and Egg Products,5.98,0.193,0.044,0,0.004200000000000001,0.137,5.36,0,7,80.7,108,4.603,1.724,0.308,27683
"Milk, whole, 3.25% milkfat, with added vitamin D",Dairy and Egg Products,3.15,0.113,0.043,0,0,0.132,4.8,5.05,3.25,88.13,61,1.865,0.812,0.195,27684
"Milk, whole, 3.25% milkfat, without added vitamin A and vitamin D",Dairy and Egg Products,3.15,0.113,0.043,0,0,0.132,4.78,5.05,3.27,88.13,61,1.865,0.812,0.195,27685
"Parmesan cheese topping, fat free",Dairy and Egg Products,40,0.8,1.15,0,0,0.6,40,1.5,5,8.6,370,3.11,1.446,0.186,27686
"Protein supplement, milk based, Muscle Milk Light, powder",Dairy and Egg Products,50,1.2,0.25,2,0.042,0.84,22,4,12,12,396,1.087,8.474,1.239,27687
"Protein supplement, milk based, Muscle Milk, powder",Dairy and Egg Products,45.71,0.5,0.329,7.1,0.03,1.129,18.5,5.71,17.14,15,411,1.553,12.105,1.77,27688
Reddi Wip Fat Free Whipped Topping,Dairy and Egg Products,3,0.108,0.072,0.4,0,0.108,25,16,5,66.44,149,2.869,1.25,0.299,27689
"Sour cream, fat free",Dairy and Egg Products,3.1,0.125,0.141,0,0,0.129,15.6,0.39,0,80.6,74,0,0,0,27690
"Sour cream, imitation, cultured",Dairy and Egg Products,2.4,0.003,0.102,0,0,0.161,6.63,6.63,19.52,71.15,208,17.791,0.588,0.056,27691
"Sour cream, light",Dairy and Egg Products,3.5,0.141,0.071,0,0.0009,0.212,7.1,0.22,10.6,78.1,138,6.6,3.1,0.4,27692
"Sour cream, reduced fat",Dairy and Egg Products,7,0.141,0.07,0,0.0009,0.211,7,0.3,14.1,71,181,8.7,4.1,0.5,27693
"Sour dressing, non-butterfat, cultured, filled cream-type",Dairy and Egg Products,3.25,0.113,0.048,0,0.0009,0.162,4.68,4.68,16.57,74.79,178,13.272,1.958,0.468,27694
"USDA Commodity, cheese, cheddar, reduced fat",Dairy and Egg Products,27.2,0.905,0.725,0,0,0.093,2,0.58,18.3,48.2,282,11.58,5.02,0.75,27695
"Whey, acid, dried",Dairy and Egg Products,11.73,2.054,0.968,0,0.0009,2.289,73.45,73.45,0.54,3.51,339,0.342,0.149,0.021,27696
"Whey, acid, fluid",Dairy and Egg Products,0.76,0.103,0.048,0,0.0001,0.143,5.12,5.12,0.09,93.42,24,0.057,0.025,0.004,27697
"Whey, sweet, dried",Dairy and Egg Products,12.93,0.796,1.079,0,0.0015,2.08,74.46,74.46,1.07,3.19,353,0.684,0.297,0.034,27698
"Whey, sweet, fluid",Dairy and Egg Products,0.85,0.047,0.054,0,0.0001,0.161,5.14,5.14,0.36,93.12,27,0.23,0.1,0.011,27699
"Whipped cream substitute, dietetic, made from powdered mix",Dairy and Egg Products,0.9,0.003,0.106,0,0,0.026,10.6,10.6,6,82.3,100,3.186,1.357,1.158,27700
"Whipped topping, frozen, low fat",Dairy and Egg Products,3,0.071,0.072,0,0,0.101,23.6,23.6,13.1,59.7,224,11.273,0.838,0.269,27701
"Yogurt, chocolate, nonfat milk",Dairy and Egg Products,3.53,0.088,0.135,1.2,0,0.339,23.53,14.97,0,71.57,112,0,0,0,27702
"Yogurt, chocolate, nonfat milk, fortified with vitamin D",Dairy and Egg Products,3.53,0.088,0.135,1.2,0,0.339,23.53,14.97,0,71.57,112,0,0,0,27703
"Yogurt, fruit variety, nonfat",Dairy and Egg Products,4.4,0.152,0.058,0,0.0007,0.194,19,19,0.2,75.4,95,0.119,0.05,0.016,27704
"Yogurt, fruit variety, nonfat, fortified with vitamin D",Dairy and Egg Products,4.4,0.152,0.058,0,0.0007,0.194,19,19,0.2,75.4,95,0.119,0.05,0.016,27705
"Yogurt, fruit, low fat, 10 grams protein per 8 ounce",Dairy and Egg Products,4.37,0.152,0.058,0,0.0007,0.195,19.05,19.05,1.08,74.48,102,0.697,0.297,0.031,27706
"Yogurt, fruit, low fat, 10 grams protein per 8 ounce, fortified with vitamin D",Dairy and Egg Products,4.37,0.152,0.058,0,0.0007,0.195,19.05,19.05,1.08,74.48,102,0.697,0.297,0.031,27707
"Yogurt, fruit, low fat, 11 grams protein per 8 ounce",Dairy and Egg Products,4.86,0.169,0.065,0,0.0007,0.216,18.6,0,1.41,74.1,105,0.909,0.387,0.04,27708
"Yogurt, fruit, low fat, 9 grams protein per 8 ounce",Dairy and Egg Products,3.98,0.138,0.053,0,0.0006,0.177,18.64,18.64,1.15,75.3,99,0.742,0.316,0.033,27709
"Yogurt, fruit, low fat, 9 grams protein per 8 ounce, fortified with vitamin D",Dairy and Egg Products,3.98,0.138,0.053,0,0.0006,0.177,18.64,18.64,1.15,75.3,99,0.742,0.316,0.033,27710
"Yogurt, fruit, lowfat, with low calorie sweetener",Dairy and Egg Products,4.86,0.152,0.058,0,0.0007,0.194,18.6,2.9,1.41,74.1,105,0.909,0.387,0.04,27711
"Yogurt, fruit, lowfat, with low calorie sweetener, fortified with vitamin D",Dairy and Egg Products,4.86,0.152,0.058,0,0.0007,0.194,18.6,2.9,1.41,74.1,105,0.909,0.387,0.04,27712
"Yogurt, plain, low fat, 12 grams protein per 8 ounce",Dairy and Egg Products,5.25,0.183,0.07,0,0.0008,0.234,7.04,7.04,1.55,85.07,63,1,0.426,0.044,27713
"Yogurt, plain, skim milk, 13 grams protein per 8 ounce",Dairy and Egg Products,5.73,0.199,0.077,0,0.0009,0.255,7.68,7.68,0.18,85.23,56,0.116,0.049,0.005,27714
"Yogurt, plain, whole milk, 8 grams protein per 8 ounce",Dairy and Egg Products,3.47,0.121,0.046,0,0.0005,0.155,4.66,4.66,3.25,87.9,61,2.096,0.893,0.092,27715
"Yogurt, vanilla or lemon flavor, nonfat milk, sweetened with low-calorie sweetener",Dairy and Egg Products,3.86,0.143,0.059,0,0.0011,0.177,7.5,7.5,0.18,87.43,43,0.116,0.049,0.005,27716
"Yogurt, vanilla or lemon flavor, nonfat milk, sweetened with low-calorie sweetener, fortified with vitamin D",Dairy and Egg Products,3.86,0.143,0.059,0,0.0011,0.177,7.5,7.5,0.18,87.43,43,0.116,0.049,0.005,27717
"Yogurt, vanilla, low fat, 11 grams protein per 8 ounce",Dairy and Egg Products,4.93,0.171,0.066,0,0.0008,0.219,13.8,13.8,1.25,79,85,0.806,0.343,0.036,27718
"Yogurt, vanilla, low fat, 11 grams protein per 8 ounce, fortified with vitamin D",Dairy and Egg Products,4.93,0.171,0.066,0,0.0008,0.219,13.8,13.8,1.25,79,85,0.806,0.343,0.036,27719
"Basil, fresh",Spices and Herbs,3.15,0.177,0.004,1.6,0.018,0.295,2.65,0.3,0.64,92.06,23,0.041,0.088,0.389,27720
"CAMPBELL Soup Company, PACE, Dry Taco Seasoning Mix",Spices and Herbs,0,0,8.068,18.8,0.045,0,56.29,18.76,0,5.7,188,0,0,0,27721
"Capers, canned",Spices and Herbs,2.36,0.04,2.964,3.2,0.0043,0.04,4.89,0.41,0.86,83.85,23,0.233,0.063,0.304,27722
"Dill weed, fresh",Spices and Herbs,3.46,0.208,0.061,2.1,0.085,0.738,7.02,0,1.12,85.95,43,0.06,0.802,0.095,27723
"Horseradish, prepared",Spices and Herbs,1.18,0.056,0.314,3.3,0.0249,0.246,11.29,7.99,0.69,85.08,48,0.09,0.13,0.339,27724
"Mustard, prepared, yellow",Spices and Herbs,4.37,0.058,1.135,3.3,0.0015,0.138,5.33,0.86,4.01,82.65,67,0.248,2.627,0.954,27725
"Peppermint, fresh",Spices and Herbs,3.75,0.243,0.031,8,0.0318,0.569,14.89,0,0.94,78.65,70,0.246,0.033,0.508,27726
"Rosemary, fresh",Spices and Herbs,3.31,0.317,0.026,14.1,0.0218,0.668,20.7,0,5.86,67.77,131,2.838,1.16,0.901,27727
"Salt, table",Spices and Herbs,0,0.024,38.758,0,0,0.008,0,0,0,0.2,0,0,0,0,27728
"Spearmint, dried",Spices and Herbs,19.93,1.488,0.344,29.8,0,1.924,52.04,0,6.03,11.3,285,1.577,0.21,3.257,27729
"Spearmint, fresh",Spices and Herbs,3.29,0.199,0.03,6.8,0.013300000000000001,0.458,8.41,0,0.73,85.55,44,0.191,0.025,0.394,27730
"Spices, allspice, ground",Spices and Herbs,6.09,0.661,0.077,21.6,0.039200000000000006,1.044,72.12,0,8.69,8.46,263,2.55,0.66,2.36,27731
"Spices, anise seed",Spices and Herbs,17.6,0.646,0.016,14.6,0.021,1.441,50.02,0,15.9,9.54,337,0.586,9.78,3.15,27732
"Spices, basil, dried",Spices and Herbs,22.98,2.24,0.076,37.7,0.0008,2.63,47.75,1.71,4.07,10.35,233,2.157,1.238,0.498,27733
"Spices, bay leaf",Spices and Herbs,7.61,0.834,0.023,26.3,0.0465,0.529,74.97,0,8.36,5.44,313,2.28,1.64,2.29,27734
"Spices, caraway seed",Spices and Herbs,19.77,0.689,0.017,38,0.021,1.351,49.9,0.64,14.59,9.87,333,0.62,7.125,3.272,27735
"Spices, cardamom",Spices and Herbs,10.76,0.383,0.018,28,0.021,1.119,68.47,0,6.7,8.28,311,0.68,0.87,0.43,27736
"Spices, celery seed",Spices and Herbs,18.07,1.767,0.16,11.8,0.0171,1.4,41.35,0.67,25.27,6.04,392,2.19,15.93,3.72,27737
"Spices, chervil, dried",Spices and Herbs,23.2,1.346,0.083,11.3,0.05,4.74,49.1,0,3.9,7.2,237,0.169,1.399,1.8,27738
"Spices, chili powder",Spices and Herbs,13.46,0.33,1.64,34.8,0.0007,1.95,49.7,7.19,14.28,10.75,282,2.462,3.211,8.006,27739
"Spices, cinnamon, ground",Spices and Herbs,3.99,1.002,0.01,53.1,0.0038,0.431,80.59,2.17,1.24,10.58,247,0.345,0.246,0.068,27740
"Spices, cloves, ground",Spices and Herbs,5.98,0.646,0.243,34.2,0.0808,1.102,61.21,2.38,20.07,6.86,323,5.438,1.471,7.088,27741
"Spices, coriander leaf, dried",Spices and Herbs,21.93,1.246,0.211,10.4,0.5667000000000001,4.466,52.1,7.27,4.78,7.3,279,0.115,2.232,0.328,27742
"Spices, coriander seed",Spices and Herbs,12.37,0.709,0.035,41.9,0.021,1.267,54.99,0,17.77,8.86,298,0.99,13.58,1.75,27743
"Spices, cumin seed",Spices and Herbs,17.81,0.931,0.168,10.5,0.0077,1.788,44.24,2.25,22.27,8.06,375,1.535,14.04,3.279,27744
"Spices, curry powder",Spices and Herbs,12.66,0.478,0.052,33.2,0.0114,1.543,58.15,2.76,13.81,9.52,325,2.237,5.551,2.557,27745
"Spices, dill seed",Spices and Herbs,15.98,1.516,0.02,21.1,0.021,1.186,55.17,0,14.54,7.7,305,0.73,9.41,1.01,27746
"Spices, dill weed, dried",Spices and Herbs,19.96,1.784,0.208,13.6,0.05,3.308,55.82,0,4.36,7.3,253,0.234,0,0,27747
"Spices, fennel seed",Spices and Herbs,15.8,1.196,0.088,39.8,0.021,1.694,52.29,0,14.87,8.81,345,0.48,9.91,1.69,27748
"Spices, fenugreek seed",Spices and Herbs,23,0.176,0.067,24.6,0.003,0.77,58.35,0,6.41,8.84,323,1.46,0,0,27749
"Spices, garlic powder",Spices and Herbs,16.55,0.079,0.06,9,0.0012,1.193,72.73,2.43,0.73,6.45,331,0.249,0.115,0.178,27750
"Spices, ginger, ground",Spices and Herbs,8.98,0.114,0.027,14.1,0.0007,1.32,71.62,3.39,4.24,9.94,335,2.599,0.479,0.929,27751
"Spices, mace, ground",Spices and Herbs,6.71,0.252,0.08,20.2,0.021,0.463,50.5,0,32.38,8.17,475,9.51,11.17,4.39,27752
"Spices, marjoram, dried",Spices and Herbs,12.66,1.99,0.077,40.3,0.0514,1.522,60.56,4.09,7.04,7.64,271,0.529,0.94,4.405,27753
"Spices, mustard seed, ground",Spices and Herbs,26.08,0.266,0.013,12.2,0.0070999999999999995,0.738,28.09,6.79,36.24,5.27,508,1.989,22.518,10.088,27754
"Spices, nutmeg, ground",Spices and Herbs,5.84,0.184,0.016,20.8,0.003,0.35,49.29,28.49,36.31,6.23,525,25.94,3.22,0.35,27755
"Spices, onion powder",Spices and Herbs,10.41,0.384,0.073,15.2,0.023399999999999997,0.985,79.12,6.63,1.04,5.39,341,0.219,0.202,0.31,27756
"Spices, oregano, dried",Spices and Herbs,9,1.597,0.025,42.5,0.0023,1.26,68.92,4.09,4.28,9.93,265,1.551,0.716,1.369,27757
"Spices, paprika",Spices and Herbs,14.14,0.229,0.068,34.9,0.0009,2.28,53.99,10.34,12.89,11.24,282,2.14,1.695,7.766,27758
"Spices, parsley, dried",Spices and Herbs,26.63,1.14,0.452,26.7,0.125,2.683,50.64,7.27,5.48,5.89,292,0.774,0.373,4.116,27759
"Spices, pepper, black",Spices and Herbs,10.39,0.443,0.02,25.3,0,1.329,63.95,0.64,3.26,12.46,251,1.392,0.739,0.998,27760
"Spices, pepper, red or cayenne",Spices and Herbs,12.01,0.148,0.03,27.2,0.07640000000000001,2.014,56.63,10.34,17.27,8.05,318,3.26,2.75,8.37,27761
"Spices, pepper, white",Spices and Herbs,10.4,0.265,0.005,26.2,0.021,0.073,68.61,0,2.12,11.42,296,0.626,0.789,0.616,27762
"Spices, poppy seed",Spices and Herbs,17.99,1.438,0.026,19.5,0.001,0.719,28.13,2.99,41.56,5.95,525,4.517,5.982,28.569,27763
"Spices, poultry seasoning",Spices and Herbs,9.59,0.996,0.027,11.3,0.012,0.684,65.59,3.08,7.53,9.31,307,3.29,1.206,1.936,27764
"Spices, pumpkin pie spice",Spices and Herbs,5.76,0.682,0.052,14.8,0.023399999999999997,0.663,69.28,7.76,12.6,8.46,342,6.53,1.102,0.78,27765
"Spices, rosemary, dried",Spices and Herbs,4.88,1.28,0.05,42.6,0.061200000000000004,0.955,64.06,0,15.22,9.31,331,7.371,3.014,2.339,27766
"Spices, saffron",Spices and Herbs,11.43,0.111,0.148,3.9,0.0808,1.724,65.37,0,5.85,11.9,310,1.586,0.429,2.067,27767
"Spices, sage, ground",Spices and Herbs,10.63,1.652,0.011,40.3,0.0324,1.07,60.73,1.71,12.75,7.96,315,7.03,1.87,1.76,27768
"Spices, savory, ground",Spices and Herbs,6.73,2.132,0.024,45.7,0.05,1.051,68.73,0,5.91,9,272,3.26,0,0,27769
"Spices, tarragon, dried",Spices and Herbs,22.77,1.139,0.062,7.4,0.05,3.02,50.22,0,7.24,7.74,295,1.881,0.474,3.698,27770
"Spices, thyme, dried",Spices and Herbs,9.11,1.89,0.055,37,0.05,0.814,63.94,1.71,7.43,7.79,276,2.73,0.47,1.19,27771
"Spices, turmeric, ground",Spices and Herbs,7.83,0.183,0.038,21.1,0.0259,2.525,64.93,3.21,9.88,11.36,354,3.12,1.66,2.18,27772
"Thyme, fresh",Spices and Herbs,5.56,0.405,0.009,14,0.1601,0.609,24.45,0,1.68,65.11,101,0.467,0.081,0.532,27773
Vanilla extract,Spices and Herbs,0.06,0.011,0.009,0,0,0.148,12.65,12.65,0.06,52.58,288,0.01,0.01,0.004,27774
"Vanilla extract, imitation, alcohol",Spices and Herbs,0.05,0,0.004,0,0,0.098,2.41,0,0,64.46,237,0,0,0,27775
"Vanilla extract, imitation, no alcohol",Spices and Herbs,0.03,0.003,0.003,0,0,0,14.4,14.4,0,85.58,56,0,0,0,27776
"Vinegar, balsamic",Spices and Herbs,0.49,0.027,0.023,0,0,0.112,17.03,14.95,0,76.45,88,0,0,0,27777
"Vinegar, cider",Spices and Herbs,0,0.007,0.005,0,0,0.073,0.93,0.4,0,93.81,21,0,0,0,27778
"Vinegar, distilled",Spices and Herbs,0,0.006,0.002,0,0,0.002,0.04,0.04,0,94.78,18,0,0,0,27779
"Vinegar, red wine",Spices and Herbs,0.04,0.006,0.008,0,0.0005,0.039,0.27,0,0,94.47,19,0,0,0,27780
"Baby food, fortified cereal bar, fruit filling",Baby Foods,5.43,1.053,0.25,1.7,0.0024,0.18,68.63,42,5.3,14,344,0.453,3.107,1.541,27781
"Babyfood, apple yogurt dessert, strained",Baby Foods,0.8,0.006,0.02,0.5,0.0351,0.07,19.5,12.02,1.6,77.9,96,1.034,0.438,0.044,27782
"Babyfood, apple-banana juice",Baby Foods,0.2,0.007,0.004,0.2,0.027899999999999998,0.123,12.3,11,0.1,87.1,51,0.018,0.004,0.034,27783
"Babyfood, apple-cranberry juice",Baby Foods,0,0.006,0.005,0,0.027899999999999998,0.097,11.49,6.88,0.02,88.3,46,0.007,0,0.012,27784
"Babyfood, apples with ham, strained",Baby Foods,2.6,0.004,0.009,1.8,0.0001,0.12,10.9,7.98,0.9,85.3,62,0.25,0.36,0.111,27785
"Babyfood, apples, dices, toddler",Baby Foods,0.2,0.01,0.006,0.9,0.0313,0.05,12.1,10.83,0.1,87.5,51,0.02,0.004,0.03,27786
"Babyfood, baked product, finger snacks cereal",Baby Foods,6.4,0.4,0.324,2.3,0.01,0.114,76.68,28,9.9,3.7,421,1.646,3.13,3.638,27787
"Babyfood, banana apple dessert, strained",Baby Foods,0.3,0.003,0.007,1,0.0123,0.071,16.3,14.66,0.2,83.1,68,0.058,0.018,0.047,27788
"Babyfood, banana juice with low fat yogurt",Baby Foods,2.5,0.079,0.037,0.4,0.0339,0.16,17.54,13.28,0.8,78.5,89,0.517,0.219,0.022,27789
"Babyfood, banana no tapioca, strained",Baby Foods,1,0.004,0.002,1.6,0.0219,0.29,21.34,11.36,0.2,76.7,91,0.072,0.028,0.041,27790
"Babyfood, carrots and beef, strained",Baby Foods,3.4,0.022,0.069,2.6,0.0008,0.226,5.7,2.04,2.5,87.3,59,0.96,1.06,0.13,27791
"Babyfood, carrots, toddler",Baby Foods,0.6,0.019,0.048,2.3,0.0007,0.129,5.23,2.5,0.3,93.5,26,0.052,0.012,0.148,27792
"Babyfood, cereal, barley, dry",Baby Foods,11.1,0.795,0.012,8.2,0.0022,0.395,75.3,3.1,3.4,6.8,365,0.418,0.666,1.759,27793
"Babyfood, cereal, barley, prepared with whole milk",Baby Foods,3.73,0.162,0.043,0.6,0.0002,0.151,9.94,5.1,3.26,82.19,84,1.76,0.802,0.309,27794
"Babyfood, cereal, brown rice, dry, instant",Baby Foods,8.7,0.055,0.009,6.5,0,0.386,85.65,0.92,3,1,404,0.6,1.08,1.07,27795
"Babyfood, cereal, egg yolks and bacon, junior",Baby Foods,2.5,0.028,0.048,0.9,0.0009,0.035,6.2,0,5,85.9,79,1.64,2.207,0.641,27796
"Babyfood, cereal, high protein, prepared with whole milk",Baby Foods,8.7,0.218,0.049,0,0,0.349,11.6,0,3.8,74.5,111,0,0,0,27797
"Babyfood, cereal, high protein, with apple and orange, dry",Baby Foods,25.4,0.751,0.104,7.1,0.0031,1.33,57.6,0,6.5,5.3,374,0.96,1.231,3.429,27798
"Babyfood, cereal, high protein, with apple and orange, prepared with whole milk",Baby Foods,6.9,0.223,0.058,0,0,0.346,13.4,0,3.9,74.4,112,0,0,0,27799
"Babyfood, cereal, mixed, dry",Baby Foods,12.2,0.733,0.003,7.5,0.0023,0.437,73.4,0.87,4.4,6.7,379,0.769,1.206,1.697,27800
"Babyfood, cereal, mixed, prepared with whole milk",Baby Foods,4.14,0.22,0.042,0.8,0.0003,0.166,12.3,4.78,3.38,79.21,96,1.745,0.855,0.359,27801
"Babyfood, cereal, mixed, with applesauce and bananas, junior",Baby Foods,1.2,0.004,0.003,1.2,0.0091,0.032,18.4,7.79,0.4,79.6,83,0.072,0.108,0.152,27802
"Babyfood, cereal, mixed, with applesauce and bananas, strained",Baby Foods,1.2,0.006,0.003,1.2,0.0255,0.041,18,7.79,0.5,80,82,0.09,0.135,0.191,27803
"Babyfood, cereal, mixed, with bananas, dry",Baby Foods,10.7,0.696,0,7.8,0.0036,0.668,77.1,6.68,4.6,4.5,391,0.846,1.223,1.734,27804
"Babyfood, cereal, mixed, with bananas, prepared with whole milk",Baby Foods,3.82,0.153,0.048,0.4,0.0004,0.178,10,5.9,3.46,81.81,86,1.808,0.859,0.342,27805
"Babyfood, cereal, mixed, with honey, prepared with whole milk",Baby Foods,5,0.294,0.048,0,0,0.171,15.9,0,3.6,74.2,115,0,0,0,27806
"Babyfood, cereal, oatmeal, dry",Baby Foods,13.07,0.707,0.004,9,0,0.42,69.65,3.84,8,5.9,399,1.37,2.57,2.47,27807
"Babyfood, cereal, oatmeal, prepared with whole milk",Baby Foods,5,0.22,0.046,1.1,0.0013,0.204,15.3,0,4.1,74.5,116,2.258,0,0,27808
"Babyfood, cereal, oatmeal, with applesauce and bananas, junior",Baby Foods,1.3,0.006,0.003,0.8,0.0245,0.048,15.7,9.48,0.7,81.8,75,0.126,0.216,0.253,27809
"Babyfood, cereal, oatmeal, with applesauce and bananas, strained",Baby Foods,1.3,0.009,0.003,0.8,0.0218,0.047,15.4,10.49,0.7,82.2,74,0.125,0.216,0.253,27810
"Babyfood, cereal, oatmeal, with bananas, dry",Baby Foods,12,0.651,0.006,5.2,0.0047,0.731,73.4,13.75,6,4.7,393,1.267,1.429,2.186,27811
"Babyfood, cereal, oatmeal, with bananas, prepared with whole milk",Baby Foods,3.82,0.153,0.048,0.4,0.0004,0.178,10,5.9,3.46,81.81,86,1.82,0.859,0.345,27812
"Babyfood, cereal, oatmeal, with honey, dry",Baby Foods,13.5,1.154,0.047,0,0,0.259,69.3,0,7,5.8,391,0,0,0,27813
"Babyfood, cereal, oatmeal, with honey, prepared with whole milk",Baby Foods,5,0.289,0.049,0,0,0.17,15.3,0,3.9,74.5,115,0,0,0,27814
"Babyfood, cereal, rice, dry",Baby Foods,7.1,0.85,0.006,0.5,0.0024,0.385,77.7,0.12,4.9,6.7,391,0.919,1.26,2.016,27815
"Babyfood, cereal, rice, prepared with whole milk",Baby Foods,3.45,0.168,0.042,0,0.0002,0.151,10.31,4.87,3.38,81.96,85,1.793,0.846,0.333,27816
"Babyfood, cereal, rice, with applesauce and bananas, strained",Baby Foods,1.2,0.017,0.004,1,0.0316,0.028,17.1,2.24,0.4,81,80,0.114,0.104,0.102,27817
"Babyfood, cereal, rice, with bananas, dry",Baby Foods,8.7,0.691,0,1,0.002,0.769,79.9,16.7,4.2,4.7,404,0.978,0.986,1.503,27818
"Babyfood, cereal, rice, with bananas, prepared with whole milk",Baby Foods,3.57,0.156,0.047,0.1,0.0002,0.18,10.49,6.13,3.32,81.81,86,1.798,0.826,0.294,27819
"Babyfood, cereal, rice, with honey, prepared with whole milk",Baby Foods,3.9,0.291,0.049,0,0,0.141,17.1,0,3.3,74.4,115,0,0,0,27820
"Babyfood, cereal, rice, with mixed fruit, junior",Baby Foods,0.9,0.016,0.01,0.6,0.009300000000000001,0.05,18.3,10.5,0.2,80.4,82,0.06,0.04,0.05,27821
"Babyfood, cereal, whole wheat, with apples, dry",Baby Foods,12.6,0.6,0.025,12.2,0.0583,0.516,77.1,22.2,4.8,1.7,402,0.639,1.277,1.866,27822
"Babyfood, cereal, with egg yolks, junior",Baby Foods,1.9,0.024,0.033,0.9,0.0007,0.035,7.1,0,1.8,88.7,52,0.61,0.79,0.3,27823
"Babyfood, cereal, with egg yolks, strained",Baby Foods,1.9,0.024,0.033,0.9,0.0007,0.039,7,0,1.8,88.8,51,0.61,0.79,0.3,27824
"Babyfood, cereal, with eggs, strained",Baby Foods,2.2,0.027,0.038,0,0.0008,0.044,8,0,1.5,87.2,58,0.49,0.61,0.33,27825
"Babyfood, cherry cobbler, junior",Baby Foods,0.3,0.005,0,0.2,0.009300000000000001,0.045,19.2,10.39,0.1,79.8,78,0.02,0.02,0.03,27826
"Babyfood, cookie, baby, fruit",Baby Foods,6.8,0.083,0.009,3.4,0.0015,0.425,73.7,24.2,12.6,5.7,435,2.7,4.865,4.334,27827
"Babyfood, cookies",Baby Foods,11.8,0.101,0.357,0.2,0.007,0.501,67.1,24.2,13.2,5.9,433,2.352,5.99,2.878,27828
"Babyfood, cookies, arrowroot",Baby Foods,7.6,0.032,0.319,0.2,0.0055,0.156,71.1,0,14.3,5.6,442,1.048,7.922,0.591,27829
"Babyfood, corn and sweet potatoes, strained",Baby Foods,1.26,0.018,0.014,1.8,0.005,0.154,15.23,4,0.28,82.62,68,0.053,0.071,0.123,27830
"Babyfood, crackers, vegetable",Baby Foods,8.4,0.041,0.571,4,0.0505,0.245,66.85,13,19.6,2,477,1.966,15.779,1.022,27831
"Babyfood, dessert, banana pudding, strained",Baby Foods,1,0.011,0.054,0.8,0.012,0.09,14.14,10.55,0.8,83.5,68,0.264,0.277,0.111,27832
"Babyfood, dessert, banana yogurt, strained",Baby Foods,1.1,0.03,0.014,0.5,0.013900000000000001,0.1,17.4,3.95,0.52,80.7,79,0.37,0.12,0.03,27833
"Babyfood, dessert, blueberry yogurt, strained",Baby Foods,0.5,0.025,0.014,0.4,0.0124,0.062,17.05,13.62,0.7,81.4,77,0.452,0.192,0.019,27834
"Babyfood, dessert, cherry vanilla pudding, junior",Baby Foods,0.2,0.005,0,0.3,0.0011,0.033,18.4,16.98,0.2,81,69,0.058,0.07,0.036,27835
"Babyfood, dessert, cherry vanilla pudding, strained",Baby Foods,0.2,0.005,0,0.3,0.0011,0.034,17.8,9.15,0.3,81.5,68,0.087,0.105,0.053,27836
"Babyfood, dessert, custard pudding, vanilla, junior",Baby Foods,1.76,0.054,0.026,0.2,0.0004,0.068,17.58,12.2,0.98,79.31,86,0.325,0.364,0.128,27837
"Babyfood, dessert, custard pudding, vanilla, strained",Baby Foods,1.6,0.055,0,0,0.0008,0.066,16,11.5,2,79.9,85,1.01,0.68,0.16,27838
"Babyfood, dessert, dutch apple, junior",Baby Foods,0.2,0.003,0.003,1.4,0.018,0.067,19.29,17.89,0.16,80.32,79,0.025,0.006,0.045,27839
"Babyfood, dessert, dutch apple, strained",Baby Foods,0.2,0.005,0,1.4,0.0214,0.033,19.74,17.89,0.4,79.5,75,0.19,0.08,0.01,27840
"Babyfood, dessert, fruit dessert, without ascorbic acid, junior",Baby Foods,0.3,0.009,0,0.6,0.003,0.095,17.2,12,0,82.2,63,0,0,0,27841
"Babyfood, dessert, fruit dessert, without ascorbic acid, strained",Baby Foods,0.3,0.009,0,0.6,0.0025,0.094,16,12.6,0,83.4,59,0,0,0,27842
"Babyfood, dessert, fruit pudding, orange, strained",Baby Foods,1.1,0.032,0.02,0.6,0.0091,0.086,17.7,0,0.9,79.8,80,0.545,0.257,0.038,27843
"Babyfood, dessert, fruit pudding, pineapple, strained",Baby Foods,1.3,0.031,0,0.7,0.0272,0.081,20.3,10.28,0.3,77.6,81,0.099,0.109,0.041,27844
"Babyfood, dessert, peach cobbler, junior",Baby Foods,0.3,0.004,0,0.7,0.0205,0.056,18.3,11.89,0,81.2,67,0,0,0,27845
"Babyfood, dessert, peach cobbler, strained",Baby Foods,0.3,0.004,0.007,0.7,0.0205,0.054,17.8,10.62,0,81.8,65,0,0,0,27846
"Babyfood, dessert, peach yogurt",Baby Foods,0.9,0.027,0.014,0.4,0.011699999999999999,0.102,17.6,6.63,0.18,80.87,76,0.117,0.048,0.005,27847
"Babyfood, dessert, tropical fruit, junior",Baby Foods,0.2,0.01,0.007,0,0.0188,0.058,16.4,0,0,83.2,60,0,0,0,27848
"Babyfood, dinner, apples and chicken, strained",Baby Foods,2.16,0.018,0.012,1.8,0.0002,0.095,10.87,7.9,1.38,85.4,65,0.347,0.411,0.35,27849
"Babyfood, dinner, beef and rice, toddler",Baby Foods,5,0.011,0.032,0,0.0039,0.12,8.8,0,2.9,81.9,82,0,0,0,27850
"Babyfood, dinner, beef lasagna, toddler",Baby Foods,4.2,0.018,0.041,0,0.0019,0.122,10,0,2.1,82.3,77,0,0,0,27851
"Babyfood, dinner, beef noodle, junior",Baby Foods,2.5,0.008,0.026,1.1,0.0014,0.046,7.3,1.45,1.9,87.8,57,0.766,0.838,0.094,27852
"Babyfood, dinner, beef noodle, strained",Baby Foods,2.44,0.01,0.015,1.3,0.0001,0.087,8.18,1.42,2.26,86.84,63,0.937,0.941,0.141,27853
"Babyfood, dinner, beef stew, toddler",Baby Foods,5.1,0.009,0.106,1.1,0.003,0.142,5.5,1.39,1.2,86.9,51,0.58,0.44,0.1,27854
"Babyfood, dinner, beef with vegetables",Baby Foods,2.03,0.019,0.038,1.8,0,0.127,6.36,2.16,6.93,84.3,96,2.84,3.12,0.9,27855
"Babyfood, dinner, broccoli and chicken, junior",Baby Foods,3.59,0.037,0.017,1.4,0.0177,0.17,6.34,1.43,2.48,86.92,62,0.51,0.77,0.43,27856
"Babyfood, dinner, chicken and noodle with vegetables, toddler",Baby Foods,3.8,0.011,0.235,0.6,0.0017,0.095,8.89,0.82,1.7,85.3,66,0.461,0.632,0.392,27857
"Babyfood, dinner, chicken and rice",Baby Foods,1.6,0.018,0.025,1.1,0.001,0.06,9.2,0.55,0.9,88,51,0.211,0.392,0.188,27858
"Babyfood, dinner, chicken noodle, junior",Baby Foods,2.37,0.021,0.035,0.9,0.0001,0.039,8.79,1.07,1.18,87.25,55,0.338,0.467,0.272,27859
"Babyfood, dinner, chicken noodle, strained",Baby Foods,2.69,0.027,0.038,2.1,0.0001,0.139,9.08,2.5,2.06,85.64,66,0.595,0.854,0.461,27860
"Babyfood, dinner, chicken soup, strained",Baby Foods,1.6,0.037,0.016,1.1,0.001,0.066,7.2,1.72,1.7,89.1,50,0.278,0.425,0.9,27861
"Babyfood, dinner, chicken stew, toddler",Baby Foods,5.2,0.036,0.025,0.6,0.0019,0.092,6.4,1.68,3.7,83.3,78,1.1,1.7,0.77,27862
"Babyfood, dinner, macaroni and cheese, junior",Baby Foods,2.6,0.051,0.266,0.3,0.0013,0.044,8.2,1.32,2,86.5,61,1.175,0.534,0.132,27863
"Babyfood, dinner, macaroni and cheese, strained",Baby Foods,3.14,0.066,0.119,0.7,0.0001,0.067,8.95,1.27,2.11,85.25,67,1.291,0.581,0.103,27864
"Babyfood, dinner, macaroni and tomato and beef, junior",Baby Foods,2.5,0.014,0.035,1.1,0.0015,0.072,9.4,2.43,1.1,86.7,59,0.411,0.447,0.079,27865
"Babyfood, dinner, macaroni and tomato and beef, strained",Baby Foods,2.36,0.017,0.038,1.2,0.0003,0.112,9.45,2.09,1.47,86.34,61,0.486,0.594,0.193,27866
"Babyfood, dinner, macaroni, beef and tomato sauce, toddler",Baby Foods,4.34,0.012,0.035,1.1,0.0019,0.148,11.53,0.37,1.9,81.81,81,0.705,0.782,0.14,27867
"Babyfood, dinner, mixed vegetable, junior",Baby Foods,1,0.017,0.038,0,0.0033,0.112,8,0,0,90.6,33,0,0,0,27868
"Babyfood, dinner, mixed vegetable, strained",Baby Foods,1.2,0.022,0.038,0,0.0028,0.121,9.5,0,0.1,88.7,41,0,0,0,27869
"Babyfood, dinner, pasta with vegetables",Baby Foods,1.7,0.014,0.011,1.5,0.0029,0.133,8.4,1.2,2.1,87.3,60,1.23,0.58,0.144,27870
"Babyfood, dinner, potatoes with cheese and ham, toddler",Baby Foods,3.5,0.053,0.205,1.2,0.0001,0.143,11.97,0.8,2,81.5,78,1.1,0.733,0.163,27871
"Babyfood, dinner, spaghetti and tomato and meat, junior",Baby Foods,2.57,0.015,0.03,1.1,0.0002,0.122,11.42,2.72,1.37,84.17,68,0.543,0.497,0.185,27872
"Babyfood, dinner, spaghetti and tomato and meat, toddler",Baby Foods,5.3,0.022,0.239,0,0.0040999999999999995,0.163,10.8,0,1,81.6,75,0,0,0,27873
"Babyfood, dinner, sweet potatoes and chicken, strained",Baby Foods,2.51,0.03,0.022,1.3,0,0.2,11.04,5.37,2.17,83.7,74,0.572,0.98,0.48,27874
"Babyfood, dinner, turkey and rice, junior",Baby Foods,2.37,0.024,0.023,1,0.0003,0.086,9.57,1.25,0.92,86.64,56,0.239,0.304,0.233,27875
"Babyfood, dinner, turkey and rice, strained",Baby Foods,2.27,0.018,0.019,0.9,0.0002,0.091,7.94,1.66,1.24,88.22,52,0.338,0.446,0.308,27876
"Babyfood, dinner, turkey, rice, and vegetables, toddler",Baby Foods,3.8,0.011,0.276,0.8,0.0003,0.107,7.5,0.66,1.6,86.3,60,0.5,0.555,0.309,27877
"Babyfood, dinner, vegetables and bacon, strained",Baby Foods,1.92,0.014,0.049,1.7,0.0003,0.116,8.81,1.77,2.95,85.75,69,1.063,1.347,0.377,27878
"Babyfood, dinner, vegetables and beef, junior",Baby Foods,2.21,0.017,0.031,1.3,0.0002,0.145,8.84,2.34,3.6,84.94,77,1.367,1.289,0.298,27879
"Babyfood, dinner, vegetables and beef, strained",Baby Foods,2.21,0.017,0.031,1.3,0.0002,0.145,8.84,2.34,3.6,84.94,77,0.87,0.82,0.19,27880
"Babyfood, dinner, vegetables and chicken, junior",Baby Foods,2.04,0.027,0.034,1.1,0.0002,0.083,8.66,1.46,1.12,87.76,53,0.302,0.402,0.269,27881
"Babyfood, dinner, vegetables and dumplings and beef, junior",Baby Foods,2.1,0.014,0.052,0,0.0008,0.047,8,0,0.8,88.6,48,0,0,0,27882
"Babyfood, dinner, vegetables and dumplings and beef, strained",Baby Foods,2,0.014,0.049,0,0.0008,0.046,7.7,0,0.9,88.9,48,0,0,0,27883
"Babyfood, dinner, vegetables and ham, junior",Baby Foods,2.02,0.014,0.045,1,0.0011,0.097,8.64,0.99,1.89,87,60,0.483,0.811,0.448,27884
"Babyfood, dinner, vegetables and ham, strained",Baby Foods,2.19,0.016,0.016,1.6,0.0016,0.141,7.8,1.37,2.14,87.47,59,0.764,0.963,0.234,27885
"Babyfood, dinner, vegetables and lamb, junior",Baby Foods,2.1,0.013,0.013,1.1,0.0017,0.095,7.1,0.94,1.7,88.6,51,0.696,0.69,0.154,27886
"Babyfood, dinner, vegetables and lamb, strained",Baby Foods,2,0.012,0.02,1.1,0.0012,0.094,6.9,0.94,2,88.6,52,0.828,0.822,0.167,27887
"Babyfood, dinner, vegetables and noodles and turkey, junior",Baby Foods,1.8,0.032,0.017,1.1,0.0008,0.073,7.6,0,1.5,88.7,52,0,0,0,27888
"Babyfood, dinner, vegetables and noodles and turkey, strained",Baby Foods,1.2,0.032,0.021,1.1,0.0008,0.063,6.8,0,1.2,90.3,44,0,0,0,27889
"Babyfood, dinner, vegetables and turkey, junior",Baby Foods,1.72,0.016,0.045,0.9,0.0004,0.098,7.55,1.36,1.74,88.5,53,0.485,0.687,0.391,27890
"Babyfood, dinner, vegetables and turkey, strained",Baby Foods,2.32,0.027,0.02,1.5,0.0007,0.102,7.62,1.54,0.9,88.82,48,0.236,0.294,0.241,27891
"Babyfood, dinner, vegetables and turkey, toddler",Baby Foods,4.8,0.046,0.041,0,0.0034,0.166,8.1,0,3.4,82.3,80,0,0,0,27892
"Babyfood, dinner, vegetables chicken, strained",Baby Foods,2.47,0.027,0.024,2.1,0.0001,0.158,8.42,1.58,1.73,86.9,59,0.496,0.712,0.374,27893
"Babyfood, dinner, vegetables, noodles and chicken, junior",Baby Foods,1.7,0.026,0.026,1.1,0.0008,0.059,9.1,0,2.2,86.2,64,0,0,0,27894
"Babyfood, dinner, vegetables, noodles and chicken, strained",Baby Foods,2,0.028,0.02,1.1,0.0007,0.055,7.9,0,2.5,87.2,63,0,0,0,27895
"Babyfood, fruit and vegetable, apple and sweet potato",Baby Foods,0.3,0.008,0.003,1.4,0.0064,0.149,15.3,11.6,0.22,84,64,0.041,0.008,0.079,27896
"Babyfood, fruit dessert, mango with tapioca",Baby Foods,0.3,0.008,0.004,1.1,0.0158,0.066,19,13,0.2,80,70,0.039,0.06,0.03,27897
"Babyfood, fruit supreme dessert",Baby Foods,0.5,0.006,0.001,2,0.0127,0.129,17.18,14.87,0.2,81.6,73,0.03,0.025,0.068,27898
"Babyfood, fruit, apple and blueberry, junior",Baby Foods,0.2,0.005,0.001,1.8,0.013900000000000001,0.065,16.6,8.83,0.2,82.8,62,0.024,0.019,0.073,27899
"Babyfood, fruit, apple and blueberry, strained",Baby Foods,0.2,0.004,0.001,1.8,0.027800000000000002,0.069,16.3,13.93,0.2,83.1,61,0.024,0.019,0.073,27900
"Babyfood, fruit, apple and raspberry, junior",Baby Foods,0.2,0.005,0,2.1,0.0289,0.072,15.4,12.96,0.2,84,58,0.032,0.008,0.063,27901
"Babyfood, fruit, apple and raspberry, strained",Baby Foods,0.2,0.005,0,2.1,0.0268,0.08,15.6,12.96,0.2,83.8,58,0.033,0.008,0.062,27902
"Babyfood, fruit, applesauce and apricots, junior",Baby Foods,0.2,0.006,0,1.8,0.0179,0.109,12.4,8.72,0.2,86.9,47,0.028,0.03,0.051,27903
"Babyfood, fruit, applesauce and apricots, strained",Baby Foods,0.2,0.006,0,1.8,0.0189,0.12,11.64,9.25,0.2,87.7,44,0.024,0.037,0.045,27904
"Babyfood, fruit, applesauce and cherries, junior",Baby Foods,0,0.001,0.001,1.1,0.0428,0.132,14.1,10.61,0,85.6,51,0,0,0,27905
"Babyfood, fruit, applesauce and cherries, strained",Baby Foods,0,0.001,0.001,1.1,0.0428,0.132,14.1,10.81,0,85.63,51,0,0,0,27906
"Babyfood, fruit, applesauce and pineapple, junior",Baby Foods,0.1,0.004,0.002,1.5,0.0268,0.076,10.5,0,0.1,89.1,39,0.011,0.009,0.032,27907
"Babyfood, fruit, applesauce and pineapple, strained",Baby Foods,0.1,0.004,0.002,1.5,0.0281,0.078,10.1,0,0.1,89.5,37,0.01,0.009,0.032,27908
"Babyfood, fruit, applesauce with banana, junior",Baby Foods,0.37,0.005,0.003,1.6,0.0173,0.131,16.16,4.35,0.1,83.14,66,0.04,0.01,0.05,27909
"Babyfood, fruit, applesauce, junior",Baby Foods,0,0.005,0.001,1.7,0.0378,0.077,10.3,8.48,0,89.5,37,0,0,0,27910
"Babyfood, fruit, applesauce, strained",Baby Foods,0.2,0.004,0,1.7,0.038299999999999994,0.071,10.8,9.87,0.2,88.6,41,0.032,0.008,0.056,27911
"Babyfood, fruit, apricot with tapioca, junior",Baby Foods,0.3,0.008,0,1.5,0.0179,0.125,17.3,1.4,0,82.1,63,0,0,0,27912
"Babyfood, fruit, apricot with tapioca, strained",Baby Foods,0.3,0.009,0,1.5,0.0216,0.121,16.3,1.41,0,83.1,60,0,0,0,27913
"Babyfood, fruit, bananas and pineapple with tapioca, junior",Baby Foods,0.2,0.007,0,1.6,0.0212,0.078,18.4,8.41,0.1,81.1,68,0.036,0.009,0.02,27914
"Babyfood, fruit, bananas and pineapple with tapioca, strained",Baby Foods,0.2,0.007,0,1.6,0.0192,0.068,17.8,10.73,0,81.7,65,0,0,0,27915
"Babyfood, fruit, bananas with apples and pears, strained",Baby Foods,0.9,0.005,0.002,1.4,0.013900000000000001,0.233,19.31,12.76,0.22,79,83,0.106,0.028,0.085,27916
"Babyfood, fruit, bananas with tapioca, junior",Baby Foods,0.4,0.008,0,1.6,0.0257,0.108,17.8,7.1,0.2,81.5,67,0.076,0.018,0.037,27917
"Babyfood, fruit, bananas with tapioca, strained",Baby Foods,0.4,0.005,0,1.6,0.0167,0.088,15.3,0.38,0.1,84,56,0.035,0.01,0.019,27918
"Babyfood, fruit, guava and papaya with tapioca, strained",Baby Foods,0.2,0.007,0.004,0,0.0809,0.074,17,0,0.1,82.5,63,0,0,0,27919
"Babyfood, fruit, papaya and applesauce with tapioca, strained",Baby Foods,0.2,0.007,0.005,1.4,0.11309999999999999,0.079,18.9,0,0.1,80.6,70,0,0,0,27920
"Babyfood, fruit, peaches, junior",Baby Foods,0.94,0.004,0.004,1.3,0.0461,0.195,14.48,11.5,0.33,83.65,65,0.02,0.01,0.04,27921
"Babyfood, fruit, peaches, strained",Baby Foods,0.94,0.004,0.004,1.3,0.0461,0.195,14.48,11.5,0.33,83.65,65,0.02,0.01,0.04,27922
"Babyfood, fruit, pears and pineapple, junior",Baby Foods,0.3,0.01,0,2.6,0.016800000000000002,0.118,11.4,7.36,0.2,87.8,44,0.013,0.031,0.058,27923
"Babyfood, fruit, pears and pineapple, strained",Baby Foods,0.3,0.01,0,2.6,0.0275,0.116,10.9,7.79,0.1,88.5,41,0.007,0.016,0.029,27924
"Babyfood, fruit, pears, junior",Baby Foods,0.3,0.008,0.001,3.6,0.022,0.115,11.6,7.34,0.1,87.8,44,0.006,0.021,0.023,27925
"Babyfood, fruit, pears, strained",Baby Foods,0.3,0.008,0.001,3.6,0.0245,0.13,10.8,6.98,0.2,88.4,42,0.012,0.042,0.046,27926
"Babyfood, fruit, plums with tapioca, without ascorbic acid, junior",Baby Foods,0.1,0.006,0,1.2,0.0008,0.083,20.5,13.64,0,79.2,74,0,0,0,27927
"Babyfood, fruit, plums with tapioca, without ascorbic acid, strained",Baby Foods,0.1,0.006,0,1.2,0.0011,0.085,19.7,13.34,0,80,71,0,0,0,27928
"Babyfood, fruit, prunes with tapioca, without ascorbic acid, junior",Baby Foods,0.6,0.015,0.002,2.7,0.0008,0.162,18.7,10.97,0.1,80.1,70,0.008,0.066,0.022,27929
"Babyfood, fruit, prunes with tapioca, without ascorbic acid, strained",Baby Foods,0.6,0.015,0.005,2.7,0.0008,0.177,18.5,10.97,0.1,80.3,69,0.008,0.066,0.022,27930
"Babyfood, fruit, tutti frutti, junior",Baby Foods,0.4,0.014,0.017,0.4,0.0072,0.047,16,9.41,0.4,82.9,69,0.11,0.148,0.061,27931
"Babyfood, fruit, tutti frutti, strained",Baby Foods,0.4,0.015,0.025,0.6,0.0124,0.046,15.48,9.29,0.3,83.5,66,0.087,0.113,0.043,27932
"Babyfood, green beans, dices, toddler",Baby Foods,1.2,0.027,0.037,1.3,0.0017,0.116,5.7,1.06,0.2,92.6,29,0.044,0.008,0.102,27933
"Babyfood, juice treats, fruit medley, toddler",Baby Foods,0,0.012,0.089,0,0.008,0.054,86.68,57.4,0,12.89,347,0,0,0,27934
"Babyfood, juice, apple",Baby Foods,0,0.004,0.008,0.1,0.0579,0.091,11.7,10.7,0.1,88,47,0.018,0.002,0.031,27935
"Babyfood, juice, apple - cherry",Baby Foods,0.2,0.007,0.004,0.3,0.032100000000000004,0.11,11.2,10.5,0.1,88.2,47,0.02,0,0.03,27936
"Babyfood, juice, apple and cherry",Baby Foods,0.1,0.005,0,0.1,0.0583,0.098,9.9,8.98,0.2,89.5,41,0.035,0.011,0.06,27937
"Babyfood, juice, apple and grape",Baby Foods,0.1,0.006,0,0.1,0.0315,0.09,11.34,10.9,0.2,88.1,46,0.04,0.009,0.059,27938
"Babyfood, juice, apple and peach",Baby Foods,0.2,0.003,0,0.1,0.0585,0.097,10.5,9.59,0.1,89,43,0.018,0.002,0.031,27939
"Babyfood, juice, apple and plum",Baby Foods,0.1,0.005,0,0.1,0.0582,0.101,12.3,11.54,0,87.3,49,0,0,0,27940
"Babyfood, juice, apple and prune",Baby Foods,0.2,0.009,0.008,0.1,0.0675,0.148,18.1,10.58,0.1,81.3,72,0.018,0.006,0.031,27941
"Babyfood, juice, apple, with calcium",Baby Foods,0.06,0.127,0.003,0.4,0.0212,0.092,11.1,9,0.1,88.3,46,0.018,0.005,0.032,27942
"Babyfood, juice, apple-sweet potato",Baby Foods,0.3,0.015,0.005,0.5,0.0341,0.137,11.4,9.6,0.1,87.9,48,0.03,0.01,0.05,27943
"Babyfood, juice, fruit punch, with calcium",Baby Foods,0.2,0.064,0.004,0.4,0.0212,0.086,12.7,10.6,0.1,86.5,52,0.02,0.004,0.029,27944
"Babyfood, juice, mixed fruit",Baby Foods,0.1,0.008,0.008,0.1,0.0636,0.101,11.7,8.46,0.1,87.9,47,0.015,0.006,0.039,27945
"Babyfood, juice, orange",Baby Foods,0.6,0.012,0,0.1,0.0625,0.184,10.2,8.26,0.3,88.5,45,0.035,0.05,0.06,27946
"Babyfood, juice, orange and apple",Baby Foods,0.4,0.01,0.003,0,0.07690000000000001,0.138,10.1,0,0.2,88.9,43,0,0,0,27947
"Babyfood, juice, orange and apple and banana",Baby Foods,0.4,0.005,0,0.1,0.032100000000000004,0.134,11.5,10.03,0.1,87.6,47,0.016,0.007,0.027,27948
"Babyfood, juice, orange and apricot",Baby Foods,0.8,0.006,0.006,0.1,0.0859,0.199,10.9,0,0.1,87.8,46,0.011,0.022,0.02,27949
"Babyfood, juice, orange and banana",Baby Foods,0.7,0.017,0.003,0,0.034,0.2,11.9,0,0.1,86.9,50,0,0,0,27950
"Babyfood, juice, orange and pineapple",Baby Foods,0.5,0.008,0.002,0.1,0.053399999999999996,0.141,11.7,0,0.1,87.3,48,0.01,0.014,0.024,27951
"Babyfood, juice, orange-carrot",Baby Foods,0.5,0.016,0.01,0.4,0.0341,0.174,9.9,8.4,0.1,89.1,43,0.01,0.01,0.03,27952
"Babyfood, juice, pear",Baby Foods,0,0.012,0.008,0.1,0.0338,0.13,11.86,7.28,0,87.9,47,0,0,0,27953
"Babyfood, juice, prune and orange",Baby Foods,0.6,0.012,0.002,0,0.0638,0.181,16.8,0,0.3,81.9,70,0,0,0,27954
"Babyfood, macaroni and cheese, toddler",Baby Foods,3.5,0.102,0.112,0.5,0,0.018,11.2,0.79,2.6,81.7,82,1.53,0.7,0.17,27955
"Babyfood, mashed cheddar potatoes and broccoli, toddlers",Baby Foods,1.13,0.038,0.176,0.9,0.0014,0.118,7.64,1.18,1.47,88.78,48,0.632,0.29,0.291,27956
"Babyfood, meat, beef with vegetables, toddler",Baby Foods,3.5,0.011,0.026,0.5,0.0025,0.168,8.72,1.18,2.1,85.23,68,0.847,0.92,0.108,27957
"Babyfood, meat, beef, junior",Baby Foods,12.03,0.005,0.041,0,0.0021000000000000003,0.187,2.43,0,2.52,82.44,81,1.18,0.95,0.16,27958
"Babyfood, meat, beef, strained",Baby Foods,12.03,0.005,0.041,0,0.0021000000000000003,0.187,2.43,0,2.52,82.44,81,1.18,0.95,0.16,27959
"Babyfood, meat, chicken sticks, junior",Baby Foods,14.6,0.073,0.408,0.2,0.0017,0.106,1.5,1.4,14.4,68.3,188,4.094,6.267,2.986,27960
"Babyfood, meat, chicken, junior",Baby Foods,14,0.055,0.049,0,0.0015,0.122,0,0,9.6,76,146,2.47,4.33,2.33,27961
"Babyfood, meat, chicken, strained",Baby Foods,13.7,0.064,0.049,0,0.0017,0.141,0.1,0,7.9,77.5,130,2.03,3.56,1.92,27962
"Babyfood, meat, ham, junior",Baby Foods,11.3,0.005,0.044,0,0.0021000000000000003,0.21,3.7,0,3.8,80.5,97,1.271,1.804,0.516,27963
"Babyfood, meat, ham, strained",Baby Foods,11.3,0.006,0.044,0,0.0021000000000000003,0.204,3.7,0,3.8,80.5,97,1.267,1.803,0.51,27964
"Babyfood, meat, lamb, junior",Baby Foods,15.2,0.007,0.042,0,0.0017,0.211,0,0,5.2,79.6,112,2.56,2.06,0.22,27965
"Babyfood, meat, lamb, strained",Baby Foods,14.07,0.007,0.043,0,0.0012,0.193,0.85,0,3.41,81.06,87,1.65,1.27,0.31,27966
"Babyfood, meat, meat sticks, junior",Baby Foods,13.4,0.034,0.423,0.2,0.0024,0.114,1.1,0.8,14.6,69.5,184,5.82,6.48,1.59,27967
"Babyfood, meat, pork, strained",Baby Foods,14,0.005,0.042,0,0.0018,0.223,0,0,7.1,78.4,124,2.4,3.58,0.78,27968
"Babyfood, meat, turkey sticks, junior",Baby Foods,13.7,0.083,0.44,0.5,0.0015,0.12,1.4,1.4,14.2,69.8,188,4.143,4.671,3.615,27969
"Babyfood, meat, turkey, junior",Baby Foods,11.5,0.041,0.049,0,0,0.135,1.4,0,6.2,80.32,111,1.641,2.384,1.741,27970
"Babyfood, meat, turkey, strained",Baby Foods,11.5,0.041,0.049,0,0.0022,0.135,1.4,0,6.2,80.32,111,1.641,2.384,1.741,27971
"Babyfood, meat, veal, strained",Baby Foods,13.12,0.006,0.039,0,0,0.17,1.51,0,2.45,82.37,81,1.063,1.054,0.157,27972
"Babyfood, mixed fruit juice with low fat yogurt",Baby Foods,2.4,0.081,0.036,0.4,0.0339,0.137,14.68,10.8,0.8,81.5,76,0.517,0.219,0.022,27973
"Babyfood, mixed fruit yogurt, strained",Baby Foods,0.8,0.028,0.016,0.4,0.011699999999999999,0.062,16.23,11.74,0.8,81.8,75,0.517,0.219,0.022,27974
"Babyfood, oatmeal cereal with fruit, dry, instant, toddler",Baby Foods,10.5,0.286,0,7.8,0.0027,0.346,74.1,11.09,7.05,5.4,402,1.572,3.008,2.29,27975
"Babyfood, peaches, dices, toddler",Baby Foods,0.5,0.006,0.009,0.8,0.0313,0.083,11.8,9.49,0.2,87.4,51,0.016,0.046,0.078,27976
"Babyfood, pears, dices, toddler",Baby Foods,0.3,0.01,0.006,1.2,0.0313,0.051,13.6,8.66,0.1,85.6,57,0.01,0.043,0.046,27977
"Babyfood, peas and brown rice",Baby Foods,3.4,0.014,0.006,2.5,0.003,0.093,11.49,2.88,0.5,84.1,64,0.1,0.18,0.18,27978
"Babyfood, peas, dices, toddler",Baby Foods,3.9,0.021,0.048,3.9,0.006,0.081,10.3,4.13,0.8,84.6,64,0.14,0.07,0.37,27979
"Babyfood, plums, bananas and rice, strained",Baby Foods,1.1,0.003,0,1.3,0.0123,0.265,12.7,9.3,0.3,85.6,57,0.06,0.12,0.07,27980
"Babyfood, potatoes, toddler",Baby Foods,1,0.004,0.057,0.9,0.0105,0.11,11.73,0.91,0.1,86.8,52,0.015,0.002,0.025,27981
"Babyfood, pretzels",Baby Foods,10.8,0.025,0.269,2.3,0.0038,0.137,82.2,3.08,2,4,397,0.316,0.188,0.833,27982
"Babyfood, prunes, without vitamin c, strained",Baby Foods,1,0.021,0.001,2.7,0.0013,0.306,23.52,21.21,0.2,74.3,100,0.02,0.13,0.04,27983
"Babyfood, ravioli, cheese filled, with tomato sauce",Baby Foods,3.6,0.054,0.282,0.1,0.0001,0.032,16.3,0.59,2.2,76.5,99,0.96,0.57,0.48,27984
"Babyfood, rice and apples, dry",Baby Foods,6.7,0.85,0.016,3,0,0.41,86.89,3.22,2.4,3.2,396,0.503,0.615,0.92,27985
"Babyfood, teething biscuits",Baby Foods,10.7,0.263,0.227,1.4,0.0091,0.323,76.4,12.65,4.2,6.4,391,1.206,1.702,0.998,27986
"Babyfood, vegetable and brown rice, strained",Baby Foods,1.9,0.014,0.014,1.1,0.0003,0.135,12.1,2.39,1.5,84.1,69,0.14,0.97,0.32,27987
"Babyfood, vegetable, butternut squash and corn",Baby Foods,2,0.032,0.005,2,0.004900000000000001,0.352,9.26,2.93,0.6,87.3,50,0.095,0.166,0.28,27988
"Babyfood, vegetable, green beans and potatoes",Baby Foods,2.2,0.06,0.018,1.4,0.0011,0.148,9,2.35,1.9,86.2,62,1.117,0.539,0.132,27989
"Babyfood, vegetables, beets, strained",Baby Foods,1.3,0.014,0.083,1.9,0.0024,0.182,7.7,6.09,0.1,90.1,34,0.016,0.019,0.036,27990
"Babyfood, vegetables, carrots, junior",Baby Foods,0.8,0.023,0.049,1.7,0.0055,0.202,7.2,3.16,0.2,91,32,0.036,0.009,0.093,27991
"Babyfood, vegetables, carrots, strained",Baby Foods,0.8,0.022,0.069,1.7,0.0057,0.196,6,3.64,0.1,92.3,26,0.018,0.004,0.047,27992
"Babyfood, vegetables, corn, creamed, junior",Baby Foods,1.4,0.004,0.052,2.1,0.0022,0.081,16.25,1.48,0.4,81.4,65,0.074,0.116,0.178,27993
"Babyfood, vegetables, corn, creamed, strained",Baby Foods,1.4,0.02,0.043,2.1,0.0021000000000000003,0.09,14.1,1.23,0.4,83.6,57,0.076,0.116,0.176,27994
"Babyfood, vegetables, garden vegetable, strained",Baby Foods,2.3,0.028,0.031,1.5,0.0057,0.168,6.8,2.57,0.2,90,32,0.035,0.011,0.09,27995
"Babyfood, vegetables, green beans, junior",Baby Foods,1.2,0.065,0.005,1.9,0.008400000000000001,0.128,5.8,1.08,0.1,92.5,24,0.023,0.004,0.052,27996
"Babyfood, vegetables, green beans, strained",Baby Foods,1.2,0.039,0.007,2.2,0.0003,0.146,6.29,1.88,0.17,91.85,27,0.039,0,0.116,27997
"Babyfood, vegetables, mix vegetables junior",Baby Foods,1.4,0.011,0.036,1.5,0.0025,0.17,8.2,1.78,0.4,89.4,36,0.079,0.025,0.186,27998
"Babyfood, vegetables, mix vegetables strained",Baby Foods,1.2,0.013,0,1.5,0.0017,0.127,8.05,1.57,0.5,89.8,36,0.089,0.118,0.194,27999
"Babyfood, vegetables, peas, strained",Baby Foods,3.27,0.018,0.005,2,0.0006,0.106,8.36,2.01,0.43,87.52,50,0.07,0.05,0.2,28000
"Babyfood, vegetables, spinach, creamed, strained",Baby Foods,2.5,0.089,0.049,1.8,0.0087,0.191,5.7,2.33,1.3,89.6,37,0.702,0.332,0.133,28001
"Babyfood, vegetables, squash, junior",Baby Foods,0.81,0.024,0.005,0.9,0.0003,0.185,5.73,3.24,0.2,92.66,24,0.02,0.02,0.04,28002
"Babyfood, vegetables, squash, strained",Baby Foods,0.81,0.024,0.005,0.9,0.0003,0.185,5.73,3.37,0.2,92.66,28,0.02,0.02,0.04,28003
"Babyfood, vegetables, sweet potatoes strained",Baby Foods,1.1,0.016,0.022,1.5,0.0099,0.263,13.2,4.05,0.1,84.8,57,0.021,0.004,0.044,28004
"Babyfood, vegetables, sweet potatoes, junior",Baby Foods,1.1,0.016,0.018,1.5,0.0096,0.243,14,4.24,0.1,84.1,60,0.021,0.004,0.044,28005
"Babyfood, yogurt, whole milk, with fruit, multigrain cereal and added DHA",Baby Foods,3.4,0.107,0.041,0.3,0.0014,0.149,13.22,11.46,3.53,79.16,98,1.84,0.916,0.367,28006
"Babyfood, yogurt, whole milk, with fruit, multigrain cereal and added iron",Baby Foods,3.05,0.098,0.038,0.6,0.0036,0.143,13,11.46,3.08,80.21,92,1.582,0.74,0.405,28007
"Child formula, ABBOTT NUTRITION, PEDIASURE, ready-to-feed (formerly ROSS)",Baby Foods,2.86,0.093,0.036,0,0.0095,0.125,11.15,10.5,4.77,80.47,99,1.256,1.936,1.068,28008
"Child formula, ABBOTT NUTRITION, PEDIASURE, ready-to-feed, with iron and fiber (formerly ROSS)",Baby Foods,2.8,0.092,0.036,0.5,0.0096,0.124,11.28,7.6,4.7,80.47,99,1.256,1.936,1.067,28009
"Child formula, MEAD JOHNSON, PORTAGEN, with iron, powder, not reconstituted",Baby Foods,16.5,0.44,0.26,0,0.038,0.59,54.8,54,22,3,483,19.186,0.683,1.609,28010
"Child formula, MEAD JOHNSON, PORTAGEN, with iron, prepared from powder",Baby Foods,3.33,0.088,0.052,0,0.0076,0.118,8.57,7.4,4.48,82.86,88,3.953,0.143,0.333,28011
"Fluid replacement, electrolyte solution (include PEDIALYTE)",Baby Foods,0,0.01,0.101,0,0,0.077,2.45,2.45,0,96.44,10,0,0,0,28012
Gerber graduates Fruit Strips Real Fruit Bars,Baby Foods,0.82,0.018,0.014,7.8,0.1799,0.312,76.61,68.65,2.24,19.09,330,0.361,0.091,0.658,28013
"Infant Formula, MEAD JOHNSON, ENFAMIL GENTLEASE LIPIL, with iron, prepared from powder",Baby Foods,1.52,0.053,0.021,0,0.0079,0.071,6.39,6.44,3.5,88.4,63,1.5,1.3,0.7,28014
"Infant Formula, MEAD JOHNSON, ENFAMIL, ENFACARE LIPIL, ready-to-feed, with ARA and DHA",Baby Foods,2,0.086,0.025,0,0.011300000000000001,0.075,7.4,7.4,3.8,86,71,1.578,1.33,0.862,28015
"Infant formula, ABBOTT NUTRITION, ALIMENTUM ADVANCE, with iron, powder, not reconstituted, with DHA and ARA (formerly ROSS)",Baby Foods,13.99,0.534,0.224,0,0.0458,0.598,51.91,34.18,28.19,1.5,517,14.282,2.276,8.877,28016
"Infant formula, ABBOTT NUTRITION, SIMILAC NATURAL CARE, ADVANCE, ready-to-feed, with ARA and DHA (formerly ROSS)",Baby Foods,2.12,0.163,0.034,0,0.0289,0.101,8.2,8.2,4.24,85.07,78,2.544,0.402,0.842,28017
"Infant formula, ABBOTT NUTRITION, SIMILAC NEOSURE, ready-to-feed, with ARA and DHA (formerly ROSS)",Baby Foods,1.92,0.072,0.023,0,0.0103,0.097,6.94,6.9,3.77,87,69,2.447,0.387,0.844,28018
"Infant formula, ABBOTT NUTRITION, SIMILAC, ADVANCE, with iron, liquid concentrate, not reconstituted (formerly ROSS)",Baby Foods,2.64,0.099,0.031,0,0.0115,0.134,13.52,13.52,6.89,76.16,127,2.401,2.551,1.555,28019
"Infant formula, ABBOTT NUTRITION, SIMILAC, ADVANCE, with iron, powder, not reconstituted (formerly ROSS)",Baby Foods,10.89,0.41,0.126,0,0.047299999999999995,0.552,54.73,54.73,28.87,2.25,522,9.873,10.491,6.396,28020
"Infant formula, ABBOTT NUTRITION, SIMILAC, ADVANCE, with iron, ready-to-feed (formerly ROSS)",Baby Foods,1.36,0.053,0.016,0,0.005900000000000001,0.069,6.77,6.9,3.7,87.79,66,1.237,1.314,0.801,28021
"Infant formula, ABBOTT NUTRITION, SIMILAC, ALIMENTUM, ADVANCE, ready-to-feed, with ARA and DHA (formerly ROSS)",Baby Foods,1.8,0.069,0.029,0,0.005900000000000001,0.077,6.77,4.4,3.63,87.32,67,1.838,0.294,1.126,28022
"Infant formula, ABBOTT NUTRITION, SIMILAC, ALIMENTUM, with iron, ready-to-feed (formerly ROSS)",Baby Foods,1.8,0.069,0.029,0,0.005900000000000001,0.077,6.77,4.4,3.63,87.32,66,1.838,0.294,1.113,28023
"Infant formula, ABBOTT NUTRITION, SIMILAC, ISOMIL, ADVANCE with iron, liquid concentrate (formerly ROSS)",Baby Foods,3.13,0.134,0.056,0,0.0115,0.138,13.21,13.1,6.98,75.81,128,2.262,2.66,1.625,28024
"Infant formula, ABBOTT NUTRITION, SIMILAC, ISOMIL, ADVANCE with iron, powder, not reconstituted (formerly ROSS)",Baby Foods,12.6,0.54,0.226,0,0.046299999999999994,0.555,53.57,52.97,28.09,2.5,517,9.167,10.778,6.584,28025
"Infant formula, ABBOTT NUTRITION, SIMILAC, ISOMIL, ADVANCE with iron, ready-to-feed (formerly ROSS)",Baby Foods,1.61,0.069,0.029,0,0.005900000000000001,0.071,6.7,6.7,3.59,87.54,66,1.166,1.371,0.838,28026
"Infant formula, ABBOTT NUTRITION, SIMILAC, ISOMIL, with iron, liquid concentrate (formerly ROSS)",Baby Foods,3.13,0.134,0.056,0,0.0115,0.138,13.21,13.1,6.98,75.81,128,2.262,2.66,1.625,28027
"Infant formula, ABBOTT NUTRITION, SIMILAC, ISOMIL, with iron, powder, not reconstituted (formerly ROSS)",Baby Foods,12.6,0.54,0.226,0,0.046299999999999994,0.555,53.57,52.97,28.09,2.5,517,9.167,10.778,6.584,28028
"Infant formula, ABBOTT NUTRITION, SIMILAC, ISOMIL, with iron, ready-to-feed (formerly ROSS)",Baby Foods,1.61,0.069,0.029,0,0.005900000000000001,0.071,6.7,6.7,3.59,87.54,66,1.166,1.371,0.838,28029
"Infant formula, ABBOTT NUTRITION, SIMILAC, NEOSURE, powder, with ARA and DHA (formerly ROSS)",Baby Foods,14.42,0.541,0.17,0,0.0772,0.731,51.75,52.02,28.33,2.25,520,9.871,10.492,6.66,28030
"Infant formula, ABBOTT NUTRITION, SIMILAC, PM 60/40, powder not reconstituted (formerly ROSS)",Baby Foods,11.4,0.288,0.123,0,0.046200000000000005,0.411,54.94,52.38,28.71,2.25,524,12.214,5.242,10.835,28031
"Infant formula, ABBOTT NUTRITION, SIMILAC, SENSITIVE (LACTOSE FREE) ready-to-feed, with ARA and DHA (formerly ROSS)",Baby Foods,1.48,0.058,0.021,0,0.006,0.074,7.04,7.4,3.74,87,68,2.43,0.384,0.832,28032
"Infant formula, ABBOTT NUTRITION, SIMILAC, SENSITIVE, (LACTOSE FREE), liquid concentrate, with ARA and DHA (formerly ROSS)",Baby Foods,2.73,0.108,0.039,0,0.0115,0.138,13.64,13.78,6.95,75.88,128,4.522,0.715,1.548,28033
"Infant formula, ABBOTT NUTRITION, SIMILAC, SENSITIVE, (LACTOSE FREE), powder, with ARA and DHA (formerly ROSS)",Baby Foods,11.13,0.436,0.158,0,0.0451,0.557,55.53,55.67,28.14,2.2,520,18.305,2.894,6.27,28034
"Infant formula, ABBOTT NUTRITION, SIMILAC, SPECIAL CARE, ADVANCE 24, with iron, ready-to-feed, with ARA and DHA (formerly ROSS)",Baby Foods,1.8,0.141,0.034,0,0.0291,0.101,8.73,8.3,3.26,85.61,71,2.12,0.324,0.737,28035
"Infant formula, ABBOTT NUTRITION, SIMILAC, low iron, liquid concentrate, not reconstituted (formerly ROSS)",Baby Foods,2.64,0.099,0.031,0,0.0115,0.134,13.76,13.76,6.89,76.16,127,2.401,2.551,1.555,28036
"Infant formula, ABBOTT NUTRITION, SIMILAC, low iron, powder, not reconstituted (formerly ROSS)",Baby Foods,10.85,0.41,0.126,0,0.047299999999999995,0.552,54.78,54.7,28.87,2.25,522,9.873,10.491,6.396,28037
"Infant formula, ABBOTT NUTRITION, SIMILAC, low iron, ready-to-feed (formerly ROSS)",Baby Foods,1.36,0.051,0.016,0,0.005900000000000001,0.069,6.93,6.93,3.55,87.79,65,1.237,1.314,0.801,28038
"Infant formula, ABBOTT NUTRITION, SIMILAC, with iron, liquid concentrate, not reconstituted (formerly ROSS)",Baby Foods,2.64,0.099,0.031,0,0.0115,0.134,13.76,13.76,6.89,76.16,127,2.401,2.551,1.555,28039
"Infant formula, ABBOTT NUTRITION, SIMILAC, with iron, powder, not reconstituted (formerly ROSS)",Baby Foods,10.89,0.41,0.126,0,0.047299999999999995,0.552,54.73,54.73,28.87,2.25,522,9.873,10.491,6.396,28040
"Infant formula, ABBOTT NUTRITION, SIMILAC, with iron, ready-to-feed (formerly ROSS)",Baby Foods,1.36,0.051,0.016,0,0.005900000000000001,0.069,6.92,6.9,3.55,87.79,65,1.237,1.314,0.801,28041
"Infant formula, MEAD JOHNSON, ENFAMIL LIPIL, with iron, ready-to-feed, with ARA and DHA",Baby Foods,1.38,0.051,0.018,0,0.0079,0.071,7.1,7.1,3.5,88.35,64,1.453,1.255,0.67,28042
"Infant formula, MEAD JOHNSON, ENFAMIL, AR LIPIL, powder, with ARA and DHA",Baby Foods,12.73,0.397,0.204,0,0.0611,0.55,56.03,56.03,25.97,3,509,10.942,9.292,5.124,28043
"Infant formula, MEAD JOHNSON, ENFAMIL, AR LIPIL, ready-to-feed, with ARA and DHA",Baby Foods,1.71,0.053,0.027,0,0.008199999999999999,0.074,7.53,6.44,3.49,87,68,1.453,1.255,0.67,28044
"Infant formula, MEAD JOHNSON, ENFAMIL, ENFACARE LIPIL, powder, with ARA and DHA",Baby Foods,14,0.59,0.173,0,0.079,0,54.6,51,26,2.5,508,10.8,9.1,5.9,28045
"Infant formula, MEAD JOHNSON, ENFAMIL, LACTOFREE LIPIL, with iron, powder, with ARA and DHA",Baby Foods,10.9,0.43,0.156,0,0.062,0.57,56.3,54.77,28,2.2,521,11.9,10.4,5.5,28046
"Infant formula, MEAD JOHNSON, ENFAMIL, LACTOFREE, LIPIL, with iron, liquid concentrate, not reconstituted, with ARA and DHA",Baby Foods,2.68,0.104,0.038,0,0.015300000000000001,0.14,14.42,13.96,7.11,75.47,132,3.059,2.642,1.411,28047
"Infant formula, MEAD JOHNSON, ENFAMIL, LACTOFREE, ready-to-feed",Baby Foods,1.38,0.053,0.019,0,0.0079,0.072,7.18,7.18,3.5,88.35,63,1.495,1.291,0.689,28048
"Infant formula, MEAD JOHNSON, ENFAMIL, LACTOFREE, with iron, liquid concentrate, not reconstituted",Baby Foods,2.68,0.104,0.038,0,0.015300000000000001,0.14,14.42,13.96,6.79,75.47,130,2.906,2.509,1.283,28049
"Infant formula, MEAD JOHNSON, ENFAMIL, LACTOFREE, with iron, powder, not reconstituted",Baby Foods,10.85,0.43,0.156,0,0.062,0.58,56.35,57,28,2.2,521,12.02,10.43,5.26,28050
"Infant formula, MEAD JOHNSON, ENFAMIL, LIPIL, low iron, liquid concentrate, with ARA and DHA",Baby Foods,2.76,0.102,0.039,0,0.0158,0.142,14.24,14.24,7.16,75.32,131,3.078,2.659,1.419,28051
"Infant formula, MEAD JOHNSON, ENFAMIL, LIPIL, low iron, powder, with ARA and DHA",Baby Foods,10.8,0.4,0.139,0,0.062,0.56,56.2,56,27,3.3,511,11.2,9.63,5.2,28052
"Infant formula, MEAD JOHNSON, ENFAMIL, LIPIL, low iron, ready to feed, with ARA and DHA",Baby Foods,1.38,0.051,0.018,0,0.0079,0.071,7.18,7.18,3.5,88.35,64,1.453,1.255,0.67,28053
"Infant formula, MEAD JOHNSON, ENFAMIL, LIPIL, ready-to-feed, with ARA and DHA",Baby Foods,1.38,0.054,0.019,0,0.0079,0.072,6.44,6.44,3.5,88.35,66,1.453,1.255,0.67,28054
"Infant formula, MEAD JOHNSON, ENFAMIL, LIPIL, with iron, liquid concentrate, with ARA and DHA",Baby Foods,2.76,0.102,0.035,0,0.0158,0.142,14.34,14,6.96,75.34,131,3.08,2.656,1.418,28055
"Infant formula, MEAD JOHNSON, ENFAMIL, LIPIL, with iron, powder, with ARA and DHA",Baby Foods,10.8,0.4,0.139,0,0.062,0.56,56.2,56,27,3.3,511,11.221,9.899,5.24,28056
"Infant formula, MEAD JOHNSON, ENFAMIL, NUTRAMIGEN LIPIL, with iron, powder, not reconstituted, with ARA and DHA",Baby Foods,13.9,0.47,0.23,0,0.06,0.55,51,51,26,3,494,11.2,9.6,5.2,28057
"Infant formula, MEAD JOHNSON, ENFAMIL, NUTRAMIGEN LIPIL, with iron, ready to feed, with ARA and DHA",Baby Foods,1.83,0.062,0.031,0,0.0079,0.072,6.81,5.13,3.5,87.38,66,1.453,1.255,0.67,28058
"Infant formula, MEAD JOHNSON, ENFAMIL, NUTRAMIGEN, LIPIL, with iron, liquid concentrate not reconstituted, with ARA and DHA",Baby Foods,3.53,0.123,0.06,0,0.015099999999999999,0.138,13.12,9.87,6.73,75.7,126,2.798,2.486,1.327,28059
"Infant formula, MEAD JOHNSON, ENFAMIL, NUTRAMIGEN, with iron, liquid concentrate, not reconstituted",Baby Foods,3.53,0.12,0.06,0,0.015099999999999999,0.138,13.12,13.08,6.73,75.7,127,2.879,2.493,1.159,28060
"Infant formula, MEAD JOHNSON, ENFAMIL, NUTRAMIGEN, with iron, powder, not reconstituted",Baby Foods,14.2,0.47,0.23,0,0.06,0.55,55,55,25,2.5,502,10.99,9.52,4.93,28061
"Infant formula, MEAD JOHNSON, ENFAMIL, NUTRAMIGEN, with iron, ready-to-feed",Baby Foods,1.83,0.062,0.031,0,0.0079,0.072,6.81,5.13,3.5,87.38,66,1.364,1.206,0.617,28062
"Infant formula, MEAD JOHNSON, ENFAMIL, PROSOBEE LIPIL, with iron, powder, not reconstituted, with ARA and DHA",Baby Foods,12.5,0.53,0.171,0,0.06,0.6,53,53,27,3.5,510,11.37,10.32,5.2,28063
"Infant formula, MEAD JOHNSON, ENFAMIL, PROSOBEE, LIPIL, liquid concentrate, not reconstituted, with ARA and DHA",Baby Foods,3.19,0.134,0.045,0,0.015300000000000001,0.153,13.58,13.58,7.11,75.47,131,2.969,2.598,1.378,28064
"Infant formula, MEAD JOHNSON, ENFAMIL, PROSOBEE, with iron, powder, not reconstituted",Baby Foods,12.5,0.53,0.18,0,0.06,0.6,54.3,53,27,3,510,11.37,10.32,5.2,28065
"Infant formula, MEAD JOHNSON, ENFAMIL, low iron, liquid concentrate, not reconstituted",Baby Foods,2.76,0.102,0.035,0,0.0158,0.142,14.05,14,7.25,75.34,132,3.084,2.693,1.437,28066
"Infant formula, MEAD JOHNSON, ENFAMIL, low iron, powder, not reconstituted",Baby Foods,10.8,0.4,0.139,0,0.062,0.56,56.2,56,27,3.3,511,11.5,10,5.3,28067
"Infant formula, MEAD JOHNSON, ENFAMIL, low iron, ready-to-feed",Baby Foods,1.38,0.051,0.018,0,0.0079,0.071,7.18,7.18,3.5,88.35,63,1.479,1.291,0.689,28068
"Infant formula, MEAD JOHNSON, ENFAMIL, with iron, liquid concentrate, not reconstituted",Baby Foods,2.68,0.1,0.035,0,0.015300000000000001,0.138,13.96,13.96,7.31,75.47,128,3.093,2.8,1.41,28069
"Infant formula, MEAD JOHNSON, ENFAMIL, with iron, powder",Baby Foods,10.8,0.4,0.139,0,0.062,0.56,56.2,56,27,3.3,520,11.5,10.3,5.2,28070
"Infant formula, MEAD JOHNSON, ENFAMIL, with iron, ready-to-feed",Baby Foods,1.38,0.051,0.018,0,0.0079,0.071,7.18,7.18,3.5,88.35,63,1.469,1.33,0.67,28071
"Infant formula, MEAD JOHNSON, NEXT STEP PROSOBEE, powder, not reconstituted",Baby Foods,15.6,0.92,0.171,0,0.057,0.57,57.1,56,21,3,480,8.905,7.8,4.2,28072
"Infant formula, MEAD JOHNSON, NEXT STEP, PROSOBEE LIPIL, powder, with ARA and DHA",Baby Foods,15.6,0.92,0.171,0,0.057,0.57,57.1,56,21,3,480,8.905,7.8,4.2,28073
"Infant formula, MEAD JOHNSON, NEXT STEP, PROSOBEE, LIPIL, ready to feed, with ARA and DHA",Baby Foods,2.14,0.128,0.023,0,0.0079,0.079,8.09,7.77,2.91,86.4,67,1.242,1.078,0.573,28074
"Infant formula, MEAD JOHNSON, PREGESTIMIL, with iron, powder, not reconstituted",Baby Foods,14,0.58,0.24,0,0.06,0.55,52.8,51,28,2,519,16.8,4.6,6.5,28075
"Infant formula, MEAD JOHNSON, PREGESTIMIL, with iron, prepared from powder",Baby Foods,1.82,0.075,0.031,0,0.0078,0.071,6.63,6.63,3.65,87.5,67,2.172,0.583,0.809,28076
"Infant formula, MEAD JOHNSON, PROSOBEE LIPIL, with iron, ready to feed, with ARA and DHA",Baby Foods,1.64,0.069,0.023,0,0.0079,0.079,6.11,6.11,3.5,88.35,64,1.453,1.255,0.671,28077
"Infant formula, MEAD JOHNSON, PROSOBEE, with iron, liquid concentrate, not reconstituted",Baby Foods,3.19,0.134,0.045,0,0.015300000000000001,0.153,13.58,13.58,7.13,75.47,131,3.038,2.72,1.371,28078
"Infant formula, MEAD JOHNSON, PROSOBEE, with iron, ready-to-feed",Baby Foods,1.64,0.069,0.023,0,0.0079,0.079,6.11,6.11,3.5,88.35,63,1.443,1.292,0.651,28079
"Infant formula, MEAD JOHNSON,NEXT STEP PROSOBEE, prepared from powder",Baby Foods,2.14,0.128,0.023,0,0.0079,0.079,8.08,7.77,2.91,86.41,67,1.243,1.078,0.544,28080
"Infant formula, NESTLE, GOOD START 2 ESSENTIALS, with iron, liquid concentrate, not reconstituted",Baby Foods,3.25,0.15,0.049,0,0.011,0.169,16.5,11.9,5.13,74.2,125,2.213,1.582,1.213,28081
"Infant formula, NESTLE, GOOD START 2 ESSENTIALS, with iron, powder",Baby Foods,12.2,0.565,0.184,0,0.042,0.636,62.23,44.3,19.3,2.5,471,8.54,6.23,4.31,28082
"Infant formula, NESTLE, GOOD START 2 ESSENTIALS, with iron, ready-to-feed",Baby Foods,1.7,0.078,0.025,0,0.005900000000000001,0.088,8.54,6.19,2.7,86.6,65,1.18,0.87,0.6,28083
"Infant formula, NESTLE, GOOD START ESSENTIALS SOY, with iron, liquid concentrate, not reconstituted",Baby Foods,3.2,0.133,0.046,0,0.0152,0.146,14.1,11.2,6.5,75.4,128,2.8,2.03,1.317,28084
"Infant formula, NESTLE, GOOD START ESSENTIALS SOY, with iron, powder",Baby Foods,12.5,0.526,0.18,0,0.0601,0.581,55.7,44.38,25.5,2.5,502,11,8.1,6,28085
"Infant formula, NESTLE, GOOD START ESSENTIALS SOY, with iron, ready-to-feed",Baby Foods,1.63,0.068,0.023,0,0.0078,0.075,7.17,5.69,3.3,87.5,65,1.4,1,0.8,28086
"Infant formula, NESTLE, GOOD START SOY, with ARA and DHA, powder",Baby Foods,12.5,0.526,0.2,0,0.0601,0.581,55.6,44.38,25.6,2.5,503,11,8.1,6,28087
"Infant formula, NESTLE, GOOD START SOY, with DHA and ARA, liquid concentrate",Baby Foods,3.31,0.139,0.053,0,0.0159,0.154,14.71,11.74,6.67,74.2,132,2.897,2.198,1.431,28088
"Infant formula, NESTLE, GOOD START SOY, with DHA and ARA, ready-to-feed",Baby Foods,1.6,0.067,0.026,0,0.0077,0.074,7.13,5.68,3.28,87.5,64,1.4,1.189,0.695,28089
"Infant formula, NESTLE, GOOD START SUPREME, with iron, DHA and ARA, prepared from liquid concentrate",Baby Foods,1.45,0.042,0.018,0,0.005900000000000001,0.071,7.39,5,3.37,87.5,66,1.47,1.08,0.74,28090
"Infant formula, NESTLE, GOOD START SUPREME, with iron, DHA and ARA, ready-to-feed",Baby Foods,1.45,0.042,0.018,0,0.005900000000000001,0.071,7.39,5,3.37,87.5,66,1.47,1.08,0.74,28091
"Infant formula, NESTLE, GOOD START SUPREME, with iron, liquid concentrate, not reconstituted",Baby Foods,2.79,0.081,0.034,0,0.011,0.137,14.2,9.7,6.5,75.8,127,2.97,2.06,1.42,28092
"Infant formula, NESTLE, GOOD START SUPREME, with iron, powder",Baby Foods,11.3,0.343,0.138,0,0.0461,0.553,57.32,39.3,26.1,2.5,509,11.66,8.5,5.88,28093
"Infant formula, NESTLE, GOOD START SUPREME, with iron, ready-to-feed",Baby Foods,1.45,0.042,0.018,0,0.005900000000000001,0.071,7.39,5,3.37,87.5,66,1.47,1.08,0.74,28094
"Infant formula, PBM PRODUCTS, ULTRA BRIGHT BEGINNINGS, liquid concentrate, not reconstituted (formerly WYETH-AYERST)",Baby Foods,2.9,0.083,0.029,0,0.0112,0.109,13.9,13.9,7,76,130,3.2,2.8,1,28095
"Infant formula, PBM PRODUCTS, ULTRA BRIGHT BEGINNINGS, powder (formerly WYETH-AYERST)",Baby Foods,12,0.331,0.118,0,0.0433,0.441,56,56,28,2,524,12.761,11.131,3.951,28096
"Infant formula, PBM PRODUCTS, ULTRA BRIGHT BEGINNINGS, ready-to-feed (formerly WYETH-AYERST)",Baby Foods,1.4,0.041,0.014,0,0.0056,0.055,6.4,6.4,3.5,88.1,63,1.6,1.4,0.5,28097
"Infant formula, PBM PRODUCTS, ULTRA BRIGHT BEGINNINGS, soy, liquid concentrate (formerly WYETH-AYERST)",Baby Foods,3.6,0.118,0.036,0,0.0108,0.138,12.18,12.18,7,76,126,3.2,2.8,1,28098
"Infant formula, PBM PRODUCTS, ULTRA BRIGHT BEGINNINGS, soy, powder (formerly WYETH-AYERST)",Baby Foods,13.6,0.453,0.151,0,0.042,0.528,52.2,52.2,27.2,2,508,12.37,10.78,3.83,28099
"Infant formula, PBM PRODUCTS, ULTRA BRIGHT BEGINNINGS, soy, ready-to-feed (formerly WYETH-AYERST)",Baby Foods,1.8,0.059,0.018,0,0.0054,0.069,6.09,6.1,3.5,88,63,1.6,1.4,0.5,28100
"Infant formula, PBM PRODUCTS, store brand, liquid concentrate, not reconstituted (formerly WYETH-AYERST)",Baby Foods,2.9,0.083,0.029,0,0.0112,0.109,13.9,13.9,7,76,130,3.2,2.8,1,28101
"Infant formula, PBM PRODUCTS, store brand, powder (formerly WYETH-AYERST)",Baby Foods,12,0.331,0.118,0,0.0433,0.441,56,56,28,2,524,12.761,11.131,3.951,28102
"Infant formula, PBM PRODUCTS, store brand, ready-to-feed (formerly WYETH-AYERST)",Baby Foods,1.4,0.041,0.014,0,0.0056,0.055,6.39,6.4,3.5,88.1,63,1.6,1.4,0.5,28103
"Infant formula, PBM PRODUCTS, store brand, soy, liquid concentrate, not reconstituted (formerly WYETH-AYERST)",Baby Foods,3.6,0.118,0.036,0,0.0108,0.138,12.18,12.18,7,76,126,3.2,2.8,1,28104
"Infant formula, PBM PRODUCTS, store brand, soy, powder (formerly WYETH-AYERST)",Baby Foods,13.6,0.453,0.151,0,0.042,0.528,52.2,52.2,27.2,2,508,12.37,10.78,3.83,28105
"Infant formula, PBM PRODUCTS, store brand, soy, ready-to-feed (formerly WYETH-AYERST)",Baby Foods,1.8,0.059,0.018,0,0.0054,0.069,6.09,6.1,3.5,88,63,1.6,1.4,0.5,28106
Zwieback,Baby Foods,10.1,0.02,0.227,2.5,0.0053,0.305,74.2,12.5,9.7,4.5,426,2.525,4.244,2.073,28107
"Animal fat, bacon grease",Fats and Oils,0,0,0.15,0,0,0,0,0,99.5,0,897,39.004,44.874,11.144,28108
"Butter replacement, without fat, powder",Fats and Oils,2,0.023,1.2,0,0,0.002,89,5.3,1,5,373,0.589,0.276,0.057,28109
"Butter, light, stick, with salt",Fats and Oils,3.3,0.048,0.45,0,0,0.071,0,0,55.1,42.1,509,34.321,15.927,2.046,28110
"Butter, light, stick, without salt",Fats and Oils,3.3,0.048,0.036,0,0,0.071,0,0,55.1,43.2,499,34.321,15.927,2.046,28111
"Creamy dressing, made with sour cream and/or buttermilk and oil, reduced calorie",Fats and Oils,1.5,0.006,1.12,0,0.0004,0.036,7,5.65,14,74,160,2.115,3.273,7.994,28112
"Creamy dressing, made with sour cream and/or buttermilk and oil, reduced calorie, cholesterol-free",Fats and Oils,1,0.036,0.932,0,0,0.049,16,3.28,8,73,140,1.355,3.391,2.891,28113
"Creamy dressing, made with sour cream and/or buttermilk and oil, reduced calorie, fat-free",Fats and Oils,1.4,0.036,0.897,0,0,0.133,20,5.35,2.7,74.7,107,0.494,0.64,1.444,28114
"Fat, beef tallow",Fats and Oils,0,0,0,0,0,0,0,0,100,0,902,49.8,41.8,4,28115
"Fat, chicken",Fats and Oils,0,0,0,0,0,0,0,0,99.8,0.2,900,29.8,44.7,20.9,28116
"Fat, duck",Fats and Oils,0,0,0,0,0,0,0,0,99.8,0.2,882,33.2,49.3,12.9,28117
"Fat, goose",Fats and Oils,0,0,0,0,0,0,0,0,99.8,0.2,900,27.7,56.7,11,28118
"Fat, mutton tallow",Fats and Oils,0,0,0,0,0,0,0,0,100,0,902,47.3,40.6,7.8,28119
"Fat, turkey",Fats and Oils,0,0,0,0,0,0,0,0,99.8,0.2,900,29.4,42.9,23.1,28120
"Fish oil, cod liver",Fats and Oils,0,0,0,0,0,0,0,0,100,0,902,22.608,46.711,22.541,28121
"Fish oil, herring",Fats and Oils,0,0,0,0,0,0,0,0,100,0,902,21.29,56.564,15.604,28122
"Fish oil, menhaden",Fats and Oils,0,0,0,0,0,0,0,0,100,0,902,30.427,26.694,34.197,28123
"Fish oil, menhaden, fully hydrogenated",Fats and Oils,0,0,0,0,0,0,0,0,100,0,902,95.6,0,0,28124
"Fish oil, salmon",Fats and Oils,0,0,0,0,0,0,0,0,100,0,902,19.872,29.037,40.324,28125
"Fish oil, sardine",Fats and Oils,0,0,0,0,0,0,0,0,100,0,902,29.892,33.841,31.867,28126
Lard,Fats and Oils,0,0,0,0,0,0,0,0,100,0,902,39.2,45.1,11.2,28127
"Margarine Spread, approximately 48% fat, tub",Fats and Oils,0.2,0.004,0.646,0,0,0.036,0.86,0,47.53,49.66,424,8.792,16.416,20.235,28128
"Margarine, 80% fat, stick, includes regular and hydrogenated corn and soybean oils",Fats and Oils,0.16,0.003,0.654,0,0,0.018,0.7,0,80.71,16.52,717,15.189,38.877,24.302,28129
"Margarine, 80% fat, tub, CANOLA HARVEST Soft Spread (canola, palm and palm kernel oils)",Fats and Oils,0.41,0,0.714,0,0,0,1.39,0,80.32,16.06,730,11.648,45.262,21.344,28130
"Margarine, industrial, non-dairy, cottonseed, soy oil (partially hydrogenated ), for flaky pastries",Fats and Oils,1.9,0.066,0.879,0,0.0004,0.094,0,0,80.2,15.8,714,20.442,46.692,9.265,28131
"Margarine, industrial, soy and partially hydrogenated soy oil, use for baking, sauces and candy",Fats and Oils,0.18,0.003,0.886,0,0,0.018,0.71,0,80,17.17,714,16.321,37.46,22.422,28132
"Margarine, margarine-like vegetable oil spread, 67-70% fat, tub",Fats and Oils,0.07,0,0.536,0,0,0,0.59,0,68.29,29.52,606,16.688,24.748,23.794,28133
"Margarine, margarine-type vegetable oil spread, 70% fat, soybean and partially hydrogenated soybean, stick",Fats and Oils,0.26,0.007,0.7,0,0,0.046,1.53,0,70.22,26.23,628,13.631,33.468,20.248,28134
"Margarine, regular, 80% fat, composite, stick, with salt",Fats and Oils,0.16,0.003,0.751,0,0.0002,0.018,0.7,0,80.71,16.52,717,15.189,38.877,24.302,28135
"Margarine, regular, 80% fat, composite, stick, with salt, with added vitamin D",Fats and Oils,0.16,0.003,0.751,0,0.0002,0.018,0.7,0,80.71,16.52,717,15.189,38.877,24.302,28136
"Margarine, regular, 80% fat, composite, stick, without salt",Fats and Oils,0.16,0.003,0.002,0,0.0002,0.018,0.7,0,80.71,16.52,717,15.189,38.877,24.302,28137
"Margarine, regular, 80% fat, composite, stick, without salt, with added vitamin D",Fats and Oils,0.16,0.003,0.002,0,0.0002,0.018,0.7,0,80.71,16.52,717,15.189,38.877,24.302,28138
"Margarine, regular, 80% fat, composite, tub, with salt",Fats and Oils,0.22,0.003,0.657,0,0.0001,0.017,0.75,0,80.17,17.07,713,14.224,36.435,26.741,28139
"Margarine, regular, 80% fat, composite, tub, with salt, with added vitamin D",Fats and Oils,0.22,0.003,0.657,0,0.0001,0.017,0.75,0,80.17,17.07,713,14.224,36.435,26.741,28140
"Margarine, regular, 80% fat, composite, tub, without salt",Fats and Oils,0.22,0.003,0.028,0,0.0001,0.017,0.75,0,80.17,17.07,713,14.224,36.435,26.741,28141
"Margarine, regular, hard, soybean (hydrogenated)",Fats and Oils,0.9,0.03,0.943,0,0.0002,0.042,0.9,0,80.5,15.7,719,16.7,39.3,20.9,28142
"Margarine-like shortening, industrial, soy (partially hydrogenated), cottonseed, and soy, principal use flaky pastries",Fats and Oils,0,0,0.864,0,0,0,0,0,71,28,628,22.869,37.243,7.512,28143
"Margarine-like spread with yogurt, 70% fat, stick, with salt",Fats and Oils,0.3,0.02,0.59,0,0.0001,0.025,0.5,0,70,28,630,13.3,39.9,14,28144
"Margarine-like spread with yogurt, approximately 40% fat, tub, with salt",Fats and Oils,2,0.05,0.63,0,0.0002,0.064,2,0,35,59,330,7,9.8,16.8,28145
"Margarine-like spread, BENECOL Light Spread",Fats and Oils,0,0.004,0.67,0,0,0.004,5.71,0,38.71,53.73,357,4.77,18.932,11.948,28146
"Margarine-like spread, SMART BALANCE Light Buttery Spread",Fats and Oils,0.02,0.003,0.58,0,0,0.032,1.98,0,36.41,59.89,337,10.087,13.699,9.651,28147
"Margarine-like spread, SMART BALANCE Omega Plus Spread (with plant sterols & fish oil)",Fats and Oils,0.13,0,0.729,0,0,0.071,0.16,0,70.95,27.86,605,19.83,25.334,18.484,28148
"Margarine-like spread, SMART BALANCE Regular Buttery Spread with flax oil",Fats and Oils,0.14,0,0.646,0,0,0.028,0.14,0,64.63,33.95,583,16.69,26.207,17.822,28149
"Margarine-like spread, SMART BEAT Smart Squeeze",Fats and Oils,0,0,0.829,0,0,0.026,7.1,1,2.1,87.91,47,0.31,0.47,1.18,28150
"Margarine-like spread, SMART BEAT Super Light without saturated fat",Fats and Oils,0,0,0.755,0,0,0.012,0,0,17.1,80.83,158,2.22,7.6,3.98,28151
"Margarine-like vegetable-oil spread, stick/tub/bottle, 60% fat, with added vitamin D",Fats and Oils,0.6,0.021,0.785,0,0.0001,0.03,0,0,59.17,38.68,535,10.042,20.316,24.686,28152
"Margarine-like, butter-margarine blend, 80% fat, stick, without salt",Fats and Oils,0.9,0.028,0.028,0,0.0001,0.037,0.6,0,80.7,15.8,718,26.904,31.832,18.236,28153
"Margarine-like, margarine-butter blend, soybean oil and butter",Fats and Oils,0.31,0.01,0.633,0,0.0001,0.022,0.77,0,80.32,17.07,714,14.198,30.292,24.17,28154
"Margarine-like, vegetable oil spread, 20% fat, with salt",Fats and Oils,0,0,0.733,0,0,0.025,0.4,0,19.5,78.8,175,2.87,7.48,8.04,28155
"Margarine-like, vegetable oil spread, 20% fat, without salt",Fats and Oils,0,0,0,0,0,0.025,0.4,0,19.5,78.8,175,2.87,7.48,8.04,28156
"Margarine-like, vegetable oil spread, 60% fat, stick, with salt",Fats and Oils,0.12,0.021,0.785,0,0.0001,0.03,0.69,0,60.39,36.95,537,10.884,29.664,16.718,28157
"Margarine-like, vegetable oil spread, 60% fat, stick, with salt, with added vitamin D",Fats and Oils,0.12,0.021,0.785,0,0.0001,0.03,0.69,0,60.39,36.95,537,10.884,29.664,16.718,28158
"Margarine-like, vegetable oil spread, 60% fat, stick/tub/bottle, with salt",Fats and Oils,0.6,0.021,0.7,0,0.0001,0.03,0,0,59.17,38.68,526,10.042,20.316,24.686,28159
"Margarine-like, vegetable oil spread, 60% fat, stick/tub/bottle, without salt",Fats and Oils,0.17,0.021,0.002,0,0.0001,0.03,0.86,0,59.81,37.58,533,12.086,19.348,26.477,28160
"Margarine-like, vegetable oil spread, 60% fat, stick/tub/bottle, without salt, with added vitamin D",Fats and Oils,0.17,0.021,0.002,0,0.0001,0.03,0.86,0,59.81,37.58,542,12.086,19.348,26.477,28161
"Margarine-like, vegetable oil spread, 60% fat, tub, with salt",Fats and Oils,0.17,0.021,0.615,0,0.0001,0.03,0.86,0,59.81,37.58,533,12.086,19.348,26.477,28162
"Margarine-like, vegetable oil spread, 60% fat, tub, with salt, with added vitamin D",Fats and Oils,0.17,0.021,0.785,0,0.0001,0.03,0.86,0,59.81,37.58,533,12.086,19.347,26.477,28163
"Margarine-like, vegetable oil spread, approximately 37% fat, unspecified oils, with salt, with added vitamin D",Fats and Oils,0.51,0.006,0.589,0,0.0001,0.034,0.66,0,37.77,59.55,339,8.637,13.958,12.735,28164
"Margarine-like, vegetable oil spread, fat free, liquid, with salt",Fats and Oils,1.5,0.038,0.833,0,0.0003,0.05,2.5,0,3,91,43,0.416,0.74,1.764,28165
"Margarine-like, vegetable oil spread, fat-free, tub",Fats and Oils,0.1,0.008,0.58,0,0,0.036,4.34,0,3.04,90.69,44,2.128,0.356,0.422,28166
"Margarine-like, vegetable oil spread, stick or tub, sweetened",Fats and Oils,0,0,0.542,0,0,0.03,16.7,16.7,52,30,534,8.35,17,23.8,28167
"Margarine-like, vegetable oil spread, unspecified oils, approximately 37% fat, with salt",Fats and Oils,0.51,0.006,0.589,0,0.0001,0.034,0.66,0,37.77,59.55,339,8.637,13.958,12.407,28168
"Margarine-like, vegetable oil-butter spread, reduced calorie, tub, with salt",Fats and Oils,1,0.028,0.607,0,0.0001,0.037,1,0,50,46,450,16.669,19.722,11.299,28169
"Margarine-like, vegetable oil-butter spread, tub, with salt",Fats and Oils,1,0.024,0.786,0,0.0001,0.036,1,0,40,56,362,7.238,18.438,12.573,28170
"Mayonnaise dressing, no cholesterol",Fats and Oils,0,0.007,0.486,0,0,0.014,0.3,0.3,77.8,21.7,688,10.784,18.026,45.539,28171
"Mayonnaise, low sodium, low calorie or diet",Fats and Oils,0.3,0,0.11,0,0,0.01,16,4.2,19.2,62.7,231,3.3,4.5,10.6,28172
"Mayonnaise, made with tofu",Fats and Oils,5.95,0.053,0.773,1.1,0,0.066,3.06,0.85,31.79,56.33,322,2.921,5.246,16.59,28173
"Mayonnaise, reduced-calorie or diet, cholesterol-free",Fats and Oils,0.9,0,0.733,0,0,0.067,6.7,4.2,33.3,56,333,4.618,7.707,19.5,28174
"Meat drippings (lard, beef tallow, mutton tallow)",Fats and Oils,0,0,0.545,0,0,0,0,0,98.59,0,889,44.79,41.77,7.56,28175
"Oil, PAM cooking spray, original",Fats and Oils,0.26,0,0.059,0,0,0,20.69,0,78.69,0.11,792,11.6,55.79,9.82,28176
"Oil, almond",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,8.2,69.9,17.4,28177
"Oil, apricot kernel",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,6.3,60,29.3,28178
"Oil, avocado",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,11.56,70.554,13.486,28179
"Oil, babassu",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,81.2,11.4,1.6,28180
"Oil, canola",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,7.365,63.276,28.142,28181
"Oil, cocoa butter",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,59.7,32.9,3,28182
"Oil, coconut",Fats and Oils,0,0,0,0,0,0,0,0,100,0,862,86.5,5.8,1.8,28183
"Oil, cooking and salad, ENOVA, 80% diglycerides",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,4.63,37.016,53.37,28184
"Oil, corn and canola",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,8.026,58.537,29.113,28185
"Oil, corn, industrial and retail, all purpose salad or cooking",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,12.948,27.576,54.677,28186
"Oil, corn, peanut, and olive",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,14.367,48.033,33.033,28187
"Oil, cottonseed, salad or cooking",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,25.9,17.8,51.9,28188
"Oil, cupu assu",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,53.2,38.7,3.8,28189
"Oil, flaxseed",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,9.4,20.2,66,28190
"Oil, grapeseed",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,9.6,16.1,69.9,28191
"Oil, hazelnut",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,7.4,78,10.2,28192
"Oil, industrial, canola (partially hydrogenated) oil for deep fat frying",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,10.117,71.075,14.038,28193
"Oil, industrial, canola for salads, woks and light frying",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,7.758,61.15,26.397,28194
"Oil, industrial, canola with antifoaming agent, principal uses salads, woks and light frying",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,7.615,62.093,25.588,28195
"Oil, industrial, canola, high oleic",Fats and Oils,0,0,0,0,0,0,0,0,100,0,900,6.787,72.734,15.785,28196
"Oil, industrial, coconut (hydrogenated), used for whipped toppings and coffee whiteners",Fats and Oils,0,0.001,0.007,0,0,0.002,0,0,99.5,0.07,880,93.536,0.267,0,28197
"Oil, industrial, coconut, confection fat, typical basis for ice cream coatings",Fats and Oils,0,0.002,0.005,0,0,0.002,0,0,100,0.08,884,86.503,5.681,1.45,28198
"Oil, industrial, coconut, principal uses candy coatings, oil sprays, roasting nuts",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,86.002,5.935,1.657,28199
"Oil, industrial, mid-oleic, sunflower",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,9.009,57.334,28.962,28200
"Oil, industrial, palm and palm kernel, filling fat (non-hydrogenated)",Fats and Oils,0,0.001,0.006,0,0,0.004,0,0,99.5,0.04,880,71.444,19.702,3.247,28201
"Oil, industrial, palm kernel (hydrogenated) , used for whipped toppings, non-dairy",Fats and Oils,0,0.001,0.006,0,0,0.002,0,0,100,0.04,884,88.965,4.934,0,28202
"Oil, industrial, palm kernel (hydrogenated), confection fat, intermediate grade product",Fats and Oils,0,0.001,0.006,0,0,0.001,0,0,100,0.04,884,92.614,1.271,0.037,28203
"Oil, industrial, palm kernel (hydrogenated), confection fat, uses similar to 95 degree hard butter",Fats and Oils,0,0.001,0.006,0,0,0.001,0,0,100,0.05,884,93.701,0.257,0,28204
"Oil, industrial, palm kernel (hydrogenated), filling fat",Fats and Oils,0,0.002,0.006,0,0,0.001,0,0,100,0.08,884,88.209,5.705,0,28205
"Oil, industrial, palm kernel, confection fat, uses similar to high quality cocoa butter",Fats and Oils,0,0.001,0.006,0,0,0.002,0,0,100,0.05,884,87.558,5.406,0.834,28206
"Oil, industrial, soy ( partially hydrogenated), all purpose",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,24.75,61.248,9.295,28207
"Oil, industrial, soy (partially hydrogenated ) and soy (winterized), pourable clear fry",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,15.341,34.63,45.228,28208
"Oil, industrial, soy (partially hydrogenated ), palm, principal uses icings and fillings",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,28.421,59.715,7.095,28209
"Oil, industrial, soy (partially hydrogenated) and cottonseed, principal use as a tortilla shortening",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,25.883,59.133,10.305,28210
"Oil, industrial, soy (partially hydrogenated), multiuse for non-dairy butter flavor",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,18.101,40.262,36.847,28211
"Oil, industrial, soy (partially hydrogenated), principal uses popcorn and flavoring vegetables",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,17.683,41.929,35.638,28212
"Oil, industrial, soy, fully hydrogenated",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,93.966,1.341,0.383,28213
"Oil, industrial, soy, low linolenic",Fats and Oils,0,0,0,0,0,0,0,0,100,0,900,15.084,21.438,58.932,28214
"Oil, industrial, soy, refined, for woks and light frying",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,15.34,21.701,58.208,28215
"Oil, industrial, soy, ultra low linolenic",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,14.853,28.876,52.149,28216
"Oil, mustard",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,11.582,59.187,21.23,28217
"Oil, nutmeg butter",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,90,4.8,0,28218
"Oil, oat",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,19.62,35.11,40.87,28219
"Oil, olive, salad or cooking",Fats and Oils,0,0.001,0.002,0,0,0.001,0,0,100,0,884,13.808,72.961,10.523,28220
"Oil, palm",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,49.3,37,9.3,28221
"Oil, peanut, salad or cooking",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,16.9,46.2,32,28222
"Oil, poppyseed",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,13.5,19.7,62.4,28223
"Oil, rice bran",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,19.7,39.3,35,28224
"Oil, safflower, salad or cooking, high oleic (primary safflower oil of commerce)",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,7.541,75.221,12.82,28225
"Oil, safflower, salad or cooking, linoleic, (over 70%)",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,6.203,14.355,74.623,28226
"Oil, sesame, salad or cooking",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,14.2,39.7,41.7,28227
"Oil, sheanut",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,46.6,44,5.2,28228
"Oil, soybean lecithin",Fats and Oils,0,0,0,0,0,0,0,0,100,0,763,15.005,10.977,45.318,28229
"Oil, soybean, salad or cooking",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,15.65,22.783,57.74,28230
"Oil, soybean, salad or cooking, (partially hydrogenated)",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,14.9,43,37.6,28231
"Oil, soybean, salad or cooking, (partially hydrogenated) and cottonseed",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,18,29.5,48.1,28232
"Oil, sunflower, high oleic (70% and over)",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,9.859,83.689,3.798,28233
"Oil, sunflower, linoleic (less than 60%)",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,10.1,45.4,40.1,28234
"Oil, sunflower, linoleic, (approx. 65%)",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,10.3,19.5,65.7,28235
"Oil, sunflower, linoleic, (partially hydrogenated)",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,13,46.2,36.4,28236
"Oil, teaseed",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,21.1,51.5,23,28237
"Oil, tomatoseed",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,19.7,22.8,53.1,28238
"Oil, ucuhuba butter",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,85.2,6.7,2.9,28239
"Oil, vegetable, Natreon canola, high stability, non trans, high oleic (70%)",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,6.511,71.991,17.098,28240
"Oil, walnut",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,9.1,22.8,63.3,28241
"Oil, wheat germ",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,18.8,15.1,61.7,28242
"Salad Dressing, coleslaw dressing, reduced fat",Fats and Oils,0,0.036,1.6,0.4,0,0.05,40,38.73,20,37,329,2.98,8.6,7.52,28243
"Salad Dressing, mayonnaise, light, SMART BALANCE, Omega Plus light",Fats and Oils,1.53,0.013,0.848,0.2,0,0.063,9.39,5.38,34.18,52.44,333,3.288,16.662,11.776,28244
"Salad Dressing, mayonnaise-like, fat-free",Fats and Oils,0.2,0.006,0.788,1.9,0,0.049,15.5,10.3,2.7,78.7,84,0.6,1.887,0.063,28245
"Salad dressing, KRAFT MIRACLE WHIP FREE Nonfat Dressing",Fats and Oils,0.2,0.006,0.788,1.9,0,0.049,15.5,10.3,2.7,78.7,84,0.6,0,0,28246
"Salad dressing, KRAFT MIRACLE WHIP LIGHT Dressing",Fats and Oils,0.6,0.005,0.821,0.1,0,0.023,14.4,9.9,18.6,63.3,231,2.9,0,0,28247
"Salad dressing, KRAFT Mayo Fat Free Mayonnaise Dressing",Fats and Oils,0.2,0.006,0.75,2,0,0.05,12.4,6.8,2.7,81.8,70,0.5,0,0,28248
"Salad dressing, KRAFT Mayo Light Mayonnaise",Fats and Oils,0.6,0.006,0.797,0.1,0.0003,0.052,8.5,4.2,32.9,55.4,334,5,0,0,28249
"Salad dressing, Mayonnaise dressing, diet, no cholesterol",Fats and Oils,0.9,0.014,0.711,0,0,0.009,23.9,6.4,33.4,39.9,390,5,9,18,28250
"Salad dressing, bacon and tomato",Fats and Oils,1.8,0.004,0.905,0.2,0.0088,0.108,2,2,35,58.7,326,5.435,8.415,19.584,28251
"Salad dressing, blue or roquefort cheese dressing, commercial, regular",Fats and Oils,1.37,0.037,1.041,0.4,0.0007,0.088,4.62,3.48,51.1,39.71,476,8.275,13.279,27.545,28252
"Salad dressing, blue or roquefort cheese dressing, fat-free",Fats and Oils,1.52,0.051,0.814,1.8,0.0005,0.194,25.6,7.44,1.01,68,115,0.45,0.441,0.069,28253
"Salad dressing, blue or roquefort cheese dressing, reduced calorie",Fats and Oils,2.1,0.068,0.939,0,0,0.051,13.2,3.6,2.7,77.8,86,0.628,1.092,0.855,28254
"Salad dressing, blue or roquefort cheese, low calorie",Fats and Oils,5.1,0.089,0.939,0,0.0003,0.005,2.9,2.82,7.2,79.5,99,2.575,1.783,2.422,28255
"Salad dressing, buttermilk, lite",Fats and Oils,1.25,0.04,1.12,1.1,0.0006,0.132,21.33,3.77,12.42,62.04,202,1.25,2.794,4.213,28256
"Salad dressing, caesar dressing, regular",Fats and Oils,2.17,0.048,1.209,0.5,0.0003,0.029,3.3,2.81,57.85,34.3,542,8.789,13.504,32.857,28257
"Salad dressing, caesar, low calorie",Fats and Oils,0.3,0.024,1.148,0.1,0,0.029,18.6,16.32,4.4,73.2,110,0.703,1.124,2.368,28258
"Salad dressing, coleslaw",Fats and Oils,0.9,0.014,0.71,0.1,0,0.009,23.8,20.11,33.4,39.9,390,4.9,9,18,28259
"Salad dressing, french dressing, commercial, regular",Fats and Oils,0.77,0.024,0.836,0,0.0036,0.067,15.58,15.95,44.81,36.56,457,5.648,8.433,21.029,28260
"Salad dressing, french dressing, commercial, regular, without salt",Fats and Oils,0.77,0.024,0,0,0,0.067,15.58,15.95,44.81,36.56,459,5.648,8.433,21.029,28261
"Salad dressing, french dressing, fat-free",Fats and Oils,0.2,0.005,0.853,2.2,0,0.084,32.14,16.45,0.27,65.04,132,0.037,0.141,0.062,28262
"Salad dressing, french dressing, reduced calorie",Fats and Oils,0.4,0.011,0.838,0,0,0.079,27,25.92,13,59,200,1.872,3.029,7.527,28263
"Salad dressing, french dressing, reduced fat",Fats and Oils,0.58,0.011,0.838,1.5,0.0048,0.107,31.22,16.86,11.52,54.28,222,0.842,4.518,3.852,28264
"Salad dressing, french dressing, reduced fat, without salt",Fats and Oils,0.58,0.011,0.03,1.1,0,0.107,29.28,28.45,13.46,54.28,233,1.099,5.898,5.029,28265
"Salad dressing, french, cottonseed, oil, home recipe",Fats and Oils,0.1,0.006,0.658,0,0,0.024,3.4,0,70.2,24.2,631,18.2,0,0,28266
"Salad dressing, french, home recipe",Fats and Oils,0.1,0.006,0.658,0,0.0006,0.024,3.4,0,70.2,24.2,631,12.6,20.7,33.7,28267
"Salad dressing, green goddess, regular",Fats and Oils,1.9,0.034,0.867,0.1,0.0002,0.058,7.36,6.67,43.33,45.03,427,5.978,9.423,23.165,28268
"Salad dressing, home recipe, cooked",Fats and Oils,4.2,0.084,0.734,0,0.0006,0.121,14.9,9.07,9.5,69.2,157,2.9,3.8,2.1,28269
"Salad dressing, home recipe, vinegar and oil",Fats and Oils,0,0,0.001,0,0,0.008,2.5,2.5,50.1,47.4,449,9.1,14.8,24.1,28270
"Salad dressing, honey mustard dressing, reduced calorie",Fats and Oils,0.98,0.067,0.9,0.8,0.0004,0.055,29.27,18.33,10,57.19,207,0.833,5.573,3.152,28271
"Salad dressing, italian dressing, commercial, regular",Fats and Oils,0.38,0.007,1.018,0,0,0.048,10.43,8.32,28.37,56.4,291,4.474,6.315,12.941,28272
"Salad dressing, italian dressing, commercial, regular, without salt",Fats and Oils,0.38,0.007,0.03,0,0,0.048,10.43,8.32,28.37,56.4,292,4.474,6.315,12.941,28273
"Salad dressing, italian dressing, fat-free",Fats and Oils,0.97,0.03,1.129,0.6,0.0004,0.102,8.75,8.85,0.87,85.95,47,0.295,0.235,0.192,28274
"Salad dressing, italian dressing, reduced calorie",Fats and Oils,0.3,0.006,1.074,0.2,0.0004,0.033,6.7,1.94,20,70,200,2.88,4.66,11.58,28275
"Salad dressing, italian dressing, reduced fat",Fats and Oils,0.47,0.009,1.074,0,0,0.085,4.57,4.55,6.38,84.68,75,0.451,2.19,1.704,28276
"Salad dressing, italian dressing, reduced fat, without salt",Fats and Oils,0.47,0.009,0.03,0,0,0.085,4.57,4.55,6.38,84.68,76,0.451,2.19,1.704,28277
"Salad dressing, mayonnaise and mayonnaise-type, low calorie",Fats and Oils,0.9,0.014,0.837,0,0,0.024,23.9,4.34,19,54.3,263,2.962,4.628,10.396,28278
"Salad dressing, mayonnaise type, regular, with salt",Fats and Oils,0.9,0.014,0.711,0,0,0.009,23.9,6.4,33.4,39.9,390,4.9,9,18,28279
"Salad dressing, mayonnaise, imitation, milk cream",Fats and Oils,2.1,0.072,0.504,0,0.0003,0.097,11.1,0,5.1,79.6,97,2.8,1.7,0.3,28280
"Salad dressing, mayonnaise, imitation, soybean",Fats and Oils,0.3,0,0.497,0,0,0.01,16,6,19.2,62.7,232,3.3,4.5,10.6,28281
"Salad dressing, mayonnaise, imitation, soybean without cholesterol",Fats and Oils,0.1,0,0.353,0,0,0.01,15.8,6,47.7,34.6,482,7.5,10.5,27.6,28282
"Salad dressing, mayonnaise, light",Fats and Oils,0.88,0.008,0.673,0,0,0.04,8.2,4.34,33.09,55.91,324,5.209,8.135,17.953,28283
"Salad dressing, mayonnaise, soybean and safflower oil, with salt",Fats and Oils,1.1,0.018,0.568,0,0,0.034,2.7,0.48,79.4,15.3,717,8.6,13,55,28284
"Salad dressing, mayonnaise, soybean oil, with salt",Fats and Oils,0.9,0.007,0.568,0,0,0.012,3.16,1.08,79.39,15.06,718,11.885,19.598,42.38,28285
"Salad dressing, mayonnaise, soybean oil, without salt",Fats and Oils,1.1,0.018,0.03,0,0,0.034,2.7,0,79.4,16.7,717,11.8,22.7,37.1,28286
"Salad dressing, peppercorn dressing, commercial, regular",Fats and Oils,1.2,0.022,1.103,0,0.0006,0.176,3.5,2.45,61.4,31.9,564,10.527,14.84,32.972,28287
"Salad dressing, ranch dressing, commercial, regular",Fats and Oils,1.03,0.031,1.094,0.7,0.0034,0.062,6.69,2.45,51.39,38.18,484,8.019,11.394,28.329,28288
"Salad dressing, ranch dressing, fat-free",Fats and Oils,0.25,0.05,0.897,0.1,0,0.111,26.51,5.35,1.92,68.33,119,0,0,0,28289
"Salad dressing, ranch dressing, reduced fat",Fats and Oils,1.25,0.04,1.12,1.1,0.0006,0.132,21.33,3.77,12.42,62.04,196,1.25,2.794,4.213,28290
"Salad dressing, russian dressing",Fats and Oils,0.69,0.013,1.133,0.7,0.006,0.173,31.9,17.68,26.18,38.53,355,2.39,5.928,14.83,28291
"Salad dressing, russian dressing, low calorie",Fats and Oils,0.5,0.019,0.868,0.3,0.006,0.157,27.6,21.87,4,65,141,0.6,0.9,2.3,28292
"Salad dressing, sesame seed dressing, regular",Fats and Oils,3.1,0.019,1,1,0,0.157,8.6,8.32,45.2,39.2,443,6.2,11.9,25.1,28293
"Salad dressing, spray-style dressing, assorted flavors",Fats and Oils,0.16,0.006,1.102,0.3,0.0007,0,16.6,14.29,10.75,71,165,1.643,0,0,28294
"Salad dressing, sweet and sour",Fats and Oils,0.1,0.004,0.208,0,0.0081,0.033,3.7,3.7,0,95.7,15,0,0,0,28295
"Salad dressing, thousand island dressing, fat-free",Fats and Oils,0.55,0.011,0.788,3.3,0,0.122,29.27,16.83,1.45,66.14,132,0.198,0.342,0.63,28296
"Salad dressing, thousand island dressing, reduced fat",Fats and Oils,0.83,0.027,0.955,1.2,0.0015,0.202,24.06,17.31,11.32,60.73,195,0.746,6.468,2.692,28297
"Salad dressing, thousand island, commercial, regular",Fats and Oils,1.09,0.017,0.863,0.8,0,0.107,14.64,15.18,35.06,46.51,370,5.092,7.881,18.22,28298
"Sandwich spread, with chopped pickle, regular, unspecified oils",Fats and Oils,0.9,0.014,1,0.4,0,0.035,22.4,15.18,34,40.8,389,5.1,7.4,20,28299
"Shortening bread, soybean (hydrogenated) and cottonseed",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,22,33,40.6,28300
"Shortening cake mix, soybean (hydrogenated) and cottonseed (hydrogenated)",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,27.2,54.2,14.1,28301
"Shortening confectionery, coconut (hydrogenated) and or palm kernel (hydrogenated)",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,91.3,2.2,1,28302
"Shortening frying (heavy duty), beef tallow and cottonseed",Fats and Oils,0,0,0,0,0,0,0,0,100,0,900,44.9,38.5,8.8,28303
"Shortening frying (heavy duty), palm (hydrogenated)",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,47.5,40.6,7.5,28304
"Shortening frying (heavy duty), soybean (hydrogenated), linoleic (less than 1%)",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,21.1,73.7,0.4,28305
Shortening household soybean (hydrogenated) and palm,Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,25.02,42.51,26.64,28306
"Shortening industrial, lard and vegetable oil",Fats and Oils,0,0,0,0,0,0,0,0,100,0,900,35.7,40.7,19.2,28307
"Shortening industrial, soybean (hydrogenated) and cottonseed",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,25.6,58,12,28308
"Shortening, confectionery, fractionated palm",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,65.5,29.6,0.5,28309
"Shortening, household, lard and vegetable oil",Fats and Oils,0,0,0,0,0,0,0,0,100,0,900,40.3,44.4,10.9,28310
"Shortening, household, soybean (partially hydrogenated)-cottonseed (partially hydrogenated)",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,25,44.5,26.1,28311
"Shortening, industrial, soy (partially hydrogenated ) and corn for frying",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,17.416,40.824,36.038,28312
"Shortening, industrial, soy (partially hydrogenated ) for baking and confections",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,18.788,71.023,5.4,28313
"Shortening, industrial, soy (partially hydrogenated), pourable liquid fry shortening",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,18.358,38.87,38.009,28314
"Shortening, multipurpose, soybean (hydrogenated) and palm (hydrogenated)",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,30.423,50.973,14.204,28315
"Shortening, special purpose for baking, soybean (hydrogenated) palm and cottonseed",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,28.843,29.583,37.174,28316
"Shortening, special purpose for cakes and frostings, soybean (hydrogenated)",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,20.001,36.555,37.991,28317
"Shortening, vegetable, household, composite",Fats and Oils,0,0.001,0.004,0,0,0,0,0,99.97,0,884,24.98,41.186,28.097,28318
"USDA Commodity Food, oil, vegetable, low saturated fat",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,7.429,22.73,65.138,28319
"USDA Commodity Food, oil, vegetable, soybean, refined",Fats and Oils,0,0,0,0,0,0,0,0,100,0,884,15.251,22.727,57.333,28320
"Vegetable oil, palm kernel",Fats and Oils,0,0,0,0,0,0,0,0,100,0,862,81.5,11.4,1.6,28321
"Vegetable oil-butter spread, reduced calorie",Fats and Oils,0,0.006,0.581,0,0,0.006,0,0,53,44.7,465,17.88,20.77,11.15,28322
"Chicken breast tenders, cooked, conventional oven",Poultry Products,15.78,0.018,0.457,1.3,0,0.218,17,0.41,17.69,47.76,293,3.786,6.787,3.038,28323
"Chicken breast tenders, cooked, microwaved",Poultry Products,16.35,0.014,0.446,0,0,0.225,17.56,0,12.89,51.34,252,3.2,5.71,2.79,28324
"Chicken breast tenders, uncooked",Poultry Products,14.73,0.019,0.451,1.1,0.0008,0.214,15.01,0.37,15.75,52.74,263,3.26,6.48,3.34,28325
"Chicken nuggets, frozen, cooked",Poultry Products,15.27,0.031,0.557,2.1,0,0.265,14.09,0,19.82,48.59,296,3.992,8.862,4.376,28326
"Chicken nuggets, frozen, uncooked",Poultry Products,13.89,0.022,0.424,2.4,0,0.249,14.38,0,18.06,51.55,276,3.575,8.266,4.393,28327
"Chicken patty, frozen, cooked",Poultry Products,14.85,0.019,0.532,0.3,0,0.261,12.84,0,19.58,50.82,287,3.662,8.337,4.805,28328
"Chicken patty, frozen, uncooked",Poultry Products,14.33,0.02,0.518,1.2,0,0.248,13.61,0,20.04,50.04,292,4.013,8.98,5.204,28329
"Chicken, broilers or fryers, back, meat and skin, cooked, fried, batter",Poultry Products,21.97,0.026,0.317,0,0,0.18,10.25,0,21.91,44.51,331,5.83,8.91,5.2,28330
"Chicken, broilers or fryers, back, meat and skin, cooked, fried, flour",Poultry Products,27.79,0.024,0.09,0,0,0.226,6.5,0,20.74,43.96,331,5.61,8.18,4.81,28331
"Chicken, broilers or fryers, back, meat and skin, cooked, roasted",Poultry Products,25.95,0.021,0.087,0,0,0.21,0,0,20.97,53.52,300,5.82,8.29,4.62,28332
"Chicken, broilers or fryers, back, meat and skin, cooked, rotisserie, original seasoning",Poultry Products,23.23,0.035,0.584,0,0,0.291,0,0.03,18.59,56.44,260,4.924,8.162,2.723,28333
"Chicken, broilers or fryers, back, meat and skin, cooked, stewed",Poultry Products,22.18,0.018,0.064,0,0,0.145,0,0,18.14,60.93,258,5.02,7.13,4,28334
"Chicken, broilers or fryers, back, meat and skin, raw",Poultry Products,14.05,0.013,0.064,0,0.0016,0.144,0,0,28.74,58.1,319,8.34,12.25,6.14,28335
"Chicken, broilers or fryers, back, meat only, cooked, fried",Poultry Products,29.99,0.026,0.099,0,0,0.251,5.68,0,15.32,47.87,288,4.12,5.73,3.64,28336
"Chicken, broilers or fryers, back, meat only, cooked, roasted",Poultry Products,28.19,0.024,0.096,0,0,0.237,0,0,13.16,58.75,239,3.6,4.83,3.05,28337
"Chicken, broilers or fryers, back, meat only, cooked, rotisserie, original seasoning",Poultry Products,25.34,0.038,0.661,0,0,0.309,0,0,11.54,61.24,205,3.007,4.898,1.701,28338
"Chicken, broilers or fryers, back, meat only, cooked, stewed",Poultry Products,25.31,0.021,0.067,0,0,0.158,0,0,11.19,64.33,209,3.04,4.02,2.62,28339
"Chicken, broilers or fryers, back, meat only, raw",Poultry Products,19.56,0.017,0.082,0,0,0.204,0,0,5.92,75.31,137,1.52,1.84,1.47,28340
"Chicken, broilers or fryers, breast, meat and skin, cooked, fried, batter",Poultry Products,24.84,0.02,0.275,0.3,0,0.201,8.99,0,13.2,51.64,260,3.52,5.46,3.08,28341
"Chicken, broilers or fryers, breast, meat and skin, cooked, fried, flour",Poultry Products,31.84,0.016,0.076,0.1,0,0.259,1.64,0,8.87,56.59,222,2.45,3.5,1.96,28342
"Chicken, broilers or fryers, breast, meat and skin, cooked, roasted",Poultry Products,29.8,0.014,0.071,0,0,0.245,0,0,7.78,62.44,197,2.19,3.03,1.66,28343
"Chicken, broilers or fryers, breast, meat and skin, cooked, rotisserie, original seasoning",Poultry Products,27.48,0.015,0.347,0,0,0.289,0,0.02,8.18,63.49,184,2.114,3.548,1.166,28344
"Chicken, broilers or fryers, breast, meat and skin, cooked, stewed",Poultry Products,27.39,0.013,0.062,0,0,0.178,0,0,7.42,66.21,184,2.08,2.9,1.58,28345
"Chicken, broilers or fryers, breast, meat and skin, raw",Poultry Products,20.85,0.011,0.063,0,0,0.22,0,0,9.25,69.46,172,2.66,3.82,1.96,28346
"Chicken, broilers or fryers, breast, meat only, cooked, fried",Poultry Products,33.44,0.016,0.079,0,0,0.276,0.51,0,4.71,60.21,187,1.29,1.72,1.07,28347
"Chicken, broilers or fryers, breast, meat only, cooked, roasted",Poultry Products,31.02,0.015,0.074,0,0,0.256,0,0,3.57,65.26,165,1.01,1.24,0.77,28348
"Chicken, broilers or fryers, breast, meat only, cooked, rotisserie, original seasoning",Poultry Products,29.07,0.014,0.341,0,0,0.296,0,0,3.49,66.67,148,0.843,1.412,0.48,28349
"Chicken, broilers or fryers, breast, meat only, cooked, stewed",Poultry Products,28.98,0.013,0.063,0,0,0.187,0,0,3.03,68.27,151,0.85,1.03,0.66,28350
"Chicken, broilers or fryers, breast, meat only, raw",Poultry Products,21.23,0.005,0.116,0,0.0012,0.37,0,0,2.59,75.79,114,0.567,0.763,0.399,28351
"Chicken, broilers or fryers, breast, meat only, raw, enhanced",Poultry Products,19.75,0.004,0.323,0,0,0.282,0,0,2.73,76.92,104,0.647,0.867,0.491,28352
"Chicken, broilers or fryers, dark meat, meat and skin, cooked, fried, batter",Poultry Products,21.85,0.021,0.295,0,0,0.185,9.38,0,18.64,48.82,298,4.95,7.58,4.43,28353
"Chicken, broilers or fryers, dark meat, meat and skin, cooked, fried, flour",Poultry Products,27.22,0.017,0.089,0,0,0.23,4.08,0,16.91,50.82,285,4.58,6.66,3.91,28354
"Chicken, broilers or fryers, dark meat, meat and skin, cooked, roasted",Poultry Products,25.97,0.015,0.087,0,0,0.22,0,0,15.78,58.63,253,4.37,6.19,3.49,28355
"Chicken, broilers or fryers, dark meat, meat and skin, cooked, stewed",Poultry Products,23.5,0.014,0.07,0,0,0.166,0,0,14.66,62.99,233,4.06,5.75,3.24,28356
"Chicken, broilers or fryers, dark meat, meat and skin, raw",Poultry Products,16.69,0.011,0.073,0,0,0.178,0,0,18.34,65.42,237,5.26,7.65,3.96,28357
"Chicken, broilers or fryers, dark meat, meat only, cooked, fried",Poultry Products,28.99,0.018,0.097,0,0,0.253,2.59,0,11.62,55.7,239,3.12,4.32,2.77,28358
"Chicken, broilers or fryers, dark meat, meat only, cooked, roasted",Poultry Products,27.37,0.015,0.093,0,0,0.24,0,0,9.73,63.06,205,2.66,3.56,2.26,28359
"Chicken, broilers or fryers, dark meat, meat only, cooked, stewed",Poultry Products,25.97,0.014,0.074,0,0,0.181,0,0,8.98,65.83,192,2.45,3.26,2.09,28360
"Chicken, broilers or fryers, dark meat, meat only, raw",Poultry Products,20.08,0.012,0.085,0,0.0031,0.222,0,0,4.31,75.99,125,1.1,1.34,1.07,28361
"Chicken, broilers or fryers, drumstick, meat and skin, cooked, fried, batter",Poultry Products,21.95,0.017,0.269,0.3,0,0.186,8.28,0,15.75,52.77,268,4.14,6.43,3.79,28362
"Chicken, broilers or fryers, drumstick, meat and skin, cooked, fried, flour",Poultry Products,26.96,0.012,0.089,0.1,0,0.229,1.63,0,13.72,56.73,245,3.66,5.42,3.23,28363
"Chicken, broilers or fryers, drumstick, meat and skin, cooked, roasted",Poultry Products,27.03,0.012,0.09,0,0,0.229,0,0,11.15,62.6,216,3.05,4.25,2.5,28364
"Chicken, broilers or fryers, drumstick, meat and skin, cooked, rotisserie, original seasoning",Poultry Products,26.86,0.021,0.411,0,0,0.291,0,0.02,11.98,59.78,215,3.02,5.22,1.785,28365
"Chicken, broilers or fryers, drumstick, meat and skin, cooked, stewed",Poultry Products,25.32,0.011,0.076,0,0,0.184,0,0,10.64,65.12,204,2.91,4.07,2.38,28366
"Chicken, broilers or fryers, drumstick, meat and skin, raw",Poultry Products,19.27,0.011,0.083,0,0.0027,0.206,0,0,8.68,72.46,161,2.39,3.37,1.94,28367
"Chicken, broilers or fryers, drumstick, meat only, cooked, fried",Poultry Products,28.62,0.012,0.096,0,0,0.249,0,0,8.08,62.24,195,2.13,2.94,1.97,28368
"Chicken, broilers or fryers, drumstick, meat only, cooked, roasted",Poultry Products,28.29,0.012,0.095,0,0,0.246,0,0,5.66,66.74,172,1.48,1.87,1.37,28369
"Chicken, broilers or fryers, drumstick, meat only, cooked, rotisserie, original seasoning",Poultry Products,28.74,0.021,0.417,0,0,0.301,0,0,6.81,63.05,176,1.594,2.855,1.041,28370
"Chicken, broilers or fryers, drumstick, meat only, cooked, stewed",Poultry Products,27.5,0.011,0.08,0,0,0.199,0,0,5.71,67.58,169,1.51,1.93,1.37,28371
"Chicken, broilers or fryers, drumstick, meat only, raw",Poultry Products,20.59,0.011,0.088,0,0.0032,0.226,0,0,3.42,76.38,119,0.88,1.06,0.85,28372
"Chicken, broilers or fryers, giblets, cooked, fried",Poultry Products,32.54,0.018,0.113,0,0.0087,0.33,4.35,0,13.46,47.89,277,3.8,4.42,3.38,28373
"Chicken, broilers or fryers, giblets, cooked, simmered",Poultry Products,27.15,0.014,0.067,0,0.0125,0.224,0,0,4.5,67.72,157,1.322,0.962,0.812,28374
"Chicken, broilers or fryers, giblets, raw",Poultry Products,17.88,0.01,0.077,0,0.0162,0.228,1.8,0,4.47,74.87,124,1.36,1.12,1.09,28375
"Chicken, broilers or fryers, leg, meat and skin, cooked, fried, batter",Poultry Products,21.77,0.018,0.279,0.3,0,0.189,8.72,0,16.17,52.08,273,4.28,6.58,3.85,28376
"Chicken, broilers or fryers, leg, meat and skin, cooked, fried, flour",Poultry Products,26.84,0.013,0.088,0.1,0,0.233,2.5,0,14.43,55.28,254,3.9,5.68,3.33,28377
"Chicken, broilers or fryers, leg, meat and skin, cooked, roasted",Poultry Products,25.96,0.012,0.087,0,0,0.225,0,0,13.46,60.92,232,3.72,5.24,3,28378
"Chicken, broilers or fryers, leg, meat and skin, cooked, stewed",Poultry Products,24.17,0.011,0.073,0,0,0.176,0,0,12.92,64.01,220,3.57,5.04,2.87,28379
"Chicken, broilers or fryers, leg, meat and skin, raw",Poultry Products,18.15,0.01,0.079,0,0,0.198,0,0,12.12,69.91,187,3.41,4.89,2.65,28380
"Chicken, broilers or fryers, leg, meat only, cooked, fried",Poultry Products,28.38,0.013,0.096,0,0,0.254,0.65,0,9.32,60.61,208,2.49,3.43,2.22,28381
"Chicken, broilers or fryers, leg, meat only, cooked, roasted",Poultry Products,27.03,0.012,0.091,0,0,0.242,0,0,8.43,64.7,191,2.29,3.05,1.97,28382
"Chicken, broilers or fryers, leg, meat only, cooked, stewed",Poultry Products,26.26,0.011,0.078,0,0,0.19,0,0,8.06,66.44,185,2.2,2.93,1.88,28383
"Chicken, broilers or fryers, leg, meat only, raw",Poultry Products,20.13,0.011,0.086,0,0,0.229,0,0,3.81,76.13,120,0.98,1.18,0.95,28384
"Chicken, broilers or fryers, light meat, meat and skin, cooked, fried, batter",Poultry Products,23.55,0.02,0.287,0,0,0.185,9.5,0,15.44,50.23,277,4.12,6.37,3.6,28385
"Chicken, broilers or fryers, light meat, meat and skin, cooked, fried, flour",Poultry Products,30.45,0.016,0.077,0.1,0,0.239,1.82,0,12.09,54.66,246,3.32,4.8,2.69,28386
"Chicken, broilers or fryers, light meat, meat and skin, cooked, roasted",Poultry Products,29.02,0.015,0.075,0,0,0.227,0,0,10.85,60.51,222,3.05,4.26,2.31,28387
"Chicken, broilers or fryers, light meat, meat and skin, cooked, stewed",Poultry Products,26.14,0.013,0.063,0,0,0.167,0,0,9.97,65.13,201,2.8,3.92,2.12,28388
"Chicken, broilers or fryers, light meat, meat and skin, raw",Poultry Products,20.27,0.011,0.065,0,0,0.204,0,0,11.07,68.6,186,3.16,4.52,2.34,28389
"Chicken, broilers or fryers, light meat, meat only, cooked, fried",Poultry Products,32.82,0.016,0.081,0,0,0.263,0.42,0,5.54,60.14,192,1.52,1.97,1.26,28390
"Chicken, broilers or fryers, light meat, meat only, cooked, roasted",Poultry Products,30.91,0.015,0.077,0,0,0.247,0,0,4.51,64.76,173,1.27,1.54,0.98,28391
"Chicken, broilers or fryers, light meat, meat only, cooked, stewed",Poultry Products,28.88,0.013,0.065,0,0,0.18,0,0,3.99,68.02,159,1.12,1.35,0.87,28392
"Chicken, broilers or fryers, light meat, meat only, raw",Poultry Products,23.2,0.012,0.068,0,0,0.239,0,0,1.65,74.86,114,0.44,0.39,0.37,28393
"Chicken, broilers or fryers, meat and skin and giblets and neck, cooked, fried, batter",Poultry Products,22.84,0.021,0.284,0,0.0004,0.19,9.03,0,17.53,49.29,291,4.67,7.13,4.15,28394
"Chicken, broilers or fryers, meat and skin and giblets and neck, cooked, fried, flour",Poultry Products,28.57,0.017,0.086,0,0.0005,0.237,3.27,0,15.27,51.88,272,4.16,5.99,3.51,28395
"Chicken, broilers or fryers, meat and skin and giblets and neck, raw",Poultry Products,18.33,0.011,0.07,0,0.0026,0.189,0.13,0,14.83,66.34,213,4.24,6.08,3.19,28396
"Chicken, broilers or fryers, meat and skin and giblets and neck, roasted",Poultry Products,26.78,0.015,0.079,0,0.0005,0.212,0.06,0,13.27,60.11,234,3.7,5.17,2.9,28397
"Chicken, broilers or fryers, meat and skin and giblets and neck, stewed",Poultry Products,24.49,0.014,0.066,0,0.0005,0.163,0.06,0,12.37,64.04,216,3.45,4.82,2.7,28398
"Chicken, broilers or fryers, meat and skin, cooked, fried, batter",Poultry Products,22.54,0.021,0.292,0.3,0,0.185,9.42,0,17.35,49.39,289,4.61,7.09,4.1,28399
"Chicken, broilers or fryers, meat and skin, cooked, fried, flour",Poultry Products,28.56,0.017,0.084,0.1,0,0.234,3.15,0,14.92,52.41,269,4.06,5.89,3.41,28400
"Chicken, broilers or fryers, meat and skin, cooked, roasted",Poultry Products,27.3,0.015,0.082,0,0,0.223,0,0,13.6,59.45,239,3.79,5.34,2.97,28401
"Chicken, broilers or fryers, meat and skin, cooked, stewed",Poultry Products,24.68,0.013,0.067,0,0,0.166,0,0,12.56,63.93,219,3.5,4.93,2.74,28402
"Chicken, broilers or fryers, meat and skin, raw",Poultry Products,18.6,0.011,0.07,0,0.0016,0.189,0,0,15.06,65.99,215,4.31,6.24,3.23,28403
"Chicken, broilers or fryers, meat only, cooked, fried",Poultry Products,30.57,0.017,0.091,0.1,0,0.257,1.69,0,9.12,57.53,219,2.46,3.35,2.15,28404
"Chicken, broilers or fryers, meat only, raw",Poultry Products,21.39,0.012,0.077,0,0.0023,0.229,0,0,3.08,75.46,119,0.79,0.9,0.75,28405
"Chicken, broilers or fryers, meat only, roasted",Poultry Products,28.93,0.015,0.086,0,0,0.243,0,0,7.41,63.79,190,2.04,2.66,1.69,28406
"Chicken, broilers or fryers, meat only, stewed",Poultry Products,27.29,0.014,0.07,0,0,0.18,0,0,6.71,66.81,177,1.84,2.39,1.54,28407
"Chicken, broilers or fryers, neck, meat and skin, cooked simmered",Poultry Products,19.61,0.027,0.052,0,0,0.108,0,0,18.1,61.73,247,5,7.22,3.94,28408
"Chicken, broilers or fryers, neck, meat and skin, cooked, fried, batter",Poultry Products,19.82,0.031,0.276,0,0,0.151,8.7,0,23.52,46.92,330,6.22,9.79,5.56,28409
"Chicken, broilers or fryers, neck, meat and skin, cooked, fried, flour",Poultry Products,24.01,0.031,0.082,0,0,0.18,4.24,0,23.61,47.49,332,6.32,9.7,5.47,28410
"Chicken, broilers or fryers, neck, meat and skin, raw",Poultry Products,14.07,0.018,0.064,0,0,0.137,0,0,26.24,59.99,297,7.27,10.55,5.68,28411
"Chicken, broilers or fryers, neck, meat only, cooked, fried",Poultry Products,26.87,0.041,0.099,0,0,0.213,1.77,0,11.88,58.71,229,3,4.57,3.04,28412
"Chicken, broilers or fryers, neck, meat only, cooked, simmered",Poultry Products,24.56,0.044,0.064,0,0,0.14,0,0,8.18,67.05,179,2.1,2.54,2.03,28413
"Chicken, broilers or fryers, neck, meat only, raw",Poultry Products,17.55,0.027,0.081,0,0,0.175,0,0,8.78,71.17,154,2.25,2.73,2.18,28414
"Chicken, broilers or fryers, separable fat, raw",Poultry Products,3.73,0.007,0.032,0,0,0.064,0,0,67.95,28.91,629,20.25,30.3,14.2,28415
"Chicken, broilers or fryers, skin only, cooked, fried, batter",Poultry Products,10.32,0.026,0.581,0,0,0.075,23.15,0,28.83,36.11,394,7.61,12.35,6.83,28416
"Chicken, broilers or fryers, skin only, cooked, fried, flour",Poultry Products,19.09,0.014,0.053,0,0,0.125,9.34,0,42.58,28.54,502,11.67,18.01,9.44,28417
"Chicken, broilers or fryers, skin only, cooked, roasted",Poultry Products,20.36,0.014,0.065,0,0,0.136,0,0,40.68,40.29,454,11.42,17.03,8.57,28418
"Chicken, broilers or fryers, skin only, cooked, rotisserie, original seasoning",Poultry Products,17.66,0.025,0.381,0,0,0.245,0,0.11,37.24,43.76,406,9.994,16.793,5.423,28419
"Chicken, broilers or fryers, skin only, cooked, stewed",Poultry Products,15.22,0.012,0.056,0,0,0.117,0,0,33.04,53.27,363,9.28,13.83,6.96,28420
"Chicken, broilers or fryers, skin only, raw",Poultry Products,13.33,0.011,0.063,0,0,0.103,0,0,32.35,54.22,349,9.08,13.54,6.81,28421
"Chicken, broilers or fryers, thigh, meat and skin, cooked, fried, batter",Poultry Products,21.61,0.018,0.288,0.3,0,0.192,9.08,0,16.53,51.5,277,4.41,6.7,3.9,28422
"Chicken, broilers or fryers, thigh, meat and skin, cooked, fried, flour",Poultry Products,26.75,0.014,0.088,0.1,0,0.237,3.18,0,14.98,54.14,262,4.09,5.87,3.41,28423
"Chicken, broilers or fryers, thigh, meat and skin, cooked, roasted",Poultry Products,25.06,0.012,0.084,0,0,0.222,0,0,15.49,59.42,247,4.33,6.15,3.42,28424
"Chicken, broilers or fryers, thigh, meat and skin, cooked, rotisserie, original seasoning",Poultry Products,22.93,0.015,0.345,0,0,0.26,0,0.02,15.7,60.34,233,4.123,6.979,2.334,28425
"Chicken, broilers or fryers, thigh, meat and skin, cooked, stewed",Poultry Products,23.26,0.011,0.071,0,0,0.17,0,0,14.74,63.11,232,4.11,5.84,3.25,28426
"Chicken, broilers or fryers, thigh, meat and skin, raw",Poultry Products,17.27,0.01,0.076,0,0.0023,0.192,0,0,15.25,67.68,211,4.263,6.328,3.286,28427
"Chicken, broilers or fryers, thigh, meat only, cooked, fried",Poultry Products,28.18,0.013,0.095,0,0,0.259,1.18,0,10.3,59.31,218,2.78,3.82,2.43,28428
"Chicken, broilers or fryers, thigh, meat only, cooked, roasted",Poultry Products,25.94,0.012,0.088,0,0,0.238,0,0,10.88,62.87,209,3.03,4.15,2.48,28429
"Chicken, broilers or fryers, thigh, meat only, cooked, rotisserie, original seasoning",Poultry Products,24.06,0.013,0.337,0,0,0.264,0,0,11.09,63.89,196,2.866,4.878,1.673,28430
"Chicken, broilers or fryers, thigh, meat only, cooked, stewed",Poultry Products,25,0.011,0.075,0,0,0.183,0,0,9.79,65.59,195,2.71,3.7,2.24,28431
"Chicken, broilers or fryers, thigh, meat only, raw",Poultry Products,19.65,0.01,0.086,0,0,0.231,0,0,3.91,75.81,119,1,1.21,0.97,28432
"Chicken, broilers or fryers, wing, meat and skin, cooked, fried, batter",Poultry Products,19.87,0.02,0.32,0.3,0,0.138,10.94,0,21.81,46.21,324,5.83,8.96,5.07,28433
"Chicken, broilers or fryers, wing, meat and skin, cooked, fried, flour",Poultry Products,26.11,0.015,0.077,0.1,0,0.177,2.39,0,22.16,48.62,321,6.06,8.89,4.95,28434
"Chicken, broilers or fryers, wing, meat and skin, cooked, roasted",Poultry Products,26.86,0.015,0.082,0,0,0.184,0,0,19.46,55.04,290,5.45,7.64,4.14,28435
"Chicken, broilers or fryers, wing, meat and skin, cooked, rotisserie, original seasoning",Poultry Products,24.34,0.029,0.61,0,0,0.296,0,0.04,18.77,55.34,266,4.814,8.109,2.69,28436
"Chicken, broilers or fryers, wing, meat and skin, cooked, stewed",Poultry Products,22.78,0.012,0.067,0,0,0.139,0,0,16.82,62.18,249,4.71,6.6,3.58,28437
"Chicken, broilers or fryers, wing, meat and skin, raw",Poultry Products,18.33,0.012,0.073,0,0.0007,0.156,0,0,15.97,66.21,222,4.48,6.35,3.39,28438
"Chicken, broilers or fryers, wing, meat only, cooked, fried",Poultry Products,30.15,0.015,0.091,0,0,0.208,0,0,9.15,59.83,211,2.5,3.08,2.07,28439
"Chicken, broilers or fryers, wing, meat only, cooked, roasted",Poultry Products,30.46,0.016,0.092,0,0,0.21,0,0,8.13,62.78,203,2.26,2.61,1.78,28440
"Chicken, broilers or fryers, wing, meat only, cooked, rotisserie, original seasoning",Poultry Products,27.69,0.032,0.725,0,0,0.322,0,0,9.53,61.13,197,2.223,3.766,1.323,28441
"Chicken, broilers or fryers, wing, meat only, cooked, stewed",Poultry Products,27.18,0.013,0.073,0,0,0.153,0,0,7.18,67.01,181,2,2.31,1.57,28442
"Chicken, broilers or fryers, wing, meat only, raw",Poultry Products,21.97,0.013,0.081,0,0.0012,0.194,0,0,3.54,74.95,126,0.94,0.85,0.8,28443
"Chicken, canned, meat only, with broth",Poultry Products,21.77,0.014,0.503,0,0.002,0.138,0,0,7.95,68.65,165,2.2,3.15,1.75,28444
"Chicken, canned, no broth",Poultry Products,25.3,0.014,0.135,0,0,0.153,0.9,0,8.1,64.9,185,2.25,3.279,1.978,28445
"Chicken, capons, giblets, cooked, simmered",Poultry Products,26.39,0.013,0.055,0,0.009,0.153,0.76,0,5.4,66.54,164,1.77,1.37,1.13,28446
"Chicken, capons, giblets, raw",Poultry Products,18.28,0.01,0.077,0,0.0184,0.226,1.42,0,5.18,74.12,130,1.64,1.32,1.19,28447
"Chicken, capons, meat and skin and giblets and neck, cooked, roasted",Poultry Products,28.35,0.015,0.05,0,0.0004,0.243,0.04,0,11.67,59.25,226,3.28,4.71,2.52,28448
"Chicken, capons, meat and skin and giblets and neck, raw",Poultry Products,18.51,0.011,0.047,0,0.0026,0.213,0.08,0,16.9,63.66,232,4.89,7.16,3.6,28449
"Chicken, capons, meat and skin, cooked, roasted",Poultry Products,28.96,0.014,0.049,0,0,0.255,0,0,11.65,58.7,229,3.26,4.75,2.52,28450
"Chicken, capons, meat and skin, raw",Poultry Products,18.77,0.011,0.045,0,0.0017,0.217,0,0,17.07,63.24,234,4.95,7.31,3.62,28451
"Chicken, cornish game hens, meat and skin, cooked, roasted",Poultry Products,22.27,0.013,0.064,0,0.0005,0.245,0,0,18.21,58.68,259,5.05,8,3.6,28452
"Chicken, cornish game hens, meat and skin, raw",Poultry Products,17.15,0.011,0.061,0,0.0005,0.236,0,0,14.02,68.18,200,3.89,6.16,2.77,28453
"Chicken, cornish game hens, meat only, cooked, roasted",Poultry Products,23.3,0.013,0.063,0,0.0006,0.25,0,0,3.87,71.9,134,0.99,1.24,0.94,28454
"Chicken, cornish game hens, meat only, raw",Poultry Products,20.04,0.012,0.068,0,0.0006,0.269,0,0,3.33,75.84,116,0.85,1.07,0.81,28455
"Chicken, feet, boiled",Poultry Products,19.4,0.088,0.067,0,0,0.031,0.2,0,14.6,65.8,215,3.92,5.5,2.98,28456
"Chicken, gizzard, all classes, cooked, simmered",Poultry Products,30.39,0.017,0.056,0,0,0.179,0,0,2.68,67.93,154,0.67,0.528,0.353,28457
"Chicken, gizzard, all classes, raw",Poultry Products,17.66,0.011,0.069,0,0.0037,0.237,0,0,2.06,79.33,94,0.529,0.512,0.357,28458
"Chicken, ground, crumbles, cooked, pan-browned",Poultry Products,23.28,0.008,0.075,0,0,0.677,0,0,10.92,64.92,189,3.11,4.879,2.08,28459
"Chicken, ground, raw",Poultry Products,17.44,0.006,0.06,0,0,0.522,0.04,0,8.1,73.24,143,2.301,3.611,1.508,28460
"Chicken, heart, all classes, cooked, simmered",Poultry Products,26.41,0.019,0.048,0,0.0018,0.132,0.1,0,7.92,64.85,185,2.26,2.01,2.3,28461
"Chicken, heart, all classes, raw",Poultry Products,15.55,0.012,0.074,0,0.0032,0.176,0.71,0,9.33,73.56,153,2.66,2.37,2.71,28462
"Chicken, liver, all classes, cooked, pan-fried",Poultry Products,25.78,0.01,0.092,0,0.0027,0.315,1.11,0,6.43,65.22,172,2.033,1.387,1.265,28463
"Chicken, liver, all classes, cooked, simmered",Poultry Products,24.46,0.011,0.076,0,0.027899999999999998,0.263,0.87,0,6.51,66.81,167,2.06,1.416,1.988,28464
"Chicken, liver, all classes, raw",Poultry Products,16.92,0.008,0.071,0,0.0179,0.23,0.73,0,4.83,76.46,119,1.563,1.249,1.306,28465
"Chicken, roasting, dark meat, meat only, cooked, roasted",Poultry Products,23.25,0.011,0.095,0,0,0.224,0,0,8.75,67.05,178,2.43,3.31,2,28466
"Chicken, roasting, dark meat, meat only, raw",Poultry Products,18.74,0.009,0.095,0,0,0.227,0,0,3.61,75.48,113,0.93,1.12,0.9,28467
"Chicken, roasting, giblets, cooked, simmered",Poultry Products,26.77,0.012,0.06,0,0.0065,0.16,0.86,0,5.22,66.32,165,1.64,1.33,1.22,28468
"Chicken, roasting, giblets, raw",Poultry Products,18.14,0.01,0.077,0,0.013099999999999999,0.227,1.14,0,5.04,74.73,127,1.54,1.28,1.26,28469
"Chicken, roasting, light meat, meat only, cooked, roasted",Poultry Products,27.13,0.013,0.051,0,0,0.236,0,0,4.07,67.85,153,1.08,1.52,0.93,28470
"Chicken, roasting, light meat, meat only, raw",Poultry Products,22.2,0.011,0.051,0,0,0.252,0,0,1.63,74.3,109,0.37,0.48,0.4,28471
"Chicken, roasting, meat and skin and giblets and neck, cooked, roasted",Poultry Products,23.96,0.013,0.071,0,0.0004,0.204,0.05,0,13.07,62.35,220,3.66,5.22,2.85,28472
"Chicken, roasting, meat and skin and giblets and neck, raw",Poultry Products,17.09,0.01,0.069,0,0.0024,0.196,0.09,0,15.46,65.98,213,4.41,6.39,3.33,28473
"Chicken, roasting, meat and skin, cooked, roasted",Poultry Products,23.97,0.012,0.073,0,0,0.211,0,0,13.39,62.1,223,3.74,5.4,2.92,28474
"Chicken, roasting, meat and skin, raw",Poultry Products,17.14,0.01,0.068,0,0,0.196,0,0,15.85,65.5,216,4.53,6.64,3.4,28475
"Chicken, roasting, meat only, cooked, roasted",Poultry Products,25.01,0.012,0.075,0,0,0.229,0,0,6.63,67.41,167,1.81,2.5,1.51,28476
"Chicken, roasting, meat only, raw",Poultry Products,20.33,0.01,0.075,0,0,0.238,0,0,2.7,74.94,111,0.67,0.83,0.67,28477
"Chicken, stewing, dark meat, meat only, cooked, stewed",Poultry Products,28.14,0.012,0.095,0,0,0.204,0,0,15.28,55.09,258,4.07,5.24,3.65,28478
"Chicken, stewing, dark meat, meat only, raw",Poultry Products,19.7,0.01,0.101,0,0.0046,0.242,0,0,8.12,71.94,157,2.08,2.52,2.02,28479
"Chicken, stewing, giblets, cooked, simmered",Poultry Products,25.73,0.013,0.056,0,0.0055,0.154,0.11,0,9.3,64,194,2.66,2.94,1.89,28480
"Chicken, stewing, giblets, raw",Poultry Products,17.89,0.01,0.077,0,0.0114,0.226,2.13,0,9.21,69.8,168,2.63,2.75,2.1,28481
"Chicken, stewing, light meat, meat only, cooked, stewed",Poultry Products,33.04,0.014,0.058,0,0,0.199,0,0,7.98,57.79,213,1.98,2.69,1.89,28482
"Chicken, stewing, light meat, meat only, raw",Poultry Products,23.1,0.011,0.053,0,0.0018,0.261,0,0,4.21,73.24,137,0.97,1.25,1.04,28483
"Chicken, stewing, meat and skin, and giblets and neck, cooked, stewed",Poultry Products,24.88,0.013,0.067,0,0.001,0.171,0,0,11.91,64.24,214,3.324,4.609,2.584,28484
"Chicken, stewing, meat and skin, and giblets and neck, raw",Poultry Products,17.48,0.01,0.071,0,0.0031,0.204,0.19,0,19.52,62.5,251,5.48,7.82,4.27,28485
"Chicken, stewing, meat and skin, cooked, stewed",Poultry Products,26.88,0.013,0.073,0,0,0.182,0,0,18.87,53.07,285,5.11,7.19,4.23,28486
"Chicken, stewing, meat and skin, raw",Poultry Products,17.55,0.01,0.071,0,0,0.204,0,0,20.33,61.84,258,5.71,8.24,4.44,28487
"Chicken, stewing, meat only, cooked, stewed",Poultry Products,30.42,0.013,0.078,0,0,0.202,0,0,11.89,56.35,237,3.1,4.05,2.83,28488
"Chicken, stewing, meat only, raw",Poultry Products,21.26,0.01,0.079,0,0.0033,0.251,0,0,6.32,72.54,148,1.57,1.94,1.57,28489
"Chicken, wing, frozen, glazed, barbecue flavored",Poultry Products,19.67,0.028,0.615,0.6,0,0.19,3.34,2.04,12.67,61.79,211,3.321,5.766,2.359,28490
"Chicken, wing, frozen, glazed, barbecue flavored, heated (conventional oven)",Poultry Products,22.24,0.028,0.559,0.5,0,0.218,3.36,1.94,14.87,57.25,242,3.966,6.737,2.838,28491
"Chicken, wing, frozen, glazed, barbecue flavored, heated (microwave)",Poultry Products,25.34,0.038,0.837,0.9,0.0005,0.254,3.84,2.1,13.9,53.89,248,3.655,6.297,2.599,28492
"Dove, cooked (includes squab)",Poultry Products,23.9,0.017,0.057,0,0.0029,0.256,0,0,13,62,213,3.739,5.462,2.733,28493
"Duck, domesticated, liver, raw",Poultry Products,18.74,0.011,0.14,0,0.0045,0.23,3.53,0,4.64,71.78,136,1.44,0.71,0.63,28494
"Duck, domesticated, meat and skin, cooked, roasted",Poultry Products,18.99,0.011,0.059,0,0,0.204,0,0,28.35,51.84,337,9.67,12.9,3.65,28495
"Duck, domesticated, meat and skin, raw",Poultry Products,11.49,0.011,0.063,0,0.0028,0.209,0,0,39.34,48.5,404,13.22,18.69,5.08,28496
"Duck, domesticated, meat only, cooked, roasted",Poultry Products,23.48,0.012,0.065,0,0,0.252,0,0,11.2,64.22,201,3.951,3.858,1.487,28497
"Duck, domesticated, meat only, raw",Poultry Products,18.28,0.011,0.074,0,0.0058,0.271,0.94,0,5.95,73.77,135,2.32,1.54,0.75,28498
"Duck, wild, breast, meat only, raw",Poultry Products,19.85,0.003,0.057,0,0.0062,0.268,0,0,4.25,75.51,123,1.32,1.21,0.58,28499
"Duck, wild, meat and skin, raw",Poultry Products,17.42,0.005,0.056,0,0.0052,0.249,0,0,15.2,66.52,211,5.04,6.8,2.02,28500
"Duck, young duckling, domesticated, White Pekin, breast, meat and skin, boneless, cooked, roasted",Poultry Products,24.5,0.008,0.084,0,0.0028,0,0,0,10.85,63.25,202,2.922,5.428,1.632,28501
"Duck, young duckling, domesticated, White Pekin, breast, meat only, boneless, cooked without skin, broiled",Poultry Products,27.6,0.009,0.105,0,0.0032,0,0,0,2.5,68.25,140,0.578,0.871,0.378,28502
"Duck, young duckling, domesticated, White Pekin, leg, meat and skin, bone in, cooked, roasted",Poultry Products,26.75,0.01,0.11,0,0.0015,0,0,0,11.4,60.9,217,2.975,5.617,1.896,28503
"Duck, young duckling, domesticated, White Pekin, leg, meat only, bone in, cooked without skin, braised",Poultry Products,29.1,0.01,0.108,0,0.0023,0,0,0,5.96,64.6,178,1.339,2.637,0.909,28504
"Emu, fan fillet, cooked, broiled",Poultry Products,31.27,0.006,0.053,0,0,0.397,0,0,2.3,66.43,154,0.583,0.917,0.318,28505
"Emu, fan fillet, raw",Poultry Products,22.5,0.003,0.12,0,0,0.3,0,0,0.8,74.62,103,0.201,0.319,0.109,28506
"Emu, flat fillet, raw",Poultry Products,22.25,0.003,0.15,0,0,0.24,0,0,0.74,75.4,102,0.187,0.295,0.102,28507
"Emu, full rump, cooked, broiled",Poultry Products,33.67,0.007,0.11,0,0,0.324,0,0,2.68,61.97,168,0.87,1.079,0.613,28508
"Emu, full rump, raw",Poultry Products,22.83,0.004,0.09,0,0,0.33,0,0,1.64,73.79,112,0.448,0.516,0.329,28509
"Emu, ground, cooked, pan-broiled",Poultry Products,28.43,0.008,0.065,0,0,0.375,0,0,4.65,65.83,163,1.242,1.953,0.677,28510
"Emu, ground, raw",Poultry Products,22.77,0.007,0.056,0,0,0.32,0,0,4.03,72.87,134,1.022,1.607,0.557,28511
"Emu, inside drum, raw",Poultry Products,22.22,0.004,0.102,0,0,0.318,0,0,1.49,74.73,108,0.386,0.532,0.256,28512
"Emu, inside drums, cooked, broiled",Poultry Products,32.38,0.006,0.118,0,0,0.312,0,0,2.01,64.31,156,0.654,0.859,0.408,28513
"Emu, outside drum, raw",Poultry Products,23.08,0.003,0.1,0,0,0.32,0,0,0.48,74.87,103,0.122,0.202,0.07,28514
"Emu, oyster, raw",Poultry Products,22.81,0.004,0.15,0,0,0.25,0,0,4.86,72.57,141,1.231,1.937,0.671,28515
"Emu, top loin, cooked, broiled",Poultry Products,29.07,0.009,0.058,0,0,0.374,0,0,3.13,67.37,152,0.794,1.249,0.433,28516
"Goose, domesticated, meat and skin, cooked, roasted",Poultry Products,25.16,0.013,0.07,0,0,0.329,0,0,21.92,51.95,305,6.87,10.25,2.52,28517
"Goose, domesticated, meat and skin, raw",Poultry Products,15.86,0.012,0.073,0,0.004200000000000001,0.308,0,0,33.62,49.66,371,9.78,17.77,3.76,28518
"Goose, domesticated, meat only, cooked, roasted",Poultry Products,28.97,0.014,0.076,0,0,0.388,0,0,12.67,57.23,238,4.56,4.34,1.54,28519
"Goose, domesticated, meat only, raw",Poultry Products,22.75,0.013,0.087,0,0.0072,0.42,0,0,7.13,68.3,161,2.79,1.85,0.9,28520
"Goose, liver, raw",Poultry Products,16.37,0.043,0.14,0,0.0045,0.23,6.32,0,4.28,71.78,133,1.59,0.81,0.26,28521
"Ground Turkey, fat free, raw",Poultry Products,23.57,0.003,0.051,0,0,0.295,0,0,1.95,74.66,112,0.487,0.512,0.594,28522
"Ground turkey, 85% lean, 15% fat, pan-broiled crumbles",Poultry Products,25.11,0.049,0.085,0,0,0.276,0,0,17.45,56.66,258,4.572,6.05,4.624,28523
"Ground turkey, 85% lean, 15% fat, patties, broiled",Poultry Products,25.88,0.048,0.081,0,0,0.242,0,0,16.2,58.25,249,4.127,5.462,4.167,28524
"Ground turkey, 85% lean, 15% fat, raw",Poultry Products,16.9,0.033,0.054,0,0,0.202,0,0,12.54,69.74,180,3.414,4.551,3.485,28525
"Ground turkey, 93% lean, 7% fat, pan-broiled crumbles",Poultry Products,27.1,0.031,0.09,0,0,0.304,0,0,11.6,61.05,213,2.966,3.895,3.562,28526
"Ground turkey, 93% lean, 7% fat, patties, broiled",Poultry Products,25.86,0.029,0.091,0,0,0.247,0,0,11.45,62.42,207,2.958,3.875,3.545,28527
"Ground turkey, 93% lean, 7% fat, raw",Poultry Products,18.73,0.021,0.069,0,0,0.213,0,0,8.34,72.63,150,2.17,2.843,2.537,28528
"Ground turkey, fat free, pan-broiled crumbles",Poultry Products,31.69,0.006,0.061,0,0,0.357,0,0,2.71,65.76,151,0.717,0.751,0.843,28529
"Ground turkey, fat free, patties, broiled",Poultry Products,28.99,0.006,0.059,0,0,0.339,0,0,2.48,68.4,138,0.673,0.713,0.759,28530
"Guinea hen, meat and skin, raw",Poultry Products,23.4,0.011,0.067,0,0.0013,0.193,0,0,6.45,68.9,158,1.77,2.43,1.41,28531
"Guinea hen, meat only, raw",Poultry Products,20.64,0.011,0.069,0,0.0017,0.22,0,0,2.47,74.44,110,0.64,0.68,0.59,28532
"Ostrich, fan, raw",Poultry Products,21.81,0.006,0.075,0,0,0.308,0,0,2.65,75.93,117,0.95,0.95,0.5,28533
"Ostrich, ground, cooked, pan-broiled",Poultry Products,26.15,0.008,0.08,0,0,0.323,0,0,7.07,67.12,175,1.793,2.156,0.739,28534
"Ostrich, ground, raw",Poultry Products,20.22,0.007,0.072,0,0,0.291,0,0,8.7,71.07,165,2.177,2.654,0.91,28535
"Ostrich, inside leg, cooked",Poultry Products,29.01,0.006,0.083,0,0,0.352,0,0,1.94,69.95,141,0.7,0.67,0.32,28536
"Ostrich, inside leg, raw",Poultry Products,22.39,0.005,0.072,0,0,0.32,0,0,1.72,75.97,111,0.6,0.6,0.38,28537
"Ostrich, inside strip, cooked",Poultry Products,29.37,0.005,0.073,0,0,0.366,0,0,4.26,66.53,164,1.71,1.74,0.74,28538
"Ostrich, inside strip, raw",Poultry Products,23.69,0.005,0.076,0,0,0.33,0,0,2.87,74.16,127,0.923,1.179,0.591,28539
"Ostrich, outside leg, raw",Poultry Products,22.86,0.005,0.09,0,0,0.322,0,0,1.96,75.59,115,0.61,0.59,0.43,28540
"Ostrich, outside strip, cooked",Poultry Products,28.55,0.005,0.072,0,0,0.367,0,0,3.83,66.88,156,1.43,1.59,0.44,28541
"Ostrich, outside strip, raw",Poultry Products,23.36,0.005,0.07,0,0,0.333,0,0,2.21,74.58,120,0.771,0.874,0.462,28542
"Ostrich, oyster, cooked",Poultry Products,28.81,0.006,0.081,0,0,0.409,0,0,3.97,67.55,159,1.69,1.45,0.51,28543
"Ostrich, oyster, raw",Poultry Products,21.55,0.006,0.083,0,0,0.297,0,0,3.67,75.62,125,1.22,1.25,0.61,28544
"Ostrich, round, raw",Poultry Products,21.99,0.005,0.072,0,0,0.315,0,0,2.4,75.68,116,0.81,0.85,0.52,28545
"Ostrich, tenderloin, raw",Poultry Products,22.07,0.006,0.086,0,0,0.32,0,0,3.19,74.5,123,1.17,1.23,0.75,28546
"Ostrich, tip trimmed, cooked",Poultry Products,28.49,0.006,0.08,0,0,0.362,0,0,2.57,68.51,145,1,0.97,0.44,28547
"Ostrich, tip trimmed, raw",Poultry Products,21.85,0.005,0.067,0,0,0.31,0,0,2.3,76.12,114,0.86,0.79,0.45,28548
"Ostrich, top loin, cooked",Poultry Products,28.12,0.006,0.077,0,0,0.353,0,0,3.87,67.95,155,1.32,1.27,0.38,28549
"Ostrich, top loin, raw",Poultry Products,21.67,0.006,0.081,0,0,0.312,0,0,2.95,75.35,119,1.2,1.06,0.58,28550
"Pate de foie gras, canned (goose liver pate), smoked",Poultry Products,11.4,0.07,0.697,0,0.002,0.138,4.67,0,43.84,37.04,462,14.45,25.61,0.84,28551
"Pheasant, breast, meat only, raw",Poultry Products,24.37,0.003,0.033,0,0.006,0.242,0,0,3.25,72.4,133,1.1,1.04,0.55,28552
"Pheasant, cooked, total edible",Poultry Products,32.4,0.016,0.043,0,0.0023,0.271,0,0,12.1,54.2,239,3.908,5.627,1.537,28553
"Pheasant, leg, meat only, raw",Poultry Products,22.2,0.029,0.045,0,0.006,0.296,0,0,4.3,73.4,134,1.46,1.38,0.73,28554
"Pheasant, raw, meat and skin",Poultry Products,22.7,0.012,0.04,0,0.0053,0.243,0,0,9.29,67.77,181,2.7,4.32,1.18,28555
"Pheasant, raw, meat only",Poultry Products,23.57,0.013,0.037,0,0.006,0.262,0,0,3.64,72.77,133,1.24,1.17,0.62,28556
"Poultry food products, ground turkey, cooked",Poultry Products,27.37,0.028,0.078,0,0,0.294,0,0,10.4,62.07,203,2.669,3.458,2.917,28557
"Poultry food products, ground turkey, raw",Poultry Products,19.66,0.019,0.058,0,0,0.237,0,0,7.66,72.36,148,2.024,2.635,2.205,28558
"Poultry, mechanically deboned, from backs and necks with skin, raw",Poultry Products,11.39,0.138,0.04,0,0.0015,0.104,0,0,24.73,62.66,272,7.45,10.44,4.96,28559
"Poultry, mechanically deboned, from backs and necks without skin, raw",Poultry Products,13.79,0.123,0.051,0,0.003,0.128,0,0,15.48,69.29,199,4.71,7.41,2.28,28560
"Poultry, mechanically deboned, from mature hens, raw",Poultry Products,14.72,0.187,0.04,0,0.002,0.104,0,0,19.98,62.95,243,4.73,9.3,4.55,28561
"Quail, breast, meat only, raw",Poultry Products,22.59,0.01,0.055,0,0.0050999999999999995,0.26,0,0,2.99,71.67,123,0.87,0.84,0.77,28562
"Quail, cooked, total edible",Poultry Products,25.1,0.015,0.052,0,0.0023,0.216,0,0,14.1,60,227,3.955,4.891,3.487,28563
"Quail, meat and skin, raw",Poultry Products,19.63,0.013,0.053,0,0.0060999999999999995,0.216,0,0,12.05,69.65,192,3.38,4.18,2.98,28564
"Quail, meat only, raw",Poultry Products,21.76,0.013,0.051,0,0.0072,0.237,0,0,4.53,70.03,134,1.32,1.28,1.17,28565
"Squab, (pigeon), light meat without skin, raw",Poultry Products,21.76,0.01,0.055,0,0.0050999999999999995,0.26,0,0,4.52,72.82,134,1.18,1.61,0.96,28566
"Squab, (pigeon), meat and skin, raw",Poultry Products,18.47,0.012,0.054,0,0.0052,0.199,0,0,23.8,56.6,294,8.43,9.72,3.07,28567
"Squab, (pigeon), meat only, raw",Poultry Products,17.5,0.013,0.051,0,0.0072,0.237,0,0,7.5,72.8,142,1.96,2.66,1.6,28568
"Turkey and gravy, frozen",Poultry Products,5.88,0.014,0.554,0,0,0.061,4.61,0,2.63,85.07,67,0.85,0.97,0.47,28569
"Turkey bacon, cooked",Poultry Products,29.6,0.009,2.285,0,0,0.395,3.1,0,27.9,32.6,382,8.294,10.899,6.807,28570
"Turkey breast, pre-basted, meat and skin, cooked, roasted",Poultry Products,22.16,0.009,0.397,0,0,0.248,0,0,3.46,70.91,126,0.98,1.14,0.84,28571
"Turkey patties, breaded, battered, fried",Poultry Products,14,0.014,0.8,0.5,0,0.275,15.7,0.32,18,49.7,283,4.69,7.47,4.71,28572
"Turkey roast, boneless, frozen, seasoned, light and dark meat, raw",Poultry Products,17.6,0.001,0.678,0,0,0.36,6.4,0,2.2,70.4,120,0.73,0.47,0.64,28573
"Turkey roast, boneless, frozen, seasoned, light and dark meat, roasted",Poultry Products,21.32,0.005,0.68,0,0,0.298,3.07,0,5.78,67.84,155,1.9,1.2,1.66,28574
"Turkey sticks, breaded, battered, fried",Poultry Products,14.2,0.014,0.838,0,0,0.26,17,0,16.9,49.4,279,4.38,6.92,4.39,28575
"Turkey thigh, pre-basted, meat and skin, cooked, roasted",Poultry Products,18.8,0.008,0.437,0,0,0.241,0,0,8.54,70.6,157,2.65,2.53,2.35,28576
"Turkey, all classes, back, meat and skin, cooked, roasted",Poultry Products,26.59,0.033,0.073,0,0,0.26,0.16,0,14.38,57.94,244,4.18,5,3.7,28577
"Turkey, all classes, back, meat and skin, raw",Poultry Products,18.11,0.017,0.066,0,0,0.236,0,0,13.11,67.78,196,3.66,4.94,3.23,28578
"Turkey, all classes, breast, meat and skin, cooked, roasted",Poultry Products,28.71,0.021,0.063,0,0,0.288,0,0,7.41,63.22,189,2.1,2.45,1.8,28579
"Turkey, all classes, breast, meat and skin, raw",Poultry Products,21.89,0.013,0.059,0,0,0.275,0,0,7.02,70.05,157,1.91,2.66,1.66,28580
"Turkey, all classes, dark meat, cooked, roasted",Poultry Products,28.57,0.032,0.079,0,0,0.29,0.1,0,7.22,63.09,188,2.182,1.729,2.298,28581
"Turkey, all classes, dark meat, meat and skin, cooked, roasted",Poultry Products,27.49,0.033,0.076,0,0,0.274,0,0,11.54,60.23,221,3.49,3.65,3.09,28582
"Turkey, all classes, dark meat, meat and skin, raw",Poultry Products,18.92,0.017,0.071,0,0,0.261,0,0,8.8,71.13,160,2.58,3,2.28,28583
"Turkey, all classes, dark meat, raw",Poultry Products,20.07,0.017,0.077,0,0,0.286,0,0,4.38,74.48,125,1.47,0.99,1.31,28584
"Turkey, all classes, giblets, cooked, simmered, some giblet fat",Poultry Products,20.89,0.006,0.064,0,0.013699999999999999,0.27,0.8,0,11.86,65.26,199,3.923,4.954,1.293,28585
"Turkey, all classes, giblets, raw",Poultry Products,19.36,0.008,0.087,0,0.0038,0.316,2.09,0,4.2,73.18,129,1.26,0.91,1.03,28586
"Turkey, all classes, leg, meat and skin, cooked, roasted",Poultry Products,27.87,0.032,0.077,0,0,0.28,0.13,0,9.82,61.19,208,3.06,2.87,2.72,28587
"Turkey, all classes, leg, meat and skin, raw",Poultry Products,19.54,0.017,0.074,0,0,0.273,0,0,6.72,72.69,144,2.06,2.06,1.83,28588
"Turkey, all classes, light meat, cooked, roasted",Poultry Products,29.9,0.019,0.064,0,0,0.305,0,0,3.22,66.27,157,1.03,0.56,0.86,28589
"Turkey, all classes, light meat, meat and skin, cooked, roasted",Poultry Products,28.57,0.021,0.063,0,0,0.285,0,0,8.33,62.83,197,2.34,2.84,2.01,28590
"Turkey, all classes, light meat, meat and skin, raw",Poultry Products,21.64,0.013,0.059,0,0,0.271,0,0,7.36,69.83,159,2,2.81,1.73,28591
"Turkey, all classes, light meat, raw",Poultry Products,23.56,0.012,0.063,0,0,0.305,0,0,1.56,73.82,115,0.5,0.27,0.42,28592
"Turkey, all classes, meat and skin and giblets and neck, cooked, roasted",Poultry Products,27.98,0.026,0.067,0,0.0001,0.272,0.07,0,9.45,62,205,2.77,3.05,2.42,28593
"Turkey, all classes, meat and skin and giblets and neck, raw",Poultry Products,20.37,0.015,0.067,0,0.0002,0.269,0.08,0,7.77,70.61,157,2.2,2.76,1.93,28594
"Turkey, all classes, meat and skin, cooked, roasted",Poultry Products,28.1,0.026,0.068,0,0,0.28,0,0,9.73,61.7,208,2.84,3.19,2.48,28595
"Turkey, all classes, meat and skin, raw",Poultry Products,20.42,0.015,0.065,0,0,0.266,0,0,8.02,70.4,160,2.26,2.9,1.98,28596
"Turkey, all classes, meat only, cooked, roasted",Poultry Products,29.32,0.025,0.07,0,0,0.298,0,0,4.97,64.88,170,1.459,1.115,1.501,28597
"Turkey, all classes, meat only, raw",Poultry Products,21.77,0.014,0.07,0,0,0.296,0.24,0,2.86,74.16,120,0.95,0.61,0.83,28598
"Turkey, all classes, neck, meat only, cooked, simmered",Poultry Products,26.84,0.037,0.056,0,0,0.149,0.1,0,7.26,65.19,180,2.194,1.74,2.31,28599
"Turkey, all classes, neck, meat only, raw",Poultry Products,20.14,0.034,0.093,0,0,0.302,0,0,5.42,72.88,135,1.82,1.23,1.63,28600
"Turkey, all classes, skin only, cooked, roasted",Poultry Products,19.7,0.035,0.053,0,0,0.16,0.33,0,39.66,39.65,443,10.34,16.9,9.08,28601
"Turkey, all classes, skin only, raw",Poultry Products,12.71,0.018,0.036,0,0,0.102,0.43,0,36.91,49.55,389,9.63,15.73,8.45,28602
"Turkey, all classes, wing, meat and skin, cooked, roasted",Poultry Products,27.38,0.024,0.061,0,0,0.266,0,0,12.43,59.49,229,3.39,4.66,2.94,28603
"Turkey, all classes, wing, meat and skin, raw",Poultry Products,20.22,0.014,0.055,0,0,0.24,0,0,12.32,66.49,197,3.28,4.97,2.86,28604
"Turkey, canned, meat only, with broth",Poultry Products,23.68,0.012,0.467,0,0.002,0.224,1.47,0,6.86,66.07,169,2,2.26,1.75,28605
"Turkey, diced, light and dark meat, seasoned",Poultry Products,18.7,0.001,0.85,0,0,0.31,1,0,6,71.7,138,1.75,1.98,1.53,28606
"Turkey, drumstick, smoked, cooked, with skin, bone removed",Poultry Products,27.9,0.032,0.996,0,0,0.28,0,0,9.8,61.2,200,3.054,2.864,2.715,28607
"Turkey, fryer-roasters, back, meat and skin, cooked, roasted",Poultry Products,26.15,0.036,0.07,0,0,0.208,0,0,10.24,63.27,204,2.99,3.53,2.64,28608
"Turkey, fryer-roasters, back, meat and skin, raw",Poultry Products,19.89,0.019,0.06,0,0,0.204,0,0,7.27,72.47,151,2.12,2.5,1.88,28609
"Turkey, fryer-roasters, back, meat only, cooked, roasted",Poultry Products,28.02,0.036,0.073,0,0,0.217,0,0,5.64,66.01,170,1.89,1.28,1.69,28610
"Turkey, fryer-roasters, back, meat only, raw",Poultry Products,20.66,0.019,0.065,0,0,0.222,0,0,3.51,75.37,120,1.18,0.8,1.05,28611
"Turkey, fryer-roasters, breast, meat and skin, cooked, roasted",Poultry Products,29.07,0.015,0.053,0,0,0.279,0,0,3.2,67.63,153,0.87,1.2,0.76,28612
"Turkey, fryer-roasters, breast, meat and skin, raw",Poultry Products,23.76,0.011,0.048,0,0,0.276,0,0,2.65,72.88,125,0.72,1,0.63,28613
"Turkey, fryer-roasters, breast, meat only, cooked, roasted",Poultry Products,30.06,0.012,0.052,0,0,0.292,0,0,0.74,68.4,135,0.24,0.13,0.2,28614
"Turkey, fryer-roasters, breast, meat only, raw",Poultry Products,24.6,0.01,0.049,0,0,0.293,0,0,0.65,74.12,111,0.21,0.11,0.17,28615
"Turkey, fryer-roasters, dark meat, meat and skin, cooked, roasted",Poultry Products,27.69,0.027,0.076,0,0,0.237,0,0,7.06,64.82,182,2.12,2.27,1.88,28616
"Turkey, fryer-roasters, dark meat, meat and skin, raw",Poultry Products,20.06,0.014,0.066,0,0,0.232,0,0,4.79,74.56,129,1.43,1.55,1.27,28617
"Turkey, fryer-roasters, dark meat, meat only, cooked, roasted",Poultry Products,28.84,0.026,0.079,0,0,0.246,0,0,4.31,66.39,162,1.45,0.98,1.29,28618
"Turkey, fryer-roasters, dark meat, meat only, raw",Poultry Products,20.46,0.013,0.069,0,0,0.244,0,0,2.67,76.22,111,0.9,0.61,0.8,28619
"Turkey, fryer-roasters, leg, meat and skin, cooked, roasted",Poultry Products,28.49,0.023,0.08,0,0,0.252,0,0,5.41,65.62,170,1.67,1.61,1.48,28620
"Turkey, fryer-roasters, leg, meat and skin, raw",Poultry Products,20.13,0.011,0.069,0,0,0.246,0,0,3.57,75.61,118,1.1,1.08,0.97,28621
"Turkey, fryer-roasters, leg, meat only, cooked, roasted",Poultry Products,29.19,0.022,0.081,0,0,0.258,0,0,3.77,66.54,159,1.27,0.86,1.13,28622
"Turkey, fryer-roasters, leg, meat only, raw",Poultry Products,20.35,0.01,0.071,0,0,0.254,0,0,2.37,76.62,108,0.8,0.54,0.71,28623
"Turkey, fryer-roasters, light meat, meat and skin, cooked, roasted",Poultry Products,28.77,0.018,0.057,0,0,0.262,0,0,4.58,66.54,164,1.25,1.7,1.09,28624
"Turkey, fryer-roasters, light meat, meat and skin, raw",Poultry Products,23.09,0.012,0.05,0,0,0.253,0,0,3.81,72.49,133,1.02,1.52,0.89,28625
"Turkey, fryer-roasters, light meat, meat only, cooked, roasted",Poultry Products,30.19,0.015,0.056,0,0,0.277,0,0,1.18,68.56,140,0.38,0.21,0.31,28626
"Turkey, fryer-roasters, light meat, meat only, raw",Poultry Products,24.18,0.01,0.052,0,0,0.276,0,0,0.49,74.61,108,0.16,0.09,0.13,28627
"Turkey, fryer-roasters, meat and skin and giblets and neck, cooked, roasted",Poultry Products,28.08,0.023,0.065,0,0.0001,0.242,0.04,0,5.64,65.95,171,1.64,1.89,1.44,28628
"Turkey, fryer-roasters, meat and skin and giblets and neck, raw",Poultry Products,22.15,0.013,0.061,0,0.0002,0.246,0.05,0,4.25,72.9,133,1.22,1.47,1.07,28629
"Turkey, fryer-roasters, meat and skin, cooked, roasted",Poultry Products,28.26,0.022,0.066,0,0,0.25,0,0,5.72,65.76,172,1.65,1.96,1.45,28630
"Turkey, fryer-roasters, meat and skin, raw",Poultry Products,22.37,0.013,0.058,0,0,0.243,0,0,4.25,72.74,134,1.21,1.52,1.06,28631
"Turkey, fryer-roasters, meat only, cooked, roasted",Poultry Products,29.56,0.02,0.067,0,0,0.263,0,0,2.63,67.57,150,0.87,0.56,0.77,28632
"Turkey, fryer-roasters, meat only, raw",Poultry Products,22.32,0.012,0.061,0,0,0.26,0,0,1.58,75.42,110,0.53,0.35,0.47,28633
"Turkey, fryer-roasters, skin only, cooked, roasted",Poultry Products,20.94,0.035,0.061,0,0,0.181,0,0,23.28,55.46,299,6.07,9.92,5.33,28634
"Turkey, fryer-roasters, skin only, raw",Poultry Products,16.6,0.019,0.035,0,0,0.121,0,0,23.54,59.86,283,6.14,10.03,5.39,28635
"Turkey, fryer-roasters, wing, meat and skin, cooked, roasted",Poultry Products,27.65,0.029,0.073,0,0,0.196,0,0,9.87,62.3,207,2.71,3.62,2.35,28636
"Turkey, fryer-roasters, wing, meat and skin, raw",Poultry Products,20.85,0.014,0.056,0,0,0.178,0,0,7.72,71.19,159,2.06,3.09,1.8,28637
"Turkey, fryer-roasters, wing, meat only, cooked, roasted",Poultry Products,30.85,0.026,0.078,0,0,0.204,0,0,3.44,65.6,163,0.967,0.681,0.967,28638
"Turkey, fryer-roasters, wing, meat only, raw",Poultry Products,22.49,0.012,0.065,0,0,0.201,0,0,1.12,75.82,106,0.36,0.2,0.3,28639
"Turkey, gizzard, all classes, cooked, simmered",Poultry Products,21.72,0.007,0.067,0,0.0063,0.332,0.38,0,3.87,72.97,129,1.163,1.21,0.503,28640
"Turkey, gizzard, all classes, raw",Poultry Products,19.14,0.006,0.072,0,0.0062,0.322,0,0,4.58,76.18,118,1.312,1.588,0.777,28641
"Turkey, heart, all classes, cooked, simmered",Poultry Products,21.47,0.007,0.09,0,0.0028,0.291,0.68,0,4.64,71.98,136,1.289,1.138,1.191,28642
"Turkey, heart, all classes, raw",Poultry Products,17.13,0.006,0.094,0,0.003,0.295,0.41,0,4.79,76.62,113,1.316,1.391,1.272,28643
"Turkey, light or dark meat, smoked, cooked, skin and bone removed",Poultry Products,29.3,0.025,0.996,0,0,0.298,0,0,5,64.9,162,1.368,1.046,1.408,28644
"Turkey, light or dark meat, smoked, cooked, with skin, bone removed",Poultry Products,28.1,0.026,0.996,0,0,0.28,0,0,9.7,61.7,200,2.831,3.18,2.473,28645
"Turkey, liver, all classes, cooked, simmered",Poultry Products,20.02,0.005,0.056,0,0.022600000000000002,0.211,1.21,0,20.54,56.95,273,6.947,9.108,2.022,28646
"Turkey, liver, all classes, raw",Poultry Products,17.84,0.005,0.071,0,0.0245,0.255,2.26,0,16.36,62.29,228,5.507,7.397,1.65,28647
"Turkey, mechanically deboned, from turkey frames, raw",Poultry Products,13.29,0.145,0.048,0,0,0.173,0,0,15.96,69.09,201,5.31,5.02,4.51,28648
"Turkey, wing, smoked, cooked, with skin, bone removed",Poultry Products,27.4,0.024,0.996,0,0,0.266,0,0,12.41,59.5,221,3.383,4.651,2.934,28649
"Turkey, young hen, back, meat and skin, cooked, roasted",Poultry Products,26.41,0.031,0.069,0,0,0.263,0,0,15.64,56.91,254,4.54,5.45,4.01,28650
"Turkey, young hen, back, meat and skin, raw",Poultry Products,17.51,0.018,0.061,0,0,0.231,0,0,15.93,65.61,218,4.41,6.12,3.89,28651
"Turkey, young hen, breast, meat and skin, cooked, roasted",Poultry Products,28.8,0.022,0.058,0,0,0.289,0,0,7.86,62.69,194,2.25,2.5,1.93,28652
"Turkey, young hen, breast, meat and skin, raw",Poultry Products,21.62,0.013,0.055,0,0,0.266,0,0,8.3,69,167,2.25,3.19,1.95,28653
"Turkey, young hen, dark meat, meat and skin, cooked, roasted",Poultry Products,27.37,0.031,0.072,0,0,0.276,0,0,12.78,59.61,232,3.86,4.06,3.42,28654
"Turkey, young hen, dark meat, meat and skin, raw",Poultry Products,18.65,0.018,0.067,0,0,0.259,0,0,10.25,69.92,172,2.99,3.53,2.64,28655
"Turkey, young hen, dark meat, meat only, cooked, roasted",Poultry Products,28.42,0.03,0.075,0,0,0.292,0,0,7.79,62.74,192,2.61,1.77,2.33,28656
"Turkey, young hen, dark meat, meat only, raw",Poultry Products,20.07,0.018,0.074,0,0,0.287,0,0,4.88,74.03,130,1.64,1.11,1.46,28657
"Turkey, young hen, leg, meat and skin, cooked, roasted",Poultry Products,27.73,0.03,0.073,0,0,0.282,0,0,10.5,60.73,213,3.28,3.04,2.91,28658
"Turkey, young hen, leg, meat and skin, raw",Poultry Products,19.46,0.018,0.07,0,0,0.273,0,0,7.5,72.04,151,2.29,2.29,2.04,28659
"Turkey, young hen, light meat, meat and skin, cooked, roasted",Poultry Products,28.64,0.023,0.058,0,0,0.286,0,0,9.4,62.14,207,2.65,3.15,2.28,28660
"Turkey, young hen, light meat, meat and skin, raw",Poultry Products,21.51,0.013,0.055,0,0,0.267,0,0,8.1,69.13,165,2.19,3.1,1.91,28661
"Turkey, young hen, light meat, meat only, cooked, roasted",Poultry Products,29.89,0.021,0.06,0,0,0.304,0,0,3.74,65.74,161,1.19,0.66,1,28662
"Turkey, young hen, light meat, meat only, raw",Poultry Products,23.64,0.012,0.06,0,0,0.299,0,0,1.66,73.57,116,0.53,0.29,0.44,28663
"Turkey, young hen, meat and skin and giblets and neck, cooked, roasted",Poultry Products,28,0.026,0.063,0,0.0001,0.272,0.07,0,10.55,61.34,215,3.1,3.39,2.7,28664
"Turkey, young hen, meat and skin and giblets and neck, raw",Poultry Products,20.15,0.016,0.063,0,0.0002,0.267,0.11,0,8.78,69.74,166,2.49,3.13,2.18,28665
"Turkey, young hen, meat and skin, cooked, roasted",Poultry Products,28.09,0.026,0.064,0,0,0.282,0,0,10.88,61.04,218,3.18,3.54,2.78,28666
"Turkey, young hen, meat and skin, raw",Poultry Products,20.18,0.015,0.061,0,0,0.263,0,0,9.09,69.5,168,2.56,3.3,2.25,28667
"Turkey, young hen, meat only, cooked, roasted",Poultry Products,29.25,0.025,0.067,0,0,0.299,0,0,5.51,64.43,175,1.82,1.14,1.58,28668
"Turkey, young hen, meat only, raw",Poultry Products,21.76,0.015,0.066,0,0,0.293,0,0,3.18,73.77,122,1.05,0.68,0.92,28669
"Turkey, young hen, skin only, cooked, roasted",Poultry Products,19.03,0.032,0.044,0,0,0.155,0,0,44.45,35.53,482,11.59,18.94,10.18,28670
"Turkey, young hen, skin only, raw",Poultry Products,11.79,0.019,0.032,0,0,0.107,0,0,40.62,46.8,417,10.6,17.31,9.3,28671
"Turkey, young hen, wing, meat and skin, cooked, roasted",Poultry Products,27.3,0.024,0.056,0,0,0.268,0,0,13.46,58.53,238,3.68,5.02,3.19,28672
"Turkey, young hen, wing, meat and skin, raw",Poultry Products,19.93,0.014,0.051,0,0,0.238,0,0,13.87,65.18,210,3.68,5.62,3.22,28673
"Turkey, young tom, back, meat and skin, cooked, roasted",Poultry Products,26.8,0.035,0.077,0,0,0.264,0,0,13.65,58.33,238,3.97,4.73,3.51,28674
"Turkey, young tom, back, meat and skin, raw",Poultry Products,18.47,0.017,0.071,0,0,0.245,0,0,11.14,69.29,179,3.14,4.12,2.78,28675
"Turkey, young tom, breast, meat and skin, cooked, roasted",Poultry Products,28.61,0.021,0.067,0,0,0.289,0,0,7.39,63.3,189,2.08,2.51,1.79,28676
"Turkey, young tom, breast, meat and skin, raw",Poultry Products,21.96,0.013,0.063,0,0,0.282,0,0,6.34,70.68,151,1.73,2.36,1.5,28677
"Turkey, young tom, dark meat, meat and skin, cooked, roasted",Poultry Products,27.58,0.035,0.08,0,0,0.276,0,0,10.85,60.33,216,3.28,3.41,2.91,28678
"Turkey, young tom, dark meat, meat and skin, raw",Poultry Products,19.05,0.017,0.075,0,0,0.265,0,0,7.89,71.88,152,2.32,2.66,2.06,28679
"Turkey, young tom, dark meat, meat only, cooked, roasted",Poultry Products,28.68,0.035,0.082,0,0,0.293,0,0,6.98,63.07,185,2.34,1.58,2.09,28680
"Turkey, young tom, dark meat, meat only, raw",Poultry Products,20.04,0.017,0.08,0,0,0.29,0,0,4.11,74.7,123,1.38,0.93,1.23,28681
"Turkey, young tom, leg, meat and skin, cooked, roasted",Poultry Products,27.93,0.035,0.08,0,0,0.281,0,0,9.63,61.18,206,2.99,2.83,2.66,28682
"Turkey, young tom, leg, meat and skin, raw",Poultry Products,19.54,0.017,0.077,0,0,0.275,0,0,6.34,72.98,141,1.94,1.94,1.72,28683
"Turkey, young tom, light meat, meat and skin, cooked, roasted",Poultry Products,28.48,0.021,0.067,0,0,0.287,0,0,7.7,63.13,191,2.15,2.66,1.86,28684
"Turkey, young tom, light meat, meat and skin, raw",Poultry Products,21.63,0.013,0.063,0,0,0.277,0,0,7.04,70.2,156,1.91,2.68,1.66,28685
"Turkey, young tom, light meat, meat only, cooked, roasted",Poultry Products,29.88,0.018,0.068,0,0,0.308,0,0,2.92,66.56,154,0.93,0.51,0.78,28686
"Turkey, young tom, light meat, meat only, raw",Poultry Products,23.43,0.012,0.067,0,0,0.314,0,0,1.57,73.96,114,0.5,0.28,0.42,28687
"Turkey, young tom, meat and skin and giblets and neck, cooked, roasted",Poultry Products,27.97,0.026,0.072,0,0.0001,0.276,0.1,0,8.81,62.18,199,2.58,2.86,2.26,28688
"Turkey, young tom, meat and skin and giblets and neck, raw",Poultry Products,20.39,0.015,0.07,0,0.0002,0.274,0.08,0,7.21,71.14,152,2.05,2.55,1.79,28689
"Turkey, young tom, meat and skin, cooked, roasted",Poultry Products,28.09,0.027,0.072,0,0,0.282,0,0,9.06,61.92,202,2.64,2.98,2.31,28690
"Turkey, young tom, meat and skin, raw",Poultry Products,20.45,0.015,0.068,0,0,0.271,0,0,7.43,70.96,154,2.1,2.68,1.84,28691
"Turkey, young tom, meat only, cooked, roasted",Poultry Products,29.36,0.025,0.074,0,0,0.301,0,0,4.68,65.05,168,1.54,0.98,1.35,28692
"Turkey, young tom, meat only, raw",Poultry Products,21.72,0.014,0.073,0,0,0.302,0,0,2.7,74.38,117,0.89,0.57,0.78,28693
"Turkey, young tom, skin only, cooked, roasted",Poultry Products,20.14,0.037,0.06,0,0,0.161,0,0,37.25,41.54,422,9.72,15.87,8.53,28694
"Turkey, young tom, skin only, raw",Poultry Products,13.24,0.018,0.04,0,0,0.096,0,0,34.56,51.25,368,9.02,14.73,7.92,28695
"Turkey, young tom, wing, meat and skin, cooked, roasted",Poultry Products,27.45,0.023,0.066,0,0,0.271,0,0,11.5,60.31,221,3.13,4.35,2.72,28696
"Turkey, young tom, wing, meat and skin, raw",Poultry Products,20.45,0.013,0.06,0,0,0.25,0,0,11.22,67.32,188,2.99,4.5,2.61,28697
"USDA Commodity Chicken, canned, meat only, drained",Poultry Products,27.52,0.012,0.271,0,0,0.189,0,0,5.72,67.29,162,1.572,2.047,1.313,28698
"USDA Commodity, Chicken, canned, meat only, with broth",Poultry Products,22.41,0.01,0.256,0,0,0.168,0.23,0.03,4.69,72.55,133,1.293,1.691,1.075,28699
"USDA Commodity, Chicken, canned, meat only, with water",Poultry Products,22.02,0.01,0.251,0,0,0.151,0,0,4.58,73.31,129,1.258,1.637,1.05,28700
"USDA Commodity, chicken fajita strips, frozen",Poultry Products,18.56,0.013,0.799,0,0,0.284,2.23,0,5.73,70.6,135,1.596,2.333,1.089,28701
"USDA Commodity, turkey ham, dark meat, smoked, frozen",Poultry Products,16.3,0.007,0.909,0,0,0.253,3.1,1.2,4,73.5,118,1.2,1.337,0.873,28702
"USDA Commodity, turkey taco meat, frozen, cooked",Poultry Products,16.8,0.069,0.632,0,0,0.297,3.03,0,7.58,70,148,1.765,2.59,2.11,28703
Adobo fresco,"Soups, Sauces, and Gravies",2,0.123,17.152,1.3,0.0058,0.187,18.6,2.03,20.9,22.1,271,2.91,14.963,2.044,28704
"CAMPBELL Soup Company, CAMPBELL'S Au Jus Gravy","Soups, Sauces, and Gravies",1.69,0,0.39,0,0,0,0,0,0,97.8,8,0,0,0,28705
"CAMPBELL Soup Company, CAMPBELL'S Beef Gravy","Soups, Sauces, and Gravies",1.69,0,0.458,0,0,0,5.08,1.69,1.69,90.2,42,0.847,0,0,28706
"CAMPBELL Soup Company, CAMPBELL'S Brown Gravy with Onions","Soups, Sauces, and Gravies",0,0,0.559,0,0,0,6.78,3.39,1.69,88.2,42,0,0,0,28707
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Microwavable Bowls, Beef with Country Vegetables Soup, ready-to-serve","Soups, Sauces, and Gravies",4.08,0.008,0.367,2,0.0005,0,8.57,1.22,1.22,85.3,61,0.612,0,0,28708
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Microwavable Bowls, Chicken and Dumplings Soup","Soups, Sauces, and Gravies",3.27,0.008,0.363,1.2,0,0,7.35,0.82,3.67,84.7,78,0.816,0,0,28709
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Microwavable Bowls, Classic Chicken Noodle, ready-to-serve","Soups, Sauces, and Gravies",2.45,0.008,0.322,0.8,0,0,5.71,1.22,1.22,88.9,45,0.408,0,0,28710
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Microwavable Bowls, Grilled Chicken and Sausage Gumbo, ready-to-serve","Soups, Sauces, and Gravies",2.86,0.016,0.318,0.4,0,0,7.35,2.04,1.63,87.8,57,0.816,0,0,28711
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Microwavable Bowls, Grilled Chicken with Vegetables and Pasta Soup, ready-to-serve","Soups, Sauces, and Gravies",2.86,0.008,0.347,1.2,0.0005,0,5.71,0.82,1.02,88.7,45,0.204,0,0,28712
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Microwavable Bowls, New England Clam Chowder, ready-to-serve","Soups, Sauces, and Gravies",2.45,0.033,0.355,1.2,0,0,6.94,0.41,4.9,84.2,82,1.02,0,0,28713
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Microwavable Bowls, Old Fashioned Vegetable Beef Soup, ready-to-serve","Soups, Sauces, and Gravies",2.86,0.016,0.359,1.2,0,0,5.71,1.63,0.61,89,41,0.204,0,0,28714
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Microwavable Bowls, Sirloin Burger with Country Vegetables Soup, ready-to-serve","Soups, Sauces, and Gravies",3.27,0.008,0.327,1.2,0,0,7.35,1.63,1.43,85.3,57,0.612,0,0,28715
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, BBQ Seasoned Pork Soup","Soups, Sauces, and Gravies",4.9,0.016,0.376,2,0,0,8.98,2.04,1.43,83.3,68,0.612,0,0,28716
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Baked Potato with Cheddar & Bacon Bits Soup","Soups, Sauces, and Gravies",2.04,0.016,0.322,0.8,0,0,9.39,1.25,3.67,83.8,78,1.224,0,0,28717
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Baked Potato with Steak & Cheese Soup","Soups, Sauces, and Gravies",3.27,0.008,0.343,1.2,0,0,8.57,1.22,4.08,82.3,82,1.02,0,0,28718
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Barbeque Seasoned Burger Soup","Soups, Sauces, and Gravies",4.08,0.024,0.367,2,0,0,11.43,4.08,2.45,80.7,84,1.02,0,0,28719
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Beef Rib Roast with Potatoes & Herbs Soup","Soups, Sauces, and Gravies",2.86,0.008,0.363,0.8,0.0005,0,6.94,2.45,0.41,87.7,44,0.408,0,0,28720
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Beef Stew - Fully Loaded","Soups, Sauces, and Gravies",4.08,0.008,0.331,1.2,0.0005,0,8.16,2.04,2.04,84.2,69,0.816,0,0,28721
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Beef Stroganof - Fully Loaded","Soups, Sauces, and Gravies",4.9,0.016,0.331,1.6,0,0,7.35,1.22,5.71,81,102,1.633,0,0,28722
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Beef with Country Vegetables Soup","Soups, Sauces, and Gravies",3.27,0.008,0.376,1.2,0,0,7.35,1.63,1.22,85.3,53,0.408,0,0,28723
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Beef with White and Wild Rice Soup","Soups, Sauces, and Gravies",3.27,0.008,0.363,0.8,0,0,9.8,2.04,0.61,84.3,57,0.204,0,0,28724
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Chicken Broccoli Cheese & Potato Soup","Soups, Sauces, and Gravies",2.86,0.008,0.359,1.2,0,0,8.16,2.45,4.49,83.22,86,1.633,0,0,28725
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Chicken Corn Chowder","Soups, Sauces, and Gravies",2.86,0.008,0.351,0.8,0,0,8.16,1.22,4.08,83.8,82,1.224,0,0,28726
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Chicken Mushroom Chowder","Soups, Sauces, and Gravies",3.27,0.008,0.347,1.2,0,0,7.76,1.63,3.67,83.2,78,0.612,0,0,28727
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Chicken and Dumplings Soup","Soups, Sauces, and Gravies",3.27,0.008,0.363,1.2,0,0,7.76,4.9,3.27,84.7,74,0.816,0,0,28728
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Classic Chicken Noodle Soup","Soups, Sauces, and Gravies",3.27,0.008,0.322,0.8,0,0,5.71,0.82,1.22,88.3,49,0.408,0,0,28729
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Fajita Chicken with Rice & Beans Soup","Soups, Sauces, and Gravies",2.86,0.008,0.347,0.8,0,0,9.39,2.86,0.61,83.9,53,0.204,0,0,28730
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Firehouse - Hot & Spicy Beef & Bean Chili","Soups, Sauces, and Gravies",6.12,0.016,0.355,2.4,0.0005,0,10.2,2.86,3.27,78.7,95,1.429,0,0,28731
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Grilled Chicken & Sausage Gumbo Soup","Soups, Sauces, and Gravies",2.86,0.008,0.347,0.8,0,0,8.57,1.63,1.22,85.6,57,0.612,0,0,28732
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Grilled Chicken with Vegetables & Pasta Soup","Soups, Sauces, and Gravies",2.45,0.008,0.359,0.8,0,0,5.71,0.82,1.02,88.8,41,0.204,0,0,28733
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Grilled Sirloin Steak with Hearty Vegetables Soup","Soups, Sauces, and Gravies",3.27,0.008,0.363,1.6,0.0005,0,7.76,6.12,0.82,87,53,0.408,0,0,28734
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, HEALTHY REQUEST Beef Barley Soup","Soups, Sauces, and Gravies",3.67,0.008,0.196,2,0,0.327,8.57,1.22,0.82,82.6,57,0.408,0.204,0,28735
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, HEALTHY REQUEST Chicken Noodle Soup","Soups, Sauces, and Gravies",3.27,0.008,0.167,0.8,0.001,0,6.94,1.22,1.02,87.5,49,0.408,0,0,28736
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, HEALTHY REQUEST Microwavable Bowls, Chicken Noodle Soup","Soups, Sauces, and Gravies",2.86,0.008,0.167,0.4,0.001,0.286,6.94,0.41,1.02,88.5,49,0.408,0.408,0.204,28737
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, HEALTHY REQUEST Microwavable Bowls, Grilled Chicken & Sausage Gumbo Soup","Soups, Sauces, and Gravies",2.86,0.016,0.167,0.8,0.0024,0.249,7.35,1.63,1.22,86.8,53,0.408,0.612,0.204,28738
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, HEALTHY REQUEST New England Clam Chowder","Soups, Sauces, and Gravies",2.04,0.041,0.167,0.8,0,0.408,8.16,0.82,1.22,81.1,53,0.408,0.408,0.408,28739
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, HEALTHY REQUEST Vegetable Soup","Soups, Sauces, and Gravies",1.63,0.024,0.167,1.6,0,0,9.8,3.27,0.41,87.7,49,0.204,0,0,28740
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Hearty Bean 'N' Ham Soup","Soups, Sauces, and Gravies",4.49,0.024,0.318,3.3,0,0,12.24,2.04,0.82,81.6,74,0.204,0,0,28741
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Hearty Beef Barley Soup","Soups, Sauces, and Gravies",3.67,0.008,0.322,1.6,0.0005,0,10.61,2.04,0.82,82.6,65,0.204,0,0,28742
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Hearty Chicken with Vegetables Soup","Soups, Sauces, and Gravies",2.45,0.008,0.29,1.2,0,0,6.94,1.22,0.82,88.6,45,0.204,0,0,28743
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Hearty Vegetable with Pasta Soup","Soups, Sauces, and Gravies",1.63,0.016,0.38,1.2,0.001,0,9.39,3.67,0.82,86.7,51,0,0,0,28744
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Herb Roasted Chicken with Potatoes & Garlic Soup","Soups, Sauces, and Gravies",3.27,0.008,0.355,1.2,0,0,6.94,1.63,0.61,87.2,46,0.408,0,0,28745
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Honey Roasted Ham with Potatoes Soup","Soups, Sauces, and Gravies",3.27,0.016,0.331,1.2,0.0005,0,8.16,2.86,1.02,86.1,55,0.408,0,0,28746
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Italian Sausage & Peppers Soup","Soups, Sauces, and Gravies",2.86,0.008,0.327,1.2,0.001,0,8.16,3.27,2.04,86,62,0.612,0,0,28747
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Manhattan Clam Chowder","Soups, Sauces, and Gravies",2.04,0.016,0.339,1.2,0,0,7.76,1.63,1.43,87.9,52,0.408,0,0,28748
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, New England Clam Chowder","Soups, Sauces, and Gravies",2.86,0.008,0.363,1.2,0,0,8.16,0.41,5.31,81.1,94,0.816,0,0,28749
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Old Fashioned Potato Ham Chowder","Soups, Sauces, and Gravies",2.45,0.008,0.327,1.2,0.001,0,6.94,0.41,4.49,83.8,78,1.633,0,0,28750
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Old Fashioned Vegetable Beef Soup","Soups, Sauces, and Gravies",3.27,0.016,0.363,1.2,0,0,6.94,1.63,1.02,86.9,49,0.408,0,0,28751
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Pepper Steak Soup","Soups, Sauces, and Gravies",3.27,0.008,0.327,1.2,0.0005,0,7.35,1.63,0.61,88.1,48,0.204,0,0,28752
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Pork Roast with Carrots & Potatoes Soup","Soups, Sauces, and Gravies",3.27,0.008,0.363,1.2,0,0,6.53,1.22,1.22,85.6,50,0.612,0,0,28753
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Rigatoni & Meatballs - Fully Loaded","Soups, Sauces, and Gravies",4.49,0.033,0.327,1.2,0,0,10.2,3.27,2.86,80.3,86,1.224,0,0,28754
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Roadhouse - Beef & Bean Chili","Soups, Sauces, and Gravies",6.12,0.016,0.359,2.4,0.0005,0,10.2,2.86,3.27,78.7,95,1.429,0,0,28755
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Salisbury Steak with Mushrooms & Onions Soup","Soups, Sauces, and Gravies",2.86,0.008,0.327,0.8,0,0,7.76,2.45,1.84,85.2,57,1.02,0,0,28756
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Savory Chicken with White & Wild Rice Soup","Soups, Sauces, and Gravies",2.86,0.008,0.331,0.8,0,0,7.35,0.41,0.82,88,45,0.204,0,0,28757
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Savory Pot Roast Soup","Soups, Sauces, and Gravies",2.86,0.008,0.322,0.8,0.0005,0,8.16,1.63,0.41,87.3,49,0.204,0,0,28758
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Savory Vegetable Soup","Soups, Sauces, and Gravies",1.22,0.016,0.314,1.6,0,0,8.98,2.45,0.41,88.2,44,0.204,0,0,28759
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Sizzlin' Steak- Grilled Steak Chili with Beans","Soups, Sauces, and Gravies",6.53,0.016,0.355,2.9,0.0005,0,11.02,3.67,1.22,80,81,0.408,0,0,28760
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Slow Roasted Beef with Mushrooms Soup","Soups, Sauces, and Gravies",3.27,0.008,0.339,1.2,0.0005,0,7.35,2.04,0.61,87.1,48,0.408,0,0,28761
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Smoked Chicken with Roasted Corn Chowder","Soups, Sauces, and Gravies",4.08,0.008,0.363,2,0,0,7.76,1.22,4.08,82.2,84,0.816,0,0,28762
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Split Pea 'N' Ham Soup","Soups, Sauces, and Gravies",4.9,0.008,0.318,2,0.001,0,12.24,2.04,1.02,81,78,0.408,0,0,28763
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Steak 'N' Potato Soup","Soups, Sauces, and Gravies",3.27,0,0.376,1.2,0,0,7.35,0.41,0.82,86.5,49,0.204,0,0,28764
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Tantalizin' Turkey - Turkey Chili with Beans Soup","Soups, Sauces, and Gravies",6.12,0.024,0.359,3.3,0.001,0,11.02,3.67,1.02,80.6,78,0.408,0,0,28765
"CAMPBELL Soup Company, CAMPBELL'S CHUNKY Soups, Turkey Pot Pie - Fully Loaded","Soups, Sauces, and Gravies",4.49,0.008,0.327,1.6,0,0,8.57,1.63,3.27,82,82,0.612,0,0,28766
"CAMPBELL Soup Company, CAMPBELL'S Chicken Gravy","Soups, Sauces, and Gravies",0,0,0.441,0,0,0,5.08,1.69,5.08,88.3,68,1.695,0,0,28767
"CAMPBELL Soup Company, CAMPBELL'S Country Style Cream Gravy","Soups, Sauces, and Gravies",1.69,0,0.322,0,0,0,5.08,1.69,5.08,86.8,76,1.695,0,0,28768
"CAMPBELL Soup Company, CAMPBELL'S Country Style Sausage Gravy","Soups, Sauces, and Gravies",3.39,0,0.458,0,0,0,5.08,1.69,10.17,78.3,119,2.542,0,0,28769
"CAMPBELL Soup Company, CAMPBELL'S Fat Free Beef Gravy","Soups, Sauces, and Gravies",1.69,0,0.508,0,0,0,5.08,0,0,91.1,25,0,0,0,28770
"CAMPBELL Soup Company, CAMPBELL'S Fat Free Chicken Gravy","Soups, Sauces, and Gravies",1.69,0,0.525,0,0,0,5.08,0,0,91.7,25,0,0,0,28771
"CAMPBELL Soup Company, CAMPBELL'S Fat Free Turkey Gravy","Soups, Sauces, and Gravies",1.67,0,0.483,0,0,0,6.67,0,0,90.6,33,0,0,0,28772
"CAMPBELL Soup Company, CAMPBELL'S Golden Pork Gravy","Soups, Sauces, and Gravies",1.69,0,0.525,0,0,0,5.08,1.69,5.08,87.4,76,2.542,0,0,28773
"CAMPBELL Soup Company, CAMPBELL'S Microwavable Beef Gravy","Soups, Sauces, and Gravies",1.69,0,0.475,0,0,0,5.08,1.69,1.69,90,42,0.847,0,0,28774
"CAMPBELL Soup Company, CAMPBELL'S Microwavable Chicken Gravy","Soups, Sauces, and Gravies",0,0,0.433,0,0,0,5,1.67,5,88.5,67,1.667,0,0,28775
"CAMPBELL Soup Company, CAMPBELL'S Microwavable Turkey Gravy","Soups, Sauces, and Gravies",1.67,0,0.45,0,0,0,5,1.67,1.67,89.6,42,0.833,0,0,28776
"CAMPBELL Soup Company, CAMPBELL'S Mushroom Gravy","Soups, Sauces, and Gravies",0,0,0.475,0,0,0,5.08,1.69,1.69,91.9,34,0,0,0,28777
"CAMPBELL Soup Company, CAMPBELL'S Red and White - Microwaveable Bowls, Chicken Noodle Soup","Soups, Sauces, and Gravies",1.63,0,0.355,0.4,0,0,4.08,0,0.82,92.4,30,0.204,0,0,28778
"CAMPBELL Soup Company, CAMPBELL'S Red and White - Microwaveable Bowls, Chicken Rice Soup","Soups, Sauces, and Gravies",0.82,0.008,0.327,0.4,0,0,5.71,0.41,0.41,92,30,0.204,0,0,28779
"CAMPBELL Soup Company, CAMPBELL'S Red and White - Microwaveable Bowls, Creamy Tomato Soup","Soups, Sauces, and Gravies",1.22,0.016,0.306,1.2,0.0024,0,10.2,6.53,2.04,84.7,64,0.408,0,0,28780
"CAMPBELL Soup Company, CAMPBELL'S Red and White - Microwaveable Bowls, Tomato Soup","Soups, Sauces, and Gravies",1.22,0.008,0.322,1.2,0.0024,0,9.8,7.35,0,87.4,44,0,0,0,28781
"CAMPBELL Soup Company, CAMPBELL'S Red and White - Microwaveable Bowls, Vegetable Beef Soup","Soups, Sauces, and Gravies",2.04,0,0.359,1.2,0,0,6.12,0.82,0.2,90.6,34,0.204,0,0,28782
"CAMPBELL Soup Company, CAMPBELL'S Red and White, 25% Less Sodium Chicken Noodle Soup, condensed","Soups, Sauces, and Gravies",2.38,0,0.524,0.8,0,0.071,6.35,0.79,1.59,87.8,48,0.397,0.794,0.397,28783
"CAMPBELL Soup Company, CAMPBELL'S Red and White, 25% Less Sodium Cream of Mushroom Soup, condensed","Soups, Sauces, and Gravies",1.61,0,0.524,1.6,0,0.105,6.45,0.81,6.45,84.2,89,0.806,2.419,3.226,28784
"CAMPBELL Soup Company, CAMPBELL'S Red and White, 25% Less Sodium Tomato Soup, condensed","Soups, Sauces, and Gravies",1.61,0,0.387,0.8,0.0048,0.29,16.13,9.68,0,80.6,73,0,0,0,28785
"CAMPBELL Soup Company, CAMPBELL'S Red and White, 98% Fat Free Broccoli Cheese Soup, condensed","Soups, Sauces, and Gravies",1.61,0.032,0.387,0.8,0,0.573,9.68,2.42,1.21,86.6,56,0.806,0,0,28786
"CAMPBELL Soup Company, CAMPBELL'S Red and White, 98% Fat Free Cream of Broccoli Soup, condensed","Soups, Sauces, and Gravies",1.61,0.016,0.565,1.6,0,0,8.06,0.81,1.61,86.6,56,0.403,0,0,28787
"CAMPBELL Soup Company, CAMPBELL'S Red and White, 98% Fat Free Cream of Celery Soup, condensed","Soups, Sauces, and Gravies",0.81,0.016,0.387,1.6,0,0.484,7.26,0.81,2.42,85.9,56,0.806,0.403,1.21,28788
"CAMPBELL Soup Company, CAMPBELL'S Red and White, 98% Fat Free Cream of Chicken Soup, condensed","Soups, Sauces, and Gravies",1.61,0,0.387,0.8,0,0.444,8.06,0.81,2.02,85.9,56,0.806,0.806,0.403,28789
"CAMPBELL Soup Company, CAMPBELL'S Red and White, 98% Fat Free Cream of Mushroom Soup, condensed","Soups, Sauces, and Gravies",0.81,0.016,0.387,0.8,0,0.444,7.26,0,2.02,87.6,48,0.403,0.403,1.21,28790
"CAMPBELL Soup Company, CAMPBELL'S Red and White, BATMAN Fun Shapes Soup, condensed","Soups, Sauces, and Gravies",2.38,0,0.46,0.8,0,0,7.94,0.79,1.59,85.7,56,0.397,0,0,28791
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Bean with Bacon Soup, condensed","Soups, Sauces, and Gravies",6.25,0.047,0.672,6.3,0.0028,0.375,19.53,3.13,2.34,70.2,125,1.172,0.781,0.391,28792
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Beef Broth, condensed","Soups, Sauces, and Gravies",2.42,0,0.694,0,0,0.032,0.81,0.81,0,94.9,12,0,0,0,28793
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Beef Consomme, condensed","Soups, Sauces, and Gravies",3.23,0,0.653,0,0,0.032,0.81,0.81,0,93.5,16,0,0,0,28794
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Beef Noodle Soup, condensed","Soups, Sauces, and Gravies",3.17,0,0.651,0.8,0,0.063,6.35,0.79,1.59,85.2,56,0.397,0.397,0.794,28795
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Beef with Vegetables and Barley Soup, condensed","Soups, Sauces, and Gravies",3.97,0,0.706,2.4,0,0.119,11.9,1.59,1.19,80.6,71,0.794,0.397,0,28796
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Beefy Mushroom Soup, condensed","Soups, Sauces, and Gravies",2.38,0,0.706,0,0,0.048,4.76,0.79,1.59,88,40,0.397,0.397,0.794,28797
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Broccoli Cheese Soup, condensed","Soups, Sauces, and Gravies",1.61,0.032,0.661,0,0.001,0.234,9.68,2.42,3.63,83.1,81,1.613,2.016,0,28798
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Cheddar Cheese Soup, condensed","Soups, Sauces, and Gravies",1.61,0.032,0.524,0.8,0,0.452,8.87,1.61,4.03,83.2,81,1.613,0.806,1.21,28799
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Chicken Alphabet Soup, condensed","Soups, Sauces, and Gravies",2.38,0,0.381,0.8,0.001,0.659,9.52,0.79,1.19,84.4,56,0.397,0.397,0.397,28800
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Chicken Barley with Mushrooms Soup, condensed","Soups, Sauces, and Gravies",3.17,0,0.571,2.4,0,0,12.7,1.59,1.19,81.8,71,0.397,0,0,28801
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Chicken Broth, condensed","Soups, Sauces, and Gravies",0.81,0,0.621,0,0,0,0.81,0.81,0.81,95.9,16,0,0,0,28802
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Chicken Gumbo Soup, condensed","Soups, Sauces, and Gravies",1.59,0.016,0.69,0.8,0,0,7.94,1.59,0.79,86.4,48,0.397,0,0,28803
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Chicken NOODLEO's Soup, condensed","Soups, Sauces, and Gravies",2.38,0,0.381,0.8,0,0.429,11.9,1.59,1.98,83,71,0.794,0.794,0.397,28804
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Chicken Noodle Soup, condensed","Soups, Sauces, and Gravies",2.38,0,0.706,0.8,0,0.04,6.35,0.79,1.59,87.7,48,0.397,0.397,0,28805
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Chicken Vegetable Soup, condensed","Soups, Sauces, and Gravies",2.38,0.016,0.706,1.6,0,0.127,11.9,2.38,0.79,83.2,63,0.397,0,0,28806
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Chicken Won Ton Soup, condensed","Soups, Sauces, and Gravies",2.38,0,0.69,0,0,0.056,6.35,0.79,0.79,89.1,40,0.397,0.397,0,28807
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Chicken and Dumplings Soup, condensed","Soups, Sauces, and Gravies",2.38,0,0.603,0.8,0,0,7.94,0.79,1.98,86.4,56,0.794,0,0,28808
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Chicken and Stars Soup, condensed","Soups, Sauces, and Gravies",2.38,0,0.381,0.8,0,0.444,8.73,0.79,1.59,86,56,0.397,0.794,0.397,28809
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Chicken with Rice Soup, condensed","Soups, Sauces, and Gravies",1.59,0,0.484,0.8,0,0.341,10.32,0.79,1.19,84.8,56,0.397,0.397,0,28810
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Cream of Asparagus Soup, condensed","Soups, Sauces, and Gravies",1.61,0,0.669,2.4,0,0.056,7.26,1.61,5.65,83.9,89,1.613,0.806,3.226,28811
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Cream of Broccoli Soup, condensed","Soups, Sauces, and Gravies",1.61,0.016,0.605,0.8,0.001,0.077,9.68,2.42,4.03,84.8,73,1.21,0.806,2.016,28812
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Cream of Celery Soup, condensed","Soups, Sauces, and Gravies",0.81,0.016,0.516,2.4,0,0.46,7.26,0.81,4.84,84.4,73,0.806,2.823,1.21,28813
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Cream of Chicken Soup, condensed","Soups, Sauces, and Gravies",1.61,0,0.702,1.6,0,0.048,8.06,0.81,6.45,83.8,97,2.016,2.823,1.21,28814
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Cream of Chicken with Herbs Soup, condensed","Soups, Sauces, and Gravies",1.61,0,0.645,0,0,0,7.26,0.81,3.23,84.3,65,1.21,1.21,0.806,28815
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Cream of Mushroom Soup, condensed","Soups, Sauces, and Gravies",0.81,0,0.702,1.6,0,0.048,7.26,0.81,4.84,84,81,1.21,0,0,28816
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Cream of Mushroom with Roasted Garlic Soup, condensed","Soups, Sauces, and Gravies",1.61,0.016,0.387,1.6,0,0.484,8.06,0.81,2.02,86.2,56,0.806,0.403,0.806,28817
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Cream of Onion Soup, condensed","Soups, Sauces, and Gravies",0.81,0,0.645,2.4,0,0.048,8.06,3.23,4.84,82.2,81,1.21,1.21,2.419,28818
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Cream of Potato Soup, condensed","Soups, Sauces, and Gravies",1.61,0,0.484,1.6,0,0.548,12.1,0.81,1.61,83,73,0.806,0.403,0.403,28819
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Cream of Shrimp Soup, condensed","Soups, Sauces, and Gravies",2.42,0,0.694,0,0,0.024,6.45,0,4.84,85.8,81,1.613,0.806,2.419,28820
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Creamy Chicken Noodle Soup, condensed","Soups, Sauces, and Gravies",3.23,0.016,0.702,3.2,0,0.056,8.87,0.81,5.65,79.1,97,1.21,1.21,2.419,28821
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Creamy Chicken Verde Soup, condensed","Soups, Sauces, and Gravies",1.61,0.016,0.629,2.4,0,0,8.06,0.81,5.65,82.2,89,2.016,2.016,1.613,28822
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Curly Noodle Soup, condensed","Soups, Sauces, and Gravies",3.17,0.016,0.381,0.8,0,0,8.73,0.79,1.59,85.4,63,0.397,0,0,28823
"CAMPBELL Soup Company, CAMPBELL'S Red and White, DANNY PHANTOM shaped pasta with Chicken in Chicken Broth, condensed","Soups, Sauces, and Gravies",3.17,0,0.444,0.8,0,0,7.94,0.79,1.59,85.7,56,0.397,0,0,28824
"CAMPBELL Soup Company, CAMPBELL'S Red and White, DORA THE EXPLORER Kidshapes Soup, condensed","Soups, Sauces, and Gravies",2.38,0,0.381,0.8,0,0.381,10.32,2.38,1.59,85.7,63,0.397,0.397,0,28825
"CAMPBELL Soup Company, CAMPBELL'S Red and White, DOUBLE NOODLE in Chicken Broth Soup, condensed","Soups, Sauces, and Gravies",2.38,0,0.381,0.8,0,0.667,15.87,0.79,1.59,79.5,87,0.397,0.794,0.397,28826
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Fiesta Nacho Cheese Soup, condensed","Soups, Sauces, and Gravies",2.42,0.065,0.637,0.8,0,0.081,8.06,1.61,6.45,80.3,97,2.419,1.613,1.613,28827
"CAMPBELL Soup Company, CAMPBELL'S Red and White, French Onion Soup, condensed","Soups, Sauces, and Gravies",1.59,0.016,0.516,0.8,0,0.444,4.76,3.17,1.19,91,36,0.794,0,0.397,28828
"CAMPBELL Soup Company, CAMPBELL'S Red and White, GOLDFISH Pasta with Chicken in Chicken Broth, condensed","Soups, Sauces, and Gravies",2.38,0,0.381,0.8,0,0.429,9.52,0.79,1.59,85.7,63,0.397,0.794,0.397,28829
"CAMPBELL Soup Company, CAMPBELL'S Red and White, GOLDFISH Pasta with Meatballs in Chicken Broth Soup, condensed","Soups, Sauces, and Gravies",3.17,0,0.381,0.8,0,0.381,8.73,0.79,2.38,84,71,0.794,0.794,0.397,28830
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Golden Mushroom Soup, condensed","Soups, Sauces, and Gravies",1.61,0,0.524,0.8,0,0.548,8.06,0.81,2.82,87.2,65,0.806,0.403,1.21,28831
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Green Pea Soup, condensed","Soups, Sauces, and Gravies",7.03,0.016,0.68,3.1,0,0.305,21.88,4.69,2.34,66.8,141,0.781,0.391,0.781,28832
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Hearty Vegetable with Pasta Soup, condensed","Soups, Sauces, and Gravies",2.38,0.016,0.706,1.6,0,0,15.08,6.35,0.4,80.5,71,0,0,0,28833
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Homestyle Chicken Noodle Soup, condensed","Soups, Sauces, and Gravies",3.17,0,0.516,0.8,0,0.508,7.94,0.79,1.59,86.6,56,0.397,0.794,0.397,28834
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Italian Style Wedding Soup, condensed","Soups, Sauces, and Gravies",3.17,0.032,0.643,2.4,0,0,9.52,1.59,1.98,83.2,71,0.794,0,0,28835
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Lentil Soup, condensed","Soups, Sauces, and Gravies",6.35,0.032,0.635,4,0,0.27,19.05,1.59,0.79,71.2,111,0.397,0,0.397,28836
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Manhattan Clam Chowder, condensed","Soups, Sauces, and Gravies",1.59,0.016,0.698,1.6,0.001,0.198,9.52,1.59,0.4,86.4,48,0.397,0,0,28837
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Mega Noodle in Chicken Broth, condensed","Soups, Sauces, and Gravies",2.38,0,0.381,0.8,0,0.437,11.9,0.79,1.59,82.8,71,0.397,0.794,0.397,28838
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Minestrone Soup, condensed","Soups, Sauces, and Gravies",3.17,0.016,0.516,2.4,0,0.833,13.49,2.38,0.79,79.7,71,0.397,0,0,28839
"CAMPBELL Soup Company, CAMPBELL'S Red and White, New England Clam Chowder, condensed","Soups, Sauces, and Gravies",3.17,0.016,0.516,0.8,0,0.516,10.32,0.79,1.98,83.3,71,0.397,0,1.19,28840
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Old Fashioned Tomato Rice Soup, condensed","Soups, Sauces, and Gravies",0.79,0,0.611,0.8,0.0019,0.119,18.25,7.94,1.59,74.5,87,0.397,0,0.397,28841
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Oyster Stew, condensed","Soups, Sauces, and Gravies",2.38,0,0.722,0,0,0.02,2.38,0,4.76,89.6,63,2.381,0.794,0.794,28842
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Pepper Pot Soup, condensed","Soups, Sauces, and Gravies",3.97,0.016,0.778,0.8,0,0,7.14,0.79,3.17,83,71,1.19,0,0,28843
"CAMPBELL Soup Company, CAMPBELL'S Red and White, SHREK shaped pasta with Chicken in Chicken Broth Soup, condensed","Soups, Sauces, and Gravies",2.38,0,0.381,0.8,0,0.444,9.52,0.79,1.59,85.7,63,0.397,0.794,0.397,28844
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Scotch Broth, condensed","Soups, Sauces, and Gravies",2.42,0,0.694,1.6,0,0,8.06,0.81,0.81,86.8,48,0,0,0,28845
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Southwest Style Pepper Jack Soup, condensed","Soups, Sauces, and Gravies",1.61,0.016,0.71,3.2,0,0,10.48,2.42,4.84,80.3,89,1.613,0,0,28846
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Southwestern-Style Chicken Vegetable Soup, condensed","Soups, Sauces, and Gravies",3.97,0.032,0.659,3.2,0.001,0,16.67,2.38,0.79,76,87,0.397,0,0,28847
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Split Pea with Ham and Bacon Soup, condensed","Soups, Sauces, and Gravies",7.81,0.016,0.664,3.1,0,0.352,23.44,3.13,1.56,66,141,0.391,0.781,0.391,28848
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Tomato Bisque, condensed","Soups, Sauces, and Gravies",1.59,0.032,0.698,0.8,0.0048,0,18.25,11.9,2.78,75.4,103,1.19,0.397,0.794,28849
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Tomato Soup, condensed","Soups, Sauces, and Gravies",1.61,0,0.387,0.8,0.0048,0.556,16.13,9.68,0,80.3,73,0,0,0,28850
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Vegetable Beef Soup, condensed","Soups, Sauces, and Gravies",3.97,0.016,0.706,2.4,0.001,0.206,11.9,1.59,0.79,81.6,71,0.397,0,0,28851
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Vegetable Soup, condensed","Soups, Sauces, and Gravies",3.17,0.016,0.516,2.4,0,0.579,16.67,5.56,0.4,78.7,79,0.397,0,0,28852
"CAMPBELL Soup Company, CAMPBELL'S Red and White, Vegetarian Vegetable Soup, condensed","Soups, Sauces, and Gravies",2.38,0.016,0.381,1.6,0,0.698,14.29,4.76,0.4,81.6,71,0,0,0,28853
"CAMPBELL Soup Company, CAMPBELL'S SELECT Gold Label Soups, Blended Red Pepper Black Bean Soup","Soups, Sauces, and Gravies",1.22,0.016,0.335,1.6,0.0015,0,9.39,4.08,0.61,87.3,49,0.408,0,0,28854
"CAMPBELL Soup Company, CAMPBELL'S SELECT Gold Label Soups, Creamy Portobello Mushroom Soup","Soups, Sauces, and Gravies",1.22,0.008,0.322,0.8,0,0,5.71,0.82,1.63,90.1,42,1.224,0,0,28855
"CAMPBELL Soup Company, CAMPBELL'S SELECT Gold Label Soups, Creamy Tomato Parmesan","Soups, Sauces, and Gravies",2.04,0.041,0.363,1.6,0,0,10.2,6.12,3.67,82.6,82,2.449,0,0,28856
"CAMPBELL Soup Company, CAMPBELL'S SELECT Gold Label Soups, Golden Butternut Squash Soup","Soups, Sauces, and Gravies",0.82,0.016,0.331,1.2,0,0,7.35,3.27,0.61,89.8,37,0.408,0,0,28857
"CAMPBELL Soup Company, CAMPBELL'S SELECT Gold Label Soups, Italian Tomato & Basil Soup","Soups, Sauces, and Gravies",1.22,0.016,0.314,1.2,0.002,0,7.76,5.71,0,89.3,37,0,0,0,28858
"CAMPBELL Soup Company, CAMPBELL'S SELECT Gold Label Soups, Southwestern Corn Chowder","Soups, Sauces, and Gravies",1.22,0.008,0.253,0.8,0.0005,0.09,10.61,2.86,1.22,84.9,61,0.204,0.408,0.612,28859
"CAMPBELL Soup Company, CAMPBELL'S SELECT HARVEST Microwaveable Bowls, 98% Fat Free New England Clam Chowder","Soups, Sauces, and Gravies",2.45,0.008,0.196,0.4,0,0,6.94,0.41,1.02,88.9,45,0.408,0.204,0.408,28860
"CAMPBELL Soup Company, CAMPBELL'S SELECT Microwaveable Bowls, Chicken with Egg Noodles Soup","Soups, Sauces, and Gravies",3.27,0.008,0.196,0.4,0,0,4.9,0.82,1.63,89.8,49,0.408,0.816,0.408,28861
"CAMPBELL Soup Company, CAMPBELL'S SELECT Microwaveable Bowls, HEALTHY REQUEST Italian Wedding Soup","Soups, Sauces, and Gravies",2.45,0.016,0.167,0.8,0,0.286,7.35,1.22,1.22,87.6,49,0.408,0.408,0,28862
"CAMPBELL Soup Company, CAMPBELL'S SELECT Microwaveable Bowls, HEALTHY REQUEST Mexican Style Tortilla","Soups, Sauces, and Gravies",3.27,0.016,0.167,0.8,0,0.314,8.16,1.22,0.82,87.7,53,0.204,0.204,0.204,28863
"CAMPBELL Soup Company, CAMPBELL'S SELECT Microwaveable Bowls, Italian Sausage Pasta & Pepperoni Soup","Soups, Sauces, and Gravies",2.86,0.016,0.355,0.8,0,0,6.12,2.04,2.45,87.5,53,1.02,0,0,28864
"CAMPBELL Soup Company, CAMPBELL'S SELECT Microwaveable Bowls, Italian-Style Wedding Soup","Soups, Sauces, and Gravies",2.86,0.016,0.196,0.8,0,0,6.53,1.22,1.63,89.1,53,1.02,0,0,28865
"CAMPBELL Soup Company, CAMPBELL'S SELECT Microwaveable Bowls, Mexican Style Chicken Tortilla Soup","Soups, Sauces, and Gravies",3.27,0.024,0.196,0.8,0,0,7.76,1.22,1.02,86.9,52,0.408,0,0,28866
"CAMPBELL Soup Company, CAMPBELL'S SELECT Microwaveable Bowls, Minestrone Soup","Soups, Sauces, and Gravies",1.63,0.016,0.196,1.2,0,0,7.76,2.04,0.41,88.7,39,0.204,0,0,28867
"CAMPBELL Soup Company, CAMPBELL'S SELECT Microwaveable Bowls, Savory Chicken and Long Grain Rice Soup","Soups, Sauces, and Gravies",2.45,0.008,0.196,0.4,0,0,6.12,1.22,0.41,89.4,45,0,0,0,28868
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, 98% Fat Free New England Clam Chowder","Soups, Sauces, and Gravies",2.45,0.008,0.196,0.4,0,0,6.94,0.41,1.02,88.7,45,0.408,0,0,28869
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Beef with Roasted Barley Soup","Soups, Sauces, and Gravies",3.67,0.008,0.196,0.8,0,0,8.57,1.63,0.41,85.6,54,0.204,0,0,28870
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Chicken & Pasta with Roasted Garlic Soup","Soups, Sauces, and Gravies",3.27,0,0.335,0.8,0,0,7.35,0.82,0.41,88.1,46,0.204,0,0,28871
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Chicken Vegetable Medley Soup","Soups, Sauces, and Gravies",2.86,0.008,0.196,0.4,0,0,7.76,1.63,0.41,88,49,0,0,0,28872
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Creamy Chicken Alfredo Soup","Soups, Sauces, and Gravies",4.08,0.016,0.196,0.4,0,0,6.12,0.41,5.31,83,90,1.632,0,0,28873
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, HEALTHY REQUEST Itallian-Style Wedding Soup","Soups, Sauces, and Gravies",2.45,0.016,0.167,0.8,0,0,6.53,0.41,1.02,89.4,45,0.408,0,0,28874
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, HEALTHY REQUEST Mexican Style Chicken Tortilla","Soups, Sauces, and Gravies",3.27,0.016,0.167,0.8,0,0,8.98,0.82,0.82,87,57,0.408,0,0,28875
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Harvest Tomato with Basil Soup","Soups, Sauces, and Gravies",1.22,0,0.196,0.8,0.002,0,8.98,4.9,0,87.9,41,0,0,0,28876
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Italian Sausage with Pasta & Pepperoni Soup","Soups, Sauces, and Gravies",2.86,0.016,0.196,0.8,0,0,7.35,1.22,2.86,86.2,65,1.02,0,0,28877
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Italian-Style Wedding Soup","Soups, Sauces, and Gravies",2.86,0.016,0.196,0.4,0,0,5.31,1.22,2.04,89.2,53,1.225,0,0,28878
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Mediterranean Meatball w/Bowtie Pasta Soup","Soups, Sauces, and Gravies",2.86,0.016,0.196,0.8,0,0,6.12,1.22,1.63,88.8,49,0.612,0,0,28879
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Mexican Style Chicken Tortilla Soup","Soups, Sauces, and Gravies",2.86,0.016,0.196,0.8,0,0,6.12,1.22,0.82,88.5,45,0.408,0,0,28880
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Minestrone Soup","Soups, Sauces, and Gravies",2.04,0.016,0.196,1.2,0,0,8.16,2.04,0.2,88.3,41,0,0,0,28881
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, New England Clam Chowder","Soups, Sauces, and Gravies",2.45,0.008,0.196,0.4,0,0,6.94,0.41,4.08,86,73,0.816,0,0,28882
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Potato Broccoli Cheese Soup","Soups, Sauces, and Gravies",1.22,0.016,0.196,1.2,0.001,0,6.12,1.22,3.67,89.2,61,0.408,0,0,28883
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Roasted Chicken with Long Grain & Wild Rice Soup","Soups, Sauces, and Gravies",2.45,0.008,0.196,0.4,0,0,8.16,0.82,0.2,88.7,45,0,0,0,28884
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Roasted Chicken with Rotini & Penne Pasta Soup","Soups, Sauces, and Gravies",3.27,0.008,0.196,0.4,0,0,5.31,0.82,0.41,88.7,37,0,0,0,28885
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Savory White Bean with Roasted Ham Soup","Soups, Sauces, and Gravies",3.67,0.024,0.196,2.9,0.0005,0,12.24,1.63,0.61,83.1,69,0.204,0,0,28886
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Slow Roasted Beef and Vegetables Soup","Soups, Sauces, and Gravies",2.86,0.016,0.196,0.8,0.0005,0,6.53,1.63,0.2,89,41,0.204,0,0,28887
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Split Pea with Roasted Ham Soup","Soups, Sauces, and Gravies",3.67,0.016,0.196,2,0,0,11.84,2.04,0.41,82.8,61,0.204,0,0,28888
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Tomato Garden Soup","Soups, Sauces, and Gravies",1.22,0.016,0.196,0.8,0,0,8.57,4.08,0.2,88.6,41,0.204,0,0,28889
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Vegetable Medley Soup","Soups, Sauces, and Gravies",1.22,0.016,0.196,1.2,0,0,6.53,2.45,0.2,88.9,33,0,0,0,28890
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soup, Zesty Azteca Meatballs & Rice","Soups, Sauces, and Gravies",2.04,0.016,0.196,0.4,0,0,7.35,1.22,2.04,87.2,53,0.816,0,0,28891
"CAMPBELL Soup Company, CAMPBELL'S SELECT Soups, HEALTHY REQUEST Chicken with Egg Noodles","Soups, Sauces, and Gravies",2.86,0.008,0.196,0.8,0,0.286,5.31,0.41,1.02,89.9,41,0.408,0.408,0.204,28892
"CAMPBELL Soup Company, CAMPBELL'S SOUP AT HAND, 25% Less Sodium Chicken with Mini Noodles Soup","Soups, Sauces, and Gravies",1.31,0,0.239,0.7,0.0008,0,3.61,0.66,0.66,93.5,26,0.164,0,0,28893
"CAMPBELL Soup Company, CAMPBELL'S SOUP AT HAND, 25% Less Sodium Classic Tomato","Soups, Sauces, and Gravies",0.98,0.007,0.216,0.7,0.011800000000000001,0,8.85,4.92,0,89,39,0,0,0,28894
"CAMPBELL Soup Company, CAMPBELL'S SOUP AT HAND, Blended Vegetable Medley Soup","Soups, Sauces, and Gravies",0.98,0.007,0.292,1.3,0.009800000000000001,0,6.23,2.95,0.49,91.1,33,0.164,0,0,28895
"CAMPBELL Soup Company, CAMPBELL'S SOUP AT HAND, Chicken & Stars Soup","Soups, Sauces, and Gravies",0.98,0,0.292,0.7,0,0,3.28,0.33,0.49,94,21,0.164,0,0,28896
"CAMPBELL Soup Company, CAMPBELL'S SOUP AT HAND, Chicken with Mini Noodles Soup","Soups, Sauces, and Gravies",1.31,0,0.321,0.7,0.0008,0,3.61,0.66,0.66,93.5,26,0.164,0,0,28897
"CAMPBELL Soup Company, CAMPBELL'S SOUP AT HAND, Cream of Broccoli Soup","Soups, Sauces, and Gravies",0.98,0.007,0.292,2.3,0,0,5.57,0.98,2.3,90.2,47,0.656,0,0,28898
"CAMPBELL Soup Company, CAMPBELL'S SOUP AT HAND, Creamy Chicken Soup","Soups, Sauces, and Gravies",1.31,0,0.292,1.3,0,0,4.26,0.33,2.95,90.5,43,0.492,0,0,28899
"CAMPBELL Soup Company, CAMPBELL'S SOUP AT HAND, Creamy Tomato Soup","Soups, Sauces, and Gravies",1.31,0.02,0.308,1.3,0.0079,0,11.15,7.54,1.31,84.8,62,0.328,0,0,28900
"CAMPBELL Soup Company, CAMPBELL'S SOUP AT HAND, Italian Style Wedding Soup","Soups, Sauces, and Gravies",0.98,0.007,0.282,0.3,0.0004,0,3.28,0.98,0.82,93.4,24,0.328,0,0,28901
"CAMPBELL Soup Company, CAMPBELL'S SOUP AT HAND, New England Clam Chowder","Soups, Sauces, and Gravies",1.31,0.007,0.292,1.3,0,0,4.26,0,1.97,91.6,40,0.328,0,0,28902
"CAMPBELL Soup Company, CAMPBELL'S SOUP AT HAND, Vegetable Beef Soup","Soups, Sauces, and Gravies",0.98,0.007,0.305,0.3,0,0,3.28,1.64,0.33,94,20,0.164,0,0,28903
"CAMPBELL Soup Company, CAMPBELL'S SOUP AT HAND, Velvety Potato Soup","Soups, Sauces, and Gravies",0.66,0.007,0.285,1.3,0,0,6.89,1.64,2.3,89.3,51,0.328,0,0,28904
"CAMPBELL Soup Company, CAMPBELL'S Turkey Gravy","Soups, Sauces, and Gravies",1.69,0,0.458,0,0,0,5.08,1.69,1.69,89.8,42,0.847,0,0,28905
"CAMPBELL Soup Company, CAMPBELL's Red and White, Souper Shapes Soup - Cars, condensed","Soups, Sauces, and Gravies",3.17,0,0.381,0.8,0,0.444,8.73,0.79,1.59,85.7,63,0.397,0.794,0.397,28906
"CAMPBELL Soup Company, FRANCO-AMERICAN Fat Free Slow Roast Beef Gravy","Soups, Sauces, and Gravies",1.69,0,0.508,0,0,0,5.08,0,0,89.6,34,0,0,0,28907
"CAMPBELL Soup Company, FRANCO-AMERICAN Fat Free Slow Roast Chicken Gravy","Soups, Sauces, and Gravies",1.69,0,0.424,0,0,0,6.78,0,0,91.2,34,0,0,0,28908
"CAMPBELL Soup Company, FRANCO-AMERICAN Slow Roast Beef Gravy","Soups, Sauces, and Gravies",1.69,0,0.525,0,0,0,5.08,0,0.85,89.5,42,0,0,0,28909
"CAMPBELL Soup Company, FRANCO-AMERICAN Slow Roast Chicken Gravy","Soups, Sauces, and Gravies",1.69,0,0.407,0,0,0,5.08,0,0.85,90.5,34,0,0,0,28910
"CAMPBELL Soup Company, FRANCO-AMERICAN Slow Roast Turkey Gravy","Soups, Sauces, and Gravies",1.69,0,0.542,0,0,0,6.78,0,0.85,88.6,42,0,0,0,28911
"CAMPBELL Soup Company, HEALTHY REQUEST, Chicken Noodle Soup, condensed","Soups, Sauces, and Gravies",2.38,0,0.325,0.8,0,0.357,6.35,0.79,1.59,88.2,48,0.397,0.397,0.397,28912
"CAMPBELL Soup Company, HEALTHY REQUEST, Chicken with Rice, condensed","Soups, Sauces, and Gravies",1.59,0,0.325,0.8,0.001,0.484,7.19,0.79,1.19,88.8,56,0.397,0.397,0,28913
"CAMPBELL Soup Company, HEALTHY REQUEST, Cream of Celery Soup, condensed","Soups, Sauces, and Gravies",0.81,0.081,0.331,0.8,0,0.484,9.68,1.61,1.61,86.5,56,0.403,0.403,0.806,28914
"CAMPBELL Soup Company, HEALTHY REQUEST, Cream of Chicken Soup, condensed","Soups, Sauces, and Gravies",1.61,0,0.331,0.8,0,0.605,9.68,5.65,2.02,85.6,65,0.806,0.403,0.806,28915
"CAMPBELL Soup Company, HEALTHY REQUEST, Cream of Mushroom Soup, condensed","Soups, Sauces, and Gravies",1.61,0.081,0.331,0.8,0,0.629,8.06,1.61,1.61,87.1,56,0.403,0.403,0.806,28916
"CAMPBELL Soup Company, HEALTHY REQUEST, Homestyle Chicken Noodle Soup, condensed","Soups, Sauces, and Gravies",2.38,0,0.325,0.8,0,0.349,7.94,0.79,1.19,88.6,48,0.397,0.397,0.397,28917
"CAMPBELL Soup Company, HEALTHY REQUEST, Minestrone Soup, condensed","Soups, Sauces, and Gravies",2.38,0.032,0.325,2.4,0,0.659,11.9,3.17,0.4,83.2,63,0,0,0,28918
"CAMPBELL Soup Company, HEALTHY REQUEST, Tomato Soup, condensed","Soups, Sauces, and Gravies",1.61,0,0.331,0.8,0.0048,0.565,13.71,8.06,1.21,81.8,73,0.403,0,0.403,28919
"CAMPBELL Soup Company, HEALTHY REQUEST, Vegetable Soup, condensed","Soups, Sauces, and Gravies",3.17,0.016,0.325,2.4,0,0.683,15.87,3.97,0.79,79.1,79,0,0,0.397,28920
"CAMPBELL Soup Company, PACE, Chipotle Chunky Salsa","Soups, Sauces, and Gravies",0,0,0.719,3.1,0,0,6.25,6.25,0,88.6,25,0,0,0,28921
"CAMPBELL Soup Company, PACE, Cilantro Chunky Salsa","Soups, Sauces, and Gravies",0,0,0.844,3.1,0,0,6.25,6.25,0,88.6,25,0,0,0,28922
"CAMPBELL Soup Company, PACE, Enchilada Sauce","Soups, Sauces, and Gravies",1.67,0,0.867,1.7,0.002,0,8.33,6.67,0,87.1,40,0,0,0,28923
"CAMPBELL Soup Company, PACE, Green Taco Sauce","Soups, Sauces, and Gravies",0,0,0.625,0,0,0,6.25,6.25,0,90.7,25,0,0,0,28924
"CAMPBELL Soup Company, PACE, Lime & Garlic Chunky Salsa","Soups, Sauces, and Gravies",0,0,0.656,3.1,0.0056,0,9.38,6.25,0,86.6,38,0,0,0,28925
"CAMPBELL Soup Company, PACE, Organic Picante Sauce","Soups, Sauces, and Gravies",0,0,0.688,3.1,0,0,6.25,6.25,0,89.3,25,0,0,0,28926
"CAMPBELL Soup Company, PACE, Picante Sauce","Soups, Sauces, and Gravies",0,0,0.781,3.1,0,0,6.25,6.25,0,89.9,25,0,0,0,28927
"CAMPBELL Soup Company, PACE, Pico De Gallo","Soups, Sauces, and Gravies",0,0,0.469,0,0.0038,0,9.38,6.25,0,88,31,0,0,0,28928
"CAMPBELL Soup Company, PACE, Red Taco Sauce","Soups, Sauces, and Gravies",0,0,0.813,0,0,0,12.5,6.25,0,85.3,50,0,0,0,28929
"CAMPBELL Soup Company, PACE, Salsa Verde","Soups, Sauces, and Gravies",0,0,0.719,0,0.0038,0.203,6.25,6.25,1.56,88.1,47,0,0,0,28930
"CAMPBELL Soup Company, PACE, Tequila Lime Salsa","Soups, Sauces, and Gravies",0,0,0.594,0,0.0075,0.313,9.38,6.25,0,88.6,47,0,0,0,28931
"CAMPBELL Soup Company, PACE, Thick & Chunky Salsa","Soups, Sauces, and Gravies",0,0,0.719,3.1,0,0,6.25,6.25,0,88.7,25,0,0,0,28932
"CAMPBELL Soup Company, PACE, Triple Pepper Salsa","Soups, Sauces, and Gravies",3.13,0,0.594,3.1,0.0075,0,9.38,6.25,0,86.2,47,0,0,0,28933
"CAMPBELL Soup Company, PREGO Pasta, Chunky Garden Combination Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",1.54,0.015,0.362,2.3,0.0018,0.308,10,7.69,1.15,84.8,54,0,0,0,28934
"CAMPBELL Soup Company, PREGO Pasta, Chunky Garden Mushroom Supreme Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",1.54,0,0.354,2.3,0.0018,0.354,10,7.69,2.31,78.8,69,0.385,0.385,1.154,28935
"CAMPBELL Soup Company, PREGO Pasta, Chunky Garden Mushroom and Green Pepper Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",1.54,0.015,0.362,2.3,0.0028,0.308,10,7.69,2.31,82.5,69,0,0,0,28936
"CAMPBELL Soup Company, PREGO Pasta, Chunky Garden Tomato, Onion and Garlic Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",1.6,0.016,0.376,2.4,0.0029,0.32,10.4,8,2.4,80.9,72,0,0,0,28937
"CAMPBELL Soup Company, PREGO Pasta, Diced Onion and Garlic Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",1.54,0.031,0.369,2.3,0.0009,0.338,13.85,9.23,3.46,79.7,92,1.154,0,0,28938
"CAMPBELL Soup Company, PREGO Pasta, Flavored with Meat Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",1.54,0.015,0.369,2.3,0.0018,0.277,10,7.69,1.92,83.5,62,0.385,0,0,28939
"CAMPBELL Soup Company, PREGO Pasta, Fresh Mushroom Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",1.54,0.015,0.369,2.3,0.0018,0.269,10,8.46,1.15,84,54,0.769,0,0,28940
"CAMPBELL Soup Company, PREGO Pasta, Garlic Supreme Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",1.54,0.031,0.408,2.3,0.0009,0.315,13.08,10,3.08,80.6,85,1.154,0,0,28941
"CAMPBELL Soup Company, PREGO Pasta, Heart Smart- Ricotta Parmesan Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",2.4,0.048,0.288,2.4,0.0019,0.328,10.4,8,2,83,72,0.8,0,0,28942
"CAMPBELL Soup Company, PREGO Pasta, Heart Smart- Roasted Red Pepper and Garlic Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",1.6,0.016,0.288,2.4,0.0019,0.328,10.4,7.2,1.2,83.5,56,0,0,0,28943
"CAMPBELL Soup Company, PREGO Pasta, Heart Smart- Traditional Sauce, ready-to-serve","Soups, Sauces, and Gravies",1.54,0.015,0.277,2.3,0.0018,0.292,10,7.7,1.15,85.4,54,0,0.769,0.385,28944
"CAMPBELL Soup Company, PREGO Pasta, Italian Sausage and Garlic Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",2.4,0.016,0.384,2.4,0.001,0.312,10.4,8,2.4,82.5,72,0.8,0,0,28945
"CAMPBELL Soup Company, PREGO Pasta, Mini Meatball Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",3.08,0.015,0.369,2.3,0.0018,0.277,10,7.69,2.31,82,77,0.769,0,0,28946
"CAMPBELL Soup Company, PREGO Pasta, Mushroom and Garlic Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",1.54,0.015,0.362,2.3,0.0009,0.331,10,7.69,1.92,84,62,0,0,0,28947
"CAMPBELL Soup Company, PREGO Pasta, Mushroom and Parmesan Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",2.4,0.048,0.384,2.4,0.001,0.376,17.6,10.4,2.8,76.6,104,1.2,0,0,28948
"CAMPBELL Soup Company, PREGO Pasta, Organic Mushroom Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",1.6,0.016,0.376,3.2,0.0038,0.36,10.4,7.2,2.4,84.5,72,0.4,0,0,28949
"CAMPBELL Soup Company, PREGO Pasta, Organic Tomato and Basil Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",1.6,0.032,0.376,3.2,0.0048,0.32,10.4,7.2,2.4,83,72,0.385,0,0,28950
"CAMPBELL Soup Company, PREGO Pasta, Roasted Garlic Parmesan Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",2.31,0.031,0.369,2.3,0.0009,0.308,10,7.69,0.77,83.7,77,0.385,0,0,28951
"CAMPBELL Soup Company, PREGO Pasta, Roasted Garlic and Herb Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",1.54,0.015,0.354,2.3,0.0018,0.308,10,6.92,2.31,83.5,69,0,0,0,28952
"CAMPBELL Soup Company, PREGO Pasta, Tomato, Basil and Garlic Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",1.6,0.016,0.336,2.4,0.001,0.32,9.6,7.2,2,83.5,64,0,0,0,28953
"CAMPBELL Soup Company, PREGO Pasta, Traditional Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",1.54,0.015,0.369,2.3,0.0018,0.292,10,7.69,1.15,80,54,0,0,0,28954
"CAMPBELL Soup Company, PREGO Pasta, Zesty Mushroom Italian Sauce, ready-to-serve","Soups, Sauces, and Gravies",1.54,0.015,0.408,2.3,0,0.354,13.85,9.23,2.69,80.2,85,0.769,0,0,28955
"CAMPBELL Soup Company, SWANSON BROTH, Certified Organic Vegetable Broth","Soups, Sauces, and Gravies",0,0,0.234,0,0,0,1.28,0.85,0,98.2,5,0,0,0,28956
"CAMPBELL Soup Company, SWANSON BROTH, Vegetable Broth","Soups, Sauces, and Gravies",0,0,0.4,0,0,0,1.28,0.85,0,97.6,5,0,0,0,28957
"CAMPBELL Soup company, CAMPBELL'S Low Sodium Soups, Chicken Broth","Soups, Sauces, and Gravies",1.01,0,0.047,0,0,0.077,0.34,0.34,0.34,98.2,10,0.168,0,0,28958
"CAMPBELL Soup company, CAMPBELL'S Low Sodium Soups, Chicken with Noodles Soup","Soups, Sauces, and Gravies",3.93,0.007,0.046,0.7,0,0.161,5.57,1.31,1.48,88.8,52,0.492,0.656,0.328,28959
"CAMPBELL Soup company, CAMPBELL'S Low Sodium Soups, Cream of Mushroom Soup","Soups, Sauces, and Gravies",1.01,0.013,0.02,0,0,0.057,6.38,2.01,2.68,89.4,54,0.839,0.503,1.342,28960
Fish broth,"Soups, Sauces, and Gravies",2,0.03,0.318,0,0,0.086,0.4,0.09,0.6,96,16,0.133,0.108,0.245,28961
"Gravy, HEINZ Home Style Savory Beef Gravy","Soups, Sauces, and Gravies",1.09,0.006,0.587,0.7,0,0.022,6.24,0.52,1.13,89.97,39,0.4,0.432,0.046,28962
"Gravy, au jus, canned","Soups, Sauces, and Gravies",1.2,0.004,0.05,0,0.001,0.081,2.5,0,0.2,94.5,16,0.1,0.08,0.01,28963
"Gravy, au jus, dry","Soups, Sauces, and Gravies",9.2,0.14,11.588,0,0.001,0.279,47.49,0,9.63,2.74,313,2.026,4.683,0.23,28964
"Gravy, beef, canned, ready-to-serve","Soups, Sauces, and Gravies",3.75,0.006,0.56,0.4,0,0.081,4.81,0.21,2.36,87.48,53,1.153,0.962,0.08,28965
"Gravy, brown instant, dry","Soups, Sauces, and Gravies",8.53,0.116,5.053,3.2,0.0019,0.367,59.78,9.23,11.85,5.51,380,5.866,5.488,0.499,28966
"Gravy, brown, dry","Soups, Sauces, and Gravies",10.74,0.132,4.843,2,0.0003,0.262,59.38,0,9.61,5,367,3.319,4.452,0.405,28967
"Gravy, chicken, canned, ready-to-serve","Soups, Sauces, and Gravies",1.93,0.02,0.424,0.4,0,0.109,5.42,0.78,5.71,85.34,79,1.41,2.55,1.5,28968
"Gravy, chicken, dry","Soups, Sauces, and Gravies",11.27,0.146,4.152,0,0.0006,0.404,62.09,0,9.73,3.85,381,2.92,4.648,1.877,28969
"Gravy, instant beef, dry","Soups, Sauces, and Gravies",9.8,0.141,5.203,4.3,0.0009,0.45,61.1,23.9,9.48,4.65,369,4.88,3.659,0.938,28970
"Gravy, instant turkey, dry","Soups, Sauces, and Gravies",11.72,0.115,4.09,3.8,0.0009,0.306,57.56,7.6,14.66,5.24,409,4.931,5.25,4.478,28971
"Gravy, meat or poultry, low sodium, prepared","Soups, Sauces, and Gravies",3.8,0.006,0.018,0.3,0,0.081,6.16,0.01,2.4,87.5,53,1.003,1.068,0.095,28972
"Gravy, mushroom, canned","Soups, Sauces, and Gravies",1.26,0.007,0.57,0.4,0,0.106,5.47,0,2.71,89,50,0.4,1.17,1.02,28973
"Gravy, mushroom, dry, powder","Soups, Sauces, and Gravies",10,0.23,6.58,4.8,0.007,0.262,64.66,3.26,4,3.34,328,2.36,1.31,0.14,28974
"Gravy, onion, dry, mix","Soups, Sauces, and Gravies",9,0.28,4.186,6,0.007,0.262,67.64,0,3,4.36,322,1.86,0.9,0.14,28975
"Gravy, pork, dry, powder","Soups, Sauces, and Gravies",8.78,0.139,5.356,2.4,0.0014,0.235,63.57,24.73,8.63,4.43,367,4.29,3.881,0.454,28976
"Gravy, turkey, canned, ready-to-serve","Soups, Sauces, and Gravies",2.6,0.004,0.577,0.4,0,0.109,5.1,0.21,2.1,88.6,51,0.62,0.9,0.49,28977
"Gravy, turkey, dry","Soups, Sauces, and Gravies",10.42,0.146,4.392,0,0.0002,0.428,65.12,0,7.19,4.67,367,1.975,2.575,2.196,28978
"Gravy, unspecified type, dry","Soups, Sauces, and Gravies",13,0.15,5.73,0,0.007,0.262,58,0,8,4,344,2.86,3.15,1.62,28979
"Potato soup, instant, dry mix","Soups, Sauces, and Gravies",9.2,0.172,0.612,7.6,0.016,1.248,76.14,10,3.1,5.8,343,0.613,1.185,1.025,28980
"Sauce, alfredo mix, dry","Soups, Sauces, and Gravies",15.32,0.467,2.59,2,0,0.254,36.52,5.4,36.35,2.05,535,13.18,13.669,8.61,28981
"Sauce, barbecue","Soups, Sauces, and Gravies",0,0.012,0.847,0.6,0.0007,0.208,36.25,26.06,0.29,60.34,150,0,0.074,0.17,28982
"Sauce, barbecue, low sodium","Soups, Sauces, and Gravies",0,0.012,0.133,0.6,0.0007,0.208,36.25,26.06,0.29,60.34,150,0,0.074,0.17,28983
"Sauce, cheese sauce mix, dry","Soups, Sauces, and Gravies",7.68,0.204,3.202,1,0.0009,0.428,60.52,10.26,18.33,3.78,438,8.44,6.868,0.983,28984
"Sauce, cheese, dry, powder","Soups, Sauces, and Gravies",22.69,0.795,3.712,1.1,0.001,0.519,33.66,25.13,25.44,4.09,448,12.05,8.44,3.66,28985
"Sauce, cheese, ready-to-serve","Soups, Sauces, and Gravies",6.71,0.184,0.828,0.5,0.0004,0.03,6.83,0.42,13.29,70.5,174,6.01,3.823,2.599,28986
"Sauce, chili, peppers, hot, immature green, canned","Soups, Sauces, and Gravies",0.7,0.005,0.025,1.9,0.068,0.564,5,2.55,0.1,93.9,20,0.013,0.068,0.012,28987
"Sauce, fish, ready-to-serve","Soups, Sauces, and Gravies",5.06,0.043,7.851,0,0.0005,0.288,3.64,3.64,0.01,71.07,35,0.003,0.002,0.003,28988
"Sauce, hoisin, ready-to-serve","Soups, Sauces, and Gravies",3.31,0.032,1.615,2.8,0.0004,0.119,44.08,27.26,3.39,44.23,220,0.568,0.963,1.698,28989
"Sauce, homemade, white, medium","Soups, Sauces, and Gravies",3.84,0.118,0.354,0.2,0.0008,0.156,9.17,4.38,10.63,74.89,147,2.854,4.42,2.862,28990
"Sauce, homemade, white, thick","Soups, Sauces, and Gravies",3.99,0.111,0.373,0.3,0.0007,0.149,11.61,3.98,13.83,69.1,186,3.416,5.851,3.914,28991
"Sauce, homemade, white, thin","Soups, Sauces, and Gravies",3.77,0.126,0.328,0.1,0.0008,0.163,7.4,4.78,6.73,80.66,105,2.152,2.67,1.586,28992
"Sauce, mole poblano, dry mix, single brand","Soups, Sauces, and Gravies",7.48,0.302,1.164,10.1,0,0.602,41.7,0,41.58,4.12,571,0,0,0,28993
"Sauce, oyster, ready-to-serve","Soups, Sauces, and Gravies",1.35,0.032,2.733,0.3,0.0001,0.054,10.92,0,0.25,80,51,0.043,0.073,0.066,28994
"Sauce, pasta, spaghetti/marinara, ready-to-serve","Soups, Sauces, and Gravies",1.78,0.022,0.41,2.6,0.002,0.316,13.76,8.84,2.69,80.01,87,0.699,0.589,1.184,28995
"Sauce, pasta, spaghetti/marinara, ready-to-serve, low sodium","Soups, Sauces, and Gravies",1.78,0.022,0.03,2.6,0.002,0.316,13.76,8.84,2.69,80.01,87,0.699,0.589,1.184,28996
"Sauce, peppers, hot, chili, mature red, canned","Soups, Sauces, and Gravies",0.9,0.009,0.025,0.7,0.03,0.564,3.9,2.55,0.6,94.1,21,0.08,0.411,0.074,28997
"Sauce, pizza, canned, ready-to-serve","Soups, Sauces, and Gravies",2.18,0.054,0.185,2,0.011300000000000001,0.354,8.67,1.75,1.15,86.71,54,0.46,0.461,0.099,28998
"Sauce, plum, ready-to-serve","Soups, Sauces, and Gravies",0.89,0.012,0.538,0.7,0.0005,0.259,42.81,0,1.04,53.71,184,0.153,0.24,0.588,28999
"Sauce, ready-to-serve, pepper or hot","Soups, Sauces, and Gravies",0.51,0.008,2.643,0.3,0.07479999999999999,0.144,1.75,1.26,0.37,89.98,11,0.052,0.03,0.196,29000
"Sauce, ready-to-serve, pepper, TABASCO","Soups, Sauces, and Gravies",1.29,0.012,0.633,0.6,0.0045,0.128,0.8,0.13,0.76,95.17,12,0.106,0.061,0.401,29001
"Sauce, salsa, ready-to-serve","Soups, Sauces, and Gravies",1.54,0.027,0.6,1.6,0.0019,0.297,6.26,3.06,0.16,89.88,27,0.03,0.025,0.115,29002
"Sauce, sofrito, prepared from recipe","Soups, Sauces, and Gravies",12.8,0.02,1.145,1.7,0.020399999999999998,0.401,5.46,0,18.2,59.66,237,0,0,0,29003
"Sauce, sweet and sour, prepared-from-recipe","Soups, Sauces, and Gravies",1.84,0.016,0.347,0.4,0.005,0.193,16.72,10.81,0.58,78.56,79,0.144,0.253,0.117,29004
"Sauce, teriyaki, ready-to-serve","Soups, Sauces, and Gravies",5.93,0.025,3.833,0.1,0,0.225,15.56,14.1,0.02,67.69,89,0,0,0,29005
"Sauce, teriyaki, ready-to-serve, reduced sodium","Soups, Sauces, and Gravies",5.93,0.025,1.778,0.1,0,0.225,15.58,14.1,0.02,69.7,89,0,0,0,29006
"Sauce, tomato chili sauce, bottled, no salt, low sodium","Soups, Sauces, and Gravies",2.5,0.02,0.02,0.5,0.016,0.37,28.73,8.96,0.3,68,104,0.044,0.042,0.137,29007
"Sauce, tomato chili sauce, bottled, with salt","Soups, Sauces, and Gravies",2.5,0.02,1.338,5.9,0.016,0.37,19.79,10.54,0.3,68,104,0.045,0.038,0.154,29008
"Sauce, white, thin, prepared-from-recipe, with butter","Soups, Sauces, and Gravies",3.95,0.131,0.184,0.1,0,0.167,8.29,5.29,2.56,84.02,72,1.6,0.656,0.112,29009
"Sauce, worcestershire","Soups, Sauces, and Gravies",0,0.107,0.98,0,0.013,0.8,19.46,10.03,0,78.54,78,0,0,0,29010
"Soup, Chicken Broth, canned, ready-to-serve","Soups, Sauces, and Gravies",1,0.006,0.158,0.4,0.007,0.08,0.4,0,0.1,97.8,7,0.029,0.045,0.021,29011
"Soup, HEALTHY CHOICE Chicken Noodle Soup, canned","Soups, Sauces, and Gravies",3.69,0.008,0.195,0.8,0,0,5.2,0.57,0.63,89.73,41,0.4,0,0.18,29012
"Soup, HEALTHY CHOICE Chicken and Rice Soup, canned","Soups, Sauces, and Gravies",2.53,0.015,0.181,0.8,0.001,0,5.71,0.37,0.55,90.31,37,0.17,0.23,0.13,29013
"Soup, HEALTHY CHOICE Garden Vegetable Soup, canned","Soups, Sauces, and Gravies",2.13,0.016,0.195,1.9,0.001,0,10.04,2.13,0.21,86.82,51,0.05,0.07,0.08,29014
"Soup, SWANSON Chicken Broth 99% Fat Free","Soups, Sauces, and Gravies",0.54,0.004,0.409,0,0,0.03,0.14,0.15,0.17,98.1,4,0,0,0,29015
"Soup, bean & ham, canned, reduced sodium, prepared with water or ready-to-serve","Soups, Sauces, and Gravies",4.19,0.038,0.187,4,0.0011,0.158,13.66,3.17,1.03,79.91,74,0.252,0.415,0.269,29016
"Soup, bean with bacon, condensed, single brand","Soups, Sauces, and Gravies",6.5,0,0.736,4.6,0,0,18,0,2.1,70.9,117,0.601,0.747,0.446,29017
"Soup, bean with bacon, dry, mix, prepared with water","Soups, Sauces, and Gravies",2.07,0.021,0.35,3.4,0.0004,0.123,6.18,0.23,0.81,89.73,40,0.36,0.35,0.06,29018
"Soup, bean with frankfurters, canned, condensed","Soups, Sauces, and Gravies",7.6,0.066,0.831,4.6,0.0007,0.363,16.75,0,5.31,67.7,142,1.61,2.08,1.25,29019
"Soup, bean with frankfurters, canned, prepared with equal volume water","Soups, Sauces, and Gravies",3.99,0.035,0.437,0,0.0004,0.191,8.8,0,2.79,83.03,75,0.85,1.09,0.66,29020
"Soup, bean with ham, canned, chunky, ready-to-serve","Soups, Sauces, and Gravies",5.19,0.032,0.4,4.6,0.0018,0.175,11.16,0,3.5,78.63,95,1.37,1.58,0.39,29021
"Soup, bean with pork, canned, condensed","Soups, Sauces, and Gravies",5.88,0.06,0.709,5.9,0.0012,0.3,16.97,3,4.42,70.17,129,1.14,1.62,1.36,29022
"Soup, bean with pork, canned, prepared with equal volume water","Soups, Sauces, and Gravies",2.88,0.031,0.349,2.9,0.0006,0.147,8.31,1.47,2.16,85.35,63,0.558,0.793,0.666,29023
"Soup, beef and mushroom, low sodium, chunk style","Soups, Sauces, and Gravies",4.3,0.013,0.025,0.2,0.003,0.14,9.58,0.84,2.3,83.3,69,1.621,0.394,0.068,29024
"Soup, beef broth bouillon and consomme, canned, condensed","Soups, Sauces, and Gravies",2.52,0.007,0.653,0,0.0007,0.125,0.84,0,0,94.76,13,0,0,0,29025
"Soup, beef broth or bouillon canned, ready-to-serve","Soups, Sauces, and Gravies",1.14,0.006,0.372,0,0,0.054,0.04,0,0.22,97.55,7,0.11,0.09,0.01,29026
"Soup, beef broth or bouillon, powder, dry","Soups, Sauces, and Gravies",15.97,0.06,16.982,0,0,0.446,23.65,16.71,8.89,3.27,238,4.32,3.616,0.332,29027
"Soup, beef broth or bouillon, powder, prepared with water","Soups, Sauces, and Gravies",0.21,0.004,0.26,0,0,0.006,0.25,0.2,0.08,98.69,3,0.04,0.033,0.003,29028
"Soup, beef broth, bouillon, consomme, prepared with equal volume water","Soups, Sauces, and Gravies",2.22,0.004,0.264,0,0.0004,0.064,0.73,0,0,96.24,12,0,0,0,29029
"Soup, beef broth, cubed, dry","Soups, Sauces, and Gravies",17.3,0.06,24,0,0,0.403,16.1,14.51,4,3.3,170,1.99,1.67,0.16,29030
"Soup, beef broth, cubed, prepared with water","Soups, Sauces, and Gravies",0.21,0.004,0.26,0,0,0.006,0.25,0.2,0.08,98.69,3,0.04,0.033,0.003,29031
"Soup, beef mushroom, canned, condensed","Soups, Sauces, and Gravies",4.6,0.004,0.709,0.2,0,0.126,5.2,0,2.4,85.5,61,1.2,1,0.1,29032
"Soup, beef mushroom, canned, prepared with equal volume water","Soups, Sauces, and Gravies",2.37,0.002,0.386,0.1,0.0019,0.063,2.6,0,1.23,92.6,30,0.61,0.51,0.05,29033
"Soup, beef noodle, canned, condensed","Soups, Sauces, and Gravies",3.85,0.012,0.653,0.6,0.0003,0.079,7.16,2.06,2.46,84.42,67,0.91,0.99,0.39,29034
"Soup, beef noodle, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.93,0.008,0.381,0.3,0.0002,0.04,3.58,1.03,1.23,92.16,34,0.455,0.495,0.195,29035
"Soup, beef noodle, dry, mix","Soups, Sauces, and Gravies",17.93,0.048,8.408,2.7,0.004,0.656,48.64,5.1,6.39,4.97,324,2.07,2.71,1.33,29036
"Soup, beef stroganoff, canned, chunky style, ready-to-serve","Soups, Sauces, and Gravies",5.1,0.02,0.435,0.6,0,0.14,9,1.68,4.6,79.9,98,2.324,1.556,0.246,29037
"Soup, beef with vegetables and barley, canned, condensed, single brand","Soups, Sauces, and Gravies",3.9,0,0.707,0,0,0,8.2,0,1.4,84.6,61,0.48,0.567,0.098,29038
"Soup, black bean, canned, condensed","Soups, Sauces, and Gravies",4.83,0.035,0.97,6.8,0.0002,0.25,15.42,2.49,1.32,75.33,91,0.34,0.48,0.41,29039
"Soup, black bean, canned, prepared with equal volume water","Soups, Sauces, and Gravies",2.42,0.019,0.487,3.4,0.0001,0.125,7.71,1.24,0.66,87.62,46,0.17,0.24,0.205,29040
"Soup, bouillon cubes and granules, low sodium, dry","Soups, Sauces, and Gravies",16.7,0.187,1.067,0.2,0.0011,0.309,64.88,14.47,13.89,2.3,438,3.433,5.365,4.485,29041
"Soup, broccoli cheese, canned, condensed, commercial","Soups, Sauces, and Gravies",2.1,0.041,0.661,1.8,0.002,0.207,7.7,2.12,5.3,82.7,87,1.6,2,1.7,29042
"Soup, cheese, canned, condensed","Soups, Sauces, and Gravies",4.22,0.111,0.693,0.8,0,0.12,8.19,0.6,8.15,77.15,121,5.19,2.31,0.23,29043
"Soup, cheese, canned, prepared with equal volume milk","Soups, Sauces, and Gravies",3.77,0.115,0.406,0.4,0.0005,0.136,6.47,0,5.8,82.44,92,3.63,1.63,0.18,29044
"Soup, cheese, canned, prepared with equal volume water","Soups, Sauces, and Gravies",2.19,0.057,0.388,0.4,0,0.062,4.26,0,4.24,88.12,63,2.7,1.2,0.12,29045
"Soup, chicken broth cubes, dry","Soups, Sauces, and Gravies",14.6,0.19,24,0,0.001,0.374,23.5,0,4.7,2.5,198,1.2,1.92,1.62,29046
"Soup, chicken broth cubes, dry, prepared with water","Soups, Sauces, and Gravies",0.39,0.005,0.326,0,0,0.01,0.62,0,0.12,97.43,5,0.03,0.05,0.04,29047
"Soup, chicken broth or bouillon, dry","Soups, Sauces, and Gravies",16.66,0.187,23.875,0,0.0011,0.309,18.01,17.36,13.88,2.27,267,3.43,5.36,4.48,29048
"Soup, chicken broth or bouillon, dry, prepared with water","Soups, Sauces, and Gravies",0.28,0.006,0.401,0,0,0.006,0.3,0.29,0.23,98.28,4,0.057,0.089,0.074,29049
"Soup, chicken broth, canned, condensed","Soups, Sauces, and Gravies",4.42,0.006,0.621,0,0,0.17,0.75,0.35,1.04,91.96,31,0.31,0.47,0.22,29050
"Soup, chicken broth, canned, less/reduced sodium","Soups, Sauces, and Gravies",1.36,0.008,0.231,0,0.0004,0.085,0.38,0.22,0,97.32,7,0,0,0,29051
"Soup, chicken broth, canned, prepared with equal volume water","Soups, Sauces, and Gravies",2.02,0.004,0.306,0,0,0.086,0.38,0.29,0.57,95.95,16,0.16,0.24,0.11,29052
"Soup, chicken broth, low sodium, canned","Soups, Sauces, and Gravies",2,0.004,0.03,0,0,0.086,1.2,0.13,0.6,96,16,0.179,0.274,0.126,29053
"Soup, chicken corn chowder, chunky, ready-to-serve, single brand","Soups, Sauces, and Gravies",3.1,0,0.299,0.9,0,0,7.5,0,6.3,82.1,99,1.75,1.27,1.9,29054
"Soup, chicken gumbo, canned, condensed","Soups, Sauces, and Gravies",2.1,0.019,0.693,1.6,0.004,0.06,6.67,1.93,1.14,88.02,45,0.26,0.52,0.28,29055
"Soup, chicken gumbo, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.08,0.01,0.391,0.8,0.002,0.031,3.43,1.02,0.59,93.84,23,0.13,0.27,0.14,29056
"Soup, chicken mushroom chowder, chunky, ready-to-serve, single brand","Soups, Sauces, and Gravies",3,0,0.339,1.4,0.002,0,7.1,0,4.4,84.2,80,1.15,0.837,1.74,29057
"Soup, chicken mushroom, canned, condensed","Soups, Sauces, and Gravies",3.5,0.023,0.661,0.2,0,0.126,7.6,1.31,7.3,80.4,109,1.92,3.21,1.85,29058
"Soup, chicken mushroom, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.8,0.012,0.327,0.1,0,0.063,3.8,0,3.75,90,54,0.98,1.65,0.95,29059
"Soup, chicken noodle, canned, condensed","Soups, Sauces, and Gravies",2.59,0.009,0.706,0.3,0,0.044,6.03,0.54,1.94,87.48,52,0.536,0.855,0.54,29060
"Soup, chicken noodle, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.27,0.006,0.349,0.2,0,0.022,2.95,0.27,0.95,93.82,25,0.262,0.418,0.264,29061
"Soup, chicken noodle, dry, mix","Soups, Sauces, and Gravies",15.42,0.055,3.643,3.2,0,0.306,62.32,1.9,6.51,4.94,377,1.947,2.263,1.39,29062
"Soup, chicken noodle, dry, mix, prepared with water","Soups, Sauces, and Gravies",0.84,0.002,0.229,0.1,0,0.013,3.67,0.31,0.55,94.16,23,0.122,0.205,0.155,29063
"Soup, chicken noodle, low sodium, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.27,0.006,0.173,0.2,0,0.022,2.95,0.27,0.95,93.82,25,0.262,0.418,0.264,29064
"Soup, chicken rice, canned, chunky, ready-to-serve","Soups, Sauces, and Gravies",5.11,0.014,0.37,0.4,0.0016,0.045,5.41,0.6,1.33,86.78,53,0.4,0.6,0.28,29065
"Soup, chicken rice, dry, mix, prepared with water","Soups, Sauces, and Gravies",0.97,0.003,0.388,0.3,0,0.004,3.66,0.29,0.57,93.84,24,0.13,0.25,0.17,29066
"Soup, chicken vegetable with potato and cheese, chunky, ready-to-serve","Soups, Sauces, and Gravies",1.16,0.015,0.416,0.3,0.004,0.073,5.2,0.64,4.46,87.9,65,1.636,1.605,0.974,29067
"Soup, chicken vegetable, canned, condensed","Soups, Sauces, and Gravies",2.94,0.014,0.733,0.7,0.0008,0.126,7.01,1.26,2.32,85.52,61,0.69,1.04,0.49,29068
"Soup, chicken vegetable, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.5,0.007,0.392,0.4,0.0004,0.064,3.56,0.54,1.18,92.64,31,0.35,0.53,0.25,29069
"Soup, chicken vegetable, chunky, canned, ready-to-serve","Soups, Sauces, and Gravies",5.13,0.011,0.347,0,0.0023,0.153,7.87,0,2.01,83.44,69,0.6,0.9,0.42,29070
"Soup, chicken vegetable, chunky, reduced fat, reduced sodium, ready-to-serve, single brand","Soups, Sauces, and Gravies",2.7,0,0.192,0,0,0,6.3,0,0.5,89.5,40,0.121,0.168,0.107,29071
"Soup, chicken with dumplings, canned, condensed","Soups, Sauces, and Gravies",4.58,0.012,0.618,0.4,0,0.095,4.93,0.46,4.51,83.82,79,1.07,2.06,1.06,29072
"Soup, chicken with dumplings, canned, prepared with equal volume water","Soups, Sauces, and Gravies",2.33,0.006,0.305,0.2,0,0.048,2.51,0.33,2.29,91.78,40,0.54,1.05,0.54,29073
"Soup, chicken with rice, canned, condensed","Soups, Sauces, and Gravies",2.89,0.014,0.484,0.5,0.0001,0.082,5.84,0.16,1.56,87.84,49,0.37,0.74,0.34,29074
"Soup, chicken with rice, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.45,0.009,0.238,0.3,0.0001,0.041,2.92,0.08,0.78,93.87,24,0.185,0.37,0.17,29075
"Soup, chicken with star-shaped pasta, canned, condensed, single brand","Soups, Sauces, and Gravies",2.3,0,0.732,0,0,0,7.1,0,1.4,86.8,50,0.342,0.546,0.303,29076
"Soup, chicken, canned, chunky, ready-to-serve","Soups, Sauces, and Gravies",5.06,0.01,0.354,0.6,0.0005,0.07,6.88,0.87,2.64,84.12,71,0.79,1.18,0.55,29077
"Soup, chili beef, canned, condensed","Soups, Sauces, and Gravies",5.09,0.033,0.788,2.5,0.0031,0.4,18.86,5.09,2.53,70.86,117,1.242,1.013,0.095,29078
"Soup, chili beef, canned, prepared with equal volume water","Soups, Sauces, and Gravies",2.49,0.018,0.388,1.2,0.0015,0.196,9.23,2.49,1.24,85.68,57,0.608,0.496,0.046,29079
"Soup, chunky beef with country vegetables, ready-to-serve","Soups, Sauces, and Gravies",4.08,0,0.367,0,0,0,8.57,0,1.22,85.3,62,0.612,0.443,0.511,29080
"Soup, chunky beef, canned, ready-to-serve","Soups, Sauces, and Gravies",3.97,0.013,0.338,0.6,0.0029,0.14,10.08,0.67,1.11,83.34,66,0.554,0.465,0.047,29081
"Soup, chunky chicken noodle, canned, ready-to-serve","Soups, Sauces, and Gravies",3.27,0.008,0.343,0.4,0.0004,0.1,4,0.75,0.9,90.66,37,0.408,0.194,0.112,29082
"Soup, chunky vegetable, canned, ready-to-serve","Soups, Sauces, and Gravies",1.46,0.023,0.359,0.5,0.0025,0.165,7.92,1.83,1.54,87.58,51,0.23,0.66,0.58,29083
"Soup, clam chowder, manhattan style, canned, chunky, ready-to-serve","Soups, Sauces, and Gravies",3.02,0.028,0.417,1.2,0.0050999999999999995,0.16,7.84,1.67,1.41,86.04,56,0.88,0.41,0.05,29084
"Soup, clam chowder, manhattan, canned, condensed","Soups, Sauces, and Gravies",1.74,0.019,0.457,1.2,0.0032,0.15,9.74,2.7,1.76,84.3,61,0.305,0.305,1.027,29085
"Soup, clam chowder, manhattan, canned, prepared with equal volume water","Soups, Sauces, and Gravies",0.85,0.011,0.226,0.6,0.0016,0.074,4.77,1.32,0.86,92.26,30,0.149,0.149,0.503,29086
"Soup, clam chowder, new england, canned, condensed","Soups, Sauces, and Gravies",3.17,0.016,0.516,0.7,0.0040999999999999995,0.221,10.32,0.38,2.06,81.49,72,0.959,0,0.959,29087
"Soup, clam chowder, new england, canned, prepared with equal volume low fat (2%) milk","Soups, Sauces, and Gravies",3.24,0.07,0.273,0.3,0.0021000000000000003,0.179,7.46,2.81,2.02,85.49,61,1.094,0.268,0.513,29088
"Soup, clam chowder, new england, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.55,0.009,0.254,0.3,0.002,0.108,5.05,0.19,1.01,90.89,35,0.468,0,0.469,29089
"Soup, consomme with gelatin, dry, mix, prepared with water","Soups, Sauces, and Gravies",0.87,0.005,1.325,0,0.0001,0.023,0.78,0.45,0.08,94.75,7,0,0,0,29090
"Soup, crab, canned, ready-to-serve","Soups, Sauces, and Gravies",2.25,0.027,0.506,0.3,0,0.134,4.22,0,0.62,91.5,31,0.16,0.28,0.16,29091
"Soup, cream of asparagus, canned, condensed","Soups, Sauces, and Gravies",1.82,0.023,0.661,0.4,0.0022,0.138,8.52,0.72,3.26,84.05,69,0.82,0.75,1.46,29092
"Soup, cream of asparagus, canned, prepared with equal volume milk","Soups, Sauces, and Gravies",2.55,0.07,0.42,0.3,0.0016,0.145,6.61,0,3.3,86,65,1.34,0.84,0.9,29093
"Soup, cream of asparagus, canned, prepared with equal volume water","Soups, Sauces, and Gravies",0.94,0.012,0.402,0.2,0.0011,0.071,4.38,0,1.68,91.8,35,0.43,0.39,0.76,29094
"Soup, cream of celery, canned, condensed","Soups, Sauces, and Gravies",1.32,0.032,0.516,0.6,0.0002,0.098,7.03,1.35,4.46,84.96,72,1.12,1.03,2,29095
"Soup, cream of celery, canned, prepared with equal volume milk","Soups, Sauces, and Gravies",2.29,0.075,0.272,0.3,0.0006,0.125,5.86,0,3.91,86.46,66,1.59,0.99,1.07,29096
"Soup, cream of celery, canned, prepared with equal volume water","Soups, Sauces, and Gravies",0.68,0.016,0.254,0.3,0.0001,0.05,3.62,0,2.29,92.27,37,0.58,0.53,1.03,29097
"Soup, cream of chicken, canned, condensed","Soups, Sauces, and Gravies",2.38,0.014,0.702,0,0.0001,0.049,7.16,0.54,5.77,82.75,90,1.697,2.056,1.063,29098
"Soup, cream of chicken, canned, condensed, reduced sodium","Soups, Sauces, and Gravies",1.8,0.011,0.357,0.4,0,0.272,9.5,0.39,1.3,85.8,58,0.5,0.4,0.4,29099
"Soup, cream of chicken, canned, condensed, single brand","Soups, Sauces, and Gravies",2.4,0,0.788,0,0,0,7.7,0,6.5,81.4,99,1.61,1.64,2.05,29100
"Soup, cream of chicken, canned, prepared with equal volume milk","Soups, Sauces, and Gravies",3.01,0.073,0.362,0.1,0.0005,0.11,6.04,0,4.62,84.83,77,1.87,1.8,0.66,29101
"Soup, cream of chicken, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.41,0.014,0.347,0.1,0.0001,0.036,3.8,0,3.02,90.62,48,0.85,1.34,0.61,29102
"Soup, cream of chicken, dry, mix, prepared with water","Soups, Sauces, and Gravies",0.68,0.029,0.454,0.1,0.0002,0.082,5.11,1.59,2.04,90.94,41,1.3,0.45,0.16,29103
"Soup, cream of mushroom, canned, condensed","Soups, Sauces, and Gravies",1.61,0.012,0.702,0,0,0.062,6.72,1.46,5.89,83.85,85,1.38,1.11,1.434,29104
"Soup, cream of mushroom, canned, condensed, reduced sodium","Soups, Sauces, and Gravies",1.21,0.013,0.383,0.6,0,0.374,8.1,2.13,1.69,87.26,52,0.53,0.44,0.56,29105
"Soup, cream of mushroom, canned, prepared with equal volume low fat (2%) milk","Soups, Sauces, and Gravies",2.49,0.068,0.335,0,0.0001,0.102,5.73,3.33,3.86,86.63,67,1.317,1.087,0.771,29106
"Soup, cream of mushroom, canned, prepared with equal volume water","Soups, Sauces, and Gravies",0.79,0.007,0.318,0,0,0.03,3.29,0.72,2.88,92.04,42,0.676,0.543,0.702,29107
"Soup, cream of mushroom, low sodium, ready-to-serve, canned","Soups, Sauces, and Gravies",1,0.019,0.02,0.2,0.0004,0.041,4.53,1.72,3.7,90.3,53,1.005,0.704,1.739,29108
"Soup, cream of onion, canned, condensed","Soups, Sauces, and Gravies",2.2,0.027,0.637,0.4,0.001,0.098,10.4,3.64,4.2,81,88,1.17,1.67,1.16,29109
"Soup, cream of onion, canned, prepared with equal volume milk","Soups, Sauces, and Gravies",2.74,0.072,0.405,0.3,0.001,0.125,7.4,0,3.78,84.5,75,1.63,1.32,0.64,29110
"Soup, cream of onion, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.13,0.014,0.38,0.4,0.0005,0.049,5.2,0,2.16,90.5,44,0.6,0.86,0.6,29111
"Soup, cream of potato, canned, condensed","Soups, Sauces, and Gravies",1.39,0.016,0.484,0.4,0,0.109,9.14,1.69,1.88,85.39,59,0.97,0.44,0.33,29112
"Soup, cream of potato, canned, prepared with equal volume milk","Soups, Sauces, and Gravies",2.33,0.067,0.23,0.2,0.0005,0.13,6.92,0,2.6,86.67,60,1.52,0.7,0.23,29113
"Soup, cream of potato, canned, prepared with equal volume water","Soups, Sauces, and Gravies",0.72,0.008,0.238,0.2,0,0.056,4.7,0,0.97,92.49,30,0.5,0.23,0.17,29114
"Soup, cream of shrimp, canned, condensed","Soups, Sauces, and Gravies",2.22,0.014,0.685,0.2,0,0.047,6.53,0,4.14,84.85,72,2.58,1.19,0.15,29115
"Soup, cream of shrimp, canned, prepared with equal volume low fat (2%) milk","Soups, Sauces, and Gravies",2.78,0.069,0.354,0.1,0.0001,0.095,5.64,2.62,3.23,87.11,61,1.93,1.147,0.155,29116
"Soup, cream of shrimp, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.11,0.009,0.391,0.1,0,0.024,3.27,0.24,2.07,92.38,36,1.29,0.595,0.075,29117
"Soup, cream of vegetable, dry, powder","Soups, Sauces, and Gravies",8,0.134,4.957,3,0.0166,0.408,52.1,17.51,24.1,2.9,446,6.03,10.73,6.29,29118
"Soup, egg drop, Chinese restaurant","Soups, Sauces, and Gravies",1.16,0.007,0.37,0.4,0.0065,0.022,4.29,0.09,0.61,92.87,27,0.166,0.191,0.129,29119
"Soup, escarole, canned, ready-to-serve","Soups, Sauces, and Gravies",0.62,0.013,1.558,0,0.0018,0.107,0.72,0,0.73,96.89,11,0.22,0.33,0.15,29120
"Soup, gazpacho, canned, ready-to-serve","Soups, Sauces, and Gravies",2.9,0.01,0.303,0.2,0.0029,0.092,1.8,0.59,0.1,93.77,19,0.01,0.01,0.03,29121
"Soup, hot and sour, Chinese restaurant","Soups, Sauces, and Gravies",2.58,0.019,0.376,0.5,0,0.055,4.35,0.42,1.21,90.65,39,0.229,0.288,0.311,29122
"Soup, lentil with ham, canned, ready-to-serve","Soups, Sauces, and Gravies",3.74,0.017,0.532,0,0.0017,0.144,8.16,0,1.12,85.75,56,0.45,0.52,0.13,29123
"Soup, minestrone, canned, chunky, ready-to-serve","Soups, Sauces, and Gravies",2.13,0.025,0.288,2.4,0.002,0.255,8.64,2.17,1.17,86.72,53,0.62,0.38,0.11,29124
"Soup, minestrone, canned, condensed","Soups, Sauces, and Gravies",3.48,0.028,0.516,0.8,0.0009,0.255,9.17,1.5,2.05,82.95,68,0.44,0.56,0.91,29125
"Soup, minestrone, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.77,0.014,0.254,0.4,0.0005,0.13,4.66,0,1.04,91.33,34,0.23,0.29,0.46,29126
"Soup, minestrone, canned, reduced sodium, ready-to-serve","Soups, Sauces, and Gravies",2,0.02,0.215,2.4,0.0057,0.186,9,2.13,0.8,87.3,50,0.14,0.28,0.25,29127
"Soup, mushroom barley, canned, condensed","Soups, Sauces, and Gravies",1.5,0.01,0.573,0,0,0.076,9.6,0,1.8,84.9,61,0.35,0.8,0.57,29128
"Soup, mushroom barley, canned, prepared with equal volume water","Soups, Sauces, and Gravies",0.77,0.005,0.365,0.3,0,0.038,4.8,0,0.93,92.4,30,0.18,0.41,0.29,29129
"Soup, mushroom with beef stock, canned, condensed","Soups, Sauces, and Gravies",2.51,0.008,0.773,0.1,0.0008,0.126,7.41,2.28,3.21,84.6,68,1.24,1.12,0.62,29130
"Soup, mushroom with beef stock, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.29,0.004,0.397,0.3,0.0004,0.065,3.81,0,1.65,92.08,35,0.64,0.58,0.32,29131
"Soup, mushroom, dry, mix, prepared with water","Soups, Sauces, and Gravies",0.66,0.005,0.403,0.2,0.0004,0.038,4.4,0.18,1.82,92.36,33,0.32,0.89,0.61,29132
"Soup, onion, canned, condensed","Soups, Sauces, and Gravies",3.06,0.022,0.732,0.7,0.001,0.056,6.68,2.72,1.42,86.35,46,0.21,0.61,0.53,29133
"Soup, onion, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.5,0.012,0.423,0.3,0.0005,0.028,3.27,1.33,0.7,93.26,23,0.103,0.299,0.26,29134
"Soup, onion, dry, mix","Soups, Sauces, and Gravies",7.48,0.143,8.031,6.6,0.0034,0.721,65.07,4.65,0.34,3.79,293,0.089,0.067,0.164,29135
"Soup, onion, dry, mix, prepared with water","Soups, Sauces, and Gravies",0.32,0.009,0.346,0.3,0.0001,0.031,2.77,0.2,0.01,95.8,12,0.004,0.003,0.007,29136
"Soup, oxtail, dry, mix, prepared with water","Soups, Sauces, and Gravies",1.11,0.008,0.475,0.1,0.0001,0.013,3.54,0.98,1.01,92.96,28,0.5,0.42,0.04,29137
"Soup, oyster stew, canned, condensed","Soups, Sauces, and Gravies",1.72,0.018,0.74,0,0.0026,0.04,3.32,0,3.13,89.88,48,2.04,0.74,0.13,29138
"Soup, oyster stew, canned, prepared with equal volume milk","Soups, Sauces, and Gravies",2.51,0.068,0.425,0,0.0018,0.096,3.99,0,3.24,88.94,55,2.06,0.85,0.13,29139
"Soup, oyster stew, canned, prepared with equal volume water","Soups, Sauces, and Gravies",0.87,0.009,0.407,0,0.0013,0.02,1.69,0,1.59,94.86,24,1.04,0.38,0.07,29140
"Soup, pea, green, canned, condensed","Soups, Sauces, and Gravies",6.54,0.021,0.68,3.9,0.0013,0.145,20.18,6.52,2.23,68.54,125,1.07,0.76,0.29,29141
"Soup, pea, green, canned, prepared with equal volume milk","Soups, Sauces, and Gravies",4.97,0.068,0.352,1.1,0.0011,0.148,12.69,0,2.77,77.93,94,1.58,0.86,0.21,29142
"Soup, pea, green, canned, prepared with equal volume water","Soups, Sauces, and Gravies",3.2,0.012,0.336,1.9,0.0006,0.071,9.88,3.19,1.09,84.55,61,0.524,0.372,0.142,29143
"Soup, pea, low sodium, prepared with equal volume water","Soups, Sauces, and Gravies",3.2,0.012,0.01,1.9,0.0006,0.071,9.88,3.19,1.09,84.55,62,0.524,0.372,0.142,29144
"Soup, pea, split with ham, canned, chunky, ready-to-serve","Soups, Sauces, and Gravies",4.62,0.014,0.301,1.7,0.0029,0.127,11.17,1.91,1.66,80.95,77,0.66,0.68,0.24,29145
"Soup, pea, split with ham, canned, condensed","Soups, Sauces, and Gravies",7.68,0.016,0.63,1.7,0.0011,0.297,20.81,0,3.28,65.67,141,1.31,1.34,0.47,29146
"Soup, pea, split with ham, canned, prepared with equal volume water","Soups, Sauces, and Gravies",4.08,0.009,0.398,0.9,0.0006,0.158,11.05,0,1.74,81.77,75,0.7,0.71,0.25,29147
"Soup, pepperpot, canned, condensed","Soups, Sauces, and Gravies",5.19,0.019,0.778,0.4,0.0011,0.124,7.66,0,3.78,80.62,84,1.68,1.64,0.29,29148
"Soup, pepperpot, canned, prepared with equal volume water","Soups, Sauces, and Gravies",2.54,0.011,0.385,0.2,0.0005,0.061,3.75,0,1.85,90.46,41,0.822,0.803,0.142,29149
"Soup, potato ham chowder, chunky, ready-to-serve, single brand","Soups, Sauces, and Gravies",2.7,0,0.364,0.6,0,0,5.6,0,5.2,85.3,80,1.62,2.46,0.32,29150
"Soup, ramen noodle, any flavor, dry","Soups, Sauces, and Gravies",10.48,0.029,2.036,2.3,0.0003,0.179,63.42,1.57,15.63,4.89,436,7.553,6.53,1.457,29151
"Soup, ramen noodle, beef flavor, dry","Soups, Sauces, and Gravies",10.29,0.03,2.002,2.2,0.0006,0.185,63.27,1.78,15.71,5.52,436,7.965,6.784,1.528,29152
"Soup, ramen noodle, chicken flavor, dry","Soups, Sauces, and Gravies",10.66,0.027,2.071,2.4,0,0.173,63.57,1.35,15.55,4.26,437,7.095,6.148,1.387,29153
"Soup, ramen noodle, dry, any flavor, reduced fat, reduced sodium","Soups, Sauces, and Gravies",10.89,0.022,1.2,2.7,0.0012,0.128,70.95,0.85,2.5,12.08,350,0,0.685,1.438,29154
"Soup, scotch broth, canned, condensed","Soups, Sauces, and Gravies",4.05,0.012,0.716,1,0.0007,0.13,7.73,0,2.14,83.78,66,0.91,0.63,0.45,29155
"Soup, scotch broth, canned, prepared with equal volume water","Soups, Sauces, and Gravies",2.03,0.008,0.415,0.5,0.0004,0.065,3.87,0.26,1.07,91.84,33,0.455,0.315,0.225,29156
"Soup, shark fin, restaurant-prepared","Soups, Sauces, and Gravies",3.2,0.01,0.501,0,0.0001,0.053,3.8,0,2,90.13,46,0.501,0.583,0.341,29157
"Soup, sirloin burger with vegetables, ready-to-serve, single brand","Soups, Sauces, and Gravies",4.2,0,0.361,2.3,0,0,6.8,0,3.7,84.1,77,1.33,1.32,0.523,29158
"Soup, split pea with ham and bacon, canned, condensed, single brand","Soups, Sauces, and Gravies",8.6,0,0.729,3,0,0,21.2,0,2.3,65.4,140,0.676,0.892,0.439,29159
"Soup, split pea with ham, chunky, reduced fat, reduced sodium, ready-to-serve, single brand","Soups, Sauces, and Gravies",5.2,0,0.343,0,0.004200000000000001,0,11.3,0,1.1,81,76,0.3,0.404,0.198,29160
"Soup, stock, beef, home-prepared","Soups, Sauces, and Gravies",1.97,0.008,0.198,0,0,0.185,1.2,0.54,0.09,95.89,13,0.035,0.043,0.005,29161
"Soup, stock, chicken, home-prepared","Soups, Sauces, and Gravies",2.52,0.003,0.143,0,0.0002,0.105,3.53,1.58,1.2,92.15,36,0.321,0.582,0.213,29162
"Soup, stock, fish, home-prepared","Soups, Sauces, and Gravies",2.26,0.003,0.156,0,0.0001,0.144,0,0,0.81,96.96,17,0.203,0.236,0.138,29163
"Soup, stockpot, canned, condensed","Soups, Sauces, and Gravies",3.79,0.017,0.816,0,0.0016,0.185,8.95,0,3.04,81.89,78,0.67,0.79,1.37,29164
"Soup, stockpot, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.97,0.009,0.424,0,0.0008,0.096,4.65,0,1.58,90.58,40,0.35,0.41,0.71,29165
"Soup, tomato beef with noodle, canned, condensed","Soups, Sauces, and Gravies",3.55,0.014,0.731,1.2,0,0.176,16.87,0,3.42,74.08,112,1.27,1.38,0.54,29166
"Soup, tomato beef with noodle, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.78,0.009,0.367,0.6,0,0.088,8.44,0.73,1.71,86.99,56,0.635,0.69,0.27,29167
"Soup, tomato bisque, canned, condensed","Soups, Sauces, and Gravies",1.76,0.031,0.685,0.8,0.0046,0.325,18.47,0,1.95,75.32,96,0.42,0.53,0.87,29168
"Soup, tomato bisque, canned, prepared with equal volume milk","Soups, Sauces, and Gravies",2.51,0.074,0.442,0.2,0.0028,0.241,11.73,0,2.63,81.51,79,1.25,0.75,0.49,29169
"Soup, tomato bisque, canned, prepared with equal volume water","Soups, Sauces, and Gravies",0.92,0.016,0.424,0.2,0.0024,0.169,9.6,0,1.02,87.17,50,0.22,0.28,0.45,29170
"Soup, tomato rice, canned, condensed","Soups, Sauces, and Gravies",1.64,0.018,0.635,1.3,0.0115,0.257,17.08,5.9,2.12,77.13,93,0.4,0.47,1.05,29171
"Soup, tomato rice, canned, prepared with equal volume water","Soups, Sauces, and Gravies",0.82,0.011,0.319,0.7,0.0058,0.129,8.54,2.95,1.06,88.52,47,0.2,0.235,0.525,29172
"Soup, tomato vegetable, dry, mix","Soups, Sauces, and Gravies",11.73,0.046,6.722,3,0.0353,0.605,59.9,3.77,5.09,3.73,325,2.32,1.91,0.5,29173
"Soup, tomato vegetable, dry, mix, prepared with water","Soups, Sauces, and Gravies",0.79,0.008,0.132,0.3,0.0012,0.067,4.04,0.99,0.34,94.24,22,0.15,0.12,0.03,29174
"Soup, tomato, canned, condensed","Soups, Sauces, and Gravies",1.61,0.013,0.387,1.2,0.0129,0.229,13.41,8.11,0.56,82.5,60,0.155,0.179,0.189,29175
"Soup, tomato, canned, condensed, reduced sodium","Soups, Sauces, and Gravies",1.61,0.013,0.022,1.2,0.0129,0.229,13.41,8.11,0.56,82.5,65,0.155,0.179,0.189,29176
"Soup, tomato, canned, prepared with equal volume low fat (2%) milk","Soups, Sauces, and Gravies",2.49,0.069,0.21,0.6,0.0063,0.183,8.95,6.53,1.3,85.98,55,0.695,0.336,0.126,29177
"Soup, tomato, canned, prepared with equal volume water, commercial","Soups, Sauces, and Gravies",0.79,0.008,0.19,0.6,0.0063,0.112,6.57,3.97,0.28,91.38,30,0.076,0.088,0.093,29178
"Soup, tomato, dry, mix, prepared with water","Soups, Sauces, and Gravies",0.93,0.029,0.356,0.4,0.002,0.111,7.17,3.9,0.61,89.88,38,0.286,0.237,0.063,29179
"Soup, tomato, low sodium, with water","Soups, Sauces, and Gravies",0.79,0.008,0.024,0.6,0.0063,0.112,6.57,3.97,0.28,91.38,30,0.076,0.088,0.093,29180
"Soup, turkey noodle, canned, condensed","Soups, Sauces, and Gravies",3.11,0.009,0.65,0.6,0.0001,0.06,6.88,0.41,1.59,86.37,55,0.44,0.64,0.39,29181
"Soup, turkey noodle, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.6,0.005,0.334,0.3,0.0001,0.031,3.54,0,0.82,92.99,28,0.23,0.33,0.2,29182
"Soup, turkey vegetable, canned, condensed","Soups, Sauces, and Gravies",2.52,0.014,0.739,0.5,0,0.143,7.05,1.09,2.47,86.01,60,0.73,1.08,0.55,29183
"Soup, turkey vegetable, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.28,0.007,0.376,0.2,0,0.073,3.58,0,1.26,92.89,30,0.37,0.55,0.28,29184
"Soup, turkey, chunky, canned, ready-to-serve","Soups, Sauces, and Gravies",4.33,0.021,0.391,0,0.0027,0.153,5.96,0,1.87,86.37,57,0.52,0.75,0.46,29185
"Soup, vegetable beef, canned, condensed","Soups, Sauces, and Gravies",4.45,0.013,0.706,1.6,0.0019,0.138,8.11,0.89,1.51,83.69,63,0.68,0.64,0.09,29186
"Soup, vegetable beef, canned, condensed, single brand","Soups, Sauces, and Gravies",3.8,0,0.555,0,0,0,7.7,0,0.8,86,53,0.231,0.176,0.237,29187
"Soup, vegetable beef, canned, prepared with equal volume water","Soups, Sauces, and Gravies",2.23,0.008,0.349,0.8,0.001,0.069,4.06,0.45,0.76,91.8,31,0.34,0.32,0.045,29188
"Soup, vegetable beef, dry, mix, prepared with water","Soups, Sauces, and Gravies",1.16,0.006,0.312,0.3,0.0008,0.072,3.17,0.36,0.44,94.2,21,0.22,0.18,0.02,29189
"Soup, vegetable beef, microwavable, ready-to-serve, single brand","Soups, Sauces, and Gravies",6.2,0,0.376,1.5,0,0,3.3,0,0.7,88.6,44,0.221,0.223,0.112,29190
"Soup, vegetable chicken, canned, prepared with water, low sodium","Soups, Sauces, and Gravies",5.1,0.011,0.035,0.4,0.0023,0.153,8.76,1.42,2,83.4,69,0.597,0.895,0.418,29191
"Soup, vegetable soup, condensed, low sodium, prepared with equal volume water","Soups, Sauces, and Gravies",1.1,0.012,0.194,1.1,0.0004,0.217,6.06,2.15,0.45,91.35,33,0.074,0.102,0.202,29192
"Soup, vegetable with beef broth, canned, condensed","Soups, Sauces, and Gravies",2.42,0.014,0.515,1.3,0.0019,0.157,10.7,1.64,1.56,83.27,66,0.36,0.45,0.64,29193
"Soup, vegetable with beef broth, canned, prepared with equal volume water","Soups, Sauces, and Gravies",1.21,0.009,0.253,0.7,0.001,0.079,5.35,0.82,0.78,91.59,33,0.18,0.225,0.32,29194
"Soup, vegetable, canned, low sodium, condensed","Soups, Sauces, and Gravies",2.2,0.02,0.385,2.1,0.0008,0.433,12.11,4.31,0.9,82.79,65,0.147,0.203,0.403,29195
"Soup, vegetarian vegetable, canned, condensed","Soups, Sauces, and Gravies",1.72,0.017,0.672,0.5,0.0012,0.171,9.78,3.13,1.58,84.91,59,0.24,0.68,0.59,29196
"Soup, vegetarian vegetable, canned, prepared with equal volume water","Soups, Sauces, and Gravies",0.86,0.01,0.338,0.3,0.0006,0.086,4.89,1.57,0.79,92.41,28,0.12,0.34,0.295,29197
"Soup, wonton, Chinese restaurant","Soups, Sauces, and Gravies",2.08,0.005,0.406,0.2,0.0007,0.032,5.25,0.34,0.26,91.18,32,0.062,0.077,0.071,29198
"Split pea soup, canned, reduced sodium, prepared with water or ready-to serve","Soups, Sauces, and Gravies",3.85,0.017,0.166,1.9,0,0.183,11.83,5.06,0.92,82.4,71,0.3,0.37,0.168,29199
"Split pea with ham soup, canned, reduced sodium, prepared with water or ready-to-serve","Soups, Sauces, and Gravies",4,0.016,0.196,1.9,0.001,0.204,11.4,1.57,0.7,82.8,68,0.294,0.282,0.135,29200
"USDA Commodity, salsa","Soups, Sauces, and Gravies",1.5,0.012,0.43,1.4,0.004,0.27,7,0,0.2,89.7,36,0.029,0.016,0.107,29201
"USDA Commodity, spaghetti sauce, meatless, canned","Soups, Sauces, and Gravies",1.2,0.02,0.59,0,0.0039,0.292,8.7,4.7,0.9,87.1,48,0.162,0.21,0.51,29202
"BUTCHER BOY MEATS, INC., Turkey Franks",Sausages and Luncheon Meats,13.45,0.148,1.162,0.2,0.0008,0.189,4.66,2.49,18.17,59.06,239,5.86,5.542,5.046,29203
Bacon and beef sticks,Sausages and Luncheon Meats,29.1,0.014,1.42,0,0,0.385,0.8,0.8,44.2,21.6,517,16,21.9,4.3,29204
"Barbecue loaf, pork, beef",Sausages and Luncheon Meats,15.84,0.055,1.334,0,0,0.329,6.4,0,8.9,64.82,173,3.17,4.14,0.81,29205
"Beef sausage, fresh, cooked",Sausages and Luncheon Meats,18.21,0.011,0.652,0,0,0.258,0.35,0,27.98,51.12,332,10.905,12.639,0.663,29206
"Beef sausage, pre-cooked",Sausages and Luncheon Meats,15.5,0.015,0.91,0,0.0007,0.234,0.03,0,37.57,43.75,405,15.098,16.387,0.879,29207
"Beef, bologna, reduced sodium",Sausages and Luncheon Meats,11.7,0.012,0.682,0,0,0.155,2,0,28.4,54.8,310,11.676,13.299,1.051,29208
"Beef, cured, corned beef, canned",Sausages and Luncheon Meats,27.1,0.012,1.006,0,0,0.136,0,0,14.93,57.72,250,6.18,5.96,0.63,29209
"Beef, cured, dried",Sausages and Luncheon Meats,31.1,0.008,2.79,0,0,0.235,2.76,2.7,1.94,53.8,153,0.95,0.84,0.07,29210
"Beef, cured, luncheon meat, jellied",Sausages and Luncheon Meats,19,0.01,1.322,0,0,0.402,0,0,3.3,74.6,111,1.41,1.45,0.17,29211
"Beef, cured, pastrami",Sausages and Luncheon Meats,21.8,0.01,0.885,0,0.0003,0.21,0.36,0.1,5.82,69.53,147,2.681,2.118,0.145,29212
"Beef, cured, sausage, cooked, smoked",Sausages and Luncheon Meats,14.11,0.007,1.131,0,0,0.176,2.42,0,26.91,53.56,312,11.44,12.97,1.05,29213
"Beef, cured, smoked, chopped beef",Sausages and Luncheon Meats,20.19,0.008,1.258,0,0,0.377,1.86,0,4.42,68.93,133,1.81,1.83,0.23,29214
"Beef, cured, thin-sliced beef",Sausages and Luncheon Meats,28.11,0.011,1.439,0,0,0.429,5.71,0,3.84,58.1,176,1.65,1.68,0.2,29215
"Beerwurst, beer salami, pork",Sausages and Luncheon Meats,14.24,0.008,1.24,0,0,0.253,2.06,0,18.8,61.46,238,6.28,8.98,2.36,29216
"Beerwurst, beer salami, pork and beef",Sausages and Luncheon Meats,14,0.027,0.732,0.9,0.0006,0.244,3.76,0,22.53,56.71,277,8.438,10.105,2.08,29217
"Beerwurst, pork and beef",Sausages and Luncheon Meats,14,0.027,0.732,0.9,0.0006,0.244,4.27,0,22.53,56.71,276,8.438,10.105,2.08,29218
Blood sausage,Sausages and Luncheon Meats,14.6,0.006,0.68,0,0,0.038,1.29,1.29,34.5,47.3,379,13.4,15.9,3.46,29219
"Bockwurst, pork, veal, raw",Sausages and Luncheon Meats,14.03,0.041,0.497,1,0.0032,0.27,2.95,1.33,25.87,54.47,301,10.256,13.138,2.328,29220
"Bologna, beef",Sausages and Luncheon Meats,10.27,0.031,1.08,0,0.0152,0.172,3.98,0,28.19,54.26,311,11.134,12.218,0.776,29221
"Bologna, beef and pork",Sausages and Luncheon Meats,15.2,0.085,0.736,0,0.0008,0.315,5.49,4.42,24.59,51.87,308,9.301,10.526,1.12,29222
"Bologna, beef and pork, low fat",Sausages and Luncheon Meats,11.5,0.011,1.108,0,0,0.156,2.6,0,19.3,63.4,230,7.308,9.145,1.639,29223
"Bologna, beef, low fat",Sausages and Luncheon Meats,11.8,0.009,1.18,0,0.001,0.147,5.2,0,14.8,65,204,5.464,6.455,0.51,29224
"Bologna, chicken, pork",Sausages and Luncheon Meats,10.31,0.196,1.24,0,0.0132,0.162,4.19,0,30.61,50.85,336,9.863,13.172,4.348,29225
"Bologna, chicken, pork, beef",Sausages and Luncheon Meats,11.33,0.092,1.12,0,0.0158,0.313,5.61,0,22.73,56.42,272,6.997,9.698,3.475,29226
"Bologna, chicken, turkey, pork",Sausages and Luncheon Meats,9.88,0.082,0.922,0,0.0197,0.152,5.65,0,26.18,55.25,298,7.99,10.555,4.672,29227
"Bologna, pork",Sausages and Luncheon Meats,15.3,0.011,1.184,0,0,0.281,0.73,0,19.87,60.6,247,6.839,9.732,2.107,29228
"Bologna, pork and turkey, lite",Sausages and Luncheon Meats,13.06,0.048,0.716,0,0,0.138,3.45,0,16.06,64.87,211,5.46,7.548,3.051,29229
"Bologna, pork, turkey and beef",Sausages and Luncheon Meats,11.56,0.031,1.055,0,0.011,0.222,6.66,1.35,29.25,45.37,336,11.7,12.789,2.512,29230
"Bologna, turkey",Sausages and Luncheon Meats,11.42,0.123,1.252,0.5,0.013300000000000001,0.135,4.68,2.9,16.05,64.55,209,4.355,6.836,3.854,29231
"Bratwurst, beef and pork, smoked",Sausages and Luncheon Meats,12.2,0.007,0.848,0,0,0.283,2,0,26.34,56.65,297,6.08,8,1.58,29232
"Bratwurst, chicken, cooked",Sausages and Luncheon Meats,19.44,0.011,0.072,0,0.002,0.211,0,0,10.35,70.28,176,0,0,0,29233
"Bratwurst, pork, beef and turkey, lite, smoked",Sausages and Luncheon Meats,14.45,0.014,0.982,0,0,0.246,1.62,1.57,13.53,65.89,186,0,7.165,0.846,29234
"Bratwurst, pork, cooked",Sausages and Luncheon Meats,13.72,0.028,0.846,0,0,0.348,2.85,0,29.18,51.45,333,9.998,14.692,2.632,29235
"Bratwurst, veal, cooked",Sausages and Luncheon Meats,13.99,0.011,0.06,0,0,0.231,0,0,31.7,53.51,341,14.94,13,1.614,29236
"Braunschweiger (a liver sausage), pork",Sausages and Luncheon Meats,14.5,0.009,1.16,0,0,0.199,3.1,0,28.5,50.6,327,9.307,12.731,3.193,29237
"Brotwurst, pork, beef, link",Sausages and Luncheon Meats,14.3,0.048,1.112,0,0,0.281,2.93,2.98,27.8,51.3,323,9.93,13.34,2.83,29238
"CARL BUDDIG, Cooked Corned Beef, Chopped, Pressed",Sausages and Luncheon Meats,19.3,0.017,1.342,0,0,0.352,1,0,6.8,69.1,142,2.8,0,0.3,29239
"CARL BUDDIG, Smoked Sliced Beef",Sausages and Luncheon Meats,19.3,0.014,1.431,0,0,0.336,0.6,0,6.5,69.4,139,2.6,0,0.3,29240
"CARL BUDDIG, Smoked Sliced Chicken, light and dark meat",Sausages and Luncheon Meats,17.9,0.124,0.954,0,0,0.256,0.7,0,10.1,68.2,165,2.6,0,2.1,29241
"CARL BUDDIG, Smoked Sliced Ham",Sausages and Luncheon Meats,18.5,0.016,1.381,0,0,0.34,1.1,0,9.3,67,163,3.1,0,1.1,29242
"CARL BUDDIG, Smoked Sliced Turkey, light and dark meat",Sausages and Luncheon Meats,17.5,0.06,1.096,0,0,0.329,1.8,0,9.1,68.2,160,3.1,0,2.7,29243
"CARL BUDDIG. Cooked Smoked Beef Pastrami, Chopped, Pressed",Sausages and Luncheon Meats,19.6,0.017,1.056,0,0,0.365,1,0,6.5,69.8,141,3,0,0.3,29244
"Cheesefurter, cheese smokie, pork, beef",Sausages and Luncheon Meats,14.1,0.058,1.082,0,0,0.206,1.37,1.51,29,52.5,328,10.47,13.64,3.02,29245
"Chicken breast, fat-free, mesquite flavor, sliced",Sausages and Luncheon Meats,16.8,0.004,1.04,0,0,0.316,2.25,0.2,0.39,76.73,80,0.13,0.16,0.06,29246
"Chicken breast, oven-roasted, fat-free, sliced",Sausages and Luncheon Meats,16.79,0.006,1.087,0,0,0.067,2.17,0.1,0.39,76.71,79,0.13,0.121,0.073,29247
"Chicken roll, light meat",Sausages and Luncheon Meats,16.64,0.016,1.059,0,0,0.439,4.66,0.57,2.73,72.41,110,0.621,0.89,0.374,29248
Chicken spread,Sausages and Luncheon Meats,18.01,0.016,0.722,0.3,0,0.106,4.05,0.47,17.56,57.41,158,3.23,4.8,2.35,29249
"Chorizo, pork and beef",Sausages and Luncheon Meats,24.1,0.008,1.235,0,0,0.398,1.86,0,38.27,31.85,455,14.38,18.4,3.46,29250
"Corned beef loaf, jellied",Sausages and Luncheon Meats,22.9,0.011,0.953,0,0,0.101,0,0,6.1,69.1,153,2.6,2.68,0.32,29251
"Dutch brand loaf, chicken, pork and beef",Sausages and Luncheon Meats,12,0.007,1.055,0.3,0.0014,0.21,3.93,0.9,22.91,58.1,273,9.043,11.433,3.053,29252
"Frankfurter, beef",Sausages and Luncheon Meats,11.24,0.014,1.14,0,0,0.156,4.06,3.39,29.57,52.01,330,11.688,14.305,1.182,29253
"Frankfurter, beef and pork",Sausages and Luncheon Meats,11.53,0.011,1.12,0,0,0.167,1.72,0,27.64,55.96,305,10.77,13.67,2.73,29254
"Frankfurter, beef and pork, low fat",Sausages and Luncheon Meats,11,0.01,1.257,0,0,0.15,4.4,0,10,71.3,152,3.692,4.69,0.937,29255
"Frankfurter, beef, heated",Sausages and Luncheon Meats,11.54,0.012,1.154,0,0,0.147,3.77,3.39,29.46,52.18,326,11.436,14.321,1.064,29256
"Frankfurter, beef, low fat",Sausages and Luncheon Meats,12,0.008,1.041,0,0.001,0.129,1.6,0,19.5,63.8,230,8.1,9.8,0.6,29257
"Frankfurter, beef, pork, and turkey, fat free",Sausages and Luncheon Meats,12.5,0.054,0.798,0,0.024,0.22,11.21,0,1.59,71.5,109,0.5,0.74,0.35,29258
"Frankfurter, chicken",Sausages and Luncheon Meats,15.51,0.074,0.844,0.4,0,0.202,2.74,0.53,16.19,62.5,223,3.856,5.932,3.873,29259
"Frankfurter, low sodium",Sausages and Luncheon Meats,12,0.02,0.311,0,0,0.166,1.8,0,28.51,56.7,312,12.038,13.606,1.379,29260
"Frankfurter, meat",Sausages and Luncheon Meats,10.26,0.099,1.09,0,0,0.152,4.17,0,25.76,56.31,290,0,0,0,29261
"Frankfurter, meat and poultry, low fat",Sausages and Luncheon Meats,15.5,0.012,0.983,0.1,0.0004,0.261,8.4,0,2.8,69.9,121,0.97,1.19,0.36,29262
"Frankfurter, meat, heated",Sausages and Luncheon Meats,9.77,0.099,1.013,0,0,0.141,4.9,0,24.31,57.82,278,0,0,0,29263
"Frankfurter, pork",Sausages and Luncheon Meats,12.81,0.267,0.816,0.1,0.002,0.264,0.28,0,23.68,59.85,269,8.719,10.89,2.233,29264
"Frankfurter, turkey",Sausages and Luncheon Meats,12.23,0.148,1.078,0,0,0.392,3.81,1.21,17.29,62.99,223,4.028,5.736,3.949,29265
HORMEL Pillow Pak Sliced Turkey Pepperoni,Sausages and Luncheon Meats,30.99,0.026,1.858,0,0,0.449,3.78,0,11.52,47.84,243,3.7,4.95,3.5,29266
"HORMEL SPAM, Lite Luncheon Meat, Pork and chicken, minced, canned, ascorbic acid added",Sausages and Luncheon Meats,15.23,0.039,1.032,0,0.0385,0.461,1.35,1.26,13.9,65.72,191,4.49,7.02,1.47,29267
"HORMEL SPAM, Luncheon Meat, pork with ham, minced, canned",Sausages and Luncheon Meats,13.24,0.014,1.369,0,0.0009,0.229,3.03,0,27.24,52.6,310,9.88,13.78,2.95,29268
HORMEL WRANGLER Beef Franks,Sausages and Luncheon Meats,12.58,0.014,0.994,0,0.019899999999999998,0.172,2.11,1.67,25.69,56.67,290,10.62,13.01,0.89,29269
Ham and cheese loaf or roll,Sausages and Luncheon Meats,13.6,0.058,1.08,0,0,0.294,4,0,18.7,60.3,241,6.966,8.59,2.022,29270
Ham and cheese spread,Sausages and Luncheon Meats,16.18,0.217,1.197,0,0,0.162,2.28,0,18.53,59.13,245,8.62,7.08,1.38,29271
Ham salad spread,Sausages and Luncheon Meats,8.68,0.008,0.912,0,0,0.15,10.64,0,15.53,62.6,216,5.049,7.171,2.689,29272
"Ham, chopped, canned",Sausages and Luncheon Meats,16.06,0.007,1.365,0,0.002,0.284,0.26,0,18.83,60.77,239,6.291,9.161,2.06,29273
"Ham, chopped, not canned",Sausages and Luncheon Meats,16.5,0.007,1.33,0,0,0.319,4.2,0,10.3,65.3,180,3.422,4.897,1.26,29274
"Ham, honey, smoked, cooked",Sausages and Luncheon Meats,17.93,0.006,0.9,0,0.0006,0.165,7.27,0,2.37,69.94,122,1.167,1.67,0.314,29275
"Ham, minced",Sausages and Luncheon Meats,16.28,0.01,1.245,0,0,0.311,1.84,0,20.68,57.35,263,7.181,9.581,2.47,29276
"Ham, sliced, extra lean",Sausages and Luncheon Meats,18.85,0.006,1.06,0,0,0.649,0.75,0,2.59,73.9,107,0.66,0.992,0.214,29277
"Ham, sliced, regular (approximately 11% fat)",Sausages and Luncheon Meats,16.6,0.024,1.304,1.3,0.004,0.287,3.83,0,8.6,67.27,163,2.936,4.354,0.789,29278
"Headcheese, pork",Sausages and Luncheon Meats,13.83,0.016,0.83,0,0,0.031,0,0,10.9,73.84,157,3.402,5.601,1.134,29279
"Honey loaf, pork, beef",Sausages and Luncheon Meats,11.12,0.017,1.32,0.8,0,0.343,9.98,0.17,4.47,70.47,125,1.449,2.024,0.472,29280
"Honey roll sausage, beef",Sausages and Luncheon Meats,18.58,0.009,1.322,0,0,0.291,2.18,0,10.5,64.78,182,4.08,4.82,0.49,29281
"Kielbasa, Polish, turkey and beef, smoked",Sausages and Luncheon Meats,13.1,0,1.2,0,0.0148,0,3.9,0,17.6,62,226,6.23,8.27,2.33,29282
"Kielbasa, kolbassy, pork, beef, nonfat dry milk added",Sausages and Luncheon Meats,12.23,0.017,0.904,0,0,0.3,2.81,1.6,27.29,54.58,309,9.233,11.8,3.884,29283
"Knackwurst, knockwurst, pork, beef",Sausages and Luncheon Meats,11.1,0.011,0.93,0,0,0.199,3.2,0,27.7,55.3,307,10.21,12.81,2.92,29284
"LOUIS RICH, Chicken (white, oven roasted)",Sausages and Luncheon Meats,17,0.017,1.196,0,0,0.302,2.3,1,5.6,71.5,128,1.452,2.336,0.973,29285
"LOUIS RICH, Chicken Breast (oven roasted deluxe)",Sausages and Luncheon Meats,18.3,0.007,1.188,0,0,0.265,2.5,1.5,2,73.7,101,0.541,0.821,0.317,29286
"LOUIS RICH, Chicken Breast Classic Baked /Grill (carving board)",Sausages and Luncheon Meats,19.7,0.008,1.142,0,0,0.291,3.7,0.7,0.5,72.9,98,0.162,0.177,0.081,29287
"LOUIS RICH, Franks (turkey and chicken cheese)",Sausages and Luncheon Meats,12.7,0.243,1.07,0,0,0.158,5.1,1.7,14.5,63.9,201,5.19,6.22,2.98,29288
"LOUIS RICH, Franks (turkey and chicken)",Sausages and Luncheon Meats,11.2,0.131,1.136,0,0,0.16,5.3,1.5,13.5,66.9,188,3.839,5.553,3.163,29289
"LOUIS RICH, Turkey (honey roasted, fat free)",Sausages and Luncheon Meats,19.3,0.015,1.18,0,0,0.262,4.5,3.9,0.8,72,102,0.234,0.239,0.143,29290
"LOUIS RICH, Turkey Bacon",Sausages and Luncheon Meats,15.1,0.04,1.213,0,0,0.208,1.7,1.8,20.3,59,250,5.27,7.51,4.7,29291
"LOUIS RICH, Turkey Bologna",Sausages and Luncheon Meats,11.3,0.124,1.077,0,0,0.152,4.85,1.2,13.2,67.55,184,3.79,5.31,3.59,29292
"LOUIS RICH, Turkey Breast (Smoked, Carving Board)",Sausages and Luncheon Meats,19.7,0.015,1.201,0,0,0.312,1.6,0.3,1,74.3,94,0.3,0.33,0.19,29293
"LOUIS RICH, Turkey Breast (oven roasted, fat free)",Sausages and Luncheon Meats,15,0.011,1.192,0,0,0.205,4.5,1.7,0.7,76.5,84,0.205,0.198,0.136,29294
"LOUIS RICH, Turkey Breast (oven roasted, portion fat free)",Sausages and Luncheon Meats,19.1,0.017,1.177,0,0,0.268,1.9,0.4,0.7,74.8,90,0.207,0.199,0.133,29295
"LOUIS RICH, Turkey Breast (smoked, portion fat free)",Sausages and Luncheon Meats,19.3,0.016,1.288,0,0,0.288,2.2,0.6,0.8,74,93,0.234,0.219,0.163,29296
"LOUIS RICH, Turkey Breast and White Turkey (oven roasted)",Sausages and Luncheon Meats,17.1,0.006,0.966,0,0,0.222,3.25,0.8,2,74.75,99,0.468,0.585,0.41,29297
"LOUIS RICH, Turkey Breast and White Turkey (smoked sliced)",Sausages and Luncheon Meats,17.6,0.009,0.919,0,0,0.262,2.4,1.1,2.3,74.8,100,0.63,0.74,0.51,29298
"LOUIS RICH, Turkey Ham (10% water added)",Sausages and Luncheon Meats,18.2,0.005,1.127,0,0,0.29,0.9,0.9,4.1,73.1,113,1.06,1.14,0.7,29299
"LOUIS RICH, Turkey Nuggets/Sticks (breaded)",Sausages and Luncheon Meats,14.3,0.009,0.679,0.5,0,0.176,15.4,0,17.5,50.7,276,3.39,8.19,5.48,29300
"LOUIS RICH, Turkey Salami",Sausages and Luncheon Meats,15.3,0.04,1.004,0,0,0.216,0.4,0.4,9.4,71.9,147,2.783,3.146,2.342,29301
"LOUIS RICH, Turkey Salami Cotto",Sausages and Luncheon Meats,15,0.031,1.018,0,0,0.22,0.9,0.4,9.5,71.4,149,2.9,3.92,2.33,29302
"LOUIS RICH, Turkey Smoked Sausage",Sausages and Luncheon Meats,14.8,0.026,0.946,0,0,0.201,3.15,2.8,9.9,69.1,161,2.67,3.56,2.68,29303
"Lebanon bologna, beef",Sausages and Luncheon Meats,19.03,0.02,1.374,0,0.0009,0.33,0.44,0,10.44,65.77,172,2.794,4.436,0.258,29304
"Liver cheese, pork",Sausages and Luncheon Meats,15.2,0.008,1.225,0,0.003,0.226,2.1,0,25.6,53.6,304,8.96,12.26,3.42,29305
"Liver sausage, liverwurst, pork",Sausages and Luncheon Meats,14.1,0.026,0.86,0,0,0.17,2.2,0,28.5,52.1,326,10.6,13.34,2.6,29306
Liverwurst spread,Sausages and Luncheon Meats,12.38,0.022,0.7,2.5,0.0035,0.17,5.89,1.65,25.45,53.53,305,9.925,12.286,2.427,29307
"Luncheon meat, beef, loaved",Sausages and Luncheon Meats,14.4,0.011,1.329,0,0,0.208,2.9,0,26.2,52.5,308,11.214,12.288,0.863,29308
"Luncheon meat, beef, thin sliced",Sausages and Luncheon Meats,17.94,0.006,1.117,0,0,0.308,2.59,0,3.4,72.53,117,1.101,1.289,0.089,29309
"Luncheon meat, pork and chicken, minced, canned, includes SPAM Lite",Sausages and Luncheon Meats,15.23,0.039,1.032,0,0.0385,0.461,1.35,1.26,13.9,65.72,196,4.514,7.02,1.47,29310
"Luncheon meat, pork with ham, minced, canned, includes SPAM (Hormel)",Sausages and Luncheon Meats,13.4,0,1.33,0,0,0.409,4.6,0,26.6,51.7,315,9.987,13.505,2.019,29311
"Luncheon meat, pork, beef",Sausages and Luncheon Meats,12.59,0.009,1.293,0,0,0.202,2.33,0,32.16,49.28,353,11.59,15.09,3.74,29312
"Luncheon meat, pork, canned",Sausages and Luncheon Meats,12.5,0.006,1.289,0,0.001,0.215,2.1,0,30.3,51.5,334,10.79,14.29,3.56,29313
"Luncheon meat, pork, ham, and chicken, minced, canned, reduced sodium, added ascorbic acid, includes SPAM, 25% less sodium",Sausages and Luncheon Meats,12.5,0,0.946,0,0.043,0.564,3.4,0,25.1,55.5,293,8.873,12.34,2.666,29314
"Luncheon sausage, pork and beef",Sausages and Luncheon Meats,15.38,0.013,1.182,0,0,0.245,1.58,0,20.9,58.62,260,7.62,9.93,2.05,29315
"Luxury loaf, pork",Sausages and Luncheon Meats,18.4,0.036,1.225,0,0,0.377,4.9,0,4.8,68.2,141,1.58,2.33,0.51,29316
"Macaroni and cheese loaf, chicken, pork and beef",Sausages and Luncheon Meats,11.76,0.122,0.003,0,0.0167,0.313,11.63,0,14.96,57.63,228,5.614,7.614,1.657,29317
"Mortadella, beef, pork",Sausages and Luncheon Meats,16.37,0.018,1.246,0,0,0.163,3.05,0,25.39,52.3,311,9.51,11.38,3.12,29318
"Mother's loaf, pork",Sausages and Luncheon Meats,12.07,0.043,1.127,0,0.001,0.225,7.53,0,22.3,54.9,282,7.95,10.34,2.58,29319
"New england brand sausage, pork, beef",Sausages and Luncheon Meats,17.27,0.007,1.22,0,0,0.321,4.83,0,7.58,66.77,161,2.56,3.64,0.72,29320
"OSCAR MAYER, Bologna (beef light)",Sausages and Luncheon Meats,11.75,0.013,1.151,0,0,0.156,5.6,2.3,14.5,65,200,5.82,7.16,0.47,29321
"OSCAR MAYER, Bologna (beef)",Sausages and Luncheon Meats,11.05,0.012,1.179,0,0,0.168,2.45,1.4,29.1,54.15,316,12.85,15.21,1.11,29322
"OSCAR MAYER, Bologna (chicken, pork, beef)",Sausages and Luncheon Meats,10.9,0.069,1.033,0,0,0.154,2.4,1.4,29.4,53.7,318,10.49,14.48,3.93,29323
"OSCAR MAYER, Bologna (fat free)",Sausages and Luncheon Meats,12.6,0.015,0.977,0,0,0.156,6,2.2,0.6,78,79,0.235,0.208,0.127,29324
"OSCAR MAYER, Bologna Light (pork, chicken, beef)",Sausages and Luncheon Meats,11.5,0.05,1.117,0,0,0.163,5.7,2.6,14.75,64.75,202,5.62,7.3,1.54,29325
"OSCAR MAYER, Braunschweiger Liver Sausage (saren tube)",Sausages and Luncheon Meats,14.2,0.009,1.118,0.1,0.009,0.184,2.3,0.7,30.6,49.6,341,10.89,15.57,3.76,29326
"OSCAR MAYER, Braunschweiger Liver Sausage (sliced)",Sausages and Luncheon Meats,14.25,0.009,1.159,0.2,0.009,0.202,2.6,1.2,29.35,50.5,331,10.94,15.48,3.73,29327
"OSCAR MAYER, Chicken Breast (honey glazed)",Sausages and Luncheon Meats,19.85,0.01,1.438,0,0,0.329,3.95,4.3,1.5,70.4,109,0.394,0.564,0.218,29328
"OSCAR MAYER, Chicken Breast (oven roasted, fat free)",Sausages and Luncheon Meats,18.3,0.012,1.242,0,0,0.316,1.7,0.9,0.6,75.8,85,0.17,0.16,0.06,29329
"OSCAR MAYER, Ham (40% ham/water product, smoked, fat free)",Sausages and Luncheon Meats,14.6,0.011,1.084,0,0,0.234,1.9,1.1,0.7,79.2,72,0.221,0.272,0.136,29330
"OSCAR MAYER, Ham (chopped with natural juice)",Sausages and Luncheon Meats,16.3,0.009,1.249,0,0,0.26,3.65,2.2,11.15,65.25,180,4.056,5.829,1.275,29331
"OSCAR MAYER, Ham (water added, baked cooked 96% fat free)",Sausages and Luncheon Meats,16.3,0.01,1.242,0,0,0.268,1.83,1,3.52,74.45,104,0.851,1.165,1.314,29332
"OSCAR MAYER, Ham (water added, boiled)",Sausages and Luncheon Meats,16.6,0.01,1.348,0,0,0.283,1.2,0.3,3.7,74.6,104,1.247,1.727,0.356,29333
"OSCAR MAYER, Ham (water added, honey)",Sausages and Luncheon Meats,16.7,0.01,1.248,0,0,0.281,3.1,3.1,3.5,72.7,111,1.167,1.669,0.314,29334
"OSCAR MAYER, Ham (water added, smoked, cooked)",Sausages and Luncheon Meats,16.6,0.01,1.214,0,0,0.268,0.1,0.1,3.6,75.8,99,1.222,1.673,0.345,29335
"OSCAR MAYER, Ham and Cheese Loaf",Sausages and Luncheon Meats,13.85,0.067,1.167,0,0,0.265,3.75,3.3,18.2,60.5,234,6.561,8.278,1.798,29336
"OSCAR MAYER, Head Cheese",Sausages and Luncheon Meats,15.7,0.021,1.073,0,0,0.029,0,0,13.5,67.5,185,4.299,6.913,1.344,29337
"OSCAR MAYER, Liver Cheese, pork fat wrapped",Sausages and Luncheon Meats,15.2,0.009,1.105,0,0.0027,0.214,2.6,1.3,26.4,52.2,313,9.3,13.1,4.1,29338
"OSCAR MAYER, Luncheon Loaf (spiced)",Sausages and Luncheon Meats,13.5,0.109,1.226,0,0,0.27,7,4.6,16.9,58.6,234,5.367,7.393,2.789,29339
"OSCAR MAYER, Old Fashioned Loaf",Sausages and Luncheon Meats,13.1,0.113,1.184,0,0,0.294,8,4.6,16.3,58.9,231,5.6,7.86,2.64,29340
"OSCAR MAYER, Olive Loaf (chicken, pork, turkey)",Sausages and Luncheon Meats,9.9,0.111,1.318,0,0,0.186,6.9,3.2,21.8,57.5,263,7.02,11.13,2.63,29341
"OSCAR MAYER, Pickle Pimiento Loaf (with chicken)",Sausages and Luncheon Meats,9.6,0.11,1.275,0,0,0.174,9.1,6.9,21.6,55.8,269,6.99,10.43,2.81,29342
"OSCAR MAYER, Pork Sausage Links (cooked)",Sausages and Luncheon Meats,16.3,0.016,0.836,0,0,0.238,1,0.9,30.5,49.5,343,10.69,14.8,3.68,29343
"OSCAR MAYER, Salami (Genoa)",Sausages and Luncheon Meats,20.7,0.02,1.826,0,0,0.334,1.2,0.2,33.3,39.1,388,12.04,16.75,2.71,29344
"OSCAR MAYER, Salami (for beer)",Sausages and Luncheon Meats,13.5,0.009,1.231,0,0,0.212,1.9,1.1,18.2,62.9,225,6.388,8.824,1.896,29345
"OSCAR MAYER, Salami (hard)",Sausages and Luncheon Meats,25.9,0.012,1.976,0,0,0.356,1.6,0.2,28.7,38.25,368,11.085,15.011,2.856,29346
"OSCAR MAYER, Salami Beef Cotto",Sausages and Luncheon Meats,14.2,0.007,1.309,0,0,0.207,1.9,1.1,15.7,64.5,206,6.74,6.91,0.78,29347
"OSCAR MAYER, Salami Cotto (beef, pork, chicken)",Sausages and Luncheon Meats,13.4,0.075,1.095,0,0,0.217,2.2,1.5,20.3,60.6,245,8.47,10.15,1.67,29348
"OSCAR MAYER, Sandwich Spread (pork, chicken, beef)",Sausages and Luncheon Meats,6.5,0.027,0.821,0.3,0,0.118,15.4,7.9,16.6,59.2,237,5.754,7.201,2.483,29349
"OSCAR MAYER, Smokie Links Sausage",Sausages and Luncheon Meats,12.4,0.01,1.007,0,0,0.18,1.7,1.6,27.3,55.5,302,9.38,13.17,2.78,29350
"OSCAR MAYER, Smokies (beef)",Sausages and Luncheon Meats,12.25,0.011,0.967,0,0,0.173,1.95,1.4,26.65,56.3,296,11.25,12.8,0.9,29351
"OSCAR MAYER, Smokies (cheese)",Sausages and Luncheon Meats,12.9,0.045,1.046,0,0,0.181,1.8,1.5,27.1,54.9,303,10.16,12.99,2.7,29352
"OSCAR MAYER, Smokies Sausage Little (pork, turkey)",Sausages and Luncheon Meats,12.4,0.011,1.022,0,0,0.173,1.8,1.4,27.1,55.5,301,9.444,12.889,2.87,29353
"OSCAR MAYER, Smokies Sausage Little Cheese (pork, turkey)",Sausages and Luncheon Meats,13.5,0.067,1.036,0,0,0.152,1.7,0.3,28.2,53.2,315,11.16,13.19,2.79,29354
"OSCAR MAYER, Summer Sausage Beef Thuringer Cervelat",Sausages and Luncheon Meats,14.6,0.008,1.424,0,0,0.233,1.9,1.1,27,52.5,309,11.8,12.9,1.13,29355
"OSCAR MAYER, Summer Sausage Thuringer Cervalat",Sausages and Luncheon Meats,14.9,0.009,1.43,0,0,0.228,0.9,0.5,26.7,53.3,304,10.727,12.142,2.229,29356
"OSCAR MAYER, Turkey Breast (smoked, fat free)",Sausages and Luncheon Meats,14.9,0.01,1.095,0,0,0.218,3.6,1.1,0.6,77.5,80,0.18,0.15,0.11,29357
"OSCAR MAYER, Wieners (beef franks)",Sausages and Luncheon Meats,11.35,0.01,1.025,0,0,0.13,2.35,1.6,30.26,53.22,327,12.46,14.74,1.36,29358
"OSCAR MAYER, Wieners (beef franks, bun length)",Sausages and Luncheon Meats,11.1,0.013,1.025,0,0,0.158,2.65,1.8,30.1,53.15,324,12.53,14.62,0.96,29359
"OSCAR MAYER, Wieners (beef franks, fat free)",Sausages and Luncheon Meats,13.2,0.02,0.927,0,0,0.467,5.1,3.8,0.5,78,78,0.225,0.19,0.049,29360
"OSCAR MAYER, Wieners (beef franks, light)",Sausages and Luncheon Meats,10.7,0.021,1.079,0,0,0.401,4.1,2.1,14.9,66.8,193,6.32,7.52,0.99,29361
"OSCAR MAYER, Wieners (cheese hot dogs with turkey)",Sausages and Luncheon Meats,12,0.164,1.143,0,0,0.131,2.8,1.8,28.7,52.9,318,10.025,13.104,3.849,29362
"OSCAR MAYER, Wieners (fat free hot dogs)",Sausages and Luncheon Meats,12.6,0.015,0.974,0,0,0.471,4.3,2.1,0.6,78.8,73,0.2,0.21,0.11,29363
"OSCAR MAYER, Wieners (light pork, turkey, beef)",Sausages and Luncheon Meats,12.1,0.038,1.036,0,0,0.397,2.8,1.5,14.9,66.7,194,5.199,6.763,2.044,29364
"OSCAR MAYER, Wieners (pork, turkey)",Sausages and Luncheon Meats,10.95,0.06,0.988,0,0,0.162,2.7,1.8,29.9,53.35,327,9.467,13.806,4.255,29365
"OSCAR MAYER, Wieners Little (pork, turkey)",Sausages and Luncheon Meats,10.9,0.013,1.039,0,0,0.159,2.3,1.6,28.7,55,311,11.15,14.33,2.67,29366
OSCAR MAYER. Bologna (Wisconsin made ring),Sausages and Luncheon Meats,11.8,0.015,0.827,0,0,0.139,2.6,2.2,28.4,54.7,313,11.14,14.09,1.97,29367
"Olive loaf, pork",Sausages and Luncheon Meats,11.8,0.109,1.484,0,0,0.297,9.2,0,16.5,58.2,235,5.85,7.87,1.93,29368
Oven-roasted chicken breast roll,Sausages and Luncheon Meats,14.59,0.006,0.883,0,0,0.324,1.79,0.43,7.65,72.98,134,2.48,2.816,1.456,29369
"Pastrami, beef, 98% fat-free",Sausages and Luncheon Meats,19.6,0.009,1.01,0,0.0346,0.228,1.54,0,1.16,74.3,95,0,0.56,0.04,29370
"Pastrami, turkey",Sausages and Luncheon Meats,16.3,0.011,0.981,0.1,0.0081,0.345,1.79,3.34,6.21,71.73,133,1.699,2.131,1.635,29371
"Pate, chicken liver, canned",Sausages and Luncheon Meats,13.45,0.01,0.386,0,0.01,0.095,6.55,0,13.1,65.64,201,4,5.28,2.46,29372
"Pate, goose liver, smoked, canned",Sausages and Luncheon Meats,11.4,0.07,0.697,0,0,0.138,4.67,0,43.84,37.04,462,14.45,25.61,0.84,29373
"Pate, liver, not specified, canned",Sausages and Luncheon Meats,14.2,0.07,0.697,0,0.002,0.138,1.5,0,28,53.9,319,9.57,12.36,3.16,29374
"Pate, truffle flavor",Sausages and Luncheon Meats,11.2,0.07,0.807,0,0.002,0.138,6.3,0,28.5,51.8,327,10.1,13.6,3.42,29375
"Peppered loaf, pork, beef",Sausages and Luncheon Meats,17.3,0.054,1.523,0,0,0.394,4.53,4.61,6.37,67.4,149,2.28,3,0.5,29376
"Pepperoni, pork, beef",Sausages and Luncheon Meats,22.68,0.022,1.653,0,0.0007,0.279,0,0,43.98,30.72,494,14.86,17.166,3.426,29377
"Pickle and pimiento loaf, pork",Sausages and Luncheon Meats,11.23,0.109,1.304,1.5,0.0078,0.371,8.46,8.46,15.95,60.29,225,5.287,7.06,2.804,29378
"Picnic loaf, pork, beef",Sausages and Luncheon Meats,14.92,0.047,1.164,0,0,0.267,4.76,0,16.64,60.2,232,6.07,7.68,1.89,29379
"Polish sausage, pork",Sausages and Luncheon Meats,14.1,0.012,0.876,0,0.001,0.237,1.63,0,28.72,53.15,326,10.33,13.52,3.08,29380
"Pork and beef sausage, fresh, cooked",Sausages and Luncheon Meats,13.8,0.01,0.805,0,0,0.189,2.7,0,36.25,44.55,396,12.96,17.18,3.9,29381
"Pork and turkey sausage, pre-cooked",Sausages and Luncheon Meats,12.05,0.074,0.876,0,0.0007,0.23,3.63,0,30.64,50.68,342,9.881,13.054,4.504,29382
"Pork sausage rice links, brown and serve, cooked",Sausages and Luncheon Meats,13.7,0.015,0.689,0,0,0.212,2.36,0,37.63,44.1,407,6.701,8.615,2.368,29383
"Pork sausage, fresh, cooked",Sausages and Luncheon Meats,19.43,0.013,0.749,0,0.0007,0.294,0,0,28.36,49.78,339,9.153,12.376,3.727,29384
"Pork sausage, fresh, raw",Sausages and Luncheon Meats,15.09,0.009,0.636,0,0.0007,0.248,0,0,26.53,56.22,304,8.796,11.814,3.558,29385
"Pork sausage, pre-cooked",Sausages and Luncheon Meats,14.49,0.137,0.752,0,0.0007,0.307,0,0,35.04,47.97,378,11.662,15.17,4.869,29386
Poultry salad sandwich spread,Sausages and Luncheon Meats,11.64,0.01,0.377,0,0.001,0.183,7.41,0,13.52,66.17,200,3.45,3.25,6.22,29387
Roast beef spread,Sausages and Luncheon Meats,15.27,0.023,0.724,0.2,0.0002,0.259,3.73,0.71,16.28,61.7,223,6.4,5.587,0.427,29388
"Salami, Italian, pork",Sausages and Luncheon Meats,21.7,0.01,1.89,0,0,0.34,1.2,1.2,37,34.6,425,13.1,18.2,3.6,29389
"Salami, Italian, pork and beef, dry, sliced, 50% less sodium",Sausages and Luncheon Meats,21.8,0.008,0.936,0,0,0.378,6.4,0,26.4,40.5,350,9.75,13.2,2.29,29390
"Salami, cooked, beef",Sausages and Luncheon Meats,12.6,0.006,1.14,0,0,0.188,1.9,1.5,22.2,60,261,9.865,10.648,1.036,29391
"Salami, cooked, beef and pork",Sausages and Luncheon Meats,21.85,0.015,1.449,0,0,0.316,2.4,0.96,25.9,45.19,336,9.316,11.127,2.529,29392
"Salami, cooked, turkey",Sausages and Luncheon Meats,19.3,0.04,1.004,0.1,0,0.216,1.55,1.12,9.21,68.93,172,2.783,3.172,2.466,29393
"Salami, dry or hard, pork",Sausages and Luncheon Meats,22.58,0.013,2.26,0,0,0.378,1.6,0,33.72,36.18,407,11.89,16,3.74,29394
"Salami, dry or hard, pork, beef",Sausages and Luncheon Meats,23.2,0.008,2.01,0,0,0.378,3.8,0,30.05,37.15,385,10.612,14.854,2.781,29395
"Salami, pork, beef, less sodium",Sausages and Luncheon Meats,15.01,0.094,0.623,0.2,0.0007,1.372,15.38,6.21,30.5,33.74,396,10.58,13.32,3.067,29396
"Sandwich spread, pork, beef",Sausages and Luncheon Meats,7.66,0.012,1.013,0.2,0,0.11,11.94,0,17.34,60.28,235,5.977,7.584,2.565,29397
"Sausage, Berliner, pork, beef",Sausages and Luncheon Meats,15.27,0.012,1.297,0,0,0.283,2.59,2.35,17.2,60.97,230,6.08,8,1.58,29398
"Sausage, Italian, pork, cooked",Sausages and Luncheon Meats,19.12,0.021,1.207,0.1,0.0001,0.304,4.27,0.86,27.31,47.13,344,9.655,12.777,3.507,29399
"Sausage, Italian, pork, raw",Sausages and Luncheon Meats,14.25,0.018,0.731,0,0.002,0.253,0.65,0,31.33,51.08,346,11.27,14.34,4.03,29400
"Sausage, Italian, sweet, links",Sausages and Luncheon Meats,16.13,0.025,0.57,0,0.0002,0.194,2.1,0,8.42,71.6,149,3.257,3.629,0.493,29401
"Sausage, Italian, turkey, smoked",Sausages and Luncheon Meats,15.05,0.021,0.928,0.9,0.0304,0.197,4.65,3.2,8.75,68.5,158,0,0,0,29402
"Sausage, Polish, beef with chicken, hot",Sausages and Luncheon Meats,17.6,0.012,1.54,0,0,0.237,3.6,0,19.4,55.2,259,8.01,9.7,0.79,29403
"Sausage, Polish, pork and beef, smoked",Sausages and Luncheon Meats,12.07,0.007,0.848,0,0,0.189,1.98,0,26.56,56.58,301,9.207,12.302,2.826,29404
"Sausage, Vienna, canned, chicken, beef, pork",Sausages and Luncheon Meats,10.5,0.01,0.969,0,0,0.101,2.6,0,19.4,64.9,230,7.125,9.628,1.29,29405
"Sausage, chicken and beef, smoked",Sausages and Luncheon Meats,18.5,0.011,1.02,0,0,0.139,0,0,24,55.6,295,7.16,10.19,4.72,29406
"Sausage, chicken, beef, pork, skinless, smoked",Sausages and Luncheon Meats,13.6,0.1,1.034,0,0,0.246,8.1,1.9,14.3,60.5,216,4.8,7.165,0.846,29407
"Sausage, pork and beef, with cheddar cheese, smoked",Sausages and Luncheon Meats,12.89,0.057,0.848,0,0,0.206,2.13,0.11,25.84,56.11,296,9.482,12.357,2.735,29408
"Sausage, smoked link sausage, pork and beef",Sausages and Luncheon Meats,12,0.012,0.911,0,0,0.179,2.42,0,28.73,53.97,320,9.769,12.238,3.927,29409
"Sausage, summer, pork and beef, sticks, with cheddar cheese",Sausages and Luncheon Meats,19.43,0.081,1.483,0.2,0,0.206,1.82,0.12,37.91,36.2,426,10.47,13.68,3.02,29410
"Sausage, turkey, breakfast links, mild",Sausages and Luncheon Meats,15.42,0.032,0.585,0,0.0005,0.229,1.56,0,18.09,63.12,235,3.839,5.158,4.657,29411
"Sausage, turkey, hot, smoked",Sausages and Luncheon Meats,15.05,0.021,0.928,0.9,0.0304,0.197,4.65,3.2,8.75,68.5,158,3.77,2.38,1.572,29412
"Scrapple, pork",Sausages and Luncheon Meats,8.06,0.007,0.659,0.3,0.0025,0.158,14.06,0.2,13.87,62.47,213,4.732,6.156,1.664,29413
"Smoked link sausage, pork",Sausages and Luncheon Meats,11.98,0.011,0.827,0,0,0.483,0.18,0.94,28.23,56.49,307,9.313,11.212,3.626,29414
"Smoked link sausage, pork and beef, flour and nonfat dry milk added",Sausages and Luncheon Meats,13.97,0.066,1.272,0,0.0032,0.155,3.96,3,21.43,57.4,268,7.789,9.89,2.251,29415
"Smoked link sausage, pork and beef, nonfat dry milk added",Sausages and Luncheon Meats,13.28,0.041,1.173,0,0,0.286,1.92,0,27.61,53.91,313,9.72,12.64,3.02,29416
"Swisswurst, pork and beef, with swiss cheese, smoked",Sausages and Luncheon Meats,12.69,0.074,0.827,0,0,0.206,1.6,0,27.37,55.31,307,0,13.68,3.02,29417
"Thuringer, cervelat, summer sausage, beef, pork",Sausages and Luncheon Meats,17.45,0.009,1.3,0,0.0166,0.26,3.33,0.85,30.43,45.18,362,11.51,12.97,1.2,29418
"Turkey and pork sausage, fresh, bulk, patty or link, cooked",Sausages and Luncheon Meats,22.7,0.032,0.878,0,0.0011,0.337,0.7,0,23,50.9,307,7.95,9.719,3.25,29419
Turkey breast meat,Sausages and Luncheon Meats,17.07,0.008,1.015,0.5,0.0057,0.302,4.21,3.51,1.66,74.07,104,0.333,0.494,0.312,29420
"Turkey breast, low salt, prepackaged or deli, luncheon meat",Sausages and Luncheon Meats,22.81,0.008,0.772,0.5,0.0057,0.211,0,3.51,0.88,74.07,99,0.198,0.294,0.186,29421
"Turkey ham, cured turkey thigh meat",Sausages and Luncheon Meats,17.5,0.008,1.114,0.2,0.0089,0.287,2.04,1.43,4.84,71.92,126,1.526,1.902,1.319,29422
"Turkey ham, sliced, extra lean, prepackaged or deli-sliced",Sausages and Luncheon Meats,19.6,0.005,1.038,0,0,0.299,2.93,0,3.8,72,124,1.09,0.864,1.148,29423
"Turkey roll, light and dark meat",Sausages and Luncheon Meats,18.14,0.032,0.586,0,0,0.27,2.12,0,6.99,70.15,149,2.04,2.3,1.78,29424
"Turkey roll, light meat",Sausages and Luncheon Meats,14.79,0.007,1.042,0,0,0.427,5.03,0,1.66,74.87,98,0.318,0.421,0.255,29425
"Turkey sausage, fresh, cooked",Sausages and Luncheon Meats,23.89,0.022,0.665,0,0.0007,0.298,0,0,10.44,65.06,196,2.266,2.99,2.706,29426
"Turkey sausage, fresh, raw",Sausages and Luncheon Meats,18.79,0.019,0.593,0,0.0023,0.262,0.47,0,8.08,70.42,155,1.963,2.624,2.386,29427
"Turkey sausage, reduced fat, brown and serve, cooked (include BUTTERBALL breakfast links turkey sausage)",Sausages and Luncheon Meats,17,0.031,0.617,0.3,0,0.207,10.92,0,10.3,59.59,204,2.871,3.876,2.541,29428
"Turkey, breast, smoked, lemon pepper flavor, 97% fat-free",Sausages and Luncheon Meats,20.9,0,1.16,0,0,0,1.31,0,0.69,73.5,95,0.22,0.25,0.19,29429
"Turkey, pork, and beef sausage, low fat, smoked",Sausages and Luncheon Meats,8,0.01,0.796,0.6,0.0019,0.243,11.54,0,2.5,76.3,101,0.85,1.026,0.329,29430
"Turkey, pork, and beef sausage, reduced fat, smoked",Sausages and Luncheon Meats,18.6,0.018,0.957,0,0,0.204,2.6,0,16.8,59.2,241,5.944,7.033,2.145,29431
"Turkey, white, rotisserie, deli cut",Sausages and Luncheon Meats,13.5,0.016,1.2,0.4,0.01,0.349,7.7,4,3,72,112,0.118,0.591,0.37,29432
"USDA Commodity, luncheon meat, canned",Sausages and Luncheon Meats,17.5,0.005,0.82,0,0,0.3,1.04,0,12.77,65.87,189,3.944,5.685,1.565,29433
"USDA Commodity, pork sausage, bulk/links/patties, frozen, raw",Sausages and Luncheon Meats,14.95,0.009,0.507,0,0,0.231,0,0,18.56,64.92,231,4.968,8.061,2.246,29434
"USDA Commodity, pork, sausage, bulk/links/patties, frozen, cooked",Sausages and Luncheon Meats,19.76,0.009,0.54,0,0,0.239,0,0,20.26,58.66,267,5.532,8.71,2.312,29435
"Yachtwurst, with pistachio nuts, cooked",Sausages and Luncheon Meats,14.8,0.019,0.936,0,0,0,1.4,0,22.6,58.2,268,7.9,0,0,29436
AMARANTH FLAKES,Breakfast Cereals,15.54,0.017,0.035,9.5,0.0025,0.353,71.15,0.42,7,3,353,1.325,2.148,2.752,29437
"Cereal ready-to-eat, CRISPY BROWN RICE",Breakfast Cereals,7.1,0.028,0.012,7.3,0,0.238,86,9.1,3.5,3,388,0.688,1.011,1.473,29438
"Cereal wafer straws, KELLOGG, APPLE JACKS Cereal straws",Breakfast Cereals,5.7,0.03,0.05,0,0,0.006,78.4,40,11.4,2,439,5.8,3.2,2.4,29439
"Cereal wafer straws, KELLOGG, COCOA KRISPIES Cereal straws",Breakfast Cereals,5.9,0.009,0.05,1.5,0,0.236,77.2,39,11.8,2,439,6.3,3.4,2.07,29440
"Cereal wafer straws, KELLOGG, FROOT LOOPS Cereal straws",Breakfast Cereals,5.4,0.054,0.049,3.1,0,0.008,78.4,38.6,11.4,2,438,5.9,3.3,2.16,29441
"Cereals ready to eat, composite character cereals (movies, TV), brand A",Breakfast Cereals,6.13,0.107,0.648,3.6,0.0525,0.165,85.9,39.25,2.33,2.68,389,0.525,0.975,0.775,29442
"Cereals ready to eat, composite character cereals (movies, TV), brand B",Breakfast Cereals,3.33,0.333,0.533,0,0.02,0.033,90,46.67,1.67,2.5,388,0,1.667,0,29443
"Cereals ready-to-eat, APPLE CINNAMON SQUARES MINI-WHEATS",Breakfast Cereals,7.2,0.038,0.036,8.6,0,0.302,80.1,21.2,1.8,9.5,331,0.4,0.5,0.9,29444
"Cereals ready-to-eat, Cranberry Macadamia Nut Cereal",Breakfast Cereals,6.64,0.126,0.418,6,0.0179,0.224,76.98,28.73,9.82,4.26,408,1.65,6.54,0.84,29445
"Cereals ready-to-eat, FAMILIA",Breakfast Cereals,9.5,0.173,0.05,8.5,0.0006,0.494,73.8,26.2,6.3,1.9,388,0.711,3.205,1.656,29446
"Cereals ready-to-eat, GENERAL MILLS Cinnamon CHEX",Breakfast Cereals,4.6,0.333,0.612,2.2,0.02,0.135,82.9,26,6.8,2.3,403,0.7,4.5,1.4,29447
"Cereals ready-to-eat, GENERAL MILLS Peanut Butter Toast Crunch",Breakfast Cereals,6.67,0.333,0.45,3.3,0.02,0.2,76,43.33,11.67,2.1,433,1.67,6.67,3.33,29448
"Cereals ready-to-eat, GENERAL MILLS, APPLE CINNAMON CHEERIOS",Breakfast Cereals,6.67,0.333,0.442,4,0.02,0.2,83.33,43.33,5,2.3,400,0,3.33,1.67,29449
"Cereals ready-to-eat, GENERAL MILLS, BASIC 4",Breakfast Cereals,7,0.455,0.586,6.3,0,0.286,78.8,25.45,4,6.27,358,1.4,1.3,0.9,29450
"Cereals ready-to-eat, GENERAL MILLS, BERRY BERRY KIX",Breakfast Cereals,3.33,0.385,0.69,3.3,0.02,0.156,86.67,30,3.5,2.3,379,0.5,1.5,1.1,29451
"Cereals ready-to-eat, GENERAL MILLS, BOO BERRY",Breakfast Cereals,5.1,0.067,0.59,3.2,0.02,0.131,86.9,36.4,3.33,2.02,384,0.522,1.67,0,29452
"Cereals ready-to-eat, GENERAL MILLS, CHEERIOS",Breakfast Cereals,11.33,0.408,0.571,10.1,0.024399999999999998,0.609,74.68,4.02,5.9,3.76,367,0.954,1.917,1.973,29453
"Cereals ready-to-eat, GENERAL MILLS, CHEERIOS, Banana Nut",Breakfast Cereals,5.4,0.357,0.57,6.1,0.0536,0.252,84.7,33.4,4,2.2,374,0.7,1.2,1.9,29454
"Cereals ready-to-eat, GENERAL MILLS, CHEERIOS, Chocolate",Breakfast Cereals,5.6,0.37,0.629,6.2,0.055600000000000004,0.291,83.4,34.5,5.1,2.2,380,1.1,1.6,2.1,29455
"Cereals ready-to-eat, GENERAL MILLS, CHEERIOS, Yogurt Burst",Breakfast Cereals,6.67,0.333,0.584,6.7,0.05,0.233,81.75,33.33,5.23,2.23,400,1.667,1.667,1.667,29456
"Cereals ready-to-eat, GENERAL MILLS, CHOCOLATE LUCKY CHARMS",Breakfast Cereals,5.3,0.357,0.568,5.2,0.0214,0.23,84.8,42.86,4,2.34,378,0.551,1.607,1.145,29457
"Cereals ready-to-eat, GENERAL MILLS, CINNAMON TOAST CRUNCH",Breakfast Cereals,5.27,0.451,0.701,3.8,0.0235,0.152,79.73,33.15,9.38,2.64,414,1.422,3.211,3.061,29458
"Cereals ready-to-eat, GENERAL MILLS, COCOA PUFFS",Breakfast Cereals,5.2,0.333,0.546,6.5,0.02,0.266,84.4,42.3,5.1,1.8,385,0,3.33,0,29459
"Cereals ready-to-eat, GENERAL MILLS, COOKIE CRISP",Breakfast Cereals,4.7,0.385,0.554,4.6,0.02,0.185,85.8,39.6,3.6,2.3,377,0.6,1,1.4,29460
"Cereals ready-to-eat, GENERAL MILLS, COUNT CHOCULA",Breakfast Cereals,4.3,0.37,0.587,3.8,0.02,0.194,86.4,44.9,3.7,2.02,382,0.692,1.633,0.703,29461
"Cereals ready-to-eat, GENERAL MILLS, Chocolate CHEX",Breakfast Cereals,4.9,0.313,0.745,1.8,0.0188,0.169,80.9,26.2,8.3,2.2,411,1.3,5.1,1.6,29462
"Cereals ready-to-eat, GENERAL MILLS, Cinnamon Grahams",Breakfast Cereals,5,0.333,0.789,3.2,0.02,0.147,86,38,2.8,2.63,378,0.5,1.012,1.04,29463
"Cereals ready-to-eat, GENERAL MILLS, Cinnamon Toast Crunch, reduced sugar",Breakfast Cereals,4.9,0.714,0.718,8.9,0.02,0.15,77.4,22.1,10.2,2.64,381,1,6.8,2.1,29464
"Cereals ready-to-eat, GENERAL MILLS, Corn CHEX",Breakfast Cereals,6.67,0.333,0.762,4,0.02,0.15,85.06,10,1.67,2.5,367,0.381,0.381,0.657,29465
"Cereals ready-to-eat, GENERAL MILLS, Curves Fruit & Nut Crunch cereal",Breakfast Cereals,6.12,0.204,0.49,10.2,0.012199999999999999,0.306,85.44,20.41,3.06,3,347,0.389,1.411,1.02,29466
"Cereals ready-to-eat, GENERAL MILLS, DORA THE EXPLORER",Breakfast Cereals,3.7,0.37,0.643,11.1,0.0222,0.186,84.97,22.22,5.56,2.79,370,0.495,2.938,1.79,29467
"Cereals ready-to-eat, GENERAL MILLS, FIBER ONE",Breakfast Cereals,6.67,0.333,0.352,47.3,0.02,0.6,83.33,0,3.33,3.19,200,0.379,0.44,1.366,29468
"Cereals ready-to-eat, GENERAL MILLS, FIBER ONE, Caramel Delight",Breakfast Cereals,6,0.2,0.452,16.7,0.012,0.263,82.5,21.5,5.9,2.8,352,1.2,2.3,2.3,29469
"Cereals ready-to-eat, GENERAL MILLS, FIBER ONE, Honey Clusters",Breakfast Cereals,6.5,0.192,0.462,24.5,0,0.349,84.9,11.2,2.8,2.7,301,0.5,0.5,1,29470
"Cereals ready-to-eat, GENERAL MILLS, FIBER ONE, Raisin Bran Clusters",Breakfast Cereals,5.2,0.182,0.384,21,0,0.391,85.2,24.6,2.2,4.6,311,0.4,0.4,0.7,29471
"Cereals ready-to-eat, GENERAL MILLS, FIBER ONE, Shredded Wheat",Breakfast Cereals,8.4,0,0.002,13.7,0,0.305,81.7,20.3,1.7,6,321,0.3,0.3,0.7,29472
"Cereals ready-to-eat, GENERAL MILLS, FRANKENBERRY",Breakfast Cereals,5,0.303,0.59,3.2,0.02,0.131,86.9,37.3,3,2.02,384,0.5,0.9,1.3,29473
"Cereals ready-to-eat, GENERAL MILLS, FROSTED CHEERIOS",Breakfast Cereals,7.5,0.357,0.617,6,0.0214,0.208,82.6,36.8,3.9,2.2,378,0.9,1.4,1.4,29474
"Cereals ready-to-eat, GENERAL MILLS, French Toast Crunch",Breakfast Cereals,6.25,0.313,0.719,3.1,0.0188,0.141,77.55,34.38,10.94,2.05,438,0.592,7.81,1.56,29475
"Cereals ready-to-eat, GENERAL MILLS, Frosted CHEX",Breakfast Cereals,3.65,0.333,0.6,0,0.02,0.1,89.26,33.33,1.67,2.2,367,0.317,0.503,0.589,29476
"Cereals ready-to-eat, GENERAL MILLS, Fruity CHEERIOS",Breakfast Cereals,5.81,0.37,0.493,6,0.055600000000000004,0.217,85.6,33.33,3.9,2.55,370,0.456,1.458,1.407,29477
"Cereals ready-to-eat, GENERAL MILLS, GENERAL MILLS Berry Burst CHEERIOS, all flavors",Breakfast Cereals,10,0.37,0.639,7.3,0.07,0.259,80,31.11,4,2.07,367,0,2.536,1.272,29478
"Cereals ready-to-eat, GENERAL MILLS, GOLDEN GRAHAMS",Breakfast Cereals,5.1,0.323,0.852,4.3,0.02,0.189,84.6,34.3,3.7,2.2,378,0.323,1.67,0.982,29479
"Cereals ready-to-eat, GENERAL MILLS, HARMONY",Breakfast Cereals,11,1.091,0.645,4,0.055,0.166,79,24,2.2,2.83,365,0.5,0.814,0.601,29480
"Cereals ready-to-eat, GENERAL MILLS, HONEY NUT CHEERIOS",Breakfast Cereals,10.71,0.357,0.568,7.1,0.0214,0.411,77.33,32.14,5.36,2.2,393,0,1.79,1.79,29481
"Cereals ready-to-eat, GENERAL MILLS, HONEY NUT CLUSTERS",Breakfast Cereals,7.27,0.036,0.515,5.5,0.0109,0.245,83.64,30.91,4.55,2.15,382,0,2.73,0.91,29482
"Cereals ready-to-eat, GENERAL MILLS, Honey Nut CHEX",Breakfast Cereals,6.67,0.333,0.619,1,0.02,0.117,86,30,1.67,2.2,400,0.278,0.585,0.615,29483
"Cereals ready-to-eat, GENERAL MILLS, KABOOM",Breakfast Cereals,3.33,0.333,0.633,3.3,0.02,0.133,87.26,20,3.33,2.37,400,0.452,1.116,1.341,29484
"Cereals ready-to-eat, GENERAL MILLS, KIX",Breakfast Cereals,6.8,0.57,0.631,8.9,0.0254,0.188,84.9,9.76,2.6,2.79,367,0.432,0.743,0.958,29485
"Cereals ready-to-eat, GENERAL MILLS, LUCKY CHARMS",Breakfast Cereals,7.02,0.399,0.721,4.7,0.0233,0.182,82.9,39.93,3.63,3.17,407,0.602,1.195,1.183,29486
"Cereals ready-to-eat, GENERAL MILLS, Multi-Bran CHEX",Breakfast Cereals,6.9,0.213,0.564,12.1,0.0128,0.379,84.06,20.69,2.59,2.5,328,0.481,0.589,0.86,29487
"Cereals ready-to-eat, GENERAL MILLS, Multi-Grain Cheerios",Breakfast Cereals,6.9,0.345,0.555,9.3,0.0517,0.292,83.34,20.69,3.45,2.5,379,0.531,1.011,1.38,29488
"Cereals ready-to-eat, GENERAL MILLS, NATURE VALLEY LOW FAT FRUIT GRANOLA",Breakfast Cereals,8,0.036,0.376,5,0,0.279,80,33.41,4.6,6.72,386,0.85,2.115,1.142,29489
"Cereals ready-to-eat, GENERAL MILLS, OATMEAL CRISP with Almonds",Breakfast Cereals,9.09,0.036,0.207,7.5,0.0109,0.327,77.25,29.09,8.18,2.86,400,0.91,3.64,2.73,29490
"Cereals ready-to-eat, GENERAL MILLS, OATMEAL CRISP, APPLE CINNAMON",Breakfast Cereals,7.27,0.036,0.491,7.3,0.0109,0.309,84,34.55,3.64,2.59,382,0.91,1.82,0.91,29491
"Cereals ready-to-eat, GENERAL MILLS, Oatmeal Crisp, Raisin",Breakfast Cereals,8.5,0.032,0.196,7.5,0,0.346,83.1,31.9,3.9,2.5,382,0.8,1.1,1.3,29492
"Cereals ready-to-eat, GENERAL MILLS, RAISIN NUT BRAN",Breakfast Cereals,7.27,0.036,0.461,9.8,0,0.35,76.36,27.27,6.36,7.54,364,0.91,2.73,0.91,29493
"Cereals ready-to-eat, GENERAL MILLS, REESE'S PUFFS",Breakfast Cereals,6.67,0.333,0.662,4,0.02,0.217,75.86,40,11.67,2.67,433,1.67,5,3.33,29494
"Cereals ready-to-eat, GENERAL MILLS, Rice CHEX",Breakfast Cereals,6.45,0.37,0.91,1,0.0222,0.167,85.42,7.41,1.61,2.5,382,0.348,0.552,0.525,29495
"Cereals ready-to-eat, GENERAL MILLS, TOTAL Corn Flakes",Breakfast Cereals,6.05,3.333,0.698,2.5,0.2,0.094,85.55,11,1.61,2.56,373,0.4,0.214,0.368,29496
"Cereals ready-to-eat, GENERAL MILLS, TOTAL Raisin Bran",Breakfast Cereals,5.45,1.818,0.438,9.1,0,0.564,76.36,34.55,1.82,8.75,309,0.297,0.262,0.914,29497
"Cereals ready-to-eat, GENERAL MILLS, TOTAL, Blueberry Pomegranate",Breakfast Cereals,9.3,2.041,0.195,8,0.12240000000000001,0.287,76.8,21.9,3.7,2.9,353,0.8,1.1,1.3,29498
"Cereals ready-to-eat, GENERAL MILLS, TOTAL, Cranberry Crunch",Breakfast Cereals,6.2,1.724,0.334,7.3,0,0.24,78.8,27.9,2,4,335,0.5,0.4,1,29499
"Cereals ready-to-eat, GENERAL MILLS, TRIX",Breakfast Cereals,4.7,0.313,0.588,4.3,0.02,0.163,85.9,34.4,4.1,2,384,0.67,1.8,1.596,29500
"Cereals ready-to-eat, GENERAL MILLS, TRIX, reduced sugar, bowlpak",Breakfast Cereals,4.76,0.381,0.571,4.8,0.0171,0.143,85.49,28.57,4.76,2,400,0.67,2.334,1.596,29501
"Cereals ready-to-eat, GENERAL MILLS, WHEATIES",Breakfast Cereals,9.4,0.067,0.737,11.7,0.02,0.35,82.6,13.33,1.8,2.59,347,0.5,0.3,1,29502
"Cereals ready-to-eat, GENERAL MILLS, WHEATIES Raisin Bran",Breakfast Cereals,7,0,0.457,9,0,0.413,81,33,1.7,7,333,0.4,0.169,0.644,29503
"Cereals ready-to-eat, GENERAL MILLS, WHEATIES, Fuel",Breakfast Cereals,6,0.182,0.276,9.7,0.0273,0.215,82.8,24.9,5.8,3,375,0.8,3.1,1.3,29504
"Cereals ready-to-eat, GENERAL MILLS, Wheat CHEX",Breakfast Cereals,10,0.2,0.635,10.8,0.012,0.368,81.09,10,2,2.5,360,0.328,0.248,0.832,29505
"Cereals ready-to-eat, GENERAL MILLS, Whole Grain TOTAL",Breakfast Cereals,8.4,3.333,0.655,9.1,0.2,0.306,74.5,16.2,2.1,2.67,319,0.6,0.3,0.8,29506
"Cereals ready-to-eat, HEALTH VALLEY, FIBER 7 Flakes",Breakfast Cereals,14.44,0.071,0.053,14.1,0.004200000000000001,0.46,78.15,1.86,1.41,3,353,0.261,0.244,0.61,29507
"Cereals ready-to-eat, JUST RIGHT with Crunchy Nuggets",Breakfast Cereals,7.7,0.026,0.614,5.1,0,0.22,83.7,21.2,2.7,3.2,371,0.2,0.5,1.9,29508
"Cereals ready-to-eat, KASHI 7 Whole Grain Flakes",Breakfast Cereals,11,0.03,0.303,11.3,0,0.319,81.8,8.6,1.95,3.4,350,0.4,0.4,1.2,29509
"Cereals ready-to-eat, KASHI 7 Whole Grain Honey Puffs",Breakfast Cereals,10,0.029,0.01,6.7,0,0.264,82.5,23,3.3,3,381,0.359,0.558,0.866,29510
"Cereals ready-to-eat, KASHI 7 Whole Grain Nuggets",Breakfast Cereals,11.8,0.041,0.448,11.7,0,0,81,5,2.8,3,356,0.6,0.6,1.6,29511
"Cereals ready-to-eat, KASHI GO LEAN CRUNCH!, Honey Almond Flax",Breakfast Cereals,16.6,0.101,0.261,16.2,0,0.486,67,23,8.3,3.5,381,0.7,4.1,3.5,29512
"Cereals ready-to-eat, KASHI GOLEAN",Breakfast Cereals,26.1,0.14,0.165,19.6,0,0.926,58,12,2,2.2,284,0.4,0.4,1.1,29513
"Cereals ready-to-eat, KASHI GOLEAN CRUNCH!",Breakfast Cereals,17.5,0.087,0.19,15.2,0,0.566,67.9,24.6,5.8,4,377,0.47,3.23,2.1,29514
"Cereals ready-to-eat, KASHI GRANOLA, COCOA BEACH Cereal",Breakfast Cereals,11,0.051,0.25,12.7,0,0.272,61,19.8,16.7,4,410,3.2,8.1,5.4,29515
"Cereals ready-to-eat, KASHI GRANOLA, Mountain Medley Cereal",Breakfast Cereals,11,0.032,0.22,11.5,0,0.255,66.5,22,13,3,397,2.1,7.2,3.7,29516
"Cereals ready-to-eat, KASHI GRANOLA, ORCHARD SPICE CEREAL",Breakfast Cereals,11,0.04,0.24,11.4,0,0.279,67,20,13.4,3,403,1.7,6.99,4.7,29517
"Cereals ready-to-eat, KASHI GRANOLA, Summer Berry Cereal",Breakfast Cereals,12,0.038,0.24,12.4,0,0.235,66.6,17,11.6,4,390,1.6,5.5,4.5,29518
"Cereals ready-to-eat, KASHI Good Friends",Breakfast Cereals,9.4,0.034,0.2,22.2,0,0.495,81.8,17,3.6,2,316,0.4,1,1.7,29519
"Cereals ready-to-eat, KASHI HEART TO HEART, Honey Toasted Oat",Breakfast Cereals,12,0.042,0.25,13.4,0.091,0.29,76.4,17.7,5,2.8,357,1,1.1,1.2,29520
"Cereals ready-to-eat, KASHI Heart to Heart Warm Cinnamon",Breakfast Cereals,12.9,0.047,0.242,14.2,0.089,0.296,75.8,14.3,5,2.8,355,1,1.1,1.2,29521
"Cereals ready-to-eat, KASHI MIGHTY BITES, Honey Crunch Cereal",Breakfast Cereals,17,0.455,0.482,9,0.046,0.33,69,17,4,2.8,353,0.8,1.6,1.6,29522
"Cereals ready-to-eat, KASHI ORGANIC PROMISE CINNAMON HARVEST",Breakfast Cereals,8.3,0,0.01,10.3,0,0.313,81,16.5,2,5.9,340,0.4,0.4,1.2,29523
"Cereals ready-to-eat, KASHI ORGANIC PROMISE Island Vanilla Biscuit",Breakfast Cereals,10.7,0,0.01,10.7,0,0.31,80.8,16.9,2,2.5,350,0,0.3,1.1,29524
"Cereals ready-to-eat, KASHI ORGANIC PROMISE STRAWBERRY FIELDS",Breakfast Cereals,7.8,0.016,0.625,3.5,0.0225,0.122,87.1,29.3,0.43,2.4,369,0,0,0,29525
"Cereals ready-to-eat, KASHI, CINNA-RAISIN CRUNCH",Breakfast Cereals,7.9,0.032,0.208,15.1,0,0.442,82,25,2.8,5,330,0.4,1,1.4,29526
"Cereals ready-to-eat, KASHI, HEART TO HEART, Wild Blueberry",Breakfast Cereals,10.9,0.045,0.241,7,0.0545,0.211,76,22,4.7,3,370,0.9,1.2,2.6,29527
"Cereals ready-to-eat, KASHI, Kashi U",Breakfast Cereals,10,0.364,0.23,12,0.055,0.22,76,18,7,3,367,0.5,2.2,3.7,29528
"Cereals ready-to-eat, KASHI, ORGANIC PROMISE Autumn Wheat",Breakfast Cereals,9.7,0,0,11.2,0,0.334,83,13.4,2.1,3.9,353,0.202,0.154,0.482,29529
"Cereals ready-to-eat, KELLOGG'S FROSTED MINI-WHEATS, original",Breakfast Cereals,9,0.033,0.01,10,0,0.352,82,21,1.6,6,343,0.36,0.24,1,29530
"Cereals ready-to-eat, KELLOGG'S SPECIAL K Chocolatey Delight",Breakfast Cereals,7,0.018,0.579,7,0.068,0.268,81,30,7,3,393,6,0.2,0.6,29531
"Cereals ready-to-eat, KELLOGG'S, FROSTED MINI-WHEATS, Maple & Brown Sugar, Bite Size",Breakfast Cereals,8,0.033,0.001,9.6,0,0.338,82,24,1.5,6,356,0.3,0.2,1,29532
"Cereals ready-to-eat, KELLOGG'S, FRUIT HARVEST Strawberry/Blueberry",Breakfast Cereals,6,0.026,0.472,4.6,0.004,0.284,87,34,1.2,3,368,0.3,0.3,0.6,29533
"Cereals ready-to-eat, KELLOGG'S, Reduced Sugar FROOT LOOPS",Breakfast Cereals,6.3,0.017,0.56,10.3,0.047,0.07,83.8,30.9,4,2.5,394,1.5,0.4,0.5,29534
"Cereals ready-to-eat, KELLOGG'S, Reduced Sugar Frosted Flakes Cereal",Breakfast Cereals,5.3,0.006,0.57,1.1,0.023,0.083,90.2,27,0.17,3,377,0.05,0.03,0.09,29535
"Cereals ready-to-eat, KELLOGG'S, SPECIAL K Low Carb Lifestyle Protein Plus",Breakfast Cereals,33.7,0.135,0.38,17,0.072,1.111,47.8,6.7,9.7,3,349,2,2.3,5.4,29536
"Cereals ready-to-eat, KELLOGG'S, SPECIAL K Vanilla Almond",Breakfast Cereals,7,0.038,0.568,5.6,0.07,0.232,83,28,4.9,2.3,384,0.6,2.8,1.5,29537
"Cereals ready-to-eat, KELLOGG's MINI-WHEATS Frosted Strawberry Delight Cereal",Breakfast Cereals,8.3,0.031,0,9.6,0,0.33,83,23.5,1.7,6,347,0.3,0.3,1.1,29538
"Cereals ready-to-eat, KELLOGG, All-Bran Strawberry Medley",Breakfast Cereals,10,0.018,0.42,18,0.011,0.64,80,19,3,4.6,318,0.5,0.4,2,29539
"Cereals ready-to-eat, KELLOGG, Berry RICE KRISPIES",Breakfast Cereals,5.5,0.008,0.726,0.3,0.02,0.101,88,31,1,2.9,382,0.3,0.3,0.4,29540
"Cereals ready-to-eat, KELLOGG, EGGO Crunch Cereal, maple flavored",Breakfast Cereals,6.6,0.167,0.472,5.9,0.0455,0.212,83,40,4.4,2.5,376,1.5,1.6,1.3,29541
"Cereals ready-to-eat, KELLOGG, Frosted MINI-WHEATS bite size STRAWBERRY DELIGHT",Breakfast Cereals,8.3,0.031,0,9.6,0,0,83,23.5,1.7,6,347,0.3,0.3,1.1,29542
"Cereals ready-to-eat, KELLOGG, KELLOGG'S ALL-BRAN BRAN BUDS",Breakfast Cereals,7,0.063,0.68,43,0.02,1,80,27,2.15,3,250,0.4,0.5,1.3,29543
"Cereals ready-to-eat, KELLOGG, KELLOGG'S ALL-BRAN Original",Breakfast Cereals,13.14,0.389,0.26,29.3,0.02,1.02,74.24,15.69,4.9,2.12,260,0.63,0.67,2.11,29544
"Cereals ready-to-eat, KELLOGG, KELLOGG'S ALL-BRAN WITH EXTRA FIBER",Breakfast Cereals,11.3,0.415,0.475,50,0.025,1.05,77,0.37,3.5,3,192,0.6,0.7,2.2,29545
"Cereals ready-to-eat, KELLOGG, KELLOGG'S ALL-BRAN Yogurt Bites",Breakfast Cereals,11,0.064,0.42,18,0.011,0.521,78.8,13,5.24,2.32,342,3,0.6,1.4,29546
"Cereals ready-to-eat, KELLOGG, KELLOGG'S APPLE JACKS",Breakfast Cereals,5,0.011,0.443,9.3,0.0594,0.12,88.2,44,2,2.98,366,0.375,0.531,0.734,29547
"Cereals ready-to-eat, KELLOGG, KELLOGG'S COCOA KRISPIES",Breakfast Cereals,5.22,0.13,0.424,1.9,0.0484,0.189,86.05,33.96,2.9,2.81,381,1.99,0.4,0.23,29548
"Cereals ready-to-eat, KELLOGG, KELLOGG'S COMPLETE Wheat Flakes",Breakfast Cereals,10,0.052,0.72,17.5,0.207,0.59,79,17,2,3,318,0.4,0.5,1.1,29549
"Cereals ready-to-eat, KELLOGG, KELLOGG'S CORN POPS",Breakfast Cereals,4.6,0.011,0.387,9,0.019,0.148,90.4,32.1,1.3,2.5,389,0.18,0.2,0.5,29550
"Cereals ready-to-eat, KELLOGG, KELLOGG'S CRACKLIN' OAT BRAN",Breakfast Cereals,8,0.06,0.309,12.7,0.031,0.45,71.8,32,13.9,3,402,6.1,4.7,3,29551
"Cereals ready-to-eat, KELLOGG, KELLOGG'S CRISPIX",Breakfast Cereals,6.7,0.01,0.751,1.1,0.021,0.135,85.7,12.1,1,3.35,377,0.225,0.225,0.45,29552
"Cereals ready-to-eat, KELLOGG, KELLOGG'S Cinnamon MINI SWIRLZ",Breakfast Cereals,5.7,0.024,0.386,3.4,0.05,0.151,83,11,6.9,1.8,406,1.1,2.6,1.9,29553
"Cereals ready-to-eat, KELLOGG, KELLOGG'S Complete Oat Bran Flakes",Breakfast Cereals,11,0.054,0.699,13,0.21,0.4,77,20,3.5,3,350,0.8,1.7,1,29554
"Cereals ready-to-eat, KELLOGG, KELLOGG'S Corn Flakes",Breakfast Cereals,6.61,0.004,0.723,2.5,0.022,0.079,87.11,10.5,0.59,3.76,361,0.172,0.099,0.289,29555
"Cereals ready-to-eat, KELLOGG, KELLOGG'S FROOT LOOPS",Breakfast Cereals,5.1,0.012,0.456,9.5,0.046,0.143,86.6,42.5,3.6,3,373,2,0.3,0.4,29556
"Cereals ready-to-eat, KELLOGG, KELLOGG'S FROSTED FLAKES",Breakfast Cereals,4.34,0.003,0.462,1.8,0.026699999999999998,0.075,90.86,38.72,0.26,3.27,367,0.069,0.04,0.102,29557
"Cereals ready-to-eat, KELLOGG, KELLOGG'S FROSTED MINI-WHEATS, bite size",Breakfast Cereals,10.1,0.032,0.01,10,0,0.345,81,20.1,1.6,6,344,0.36,0.24,1,29558
"Cereals ready-to-eat, KELLOGG, KELLOGG'S FROSTED RICE KRISPIES",Breakfast Cereals,5,0.007,0.371,0.3,0.05,0.059,89,39,0.9,2.9,384,0.3,0.3,0.3,29559
"Cereals ready-to-eat, KELLOGG, KELLOGG'S HONEY CRUNCH CORN FLAKES",Breakfast Cereals,6.8,0.022,0.7,3.4,0.02,0.102,87,33,1.9,3,385,0.3,1,0.6,29560
"Cereals ready-to-eat, KELLOGG, KELLOGG'S Honey SMACKS",Breakfast Cereals,6,0.024,0.19,3.7,0.022600000000000002,0.15,89,56,2,2.5,388,0.3,0.65,0.9,29561
"Cereals ready-to-eat, KELLOGG, KELLOGG'S JUST RIGHT Fruit & Nut",Breakfast Cereals,7,0.035,0.459,5.2,0,0.269,81.7,25,3.3,5.9,366,0.5,1.5,1.3,29562
"Cereals ready-to-eat, KELLOGG, KELLOGG'S Low Fat Granola with Raisins",Breakfast Cereals,9,0.032,0.24,7,0.002,0.2,79,28,5,3.5,377,1.4,2,1.6,29563
"Cereals ready-to-eat, KELLOGG, KELLOGG'S Low Fat Granola without Raisins",Breakfast Cereals,8,0.039,0.219,6.1,0.002,0.249,81.5,28,5.2,3.5,387,1.3,2.2,1.7,29564
"Cereals ready-to-eat, KELLOGG, KELLOGG'S MARSHMALLOW FROOT LOOPS",Breakfast Cereals,4.5,0.01,0.383,6.8,0.038,0.116,87.9,50.7,3,2.5,374,1.3,0.2,0.3,29565
"Cereals ready-to-eat, KELLOGG, KELLOGG'S MUESLIX",Breakfast Cereals,9.1,0.058,0.309,7.3,0.0004,0.436,73,31,5.5,8.7,357,0.8,2.9,1.8,29566
"Cereals ready-to-eat, KELLOGG, KELLOGG'S PRODUCT 19",Breakfast Cereals,7.7,0.016,0.69,3.3,0.204,0.167,83,13.3,1.4,3,333,0.3,0.4,0.7,29567
"Cereals ready-to-eat, KELLOGG, KELLOGG'S Puffed Wheat",Breakfast Cereals,15,0.04,0.003,15,0,0.475,77,0,1.6,4,322,0.3,0.6,0.7,29568
"Cereals ready-to-eat, KELLOGG, KELLOGG'S RAISIN BRAN",Breakfast Cereals,8.59,0.047,0.425,11.1,0,0.567,77.36,29.9,2.19,8.77,322,0.353,0.291,0.73,29569
"Cereals ready-to-eat, KELLOGG, KELLOGG'S RICE KRISPIES",Breakfast Cereals,6.84,0.007,0.561,0.8,0.047,0.088,85.24,9.46,0.96,4.13,387,0.216,0.279,0.295,29570
"Cereals ready-to-eat, KELLOGG, KELLOGG'S RICE KRISPIES TREATS Cereal",Breakfast Cereals,4.8,0.01,0.55,0.2,0.02,0.077,84.7,31,4.6,3.5,399,0.9,1.6,0.6,29571
"Cereals ready-to-eat, KELLOGG, KELLOGG'S SMART START Strong Heart Antioxidants Cereal",Breakfast Cereals,7,0.024,0.55,5.4,0.03,0.18,86,28,1.41,3,365,0.27,0.17,0.97,29572
"Cereals ready-to-eat, KELLOGG, KELLOGG'S SPECIAL K",Breakfast Cereals,18,0.025,0.658,8.5,0.0677,0.066,74,12.9,2,3,379,0.35,0.4,0.8,29573
"Cereals ready-to-eat, KELLOGG, KELLOGG'S SPECIAL K RED BERRIES",Breakfast Cereals,6,0.018,0.613,8,0.0677,0.223,86,30,1,3,359,0.24,0.2,0.6,29574
"Cereals ready-to-eat, KELLOGG, KELLOGG'S Shredded Wheat Miniatures",Breakfast Cereals,11,0.042,0,13,0,0.452,80,2,2.1,5,339,0.4,0.3,1.4,29575
"Cereals ready-to-eat, KELLOGG, KELLOGG'S Special K Blueberry",Breakfast Cereals,7,0.018,0.471,10,0.07,0.197,87,28,1.5,3,363,0,0.1,0.5,29576
"Cereals ready-to-eat, KELLOGG, KELLOGG'S Special K low fat granola",Breakfast Cereals,13.1,0.04,0.24,11.7,0,0.23,75.1,17.5,6,3,380,1,1.7,1.8,29577
"Cereals ready-to-eat, KELLOGG, KELLOGG'S Special K, Cinnamon Pecan",Breakfast Cereals,8,0.03,0.649,8,0.07,0.264,81,24,6,3,384,1,0.1,0.6,29578
"Cereals ready-to-eat, KELLOGG, KELLOGG'S, RAISIN BRAN CRUNCH",Breakfast Cereals,6.1,0.035,0.39,7.6,0.0013,0.401,84.9,37.7,1.9,5.3,354,0.38,0.7,0.8,29579
"Cereals ready-to-eat, KELLOGG, SMART START Strong Heart, Maple Brown Sugar",Breakfast Cereals,10,0.036,0.23,8.5,0.025,0.633,79,28,3.7,3.5,367,0.7,1.1,1.9,29580
"Cereals ready-to-eat, KELLOGG, SMORZ",Breakfast Cereals,4.9,0.022,0.46,2.5,0.05,0.097,83,43,6.9,2.7,406,1.8,1,3.9,29581
"Cereals ready-to-eat, KELLOGG, SPECIAL K, Fruit & Yogurt",Breakfast Cereals,7,0.027,0.456,4.7,0.066,0.184,85,33,2.9,3,373,1.5,0.2,1.2,29582
"Cereals ready-to-eat, KELLOGG, Smart Start Strong Heart, Original",Breakfast Cereals,10,0.035,0.234,8.9,0.025,0.632,79,28,3.8,3.5,366,0.7,1.1,2,29583
"Cereals ready-to-eat, MALT-O-MEAL, Apple ZINGS",Breakfast Cereals,5.4,0.429,0.455,2.8,0.0597,0.14,87.3,42.9,2.66,2.2,395,0.447,1.26,0.835,29584
"Cereals ready-to-eat, MALT-O-MEAL, BERRY COLOSSAL CRUNCH",Breakfast Cereals,4.89,0.014,0.733,3.3,0.0036,0.114,85.9,41.3,5.44,1.56,412,0.091,1.4,0.63,29585
"Cereals ready-to-eat, MALT-O-MEAL, Blueberry Muffin Tops Cereal",Breakfast Cereals,4.9,0.356,0.466,4.8,0.0354,0.158,80,35.4,11.5,1.1,443,1.84,4.49,4.63,29586
"Cereals ready-to-eat, MALT-O-MEAL, CINNAMON TOASTERS",Breakfast Cereals,5.8,0.388,0.461,4.7,0.021,0.15,79.1,34.8,10.1,2.4,431,1.67,3.91,4.07,29587
"Cereals ready-to-eat, MALT-O-MEAL, COCO-ROOS",Breakfast Cereals,3.7,0.406,0.45,1.8,0.0205,0.195,89.5,46.5,2.6,2,396,0.464,1.61,0.419,29588
"Cereals ready-to-eat, MALT-O-MEAL, COLOSSAL CRUNCH",Breakfast Cereals,4.2,0.006,0.766,0.7,0,0.089,86.6,40.65,5.48,1.25,415,0.88,1.43,0.46,29589
"Cereals ready-to-eat, MALT-O-MEAL, CORN BURSTS",Breakfast Cereals,4,0.005,0.871,1.8,0.0605,0.059,93.7,43.6,0.34,0.9,394,0.165,0.042,0.113,29590
"Cereals ready-to-eat, MALT-O-MEAL, Cocoa DYNO-BITES",Breakfast Cereals,4.1,0.008,0.517,1.2,0.001,0.179,89.4,45.2,3.06,1.5,402,2.43,0.256,0.211,29591
"Cereals ready-to-eat, MALT-O-MEAL, Crispy Rice",Breakfast Cereals,6.3,0.007,0.909,1,0.0621,0.124,86.4,9,1.13,3.4,381,0.465,0.251,0.358,29592
"Cereals ready-to-eat, MALT-O-MEAL, Frosted Flakes",Breakfast Cereals,5,0,0.573,1.9,0.0615,0.067,90.9,37.4,0.4,2.1,387,0.13,0.07,0.19,29593
"Cereals ready-to-eat, MALT-O-MEAL, Frosted Mini SPOONERS",Breakfast Cereals,9.4,0.027,0.018,10.5,0.001,0.34,82.9,17.9,2.08,4.4,388,0.393,0.367,1.23,29594
"Cereals ready-to-eat, MALT-O-MEAL, Fruity DYNO-BITES",Breakfast Cereals,3.9,0.003,0.63,1,0.001,0.076,90.1,42.6,3.15,1.2,404,0.64,2.12,0.258,29595
"Cereals ready-to-eat, MALT-O-MEAL, GOLDEN PUFFS",Breakfast Cereals,5.77,0.011,0.241,2.6,0.027600000000000003,0.154,89.72,51.34,0.89,2.76,397,0.161,0.22,0.349,29596
"Cereals ready-to-eat, MALT-O-MEAL, Honey BUZZERS",Breakfast Cereals,6.3,0.014,0.759,2.9,0.0019,0.12,87.6,37.4,2.14,1.88,395,0.65,0.55,0.65,29597
"Cereals ready-to-eat, MALT-O-MEAL, Honey Nut TOASTY O'S Cereal",Breakfast Cereals,9.63,0.397,0.7,5.6,0.030600000000000002,0.309,81.53,38.73,2.16,1.9,390,0.435,0.831,0.871,29598
"Cereals ready-to-eat, MALT-O-MEAL, MARSHMALLOW MATEYS",Breakfast Cereals,7.2,0.457,0.667,4.6,0.0297,0.197,83.3,42.8,3.6,1.7,394,0.719,1.42,1.3,29599
"Cereals ready-to-eat, MALT-O-MEAL, Maple & Brown Sugar Hot Wheat Cereal, dry",Breakfast Cereals,8,0.314,0.01,3.1,0.001,0.13,84.5,35.6,0.75,5.9,377,0.19,0.096,0.428,29600
"Cereals ready-to-eat, MALT-O-MEAL, Puffed Rice Cereal",Breakfast Cereals,7.8,0.005,0.005,1,0.001,0.103,90.2,0.8,0.97,0.6,401,0.297,0.255,0.377,29601
"Cereals ready-to-eat, MALT-O-MEAL, Puffed Wheat Cereal",Breakfast Cereals,15.6,0.025,0.01,7.3,0.001,0.412,78.8,1.1,2.03,2.1,396,0.442,0.353,1.15,29602
"Cereals ready-to-eat, MALT-O-MEAL, Raisin Bran Cereal",Breakfast Cereals,9,0.045,0.576,13.5,0.013,0.578,76.9,30.3,1.98,8.5,361,0.476,0.401,1.01,29603
"Cereals ready-to-eat, MALT-O-MEAL, TOASTY O'S",Breakfast Cereals,13.1,0.406,0.897,10.7,0.0205,0.317,74.6,3.9,5.92,2,404,1.23,2.16,2.27,29604
"Cereals ready-to-eat, MALT-O-MEAL, TOOTIE FRUITIES",Breakfast Cereals,5,0.407,0.464,2.5,0.0703,0.131,87.9,45.7,3.29,1.5,401,0.687,1.47,0.976,29605
"Cereals ready-to-eat, MUESLI, dried fruit and nuts",Breakfast Cereals,9.7,0,0.231,7.3,0,0.486,77.8,31,4.9,5.8,340,0.805,2.38,1.315,29606
"Cereals ready-to-eat, Malt-O-Meal Honey Graham Squares",Breakfast Cereals,3.33,0.333,0.9,3.3,0.02,0.167,80.33,33.33,10,3.6,433,1.667,3.333,3.333,29607
"Cereals ready-to-eat, Marshmallow ALPHA-BITS",Breakfast Cereals,5.9,0.014,0.711,1.7,0,0.1,86.7,43.5,3.3,1.5,398,0.6,1.015,1.209,29608
"Cereals ready-to-eat, NATURE'S PATH, OPTIMUM",Breakfast Cereals,14.55,0.455,0.418,18.2,0.0022,0.751,73.37,29.09,4.55,3.67,345,0,0.962,3.11,29609
"Cereals ready-to-eat, NATURE'S PATH, OPTIMUM SLIM",Breakfast Cereals,16.36,0.455,0.527,20,0,0.956,69.09,18.18,4.55,4.07,327,0,1.13,2.903,29610
"Cereals ready-to-eat, OAT BRAN FLAKES, HEALTH VALLEY",Breakfast Cereals,10.7,0.038,0.036,13,0.0004,0.321,78.6,19.47,1.4,3.4,353,0.251,0.431,0.529,29611
"Cereals ready-to-eat, POST Banana Nut Crunch",Breakfast Cereals,8.5,0.036,0.39,6.8,0.0002,0.29,74,20.4,10.3,4.5,422,1.4,3.667,4.485,29612
"Cereals ready-to-eat, POST Blueberry Morning",Breakfast Cereals,6.6,0.027,0.473,3.8,0,0.166,79.2,20.8,4.5,7.2,383,0.6,2.008,1.466,29613
"Cereals ready-to-eat, POST Raisin Bran Cereal",Breakfast Cereals,9.33,0.054,0.424,12.1,0.008,0.613,76.84,28.06,2.5,8.13,317,0.39,0.46,1.05,29614
"Cereals ready-to-eat, POST Selects Cranberry Almond Crunch",Breakfast Cereals,7.4,0.029,0.225,6,0.0263,0.228,79.06,25.89,5.55,6,386,0,2.974,1.778,29615
"Cereals ready-to-eat, POST TOASTIES corn flakes",Breakfast Cereals,6.7,0.003,0.714,4.5,0,0.117,86.7,6.5,0.1,3.5,360,0,0.03,0.051,29616
"Cereals ready-to-eat, POST, 100% Bran Cereal",Breakfast Cereals,12.7,0.076,0.417,28.6,0,0.947,78.2,24.4,2.1,2.8,287,0.3,0.316,1.101,29617
"Cereals ready-to-eat, POST, ALPHA-BITS",Breakfast Cereals,8.5,0.03,0.571,4.1,0,0.194,83.5,39,4.1,1.3,405,0.8,1.175,1.487,29618
"Cereals ready-to-eat, POST, COCOA PEBBLES",Breakfast Cereals,3.5,0.012,0.633,1.6,0,0.146,87.9,44,4.2,2.9,398,3.7,0.131,0.128,29619
"Cereals ready-to-eat, POST, FRUITY PEBBLES",Breakfast Cereals,3.6,0.005,0.633,0.7,0,0.11,87.9,44,3.9,3,401,0.9,1.632,1.294,29620
"Cereals ready-to-eat, POST, GRAPE-NUTS Cereal",Breakfast Cereals,12.48,0.039,0.5,8.8,0,0.338,80.1,12.61,1.85,3,359,0.305,0.261,0.871,29621
"Cereals ready-to-eat, POST, GRAPE-NUTS Flakes",Breakfast Cereals,10,0.039,0.431,8.8,0,0.34,81.5,17.6,2.9,3.2,365,0.6,0,0,29622
"Cereals ready-to-eat, POST, GREAT GRAINS Crunchy Pecan Cereal",Breakfast Cereals,9.3,0.028,0.403,7,0.0002,0.321,71.4,15.3,11.8,5.8,408,1.4,0,0,29623
"Cereals ready-to-eat, POST, Golden Crisp",Breakfast Cereals,5.5,0.015,0.093,0,0,0.125,90.9,53.9,1.4,2.9,398,0.2,0.45,0.553,29624
"Cereals ready-to-eat, POST, Great Grains, Raisin, Date & Pecan",Breakfast Cereals,8,0.031,0.296,7.4,0.0001,0.326,73.2,24.6,8.4,8.7,377,1.1,3.664,2.953,29625
"Cereals ready-to-eat, POST, HONEY BUNCHES OF OATS, honey roasted",Breakfast Cereals,7.33,0.023,0.5,4.8,0,0.185,82.86,24.66,4.25,3.4,394,0.374,1.901,1.215,29626
"Cereals ready-to-eat, POST, Honey Bunches of Oats, with almonds",Breakfast Cereals,7.8,0.037,0.438,4.5,0.0001,0.227,78,21,8.5,3,407,1.1,4.176,2.737,29627
"Cereals ready-to-eat, POST, Honey Nut Shredded Wheat",Breakfast Cereals,9.82,0.027,0.135,7.7,0,0.253,81.22,23.08,3.77,3.82,385,0.469,1.759,1.196,29628
"Cereals ready-to-eat, POST, Honeycomb Cereal",Breakfast Cereals,5.2,0.017,0.563,2.5,0,0.12,88.9,38.3,2.1,1.5,395,0.6,0.49,0.69,29629
"Cereals ready-to-eat, POST, OREO O's Cereal",Breakfast Cereals,4.8,0.019,0.475,5.5,0,0.183,81.9,47,8.8,2.5,415,1.6,3.346,3.304,29630
"Cereals ready-to-eat, POST, Shredded Wheat n' Bran, spoon-size",Breakfast Cereals,12.5,0.045,0.005,13.4,0,0.42,79.9,1,1.4,4.5,334,0.2,0.173,0.634,29631
"Cereals ready-to-eat, POST, Shredded Wheat, bite-size",Breakfast Cereals,7.8,0.013,0.019,9.6,0,0.327,83.8,22.3,1.9,6,352,0.3,0.271,0.925,29632
"Cereals ready-to-eat, POST, Shredded Wheat, spoon-size",Breakfast Cereals,10.3,0.043,0.007,11.4,0,0.415,83,0.9,1.1,4.1,340,0.2,0.119,0.439,29633
"Cereals ready-to-eat, Puffed KASHI",Breakfast Cereals,13,0.038,0.01,8,0,0.28,79.4,3.2,2.5,3,336,0.509,0.679,1.103,29634
"Cereals ready-to-eat, QUAKER, 100% Natural Granola Oats and Honey",Breakfast Cereals,10.38,0.117,0.049,6.9,0.0002,0.463,72.64,25.79,12.86,2.21,429,7.94,2.64,1.69,29635
"Cereals ready-to-eat, QUAKER, CAP'N CRUNCH",Breakfast Cereals,4.37,0.011,0.782,2.5,0,0.185,84.78,43.84,5.89,2.5,402,4.2,0.7,0.79,29636
"Cereals ready-to-eat, QUAKER, CAP'N CRUNCH with CRUNCHBERRIES",Breakfast Cereals,4.41,0.014,0.715,2.5,0.0001,0.188,85.19,44.73,5.64,2.5,402,3.91,0.72,0.8,29637
"Cereals ready-to-eat, QUAKER, CAP'N CRUNCH'S PEANUT BUTTER CRUNCH",Breakfast Cereals,7.1,0.009,0.742,2.7,0,0.237,78.72,33.12,9.16,2.5,416,4.01,2.63,2.16,29638
"Cereals ready-to-eat, QUAKER, HONEY GRAHAM OH!S",Breakfast Cereals,4.09,0.01,0.612,2,0.0294,0.164,83.61,42.97,7.45,2.75,411,5.79,0.76,0.79,29639
"Cereals ready-to-eat, QUAKER, Honey Graham bagged cereal",Breakfast Cereals,5.6,0.035,0.825,2.8,0.023600000000000003,0.234,83.64,38.37,5.24,2.5,395,1.31,0.81,0.7,29640
"Cereals ready-to-eat, QUAKER, Honey Nut Oats",Breakfast Cereals,6.04,0.027,0.805,4.3,0.023600000000000003,0.191,84.53,38.64,3.65,2.5,385,0.63,1.44,0.95,29641
"Cereals ready-to-eat, QUAKER, KING VITAMAN",Breakfast Cereals,6.54,0.01,0.837,3.5,0.04,0.274,85,20.18,3.47,2,386,1.58,0.65,1.14,29642
"Cereals ready-to-eat, QUAKER, KRETSCHMER Honey Crunch Wheat Germ",Breakfast Cereals,26.55,0.05,0.011,10.2,0,0.964,58.11,24.85,7.78,3.4,372,1.36,1.04,4.84,29643
"Cereals ready-to-eat, QUAKER, KRETSCHMER Toasted Wheat Bran",Breakfast Cereals,17.56,0.066,0.006,41.3,0,1.284,59.51,0,5.16,11.72,200,0.77,0.8,2.72,29644
"Cereals ready-to-eat, QUAKER, KRETSCHMER Wheat Germ, Regular",Breakfast Cereals,31.43,0.05,0.006,11.9,0.006,1.097,49.38,0,9.56,4.78,366,1.66,1.25,5.98,29645
"Cereals ready-to-eat, QUAKER, Low Fat 100% Natural Granola with Raisins",Breakfast Cereals,7.87,0.064,0.253,5.6,0.0007,0.412,81.12,33.6,5.54,3.5,390,2.57,1.37,1.08,29646
"Cereals ready-to-eat, QUAKER, MOTHER'S CINNAMON OAT CRUNCH",Breakfast Cereals,10.49,0.074,0.419,8.3,0.0001,0.537,79.21,25.07,4.64,2.5,380,0.79,1.45,1.41,29647
"Cereals ready-to-eat, QUAKER, MOTHER'S COCOA BUMPERS",Breakfast Cereals,5.08,0.139,0.473,3.1,0,0.787,87.64,41.84,1.74,2.5,376,0.31,0.31,0.63,29648
"Cereals ready-to-eat, QUAKER, MOTHER'S GROOVY GRAHAMS",Breakfast Cereals,5.44,0.138,0.823,3,0,0.755,86.38,44.82,1.63,2.5,372,0.28,0.32,0.69,29649
"Cereals ready-to-eat, QUAKER, MOTHER'S HONEY ROUNDUP",Breakfast Cereals,5.44,0.023,0.587,3.1,0,0.237,87.77,35.68,2,2.5,382,0.38,0.56,0.82,29650
"Cereals ready-to-eat, QUAKER, MOTHER'S PEANUT BUTTER BUMPERS Cereal",Breakfast Cereals,8.28,0.1,0.774,2.9,0,0.648,78.27,29.51,7.34,2.5,403,1.44,3.08,2.23,29651
"Cereals ready-to-eat, QUAKER, MOTHER'S TOASTED OAT BRAN CEREAL, Brown Sugar Flavor",Breakfast Cereals,11.9,0.066,0.632,8.9,0,0.49,74.92,15.5,5.17,4,373,0.89,1.63,1.82,29652
"Cereals ready-to-eat, QUAKER, Oatmeal Cereal, Brown Sugar Bliss",Breakfast Cereals,8.78,0.298,0.509,7.4,0.0032,0.327,79.69,0,5.56,2.5,384,1.16,1.69,1.36,29653
"Cereals ready-to-eat, QUAKER, QUAKER 100% Natural Cereal with oats, honey, and raisins",Breakfast Cereals,9.42,0.108,0.249,6.5,0.0005,0.485,73.88,29.87,11.33,3.54,417,6.97,2.34,1.49,29654
"Cereals ready-to-eat, QUAKER, QUAKER APPLE ZAPS",Breakfast Cereals,3.68,0.009,0.449,2.3,0.022,0.175,88.74,46.99,3.45,2.5,393,0.87,0.6,0.62,29655
"Cereals ready-to-eat, QUAKER, QUAKER CINNAMON OATMEAL SQUARES",Breakfast Cereals,10.49,0.195,0.439,8.2,0.011,0.358,79.21,21.19,4.48,2.5,379,0.8,1.47,1.42,29656
"Cereals ready-to-eat, QUAKER, QUAKER COCOA BLASTS",Breakfast Cereals,3.67,0.007,0.409,2.5,0.02,0.189,88.7,48.29,3.54,2.5,394,0.95,0.64,0.59,29657
"Cereals ready-to-eat, QUAKER, QUAKER CRUNCHY BRAN",Breakfast Cereals,6.4,0.071,0.868,17.3,0,0.239,84.11,20.75,4.07,2,333,2.033,0.761,1.204,29658
"Cereals ready-to-eat, QUAKER, QUAKER FRUITANGY OH!S",Breakfast Cereals,4.87,0.009,0.491,2.6,0.04,0.189,87.21,43.25,3.5,2.5,392,0.92,1.73,0.85,29659
"Cereals ready-to-eat, QUAKER, QUAKER Honey Graham LIFE Cereal",Breakfast Cereals,9.33,0.366,0.488,6.3,0,0.269,78.56,23.12,4.14,4.3,373,0.77,1.4,1.34,29660
"Cereals ready-to-eat, QUAKER, QUAKER MARSHMALLOW SAFARI",Breakfast Cereals,5.79,0.087,0.64,4.2,0.04,0.139,84.27,47.27,5.02,2.1,396,1.25,2.5,1.25,29661
"Cereals ready-to-eat, QUAKER, QUAKER OAT CINNAMON LIFE",Breakfast Cereals,8.93,0.35,0.479,6.2,0.0001,0.258,79.27,26.12,4.01,4.3,373,0.75,1.33,1.28,29662
"Cereals ready-to-eat, QUAKER, QUAKER OAT LIFE, plain",Breakfast Cereals,9.89,0.349,0.549,6.6,0,0.285,77.81,19.48,4.39,4.3,373,0.82,1.49,1.42,29663
"Cereals ready-to-eat, QUAKER, QUAKER OATMEAL SQUARES",Breakfast Cereals,11.04,0.201,0.447,7.1,0.0114,0.366,78.34,16.1,4.32,2.75,378,0.9,1.45,1.76,29664
"Cereals ready-to-eat, QUAKER, QUAKER Oat Bran Cereal",Breakfast Cereals,12.38,0.191,0.364,9.9,0.0116,0.438,74.89,16.34,5.11,4,372,0.91,1.57,2.04,29665
"Cereals ready-to-eat, QUAKER, QUAKER Puffed Rice",Breakfast Cereals,7,0.009,0.005,1.4,0,0.116,87.77,0,0.9,3.91,383,0.32,0.18,0.34,29666
"Cereals ready-to-eat, QUAKER, QUAKER Puffed Wheat",Breakfast Cereals,16.26,0.024,0.005,9.4,0,0.364,76.39,1.36,2.15,3.69,366,0.41,0.31,1.07,29667
"Cereals ready-to-eat, QUAKER, QUAKER SWEET PUFFS",Breakfast Cereals,6.76,0.01,0.236,3.4,0,0.148,87.99,47.47,1.93,2.22,391,0.36,0.43,0.93,29668
"Cereals ready-to-eat, QUAKER, QUAKER Toasted Oatmeal Cereal",Breakfast Cereals,9.38,0.357,0.56,5.6,0.0040999999999999995,0.234,80.39,23.76,4.22,2.5,383,1.27,1.24,1.17,29669
"Cereals ready-to-eat, QUAKER, QUAKER toasted Oatmeal Cereal, Honey Nut",Breakfast Cereals,9.01,0.337,0.465,5.5,0.0039,0.227,81.24,25.03,4.07,2.5,384,1.25,1.19,1.12,29670
"Cereals ready-to-eat, QUAKER, SUN COUNTRY Granola with Almonds",Breakfast Cereals,11.77,0.086,0.033,5.2,0.0001,0.388,67.2,20.42,18.02,1.37,467,2.22,5.84,3.18,29671
"Cereals ready-to-eat, QUAKER, SWEET CRUNCH/QUISP",Breakfast Cereals,4.5,0.009,0.74,2.3,0,0.187,85.03,43.36,6.07,2,405,4.44,0.69,0.85,29672
"Cereals ready-to-eat, QUAKER, Shredded Wheat, bagged cereal",Breakfast Cereals,11.09,0.045,0.004,11.6,0,0.354,79.93,0.8,1.98,5.46,343,0.4,0.3,1.2,29673
"Cereals ready-to-eat, QUAKER, Toasted Oats/OATMMM'S",Breakfast Cereals,9.23,0.041,0.953,6.6,0.022,0.288,77.91,7.99,5.1,3.5,380,0.91,1.77,1.46,29674
"Cereals ready-to-eat, Ralston Corn Biscuits",Breakfast Cereals,5.83,0.378,1.07,2.6,0.0185,0.09,85.79,9.51,1.05,3.59,367,0.193,0.133,0.347,29675
"Cereals ready-to-eat, Ralston Corn Flakes",Breakfast Cereals,5.9,0.002,0.71,2.7,0.065,0.086,88.01,7.84,0.91,3.31,357,0.227,0.107,0.293,29676
"Cereals ready-to-eat, Ralston Crispy Hexagons",Breakfast Cereals,5.94,0.008,0.816,1.5,0.0438,0.099,86.78,9.57,0.95,4.07,379,0.263,0.193,0.35,29677
"Cereals ready-to-eat, Ralston Crispy Rice",Breakfast Cereals,6.69,0.005,0.763,0.7,0.0645,0.11,86.22,7.65,1.26,3.62,364,0.36,0.243,0.293,29678
"Cereals ready-to-eat, Ralston Enriched Bran flakes",Breakfast Cereals,10.21,0.045,0.813,16.9,0.2397,0.63,79.77,17.17,3.36,2.53,310,0.433,0.443,1.383,29679
"Cereals ready-to-eat, Ralston TASTEEOS",Breakfast Cereals,11.83,0.357,0.853,10.7,0.0149,0.307,74.22,4.64,5.97,3.68,398,1.023,1.746,1.87,29680
"Cereals ready-to-eat, UNCLE SAM CEREAL",Breakfast Cereals,15.98,0.095,0.206,20.3,0.0615,0.446,65.78,1.56,11.6,4.43,431,1.32,1.98,8.35,29681
"Cereals ready-to-eat, USDA Commodity Corn and Rice (includes all commodity brands)",Breakfast Cereals,6.06,0.01,0.795,1.4,0.0385,0.105,86.85,10.35,1.08,3.78,378,0.26,0.204,0.354,29682
"Cereals ready-to-eat, USDA Commodity Rice Crisps (includes all commodity brands)",Breakfast Cereals,6.45,0.005,0.85,0.7,0.046,0.108,86.04,8.35,1.27,3.85,381,0.355,0.253,0.325,29683
"Cereals ready-to-eat, WAFFELOS",Breakfast Cereals,5.6,0.028,0.416,0,0.053,0.088,86.3,0,4.2,2.5,405,0,0,0,29684
"Cereals ready-to-eat, WEETABIX WHOLE WHEAT CEREAL",Breakfast Cereals,11.5,0.1,0.387,11.4,0,0.545,77.4,2,2.8,5,373,0.48,0.35,1.17,29685
"Cereals ready-to-eat, bran flakes, single brand",Breakfast Cereals,9.4,0.056,0.732,17.6,0,0.616,80.4,18.9,2.2,3.7,320,0.4,0.295,1.025,29686
"Cereals ready-to-eat, chocolate-flavored frosted puffed corn",Breakfast Cereals,3.34,0.07,0.67,3.8,0.052899999999999996,0.176,87.2,47,3.5,3.42,405,0.961,2.079,0.231,29687
"Cereals ready-to-eat, corn flakes, low sodium",Breakfast Cereals,7.7,0.043,0.01,1.1,0,0.073,88.8,6.11,0.3,3,399,0.041,0.075,0.129,29688
"Cereals ready-to-eat, frosted oat cereal with marshmallows",Breakfast Cereals,7.1,0.071,0.5,4.3,0.052899999999999996,0.207,84.1,43.5,3.5,2.8,388,0.62,1.1,1.28,29689
"Cereals ready-to-eat, granola, homemade",Breakfast Cereals,14.85,0.078,0.025,9,0.0012,0.54,53.25,20,24.06,5.4,489,4.058,9.527,9.218,29690
"Cereals ready-to-eat, oat, corn and wheat squares, presweetened, maple flavored",Breakfast Cereals,6,0.02,0.432,1.8,0,0.112,79.9,35.45,9.8,2.5,430,1.4,4.303,4.082,29691
"Cereals ready-to-eat, rice, puffed, fortified",Breakfast Cereals,6.3,0.006,0.003,1.7,0,0.113,89.8,0,0.5,3,402,0.13,0,0,29692
"Cereals ready-to-eat, rolled oats, whole wheat, rice, presweetened, maple flavored, with pecans",Breakfast Cereals,8.3,0.045,0.279,6,0,0.298,76.9,24.5,10.5,2.6,422,3.5,4,2.2,29693
"Cereals ready-to-eat, wheat and bran, presweetened with nuts and fruits",Breakfast Cereals,7.1,0.043,0.509,9.7,0,0.443,76.2,29.8,5.6,8.5,385,0.8,1.951,2.293,29694
"Cereals ready-to-eat, wheat and malt barley flakes",Breakfast Cereals,10,0.039,0.482,8.8,0,0.34,81.5,17.6,2.9,3.2,365,0.6,0.816,1.05,29695
"Cereals ready-to-eat, wheat germ, toasted, plain",Breakfast Cereals,29.1,0.045,0.004,15.1,0.006,0.947,49.6,7.8,10.7,5.6,382,1.83,1.5,6.62,29696
"Cereals ready-to-eat, wheat, puffed, fortified",Breakfast Cereals,14.7,0.028,0.004,4.4,0,0.348,79.6,0,1.2,3,364,0.2,0,0,29697
"Cereals ready-to-eat, wheat, shredded, plain, sugar and salt free",Breakfast Cereals,11.37,0.05,0.006,12,0.0101,0.376,78.76,0.94,2.21,6.1,337,0.45,0.35,1.26,29698
"Cereals ready-to-eat, whole wheat, rolled oats, presweetened, with pecans",Breakfast Cereals,9.3,0.028,0.403,7,0.0002,0.321,71.4,15.3,11.8,5.8,408,1.4,6.262,3.797,29699
"Cereals, CREAM OF RICE, cooked with water, with salt",Breakfast Cereals,0.9,0.003,0.173,0.1,0,0.02,11.5,0.02,0.1,87.5,52,0.02,0.031,0.027,29700
"Cereals, CREAM OF RICE, cooked with water, without salt",Breakfast Cereals,0.9,0.003,0.001,0.1,0,0.02,11.4,0.02,0.1,87.5,52,0.027,0.031,0.027,29701
"Cereals, CREAM OF RICE, dry",Breakfast Cereals,6.3,0.024,0.006,0.7,0,0.143,82.4,0.12,0.5,10.5,370,0.136,0.156,0.134,29702
"Cereals, CREAM OF WHEAT, 1 minute cook time, cooked with water, microwaved, without salt",Breakfast Cereals,1.95,0.126,0.004,1.5,0,0.023,10.67,2.98,0.37,86.51,55,0.094,0.049,0.194,29703
"Cereals, CREAM OF WHEAT, 1 minute cook time, cooked with water, stove-top, without salt",Breakfast Cereals,1.65,0.125,0.004,0.4,0,0.023,11.16,0.07,0.42,86.4,56,0.106,0.064,0.21,29704
"Cereals, CREAM OF WHEAT, 1 minute cook time, dry",Breakfast Cereals,11.77,0.801,0.007,4.4,0,0.151,72.63,0.51,1.47,11.41,359,0.295,0.183,0.849,29705
"Cereals, CREAM OF WHEAT, instant, dry",Breakfast Cereals,10.6,0.357,0.015,3.3,0,0.115,75.5,0.4,1.4,10.4,366,0.222,0.192,0.774,29706
"Cereals, CREAM OF WHEAT, instant, prepared with water, with salt, (wheat)",Breakfast Cereals,1.84,0.064,0.151,0.6,0,0.02,13.08,0.07,0.24,84.4,62,0.038,0.033,0.134,29707
"Cereals, CREAM OF WHEAT, instant, prepared with water, without salt",Breakfast Cereals,1.84,0.064,0.004,0.6,0,0.02,13.08,0.07,0.24,84.4,62,0.038,0.033,0.134,29708
"Cereals, CREAM OF WHEAT, mix'n eat, apple, banana and maple flavored, dry",Breakfast Cereals,6.9,0.113,0.68,2.5,0,0.155,81.6,0,1.1,8,373,0,0,0,29709
"Cereals, CREAM OF WHEAT, mix'n eat, apple, banana and maple flavored, prepared",Breakfast Cereals,1.6,0.027,0.161,0.3,0,0.037,19.3,0,0.3,78.3,88,0.044,0,0,29710
"Cereals, CREAM OF WHEAT, mix'n eat, plain, dry",Breakfast Cereals,9.7,0.071,0.85,2.3,0,0.135,75.6,0,1.2,10.7,361,0.203,0.166,0.666,29711
"Cereals, CREAM OF WHEAT, mix'n eat, plain, prepared with water",Breakfast Cereals,1.9,0.014,0.17,0.3,0,0.027,15.1,0,0.2,82.1,72,0.034,0.028,0.111,29712
"Cereals, CREAM OF WHEAT, regular (10 minute), cooked with water, with salt",Breakfast Cereals,1.46,0.089,0.129,0.5,0,0.017,10.66,0.03,0.21,87.14,50,0.033,0.029,0.115,29713
"Cereals, CREAM OF WHEAT, regular (10 minute), cooked with water, without salt",Breakfast Cereals,1.44,0.087,0.006,0.5,0,0.016,10.52,0.03,0.21,87.61,50,0.033,0.028,0.114,29714
"Cereals, CREAM OF WHEAT, regular, 10 minute cooking, dry",Breakfast Cereals,10.5,0.606,0.007,3.8,0,0.12,76.5,0.2,1.5,10.9,370,0.238,0.205,0.829,29715
"Cereals, Cream of Wheat, 2 1/2 minute cook time, cooked with water, microwaved, without salt",Breakfast Cereals,1.88,0.131,0.045,0.7,0,0.026,10.1,0.34,0.37,87.11,52,0.099,0.058,0.176,29716
"Cereals, Cream of Wheat, 2 1/2 minute cook time, cooked with water, stove-top, without salt",Breakfast Cereals,1.44,0.104,0.034,0.7,0,0.023,11.74,0.07,0.2,86.2,56,0.051,0.03,0.102,29717
"Cereals, Cream of Wheat, 2 1/2 minute cook time, dry",Breakfast Cereals,11.61,0.882,0.328,4.4,0,0.16,71.79,0.47,1.41,11.56,355,0.253,0.175,0.63,29718
"Cereals, KASHI GO LEAN Hot Cereal, Hearty Honey & Cinnamon, dry",Breakfast Cereals,22,0.07,0.249,19.4,0,0.636,63,15,5.5,5,362,1,1.5,3,29719
"Cereals, KASHI HEART TO HEART, Instant Oatmeal, Apple Cinnamon, dry",Breakfast Cereals,10.7,0.233,0.251,11.4,0.07,0.721,78,28,4.9,5.3,374,1,1.7,2.2,29720
"Cereals, KASHI HEART TO HEART, Instant Oatmeal, maple, dry",Breakfast Cereals,10.9,0.233,0.231,11.6,0.07,0.605,77,27,5,5.4,376,1,1.8,2.2,29721
"Cereals, KASHI, KASHI GO LEAN Hot Cereal, Truly Vanilla, dry",Breakfast Cereals,19,0.073,0.247,12.8,0,0.532,65,18,5.3,5,362,0.7,1.8,2.8,29722
"Cereals, MALT-O-MEAL, Farina Hot Wheat Cereal, dry",Breakfast Cereals,13.3,0.377,0.01,3.9,0.001,0.14,74.3,0.7,1.28,9.7,362,0.306,0.151,0.759,29723
"Cereals, MALT-O-MEAL, chocolate, dry",Breakfast Cereals,10.6,0.369,0.01,4.3,0.001,0.315,79.9,24.4,1.31,6.7,374,0.462,0.265,0.521,29724
"Cereals, MALT-O-MEAL, plain, dry",Breakfast Cereals,11.8,0.508,0.01,4,0.001,0.17,75.6,1.1,1.27,9.8,361,0.299,0.168,0.743,29725
"Cereals, MALTEX, cooked with water, with salt",Breakfast Cereals,2.29,0.009,0.076,0.9,0,0.107,15.81,0.13,0.43,81,76,0.061,0.046,0.155,29726
"Cereals, MALTEX, cooked with water, without salt",Breakfast Cereals,2.29,0.009,0.005,0.9,0,0.107,15.81,0.13,0.43,81,76,0.069,0.05,0.167,29727
"Cereals, MALTEX, dry",Breakfast Cereals,11.2,0.036,0.017,4.2,0,0.521,77.3,0.66,2.1,7.5,352,0.321,0.243,0.813,29728
"Cereals, MAYPO, cooked with water, with salt",Breakfast Cereals,2.43,0.054,0.108,2,0.011800000000000001,0.088,13.23,6.26,0.97,82.7,71,0.185,0.255,0.212,29729
"Cereals, MAYPO, cooked with water, without salt",Breakfast Cereals,2.4,0.052,0.004,2.4,0.012,0.088,13.3,6.26,1,82.7,71,0.175,0.305,0.368,29730
"Cereals, MAYPO, dry",Breakfast Cereals,13.2,0.282,0.019,10.8,0.064,0.478,72,34.05,5.3,6.3,385,0.686,1.51,1.26,29731
"Cereals, Malt-O-Meal, chocolate, prepared with water, without salt",Breakfast Cereals,1.22,0.045,0.003,0.5,0.0001,0.036,9.22,2.81,0.15,89.15,44,0.054,0.031,0.061,29732
"Cereals, Malt-o-Meal, plain, prepared with water, without salt",Breakfast Cereals,1.36,0.061,0.003,0.5,0.0001,0.02,8.72,0.13,0.15,89.51,42,0.035,0.02,0.086,29733
"Cereals, NESTUM, prepared with water",Breakfast Cereals,1.41,0.108,0.033,0.4,0.0063,0.025,16.22,2.91,0.23,81.5,73,0,0.066,0.138,29734
"Cereals, Oat Bran, QUAKER, QUAKER/MOTHER'S Oat Bran, prepared with water, salt",Breakfast Cereals,2.03,0.011,0.046,1.7,0,0.069,7.49,0,0.95,88.98,43,0.17,0.31,0.36,29735
"Cereals, QUAKER Instant Oatmeal, NUTRITION FOR WOMEN, Vanilla Cinnamon, prepared with boiling water",Breakfast Cereals,3.22,0.24,0.177,1.7,0,0.073,19.82,8.1,1.28,74.19,104,0.22,0.449,0.388,29736
"Cereals, QUAKER, Creamy Wheat, farina, enriched, prepared with water, no salt",Breakfast Cereals,1.53,0.004,0.003,0.4,0,0.014,11.26,0,0.07,86.99,51,0.01,0.04,0.022,29737
"Cereals, QUAKER, Creamy Wheat, farina, enriched, prepared with water, salt",Breakfast Cereals,1.53,0.004,0.055,0.4,0,0.014,11.24,0,0.07,86.87,51,0.01,0.04,0.022,29738
"Cereals, QUAKER, Instant Grits Product with American Cheese Flavor, Dry",Breakfast Cereals,8.9,0.012,1.518,4.4,0,0.135,75.15,2.01,4.67,6.8,364,1.08,2.29,1.12,29739
"Cereals, QUAKER, Instant Grits Product with Imitation Bacon Bits and Cheddar Flavor, Dry",Breakfast Cereals,10.29,0.024,1.557,4.7,0,0.209,72.49,2.27,5.35,6.96,363,1.2,2.53,1.43,29740
"Cereals, QUAKER, Instant Grits Product with Redeye Gravy and Imitation Ham Bits, Dry",Breakfast Cereals,9.93,0.009,1.764,4.6,0,0.173,76.62,0,1.75,7.13,346,0.25,0.52,0.73,29741
"Cereals, QUAKER, Instant Grits Product--Ham 'n' Cheese",Breakfast Cereals,10.83,0.023,1.93,4.2,0,0.212,71.98,0,4.69,6.82,359,1.06,2.26,1.05,29742
"Cereals, QUAKER, Instant Oatmeal EXPRESS Cinnamon Roll, Dry",Breakfast Cereals,8.85,0.204,0.456,6.7,0.0002,0.261,76.34,30.99,5.04,6.77,371,0.92,1.73,1.5,29743
"Cereals, QUAKER, Instant Oatmeal EXPRESS, Baked Apple, dry",Breakfast Cereals,8.07,0.204,0.59,7.3,0.0008,0.303,77.43,34.57,4.62,6.35,367,0.788,1.55,1.37,29744
"Cereals, QUAKER, Instant Oatmeal EXPRESS, Golden Brown Sugar, dry",Breakfast Cereals,8.5,0.204,0.538,6.2,0,0.248,77,32.45,4.78,6.6,372,0.827,1.66,1.44,29745
"Cereals, QUAKER, Instant Oatmeal, Baked Apple, prepared with boiling water",Breakfast Cereals,2.02,0.141,0.144,1.8,0.0002,0.076,19.45,8.78,1.11,76.27,96,0.19,0.384,0.321,29746
"Cereals, QUAKER, Instant Oatmeal, Banana Bread, dry",Breakfast Cereals,8.85,0.268,0.7,6.4,0.0009,0.284,75.86,30.47,4.75,6.68,368,0.785,1.708,1.41,29747
"Cereals, QUAKER, Instant Oatmeal, Cinnamon Spice, prepared with boiling water",Breakfast Cereals,2.4,0.069,0.135,1.8,0,0.07,21.52,9.42,1.31,73.8,107,0.226,0.467,0.386,29748
"Cereals, QUAKER, Instant Oatmeal, Cinnamon-Spice, dry",Breakfast Cereals,8.59,0.239,0.482,6.6,0.0001,0.251,76.97,33.7,4.67,6.56,370,0.87,1.67,1.38,29749
"Cereals, QUAKER, Instant Oatmeal, DINOSAUR EGGS with ""DINOSAUR BONES"", Brown Sugar Cinnamon, dry",Breakfast Cereals,7.67,0.22,0.523,5.6,0,0.218,75.85,39,7.47,6.04,389,3.501,1.52,1.21,29750
"Cereals, QUAKER, Instant Oatmeal, DINOSAUR EGGS with DINOSAUR BONES, Brown Sugar Cinnamon, prepared with boiling water",Breakfast Cereals,2.28,0.067,0.156,1.7,0,0.065,22.51,11.57,2.22,72.05,119,1.039,0.451,0.359,29751
"Cereals, QUAKER, Instant Oatmeal, EXPRESS Baked Apple, prepared with boiling water",Breakfast Cereals,2.53,0.065,0.186,2.3,0.0002,0.095,24.24,10.82,1.45,70.61,120,0.247,0.485,0.429,29752
"Cereals, QUAKER, Instant Oatmeal, EXPRESS Cinnamon Roll, prepared with boiling water",Breakfast Cereals,2.77,0.065,0.144,2.1,0,0.082,23.9,9.7,1.58,70.75,121,0.267,0.542,0.47,29753
"Cereals, QUAKER, Instant Oatmeal, EXPRESS, Golden Brown Sugar, prepared with boiling water",Breakfast Cereals,2.66,0.065,0.17,2,0,0.078,24.1,10.16,1.5,70.69,121,0.259,0.52,0.451,29754
"Cereals, QUAKER, Instant Oatmeal, French Vanilla, prepared with boiling water",Breakfast Cereals,2.41,0.07,0.154,1.8,0,0.07,20.16,7.88,1.31,75.13,102,0.237,0.471,0.391,29755
"Cereals, QUAKER, Instant Oatmeal, Honey Nut, prepared with boiling water",Breakfast Cereals,2.47,0.067,0.147,1.7,0,0.071,19.32,7.97,2.23,75.02,107,0.306,0.57,1.118,29756
"Cereals, QUAKER, Instant Oatmeal, NUTRITION FOR WOMEN, Apple Spice, prepared with boiling water",Breakfast Cereals,3,0.242,0.192,1.9,0.0002,0.083,21.02,9.66,1.19,73.23,107,0.198,0.4,0.358,29757
"Cereals, QUAKER, Instant Oatmeal, NUTRITION FOR WOMEN, Golden Brown Sugar, prepared with boiling water",Breakfast Cereals,3.24,0.239,0.199,1.7,0,0.088,20.04,7.82,1.32,73.79,105,0.226,0.456,0.389,29758
"Cereals, QUAKER, Instant Oatmeal, Raisin and Spice, dry",Breakfast Cereals,7.76,0.256,0.485,6.2,0.0007,0.361,76.31,36.31,4.27,8.23,360,0.8,1.49,1.34,29759
"Cereals, QUAKER, Instant Oatmeal, Raisin and Spice, prepared",Breakfast Cereals,2.07,0.07,0.13,1.6,0.0002,0.096,20.32,9.67,1.14,75.49,100,0.198,0.397,0.357,29760
"Cereals, QUAKER, Instant Oatmeal, TREASURE HUNT, prepared with boiling water",Breakfast Cereals,2.19,0.065,0.155,1.6,0,0.063,21.85,10.27,1.35,73.53,108,0.327,0.446,0.352,29761
"Cereals, QUAKER, Instant Oatmeal, apples and cinnamon, dry",Breakfast Cereals,8,0.314,0.506,7.9,0.0012,0.318,77.92,35.53,4.39,6.3,365,0.734,1.51,1.26,29762
"Cereals, QUAKER, Instant Oatmeal, apples and cinnamon, prepared with boiling water",Breakfast Cereals,1.82,0.074,0.111,1.8,0.0002,0.073,17.77,8.1,1,78.56,87,0.167,0.344,0.287,29763
"Cereals, QUAKER, Instant Oatmeal, fruit and cream variety, dry",Breakfast Cereals,7.99,0.314,0.503,5.8,0.0004,0.283,74.72,31.2,7.52,6.39,386,1.588,2.384,1.28,29764
"Cereals, QUAKER, Instant Oatmeal, fruit and cream variety, prepared with boiling water",Breakfast Cereals,1.45,0.059,0.092,1.1,0.0001,0.051,13.56,5.66,1.36,82.93,72,0.288,0.433,0.232,29765
"Cereals, QUAKER, Instant Oatmeal, low sodium, dry",Breakfast Cereals,13,0.393,0.278,9.8,0,0.375,67.29,1.09,7.09,9.09,365,1.253,2.57,2.83,29766
"Cereals, QUAKER, Instant Oatmeal, maple and brown sugar, dry",Breakfast Cereals,8.93,0.256,0.606,6.6,0,0.258,75.28,30.43,4.83,6.87,366,0.835,1.74,1.44,29767
"Cereals, QUAKER, Instant Oatmeal, maple and brown sugar, prepared with boiling water",Breakfast Cereals,2.38,0.07,0.163,1.8,0,0.069,20.04,8.1,1.29,75.13,101,0.222,0.463,0.383,29768
"Cereals, QUAKER, Instant Oatmeal, raisins, dates and walnuts, dry",Breakfast Cereals,8.87,0.288,0.515,6.6,0.0006,0.35,73.69,0,5.5,8.12,365,0.94,1.73,2.11,29769
"Cereals, QUAKER, Mother's Instant Oatmeal (Non-Fortified), Dry",Breakfast Cereals,13.7,0.052,0.003,9.5,0,0.384,65.81,1.42,7.1,11.5,359,1.34,2.59,2.14,29770
"Cereals, QUAKER, Oat Bran, QUAKER/MOTHER'S Oat Bran, dry",Breakfast Cereals,17.03,0.079,0.005,14.3,0,0.579,62.94,1.43,7.97,8.9,364,1.43,2.61,3.03,29771
"Cereals, QUAKER, Oat Bran, QUAKER/MOTHER'S Oat Bran, prepared with water, no salt",Breakfast Cereals,2.03,0.011,0.003,1.7,0,0.069,7.49,0,0.95,89.07,43,0.17,0.31,0.36,29772
"Cereals, QUAKER, QUAKER MultiGrain Oatmeal, dry",Breakfast Cereals,11.3,0.034,0.003,12,0,0.411,73.44,0.6,2.58,11.07,333,0.52,0.57,1.28,29773
"Cereals, QUAKER, QUAKER MultiGrain Oatmeal, prepared with water, no salt",Breakfast Cereals,2.08,0.008,0.003,2.2,0,0.075,13.49,0,0.47,83.58,61,0.096,0.105,0.235,29774
"Cereals, QUAKER, QUAKER MultiGrain Oatmeal, prepared with water, salt",Breakfast Cereals,2.07,0.008,0.07,2.2,0,0.075,13.47,0,0.47,83.44,61,0.095,0.105,0.235,29775
"Cereals, QUAKER, Quick Oats, Dry",Breakfast Cereals,13.7,0.047,0.003,9.4,0,0.358,68.18,1.42,6.87,9.37,371,1.11,1.98,2.3,29776
"Cereals, QUAKER, Scotch Barley, regular and quick, dry",Breakfast Cereals,10.74,0.028,0.007,10.4,0,0.261,76.38,0.66,2.24,9.65,346,0.47,0.2,1.03,29777
"Cereals, QUAKER, corn grits, instant, butter flavor, dry",Breakfast Cereals,8.09,0.411,1.31,4.8,0,0.147,75.04,0.87,5.51,6.73,366,2.44,0.98,0.51,29778
"Cereals, QUAKER, corn grits, instant, butter flavor, prepared with water",Breakfast Cereals,1.51,0.079,0.247,0.9,0,0.028,14.02,0.16,1.03,82.49,71,0.458,0.184,0.096,29779
"Cereals, QUAKER, corn grits, instant, cheddar cheese flavor, dry",Breakfast Cereals,8.37,0.046,1.865,4.2,0,0.17,73.08,2.13,5.97,7.6,366,1.76,1.57,0.87,29780
"Cereals, QUAKER, corn grits, instant, cheddar cheese flavor, prepared with water",Breakfast Cereals,1.6,0.011,0.358,0.8,0,0.033,14.01,0.41,1.1,82.26,72,0.308,0.284,0.142,29781
"Cereals, QUAKER, corn grits, instant, country bacon (imitation bacon bits), prepared with water",Breakfast Cereals,1.92,0.004,0.293,1,0,0.04,14.61,0.07,0.33,82.2,69,0.044,0.077,0.167,29782
"Cereals, QUAKER, corn grits, instant, plain, dry",Breakfast Cereals,7.31,0.378,1.05,4.2,0,0.163,76.02,1.51,2.14,10.35,359,0.362,0.395,0.967,29783
"Cereals, QUAKER, corn grits, instant, plain, prepared (microwaved or boiling water added), without salt",Breakfast Cereals,1.58,0.064,0.188,1.1,0,0.034,15.95,0.1,0.49,81.22,76,0.076,0.081,0.191,29784
"Cereals, QUAKER, corn grits, instant, with imitation bacon bits, dry",Breakfast Cereals,9.85,0.038,1.219,5.3,0,0.252,77.35,0.27,1.65,7.3,349,0.24,0.42,0.64,29785
"Cereals, QUAKER, farina, Creamy Wheat, enriched, dry",Breakfast Cereals,10.82,0.012,0.002,2.9,0,0.098,76.13,0.3,0.95,11.66,349,0.24,0.08,0.48,29786
"Cereals, QUAKER, farina, enriched cinnamon flavor, dry",Breakfast Cereals,12.11,0.033,0.003,3.7,0.0004,0.104,74.54,0.29,0.79,12.14,346,0.28,0,0,29787
"Cereals, QUAKER, hominy grits, white, quick, dry",Breakfast Cereals,8.53,0.004,0.002,4.8,0,0.146,79.16,1,1.36,10.48,347,0.26,0.2,0.68,29788
"Cereals, QUAKER, hominy grits, white, regular, dry",Breakfast Cereals,8.53,0.004,0.002,4.8,0,0.146,79.16,1,1.36,10.48,347,0.26,0.2,0.68,29789
"Cereals, QUAKER, hominy grits, yellow, quick, dry",Breakfast Cereals,8.26,0.004,0.002,5.6,0,0.167,77.76,1.08,1.66,11.74,337,0.45,0.55,0.71,29790
"Cereals, QUAKER, oatmeal, instant, low sodium, prepared with water",Breakfast Cereals,3.59,0.098,0.072,2.3,0,0.094,16.67,0,1.79,76.98,93,0.341,0.649,0.714,29791
"Cereals, QUAKER, oatmeal, instant, raisins, dates and walnuts, prepared with water",Breakfast Cereals,2.83,0.093,0.165,2.1,0.0002,0.112,23.5,0,1.75,70.63,116,0.3,0.552,0.673,29792
"Cereals, RALSTON, cooked with water, with salt",Breakfast Cereals,2.2,0.005,0.188,2.4,0,0.061,11.2,0,0.3,86.1,53,0.056,0,0,29793
"Cereals, RALSTON, cooked with water, without salt",Breakfast Cereals,2.2,0.005,0.002,2.4,0,0.061,11.2,0,0.3,86.1,53,0.052,0.04,0.146,29794
"Cereals, RALSTON, dry",Breakfast Cereals,14.1,0.035,0.011,13.4,0,0.392,72.1,0,2.1,9.9,341,0,0,0,29795
"Cereals, ROMAN MEAL WITH OATS, cooked with water, with salt",Breakfast Cereals,3,0.011,0.225,3.4,0,0.107,14.2,0,0.8,81.5,71,0.072,0,0,29796
"Cereals, ROMAN MEAL WITH OATS, cooked with water, without salt",Breakfast Cereals,3,0.011,0.004,2.9,0,0.107,14.2,0,0.8,81.5,71,0.072,0,0,29797
"Cereals, ROMAN MEAL, plain, cooked with water, with salt",Breakfast Cereals,2.7,0.012,0.082,3.4,0,0.125,13.7,0,0.4,82.7,61,0.053,0,0,29798
"Cereals, ROMAN MEAL, plain, cooked with water, without salt",Breakfast Cereals,2.7,0.012,0.001,3.4,0,0.125,13.7,0,0.4,82.7,61,0.053,0,0,29799
"Cereals, ROMAN MEAL, plain, dry",Breakfast Cereals,14.4,0.065,0.006,17.9,0,0.66,72,0,2.2,9.1,322,0.326,0.288,1.005,29800
"Cereals, WHEATENA, cooked with water",Breakfast Cereals,2,0.004,0.002,2.7,0,0.077,11.8,0,0.5,85.4,56,0.075,0.071,0.252,29801
"Cereals, WHEATENA, cooked with water, with salt",Breakfast Cereals,2.03,0.006,0.238,2,0,0.077,11.74,0.25,0.45,85.4,59,0.091,0.067,0.24,29802
"Cereals, WHEATENA, dry",Breakfast Cereals,13.1,0.028,0.013,12.8,0,0.492,75.6,1.6,2.9,6.5,357,0.43,0.41,1.46,29803
"Cereals, corn grits, white, regular and quick, enriched, cooked with water, with salt",Breakfast Cereals,1.71,0.001,0.223,0.8,0,0.027,14.76,0.12,0.46,82.93,71,0.066,0.064,0.165,29804
"Cereals, corn grits, white, regular and quick, enriched, cooked with water, without salt",Breakfast Cereals,1.71,0.001,0.002,0.8,0,0.027,14.76,0.12,0.46,82.93,71,0.066,0.064,0.165,29805
"Cereals, corn grits, white, regular and quick, enriched, dry",Breakfast Cereals,7.65,0.004,0.001,4.6,0,0.141,79.09,0.57,1.75,10.92,370,0.34,0.315,0.875,29806
"Cereals, corn grits, white, regular and quick, unenriched, cooked with water, without salt",Breakfast Cereals,1.42,0.003,0.002,0.3,0,0.021,12.87,0.1,0.19,85.35,59,0.025,0.048,0.083,29807
"Cereals, corn grits, white, regular and quick, unenriched, dry",Breakfast Cereals,8.8,0.002,0.001,1.6,0,0.137,79.6,0.64,1.2,10,371,0.155,0.3,0.516,29808
"Cereals, corn grits, white, regular, quick, unenriched, cooked with water, with salt",Breakfast Cereals,1.4,0,0.223,0.2,0,0.022,13,0,0.2,85.3,60,0.03,0.05,0.08,29809
"Cereals, corn grits, yellow, regular and quick, enriched, cooked with water, without salt",Breakfast Cereals,1.23,0.001,0.002,0.7,0,0.022,13.86,0.09,0.39,84.36,65,0.06,0.062,0.13,29810
"Cereals, corn grits, yellow, regular and quick, enriched, dry",Breakfast Cereals,6.72,0.004,0.002,3.9,0,0.118,79.91,0.55,1.49,11.37,368,0.29,0.31,0.72,29811
"Cereals, corn grits, yellow, regular and quick, unenriched, cooked with water, without salt",Breakfast Cereals,1.42,0.003,0.002,0.3,0,0.021,12.87,0.1,0.19,85.35,59,0.025,0.048,0.083,29812
"Cereals, corn grits, yellow, regular and quick, unenriched, dry",Breakfast Cereals,8.8,0.002,0.001,1.6,0,0.137,79.6,0.64,1.2,10,371,0.155,0.3,0.516,29813
"Cereals, corn grits, yellow, regular, quick, enriched, cooked with water, with salt",Breakfast Cereals,1.23,0.001,0.223,0.7,0,0.022,13.86,0.09,0.39,84.36,65,0.06,0.062,0.13,29814
"Cereals, corn grits, yellow, regular, quick, unenriched, cooked with water, with salt",Breakfast Cereals,1.42,0.003,0.223,0.3,0,0.021,12.87,0.1,0.19,85.35,59,0.025,0.048,0.083,29815
"Cereals, farina, enriched, assorted brands including CREAM OF WHEAT, quick (1-3 minutes), cooked with water, without salt",Breakfast Cereals,1.82,0.097,0.018,0.8,0,0.023,10.92,0.76,0.34,86.55,55,0.066,0.038,0.126,29816
"Cereals, farina, enriched, assorted brands including CREAM OF WHEAT, quick (1-3 minutes), dry",Breakfast Cereals,11.6,0.711,0.124,4.2,0,0.153,73.19,0.56,1.41,11.08,360,0.232,0.15,0.627,29817
"Cereals, farina, enriched, cooked with water, with salt",Breakfast Cereals,1.82,0.097,0.126,0.8,0,0.023,10.92,0.76,0.34,86.55,53,0.066,0.038,0.126,29818
"Cereals, farina, enriched, cooked with water, without salt",Breakfast Cereals,1.82,0.097,0.018,0.8,0,0.023,10.92,0.76,0.34,86.55,53,0.066,0.038,0.126,29819
"Cereals, farina, unenriched, dry",Breakfast Cereals,10.6,0.014,0.003,1.9,0,0.094,78,0,0.5,10.5,369,0.08,0.06,0.22,29820
"Cereals, oats, instant, fortified, plain, dry",Breakfast Cereals,12.72,0.352,0.278,10,0,0.359,67.55,1.51,6.25,10.37,375,1.537,2.129,2.426,29821
"Cereals, oats, instant, fortified, plain, prepared with water (boiling water added or microwaved)",Breakfast Cereals,2.37,0.08,0.049,1.7,0,0.061,11.67,0.46,1.36,84.03,68,0.267,0.37,0.422,29822
"Cereals, oats, instant, fortified, with cinnamon and spice, dry",Breakfast Cereals,8.59,0.239,0.482,6.6,0.0001,0.251,76.97,33.7,4.67,6.56,370,0.808,1.67,1.38,29823
"Cereals, oats, instant, fortified, with cinnamon and spice, prepared with water",Breakfast Cereals,2.4,0.069,0.135,1.8,0,0.07,21.52,9.42,1.31,73.8,107,0.226,0.467,0.386,29824
"Cereals, oats, instant, fortified, with raisins and spice, dry",Breakfast Cereals,7.76,0.256,0.485,6.2,0.0007,0.361,76.31,36.31,4.27,8.23,360,0.8,1.49,1.34,29825
"Cereals, oats, instant, fortified, with raisins and spice, prepared with water",Breakfast Cereals,2.07,0.07,0.151,1.6,0.0002,0.096,20.32,9.67,1.14,75.49,100,0.198,0.397,0.357,29826
"Cereals, oats, regular and quick and instant, not fortified, dry",Breakfast Cereals,13.15,0.052,0.006,10.1,0,0.362,67.7,0.99,6.52,10.84,379,1.11,1.98,2.3,29827
"Cereals, oats, regular and quick and instant, unenriched, cooked with water (includes boiling and microwaving), with salt",Breakfast Cereals,2.54,0.009,0.071,1.7,0,0.07,12,0.27,1.52,83.61,71,0.31,0.435,0.559,29828
"Cereals, oats, regular and quick and instant, unenriched, cooked with water (includes boiling and microwaving), without salt",Breakfast Cereals,2.54,0.009,0.004,1.7,0,0.07,12,0.27,1.52,83.61,71,0.31,0.435,0.559,29829
"Cereals, ready-to-eat, ALPEN",Breakfast Cereals,11.2,0.147,0.213,9.1,0.0087,0.568,75.7,20,3.3,7.4,352,0.496,1.327,0.925,29830
"Cereals, ready-to-eat, KELLOGG, KELLOGG'S CINNABON cereal",Breakfast Cereals,5.7,0.026,0.385,3.9,0.065,0.129,83.3,41.6,7,2.5,410,1,3,1.4,29831
"Cereals, whole wheat hot natural cereal, cooked with water, with salt",Breakfast Cereals,2,0.007,0.233,1.6,0,0.071,13.7,0.08,0.4,83.6,62,0.06,0.057,0.202,29832
"Cereals, whole wheat hot natural cereal, cooked with water, without salt",Breakfast Cereals,2,0.007,0,1.6,0,0.071,13.7,0.08,0.4,83.6,62,0.06,0.057,0.202,29833
"Cereals, whole wheat hot natural cereal, dry",Breakfast Cereals,11.2,0.04,0.002,9.5,0,0.389,75.2,0.42,2,9.9,342,0.3,0.283,1.008,29834
"Incaparina, dry mix (corn and soy flours), unprepared",Breakfast Cereals,21.75,0.6,0.004,9.9,0,1.03,60.53,0,5.58,7.47,379,0,0,0,29835
KASHI GOLEAN Crisp Toasted Berry Crumble,Breakfast Cereals,17.8,0.137,0.244,14.9,0,0.321,68.4,20.9,8,4,368,1,3.6,2.4,29836
Milk and cereal bar,Breakfast Cereals,6.47,0.41,0.319,0.4,0.0154,0.254,72.05,45.97,10.98,8.69,413,9.114,1.186,0.239,29837
"Millet, puffed",Breakfast Cereals,13,0.008,0.005,2.7,0,0.04,80,0.55,3.4,2.5,354,0.67,0.717,1.98,29838
Rice and Wheat cereal bar,Breakfast Cereals,9.09,0.031,0.5,1.8,0,0.143,72.73,31.8,9.09,7.5,409,0,6.567,2.049,29839
"Abiyuch, raw",Fruits and Fruit Juices,1.5,0.008,0.02,5.3,0.0541,0.304,17.6,8.55,0.1,79.9,69,0.014,0,0,29840
"Acerola juice, raw",Fruits and Fruit Juices,0.4,0.01,0.003,0.3,1.6,0.097,4.8,4.5,0.3,94.3,23,0.068,0.082,0.09,29841
"Acerola, (west indian cherry), raw",Fruits and Fruit Juices,0.4,0.012,0.007,1.1,1.6776,0.146,7.69,0,0.3,91.41,32,0.068,0.082,0.09,29842
"Apple juice, canned or bottled, unsweetened, with added ascorbic acid",Fruits and Fruit Juices,0.1,0.008,0.004,0.2,0.0385,0.101,11.3,9.62,0.13,88.24,46,0.022,0.006,0.039,29843
"Apple juice, canned or bottled, unsweetened, without added ascorbic acid",Fruits and Fruit Juices,0.1,0.008,0.004,0.2,0.0009,0.101,11.3,9.62,0.13,88.24,46,0.022,0.006,0.039,29844
"Apple juice, frozen concentrate, unsweetened, diluted with 3 volume water without added ascorbic acid",Fruits and Fruit Juices,0.14,0.006,0.007,0.1,0.0006,0.126,11.54,10.93,0.1,87.9,47,0.018,0.002,0.031,29845
"Apple juice, frozen concentrate, unsweetened, diluted with 3 volume water, with added ascorbic acid",Fruits and Fruit Juices,0.14,0.006,0.007,0.1,0.025,0.126,11.54,0,0.1,87.9,47,0.018,0.002,0.031,29846
"Apple juice, frozen concentrate, unsweetened, undiluted, with added ascorbic acid",Fruits and Fruit Juices,0.51,0.02,0.025,0,0.0889,0.448,41,38.83,0.37,57,166,0.06,0.015,0.108,29847
"Apple juice, frozen concentrate, unsweetened, undiluted, without added ascorbic acid",Fruits and Fruit Juices,0.51,0.02,0.025,0.4,0.0021000000000000003,0.448,41,38.83,0.37,57,166,0.06,0.015,0.108,29848
"Apples, canned, sweetened, sliced, drained, heated",Fruits and Fruit Juices,0.18,0.004,0.003,2,0.0002,0.07,16.84,14.84,0.43,82.28,67,0.07,0.017,0.126,29849
"Apples, canned, sweetened, sliced, drained, unheated",Fruits and Fruit Juices,0.18,0.004,0.003,1.7,0.0004,0.068,16.7,15,0.49,82.36,67,0.08,0.02,0.144,29850
"Apples, dehydrated (low moisture), sulfured, stewed",Fruits and Fruit Juices,0.28,0.004,0.026,2.6,0.0006,0.136,19.91,0,0.12,79.36,74,0.02,0.005,0.036,29851
"Apples, dehydrated (low moisture), sulfured, uncooked",Fruits and Fruit Juices,1.32,0.019,0.124,12.4,0.0022,0.64,93.53,81.13,0.58,3,346,0.095,0.024,0.171,29852
"Apples, dried, sulfured, stewed, with added sugar",Fruits and Fruit Juices,0.2,0.003,0.019,1.9,0.0009,0.098,20.73,0,0.07,78.76,83,0.011,0.003,0.02,29853
"Apples, dried, sulfured, stewed, without added sugar",Fruits and Fruit Juices,0.22,0.003,0.02,2,0.001,0.105,15.32,13.32,0.07,84.13,57,0.012,0.003,0.022,29854
"Apples, dried, sulfured, uncooked",Fruits and Fruit Juices,0.93,0.014,0.087,8.7,0.0039,0.45,65.89,57.19,0.32,31.76,243,0.052,0.013,0.093,29855
"Apples, frozen, unsweetened, heated",Fruits and Fruit Juices,0.29,0.005,0.003,1.3,0.0004,0.076,12,0,0.33,87.16,47,0.053,0.013,0.095,29856
"Apples, frozen, unsweetened, unheated",Fruits and Fruit Juices,0.28,0.004,0.003,1.3,0.0001,0.077,12.31,0,0.32,86.85,48,0.053,0.013,0.095,29857
"Apples, raw, with skin",Fruits and Fruit Juices,0.26,0.006,0.001,2.4,0.0046,0.107,13.81,10.39,0.17,85.56,52,0.028,0.007,0.051,29858
"Apples, raw, without skin",Fruits and Fruit Juices,0.27,0.005,0,1.3,0.004,0.09,12.76,10.1,0.13,86.67,48,0.021,0.005,0.037,29859
"Apples, raw, without skin, cooked, boiled",Fruits and Fruit Juices,0.26,0.005,0.001,2.4,0.0002,0.088,13.64,11.01,0.36,85.47,53,0.058,0.014,0.104,29860
"Apples, raw, without skin, cooked, microwave",Fruits and Fruit Juices,0.28,0.005,0.001,2.8,0.0003,0.093,14.41,11.61,0.42,84.63,56,0.068,0.017,0.122,29861
"Applesauce, canned, sweetened, with salt",Fruits and Fruit Juices,0.18,0.004,0.028,1.2,0.0017,0.061,19.91,0,0.18,79.58,76,0.03,0.007,0.054,29862
"Applesauce, canned, sweetened, without salt (includes USDA commodity)",Fruits and Fruit Juices,0.16,0.003,0.002,1.2,0.0017,0.075,17.49,14.67,0.17,81.98,68,0.03,0.007,0.054,29863
"Applesauce, canned, unsweetened, with added ascorbic acid",Fruits and Fruit Juices,0.17,0.004,0.002,1.1,0.0212,0.074,11.27,9.39,0.1,88.22,42,0.008,0.002,0.014,29864
"Applesauce, canned, unsweetened, without added ascorbic acid (includes USDA commodity)",Fruits and Fruit Juices,0.17,0.004,0.002,1.1,0.001,0.074,11.27,9.39,0.1,88.22,42,0.008,0.002,0.014,29865
"Apricot nectar, canned, with added ascorbic acid",Fruits and Fruit Juices,0.37,0.007,0.003,0.6,0.0544,0.114,14.39,0,0.09,84.87,56,0.006,0.038,0.017,29866
"Apricot nectar, canned, without added ascorbic acid",Fruits and Fruit Juices,0.37,0.007,0.003,0.6,0.0006,0.114,14.39,13.79,0.09,84.87,56,0.006,0.038,0.017,29867
"Apricots, canned, extra heavy syrup pack, without skin, solids and liquids",Fruits and Fruit Juices,0.55,0.008,0.013,1.6,0.0024,0.126,24.85,0,0.04,74.33,96,0.003,0.017,0.008,29868
"Apricots, canned, extra light syrup pack, with skin, solids and liquids",Fruits and Fruit Juices,0.6,0.01,0.002,1.6,0.004,0.14,12.5,0,0.1,86.3,49,0.007,0.043,0.02,29869
"Apricots, canned, heavy syrup pack, with skin, solids and liquids",Fruits and Fruit Juices,0.53,0.009,0.004,1.6,0.0031,0.14,21.47,19.87,0.08,77.56,83,0.005,0.033,0.015,29870
"Apricots, canned, heavy syrup pack, without skin, solids and liquids",Fruits and Fruit Juices,0.51,0.009,0.011,1.6,0.0028,0.134,21.45,0,0.09,77.66,83,0.006,0.037,0.017,29871
"Apricots, canned, heavy syrup, drained",Fruits and Fruit Juices,0.64,0.01,0.004,2.7,0.0031,0.143,21.31,18.65,0.11,77.56,83,0.007,0.045,0.021,29872
"Apricots, canned, juice pack, with skin, solids and liquids",Fruits and Fruit Juices,0.63,0.012,0.004,1.6,0.004900000000000001,0.165,12.34,10.74,0.04,86.62,48,0.003,0.017,0.007,29873
"Apricots, canned, light syrup pack, with skin, solids and liquids",Fruits and Fruit Juices,0.53,0.011,0.004,1.6,0.0027,0.138,16.49,14.89,0.05,82.56,63,0.003,0.021,0.01,29874
"Apricots, canned, water pack, with skin, solids and liquids",Fruits and Fruit Juices,0.71,0.008,0.003,1.6,0.0034,0.192,6.39,4.79,0.16,92.36,27,0.011,0.069,0.031,29875
"Apricots, canned, water pack, without skin, solids and liquids",Fruits and Fruit Juices,0.69,0.008,0.011,1.1,0.0018,0.154,5.48,0,0.03,93.43,22,0.002,0.013,0.006,29876
"Apricots, dehydrated (low-moisture), sulfured, stewed",Fruits and Fruit Juices,1.93,0.024,0.005,0,0.0070999999999999995,0.728,32.62,0,0.24,63.6,126,0.017,0.105,0.047,29877
"Apricots, dehydrated (low-moisture), sulfured, uncooked",Fruits and Fruit Juices,4.9,0.061,0.013,0,0.0095,1.85,82.89,0,0.62,7.5,320,0.043,0.268,0.121,29878
"Apricots, dried, sulfured, stewed, with added sugar",Fruits and Fruit Juices,1.17,0.015,0.003,4.1,0.0014,0.443,29.26,0,0.15,68.45,113,0.01,0.064,0.029,29879
"Apricots, dried, sulfured, stewed, without added sugar",Fruits and Fruit Juices,1.2,0.019,0.004,2.6,0.0003,0.411,22.15,19.57,0.18,75.56,85,0.006,0.026,0.026,29880
"Apricots, dried, sulfured, uncooked",Fruits and Fruit Juices,3.39,0.055,0.01,7.3,0.001,1.162,62.64,53.44,0.51,30.89,241,0.017,0.074,0.074,29881
"Apricots, frozen, sweetened",Fruits and Fruit Juices,0.7,0.01,0.004,2.2,0.009,0.229,25.1,0,0.1,73.3,98,0.007,0.044,0.02,29882
"Apricots, raw",Fruits and Fruit Juices,1.4,0.013,0.001,2,0.01,0.259,11.12,9.24,0.39,86.35,48,0.027,0.17,0.077,29883
"Avocados, raw, California",Fruits and Fruit Juices,1.96,0.013,0.008,6.8,0.0088,0.507,8.64,0.3,15.41,72.33,167,2.126,9.799,1.816,29884
"Avocados, raw, Florida",Fruits and Fruit Juices,2.23,0.01,0.002,5.6,0.0174,0.351,7.82,2.42,10.06,78.81,120,1.96,5.513,1.676,29885
"Avocados, raw, all commercial varieties",Fruits and Fruit Juices,2,0.012,0.007,6.7,0.01,0.485,8.53,0.66,14.66,73.23,160,2.126,9.799,1.816,29886
"Bananas, dehydrated, or banana powder",Fruits and Fruit Juices,3.89,0.022,0.003,9.9,0.007,1.491,88.28,47.3,1.81,3,346,0.698,0.153,0.337,29887
"Bananas, raw",Fruits and Fruit Juices,1.09,0.005,0.001,2.6,0.0087,0.358,22.84,12.23,0.33,74.91,89,0.112,0.032,0.073,29888
"Blackberries, canned, heavy syrup, solids and liquids",Fruits and Fruit Juices,1.31,0.021,0.003,3.4,0.0028,0.099,23.1,19.7,0.14,75.06,92,0.005,0.014,0.08,29889
"Blackberries, frozen, unsweetened",Fruits and Fruit Juices,1.18,0.029,0.001,5,0.0031,0.14,15.67,10.67,0.43,82.21,64,0.015,0.041,0.245,29890
"Blackberries, raw",Fruits and Fruit Juices,1.39,0.029,0.001,5.3,0.021,0.162,9.61,4.88,0.49,88.15,43,0.014,0.047,0.28,29891
"Blackberry juice, canned",Fruits and Fruit Juices,0.3,0.012,0.001,0.1,0.011300000000000001,0.135,7.8,7.7,0.6,90.9,38,0.018,0.058,0.344,29892
"Blueberries, canned, heavy syrup, solids and liquids",Fruits and Fruit Juices,0.65,0.005,0.003,1.6,0.0011,0.04,22.06,20.46,0.33,76.78,88,0.027,0.047,0.144,29893
"Blueberries, canned, light syrup, drained",Fruits and Fruit Juices,1.04,0.006,0.003,2.6,0.0005,0.054,22.66,17.45,0.4,75.72,88,0.028,0.062,0.19,29894
"Blueberries, frozen, sweetened",Fruits and Fruit Juices,0.4,0.006,0.001,2.2,0.001,0.06,21.95,19.72,0.13,77.4,81,0.011,0.018,0.057,29895
"Blueberries, frozen, unsweetened",Fruits and Fruit Juices,0.42,0.008,0.001,2.7,0.0025,0.054,12.17,8.45,0.64,86.59,51,0.053,0.091,0.279,29896
"Blueberries, raw",Fruits and Fruit Juices,0.74,0.006,0.001,2.4,0.009699999999999999,0.077,14.49,9.96,0.33,84.21,57,0.028,0.047,0.146,29897
"Blueberries, wild, canned, heavy syrup, drained",Fruits and Fruit Juices,0.56,0.019,0.001,4.9,0.0003,0.046,28.32,19.28,0.34,70.63,107,0,0,0,29898
"Blueberries, wild, frozen",Fruits and Fruit Juices,0,0.017,0.003,4.4,0.0017,0.068,13.85,0,0.16,85.8,51,0.03,0.02,0.09,29899
"Boysenberries, canned, heavy syrup",Fruits and Fruit Juices,0.99,0.018,0.003,2.6,0.0062,0.09,22.31,0,0.12,76.26,88,0.004,0.012,0.068,29900
"Boysenberries, frozen, unsweetened",Fruits and Fruit Juices,1.1,0.027,0.001,5.3,0.0031,0.139,12.19,6.89,0.26,85.9,50,0.009,0.025,0.148,29901
"Breadfruit, raw",Fruits and Fruit Juices,1.07,0.017,0.002,4.9,0.029,0.49,27.12,11,0.23,70.65,103,0.048,0.034,0.066,29902
Candied fruit,Fruits and Fruit Juices,0.34,0.018,0.098,1.6,0,0.056,82.74,80.68,0.07,16.7,322,0.01,0.008,0.02,29903
"Carambola, (starfruit), raw",Fruits and Fruit Juices,1.04,0.003,0.002,2.8,0.0344,0.133,6.73,3.98,0.33,91.38,31,0.019,0.03,0.184,29904
"Carissa, (natal-plum), raw",Fruits and Fruit Juices,0.5,0.011,0.003,0,0.038,0.26,13.63,0,1.3,84.17,62,0,0,0,29905
"Cherimoya, raw",Fruits and Fruit Juices,1.57,0.01,0.007,3,0.0126,0.287,17.71,12.87,0.68,79.39,75,0.233,0.055,0.188,29906
"Cherries, sour, red, canned, extra heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.71,0.01,0.007,0.8,0.0019,0.091,29.23,0,0.09,69.73,114,0.021,0.025,0.028,29907
"Cherries, sour, red, canned, heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.73,0.01,0.007,1.1,0.002,0.093,23.27,22.17,0.1,75.66,91,0.021,0.026,0.029,29908
"Cherries, sour, red, canned, light syrup pack, solids and liquids",Fruits and Fruit Juices,0.74,0.01,0.007,0.8,0.002,0.095,19.3,0,0.1,79.62,75,0.022,0.026,0.029,29909
"Cherries, sour, red, canned, water pack, solids and liquids (includes USDA commodity red tart cherries, canned)",Fruits and Fruit Juices,0.77,0.011,0.007,1.1,0.0021000000000000003,0.098,8.94,7.6,0.1,89.93,36,0.023,0.027,0.03,29910
"Cherries, sour, red, frozen, unsweetened",Fruits and Fruit Juices,0.92,0.013,0.001,1.6,0.0017,0.124,11.02,9.02,0.44,87.2,46,0.1,0.12,0.132,29911
"Cherries, sour, red, raw",Fruits and Fruit Juices,1,0.016,0.003,1.6,0.01,0.173,12.18,8.49,0.3,86.13,50,0.068,0.082,0.09,29912
"Cherries, sweet, canned, extra heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.59,0.009,0.003,1.5,0.0036,0.142,26.23,0,0.15,72.66,102,0.033,0.04,0.045,29913
"Cherries, sweet, canned, juice pack, solids and liquids",Fruits and Fruit Juices,0.91,0.014,0.003,1.5,0.0025,0.131,13.81,12.31,0.02,84.95,54,0.004,0.005,0.006,29914
"Cherries, sweet, canned, light syrup pack, solids and liquids",Fruits and Fruit Juices,0.61,0.009,0.003,1.5,0.0037,0.148,17.29,15.79,0.15,81.56,67,0.034,0.042,0.046,29915
"Cherries, sweet, canned, pitted, heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.6,0.009,0.003,1.4,0.0036,0.145,21.27,16.18,0.15,77.61,83,0.034,0.041,0.045,29916
"Cherries, sweet, canned, pitted, heavy syrup, drained",Fruits and Fruit Juices,0.73,0.01,0.003,2.3,0.0036,0.148,21.07,16.18,0.21,77.61,83,0.041,0.049,0.055,29917
"Cherries, sweet, canned, water pack, solids and liquids",Fruits and Fruit Juices,0.77,0.011,0.001,1.5,0.0022,0.131,11.76,10.26,0.13,87.05,46,0.028,0.034,0.038,29918
"Cherries, sweet, frozen, sweetened",Fruits and Fruit Juices,1.15,0.012,0.001,2.1,0.001,0.199,22.36,20.26,0.13,75.53,89,0.03,0.036,0.04,29919
"Cherries, sweet, raw",Fruits and Fruit Juices,1.06,0.013,0,2.1,0.007,0.222,16.01,12.82,0.2,82.25,63,0.038,0.047,0.052,29920
"Clementines, raw",Fruits and Fruit Juices,0.85,0.03,0.001,1.7,0.048799999999999996,0.177,12.02,9.18,0.15,86.58,47,0,0,0,29921
"Crabapples, raw",Fruits and Fruit Juices,0.4,0.018,0.001,0,0.008,0.194,19.95,0,0.3,78.94,76,0.048,0.012,0.088,29922
"Cranberries, dried, sweetened",Fruits and Fruit Juices,0.07,0.01,0.003,5.7,0.0002,0.04,82.36,65,1.37,16,308,0.103,0.198,0.658,29923
"Cranberries, raw",Fruits and Fruit Juices,0.39,0.008,0.002,4.6,0.013300000000000001,0.085,12.2,4.04,0.13,87.13,46,0.011,0.018,0.055,29924
"Cranberry juice, unsweetened",Fruits and Fruit Juices,0.39,0.008,0.002,0.1,0.009300000000000001,0.077,12.2,12.1,0.13,87.13,46,0.01,0.023,0.07,29925
"Cranberry sauce, canned, sweetened",Fruits and Fruit Juices,0.2,0.004,0.029,1,0.002,0.026,38.9,37.9,0.15,60.65,151,0.013,0.021,0.066,29926
"Cranberry-orange relish, canned",Fruits and Fruit Juices,0.3,0.011,0.032,0,0.018,0.038,46.2,0,0.1,53.2,178,0.012,0,0,29927
"Currants, european black, raw",Fruits and Fruit Juices,1.4,0.055,0.002,0,0.181,0.322,15.38,0,0.41,81.96,63,0.034,0.058,0.179,29928
"Currants, red and white, raw",Fruits and Fruit Juices,1.4,0.033,0.001,4.3,0.041,0.275,13.8,7.37,0.2,83.95,56,0.017,0.028,0.088,29929
"Currants, zante, dried",Fruits and Fruit Juices,4.08,0.086,0.008,6.8,0.0047,0.892,74.08,67.28,0.27,19.21,283,0.028,0.047,0.18,29930
"Custard-apple, (bullock's-heart), raw",Fruits and Fruit Juices,1.7,0.03,0.004,2.4,0.0192,0.382,25.2,0,0.6,71.5,101,0.231,0,0,29931
"Dates, deglet noor",Fruits and Fruit Juices,2.45,0.039,0.002,8,0.0004,0.656,75.03,63.35,0.39,20.53,282,0.032,0.036,0.019,29932
"Dates, medjool",Fruits and Fruit Juices,1.81,0.064,0.001,6.7,0,0.696,74.97,66.47,0.15,21.32,277,0,0,0,29933
"Durian, raw or frozen",Fruits and Fruit Juices,1.47,0.006,0.002,3.8,0.0197,0.436,27.09,0,5.33,64.99,147,0,0,0,29934
"Elderberries, raw",Fruits and Fruit Juices,0.66,0.038,0.006,7,0.036,0.28,18.4,0,0.5,79.8,73,0.023,0.08,0.247,29935
"Feijoa, raw",Fruits and Fruit Juices,0.98,0.017,0.003,6.4,0.0329,0.172,12.92,8.2,0.6,84.94,55,0.148,0.081,0.194,29936
"Figs, canned, extra heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.38,0.026,0.001,0,0.001,0.097,27.86,0,0.1,71.39,107,0.02,0.022,0.047,29937
"Figs, canned, heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.38,0.027,0.001,2.2,0.001,0.099,22.9,20.7,0.1,76.33,88,0.02,0.022,0.048,29938
"Figs, canned, light syrup pack, solids and liquids",Fruits and Fruit Juices,0.39,0.027,0.001,1.8,0.001,0.102,17.95,16.15,0.1,81.26,69,0.02,0.022,0.048,29939
"Figs, canned, water pack, solids and liquids",Fruits and Fruit Juices,0.4,0.028,0.001,2.2,0.001,0.103,13.99,11.79,0.1,85.21,53,0.02,0.022,0.048,29940
"Figs, dried, stewed",Fruits and Fruit Juices,1.42,0.07,0.004,4.2,0.0044,0.294,27.57,23.35,0.4,69.8,107,0.062,0.068,0.149,29941
"Figs, dried, uncooked",Fruits and Fruit Juices,3.3,0.162,0.01,9.8,0.0012,0.68,63.87,47.92,0.93,30.05,249,0.144,0.159,0.345,29942
"Figs, raw",Fruits and Fruit Juices,0.75,0.035,0.001,2.9,0.002,0.232,19.18,16.26,0.3,79.11,74,0.06,0.066,0.144,29943
"Fruit cocktail, (peach and pineapple and pear and grape and cherry), canned, extra heavy syrup, solids and liquids",Fruits and Fruit Juices,0.39,0.006,0.006,1.1,0.0019,0.086,22.89,0,0.07,76.43,88,0.01,0.013,0.03,29944
"Fruit cocktail, (peach and pineapple and pear and grape and cherry), canned, extra light syrup, solids and liquids",Fruits and Fruit Juices,0.4,0.008,0.004,1.1,0.003,0.104,11.63,0,0.07,87.7,45,0.01,0.014,0.031,29945
"Fruit cocktail, (peach and pineapple and pear and grape and cherry), canned, heavy syrup, solids and liquids",Fruits and Fruit Juices,0.39,0.006,0.006,1,0.0019,0.088,18.91,17.91,0.07,80.4,73,0.01,0.013,0.031,29946
"Fruit cocktail, (peach and pineapple and pear and grape and cherry), canned, juice pack, solids and liquids",Fruits and Fruit Juices,0.46,0.008,0.004,1,0.0027,0.095,11.86,10.86,0.01,87.44,46,0,0.002,0.004,29947
"Fruit cocktail, (peach and pineapple and pear and grape and cherry), canned, light syrup, solids and liquids",Fruits and Fruit Juices,0.4,0.006,0.006,1,0.0019,0.089,14.93,13.93,0.07,84.37,57,0.01,0.014,0.031,29948
"Fruit cocktail, (peach and pineapple and pear and grape and cherry), canned, water pack, solids and liquids",Fruits and Fruit Juices,0.42,0.005,0.004,1,0.0021000000000000003,0.094,8.51,7.51,0.05,90.76,32,0.006,0.009,0.02,29949
"Fruit cocktail, canned, heavy syrup, drained",Fruits and Fruit Juices,0.47,0.007,0.006,1.7,0.0019,0.09,18.8,17.14,0.1,80.4,70,0.014,0.018,0.042,29950
"Fruit salad, (peach and pear and apricot and pineapple and cherry), canned, extra heavy syrup, solids and liquids",Fruits and Fruit Juices,0.33,0.006,0.005,1,0.0021000000000000003,0.08,22.77,0,0.06,76.64,88,0.009,0.012,0.027,29951
"Fruit salad, (peach and pear and apricot and pineapple and cherry), canned, heavy syrup, solids and liquids",Fruits and Fruit Juices,0.34,0.006,0.006,1,0.0024,0.08,19.11,18.11,0.07,80.26,73,0.01,0.014,0.031,29952
"Fruit salad, (peach and pear and apricot and pineapple and cherry), canned, juice pack, solids and liquids",Fruits and Fruit Juices,0.51,0.011,0.005,1,0.0033,0.116,13.05,0,0.03,86.15,50,0.004,0.005,0.011,29953
"Fruit salad, (peach and pear and apricot and pineapple and cherry), canned, light syrup, solids and liquids",Fruits and Fruit Juices,0.34,0.007,0.006,1,0.0025,0.082,15.14,0,0.07,84.24,58,0.009,0.012,0.028,29954
"Fruit salad, (peach and pear and apricot and pineapple and cherry), canned, water pack, solids and liquids",Fruits and Fruit Juices,0.35,0.007,0.003,1,0.0019,0.078,7.87,0,0.07,91.5,30,0.009,0.013,0.028,29955
"Fruit salad, (pineapple and papaya and banana and guava), tropical, canned, heavy syrup, solids and liquids",Fruits and Fruit Juices,0.41,0.013,0.002,1.3,0.0175,0.131,22.36,0,0.1,76.78,86,0.019,0.018,0.032,29956
"Fruit, mixed, (peach and cherry-sweet and -sour and raspberry and grape and boysenberry), frozen, sweetened",Fruits and Fruit Juices,1.42,0.007,0.003,1.9,0.075,0.131,24.23,0,0.18,73.73,98,0.025,0.034,0.078,29957
"Fruit, mixed, (peach and pear and pineapple), canned, heavy syrup, solids and liquids",Fruits and Fruit Juices,0.37,0.001,0.004,1,0.069,0.084,18.76,0,0.1,80.56,72,0.014,0.019,0.042,29958
"Fruit, mixed, (prune and apricot and pear), dried",Fruits and Fruit Juices,2.46,0.038,0.018,7.8,0.0038,0.796,64.06,0,0.49,31.18,243,0.04,0.232,0.11,29959
"Gooseberries, canned, light syrup pack, solids and liquids",Fruits and Fruit Juices,0.65,0.016,0.002,2.4,0.01,0.077,18.75,0,0.2,80.1,73,0.013,0.018,0.11,29960
"Gooseberries, raw",Fruits and Fruit Juices,0.88,0.025,0.001,4.3,0.0277,0.198,10.18,0,0.58,87.87,44,0.038,0.051,0.317,29961
"Grape juice cocktail, frozen concentrate, diluted with 3 volume water, with added ascorbic acid",Fruits and Fruit Juices,0.19,0.004,0.002,0.1,0.023899999999999998,0.021,12.75,12.65,0.09,86.9,51,0.029,0.004,0.026,29962
"Grape juice cocktail, frozen concentrate, undiluted, with added ascorbic acid",Fruits and Fruit Juices,0.65,0.013,0.007,0.3,0.0831,0.074,44.37,44.07,0.31,54.4,179,0.103,0.013,0.092,29963
"Grape juice, canned or bottled, unsweetened, with added ascorbic acid",Fruits and Fruit Juices,0.37,0.011,0.005,0.2,0.025,0.104,14.77,14.2,0.13,84.51,60,0.025,0.003,0.022,29964
"Grape juice, canned or bottled, unsweetened, without added ascorbic acid",Fruits and Fruit Juices,0.37,0.011,0.005,0.2,0.0001,0.104,14.77,14.2,0.13,84.51,60,0.025,0.003,0.022,29965
"Grapefruit juice, pink, raw",Fruits and Fruit Juices,0.5,0.009,0.001,0,0.038,0.162,9.2,0,0.1,90,39,0.014,0.013,0.024,29966
"Grapefruit juice, white, canned, sweetened",Fruits and Fruit Juices,0.58,0.008,0.002,0.1,0.0269,0.162,11.13,11.03,0.09,87.38,46,0.012,0.012,0.021,29967
"Grapefruit juice, white, canned, unsweetened",Fruits and Fruit Juices,0.52,0.007,0.001,0.1,0.0292,0.153,8.96,8.86,0.1,90.1,38,0.013,0.013,0.023,29968
"Grapefruit juice, white, frozen concentrate, unsweetened, diluted with 3 volume water",Fruits and Fruit Juices,0.55,0.008,0.001,0.1,0.0337,0.136,9.73,9.63,0.13,89.3,41,0.019,0.018,0.032,29969
"Grapefruit juice, white, frozen concentrate, unsweetened, undiluted",Fruits and Fruit Juices,1.97,0.027,0.003,0.4,0.1198,0.484,34.56,34.16,0.48,62,146,0.064,0.063,0.113,29970
"Grapefruit juice, white, raw",Fruits and Fruit Juices,0.5,0.009,0.001,0.1,0.038,0.162,9.2,9.1,0.1,90,39,0.014,0.013,0.024,29971
"Grapefruit, raw, pink and red and white, all areas",Fruits and Fruit Juices,0.63,0.012,0,1.1,0.0344,0.139,8.08,6.98,0.1,90.89,32,0.014,0.013,0.024,29972
"Grapefruit, raw, pink and red, California and Arizona",Fruits and Fruit Juices,0.5,0.011,0.001,0,0.0381,0.147,9.69,0,0.1,89.31,37,0.014,0.013,0.024,29973
"Grapefruit, raw, pink and red, Florida",Fruits and Fruit Juices,0.55,0.015,0,1.1,0.037,0.127,7.5,0,0.1,91.56,30,0.014,0.013,0.024,29974
"Grapefruit, raw, pink and red, all areas",Fruits and Fruit Juices,0.77,0.022,0,1.6,0.0312,0.135,10.66,6.89,0.14,88.06,42,0.021,0.02,0.036,29975
"Grapefruit, raw, white, California",Fruits and Fruit Juices,0.88,0.012,0,0,0.033299999999999996,0.143,9.09,0,0.1,89.58,37,0.014,0.013,0.024,29976
"Grapefruit, raw, white, Florida",Fruits and Fruit Juices,0.63,0.015,0,0,0.037,0.15,8.19,0,0.1,90.76,32,0.014,0.013,0.024,29977
"Grapefruit, raw, white, all areas",Fruits and Fruit Juices,0.69,0.012,0,1.1,0.033299999999999996,0.148,8.41,7.31,0.1,90.48,33,0.014,0.013,0.024,29978
"Grapefruit, sections, canned, juice pack, solids and liquids",Fruits and Fruit Juices,0.7,0.015,0.007,0.4,0.0339,0.169,9.21,8.8,0.09,89.67,37,0.012,0.012,0.021,29979
"Grapefruit, sections, canned, light syrup pack, solids and liquids",Fruits and Fruit Juices,0.56,0.014,0.002,0.4,0.0213,0.129,15.44,15.04,0.1,83.59,60,0.014,0.013,0.024,29980
"Grapefruit, sections, canned, water pack, solids and liquids",Fruits and Fruit Juices,0.58,0.015,0.002,0.4,0.0218,0.132,9.15,8.75,0.1,89.85,36,0.014,0.014,0.024,29981
"Grapes, american type (slip skin), raw",Fruits and Fruit Juices,0.63,0.014,0.002,0.9,0.004,0.191,17.15,16.25,0.35,81.3,67,0.114,0.014,0.102,29982
"Grapes, canned, thompson seedless, heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.48,0.01,0.005,0.6,0.001,0.103,19.65,19.05,0.1,79.53,76,0.033,0.004,0.03,29983
"Grapes, canned, thompson seedless, water pack, solids and liquids",Fruits and Fruit Juices,0.5,0.01,0.006,0.6,0.001,0.107,10.3,9.7,0.11,88.84,40,0.035,0.004,0.031,29984
"Grapes, muscadine, raw",Fruits and Fruit Juices,0.81,0.037,0.001,3.9,0,0.203,13.93,0,0.47,84.29,57,0,0,0,29985
"Grapes, red or green (European type, such as Thompson seedless), raw",Fruits and Fruit Juices,0.72,0.01,0.002,0.9,0.0108,0.191,18.1,15.48,0.16,80.54,69,0.054,0.007,0.048,29986
"Groundcherries, (cape-gooseberries or poha), raw",Fruits and Fruit Juices,1.9,0.009,0,0,0.011,0,11.2,0,0.7,85.4,53,0,0,0,29987
"Guanabana nectar, canned",Fruits and Fruit Juices,0.11,0.007,0.008,0.1,0.0111,0.025,14.93,13.07,0.17,84.72,59,0.029,0.051,0.039,29988
"Guava nectar, canned",Fruits and Fruit Juices,0.09,0.011,0.007,1,0.0197,0.038,14.87,12.37,0.06,84.89,57,0,0,0,29989
"Guava sauce, cooked",Fruits and Fruit Juices,0.32,0.007,0.004,3.6,0.1464,0.225,9.48,5.88,0.14,89.56,36,0.04,0.013,0.059,29990
"Guavas, common, raw",Fruits and Fruit Juices,2.55,0.018,0.002,5.4,0.2283,0.417,14.32,8.92,0.95,80.8,68,0.272,0.087,0.401,29991
"Guavas, strawberry, raw",Fruits and Fruit Juices,0.58,0.021,0.037,5.4,0.037,0.292,17.36,0,0.6,80.66,69,0.172,0.055,0.253,29992
Horned melon (Kiwano),Fruits and Fruit Juices,1.78,0.013,0.002,0,0.0053,0.123,7.56,0,1.26,88.97,44,0,0,0,29993
"Jackfruit, canned, syrup pack",Fruits and Fruit Juices,0.36,0.044,0.011,0.9,0.0005,0.096,23.94,0,0.14,75.28,92,0,0,0,29994
"Jackfruit, raw",Fruits and Fruit Juices,1.72,0.024,0.002,1.5,0.013699999999999999,0.448,23.25,19.08,0.64,73.46,95,0.195,0.155,0.094,29995
"Java-plum, (jambolan), raw",Fruits and Fruit Juices,0.72,0.019,0.014,0,0.0143,0.079,15.56,0,0.23,83.13,60,0,0,0,29996
"Juice, apple and grape blend, with added ascorbic acid",Fruits and Fruit Juices,0.16,0.011,0.007,0,0.0282,0.096,12.46,10.92,0.12,87.05,50,0,0,0,29997
"Juice, apple, grape and pear blend, with added ascorbic acid and calcium",Fruits and Fruit Juices,0.17,0.072,0.005,0.2,0.0507,0.089,12.96,9.95,0.12,86.44,52,0.021,0.005,0.04,29998
"Jujube, dried",Fruits and Fruit Juices,3.7,0.079,0.009,0,0.013,0.531,73.6,0,1.1,19.7,287,0,0,0,29999
"Jujube, raw",Fruits and Fruit Juices,1.2,0.021,0.003,0,0.069,0.25,20.23,0,0.2,77.86,79,0,0,0,30000
"Kiwifruit, gold, raw",Fruits and Fruit Juices,1.23,0.02,0.003,2,0.10540000000000001,0.316,14.23,10.98,0.56,83.22,60,0.149,0.036,0.207,30001
"Kiwifruit, green, raw",Fruits and Fruit Juices,1.14,0.034,0.003,3,0.0927,0.312,14.66,8.99,0.52,83.07,61,0.029,0.047,0.287,30002
"Kumquats, raw",Fruits and Fruit Juices,1.88,0.062,0.01,6.5,0.0439,0.186,15.9,9.36,0.86,80.85,71,0.103,0.154,0.171,30003
"Lemon juice, canned or bottled",Fruits and Fruit Juices,0.4,0.011,0.021,0.4,0.0248,0.102,6.48,2.4,0.29,92.46,21,0.038,0.011,0.085,30004
"Lemon juice, frozen, unsweetened, single strength",Fruits and Fruit Juices,0.46,0.008,0.001,0.4,0.0315,0.089,6.5,2.4,0.32,92.39,22,0.043,0.012,0.097,30005
"Lemon juice, raw",Fruits and Fruit Juices,0.35,0.006,0.001,0.3,0.038700000000000005,0.103,6.9,2.52,0.24,92.31,22,0.04,0.006,0.021,30006
"Lemon peel, raw",Fruits and Fruit Juices,1.5,0.134,0.006,10.6,0.129,0.16,16,4.17,0.3,81.6,47,0.039,0.011,0.089,30007
"Lemons, raw, without peel",Fruits and Fruit Juices,1.1,0.026,0.002,2.8,0.053,0.138,9.32,2.5,0.3,88.98,29,0.039,0.011,0.089,30008
"Lime juice, canned or bottled, unsweetened",Fruits and Fruit Juices,0.25,0.012,0.016,0.4,0.0064,0.075,6.69,1.37,0.23,92.52,21,0.026,0.022,0.064,30009
"Lime juice, raw",Fruits and Fruit Juices,0.42,0.014,0.002,0.4,0.03,0.117,8.42,1.69,0.07,90.79,25,0.008,0.008,0.023,30010
"Limes, raw",Fruits and Fruit Juices,0.7,0.033,0.002,2.8,0.0291,0.102,10.54,1.69,0.2,88.26,30,0.022,0.019,0.055,30011
"Litchis, dried",Fruits and Fruit Juices,3.8,0.033,0.003,4.6,0.183,1.11,70.7,66.1,1.2,22.3,277,0.27,0.328,0.361,30012
"Litchis, raw",Fruits and Fruit Juices,0.83,0.005,0.001,1.3,0.0715,0.171,16.53,15.23,0.44,81.76,66,0.099,0.12,0.132,30013
"Loganberries, frozen",Fruits and Fruit Juices,1.52,0.026,0.001,5.3,0.015300000000000001,0.145,13.02,7.7,0.31,84.61,55,0.011,0.03,0.176,30014
"Longans, dried",Fruits and Fruit Juices,4.9,0.045,0.048,0,0.028,0.658,74,0,0.4,17.6,286,0,0,0,30015
"Longans, raw",Fruits and Fruit Juices,1.31,0.001,0,1.1,0.084,0.266,15.14,0,0.1,82.75,60,0,0,0,30016
"Loquats, raw",Fruits and Fruit Juices,0.43,0.016,0.001,1.7,0.001,0.266,12.14,0,0.2,86.73,47,0.04,0.008,0.091,30017
"Mammy-apple, (mamey), raw",Fruits and Fruit Juices,0.5,0.011,0.015,3,0.014,0.047,12.5,0,0.5,86.2,51,0.136,0.205,0.079,30018
"Mango nectar, canned",Fruits and Fruit Juices,0.11,0.017,0.005,0.3,0.0152,0.024,13.12,12.45,0.06,86.63,51,0.014,0.022,0.011,30019
"Mangos, raw",Fruits and Fruit Juices,0.82,0.011,0.001,1.6,0.0364,0.168,14.98,13.66,0.38,83.46,60,0.092,0.14,0.071,30020
"Mangosteen, canned, syrup pack",Fruits and Fruit Juices,0.41,0.012,0.007,1.8,0.0029,0.048,17.91,0,0.58,80.94,73,0,0,0,30021
"Maraschino cherries, canned, drained",Fruits and Fruit Juices,0.22,0.054,0.004,3.2,0,0.021,41.97,38.77,0.21,57.27,165,0.039,0.049,0.055,30022
"Melon balls, frozen",Fruits and Fruit Juices,0.84,0.01,0.031,0.7,0.0062,0.28,7.94,0,0.25,90.26,33,0.064,0.006,0.098,30023
"Melons, cantaloupe, raw",Fruits and Fruit Juices,0.84,0.009,0.016,0.9,0.0367,0.267,8.16,7.86,0.19,90.15,34,0.051,0.003,0.081,30024
"Melons, casaba, raw",Fruits and Fruit Juices,1.11,0.011,0.009,0.9,0.0218,0.182,6.58,5.69,0.1,91.85,28,0.025,0.002,0.039,30025
"Melons, honeydew, raw",Fruits and Fruit Juices,0.54,0.006,0.018,0.8,0.018,0.228,9.09,8.12,0.14,89.82,36,0.038,0.003,0.059,30026
"Mulberries, raw",Fruits and Fruit Juices,1.44,0.039,0.01,1.7,0.0364,0.194,9.8,8.1,0.39,87.68,43,0.027,0.041,0.207,30027
"Nance, canned, syrup, drained",Fruits and Fruit Juices,0.56,0.042,0.008,7,0.0108,0.194,22.79,15.66,1.28,74.85,95,0,0,0,30028
"Nance, frozen, unsweetened",Fruits and Fruit Juices,0.66,0.046,0.003,7.5,0.0925,0.244,16.97,8.31,1.16,80.64,73,0,0,0,30029
"Naranjilla (lulo) pulp, frozen, unsweetened",Fruits and Fruit Juices,0.44,0.008,0.004,1.1,0.0032,0.2,5.9,3.74,0.22,93.05,25,0,0,0,30030
"Nectarines, raw",Fruits and Fruit Juices,1.06,0.006,0,1.7,0.0054,0.201,10.55,7.89,0.32,87.59,44,0.025,0.088,0.113,30031
"Oheloberries, raw",Fruits and Fruit Juices,0.38,0.007,0.001,0,0.006,0.038,6.84,0,0.22,92.3,28,0,0,0,30032
"Olives, pickled, canned or bottled, green",Fruits and Fruit Juices,1.03,0.052,1.556,3.3,0,0.042,3.84,0.54,15.32,75.28,145,2.029,11.314,1.307,30033
"Olives, ripe, canned (jumbo-super colossal)",Fruits and Fruit Juices,0.97,0.094,0.735,2.5,0.0015,0.009,5.61,0,6.87,84.34,81,0.909,5.071,0.586,30034
"Olives, ripe, canned (small-extra large)",Fruits and Fruit Juices,0.84,0.088,0.735,3.2,0.0009,0.008,6.26,0,10.68,79.99,115,1.415,7.888,0.911,30035
"Orange juice, canned, unsweetened",Fruits and Fruit Juices,0.68,0.01,0.004,0.3,0.030100000000000002,0.184,11.01,8.76,0.15,87.72,47,0.018,0.025,0.034,30036
"Orange juice, chilled, includes from concentrate",Fruits and Fruit Juices,0.68,0.011,0.002,0.3,0.033600000000000005,0.178,11.54,8.31,0.12,87.22,49,0.014,0.022,0.03,30037
"Orange juice, chilled, includes from concentrate, fortified with calcium",Fruits and Fruit Juices,0.68,0.201,0.002,0.3,0.033600000000000005,0.178,11.27,8.31,0.12,87.22,47,0.014,0.022,0.03,30038
"Orange juice, chilled, includes from concentrate, fortified with calcium and vitamin D",Fruits and Fruit Juices,0.68,0.201,0.002,0.3,0.033600000000000005,0.178,11.27,8.31,0.12,87.22,47,0.014,0.022,0.03,30039
"Orange juice, frozen concentrate, unsweetened, diluted with 3 volume water",Fruits and Fruit Juices,0.68,0.009,0.001,0.2,0.0389,0.19,10.78,8.4,0.06,88.1,45,0.007,0.01,0.012,30040
"Orange juice, frozen concentrate, unsweetened, undiluted",Fruits and Fruit Juices,2.39,0.032,0.003,0.8,0.1379,0.674,38.17,37.37,0.21,57.85,159,0.024,0.038,0.041,30041
"Orange juice, raw",Fruits and Fruit Juices,0.7,0.011,0.001,0.2,0.05,0.2,10.4,8.4,0.2,88.3,45,0.024,0.036,0.04,30042
"Orange peel, raw",Fruits and Fruit Juices,1.5,0.161,0.003,10.6,0.136,0.212,25,0,0.2,72.5,97,0.024,0.036,0.04,30043
"Orange-grapefruit juice, canned, unsweetened",Fruits and Fruit Juices,0.6,0.008,0.003,0.1,0.0291,0.158,10.28,10.18,0.1,88.64,43,0.011,0.017,0.019,30044
Orange-strawberry-banana juice,Fruits and Fruit Juices,0.42,0.008,0.004,0.2,0.025,0.146,12.34,9.58,0.16,86.75,50,0.023,0.02,0.042,30045
"Oranges, raw, California, valencias",Fruits and Fruit Juices,1.04,0.04,0,2.5,0.0485,0.179,11.89,0,0.3,86.34,49,0.035,0.055,0.06,30046
"Oranges, raw, Florida",Fruits and Fruit Juices,0.7,0.043,0,2.4,0.045,0.169,11.54,9.14,0.21,87.14,46,0.025,0.039,0.042,30047
"Oranges, raw, all commercial varieties",Fruits and Fruit Juices,0.94,0.04,0,2.4,0.053200000000000004,0.181,11.75,9.35,0.12,86.75,47,0.015,0.023,0.025,30048
"Oranges, raw, navels",Fruits and Fruit Juices,0.91,0.043,0.001,2.2,0.0591,0.166,12.54,8.5,0.15,85.97,49,0.017,0.03,0.031,30049
"Oranges, raw, with peel",Fruits and Fruit Juices,1.3,0.07,0.002,4.5,0.071,0.196,15.5,0,0.3,82.3,63,0.035,0.055,0.06,30050
"Papaya nectar, canned",Fruits and Fruit Juices,0.17,0.01,0.005,0.6,0.003,0.031,14.51,13.91,0.15,85.02,57,0.047,0.041,0.035,30051
"Papaya, canned, heavy syrup, drained",Fruits and Fruit Juices,0.14,0.021,0.009,1.5,0.0035,0.067,55.83,52.2,0.55,43.33,206,0.268,0.076,0.096,30052
"Papayas, raw",Fruits and Fruit Juices,0.47,0.02,0.008,1.7,0.060899999999999996,0.182,10.82,7.82,0.26,88.06,43,0.081,0.072,0.058,30053
"Passion-fruit juice, purple, raw",Fruits and Fruit Juices,0.39,0.004,0.006,0.2,0.0298,0.278,13.6,13.4,0.05,85.62,51,0.004,0.006,0.029,30054
"Passion-fruit juice, yellow, raw",Fruits and Fruit Juices,0.67,0.004,0.006,0.2,0.0182,0.278,14.45,14.25,0.18,84.21,60,0.015,0.022,0.106,30055
"Passion-fruit, (granadilla), purple, raw",Fruits and Fruit Juices,2.2,0.012,0.028,10.4,0.03,0.348,23.38,11.2,0.7,72.93,97,0.059,0.086,0.411,30056
"Peach nectar, canned, with added ascorbic acid",Fruits and Fruit Juices,0.27,0.005,0.007,0.6,0.0268,0.04,13.92,0,0.02,85.64,54,0.002,0.008,0.011,30057
"Peach nectar, canned, without added ascorbic acid",Fruits and Fruit Juices,0.27,0.005,0.007,0.6,0.0053,0.04,13.92,13.32,0.02,85.64,54,0.002,0.008,0.011,30058
"Peaches, canned, extra heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.47,0.003,0.008,1,0.0012,0.083,26.06,0,0.03,73.19,96,0.003,0.011,0.014,30059
"Peaches, canned, extra light syrup, solids and liquids",Fruits and Fruit Juices,0.4,0.005,0.005,1,0.003,0.074,11.1,0,0.1,88.2,42,0.011,0.036,0.048,30060
"Peaches, canned, heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.45,0.003,0.006,1.3,0.0028,0.092,19.94,18.64,0.1,79.28,74,0.01,0.035,0.047,30061
"Peaches, canned, heavy syrup, drained",Fruits and Fruit Juices,0.52,0.003,0.006,1.2,0.0007,0.094,18.43,14.66,0.18,80.62,72,0.013,0.047,0.066,30062
"Peaches, canned, juice pack, solids and liquids",Fruits and Fruit Juices,0.63,0.006,0.004,1.3,0.0036,0.128,11.57,10.27,0.03,87.49,44,0.004,0.012,0.016,30063
"Peaches, canned, light syrup pack, solids and liquids",Fruits and Fruit Juices,0.45,0.003,0.005,1.3,0.0024,0.097,14.55,13.25,0.03,84.72,54,0.003,0.012,0.015,30064
"Peaches, canned, water pack, solids and liquids",Fruits and Fruit Juices,0.44,0.002,0.003,1.3,0.0029,0.099,6.11,4.81,0.06,93.13,24,0.006,0.021,0.028,30065
"Peaches, dehydrated (low-moisture), sulfured, stewed",Fruits and Fruit Juices,2.01,0.016,0.004,0,0.0068,0.554,34.14,0,0.42,62.04,133,0.045,0.154,0.204,30066
"Peaches, dehydrated (low-moisture), sulfured, uncooked",Fruits and Fruit Juices,4.89,0.038,0.01,0,0.0106,1.351,83.18,0,1.03,7.5,325,0.111,0.377,0.498,30067
"Peaches, dried, sulfured, stewed, with added sugar",Fruits and Fruit Juices,1.06,0.008,0.002,2.4,0.0034,0.292,26.6,0,0.22,71.38,103,0.024,0.082,0.108,30068
"Peaches, dried, sulfured, stewed, without added sugar",Fruits and Fruit Juices,1.16,0.009,0.002,2.7,0.0037,0.32,19.69,16.99,0.25,78.1,77,0.026,0.089,0.118,30069
"Peaches, dried, sulfured, uncooked",Fruits and Fruit Juices,3.61,0.028,0.007,8.2,0.0048,0.996,61.33,41.74,0.76,31.8,239,0.082,0.278,0.367,30070
"Peaches, frozen, sliced, sweetened",Fruits and Fruit Juices,0.63,0.003,0.006,1.8,0.0942,0.13,23.98,22.18,0.13,74.73,94,0.014,0.048,0.064,30071
"Peaches, raw",Fruits and Fruit Juices,0.91,0.006,0,1.5,0.0066,0.19,9.54,8.39,0.25,88.87,39,0.019,0.067,0.086,30072
"Peaches, spiced, canned, heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.41,0.006,0.004,1.3,0.0053,0.085,20.08,18.78,0.1,79.2,75,0.011,0.036,0.048,30073
"Pear nectar, canned, with added ascorbic acid",Fruits and Fruit Juices,0.11,0.005,0.004,0.6,0.027,0.013,15.76,0,0.01,84.01,60,0,0.003,0.003,30074
"Pear nectar, canned, without added ascorbic acid",Fruits and Fruit Juices,0.11,0.005,0.004,0.6,0.0011,0.013,15.76,15.16,0.01,84.01,60,0,0.003,0.003,30075
"Pears, asian, raw",Fruits and Fruit Juices,0.5,0.004,0,3.6,0.0038,0.121,10.65,7.05,0.23,88.25,42,0.012,0.049,0.055,30076
"Pears, canned, extra heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.19,0.005,0.005,1.6,0.0011,0.064,25.25,0,0.13,74.28,97,0.007,0.026,0.03,30077
"Pears, canned, extra light syrup pack, solids and liquids",Fruits and Fruit Juices,0.3,0.007,0.002,1.6,0.002,0.045,12.2,0,0.1,87.3,47,0.006,0.021,0.023,30078
"Pears, canned, heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.2,0.005,0.005,1.6,0.0011,0.065,19.17,15.2,0.13,80.35,74,0.007,0.027,0.03,30079
"Pears, canned, heavy syrup, drained",Fruits and Fruit Juices,0.24,0.006,0.005,2.7,0.0011,0.066,19.08,16.42,0.18,80.35,74,0.01,0.037,0.041,30080
"Pears, canned, juice pack, solids and liquids",Fruits and Fruit Juices,0.34,0.009,0.004,1.6,0.0016,0.096,12.94,9.7,0.07,86.47,50,0.004,0.014,0.015,30081
"Pears, canned, light syrup pack, solids and liquids",Fruits and Fruit Juices,0.19,0.005,0.005,1.6,0.0007,0.066,15.17,12.1,0.03,84.46,57,0.002,0.006,0.007,30082
"Pears, canned, water pack, solids and liquids",Fruits and Fruit Juices,0.19,0.004,0.002,1.6,0.001,0.053,7.81,6.1,0.03,91.81,29,0.002,0.006,0.007,30083
"Pears, dried, sulfured, stewed, with added sugar",Fruits and Fruit Juices,0.86,0.015,0.003,5.8,0.0038,0.245,37.14,0,0.29,61.2,140,0.016,0.061,0.068,30084
"Pears, dried, sulfured, stewed, without added sugar",Fruits and Fruit Juices,0.91,0.016,0.003,6.4,0.004,0.258,33.81,27.41,0.31,64.44,127,0.017,0.064,0.072,30085
"Pears, dried, sulfured, uncooked",Fruits and Fruit Juices,1.87,0.034,0.006,7.5,0.007,0.533,69.7,62.2,0.63,26.69,262,0.035,0.132,0.148,30086
"Pears, raw",Fruits and Fruit Juices,0.38,0.009,0.001,3.1,0.004200000000000001,0.119,15.46,9.8,0.12,83.71,58,0.006,0.026,0.029,30087
"Persimmons, japanese, dried",Fruits and Fruit Juices,1.38,0.025,0.002,14.5,0,0.802,73.43,0,0.59,23.01,274,0,0,0,30088
"Persimmons, japanese, raw",Fruits and Fruit Juices,0.58,0.008,0.001,3.6,0.0075,0.161,18.59,12.53,0.19,80.32,70,0.02,0.037,0.043,30089
"Persimmons, native, raw",Fruits and Fruit Juices,0.8,0.027,0.001,0,0.066,0.31,33.5,0,0.4,64.4,127,0,0,0,30090
"Pineapple juice, canned, unsweetened, with added ascorbic acid",Fruits and Fruit Juices,0.36,0.013,0.002,0.2,0.0438,0.13,12.87,9.98,0.12,86.37,53,0.008,0.014,0.042,30091
"Pineapple juice, canned, unsweetened, without added ascorbic acid",Fruits and Fruit Juices,0.36,0.013,0.002,0.2,0.01,0.13,12.87,9.98,0.12,86.37,53,0.008,0.014,0.042,30092
"Pineapple juice, frozen concentrate, unsweetened, diluted with 3 volume water",Fruits and Fruit Juices,0.4,0.013,0.001,0.2,0.012,0.132,12.67,12.47,0.03,86.6,51,0.002,0.003,0.01,30093
"Pineapple juice, frozen concentrate, unsweetened, undiluted",Fruits and Fruit Juices,1.3,0.039,0.003,0.7,0.042,0.472,44.3,43.6,0.1,53.1,179,0.007,0.012,0.035,30094
"Pineapple, canned, extra heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.34,0.014,0.001,0.8,0.0073,0.102,21.5,0,0.11,77.71,83,0.009,0.013,0.038,30095
"Pineapple, canned, heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.35,0.014,0.001,0.8,0.0074,0.104,20.2,16.9,0.11,78.99,78,0.009,0.013,0.04,30096
"Pineapple, canned, juice pack, drained",Fruits and Fruit Juices,0.51,0.016,0.001,1.3,0.0094,0.124,15.56,14.26,0.11,83.51,60,0.008,0.014,0.04,30097
"Pineapple, canned, juice pack, solids and liquids",Fruits and Fruit Juices,0.42,0.014,0.001,0.8,0.0095,0.122,15.7,14.45,0.08,83.51,60,0.006,0.01,0.029,30098
"Pineapple, canned, light syrup pack, solids and liquids",Fruits and Fruit Juices,0.36,0.014,0.001,0.8,0.0075,0.105,13.45,12.65,0.12,85.73,52,0.009,0.013,0.04,30099
"Pineapple, canned, water pack, solids and liquids",Fruits and Fruit Juices,0.43,0.015,0.001,0.8,0.0077,0.127,8.3,7.5,0.09,90.82,32,0.006,0.011,0.031,30100
"Pineapple, frozen, chunks, sweetened",Fruits and Fruit Juices,0.4,0.009,0.002,1.1,0.008,0.1,22.2,21.1,0.1,77.1,86,0.007,0.012,0.035,30101
"Pineapple, raw, all varieties",Fruits and Fruit Juices,0.54,0.013,0.001,1.4,0.047799999999999995,0.109,13.12,9.85,0.12,86,50,0.009,0.013,0.04,30102
"Pineapple, raw, extra sweet variety",Fruits and Fruit Juices,0.53,0.013,0.001,1.4,0.0564,0.108,13.5,10.32,0.11,85.66,51,0,0,0,30103
"Pineapple, raw, traditional varieties",Fruits and Fruit Juices,0.55,0.013,0.001,0,0.0169,0.125,11.82,8.29,0.13,87.24,45,0,0,0,30104
"Pitanga, (surinam-cherry), raw",Fruits and Fruit Juices,0.8,0.009,0.003,0,0.0263,0.103,7.49,0,0.4,90.81,33,0,0,0,30105
"Plantains, cooked",Fruits and Fruit Juices,0.79,0.002,0.005,2.3,0.0109,0.465,31.15,14,0.18,67.3,116,0.069,0.015,0.033,30106
"Plantains, green, fried",Fruits and Fruit Juices,1.5,0.004,0.002,3.5,0.0034,0.482,49.17,3.63,11.81,36.08,309,3.69,4.353,2.697,30107
"Plantains, raw",Fruits and Fruit Juices,1.3,0.003,0.004,2.3,0.0184,0.499,31.89,15,0.37,65.28,122,0.143,0.032,0.069,30108
"Plantains, yellow, fried, Latino restaurant",Fruits and Fruit Juices,1.42,0.006,0.006,3.2,0,0.507,40.77,21.76,7.51,49.04,236,1.816,2.319,2.275,30109
"Plums, canned, heavy syrup, drained",Fruits and Fruit Juices,0.44,0.01,0.019,1.5,0.0004,0.093,23.12,21.58,0.14,76.06,89,0.011,0.09,0.03,30110
"Plums, canned, purple, extra heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.36,0.009,0.019,1,0.0004,0.089,26.31,0,0.1,73,101,0.008,0.065,0.021,30111
"Plums, canned, purple, heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.36,0.009,0.019,0.9,0.0004,0.091,23.24,22.31,0.1,76.06,89,0.008,0.066,0.022,30112
"Plums, canned, purple, juice pack, solids and liquids",Fruits and Fruit Juices,0.51,0.01,0.001,0.9,0.0028,0.154,15.15,14.22,0.02,84.02,58,0.002,0.014,0.005,30113
"Plums, canned, purple, light syrup pack, solids and liquids",Fruits and Fruit Juices,0.37,0.009,0.02,0.9,0.0004,0.093,16.28,15.35,0.1,83,63,0.008,0.069,0.023,30114
"Plums, canned, purple, water pack, solids and liquids",Fruits and Fruit Juices,0.39,0.007,0.001,0.9,0.0027,0.126,11.03,10.1,0.01,88.35,41,0,0.005,0.002,30115
"Plums, dried (prunes), stewed, with added sugar",Fruits and Fruit Juices,1.09,0.021,0.002,3.8,0.0027,0.312,32.88,0,0.22,65.08,124,0.017,0.142,0.047,30116
"Plums, dried (prunes), stewed, without added sugar",Fruits and Fruit Juices,0.96,0.019,0.001,3.1,0.0029,0.321,28.08,24.98,0.16,69.73,107,0.01,0.08,0.026,30117
"Plums, dried (prunes), uncooked",Fruits and Fruit Juices,2.18,0.043,0.002,7.1,0.0006,0.732,63.88,38.13,0.38,30.92,240,0.088,0.053,0.062,30118
"Plums, raw",Fruits and Fruit Juices,0.7,0.006,0,1.4,0.0095,0.157,11.42,9.92,0.28,87.23,46,0.017,0.134,0.044,30119
"Pomegranate juice, bottled",Fruits and Fruit Juices,0.15,0.011,0.009,0.1,0.0001,0.214,13.13,12.65,0.29,85.95,54,0.077,0.059,0.05,30120
"Pomegranates, raw",Fruits and Fruit Juices,1.67,0.01,0.003,4,0.010199999999999999,0.236,18.7,13.67,1.17,77.93,83,0.12,0.093,0.079,30121
"Prickly pears, raw",Fruits and Fruit Juices,0.73,0.056,0.005,3.6,0.014,0.22,9.57,0,0.51,87.55,41,0.067,0.075,0.213,30122
"Prune juice, canned",Fruits and Fruit Juices,0.61,0.012,0.004,1,0.0040999999999999995,0.276,17.45,16.45,0.03,81.24,71,0.003,0.021,0.007,30123
Prune puree,Fruits and Fruit Juices,2.1,0.031,0.023,3.3,0.0043,0.852,65.1,39,0.2,30,257,0.016,0,0,30124
"Prunes, canned, heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.87,0.017,0.003,3.8,0.0028,0.226,27.8,0,0.2,70.67,105,0.016,0.13,0.043,30125
"Prunes, dehydrated (low-moisture), stewed",Fruits and Fruit Juices,1.23,0.024,0.002,0,0,0.353,29.7,0,0.24,67.99,113,0.02,0.161,0.053,30126
"Prunes, dehydrated (low-moisture), uncooked",Fruits and Fruit Juices,3.7,0.072,0.005,0,0,1.058,89.07,0,0.73,4,339,0.059,0.483,0.159,30127
"Pummelo, raw",Fruits and Fruit Juices,0.76,0.004,0.001,1,0.061,0.216,9.62,0,0.04,89.1,38,0,0,0,30128
"Quinces, raw",Fruits and Fruit Juices,0.4,0.011,0.004,1.9,0.015,0.197,15.3,0,0.1,83.8,57,0.01,0.036,0.05,30129
"Raisins, golden seedless",Fruits and Fruit Juices,3.39,0.053,0.012,4,0.0032,0.746,79.52,59.19,0.46,14.97,302,0.151,0.019,0.135,30130
"Raisins, seeded",Fruits and Fruit Juices,2.52,0.028,0.028,6.8,0.0054,0.825,78.47,0,0.54,16.57,296,0.178,0.022,0.159,30131
"Raisins, seedless",Fruits and Fruit Juices,3.07,0.05,0.011,3.7,0.0023,0.749,79.18,59.19,0.46,15.43,299,0.058,0.051,0.037,30132
"Rambutan, canned, syrup pack",Fruits and Fruit Juices,0.65,0.022,0.011,0.9,0.004900000000000001,0.042,20.87,0,0.21,78.04,82,0,0,0,30133
"Raspberries, canned, red, heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.83,0.011,0.003,3.3,0.0087,0.094,23.36,20.06,0.12,75.33,91,0.005,0.011,0.068,30134
"Raspberries, frozen, red, sweetened",Fruits and Fruit Juices,0.7,0.015,0.001,4.4,0.0165,0.114,26.16,21.76,0.16,72.75,103,0.005,0.015,0.089,30135
"Raspberries, raw",Fruits and Fruit Juices,1.2,0.025,0.001,6.5,0.026199999999999998,0.151,11.94,4.42,0.65,85.75,52,0.019,0.064,0.375,30136
"Rhubarb, frozen, cooked, with sugar",Fruits and Fruit Juices,0.39,0.145,0.001,2,0.0033,0.096,31.2,28.7,0.05,67.79,116,0.014,0.01,0.025,30137
"Rhubarb, frozen, uncooked",Fruits and Fruit Juices,0.55,0.194,0.002,1.8,0.0048,0.108,5.1,1.1,0.11,93.51,21,0.029,0.021,0.054,30138
"Rhubarb, raw",Fruits and Fruit Juices,0.9,0.086,0.004,1.8,0.008,0.288,4.54,1.1,0.2,93.61,21,0.053,0.039,0.099,30139
"Rose-apples, raw",Fruits and Fruit Juices,0.6,0.029,0,0,0.0223,0.123,5.7,0,0.3,93,25,0,0,0,30140
"Roselle, raw",Fruits and Fruit Juices,0.96,0.215,0.006,0,0.012,0.208,11.31,0,0.64,86.58,49,0,0,0,30141
"Rowal, raw",Fruits and Fruit Juices,2.3,0.015,0.004,6.2,0.0258,0.131,23.9,14.1,2,71.4,111,0.245,0,0,30142
"Sapodilla, raw",Fruits and Fruit Juices,0.44,0.021,0.012,5.3,0.0147,0.193,19.96,0,1.1,78,83,0.194,0.521,0.011,30143
"Sapote, mamey, raw",Fruits and Fruit Juices,1.45,0.018,0.007,5.4,0.023,0.454,32.1,20.14,0.46,64.87,124,0.169,0.102,0.097,30144
"Soursop, raw",Fruits and Fruit Juices,1,0.014,0.014,3.3,0.0206,0.278,16.84,13.54,0.3,81.16,66,0.051,0.09,0.069,30145
"Strawberries, canned, heavy syrup pack, solids and liquids",Fruits and Fruit Juices,0.56,0.013,0.004,1.7,0.0317,0.086,23.53,21.83,0.26,75.35,92,0.014,0.036,0.129,30146
"Strawberries, frozen, sweetened, sliced",Fruits and Fruit Juices,0.53,0.011,0.003,1.9,0.0414,0.098,25.92,24.01,0.13,73.18,96,0.007,0.018,0.064,30147
"Strawberries, frozen, sweetened, whole",Fruits and Fruit Juices,0.52,0.011,0.001,1.9,0.0395,0.098,21,18.61,0.14,78.05,78,0.007,0.019,0.068,30148
"Strawberries, frozen, unsweetened",Fruits and Fruit Juices,0.43,0.016,0.002,2.1,0.0412,0.148,9.13,4.56,0.11,89.97,35,0.006,0.015,0.054,30149
"Strawberries, raw",Fruits and Fruit Juices,0.67,0.016,0.001,2,0.0588,0.153,7.68,4.89,0.3,90.95,32,0.015,0.043,0.155,30150
"Sugar-apples, (sweetsop), raw",Fruits and Fruit Juices,2.06,0.024,0.009,4.4,0.0363,0.247,23.64,0,0.29,73.23,94,0.048,0.114,0.04,30151
"Tamarind nectar, canned",Fruits and Fruit Juices,0.09,0.01,0.007,0.5,0.0070999999999999995,0.027,14.73,12.7,0.12,84.97,57,0,0,0,30152
"Tamarinds, raw",Fruits and Fruit Juices,2.8,0.074,0.028,5.1,0.0035,0.628,62.5,57.4,0.6,31.4,239,0.272,0.181,0.059,30153
"Tangerine juice, canned, sweetened",Fruits and Fruit Juices,0.5,0.018,0.001,0.2,0.022,0.178,12,11.8,0.2,87,50,0.013,0.018,0.025,30154
"Tangerine juice, frozen concentrate, sweetened, diluted with 3 volume water",Fruits and Fruit Juices,0.43,0.008,0.001,0,0.0242,0.113,11.06,0,0.11,88.1,46,0.007,0.01,0.014,30155
"Tangerine juice, frozen concentrate, sweetened, undiluted",Fruits and Fruit Juices,1.5,0.027,0.003,0.6,0.0851,0.397,38.85,0,0.39,58.2,161,0.026,0.034,0.048,30156
"Tangerine juice, raw",Fruits and Fruit Juices,0.5,0.018,0.001,0.2,0.031,0.178,10.1,9.9,0.2,88.9,43,0.024,0.036,0.04,30157
"Tangerines, (mandarin oranges), canned, juice pack",Fruits and Fruit Juices,0.62,0.011,0.005,0.7,0.0342,0.133,9.57,8.87,0.03,89.51,37,0.003,0.005,0.005,30158
"Tangerines, (mandarin oranges), canned, juice pack, drained",Fruits and Fruit Juices,0.75,0.012,0.005,1.2,0.0339,0.136,9.41,8.25,0.04,89.51,38,0.004,0.007,0.007,30159
"Tangerines, (mandarin oranges), canned, light syrup pack",Fruits and Fruit Juices,0.45,0.007,0.006,0.7,0.0198,0.078,16.19,15.49,0.1,83.06,61,0.012,0.018,0.02,30160
"Tangerines, (mandarin oranges), raw",Fruits and Fruit Juices,0.81,0.037,0.002,1.8,0.026699999999999998,0.166,13.34,10.58,0.31,85.17,53,0.039,0.06,0.065,30161
"USDA Commodity peaches, canned, light syrup, drained",Fruits and Fruit Juices,0.56,0.003,0.007,0.7,0.0025,0.087,15.65,10.61,0.15,83.43,61,0,0,0,30162
"USDA Commodity pears, canned, juice pack, drained",Fruits and Fruit Juices,0.34,0.009,0.004,2.2,0.0003,0.099,12.91,8.27,0.19,86.26,51,0,0,0,30163
"USDA Commodity pears, canned, light syrup, drained",Fruits and Fruit Juices,0.28,0.005,0.005,1.6,0.0009,0.061,16.21,11.22,0.15,83.16,62,0,0,0,30164
"USDA Commodity, mixed fruit (peaches, pears, grapes), canned, light syrup, drained",Fruits and Fruit Juices,0.46,0.005,0.005,1.6,0.0022,0.089,14.65,11.25,0.1,84.6,57,0.01,0,0,30165
"USDA Commodity, mixed fruit (peaches, pears, grapes), canned, light syrup, solids and liquids",Fruits and Fruit Juices,0.41,0.005,0.006,1.2,0.0031,0.085,14.3,11.46,0.08,85.02,55,0.01,0,0,30166
"Watermelon, raw",Fruits and Fruit Juices,0.61,0.007,0.001,0.4,0.0081,0.112,7.55,6.2,0.15,91.45,30,0.016,0.037,0.05,30167
"HORMEL ALWAYS TENDER, Boneless Pork Loin, Fresh Pork",Pork Products,19.02,0.004,0.358,0,0.0013,0.31,0.76,0.08,7.18,71.35,145,2.84,3.42,0.64,30168
"HORMEL ALWAYS TENDER, Center Cut Chops, Fresh Pork",Pork Products,18.74,0.014,0.378,0,0.0017,0.307,0.84,0.28,9.62,69.17,167,3.6,4.43,0.83,30169
"HORMEL ALWAYS TENDER, Pork Loin Filets, Lemon Garlic-Flavored",Pork Products,17.83,0.006,0.59,0,0.0017,0.542,1.79,0.22,4.16,73.53,118,1.41,1.83,0.6,30170
"HORMEL ALWAYS TENDER, Pork Tenderloin, Peppercorn-Flavored",Pork Products,17.21,0.014,0.594,0,0.0014,0.586,1.82,0.14,3.8,74.44,110,1.16,1.45,1.05,30171
"HORMEL ALWAYS TENDER, Pork Tenderloin, Teriyaki-Flavored",Pork Products,18.2,0.007,0.413,0,0.0027,0.536,4.63,3.33,3.07,71.9,119,1.05,1.26,0.6,30172
HORMEL Canadian Style Bacon,Pork Products,16.88,0.006,1.016,0,0.0014,0.279,1.87,1.43,4.94,72.95,122,1.83,2.49,0.62,30173
"HORMEL, Cure 81 Ham",Pork Products,18.43,0.004,1.038,0,0.0016,0.319,0.21,0,3.59,74.25,106,1.17,1.75,0.37,30174
"Pork, Leg Cap Steak, boneless, separable lean and fat, raw",Pork Products,21.64,0.007,0.073,0,0,0.365,0,0,3.39,75.18,123,1.187,1.688,0.509,30175
"Pork, Leg sirloin tip roast, boneless, separable lean and fat, cooked, braised",Pork Products,31.11,0.005,0.043,0,0,0.363,0,0,2.56,66.99,156,0.791,1.022,0.474,30176
"Pork, Leg sirloin tip roast, boneless, separable lean and fat, raw",Pork Products,22.88,0.005,0.05,0,0,0.399,0,0,1.71,75.88,113,0.524,0.687,0.323,30177
"Pork, Shoulder breast, boneless, separable lean and fat, cooked, broiled",Pork Products,28.47,0.007,0.054,0,0,0.364,0,0,4.49,67.26,162,1.325,1.802,0.578,30178
"Pork, Shoulder breast, boneless, separable lean and fat, raw",Pork Products,22.54,0.007,0.054,0,0,0.378,0,0,3.4,74.37,127,1.074,1.473,0.488,30179
"Pork, Shoulder petite tender, boneless, separable lean and fat, raw",Pork Products,21.65,0.006,0.05,0,0,0.391,0,0,3.91,74.57,128,1.012,1.324,0.469,30180
"Pork, bacon, rendered fat, cooked",Pork Products,0.07,0.001,0.027,0,0,0.015,0,0,99.5,0.25,898,31.991,41.435,10.535,30181
"Pork, cured, bacon, cooked, baked",Pork Products,35.73,0.01,2.193,0,0,0.539,1.35,0,43.27,12.52,548,14.187,19.065,4.859,30182
"Pork, cured, bacon, cooked, broiled, pan-fried or roasted",Pork Products,37.04,0.011,2.31,0,0,0.565,1.43,0,41.78,12.32,541,13.739,18.52,4.548,30183
"Pork, cured, bacon, cooked, broiled, pan-fried or roasted, reduced sodium",Pork Products,37.04,0.011,1.03,0,0,0.565,1.43,0,41.78,12.32,541,13.739,18.52,4.548,30184
"Pork, cured, bacon, cooked, microwaved",Pork Products,38.62,0.011,2.073,0,0,0.498,1.05,0,37.27,16.49,505,12.102,16.336,4.372,30185
"Pork, cured, bacon, cooked, pan-fried",Pork Products,38.34,0.012,2.428,0,0,0.591,1.5,0,40.3,12.12,533,13.291,17.976,4.447,30186
"Pork, cured, bacon, raw",Pork Products,11.6,0.006,0.833,0,0,0.208,0.66,0,45.04,40.2,458,14.993,20.047,4.821,30187
"Pork, cured, breakfast strips, cooked",Pork Products,28.95,0.014,2.099,0,0,0.466,1.05,0,36.7,26.93,459,12.77,16.4,5.65,30188
"Pork, cured, breakfast strips, raw or unheated",Pork Products,11.74,0.008,0.987,0,0.0272,0.204,0.7,0,37.16,47.41,388,12.91,16.79,5.55,30189
"Pork, cured, canadian-style bacon, grilled",Pork Products,24.24,0.01,1.546,0,0,0.39,1.35,0,8.44,61.7,185,2.84,4.04,0.81,30190
"Pork, cured, canadian-style bacon, unheated",Pork Products,20.64,0.008,1.409,0,0,0.344,1.68,0,6.97,66.93,157,2.22,3.15,0.63,30191
"Pork, cured, feet, pickled",Pork Products,11.63,0.032,0.56,0,0,0.013,0.01,0,10.02,75,140,2.945,5.677,0.784,30192
"Pork, cured, ham -- water added, rump, bone-in, separable lean and fat, heated, roasted",Pork Products,20.1,0.008,1.101,0,0,0.244,0.99,0.78,8.56,67.23,161,2.897,3.918,1.02,30193
"Pork, cured, ham -- water added, rump, bone-in, separable lean and fat, unheated",Pork Products,13.99,0.006,1.069,0,0,0.199,0.8,0.62,12.5,69.49,172,4.176,5.639,1.566,30194
"Pork, cured, ham -- water added, rump, bone-in, separable lean only, heated, roasted",Pork Products,21.41,0.008,1.15,0,0,0.25,0.87,0.84,3.56,70.92,121,1.254,1.61,0.387,30195
"Pork, cured, ham -- water added, rump, bone-in, separable lean only, unheated",Pork Products,15.43,0.007,1.17,0,0,0.205,0.56,0.67,3.48,77.03,95,1.227,1.575,0.379,30196
"Pork, cured, ham -- water added, shank, bone-in, separable lean and fat, heated, roasted",Pork Products,18.62,0.009,0.988,0,0,0.232,1.35,0.79,13.37,63.43,200,4.371,6.143,1.746,30197
"Pork, cured, ham -- water added, shank, bone-in, separable lean and fat, unheated",Pork Products,16.65,0.007,0.964,0,0,0.219,0.33,0.66,11.02,69.21,167,3.607,4.975,1.457,30198
"Pork, cured, ham -- water added, shank, bone-in, separable lean only, heated, roasted",Pork Products,20.92,0.009,1.06,0,0,0.241,1.2,0.91,4.43,69.97,128,1.402,1.911,0.641,30199
"Pork, cured, ham -- water added, shank, bone-in, separable lean only, unheated",Pork Products,18.65,0.007,1.04,0,0,0.229,0,0.71,1.87,76.53,91,0.592,0.806,0.27,30200
"Pork, cured, ham -- water added, slice, bone-in, separable lean and fat, heated, pan-broil",Pork Products,20.8,0.011,1.309,0,0,0.28,1.12,1.54,8.73,65.48,166,2.901,4.032,1.229,30201
"Pork, cured, ham -- water added, slice, bone-in, separable lean and fat, unheated",Pork Products,15.73,0.008,1.011,0,0,0.213,0.58,1.1,10.77,69.88,162,3.554,4.88,1.459,30202
"Pork, cured, ham -- water added, slice, bone-in, separable lean only, heated, pan-broil",Pork Products,22.04,0.011,1.374,0,0,0.289,1.03,1.67,4.3,68.58,131,1.439,1.989,0.687,30203
"Pork, cured, ham -- water added, slice, bone-in, separable lean only, unheated",Pork Products,17.38,0.009,1.09,0,0,0.221,0.32,1.23,2.29,76.75,91,0.768,1.061,0.367,30204
"Pork, cured, ham -- water added, slice, boneless, separable lean and fat, heated, pan-broil",Pork Products,18.62,0.009,1.212,0,0,0.334,1.26,1.72,5.05,71.32,125,1.717,2.321,0.659,30205
"Pork, cured, ham -- water added, slice, boneless, separable lean only, heated, pan-broil",Pork Products,18.82,0.009,1.223,0,0,0.337,1.24,1.75,4.09,72.06,117,1.401,1.879,0.54,30206
"Pork, cured, ham -- water added, whole, boneless, separable lean and fat, heated, roasted",Pork Products,17.77,0.009,1.181,0,0,0.313,1.39,1.54,5.48,71.72,126,1.787,2.49,0.679,30207
"Pork, cured, ham -- water added, whole, boneless, separable lean and fat, unheated",Pork Products,17.06,0.008,1.126,0,0,0.312,1.2,1.42,5.38,72.85,121,1.889,2.571,0.759,30208
"Pork, cured, ham -- water added, whole, boneless, separable lean only, heated, roasted",Pork Products,17.99,0.009,1.193,0,0,0.316,1.38,1.57,4.39,72.58,117,1.424,1.983,0.542,30209
"Pork, cured, ham -- water added, whole, boneless, separable lean only, unheated",Pork Products,17.34,0.008,1.141,0,0,0.317,1.18,1.45,3.97,73.95,110,1.428,1.938,0.577,30210
"Pork, cured, ham and water product, rump, bone-in, separable lean and fat, heated, roasted",Pork Products,19.46,0.01,1.181,0,0,0.254,1.15,1.03,11.48,64.6,186,3.783,5.265,1.479,30211
"Pork, cured, ham and water product, rump, bone-in, separable lean and fat, unheated",Pork Products,16.09,0.009,0.99,0,0,0.213,1.35,0.88,12.13,67.5,179,3.984,5.475,1.592,30212
"Pork, cured, ham and water product, rump, bone-in, separable lean only, heated, roasted",Pork Products,21.28,0.01,1.267,0,0,0.264,1,1.15,4.7,69.53,131,1.536,2.127,0.634,30213
"Pork, cured, ham and water product, rump, bone-in, separable lean only, unheated",Pork Products,17.93,0.01,1.07,0,0,0.221,1.24,0.97,3.38,74.32,107,1.105,1.53,0.456,30214
"Pork, cured, ham and water product, shank, bone-in, separable lean and fat, heated, roasted",Pork Products,18.17,0.008,0.945,0,0,0.216,1.42,0.99,17.29,60.03,234,5.715,7.914,2.281,30215
"Pork, cured, ham and water product, shank, bone-in, separable lean and fat, unheated",Pork Products,14.28,0.006,0.936,0,0,0.215,1.42,0.86,20,61.56,243,6.583,8.993,2.689,30216
"Pork, cured, ham and water product, shank, bone-in, separable lean only, heated, roasted",Pork Products,21.69,0.009,1.045,0,0,0.224,1.2,1.26,4.45,69.23,132,1.471,1.966,0.713,30217
"Pork, cured, ham and water product, shank, bone-in, unheated, separable lean only",Pork Products,17.53,0.008,1.09,0,0,0.234,1.2,1.06,4.18,73.97,113,1.384,1.849,0.671,30218
"Pork, cured, ham and water product, slice, bone-in, separable lean and fat, heated, pan-broil",Pork Products,19.85,0.011,1.188,0,0,0.281,1.41,1.03,7.78,67.21,155,2.586,3.577,1.032,30219
"Pork, cured, ham and water product, slice, bone-in, separable lean and fat, unheated",Pork Products,13.69,0.008,1.099,0,0,0.205,2.72,0.85,9.29,71.07,149,3.076,4.212,1.25,30220
"Pork, cured, ham and water product, slice, bone-in, separable lean only, heated, pan-broil",Pork Products,20.9,0.012,1.237,0,0,0.289,1.35,1.1,3.63,70.21,122,1.217,1.662,0.517,30221
"Pork, cured, ham and water product, slice, bone-in, separable lean only, unheated",Pork Products,14.47,0.009,1.16,0,0,0.209,2.82,0.91,3.78,75.54,103,1.266,1.73,0.538,30222
"Pork, cured, ham and water product, slice, boneless, separable lean and fat, heated, pan-broil",Pork Products,15.08,0.008,1.389,0,0,0.272,4.38,4.69,5.13,71.14,124,1.553,2.16,0.699,30223
"Pork, cured, ham and water product, slice, boneless, separable lean only, heated, pan-broil",Pork Products,15.09,0.008,1.39,0,0,0.272,4.38,4.69,5.06,71.2,123,1.528,2.125,0.69,30224
"Pork, cured, ham and water product, whole, boneless, separable lean and fat, heated, roasted",Pork Products,13.88,0.008,1.335,0,0,0.245,4.49,4.61,5.46,72.11,123,1.841,2.458,0.851,30225
"Pork, cured, ham and water product, whole, boneless, separable lean and fat, unheated",Pork Products,14.05,0.008,1.308,0,0,0.267,4,4.21,4.99,72.96,117,1.489,2.098,0.66,30226
"Pork, cured, ham and water product, whole, boneless, separable lean only, heated, roasted",Pork Products,13.88,0.008,1.335,0,0,0.245,4.49,4.61,5.46,72.11,123,1.841,2.458,0.851,30227
"Pork, cured, ham and water product, whole, boneless, separable lean only, unheated",Pork Products,14.07,0.008,1.31,0,0,0.268,4,4.22,4.86,73.05,116,1.447,2.041,0.644,30228
"Pork, cured, ham with natural juices, rump, bone-in, separable lean and fat, heated, roasted",Pork Products,22.47,0.01,0.841,0,0,0.477,0.6,0.45,9.39,64.38,177,3.116,4.364,1.337,30229
"Pork, cured, ham with natural juices, rump, bone-in, separable lean and fat, unheated",Pork Products,19.7,0.008,0.838,0,0,0.278,0.42,0.43,13.26,63.86,200,4.373,6.03,1.83,30230
"Pork, cured, ham with natural juices, rump, bone-in, separable lean only, heated, roasted",Pork Products,24.14,0.01,0.861,0,0,0.511,0.43,0.48,4.25,67.9,137,1.418,1.995,0.711,30231
"Pork, cured, ham with natural juices, rump, bone-in, separable lean only, unheated",Pork Products,22.71,0.009,0.893,0,0,0.303,0.06,0.43,3.47,70.8,122,1.16,1.632,0.582,30232
"Pork, cured, ham with natural juices, shank, bone-in, separable lean and fat, heated, roasted",Pork Products,22.88,0.007,0.801,0,0,0.424,0.26,0.33,10.93,62.94,191,3.545,5.12,1.351,30233
"Pork, cured, ham with natural juices, shank, bone-in, separable lean and fat, unheated",Pork Products,22.35,0.006,0.779,0,0,0.279,0.29,0.32,11.11,65.04,191,3.614,5.088,1.419,30234
"Pork, cured, ham with natural juices, shank, bone-in, separable lean only, heated, roasted",Pork Products,24.95,0.007,0.82,0,0,0.458,0.01,0.34,4.97,66.98,145,1.564,2.38,0.6,30235
"Pork, cured, ham with natural juices, shank, bone-in, separable lean only, unheated",Pork Products,25.11,0.007,0.809,0,0,0.299,0,0.3,3.33,70.5,130,1.048,1.595,0.402,30236
"Pork, cured, ham with natural juices, slice, bone-in, separable lean and fat, heated, pan-broil",Pork Products,26.18,0.012,0.821,0,0,0.344,0.17,0.02,8.28,63.36,180,2.427,3.206,1.064,30237
"Pork, cured, ham with natural juices, slice, bone-in, separable lean and fat, unheated",Pork Products,22.82,0.009,0.839,0,0,0.289,0.17,0.04,7.4,67.16,159,2.236,2.965,0.967,30238
"Pork, cured, ham with natural juices, slice, bone-in, separable lean only, heated, pan-broil",Pork Products,27.75,0.012,0.835,0,0,0.358,0,0,4.38,65.87,150,1.11,1.351,0.577,30239
"Pork, cured, ham with natural juices, slice, bone-in, separable lean only, unheated",Pork Products,24.34,0.009,0.861,0,0,0.3,0,0,2.87,70.29,123,0.726,0.884,0.378,30240
"Pork, cured, ham with natural juices, slice, boneless, separable lean and fat, heated, pan-broil",Pork Products,20.89,0.006,1.16,0,0,0.363,0.99,1.04,3.4,70.93,118,1.054,1.445,0.454,30241
"Pork, cured, ham with natural juices, slice, boneless, separable lean only, heated, pan-broil",Pork Products,20.95,0.006,1.163,0,0,0.364,0.99,1.05,3.16,71.1,116,0.975,1.333,0.424,30242
"Pork, cured, ham with natural juices, spiral slice, boneless, separable lean and fat, heated, roasted",Pork Products,22.18,0.004,0.977,0,0,0.345,0.46,1.06,5.1,68.53,136,0.471,0.657,0.179,30243
"Pork, cured, ham with natural juices, spiral slice, boneless, separable lean and fat, unheated",Pork Products,18.66,0.004,0.881,0,0,0.31,0.69,1.18,5.75,70.62,129,1.833,2.513,0.902,30244
"Pork, cured, ham with natural juices, spiral slice, boneless, separable lean only, unheated",Pork Products,19.25,0.004,0.895,0,0,0.317,0.63,1.22,3.26,72.46,109,1.009,1.384,0.585,30245
"Pork, cured, ham with natural juices, spiral slice, meat only, boneless, separable lean only, heated, roasted",Pork Products,22.56,0.004,0.986,0,0,0.349,0.41,1.08,3.78,69.47,126,0.493,0.676,0.286,30246
"Pork, cured, ham with natural juices, whole, boneless, separable lean and fat, heated, roasted",Pork Products,20.54,0.006,1.179,0,0,0.367,0.84,0.81,3.13,71.62,114,1.084,1.479,0.452,30247
"Pork, cured, ham with natural juices, whole, boneless, separable lean and fat, unheated",Pork Products,19.38,0.006,1.096,0,0,0.346,0.95,1.02,3.43,72.68,112,1.005,1.372,0.428,30248
"Pork, cured, ham with natural juices, whole, boneless, separable lean only, heated, roasted",Pork Products,20.57,0.006,1.18,0,0,0.367,0.84,0.81,3.01,71.71,113,1.044,1.422,0.437,30249
"Pork, cured, ham with natural juices, whole, boneless, separable lean only, unheated",Pork Products,19.44,0.006,1.098,0,0,0.347,0.95,1.03,3.21,72.85,110,0.934,1.274,0.4,30250
"Pork, cured, ham, boneless, extra lean (approximately 5% fat), roasted",Pork Products,20.93,0.008,1.203,0,0,0.287,1.5,0,5.53,67.67,145,1.81,2.62,0.54,30251
"Pork, cured, ham, boneless, extra lean and regular, roasted",Pork Products,21.97,0.008,1.385,0,0,0.362,0.5,0,7.66,65.75,165,2.61,3.74,1.07,30252
"Pork, cured, ham, boneless, extra lean and regular, unheated",Pork Products,18.26,0.007,1.278,0,0,0.297,2.28,0,8.39,66.92,162,2.7,3.94,0.93,30253
"Pork, cured, ham, boneless, low sodium, extra lean (approximately 5% fat), roasted",Pork Products,20.9,0.008,0.969,0,0,0.287,1.5,0,5.5,67.7,145,1.801,2.607,0.537,30254
"Pork, cured, ham, boneless, low sodium, extra lean and regular, roasted",Pork Products,22,0.008,0.969,0,0,0.362,0.5,0,7.7,65.8,165,2.624,3.76,1.076,30255
"Pork, cured, ham, boneless, regular (approximately 11% fat), roasted",Pork Products,22.62,0.008,1.5,0,0,0.409,0,0,9.02,64.54,178,3.12,4.44,1.41,30256
"Pork, cured, ham, center slice, country-style, separable lean only, raw",Pork Products,27.8,0.01,2.695,0,0,0.51,0.3,0,8.32,55.93,195,2.78,3.821,0.97,30257
"Pork, cured, ham, center slice, separable lean and fat, unheated",Pork Products,20.17,0.007,1.386,0,0,0.337,0.05,0,12.9,63.46,203,4.572,6.092,1.411,30258
"Pork, cured, ham, extra lean (approximately 4% fat), canned, roasted",Pork Products,21.16,0.006,1.135,0,0,0.348,0.52,0,4.88,69.45,136,1.6,2.49,0.44,30259
"Pork, cured, ham, extra lean (approximately 4% fat), canned, unheated",Pork Products,18.49,0.006,1.255,0,0,0.364,0,0,4.56,73.52,120,1.51,2.21,0.39,30260
"Pork, cured, ham, extra lean and regular, canned, roasted",Pork Products,20.94,0.007,1.068,0,0,0.351,0.49,0,8.43,66.52,167,2.81,4.06,0.9,30261
"Pork, cured, ham, extra lean and regular, canned, unheated",Pork Products,17.97,0.006,1.276,0,0,0.334,0,0,7.46,71.12,144,2.45,3.57,0.76,30262
"Pork, cured, ham, low sodium, lean and fat, cooked",Pork Products,22.3,0.008,0.969,0,0,0.386,0.3,0,8.3,65.1,172,2.781,4.002,1.013,30263
"Pork, cured, ham, patties, grilled",Pork Products,13.3,0.009,1.063,0,0,0.244,1.7,0,30.85,51.35,342,11.09,14.67,3.33,30264
"Pork, cured, ham, patties, unheated",Pork Products,12.78,0.008,1.088,0,0,0.239,1.69,0,28.19,54.4,315,10.13,13.26,3.04,30265
"Pork, cured, ham, regular (approximately 13% fat), canned, roasted",Pork Products,20.53,0.008,0.941,0,0.014,0.357,0.42,0,15.2,60.93,226,5.04,7.07,1.78,30266
"Pork, cured, ham, regular (approximately 13% fat), canned, unheated",Pork Products,16.97,0.006,1.24,0,0,0.316,0.02,0,12.99,66.54,190,4.25,6.18,1.47,30267
"Pork, cured, ham, rump, bone-in, separable lean and fat, heated, roasted",Pork Products,23.95,0.007,0.826,0,0,0.37,0.37,0.64,8.88,63.82,177,2.85,3.791,1.27,30268
"Pork, cured, ham, rump, bone-in, separable lean and fat, unheated",Pork Products,22.27,0.007,0.722,0,0,0.358,0.52,0.06,9.38,65.19,176,3.008,3.966,1.356,30269
"Pork, cured, ham, rump, bone-in, separable lean only, heated, roasted",Pork Products,26.02,0.008,0.846,0,0,0.394,0.15,0.68,3.07,67.68,132,0.917,1.067,0.562,30270
"Pork, cured, ham, rump, bone-in, separable lean only, unheated",Pork Products,24.46,0.007,0.737,0,0,0.385,0.32,0,2.92,69.56,125,0.874,1.017,0.535,30271
"Pork, cured, ham, separable fat, boneless, heated",Pork Products,8.77,0.006,0.677,0,0,0.195,2,0.28,51.57,35.47,507,17.048,23.798,6.47,30272
"Pork, cured, ham, separable fat, boneless, unheated",Pork Products,7.5,0.004,0.616,0,0,0.174,1.87,0.44,53,35.65,515,17.433,23.902,6.901,30273
"Pork, cured, ham, shank, bone-in, separable lean and fat, heated, roasted",Pork Products,24.39,0.007,0.81,0,0,0.356,0.24,0.64,9.35,63.67,183,2.019,2.819,0.766,30274
"Pork, cured, ham, shank, bone-in, separable lean and fat, unheated",Pork Products,21.61,0.006,0.816,0,0,0.339,0.41,0.06,9.85,65.23,177,3.18,4.269,1.366,30275
"Pork, cured, ham, shank, bone-in, separable lean only, heated, roasted",Pork Products,26.49,0.007,0.828,0,0,0.378,0,0.68,3.68,67.46,139,1.134,1.433,0.592,30276
"Pork, cured, ham, shank, bone-in, separable lean only, unheated",Pork Products,23.79,0.006,0.846,0,0,0.364,0.18,0,3.19,69.79,125,0.981,1.24,0.512,30277
"Pork, cured, ham, slice, bone-in, separable lean and fat, heated, pan-broil",Pork Products,25.48,0.014,0.852,0,0,0.405,0.39,0.7,8.48,62.57,180,1.577,2.201,0.598,30278
"Pork, cured, ham, slice, bone-in, separable lean and fat, unheated",Pork Products,22.45,0.012,0.744,0,0,0.348,0.21,0.05,9.17,66.09,173,2.945,3.955,1.281,30279
"Pork, cured, ham, slice, bone-in, separable lean only, heated, pan-broil",Pork Products,27.18,0.015,0.87,0,0,0.427,0.23,0.74,4.09,65.33,146,1.253,1.613,0.644,30280
"Pork, cured, ham, slice, bone-in, separable lean only, unheated",Pork Products,24.36,0.013,0.76,0,0,0.37,0,0,3.59,69.97,130,1.099,1.414,0.565,30281
"Pork, cured, ham, steak, boneless, extra lean, unheated",Pork Products,19.56,0.004,1.269,0,0.032299999999999995,0.325,0,0,4.25,72.24,122,1.44,1.96,0.47,30282
"Pork, cured, ham, whole, separable lean and fat, roasted",Pork Products,21.57,0.007,1.187,0,0,0.286,0,0,16.77,58.4,243,5.98,7.88,1.81,30283
"Pork, cured, ham, whole, separable lean and fat, unheated",Pork Products,18.49,0.007,1.284,0,0.0349,0.31,0.06,0,18.52,59.71,246,6.62,8.7,1.64,30284
"Pork, cured, ham, whole, separable lean only, roasted",Pork Products,25.05,0.007,1.327,0,0,0.316,0,0,5.5,65.78,157,1.84,2.53,0.63,30285
"Pork, cured, ham, whole, separable lean only, unheated",Pork Products,22.32,0.007,1.516,0,0,0.371,0.05,0,5.71,68.26,147,1.92,2.62,0.66,30286
"Pork, cured, salt pork, raw",Pork Products,5.05,0.006,1.424,0,0,0.066,0,0,80.5,11,748,29.38,37.94,9.4,30287
"Pork, cured, separable fat (from ham and arm picnic), roasted",Pork Products,7.64,0.008,0.624,0,0,0.164,0,0,61.86,28.89,591,22.69,29.69,6.58,30288
"Pork, cured, separable fat (from ham and arm picnic), unheated",Pork Products,5.68,0.005,0.505,0,0,0.105,0.09,0,61.41,31.06,579,22.52,29.47,6.53,30289
"Pork, cured, shoulder, arm picnic, separable lean and fat, roasted",Pork Products,20.43,0.01,1.072,0,0,0.258,0,0,21.35,54.73,280,7.67,10.14,2.32,30290
"Pork, cured, shoulder, arm picnic, separable lean only, roasted",Pork Products,24.94,0.011,1.231,0,0,0.292,0,0,7.04,63.86,170,2.36,3.23,0.81,30291
"Pork, cured, shoulder, blade roll, separable lean and fat, roasted",Pork Products,17.28,0.007,0.973,0,0.0032,0.194,0.37,0,23.48,56.18,287,8.38,11.03,2.51,30292
"Pork, cured, shoulder, blade roll, separable lean and fat, unheated",Pork Products,16.47,0.007,1.25,0,0.0003,0.295,0,0,21.98,59.13,269,7.939,9.755,2.334,30293
"Pork, fresh, backfat, raw",Pork Products,2.92,0.002,0.011,0,0.0001,0.065,0,0,88.69,7.69,812,32.21,41.95,10.35,30294
"Pork, fresh, backribs, separable lean and fat, cooked, roasted",Pork Products,23.01,0.046,0.094,0,0,0.24,0,0,21.51,54.82,286,7.8,9.29,3.529,30295
"Pork, fresh, backribs, separable lean and fat, raw",Pork Products,19.07,0.031,0.087,0,0,0.247,0,0,16.33,63.47,224,5.783,6.861,2.676,30296
"Pork, fresh, belly, raw",Pork Products,9.34,0.005,0.032,0,0.0003,0.185,0,0,53.01,36.74,518,19.33,24.7,5.65,30297
"Pork, fresh, carcass, separable lean and fat, raw",Pork Products,13.91,0.019,0.042,0,0.0004,0.253,0,0,35.07,49.83,376,12.44,15.93,3.8,30298
"Pork, fresh, composite of trimmed leg, loin, shoulder, and spareribs, (includes cuts to be cured), separable lean and fat, raw",Pork Products,18.22,0.011,0.057,0,0.0003,0.319,0,0,14.79,65.99,211,4.918,6.102,1.726,30299
"Pork, fresh, composite of trimmed retail cuts (leg, loin, and shoulder), separable lean only, cooked",Pork Products,27.51,0.018,0.055,0,0.0002,0.357,0,0,9.21,62.53,201,3.092,3.908,0.863,30300
"Pork, fresh, composite of trimmed retail cuts (leg, loin, shoulder), separable lean only, raw",Pork Products,21.2,0.013,0.059,0,0.0002,0.364,0,0,4.82,73.21,134,1.022,1.308,0.329,30301
"Pork, fresh, composite of trimmed retail cuts (leg, loin, shoulder, and spareribs), separable lean and fat, cooked",Pork Products,26.36,0.02,0.057,0,0.0001,0.341,0,0,13.89,58.53,238,4.893,5.976,1.519,30302
"Pork, fresh, composite of trimmed retail cuts (leg, loin, shoulder, and spareribs), separable lean and fat, raw",Pork Products,18.95,0.019,0.055,0,0.0005,0.335,0,0,14.95,65.11,216,5.28,6.63,1.55,30303
"Pork, fresh, composite of trimmed retail cuts (loin and shoulder blade), separable lean and fat, cooked",Pork Products,26.07,0.02,0.055,0,0.0001,0.345,0,0,13.66,59.43,235,4.703,5.758,1.367,30304
"Pork, fresh, composite of trimmed retail cuts (loin and shoulder blade), separable lean and fat, raw",Pork Products,20.08,0.016,0.054,0,0.0001,0.345,0,0,10.14,69.13,177,3.106,3.768,1.122,30305
"Pork, fresh, composite of trimmed retail cuts (loin and shoulder blade), separable lean only, cooked",Pork Products,29.47,0.022,0.057,0,0.0004,0.377,0,0,9.44,60.3,211,3.34,4.22,0.71,30306
"Pork, fresh, composite of trimmed retail cuts (loin and shoulder blade), separable lean only, raw",Pork Products,21.23,0.017,0.054,0,0.0006,0.384,0,0,5.88,72.23,144,2.03,2.66,0.63,30307
"Pork, fresh, enhanced, composite of separable fat, cooked",Pork Products,10.06,0.036,0.125,0,0,0.214,0.32,0,60.42,28.28,585,21.382,24.9,8.49,30308
"Pork, fresh, enhanced, composite of separable fat, raw",Pork Products,9.27,0.022,0.081,0,0,0.191,0,0,52.33,38.32,508,18.488,21.538,7.532,30309
"Pork, fresh, enhanced, loin, tenderloin, separable lean and fat, cooked, roasted",Pork Products,21.5,0.005,0.23,0,0,0.563,0.31,0,3.7,72.64,121,1.27,1.424,0.531,30310
"Pork, fresh, enhanced, loin, tenderloin, separable lean and fat, raw",Pork Products,20.16,0.005,0.239,0,0,0.519,0,0,3.14,76.1,109,1.045,1.198,0.503,30311
"Pork, fresh, enhanced, loin, tenderloin, separable lean only, cooked, roasted",Pork Products,21.61,0.005,0.231,0,0,0.567,0.31,0,3.15,73.07,116,1.077,1.199,0.455,30312
"Pork, fresh, enhanced, loin, tenderloin, separable lean only, raw",Pork Products,20.39,0.004,0.243,0,0,0.527,0,0,2.09,76.91,106,0.671,0.762,0.352,30313
"Pork, fresh, enhanced, loin, top loin (chops), boneless, separable lean and fat, cooked, broiled",Pork Products,24.46,0.006,0.234,0,0,0.517,0,0,4.86,69.4,142,1.678,2.085,0.542,30314
"Pork, fresh, enhanced, loin, top loin (chops), boneless, separable lean and fat, raw",Pork Products,21.35,0.005,0.226,0,0,0.479,0,0,4.21,73.63,123,1.486,1.83,0.516,30315
"Pork, fresh, enhanced, loin, top loin (chops), boneless, separable lean only, cooked, broiled",Pork Products,24.81,0.005,0.237,0,0,0.524,0,0,3.53,70.39,131,1.206,1.539,0.351,30316
"Pork, fresh, enhanced, loin, top loin (chops), boneless, separable lean only, raw",Pork Products,21.78,0.005,0.232,0,0,0.489,0,0,2.51,74.88,110,0.886,1.134,0.269,30317
"Pork, fresh, enhanced, shoulder, (Boston butt), blade (steaks), separable lean and fat, cooked, braised",Pork Products,25.84,0.027,0.151,0,0,0.37,0.03,0,16.94,56.13,263,6.288,7.613,2.006,30318
"Pork, fresh, enhanced, shoulder, (Boston butt), blade (steaks), separable lean and fat, raw",Pork Products,17.19,0.015,0.155,0,0,0.391,0.16,0,11.12,70.36,169,3.912,4.686,1.434,30319
"Pork, fresh, enhanced, shoulder, (Boston butt), blade (steaks), separable lean only, cooked, braised",Pork Products,27.58,0.026,0.154,0,0,0.388,0,0,12.14,59.2,227,4.62,5.702,1.29,30320
"Pork, fresh, enhanced, shoulder, (Boston butt), blade (steaks), separable lean only, raw",Pork Products,18.29,0.014,0.165,0,0,0.419,0.18,0,5.36,74.85,122,1.873,2.329,0.581,30321
"Pork, fresh, ground, cooked",Pork Products,25.69,0.022,0.073,0,0.0007,0.362,0,0,20.77,52.75,297,7.72,9.25,1.87,30322
"Pork, fresh, ground, raw",Pork Products,16.88,0.014,0.056,0,0.0007,0.287,0,0,21.19,61.06,263,7.87,9.44,1.91,30323
"Pork, fresh, leg (ham), rump half, separable lean and fat, cooked, roasted",Pork Products,28.88,0.012,0.062,0,0.0002,0.374,0,0,14.28,56.8,252,5.25,6.37,1.38,30324
"Pork, fresh, leg (ham), rump half, separable lean and fat, raw",Pork Products,18.74,0.005,0.061,0,0.0011,0.333,0,0,15.69,64.73,222,5.44,6.98,1.68,30325
"Pork, fresh, leg (ham), rump half, separable lean only, cooked, roasted",Pork Products,30.94,0.007,0.065,0,0.0002,0.391,0,0,8.14,60.95,206,2.87,3.77,0.76,30326
"Pork, fresh, leg (ham), rump half, separable lean only, raw",Pork Products,21.24,0.006,0.069,0,0.0013,0.376,0,0,5.19,72.83,137,1.79,2.34,0.56,30327
"Pork, fresh, leg (ham), shank half, separable lean and fat, cooked, roasted",Pork Products,25.34,0.015,0.059,0,0.0003,0.338,0,0,20.06,53.77,289,7.36,9.02,1.89,30328
"Pork, fresh, leg (ham), shank half, separable lean and fat, raw",Pork Products,17.08,0.006,0.055,0,0.0007,0.285,0,0,21.02,60.87,263,7.29,9.34,2.24,30329
"Pork, fresh, leg (ham), shank half, separable lean only, cooked, roasted",Pork Products,28.21,0.007,0.064,0,0.0004,0.36,0,0,10.5,60.43,215,3.63,5.02,0.91,30330
"Pork, fresh, leg (ham), shank half, separable lean only, raw",Pork Products,20.62,0.007,0.067,0,0.0009,0.34,0,0,5.63,72.83,139,1.94,2.54,0.61,30331
"Pork, fresh, leg (ham), whole, separable lean and fat, cooked, roasted",Pork Products,26.83,0.014,0.06,0,0.0003,0.352,0,0,17.61,55.04,273,6.47,7.88,1.68,30332
"Pork, fresh, leg (ham), whole, separable lean and fat, raw",Pork Products,17.43,0.005,0.047,0,0.0007,0.315,0,0,18.87,62.47,245,6.54,8.38,2.01,30333
"Pork, fresh, leg (ham), whole, separable lean only, cooked, roasted",Pork Products,29.41,0.007,0.064,0,0.0004,0.373,0,0,9.44,60.66,211,3.3,4.45,0.85,30334
"Pork, fresh, leg (ham), whole, separable lean only, raw",Pork Products,20.48,0.006,0.055,0,0.0009,0.369,0,0,5.41,72.9,136,1.87,2.44,0.58,30335
"Pork, fresh, loin, blade (chops or roasts), bone-in, separable lean and fat, raw",Pork Products,19.56,0.035,0.069,0,0,0.288,0,0,12.27,67.64,194,4.27,4.913,1.941,30336
"Pork, fresh, loin, blade (chops or roasts), bone-in, separable lean only, raw",Pork Products,21.22,0.03,0.073,0,0,0.313,0,0,5.84,72.84,143,1.974,2.301,0.805,30337
"Pork, fresh, loin, blade (chops), bone-in, separable lean and fat, cooked, braised",Pork Products,26.54,0.051,0.069,0,0,0.259,0,0,15.71,57.32,255,4.487,5.067,1.948,30338
"Pork, fresh, loin, blade (chops), bone-in, separable lean and fat, cooked, broiled",Pork Products,23.72,0.056,0.074,0,0,0.315,0,0,14.35,61.45,231,4.339,4.887,1.894,30339
"Pork, fresh, loin, blade (chops), bone-in, separable lean and fat, cooked, pan-fried",Pork Products,25.02,0.048,0.085,0,0,0.318,0,0,16.56,58.01,256,4.491,5.069,1.952,30340
"Pork, fresh, loin, blade (chops), bone-in, separable lean only, cooked, braised",Pork Products,28.02,0.049,0.07,0,0,0.27,0,0,11.29,60.32,222,2.59,3.02,1.056,30341
"Pork, fresh, loin, blade (chops), bone-in, separable lean only, cooked, broiled",Pork Products,24.99,0.055,0.076,0,0,0.332,0,0,9.56,65.03,193,2.322,2.707,0.947,30342
"Pork, fresh, loin, blade (chops), bone-in, separable lean only, cooked, pan-fried",Pork Products,26.38,0.046,0.088,0,0,0.335,0,0,12.14,61.13,222,2.557,2.981,1.043,30343
"Pork, fresh, loin, blade (roasts), bone-in, separable lean and fat, cooked, roasted",Pork Products,24.29,0.031,0.076,0,0,0.318,0,0,16.71,58.74,254,5.9,6.684,2.465,30344
"Pork, fresh, loin, blade (roasts), bone-in, separable lean only, cooked, roasted",Pork Products,25.7,0.027,0.078,0,0,0.337,0,0,11.89,62.24,217,3.934,4.572,1.524,30345
"Pork, fresh, loin, center loin (chops), bone-in, separable lean and fat, cooked, braised",Pork Products,28.21,0.055,0.073,0,0,0.273,0,0,13.51,58.04,242,4.934,5.557,2.155,30346
"Pork, fresh, loin, center loin (chops), bone-in, separable lean and fat, cooked, broiled",Pork Products,25.61,0.024,0.055,0,0,0.344,0,0,11.06,62.27,209,3.518,4.22,1.359,30347
"Pork, fresh, loin, center loin (chops), bone-in, separable lean and fat, cooked, pan-fried",Pork Products,27.63,0.053,0.094,0,0,0.353,0,0,13.32,58.39,238,4.867,5.478,2.127,30348
"Pork, fresh, loin, center loin (chops), bone-in, separable lean and fat, raw",Pork Products,20.71,0.019,0.055,0,0,0.343,0,0,9.03,69.7,170,2.993,3.548,1.19,30349
"Pork, fresh, loin, center loin (chops), bone-in, separable lean only, cooked, braised",Pork Products,30.2,0.053,0.075,0,0,0.288,0,0,7.86,61.79,200,2.671,3.114,1.089,30350
"Pork, fresh, loin, center loin (chops), bone-in, separable lean only, cooked, broiled",Pork Products,26.76,0.023,0.056,0,0,0.356,0,0,7.29,65.01,180,2.156,2.643,0.816,30351
"Pork, fresh, loin, center loin (chops), bone-in, separable lean only, cooked, pan-fried",Pork Products,29.56,0.051,0.099,0,0,0.378,0,0,7.66,62.17,195,2.597,3.028,1.059,30352
"Pork, fresh, loin, center loin (chops), bone-in, separable lean only, raw",Pork Products,21.99,0.018,0.058,0,0,0.362,0,0,3.71,73.62,127,1.098,1.346,0.415,30353
"Pork, fresh, loin, center loin (roasts), bone-in, separable lean and fat, cooked, roasted",Pork Products,27.01,0.033,0.083,0,0,0.35,0,0,12.8,60.26,231,4.859,5.478,2.073,30354
"Pork, fresh, loin, center loin (roasts), bone-in, separable lean only, cooked, roasted",Pork Products,28.58,0.03,0.086,0,0,0.37,0,0,7.95,63.65,194,2.932,3.398,1.162,30355
"Pork, fresh, loin, center rib (chops or roasts), bone-in, separable lean and fat, raw",Pork Products,20.28,0.025,0.056,0,0,0.337,0,0,11.04,67.83,186,2.365,2.756,0.964,30356
"Pork, fresh, loin, center rib (chops or roasts), bone-in, separable lean only, raw",Pork Products,21.79,0.024,0.06,0,0,0.359,0,0,4.8,72.4,136,1.617,1.999,0.524,30357
"Pork, fresh, loin, center rib (chops or roasts), boneless, separable lean and fat, raw",Pork Products,19.9,0.005,0.042,0,0.0003,0.384,0,0,14.01,66.15,211,4.86,6.25,1.5,30358
"Pork, fresh, loin, center rib (chops or roasts), boneless, separable lean only, raw",Pork Products,21.8,0.005,0.045,0,0.0003,0.421,0,0,6.48,71.98,152,2.24,2.93,0.7,30359
"Pork, fresh, loin, center rib (chops), bone-in, separable lean and fat, cooked, braised",Pork Products,26.66,0.053,0.07,0,0,0.259,0,0,16.28,56.85,261,5.435,6.088,2.397,30360
"Pork, fresh, loin, center rib (chops), bone-in, separable lean and fat, cooked, broiled",Pork Products,24.42,0.028,0.055,0,0,0.329,0,0,13.04,61.66,222,4.486,5.41,1.593,30361
"Pork, fresh, loin, center rib (chops), bone-in, separable lean and fat, cooked, pan-fried",Pork Products,26.81,0.048,0.084,0,0,0.313,0,0,15.71,57.11,256,5.056,5.68,2.218,30362
"Pork, fresh, loin, center rib (chops), bone-in, separable lean only, cooked, braised",Pork Products,29.03,0.051,0.072,0,0,0.277,0,0,9.32,61.56,208,2.553,2.977,1.041,30363
"Pork, fresh, loin, center rib (chops), bone-in, separable lean only, cooked, broiled",Pork Products,25.79,0.026,0.057,0,0,0.343,0,0,8.36,65.14,186,2.816,3.482,0.913,30364
"Pork, fresh, loin, center rib (chops), bone-in, separable lean only, cooked, pan-fried",Pork Products,28.84,0.045,0.088,0,0,0.335,0,0,9.73,61.14,211,2.563,2.989,1.045,30365
"Pork, fresh, loin, center rib (chops), boneless, separable lean and fat, cooked, braised",Pork Products,26.29,0.005,0.04,0,0.0003,0.387,0,0,15.79,58.15,255,6.12,7.21,1.32,30366
"Pork, fresh, loin, center rib (chops), boneless, separable lean and fat, cooked, broiled",Pork Products,27.63,0.028,0.062,0,0.0003,0.401,0,0,15.76,53.37,260,5.79,7.01,1.24,30367
"Pork, fresh, loin, center rib (chops), boneless, separable lean and fat, cooked, pan-fried",Pork Products,25.82,0.011,0.05,0,0,0.428,0,0,18.05,56.34,273,6.72,7.96,2.08,30368
"Pork, fresh, loin, center rib (chops), boneless, separable lean only, cooked, braised",Pork Products,27.95,0.005,0.041,0,0.0003,0.405,0,0,10.14,62.25,211,3.96,4.85,0.73,30369
"Pork, fresh, loin, center rib (chops), boneless, separable lean only, cooked, broiled",Pork Products,29.46,0.031,0.065,0,0.0003,0.42,0,0,10.05,56.95,216,3.57,4.6,0.64,30370
"Pork, fresh, loin, center rib (chops), boneless, separable lean only, cooked, pan-fried",Pork Products,27.68,0.005,0.052,0,0.0003,0.454,0,0,11.8,60.86,224,4.3,5.31,1.49,30371
"Pork, fresh, loin, center rib (roasts), bone-in, separable lean and fat, cooked, roasted",Pork Products,26.99,0.019,0.091,0,0,0.272,0,0,14.68,57.76,248,5.442,6.183,2.474,30372
"Pork, fresh, loin, center rib (roasts), bone-in, separable lean only, cooked, roasted",Pork Products,28.82,0.013,0.095,0,0,0.287,0,0,9.21,61.44,206,3.254,3.83,1.453,30373
"Pork, fresh, loin, center rib (roasts), boneless, separable lean and fat, cooked, roasted",Pork Products,26.99,0.006,0.048,0,0.0004,0.346,0,0,15.15,57.33,252,5.35,6.68,1.3,30374
"Pork, fresh, loin, center rib (roasts), boneless, separable lean only, cooked, roasted",Pork Products,28.81,0.006,0.05,0,0.0004,0.363,0,0,10.13,60.61,214,3.54,4.49,0.85,30375
"Pork, fresh, loin, country-style ribs, separable lean and fat, cooked, braised",Pork Products,26.49,0.034,0.058,0,0,0.288,0,0,17.71,53.9,273,6.302,7.654,2.138,30376
"Pork, fresh, loin, country-style ribs, separable lean and fat, cooked, roasted",Pork Products,23.4,0.025,0.052,0,0.0003,0.344,0,0,25.34,50.87,328,9.21,11.01,2.01,30377
"Pork, fresh, loin, country-style ribs, separable lean and fat, raw",Pork Products,19.34,0.022,0.063,0,0,0.318,0,0,11.82,68.22,189,2.37,2.762,0.966,30378
"Pork, fresh, loin, country-style ribs, separable lean only, cooked, braised",Pork Products,27.74,0.033,0.06,0,0,0.297,0,0,14.26,56.16,247,5.086,6.26,1.629,30379
"Pork, fresh, loin, country-style ribs, separable lean only, cooked, roasted",Pork Products,26.6,0.029,0.029,0,0.0003,0.349,0,0,14.81,58.2,247,5.31,6.46,1.1,30380
"Pork, fresh, loin, country-style ribs, separable lean only, raw",Pork Products,20.76,0.021,0.067,0,0,0.338,0,0,5.64,72.84,140,2.012,2.477,0.645,30381
"Pork, fresh, loin, sirloin (chops or roasts), bone-in, separable lean and fat, raw",Pork Products,20.48,0.014,0.057,0,0,0.336,0,0,8.96,70.29,168,1.851,2.157,0.754,30382
"Pork, fresh, loin, sirloin (chops or roasts), bone-in, separable lean only, raw",Pork Products,21.65,0.012,0.059,0,0,0.353,0,0,4.02,73.98,129,0,1.537,0.424,30383
"Pork, fresh, loin, sirloin (chops or roasts), boneless, separable lean and fat, raw",Pork Products,20.57,0.013,0.05,0,0.0009,0.361,0,0,6.31,71.93,145,2.18,2.83,0.68,30384
"Pork, fresh, loin, sirloin (chops or roasts), boneless, separable lean only, raw",Pork Products,21.06,0.013,0.051,0,0.0009,0.37,0,0,4.22,73.55,128,1.46,1.91,0.45,30385
"Pork, fresh, loin, sirloin (chops), bone-in, separable lean and fat, cooked, braised",Pork Products,25.36,0.018,0.051,0,0.0008,0.325,0,0,15.11,58.73,245,5.56,6.56,1.4,30386
"Pork, fresh, loin, sirloin (chops), bone-in, separable lean and fat, cooked, broiled",Pork Products,26.65,0.017,0.068,0,0.0009,0.383,0,0,16.09,56.4,259,5.93,6.99,1.5,30387
"Pork, fresh, loin, sirloin (chops), bone-in, separable lean only, cooked, braised",Pork Products,27,0.013,0.053,0,0.0009,0.336,0,0,9.01,63.16,197,3.2,3.97,0.78,30388
"Pork, fresh, loin, sirloin (chops), bone-in, separable lean only, cooked, broiled",Pork Products,28.46,0.013,0.072,0,0.001,0.401,0,0,10.12,60.54,213,3.61,4.45,0.89,30389
"Pork, fresh, loin, sirloin (chops), boneless, separable lean and fat, cooked, braised",Pork Products,26.54,0.013,0.046,0,0.0008,0.352,0,0,8.38,63.6,189,3.03,3.66,0.75,30390
"Pork, fresh, loin, sirloin (chops), boneless, separable lean and fat, cooked, broiled",Pork Products,30.52,0.018,0.056,0,0.0004,0.372,0,0,8.6,60.11,208,2.88,3.75,0.69,30391
"Pork, fresh, loin, sirloin (chops), boneless, separable lean only, cooked, braised",Pork Products,27,0.013,0.046,0,0.0009,0.356,0,0,6.6,64.9,175,2.34,2.9,0.57,30392
"Pork, fresh, loin, sirloin (chops), boneless, separable lean only, cooked, broiled",Pork Products,31.13,0.018,0.056,0,0.0004,0.377,0,0,6.67,61.43,193,2.22,2.91,0.53,30393
"Pork, fresh, loin, sirloin (roasts), bone-in, separable lean and fat, cooked, roasted",Pork Products,26.64,0.015,0.057,0,0,0.34,0,0,12.87,59.34,230,4.113,5.042,1.499,30394
"Pork, fresh, loin, sirloin (roasts), bone-in, separable lean only, cooked, roasted",Pork Products,27.78,0.013,0.059,0,0,0.352,0,0,9.44,61.74,204,2.871,3.612,0.997,30395
"Pork, fresh, loin, sirloin (roasts), boneless, separable lean and fat, cooked, roasted",Pork Products,28.5,0.016,0.056,0,0.001,0.402,0,0,9.43,60.48,207,3.42,4.12,0.85,30396
"Pork, fresh, loin, sirloin (roasts), boneless, separable lean only, cooked, roasted",Pork Products,28.85,0.017,0.056,0,0.001,0.405,0,0,8.27,61.28,198,2.97,3.62,0.73,30397
"Pork, fresh, loin, tenderloin, separable lean and fat, cooked, broiled",Pork Products,29.86,0.005,0.064,0,0.001,0.444,0,0,8.11,61.09,201,2.93,3.34,0.74,30398
"Pork, fresh, loin, tenderloin, separable lean and fat, cooked, roasted",Pork Products,26.04,0.006,0.057,0,0,0.419,0,0,3.96,69.11,147,1.358,1.521,0.569,30399
"Pork, fresh, loin, tenderloin, separable lean and fat, raw",Pork Products,20.65,0.006,0.052,0,0,0.393,0,0,3.53,74.97,120,1.181,1.355,0.562,30400
"Pork, fresh, loin, tenderloin, separable lean only, cooked, broiled",Pork Products,30.42,0.005,0.065,0,0.001,0.451,0,0,6.33,62.3,187,2.24,2.58,0.56,30401
"Pork, fresh, loin, tenderloin, separable lean only, cooked, roasted",Pork Products,26.17,0.006,0.057,0,0,0.421,0,0,3.51,69.45,143,1.198,1.334,0.506,30402
"Pork, fresh, loin, tenderloin, separable lean only, raw",Pork Products,20.95,0.005,0.053,0,0,0.399,0,0,2.17,76,109,0.698,0.792,0.367,30403
"Pork, fresh, loin, top loin (chops), boneless, enhanced, separable lean and fat, cooked, pan-broiled",Pork Products,28.35,0.01,0.205,0,0,0.512,0,0,7.66,62.52,190,2.725,3.5,0.664,30404
"Pork, fresh, loin, top loin (chops), boneless, enhanced, separable lean only, cooked, pan-broiled",Pork Products,28.95,0.01,0.209,0,0,0.517,0,0,5,64.49,169,1.797,2.406,0.691,30405
"Pork, fresh, loin, top loin (chops), boneless, separable lean and fat, cooked, braised",Pork Products,29.2,0.012,0.066,0,0,0.261,0,0,8.31,62.44,200,3.218,3.625,1.29,30406
"Pork, fresh, loin, top loin (chops), boneless, separable lean and fat, cooked, broiled",Pork Products,26.62,0.007,0.044,0,0,0.357,0,0,9.14,62.92,196,3.164,3.904,1.048,30407
"Pork, fresh, loin, top loin (chops), boneless, separable lean and fat, cooked, pan-fried",Pork Products,29.36,0.012,0.086,0,0,0.376,0,0,7.86,62.62,196,3.058,3.491,1.278,30408
"Pork, fresh, loin, top loin (chops), boneless, separable lean and fat, raw",Pork Products,21.55,0.007,0.048,0,0,0.373,0,0,6.94,70.38,155,2.45,2.985,0.878,30409
"Pork, fresh, loin, top loin (chops), boneless, separable lean only, cooked, braised",Pork Products,30.54,0.008,0.067,0,0,0.269,0,0,4.34,65.14,170,1.657,1.934,0.551,30410
"Pork, fresh, loin, top loin (chops), boneless, separable lean only, cooked, broiled",Pork Products,27.58,0.005,0.045,0,0,0.367,0,0,6.08,65.11,173,2.077,2.651,0.605,30411
"Pork, fresh, loin, top loin (chops), boneless, separable lean only, cooked, pan-fried",Pork Products,30.46,0.008,0.087,0,0,0.39,0,0,4.62,64.81,172,1.784,2.113,0.679,30412
"Pork, fresh, loin, top loin (chops), boneless, separable lean only, raw",Pork Products,22.41,0.005,0.049,0,0,0.387,0,0,3.42,72.93,127,1.208,1.546,0.366,30413
"Pork, fresh, loin, top loin (roasts), boneless, separable lean and fat, cooked, roasted",Pork Products,26.45,0.007,0.046,0,0,0.349,0,0,8.82,63.76,192,2.839,3.517,0.999,30414
"Pork, fresh, loin, top loin (roasts), boneless, separable lean and fat, raw",Pork Products,21.34,0.007,0.047,0,0,0.358,0,0,8.33,70.14,166,1.598,1.861,0.651,30415
"Pork, fresh, loin, top loin (roasts), boneless, separable lean only, cooked, roasted",Pork Products,27.23,0.006,0.047,0,0,0.357,0,0,6.28,65.6,173,1.927,2.464,0.631,30416
"Pork, fresh, loin, top loin (roasts), boneless, separable lean only, raw",Pork Products,22.39,0.005,0.049,0,0,0.374,0,0,4.06,73.28,132,1.248,1.595,0.409,30417
"Pork, fresh, loin, whole, separable lean and fat, cooked, braised",Pork Products,27.23,0.021,0.048,0,0.0006,0.374,0,0,13.62,58.27,239,5.11,6.06,1.17,30418
"Pork, fresh, loin, whole, separable lean and fat, cooked, broiled",Pork Products,27.32,0.019,0.062,0,0.0006,0.423,0,0,13.92,57.87,242,5.23,6.19,1.2,30419
"Pork, fresh, loin, whole, separable lean and fat, cooked, roasted",Pork Products,27.09,0.019,0.059,0,0.0006,0.408,0,0,14.65,57.51,248,5.37,6.51,1.21,30420
"Pork, fresh, loin, whole, separable lean and fat, raw",Pork Products,19.74,0.018,0.05,0,0.0006,0.356,0,0,12.58,66.92,198,4.36,5.61,1.34,30421
"Pork, fresh, loin, whole, separable lean only, cooked, braised",Pork Products,28.57,0.018,0.05,0,0.0006,0.387,0,0,9.12,61.4,204,3.38,4.16,0.7,30422
"Pork, fresh, loin, whole, separable lean only, cooked, broiled",Pork Products,28.57,0.017,0.064,0,0.0007,0.438,0,0,9.8,60.72,210,3.64,4.45,0.77,30423
"Pork, fresh, loin, whole, separable lean only, cooked, roasted",Pork Products,28.62,0.018,0.058,0,0.0006,0.425,0,0,9.63,61.02,209,3.51,4.32,0.76,30424
"Pork, fresh, loin, whole, separable lean only, raw",Pork Products,21.43,0.017,0.052,0,0.0006,0.389,0,0,5.66,72.23,143,1.95,2.56,0.61,30425
"Pork, fresh, separable fat, cooked",Pork Products,11.04,0.07,0.055,0,0,0.144,0,0,62.11,25.79,607,24.413,26.577,11.323,30426
"Pork, fresh, separable fat, raw",Pork Products,9.03,0.061,0.042,0,0,0.126,0,0,53.1,34.6,518,18.843,21.488,9.152,30427
"Pork, fresh, shoulder, (Boston butt), blade (steaks), separable lean and fat, cooked, braised",Pork Products,25.07,0.026,0.058,0,0,0.305,0,0,17.69,54.95,267,6.584,7.987,2.072,30428
"Pork, fresh, shoulder, (Boston butt), blade (steaks), separable lean and fat, raw",Pork Products,17.42,0.016,0.061,0,0,0.318,0,0,12.36,69.18,186,4.348,5.206,1.597,30429
"Pork, fresh, shoulder, (Boston butt), blade (steaks), separable lean only, cooked, braised",Pork Products,26.57,0.025,0.06,0,0,0.318,0,0,13.2,58,233,5.023,6.199,1.402,30430
"Pork, fresh, shoulder, (Boston butt), blade (steaks), separable lean only, raw",Pork Products,18.73,0.014,0.065,0,0,0.339,0,0,5.71,74.31,132,1.998,2.484,0.62,30431
"Pork, fresh, shoulder, arm picnic, separable lean and fat, cooked, braised",Pork Products,27.99,0.018,0.088,0,0.0003,0.369,0,0,23.22,47.65,329,8.49,10.38,2.28,30432
"Pork, fresh, shoulder, arm picnic, separable lean and fat, cooked, roasted",Pork Products,23.47,0.019,0.07,0,0.0002,0.325,0,0,24.01,52.04,317,8.78,10.72,2.35,30433
"Pork, fresh, shoulder, arm picnic, separable lean and fat, raw",Pork Products,16.69,0.005,0.068,0,0.0007,0.291,0,0,20.19,62.06,253,7,8.97,2.16,30434
"Pork, fresh, shoulder, arm picnic, separable lean only, cooked, braised",Pork Products,32.26,0.008,0.102,0,0.0004,0.405,0,0,12.21,54.26,248,4.16,5.78,1.16,30435
"Pork, fresh, shoulder, arm picnic, separable lean only, cooked, roasted",Pork Products,26.68,0.009,0.08,0,0.0003,0.351,0,0,12.62,60.27,228,4.3,5.98,1.2,30436
"Pork, fresh, shoulder, arm picnic, separable lean only, raw",Pork Products,19.75,0.006,0.082,0,0.0009,0.341,0,0,6.16,73.13,140,2.13,2.78,0.66,30437
"Pork, fresh, shoulder, blade, boston (roasts), separable lean and fat, cooked, roasted",Pork Products,23.11,0.028,0.067,0,0.0007,0.332,0,0,18.86,57.47,269,6.97,8.27,1.76,30438
"Pork, fresh, shoulder, blade, boston (roasts), separable lean only, cooked, roasted",Pork Products,24.21,0.027,0.088,0,0.001,0.427,0,0,14.3,60.94,232,5.19,6.34,1.29,30439
"Pork, fresh, shoulder, blade, boston (steaks), separable lean and fat, cooked, broiled",Pork Products,25.58,0.036,0.069,0,0.0003,0.326,0,0,16.61,57.25,259,5.95,7.42,1.46,30440
"Pork, fresh, shoulder, blade, boston (steaks), separable lean only, cooked, broiled",Pork Products,26.74,0.033,0.074,0,0.0003,0.343,0,0,12.54,60.07,227,4.45,5.64,1.08,30441
"Pork, fresh, shoulder, whole, separable lean and fat, cooked, roasted",Pork Products,23.28,0.024,0.068,0,0.0005,0.329,0,0,21.39,54.8,292,7.86,9.47,2.05,30442
"Pork, fresh, shoulder, whole, separable lean and fat, raw",Pork Products,17.18,0.015,0.065,0,0.0007,0.302,0,0,17.99,64.02,236,6.24,8.01,1.92,30443
"Pork, fresh, shoulder, whole, separable lean only, cooked, roasted",Pork Products,25.33,0.018,0.075,0,0.0006,0.346,0,0,13.54,60.64,230,4.79,6.17,1.25,30444
"Pork, fresh, shoulder, whole, separable lean only, raw",Pork Products,19.55,0.014,0.076,0,0.0008,0.341,0,0,7.14,72.63,148,2.47,3.23,0.77,30445
"Pork, fresh, spareribs, separable lean and fat, cooked, braised",Pork Products,29.06,0.047,0.093,0,0,0.32,0,0,30.3,40.42,397,11.12,13.48,2.73,30446
"Pork, fresh, spareribs, separable lean and fat, cooked, roasted",Pork Products,20.89,0.019,0.091,0,0,0.265,0,0,30.86,48.11,361,9.242,10.709,2.996,30447
"Pork, fresh, spareribs, separable lean and fat, raw",Pork Products,15.47,0.015,0.081,0,0,0.242,0,0,23.4,59.75,277,7.529,8.542,3.953,30448
"Pork, fresh, variety meats and by-products, brain, cooked, braised",Pork Products,12.14,0.009,0.091,0,0.014,0.195,0,0,9.51,75.88,138,2.15,1.72,1.47,30449
"Pork, fresh, variety meats and by-products, brain, raw",Pork Products,10.28,0.01,0.12,0,0.0135,0.258,0,0,9.21,78.36,127,2.079,1.659,1.429,30450
"Pork, fresh, variety meats and by-products, chitterlings, cooked, simmered",Pork Products,12.49,0.025,0.018,0,0,0.014,0,0,20.32,67.87,233,9.52,7.073,1.217,30451
"Pork, fresh, variety meats and by-products, chitterlings, raw",Pork Products,7.64,0.016,0.024,0,0.0011,0.018,0,0,16.61,76.11,182,7.619,5.367,0.968,30452
"Pork, fresh, variety meats and by-products, ears, frozen, cooked, simmered",Pork Products,15.95,0.018,0.167,0,0,0.04,0.2,0,10.8,72.65,166,3.86,4.91,1.15,30453
"Pork, fresh, variety meats and by-products, ears, frozen, raw",Pork Products,22.45,0.021,0.191,0,0,0.055,0.6,0,15.1,61.25,234,5.39,6.86,1.61,30454
"Pork, fresh, variety meats and by-products, feet, cooked, simmered",Pork Products,21.94,0,0.073,0,0,0.033,0,0,16.05,62.85,232,4.343,8.005,1.54,30455
"Pork, fresh, variety meats and by-products, feet, raw",Pork Products,23.16,0.07,0.132,0,0,0.063,0,0,12.59,64.99,212,3.57,6.289,1.092,30456
"Pork, fresh, variety meats and by-products, heart, cooked, braised",Pork Products,23.6,0.007,0.035,0,0.002,0.206,0.4,0,5.05,68.08,148,1.34,1.18,1.3,30457
"Pork, fresh, variety meats and by-products, heart, raw",Pork Products,17.27,0.005,0.056,0,0.0053,0.294,1.33,0,4.36,76.21,118,1.16,1.02,1.12,30458
"Pork, fresh, variety meats and by-products, jowl, raw",Pork Products,6.38,0.004,0.025,0,0,0.148,0,0,69.61,22.19,655,25.26,32.89,8.11,30459
"Pork, fresh, variety meats and by-products, kidneys, cooked, braised",Pork Products,25.4,0.013,0.08,0,0.0106,0.143,0,0,4.7,68.7,151,1.51,1.55,0.38,30460
"Pork, fresh, variety meats and by-products, kidneys, raw",Pork Products,16.46,0.009,0.121,0,0.013300000000000001,0.229,0,0,3.25,80.06,100,1.04,1.07,0.26,30461
"Pork, fresh, variety meats and by-products, leaf fat, raw",Pork Products,1.76,0.001,0.005,0,0,0.031,0,0,94.16,4.09,857,45.23,37.22,7.28,30462
"Pork, fresh, variety meats and by-products, liver, cooked, braised",Pork Products,26.02,0.01,0.049,0,0.023600000000000003,0.15,3.76,0,4.4,64.32,165,1.41,0.63,1.05,30463
"Pork, fresh, variety meats and by-products, liver, raw",Pork Products,21.39,0.009,0.087,0,0.0253,0.273,2.47,0,3.65,71.06,134,1.17,0.52,0.87,30464
"Pork, fresh, variety meats and by-products, lungs, cooked, braised",Pork Products,16.6,0.008,0.081,0,0.0079,0.151,0,0,3.1,80,99,1.09,0.7,0.38,30465
"Pork, fresh, variety meats and by-products, lungs, raw",Pork Products,14.08,0.007,0.153,0,0.0123,0.303,0,0,2.72,79.52,85,0.96,0.61,0.34,30466
"Pork, fresh, variety meats and by-products, mechanically separated, raw",Pork Products,15.03,0.315,0.05,0,0.0007,0.298,0,0,26.54,56.87,304,9.82,12.26,2.44,30467
"Pork, fresh, variety meats and by-products, pancreas, cooked, braised",Pork Products,28.5,0.016,0.042,0,0.0057,0.168,0,0,10.8,60.3,219,3.73,3.75,2.03,30468
"Pork, fresh, variety meats and by-products, pancreas, raw",Pork Products,18.56,0.011,0.044,0,0.015300000000000001,0.197,0,0,13.24,67.18,199,4.58,4.6,2.48,30469
"Pork, fresh, variety meats and by-products, spleen, cooked, braised",Pork Products,28.2,0.013,0.107,0,0.0116,0.227,0,0,3.2,66.7,149,1.06,0.86,0.23,30470
"Pork, fresh, variety meats and by-products, spleen, raw",Pork Products,17.86,0.01,0.098,0,0.0285,0.396,0,0,2.59,78.43,100,0.86,0.69,0.19,30471
"Pork, fresh, variety meats and by-products, stomach, cooked, simmered",Pork Products,21.4,0.015,0.04,0,0,0.085,0.09,0,7.26,70.72,157,2.99,2.126,0.738,30472
"Pork, fresh, variety meats and by-products, stomach, raw",Pork Products,16.85,0.011,0.075,0,0,0.14,0,0,10.14,73.5,159,4.025,3.59,0.892,30473
"Pork, fresh, variety meats and by-products, tail, cooked, simmered",Pork Products,17,0.014,0.025,0,0,0.157,0,0,35.8,46.7,396,12.45,16.89,3.94,30474
"Pork, fresh, variety meats and by-products, tail, raw",Pork Products,17.75,0.018,0.063,0,0,0.349,0,0,33.5,46.05,378,11.64,15.8,3.68,30475
"Pork, fresh, variety meats and by-products, tongue, cooked, braised",Pork Products,24.1,0.019,0.109,0,0.0017,0.237,0,0,18.6,56.9,271,6.449,8.759,1.93,30476
"Pork, fresh, variety meats and by-products, tongue, raw",Pork Products,16.3,0.016,0.11,0,0.0044,0.243,0,0,17.2,65.9,225,5.96,8.13,1.78,30477
"Pork, ground, 72% lean / 28% fat, cooked, crumbles",Pork Products,22.83,0.02,0.094,0,0,0.28,1.39,0,32.93,41.67,393,11.311,15.326,4.323,30478
"Pork, ground, 72% lean / 28% fat, cooked, pan-broiled",Pork Products,22.59,0.02,0.091,0,0,0.275,1.08,0,31.42,43.85,377,10.804,14.356,4.222,30479
"Pork, ground, 72% lean / 28% fat, raw",Pork Products,14.87,0.016,0.069,0,0,0.178,0.66,0,28,55.71,314,9.422,13.009,3.882,30480
"Pork, ground, 84% lean / 16% fat, cooked, crumbles",Pork Products,26.69,0.02,0.089,0,0,0.354,0.58,0,20.04,51.35,289,6.631,9.021,2.549,30481
"Pork, ground, 84% lean / 16% fat, cooked, pan-broiled",Pork Products,27.14,0.02,0.089,0,0,0.345,0,0,21.39,51.97,301,7.453,10.032,3.061,30482
"Pork, ground, 84% lean / 16% fat, raw",Pork Products,17.99,0.015,0.068,0,0,0.244,0.44,0,16,64.67,218,5.362,7.28,2.235,30483
"Pork, ground, 96% lean / 4% fat, cooked, crumbles",Pork Products,30.55,0.019,0.084,0,0,0.428,0,0,7.15,61.03,187,1.951,2.714,0.776,30484
"Pork, ground, 96% lean / 4% fat, cooked, pan-broiled",Pork Products,31.69,0.02,0.088,0,0,0.415,0.57,0,6.2,60.08,185,2.076,2.621,0.906,30485
"Pork, ground, 96% lean / 4% fat, raw",Pork Products,21.1,0.015,0.067,0,0,0.31,0.21,0,4,73.62,121,1.314,1.744,0.611,30486
"Pork, loin, leg cap steak, boneless, separable lean and fat, cooked, broiled",Pork Products,27.57,0.007,0.076,0,0,0.366,0,0,4.41,68.72,158,1.358,1.928,0.578,30487
"Pork, oriental style, dehydrated",Pork Products,11.8,0.01,0.685,0,0,0.139,1.4,0,62.4,21.8,615,23.056,28.555,7.32,30488
"Pork, pickled pork hocks",Pork Products,19.11,0.019,1.05,0,0,0.047,0,0,10.54,68.02,171,3.231,5.134,1.198,30489
"Pork, shoulder, petite tender, boneless, separable lean and fat, cooked, broiled",Pork Products,27.47,0.008,0.053,0,0,0.415,0,0,4.23,68.7,155,1.322,1.724,0.616,30490
"USDA Commodity, pork, canned",Pork Products,19.4,0.005,0.213,0,0,0.243,0.59,0,12.95,65.82,196,4.225,5.935,1.79,30491
"USDA Commodity, pork, cured, ham, boneless, cooked, heated",Pork Products,18.84,0.006,1.155,0,0.0233,0.281,0,0,7.62,71.8,149,1.701,2.864,0.747,30492
"USDA Commodity, pork, cured, ham, boneless, cooked, unheated",Pork Products,17.44,0.007,1.21,0,0.022699999999999998,0.272,0.69,0,6.16,72.42,133,1.673,2.655,0.777,30493
"USDA Commodity, pork, ground, fine/coarse, frozen, cooked",Pork Products,23.55,0.008,0.076,0,0.0023,0.311,0,0,18.19,57.08,265,5.515,9.141,2.337,30494
"USDA Commodity, pork, ground, fine/coarse, frozen, raw",Pork Products,15.41,0.014,0.058,0,0.0006,0.297,0,0,17.18,64.46,221,6.379,7.651,1.548,30495
"Alfalfa seeds, sprouted, raw",Vegetables and Vegetable Products,3.99,0.032,0.006,1.9,0.008199999999999999,0.079,2.1,0.18,0.69,92.82,23,0.069,0.056,0.409,30496
"Amaranth leaves, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.11,0.209,0.257,0,0.041100000000000005,0.641,4.11,0,0.18,91.49,21,0.05,0.041,0.08,30497
"Amaranth leaves, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.11,0.209,0.021,0,0.041100000000000005,0.641,4.11,0,0.18,91.49,21,0.05,0.041,0.08,30498
"Amaranth leaves, raw",Vegetables and Vegetable Products,2.46,0.215,0.02,0,0.0433,0.611,4.02,0,0.33,91.69,23,0.091,0.076,0.147,30499
"Arrowhead, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,4.49,0.007,0.254,0,0.0003,0.881,16.14,0,0.1,77.08,78,0,0,0,30500
"Arrowhead, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,4.49,0.007,0.018,0,0.0003,0.881,16.14,0,0.1,77.08,78,0,0,0,30501
"Arrowhead, raw",Vegetables and Vegetable Products,5.33,0.01,0.022,0,0.0011,0.922,20.23,0,0.29,72.48,99,0,0,0,30502
"Arrowroot, raw",Vegetables and Vegetable Products,4.24,0.006,0.026,1.3,0.0019,0.454,13.39,0,0.2,80.75,65,0.039,0.004,0.092,30503
"Artichokes, (globe or french), cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.89,0.021,0.06,8.6,0.0074,0.286,11.95,0.99,0.34,84.08,53,0.081,0.011,0.143,30504
"Artichokes, (globe or french), cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.89,0.021,0.06,8.6,0.0074,0.286,11.95,0.99,0.34,84.08,53,0.081,0.011,0.143,30505
"Artichokes, (globe or french), frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,3.11,0.021,0.289,4.6,0.005,0.264,9.18,0.84,0.5,86.5,45,0.119,0.015,0.216,30506
"Artichokes, (globe or french), frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,3.11,0.021,0.053,4.6,0.005,0.264,9.18,0.84,0.5,86.5,45,0.119,0.015,0.216,30507
"Artichokes, (globe or french), frozen, unprepared",Vegetables and Vegetable Products,2.63,0.019,0.047,3.9,0.0053,0.248,7.76,0,0.43,88.59,38,0.099,0.012,0.179,30508
"Artichokes, (globe or french), raw",Vegetables and Vegetable Products,3.27,0.044,0.094,5.4,0.011699999999999999,0.37,10.51,0.99,0.15,84.94,47,0.036,0.005,0.064,30509
"Arugula, raw",Vegetables and Vegetable Products,2.58,0.16,0.027,1.6,0.015,0.369,3.65,2.05,0.66,91.71,25,0.086,0.049,0.319,30510
"Asparagus, canned, drained solids",Vegetables and Vegetable Products,2.14,0.016,0.287,1.6,0.0184,0.172,2.46,1.06,0.65,93.98,19,0.147,0.021,0.284,30511
"Asparagus, canned, no salt added, solids and liquids",Vegetables and Vegetable Products,1.8,0.015,0.026,1,0.0165,0.172,2.48,1,0.18,94.32,15,0.041,0.006,0.079,30512
"Asparagus, canned, regular pack, solids and liquids",Vegetables and Vegetable Products,1.8,0.015,0.284,1,0.0165,0.172,2.47,0,0.18,94.32,15,0.044,0.006,0.085,30513
"Asparagus, cooked, boiled, drained",Vegetables and Vegetable Products,2.4,0.023,0.014,2,0.0077,0.224,4.11,1.3,0.22,92.63,22,0.048,0,0.105,30514
"Asparagus, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.4,0.023,0.24,2,0.0077,0.224,4.11,1.3,0.22,92.63,22,0.048,0,0.105,30515
"Asparagus, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.95,0.018,0.24,1.6,0.024399999999999998,0.172,1.92,0.32,0.42,94.1,18,0.096,0.013,0.185,30516
"Asparagus, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.95,0.018,0.003,1.6,0.024399999999999998,0.172,1.92,0.32,0.42,94.1,18,0.096,0.013,0.185,30517
"Asparagus, frozen, unprepared",Vegetables and Vegetable Products,3.23,0.025,0.008,1.9,0.0318,0.253,4.1,0,0.23,91.82,24,0.052,0.007,0.101,30518
"Asparagus, raw",Vegetables and Vegetable Products,2.2,0.024,0.002,2.1,0.0056,0.202,3.88,1.88,0.12,93.22,20,0.04,0,0.05,30519
"Balsam-pear (bitter gourd), leafy tips, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,3.6,0.042,0.249,1.9,0.055600000000000004,0.602,6.16,1.04,0.2,88.69,32,0,0,0,30520
"Balsam-pear (bitter gourd), leafy tips, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,3.6,0.042,0.013,1.9,0.055600000000000004,0.602,6.68,1.04,0.2,88.69,34,0.032,0.005,0.083,30521
"Balsam-pear (bitter gourd), leafy tips, raw",Vegetables and Vegetable Products,5.3,0.084,0.011,0,0.088,0.608,3.29,0,0.69,89.25,30,0,0,0,30522
"Balsam-pear (bitter gourd), pods, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.84,0.009,0.242,2,0.033,0.319,4.32,1.95,0.18,93.95,19,0,0,0,30523
"Balsam-pear (bitter gourd), pods, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.84,0.009,0.006,2,0.033,0.319,4.32,1.95,0.18,93.95,19,0.014,0.033,0.078,30524
"Balsam-pear (bitter gourd), pods, raw",Vegetables and Vegetable Products,1,0.019,0.005,2.8,0.084,0.296,3.7,0,0.17,94.03,17,0,0,0,30525
"Bamboo shoots, canned, drained solids",Vegetables and Vegetable Products,1.72,0.008,0.007,1.4,0.0011,0.08,3.22,1.89,0.4,94.32,19,0.092,0.009,0.178,30526
"Bamboo shoots, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.53,0.012,0.24,1,0,0.533,1.52,0,0.22,95.92,11,0.051,0.005,0.098,30527
"Bamboo shoots, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.53,0.012,0.004,1,0,0.533,1.92,0,0.22,95.92,12,0.051,0.005,0.098,30528
"Bamboo shoots, raw",Vegetables and Vegetable Products,2.6,0.013,0.004,2.2,0.004,0.533,5.2,3,0.3,91,27,0.069,0.007,0.134,30529
"Beans, fava, in pod, raw",Vegetables and Vegetable Products,7.92,0.037,0.025,0,0.0037,0.332,17.63,0,0.73,72.6,88,0.118,0.104,0.342,30530
"Beans, kidney, mature seeds, sprouted, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,4.83,0.019,0.243,0,0.0356,0.194,4.72,0,0.58,89.3,33,0.083,0.045,0.318,30531
"Beans, kidney, mature seeds, sprouted, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,4.83,0.019,0.007,0,0.0356,0.194,4.72,0,0.58,89.3,33,0.083,0.045,0.318,30532
"Beans, kidney, mature seeds, sprouted, raw",Vegetables and Vegetable Products,4.2,0.017,0.006,0,0.038700000000000005,0.187,4.1,0,0.5,90.7,29,0.072,0.039,0.276,30533
"Beans, lima, immature seeds, canned, regular pack, solids and liquids",Vegetables and Vegetable Products,4.07,0.028,0.252,3.6,0.0073,0.285,13.33,0,0.29,81.17,71,0.066,0.016,0.139,30534
"Beans, mung, mature seeds, sprouted, canned, drained solids",Vegetables and Vegetable Products,1.4,0.014,0.14,0.8,0.0003,0.027,2.14,1.34,0.06,96.1,12,0.016,0.008,0.02,30535
"Beans, navy, mature seeds, sprouted, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,7.07,0.016,0.25,0,0.0173,0.317,15.01,0,0.81,76.02,78,0.098,0.06,0.468,30536
"Beans, navy, mature seeds, sprouted, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,7.07,0.016,0.014,0,0.0173,0.317,15.01,0,0.81,76.02,78,0.098,0.06,0.468,30537
"Beans, navy, mature seeds, sprouted, raw",Vegetables and Vegetable Products,6.15,0.015,0.013,0,0.0188,0.307,13.05,0,0.7,79.15,67,0.085,0.052,0.407,30538
"Beans, pinto, immature seeds, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,9.31,0.052,0.319,8.6,0.0007,0.646,30.88,0,0.48,58.01,162,0.058,0.035,0.276,30539
"Beans, pinto, immature seeds, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,9.31,0.052,0.083,8.6,0.0007,0.646,30.88,0,0.48,58.01,162,0.058,0.035,0.276,30540
"Beans, pinto, immature seeds, frozen, unprepared",Vegetables and Vegetable Products,9.8,0.058,0.092,5.7,0.001,0.756,32.5,0,0.5,55.8,170,0.061,0.037,0.291,30541
"Beans, pinto, mature seeds, sprouted, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.86,0.015,0.287,0,0.0060999999999999995,0.098,3.5,0,0.32,93.39,20,0.039,0.024,0.185,30542
"Beans, pinto, mature seeds, sprouted, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.86,0.015,0.051,0,0.0060999999999999995,0.098,4.1,0,0.32,93.39,22,0.039,0.024,0.185,30543
"Beans, pinto, mature seeds, sprouted, raw",Vegetables and Vegetable Products,5.25,0.043,0.153,0,0.0217,0.307,11.6,0,0.9,81.3,62,0.109,0.067,0.523,30544
"Beans, shellie, canned, solids and liquids",Vegetables and Vegetable Products,1.76,0.029,0.334,3.4,0.0031,0.109,6.19,0.63,0.19,90.69,30,0.023,0.014,0.11,30545
"Beans, snap, canned, all styles, seasoned, solids and liquids",Vegetables and Vegetable Products,0.83,0.022,0.373,1.5,0.0031,0.093,3.49,0,0.2,94.3,16,0.045,0.008,0.102,30546
"Beans, snap, green variety, canned, regular pack, solids and liquids",Vegetables and Vegetable Products,0.8,0.024,0.259,1.5,0.0034,0.092,3.5,0,0.1,94.68,15,0.023,0.004,0.052,30547
"Beans, snap, green, canned, no salt added, drained solids",Vegetables and Vegetable Products,1.15,0.026,0.002,1.9,0.0048,0.109,4.5,0.77,0.1,93.3,20,0.023,0.004,0.052,30548
"Beans, snap, green, canned, no salt added, solids and liquids",Vegetables and Vegetable Products,0.8,0.024,0.014,1.5,0.0034,0.092,3.5,0,0.1,94.68,15,0.023,0.004,0.052,30549
"Beans, snap, green, canned, regular pack, drained solids",Vegetables and Vegetable Products,1.18,0.028,0.262,2.3,0.0043,0.111,4.41,0.78,0.11,93.29,23,0.025,0.004,0.056,30550
"Beans, snap, green, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.89,0.044,0.239,3.2,0.009699999999999999,0.146,7.88,1.55,0.28,89.22,35,0.062,0.012,0.145,30551
"Beans, snap, green, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.89,0.044,0.001,3.2,0.009699999999999999,0.146,7.88,1.55,0.28,89.22,35,0.062,0.012,0.145,30552
"Beans, snap, green, frozen, all styles, microwaved",Vegetables and Vegetable Products,1.98,0.061,0.003,3.4,0.011,0.237,6.98,2.6,0.41,89.93,40,0.047,0.008,0.108,30553
"Beans, snap, green, frozen, all styles, unprepared",Vegetables and Vegetable Products,1.79,0.042,0.003,2.6,0.0129,0.186,7.54,2.21,0.21,89.93,39,0.047,0.008,0.108,30554
"Beans, snap, green, frozen, cooked, boiled, drained without salt",Vegetables and Vegetable Products,1.49,0.042,0.001,3,0.0040999999999999995,0.159,6.45,1.23,0.17,91.42,28,0.042,0.007,0.084,30555
"Beans, snap, green, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.49,0.042,0.245,3,0.0040999999999999995,0.159,5.86,1.23,0.17,91.42,26,0.042,0.007,0.084,30556
"Beans, snap, green, microwaved",Vegetables and Vegetable Products,2.31,0.055,0.003,3.4,0.0073,0.323,6.41,3.22,0.5,90.04,39,0,0,0,30557
"Beans, snap, green, raw",Vegetables and Vegetable Products,1.83,0.037,0.006,2.7,0.012199999999999999,0.211,6.97,3.26,0.22,90.32,31,0.05,0.01,0.113,30558
"Beans, snap, yellow, canned, no salt added, drained solids",Vegetables and Vegetable Products,1.15,0.026,0.002,1.3,0.0048,0.109,4.5,0.96,0.1,93.3,20,0.022,0.004,0.051,30559
"Beans, snap, yellow, canned, no salt added, solids and liquids",Vegetables and Vegetable Products,0.8,0.024,0.014,1.5,0.004,0.098,3.5,0,0.1,94.68,15,0.023,0.004,0.052,30560
"Beans, snap, yellow, canned, regular pack, drained solids",Vegetables and Vegetable Products,1.15,0.026,0.251,1.3,0.0048,0.109,4.5,0.96,0.1,93.3,20,0.022,0.004,0.051,30561
"Beans, snap, yellow, canned, regular pack, solids and liquids",Vegetables and Vegetable Products,0.8,0.024,0.259,1.5,0.0034,0.098,3.5,0,0.1,94.68,15,0.023,0.004,0.052,30562
"Beans, snap, yellow, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.89,0.046,0.239,3.3,0.009699999999999999,0.299,7.88,1.55,0.28,89.22,35,0.062,0.012,0.145,30563
"Beans, snap, yellow, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.89,0.046,0.003,3.3,0.009699999999999999,0.299,7.88,1.55,0.28,89.22,35,0.062,0.012,0.145,30564
"Beans, snap, yellow, frozen, all styles, unprepared",Vegetables and Vegetable Products,1.8,0.042,0.003,2.8,0.0129,0.186,7.58,0,0.21,89.88,33,0.047,0.008,0.108,30565
"Beans, snap, yellow, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.49,0.049,0.245,3,0.0040999999999999995,0.126,5.86,1.23,0.17,91.42,26,0.042,0.007,0.084,30566
"Beans, snap, yellow, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.49,0.049,0.009,3,0.0040999999999999995,0.126,6.45,1.23,0.17,91.42,28,0.042,0.007,0.084,30567
"Beans, snap, yellow, raw",Vegetables and Vegetable Products,1.82,0.037,0.006,3.4,0.016300000000000002,0.209,7.13,0,0.12,90.27,31,0.026,0.005,0.059,30568
"Beet greens, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.57,0.114,0.477,2.9,0.0249,0.909,5.46,0.6,0.2,89.13,27,0.031,0.038,0.07,30569
"Beet greens, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.57,0.114,0.241,2.9,0.0249,0.909,5.46,0.6,0.2,89.13,27,0.031,0.038,0.07,30570
"Beet greens, raw",Vegetables and Vegetable Products,2.2,0.117,0.226,3.7,0.03,0.762,4.33,0.5,0.13,91.02,22,0.02,0.026,0.046,30571
"Beets, canned, drained solids",Vegetables and Vegetable Products,0.91,0.015,0.194,1.8,0.0040999999999999995,0.148,7.21,5.51,0.14,90.96,31,0.022,0.027,0.049,30572
"Beets, canned, no salt added, solids and liquids",Vegetables and Vegetable Products,0.8,0.013,0.021,1.2,0.0028,0.142,6.57,5.38,0.07,91.62,28,0.011,0.013,0.025,30573
"Beets, canned, regular pack, solids and liquids",Vegetables and Vegetable Products,0.73,0.018,0.143,1.2,0.0028,0.159,7.14,6.53,0.09,91.3,30,0.013,0.016,0.03,30574
"Beets, cooked, boiled, drained",Vegetables and Vegetable Products,1.68,0.016,0.077,2,0.0036,0.305,9.96,7.96,0.18,87.06,44,0.028,0.035,0.064,30575
"Beets, cooked, boiled. drained, with salt",Vegetables and Vegetable Products,1.68,0.016,0.285,2,0.0036,0.305,9.96,7.96,0.18,87.06,44,0.028,0.035,0.064,30576
"Beets, harvard, canned, solids and liquids",Vegetables and Vegetable Products,0.84,0.011,0.162,2.5,0.0024,0.164,18.18,0,0.06,80.16,73,0.009,0.011,0.019,30577
"Beets, pickled, canned, solids and liquids",Vegetables and Vegetable Products,0.8,0.011,0.264,2.6,0.0023,0.148,16.28,13.59,0.08,81.88,65,0.013,0.016,0.029,30578
"Beets, raw",Vegetables and Vegetable Products,1.61,0.016,0.078,2.8,0.004900000000000001,0.325,9.56,6.76,0.17,87.58,43,0.027,0.032,0.06,30579
"Borage, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.09,0.102,0.324,0,0.0325,0.491,3.55,0,0.81,91.88,25,0.197,0.245,0.127,30580
"Borage, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.09,0.102,0.088,0,0.0325,0.491,3.55,0,0.81,91.88,25,0.197,0.245,0.127,30581
"Borage, raw",Vegetables and Vegetable Products,1.8,0.093,0.08,0,0.035,0.47,3.06,0,0.7,93,21,0.17,0.211,0.109,30582
"Broadbeans, immature seeds, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,4.8,0.018,0.277,0,0.0198,0.193,10.1,0,0.5,83.7,62,0.142,0.02,0.303,30583
"Broadbeans, immature seeds, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,4.8,0.018,0.041,3.6,0.0198,0.193,10.1,0,0.5,83.7,62,0.142,0.02,0.303,30584
"Broadbeans, immature seeds, raw",Vegetables and Vegetable Products,5.6,0.022,0.05,4.2,0.033,0.25,11.7,0,0.6,81,72,0.138,0.017,0.31,30585
"Broccoli raab, cooked",Vegetables and Vegetable Products,3.83,0.118,0.056,2.8,0.037,0.343,3.12,0.62,0.52,91.41,33,0.057,0.03,0.15,30586
"Broccoli raab, raw",Vegetables and Vegetable Products,3.17,0.108,0.033,2.7,0.0202,0.196,2.85,0.38,0.49,92.55,22,0.05,0.026,0.13,30587
"Broccoli, chinese, cooked",Vegetables and Vegetable Products,1.14,0.1,0.007,2.5,0.0282,0.261,3.81,0.84,0.72,93.54,22,0.11,0.05,0.33,30588
"Broccoli, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.38,0.04,0.262,3.3,0.0649,0.293,7.18,1.39,0.41,89.25,35,0.079,0.04,0.17,30589
"Broccoli, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.38,0.04,0.041,3.3,0.0649,0.293,7.18,1.39,0.41,89.25,35,0.079,0.04,0.17,30590
"Broccoli, flower clusters, raw",Vegetables and Vegetable Products,2.98,0.048,0.027,0,0.0932,0.325,5.24,0,0.35,90.69,28,0.054,0.024,0.167,30591
"Broccoli, frozen, chopped, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,3.1,0.033,0.26,3,0.040100000000000004,0.142,5.35,1.47,0.12,90.72,28,0.018,0.008,0.055,30592
"Broccoli, frozen, chopped, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,3.1,0.033,0.011,3,0.040100000000000004,0.142,5.35,1.47,0.12,90.72,28,0.018,0.008,0.055,30593
"Broccoli, frozen, chopped, unprepared",Vegetables and Vegetable Products,2.81,0.056,0.024,3,0.0564,0.212,4.78,1.35,0.29,91.46,26,0.044,0.02,0.136,30594
"Broccoli, frozen, spears, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,3.1,0.051,0.26,3,0.040100000000000004,0.18,5.35,1.44,0.11,90.72,28,0.018,0.008,0.055,30595
"Broccoli, frozen, spears, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,3.1,0.051,0.024,3,0.040100000000000004,0.18,5.36,1.47,0.11,90.72,28,0.018,0.008,0.055,30596
"Broccoli, frozen, spears, unprepared",Vegetables and Vegetable Products,3.06,0.041,0.017,3,0.0683,0.25,5.35,1.47,0.34,90.55,29,0.052,0.024,0.162,30597
"Broccoli, leaves, raw",Vegetables and Vegetable Products,2.98,0.048,0.027,0,0.0932,0.325,5.24,0,0.35,90.69,28,0.054,0.024,0.167,30598
"Broccoli, raw",Vegetables and Vegetable Products,2.82,0.047,0.033,2.6,0.0892,0.316,6.64,1.7,0.37,89.3,34,0.039,0.011,0.038,30599
"Broccoli, stalks, raw",Vegetables and Vegetable Products,2.98,0.048,0.027,0,0.0932,0.325,5.24,0,0.35,90.69,28,0.054,0.024,0.167,30600
"Brussels sprouts, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.55,0.036,0.257,2.6,0.062,0.317,7.1,1.74,0.5,88.9,36,0.102,0.038,0.255,30601
"Brussels sprouts, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.55,0.036,0.021,2.6,0.062,0.317,7.1,1.74,0.5,88.9,36,0.102,0.038,0.255,30602
"Brussels sprouts, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,3.64,0.026,0.259,4.1,0.045700000000000005,0.29,8.32,2.08,0.39,86.74,42,0.081,0.03,0.2,30603
"Brussels sprouts, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,3.64,0.026,0.015,4.1,0.045700000000000005,0.29,8.32,2.08,0.39,86.74,42,0.081,0.03,0.2,30604
"Brussels sprouts, frozen, unprepared",Vegetables and Vegetable Products,3.78,0.026,0.01,3.8,0.0741,0.37,7.86,0,0.41,87.07,41,0.084,0.031,0.207,30605
"Brussels sprouts, raw",Vegetables and Vegetable Products,3.38,0.042,0.025,3.8,0.085,0.389,8.95,2.2,0.3,86,43,0.062,0.023,0.153,30606
"Burdock root, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.09,0.049,0.24,1.8,0.0026,0.36,21.15,3.55,0.14,75.64,88,0,0,0,30607
"Burdock root, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.09,0.049,0.004,1.8,0.0026,0.36,21.15,3.55,0.14,75.64,88,0.023,0.035,0.055,30608
"Burdock root, raw",Vegetables and Vegetable Products,1.53,0.041,0.005,3.3,0.003,0.308,17.34,2.9,0.15,80.09,72,0.025,0.037,0.059,30609
"Butterbur, (fuki), raw",Vegetables and Vegetable Products,0.39,0.103,0.007,0,0.0315,0.655,3.61,0,0.04,94.5,14,0,0,0,30610
"Butterbur, canned",Vegetables and Vegetable Products,0.11,0.034,0.004,0,0.0119,0.012,0.38,0,0.13,97.92,3,0,0,0,30611
"Butterbur, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.23,0.059,0.24,0,0.0189,0.354,2.16,0,0.02,96.7,8,0,0,0,30612
"Butterbur, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.23,0.059,0.004,0,0.0189,0.354,2.16,0,0.02,96.7,8,0,0,0,30613
"CAMPBELL Soup Company, HEALTHY REQUEST Tomato juice",Vegetables and Vegetable Products,0.82,0.008,0.198,0.8,0.0296,0.206,4.53,3.7,0,93.7,21,0,0,0,30614
"CAMPBELL Soup Company, Organic Tomato juice",Vegetables and Vegetable Products,0.82,0.008,0.28,0.8,0.0296,0.177,4.12,2.88,0,93.8,21,0,0,0,30615
"CAMPBELL Soup Company, PACE, Diced Green Chilies",Vegetables and Vegetable Products,0,0,0.333,3.3,0.012,0,6.67,3.33,0,92.3,27,0,0,0,30616
"CAMPBELL Soup Company, PACE, Jalapenos Nacho Sliced Peppers",Vegetables and Vegetable Products,0,0.067,1,3.3,0.012,0,3.33,3.33,0,91.3,13,0,0,0,30617
"CAMPBELL Soup Company, Tomato juice",Vegetables and Vegetable Products,0.82,0.008,0.28,0.8,0.0296,0.177,4.12,2.88,0,93.9,21,0,0,0,30618
"CAMPBELL Soup Company, Tomato juice, low sodium",Vegetables and Vegetable Products,0.82,0.008,0.058,0.8,0.0296,0.379,4.12,2.88,0,93.9,21,0,0,0,30619
"CAMPBELL Soup Company, V8 60% Vegetable Juice, V8 V-Lite",Vegetables and Vegetable Products,0.41,0.016,0.148,0.4,0.0296,0.111,2.88,2.06,0,95.5,14,0,0,0,30620
"CAMPBELL Soup Company, V8 Vegetable Juice, Calcium Enriched V8",Vegetables and Vegetable Products,0.82,0.123,0.198,0.8,0.0296,0.28,4.53,3.29,0,93.1,21,0,0,0,30621
"CAMPBELL Soup Company, V8 Vegetable Juice, Essential Antioxidants V8",Vegetables and Vegetable Products,0.82,0.016,0.198,0.8,0.0494,0.177,4.53,3.29,0,93.3,21,0,0,0,30622
"CAMPBELL Soup Company, V8 Vegetable Juice, High Fiber V8",Vegetables and Vegetable Products,0.82,0.016,0.198,2.1,0.0296,0.193,5.39,3.29,0,92.5,25,0,0,0,30623
"CAMPBELL Soup Company, V8 Vegetable Juice, Low Sodium Spicy Hot",Vegetables and Vegetable Products,0.82,0.008,0.058,0.8,0.0247,0.412,4.53,3.29,0,94,21,0,0,0,30624
"CAMPBELL Soup Company, V8 Vegetable Juice, Low Sodium V8",Vegetables and Vegetable Products,0.82,0.008,0.058,0.8,0.0296,0.337,4.12,3.29,0,93.9,21,0,0,0,30625
"CAMPBELL Soup Company, V8 Vegetable Juice, Organic V8",Vegetables and Vegetable Products,0.41,0.008,0.198,0.8,0.0296,0.193,4.53,3.29,0,93.6,20,0,0,0,30626
"CAMPBELL Soup Company, V8 Vegetable Juice, Spicy Hot V8",Vegetables and Vegetable Products,0.82,0.016,0.198,0.8,0.0296,0.267,4.12,3.29,0,94,21,0,0,0,30627
"Cabbage, chinese (pak-choi), cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.56,0.093,0.27,1,0.026,0.371,1.78,0.83,0.16,95.55,12,0.021,0.012,0.077,30628
"Cabbage, chinese (pak-choi), cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.56,0.093,0.034,1,0.026,0.371,1.78,0.83,0.16,95.55,12,0.021,0.012,0.077,30629
"Cabbage, chinese (pak-choi), raw",Vegetables and Vegetable Products,1.5,0.105,0.065,1,0.045,0.252,2.18,1.18,0.2,95.32,13,0.027,0.015,0.096,30630
"Cabbage, chinese (pe-tsai), cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.5,0.032,0.245,1.7,0.0158,0.225,2.41,0,0.17,95.24,14,0.036,0.02,0.061,30631
"Cabbage, chinese (pe-tsai), cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.5,0.032,0.009,1.7,0.0158,0.225,2.41,0,0.17,95.24,14,0.036,0.02,0.061,30632
"Cabbage, chinese (pe-tsai), raw",Vegetables and Vegetable Products,1.2,0.077,0.009,1.2,0.027,0.238,3.23,1.41,0.2,94.39,16,0.043,0.023,0.072,30633
"Cabbage, common (danish, domestic, and pointed types), freshly harvest, raw",Vegetables and Vegetable Products,1.21,0.047,0.018,2.3,0.051,0.246,5.37,0,0.18,92.52,24,0.023,0.013,0.087,30634
"Cabbage, common (danish, domestic, and pointed types), stored, raw",Vegetables and Vegetable Products,1.21,0.047,0.018,2.3,0.042,0.246,5.37,0,0.18,92.52,24,0.023,0.013,0.087,30635
"Cabbage, common, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.27,0.048,0.255,1.9,0.0375,0.196,5.51,2.79,0.06,92.57,23,0,0.019,0.023,30636
"Cabbage, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.27,0.048,0.008,1.9,0.0375,0.196,5.51,2.79,0.06,92.57,23,0,0.019,0.023,30637
"Cabbage, japanese style, fresh, pickled",Vegetables and Vegetable Products,1.6,0.048,0.277,3.1,0.0007,0.853,5.67,1.3,0.1,91.4,30,0.013,0.008,0.048,30638
"Cabbage, mustard, salted",Vegetables and Vegetable Products,1.1,0.067,0.717,3.1,0,0.246,5.63,1.4,0.1,92.1,28,0.013,0.007,0.048,30639
"Cabbage, napa, cooked",Vegetables and Vegetable Products,1.1,0.029,0.011,0,0.0032,0.087,2.23,0,0.17,96.33,12,0,0,0,30640
"Cabbage, raw",Vegetables and Vegetable Products,1.28,0.04,0.018,2.5,0.0366,0.17,5.8,3.2,0.1,92.18,25,0.034,0.017,0.017,30641
"Cabbage, red, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.51,0.042,0.244,2.6,0.0108,0.262,6.94,3.32,0.09,90.84,29,0.011,0.007,0.043,30642
"Cabbage, red, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.51,0.042,0.028,2.6,0.0344,0.262,6.94,3.32,0.09,90.84,29,0.011,0.007,0.043,30643
"Cabbage, red, raw",Vegetables and Vegetable Products,1.43,0.045,0.027,2.1,0.057,0.243,7.37,3.83,0.16,90.39,31,0.021,0.012,0.08,30644
"Cabbage, savoy, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.8,0.03,0.26,2.8,0.017,0.184,5.41,0,0.09,92,24,0.012,0.007,0.043,30645
"Cabbage, savoy, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.8,0.03,0.024,2.8,0.017,0.184,5.41,0,0.09,92,24,0.012,0.007,0.043,30646
"Cabbage, savoy, raw",Vegetables and Vegetable Products,2,0.035,0.028,3.1,0.031,0.23,6.1,2.27,0.1,91,27,0.013,0.007,0.049,30647
Campbell's V8 100% Vegetable Juice,Vegetables and Vegetable Products,0.82,0.016,0.173,0.8,0.0296,0.193,4.12,3.29,0,93.9,21,0,0,0,30648
"Cardoon, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.76,0.072,0.412,1.7,0.0017,0.392,4.74,0,0.11,93.46,20,0.012,0.02,0.044,30649
"Cardoon, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.76,0.072,0.176,1.7,0.0017,0.392,5.33,0,0.11,93.46,22,0.012,0.02,0.044,30650
"Cardoon, raw",Vegetables and Vegetable Products,0.7,0.07,0.17,1.6,0.002,0.4,4.07,0,0.1,94,17,0.011,0.018,0.041,30651
"Carrot juice, canned",Vegetables and Vegetable Products,0.95,0.024,0.029,0.8,0.0085,0.292,9.28,3.91,0.15,88.87,40,0.027,0.007,0.071,30652
"Carrot, dehydrated",Vegetables and Vegetable Products,8.1,0.212,0.275,23.6,0.0146,2.54,79.57,38.82,1.49,4,341,0.256,0.076,0.73,30653
"Carrots, baby, raw",Vegetables and Vegetable Products,0.64,0.032,0.078,2.9,0.0026,0.237,8.24,4.76,0.13,90.35,35,0.023,0.007,0.065,30654
"Carrots, canned, no salt added, drained solids",Vegetables and Vegetable Products,0.64,0.025,0.042,1.5,0.0027,0.179,5.54,2.48,0.19,92.95,25,0.036,0.009,0.092,30655
"Carrots, canned, no salt added, solids and liquids",Vegetables and Vegetable Products,0.59,0.031,0.034,1.8,0.002,0.158,5.36,2.46,0.14,92.99,23,0.024,0.007,0.068,30656
"Carrots, canned, regular pack, drained solids",Vegetables and Vegetable Products,0.64,0.025,0.242,1.5,0.0027,0.179,5.54,2.48,0.19,92.95,25,0.036,0.009,0.092,30657
"Carrots, canned, regular pack, solids and liquids",Vegetables and Vegetable Products,0.58,0.031,0.24,1.8,0.002,0.173,5.37,2.46,0.14,92.99,23,0.025,0.006,0.065,30658
"Carrots, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.76,0.03,0.302,3,0.0036,0.235,8.22,3.45,0.18,90.17,35,0.037,0.007,0.107,30659
"Carrots, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.76,0.03,0.058,3,0.0036,0.235,8.22,3.45,0.18,90.17,35,0.03,0.006,0.089,30660
"Carrots, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.58,0.035,0.295,3.3,0.0023,0.192,7.73,4.08,0.68,90.32,37,0.132,0.035,0.318,30661
"Carrots, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.58,0.035,0.059,3.3,0.0023,0.192,7.73,4.08,0.68,90.32,37,0.12,0.031,0.334,30662
"Carrots, frozen, unprepared",Vegetables and Vegetable Products,0.78,0.036,0.068,3.3,0.0025,0.235,7.9,4.76,0.46,90.04,36,0.047,0.014,0.263,30663
"Carrots, raw",Vegetables and Vegetable Products,0.93,0.033,0.069,2.8,0.005900000000000001,0.32,9.58,4.74,0.24,88.29,41,0.037,0.014,0.117,30664
"Cassava, raw",Vegetables and Vegetable Products,1.36,0.016,0.014,1.8,0.0206,0.271,38.06,1.7,0.28,59.68,160,0.074,0.075,0.048,30665
Catsup,Vegetables and Vegetable Products,1.74,0.018,1.114,0.3,0.015099999999999999,0.382,25.15,22.77,0.31,69.15,97,0.043,0.047,0.126,30666
"Catsup, low sodium",Vegetables and Vegetable Products,1.74,0.018,0.02,0.3,0.015099999999999999,0.382,25.15,22.77,0.31,69.15,97,0.043,0.047,0.126,30667
"Cauliflower, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.84,0.016,0.242,2.3,0.0443,0.142,4.11,1.86,0.45,93,23,0.07,0.032,0.217,30668
"Cauliflower, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.84,0.016,0.015,2.3,0.0443,0.142,4.11,2.08,0.45,93,23,0.07,0.032,0.217,30669
"Cauliflower, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.61,0.017,0.254,2.7,0.0313,0.139,3.16,0.46,0.22,94,17,0.034,0.015,0.105,30670
"Cauliflower, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.61,0.017,0.018,2.7,0.0313,0.139,3.75,1.05,0.22,94,19,0.034,0.015,0.105,30671
"Cauliflower, frozen, unprepared",Vegetables and Vegetable Products,2.01,0.022,0.024,2.3,0.048799999999999996,0.193,4.68,2.22,0.27,92.51,24,0.041,0.019,0.128,30672
"Cauliflower, green, cooked, no salt added",Vegetables and Vegetable Products,3.04,0.032,0.023,3.3,0.0726,0.278,6.28,0,0.31,89.47,32,0.049,0.029,0.137,30673
"Cauliflower, green, cooked, with salt",Vegetables and Vegetable Products,3.04,0.032,0.259,3.3,0.0726,0.278,6.28,0,0.31,89.47,32,0.049,0.029,0.137,30674
"Cauliflower, green, raw",Vegetables and Vegetable Products,2.95,0.033,0.023,3.2,0.0881,0.3,6.09,3.03,0.3,89.79,31,0.047,0.028,0.133,30675
"Cauliflower, raw",Vegetables and Vegetable Products,1.92,0.022,0.03,2,0.0482,0.299,4.97,1.91,0.28,92.07,25,0.064,0.017,0.015,30676
"Celeriac, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.96,0.026,0.297,0,0.0036,0.173,5.9,0,0.19,92.3,27,0,0,0,30677
"Celeriac, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.96,0.026,0.061,1.2,0.0036,0.173,5.9,0,0.19,92.3,27,0,0,0,30678
"Celeriac, raw",Vegetables and Vegetable Products,1.5,0.043,0.1,1.8,0.008,0.3,9.2,1.6,0.3,88,42,0.079,0.058,0.148,30679
"Celery flakes, dried",Vegetables and Vegetable Products,11.3,0.587,1.435,27.8,0.0865,4.388,63.7,35.9,2.1,9,319,0.555,0.405,1.035,30680
"Celery, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.83,0.042,0.327,1.6,0.0060999999999999995,0.284,4,2.37,0.16,94.11,18,0.04,0.03,0.075,30681
"Celery, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.83,0.042,0.091,1.6,0.0060999999999999995,0.284,4,2.37,0.16,94.11,18,0.04,0.03,0.075,30682
"Celery, raw",Vegetables and Vegetable Products,0.69,0.04,0.08,1.6,0.0031,0.26,2.97,1.83,0.17,95.43,16,0.042,0.032,0.079,30683
"Celtuce, raw",Vegetables and Vegetable Products,0.85,0.039,0.011,1.7,0.0195,0.33,3.65,0,0.3,94.5,18,0,0,0,30684
"Chard, swiss, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.88,0.058,0.415,2.1,0.018,0.549,4.13,1.1,0.08,92.65,20,0,0,0,30685
"Chard, swiss, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.88,0.058,0.179,2.1,0.018,0.549,4.13,1.1,0.08,92.65,20,0.012,0.016,0.028,30686
"Chard, swiss, raw",Vegetables and Vegetable Products,1.8,0.051,0.213,1.6,0.03,0.379,3.74,1.1,0.2,92.66,19,0.03,0.04,0.07,30687
"Chayote, fruit, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.62,0.013,0.237,2.8,0.008,0.173,4.5,0,0.48,93.43,22,0.086,0,0,30688
"Chayote, fruit, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.62,0.013,0.001,2.8,0.008,0.173,5.09,0,0.48,93.43,24,0.086,0,0,30689
"Chayote, fruit, raw",Vegetables and Vegetable Products,0.82,0.017,0.002,1.7,0.0077,0.125,4.51,1.66,0.13,94.24,19,0.028,0.01,0.057,30690
"Chicory greens, raw",Vegetables and Vegetable Products,1.7,0.1,0.045,4,0.024,0.42,4.7,0.7,0.3,92,23,0.073,0.006,0.131,30691
"Chicory roots, raw",Vegetables and Vegetable Products,1.4,0.041,0.05,1.5,0.005,0.29,17.51,8.73,0.2,80,72,0.048,0.004,0.087,30692
"Chicory, witloof, raw",Vegetables and Vegetable Products,0.9,0.019,0.002,3.1,0.0028,0.211,4,0,0.1,94.52,17,0.024,0.002,0.044,30693
"Chives, freeze-dried",Vegetables and Vegetable Products,21.2,0.813,0.07,26.2,0.66,2.96,64.29,0,3.5,2,311,0.591,0.49,1.369,30694
"Chives, raw",Vegetables and Vegetable Products,3.27,0.092,0.003,2.5,0.0581,0.296,4.35,1.85,0.73,90.65,30,0.146,0.095,0.267,30695
"Chrysanthemum leaves, raw",Vegetables and Vegetable Products,3.36,0.117,0.118,3,0.0014,0.567,3.01,0,0.56,91.4,24,0,0,0,30696
"Chrysanthemum, garland, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.64,0.069,0.289,2.3,0.023899999999999998,0.569,4.31,2.01,0.09,92.49,20,0,0,0,30697
"Chrysanthemum, garland, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.64,0.069,0.053,2.3,0.023899999999999998,0.569,4.31,2.01,0.09,92.49,20,0.022,0.002,0.039,30698
"Chrysanthemum, garland, raw",Vegetables and Vegetable Products,3.36,0.117,0.118,3,0.0014,0.567,3.02,0,0.56,91.4,24,0,0,0,30699
"Coleslaw, home-prepared",Vegetables and Vegetable Products,1.29,0.045,0.023,1.5,0.0327,0.181,12.41,0,2.61,81.5,78,0.385,0.708,1.351,30700
"Collards, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.11,0.14,0.252,2.8,0.0182,0.116,4.91,0.4,0.36,91.86,26,0.047,0.026,0.173,30701
"Collards, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.11,0.14,0.016,2.8,0.0182,0.116,4.91,0.4,0.36,91.86,26,0.047,0.026,0.173,30702
"Collards, frozen, chopped, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.97,0.21,0.286,2.8,0.0264,0.251,7.1,0.57,0.41,88.47,36,0.04,0,0,30703
"Collards, frozen, chopped, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.97,0.21,0.05,2.8,0.0264,0.251,7.1,0.57,0.41,88.47,36,0.06,0.02,0.21,30704
"Collards, frozen, chopped, unprepared",Vegetables and Vegetable Products,2.69,0.201,0.048,3.6,0.04,0.253,6.46,0,0.37,89.53,33,0.048,0.027,0.178,30705
"Collards, raw",Vegetables and Vegetable Products,2.45,0.145,0.02,3.6,0.0353,0.169,5.69,0.46,0.42,90.55,30,0.055,0.03,0.201,30706
"Coriander (cilantro) leaves, raw",Vegetables and Vegetable Products,2.13,0.067,0.046,2.8,0.027,0.521,3.67,0.87,0.52,92.21,23,0.014,0.275,0.04,30707
"Corn pudding, home prepared",Vegetables and Vegetable Products,4.42,0.039,0.282,1.2,0.0037,0.176,16.97,6.59,5.04,72.33,131,2.532,1.549,0.568,30708
"Corn with red and green peppers, canned, solids and liquids",Vegetables and Vegetable Products,2.33,0.005,0.347,0,0.0088,0.153,18.17,0,0.55,77.5,75,0.085,0.157,0.261,30709
"Corn, sweet, white, canned, cream style, no salt added",Vegetables and Vegetable Products,1.74,0.003,0.003,1.2,0.0046,0.134,18.13,2.21,0.42,78.73,72,0.065,0.123,0.198,30710
"Corn, sweet, white, canned, cream style, regular pack",Vegetables and Vegetable Products,1.74,0.003,0.285,1.2,0.0046,0.134,18.13,2.21,0.42,78.73,72,0.065,0.123,0.198,30711
"Corn, sweet, white, canned, vacuum pack, no salt added",Vegetables and Vegetable Products,2.41,0.005,0.003,2,0.0081,0.186,19.44,0,0.5,76.58,79,0.077,0.147,0.237,30712
"Corn, sweet, white, canned, vacuum pack, regular pack",Vegetables and Vegetable Products,2.41,0.005,0.272,2,0.0081,0.186,19.44,0,0.5,76.58,79,0.077,0.147,0.237,30713
"Corn, sweet, white, canned, whole kernel, drained solids",Vegetables and Vegetable Products,2.62,0.005,0.323,2,0.0085,0.195,18.59,2.4,1,76.92,81,0.154,0.292,0.471,30714
"Corn, sweet, white, canned, whole kernel, no salt added, solids and liquids",Vegetables and Vegetable Products,1.95,0.004,0.012,0.7,0.0055,0.164,15.41,0,0.5,81.34,64,0.077,0.144,0.233,30715
"Corn, sweet, white, canned, whole kernel, regular pack, solids and liquids",Vegetables and Vegetable Products,1.95,0.004,0.213,1.7,0.0055,0.164,15.41,0,0.5,81.34,64,0.077,0.144,0.233,30716
"Corn, sweet, white, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,3.34,0.002,0.253,2.7,0.0062,0.252,21.71,7.73,1.41,72.84,97,0.197,0.374,0.603,30717
"Corn, sweet, white, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,3.34,0.002,0.003,2.7,0.0062,0.252,21.71,7.73,1.41,72.84,97,0.197,0.374,0.603,30718
"Corn, sweet, white, frozen, kernels cut off cob, boiled, drained, with salt",Vegetables and Vegetable Products,2.75,0.004,0.245,2.4,0.0031,0.147,19.56,3.12,0.43,76.73,80,0.066,0.126,0.203,30719
"Corn, sweet, white, frozen, kernels cut off cob, boiled, drained, without salt",Vegetables and Vegetable Products,2.75,0.004,0.005,2.4,0.0031,0.147,19.56,3.12,0.43,76.73,80,0.066,0.126,0.203,30720
"Corn, sweet, white, frozen, kernels cut off cob, unprepared",Vegetables and Vegetable Products,3.02,0.004,0.003,2.4,0.0064,0.21,20.81,0,0.77,74.92,88,0.119,0.227,0.366,30721
"Corn, sweet, white, frozen, kernels on cob, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,3.11,0.003,0.24,2.8,0.0048,0.251,22.33,0,0.74,73.2,94,0.114,0.216,0.348,30722
"Corn, sweet, white, frozen, kernels on cob, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,3.11,0.003,0.004,2.1,0.0048,0.251,22.33,0,0.74,73.2,94,0.114,0.216,0.348,30723
"Corn, sweet, white, frozen, kernels on cob, unprepared",Vegetables and Vegetable Products,3.28,0.004,0.005,2.8,0.0072,0.294,23.5,0,0.78,71.79,98,0.12,0.228,0.367,30724
"Corn, sweet, white, raw",Vegetables and Vegetable Products,3.22,0.002,0.015,2.7,0.0068,0.27,19.02,3.22,1.18,75.96,86,0.182,0.347,0.559,30725
"Corn, sweet, yellow, canned, brine pack, regular pack, solids and liquids",Vegetables and Vegetable Products,1.95,0.004,0.213,1.7,0.0055,0.164,15.41,2.83,0.5,81.34,64,0.077,0.144,0.233,30726
"Corn, sweet, yellow, canned, cream style, no salt added",Vegetables and Vegetable Products,1.74,0.003,0.003,1.2,0.0046,0.134,18.13,3.23,0.42,78.73,72,0.065,0.123,0.198,30727
"Corn, sweet, yellow, canned, cream style, regular pack",Vegetables and Vegetable Products,1.74,0.003,0.285,1.2,0.0046,0.134,18.13,3.23,0.42,78.73,72,0.065,0.123,0.198,30728
"Corn, sweet, yellow, canned, no salt added, solids and liquids",Vegetables and Vegetable Products,1.95,0.004,0.012,1.7,0.0055,0.164,15.41,2.83,0.5,81.34,64,0.077,0.144,0.234,30729
"Corn, sweet, yellow, canned, vacuum pack, no salt added",Vegetables and Vegetable Products,2.41,0.005,0.003,2,0.0081,0.186,19.44,3.56,0.5,76.58,79,0.077,0.147,0.237,30730
"Corn, sweet, yellow, canned, vacuum pack, regular pack",Vegetables and Vegetable Products,2.41,0.005,0.272,2,0.0081,0.186,19.44,3.56,0.5,76.58,79,0.077,0.147,0.237,30731
"Corn, sweet, yellow, canned, whole kernel, drained solids",Vegetables and Vegetable Products,2.64,0.005,0.298,1.9,0.0007,0.135,18.8,3.04,0.93,76.71,81,0.173,0.239,0.464,30732
"Corn, sweet, yellow, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,3.41,0.003,0.253,2.4,0.0055,0.218,20.98,4.54,1.5,73.41,96,0.197,0.374,0.603,30733
"Corn, sweet, yellow, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,3.41,0.003,0.001,2.4,0.0055,0.218,20.98,4.54,1.5,73.41,96,0.197,0.374,0.603,30734
"Corn, sweet, yellow, frozen, kernels cut off cob, boiled, drained, without salt",Vegetables and Vegetable Products,2.55,0.003,0.001,2.4,0.0035,0.233,19.3,3.07,0.67,77.03,81,0.103,0.197,0.318,30735
"Corn, sweet, yellow, frozen, kernels cut off cob, unprepared",Vegetables and Vegetable Products,3.02,0.004,0.003,2.1,0.0064,0.213,20.71,2.5,0.78,75,88,0.119,0.227,0.366,30736
"Corn, sweet, yellow, frozen, kernels on cob, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,3.11,0.003,0.24,2.8,0.0048,0.251,22.33,3.59,0.74,73.2,94,0.114,0.216,0.348,30737
"Corn, sweet, yellow, frozen, kernels on cob, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,3.11,0.003,0.004,2.8,0.0048,0.251,22.33,3.59,0.74,73.2,94,0.114,0.216,0.348,30738
"Corn, sweet, yellow, frozen, kernels on cob, unprepared",Vegetables and Vegetable Products,3.28,0.004,0.005,2.8,0.0072,0.294,23.5,3.78,0.78,71.79,98,0.12,0.228,0.367,30739
"Corn, sweet, yellow, frozen, kernels, cut off cob, boiled, drained, with salt",Vegetables and Vegetable Products,2.55,0.003,0.245,2.4,0.0035,0.233,18.71,3.07,0.67,77.03,79,0.066,0.126,0.203,30740
"Corn, sweet, yellow, raw",Vegetables and Vegetable Products,3.27,0.002,0.015,2,0.0068,0.27,18.7,6.26,1.35,76.05,86,0.325,0.432,0.487,30741
"Corn, yellow, whole kernel, frozen, microwaved",Vegetables and Vegetable Products,3.62,0.005,0.004,2.6,0,0.276,25.87,3.36,1.42,68.4,131,0.15,0.287,0.463,30742
"Cornsalad, raw",Vegetables and Vegetable Products,2,0.038,0.004,0,0.038200000000000005,0.459,3.6,0,0.4,92.8,21,0,0,0,30743
"Cowpeas (Blackeyes), immature seeds, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,3.17,0.128,0.24,5,0.0022,0.418,19.73,3.23,0.38,75.48,94,0.096,0.034,0.161,30744
"Cowpeas (Blackeyes), immature seeds, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,3.17,0.128,0.004,5,0.0022,0.418,20.32,3.23,0.38,75.48,97,0.096,0.034,0.161,30745
"Cowpeas (Blackeyes), immature seeds, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,8.49,0.023,0.241,6.4,0.0026,0.375,23.5,4.46,0.66,66.1,131,0.175,0.06,0.28,30746
"Cowpeas (Blackeyes), immature seeds, frozen, unprepared",Vegetables and Vegetable Products,8.98,0.026,0.006,5,0.004,0.441,25.13,0,0.7,64.15,139,0.185,0.063,0.297,30747
"Cowpeas (blackeyes), immature seeds, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,8.49,0.023,0.005,6.4,0.0026,0.375,23.76,4.46,0.66,66.1,132,0.175,0.06,0.28,30748
"Cowpeas (blackeyes), immature seeds, raw",Vegetables and Vegetable Products,2.95,0.126,0.004,5,0.0025,0.431,18.83,3,0.35,77.2,90,0.09,0.032,0.15,30749
"Cowpeas, leafy tips, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,4.67,0.069,0.242,0,0.0184,0.351,2.8,0,0.1,91.3,22,0.026,0.009,0.042,30750
"Cowpeas, leafy tips, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,4.67,0.069,0.006,0,0.0184,0.351,2.8,0,0.1,91.3,22,0.026,0.009,0.042,30751
"Cowpeas, leafy tips, raw",Vegetables and Vegetable Products,4.1,0.063,0.007,0,0.036,0.455,4.82,0,0.25,89.78,29,0.066,0.022,0.106,30752
"Cowpeas, young pods with seeds, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.6,0.055,0.239,0,0.017,0.196,7,0,0.3,89.5,34,0.079,0.027,0.127,30753
"Cowpeas, young pods with seeds, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.6,0.055,0.003,0,0.017,0.196,7,0,0.3,89.5,34,0.079,0.027,0.127,30754
"Cowpeas, young pods with seeds, raw",Vegetables and Vegetable Products,3.3,0.065,0.004,0,0.033,0.215,9.5,0,0.3,86,44,0.079,0.027,0.127,30755
"Cress, garden, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.9,0.061,0.244,0.7,0.023,0.353,3.8,3.11,0.6,92.5,23,0.02,0.205,0.196,30756
"Cress, garden, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.9,0.061,0.008,0.7,0.023,0.353,3.8,3.11,0.6,92.5,23,0.02,0.205,0.196,30757
"Cress, garden, raw",Vegetables and Vegetable Products,2.6,0.081,0.014,1.1,0.069,0.606,5.5,4.4,0.7,89.4,32,0.023,0.239,0.228,30758
"Cucumber, peeled, raw",Vegetables and Vegetable Products,0.59,0.014,0.002,0.7,0.0032,0.136,2.16,1.38,0.16,96.73,12,0.013,0.002,0.003,30759
"Cucumber, with peel, raw",Vegetables and Vegetable Products,0.65,0.016,0.002,0.5,0.0028,0.147,3.63,1.67,0.11,95.23,15,0.037,0.005,0.032,30760
"Dandelion greens, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2,0.14,0.28,2.9,0.018,0.232,6.4,1.62,0.6,89.8,33,0,0,0,30761
"Dandelion greens, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2,0.14,0.044,2.9,0.018,0.232,6.4,0.5,0.6,89.8,33,0.146,0.012,0.262,30762
"Dandelion greens, raw",Vegetables and Vegetable Products,2.7,0.187,0.076,3.5,0.035,0.397,9.2,0.71,0.7,85.6,45,0.17,0.014,0.306,30763
"Dock, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.83,0.038,0.239,0,0.0263,0.321,2.93,0,0.64,93.6,20,0,0,0,30764
"Dock, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.83,0.038,0.003,2.6,0.0263,0.321,2.93,0,0.64,93.6,20,0,0,0,30765
"Dock, raw",Vegetables and Vegetable Products,2,0.044,0.004,2.9,0.048,0.39,3.2,0,0.7,93,22,0,0,0,30766
"Edamame, frozen, prepared",Vegetables and Vegetable Products,10.88,0.063,0.006,5.2,0.0060999999999999995,0.436,9.94,2.18,5.2,72.77,122,0.62,1.282,2.156,30767
"Edamame, frozen, unprepared",Vegetables and Vegetable Products,10.25,0.06,0.006,4.8,0.009699999999999999,0.482,8.58,2.48,4.73,75.17,110,0,0,0,30768
"Eggplant, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.83,0.006,0.239,2.5,0.0013,0.123,8.14,3.2,0.23,89.67,33,0.044,0.02,0.093,30769
"Eggplant, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.83,0.006,0.001,2.5,0.0013,0.123,8.73,3.2,0.23,89.67,35,0.044,0.02,0.093,30770
"Eggplant, pickled",Vegetables and Vegetable Products,0.9,0.025,1.674,2.5,0,0.012,9.77,4.8,0.7,86.9,49,0.14,0.063,0.294,30771
"Eggplant, raw",Vegetables and Vegetable Products,1.01,0.009,0.002,3.4,0.0022,0.23,5.7,2.35,0.19,92.41,24,0.034,0.016,0.076,30772
"Endive, raw",Vegetables and Vegetable Products,1.25,0.052,0.022,3.1,0.0065,0.314,3.35,0.25,0.2,93.79,17,0.048,0.004,0.087,30773
"Epazote, raw",Vegetables and Vegetable Products,0.33,0.275,0.043,3.8,0.0036,0.633,7.44,0,0.52,89.21,32,0,0,0,30774
"Eppaw, raw",Vegetables and Vegetable Products,4.6,0.11,0.012,0,0.013,0.34,31.68,0,1.8,60,150,0,0,0,30775
"Fennel, bulb, raw",Vegetables and Vegetable Products,1.24,0.049,0.052,3.1,0.012,0.414,7.29,0,0.2,90.21,31,0,0,0,30776
"Fiddlehead ferns, frozen, unprepared",Vegetables and Vegetable Products,4.31,0.024,0,0,0.0178,0.129,5.74,0,0.35,88.91,34,0,0,0,30777
"Fiddlehead ferns, raw",Vegetables and Vegetable Products,4.55,0.032,0.001,0,0.026600000000000002,0.37,5.54,0,0.4,88.68,34,0,0,0,30778
"Fireweed, leaves, raw",Vegetables and Vegetable Products,4.71,0.429,0.034,10.6,0.0022,0.494,19.22,0,2.75,70.78,103,0,0,0,30779
"Fungi, Cloud ears, dried",Vegetables and Vegetable Products,9.25,0.159,0.035,70.1,0,0.754,73.01,0,0.73,14.8,284,0,0,0,30780
"Garlic, raw",Vegetables and Vegetable Products,6.36,0.181,0.017,2.1,0.0312,0.401,33.06,1,0.5,58.58,149,0.089,0.011,0.249,30781
"Ginger root, raw",Vegetables and Vegetable Products,1.82,0.016,0.013,2,0.005,0.415,17.77,1.7,0.75,78.89,80,0.203,0.154,0.154,30782
"Gourd, dishcloth (towelgourd), cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.66,0.009,0.257,0,0.0057,0.453,13.75,0,0.34,84.29,54,0.027,0.063,0.148,30783
"Gourd, dishcloth (towelgourd), cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.66,0.009,0.021,0,0.0057,0.453,14.34,0,0.34,84.29,56,0.027,0.063,0.148,30784
"Gourd, dishcloth (towelgourd), raw",Vegetables and Vegetable Products,1.2,0.02,0.003,0,0.012,0.139,4.36,0,0.2,93.85,20,0.016,0.037,0.087,30785
"Gourd, white-flowered (calabash), cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.6,0.024,0.238,0,0.0085,0.17,3.1,0,0.02,95.32,13,0,0.004,0.009,30786
"Gourd, white-flowered (calabash), cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.6,0.024,0.002,0,0.0085,0.17,3.69,0,0.02,95.32,15,0.002,0.004,0.009,30787
"Gourd, white-flowered (calabash), raw",Vegetables and Vegetable Products,0.62,0.026,0.002,0,0.0101,0.15,3.39,0,0.02,95.54,14,0.002,0.004,0.009,30788
"Grape leaves, canned",Vegetables and Vegetable Products,4.27,0.289,2.853,0,0.011300000000000001,0.029,11.71,0,1.97,76.1,69,0.312,0.076,0.989,30789
"Grape leaves, raw",Vegetables and Vegetable Products,5.6,0.363,0.009,11,0.0111,0.272,17.31,6.3,2.12,73.32,93,0.336,0.081,1.065,30790
"Hearts of palm, canned",Vegetables and Vegetable Products,2.52,0.058,0.426,2.4,0.0079,0.177,4.62,0,0.62,90.2,28,0.13,0.103,0.202,30791
"Horseradish-tree leafy tips, raw",Vegetables and Vegetable Products,9.4,0.185,0.009,2,0.0517,0.337,8.28,0,1.4,78.66,64,0,0,0,30792
"Horseradish-tree, leafy tips, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,5.27,0.151,0.245,2,0.031,0.344,11.15,1,0.93,81.65,60,0,0,0,30793
"Horseradish-tree, leafy tips, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,5.27,0.151,0.009,2,0.031,0.344,11.15,1,0.93,81.65,60,0.152,0.473,0.015,30794
"Horseradish-tree, pods, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.09,0.02,0.279,4.2,0.097,0.457,8.18,0,0.19,88.42,36,0,0,0,30795
"Horseradish-tree, pods, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.09,0.02,0.043,4.2,0.097,0.457,8.18,0,0.19,88.42,36,0.031,0.097,0.003,30796
"Horseradish-tree, pods, raw",Vegetables and Vegetable Products,2.1,0.03,0.042,3.2,0.141,0.461,8.53,0,0.2,88.2,37,0.033,0.102,0.003,30797
"Hyacinth-beans, immature seeds, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.95,0.041,0.238,0,0.0050999999999999995,0.262,9.2,0,0.27,86.9,50,0.119,0.129,0.01,30798
"Hyacinth-beans, immature seeds, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.95,0.041,0.002,0,0.0050999999999999995,0.262,9.2,0,0.27,86.9,50,0.119,0.129,0.01,30799
"Hyacinth-beans, immature seeds, raw",Vegetables and Vegetable Products,2.1,0.05,0.002,0,0.0129,0.252,9.19,0,0.2,87.87,46,0.088,0.095,0.008,30800
"Jerusalem-artichokes, raw",Vegetables and Vegetable Products,2,0.014,0.004,1.6,0.004,0.429,17.44,9.6,0.01,78.01,73,0,0.004,0,30801
"Jew's ear, (pepeao), raw",Vegetables and Vegetable Products,0.48,0.016,0.009,0,0.0006,0.043,6.75,0,0.04,92.59,25,0,0,0,30802
"Jute, potherb, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,3.68,0.211,0.247,2,0.033,0.55,7.29,1,0.2,87.15,37,0.03,0.014,0.096,30803
"Jute, potherb, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,3.68,0.211,0.011,2,0.033,0.55,7.29,1,0.2,87.15,37,0.03,0.014,0.096,30804
"Jute, potherb, raw",Vegetables and Vegetable Products,4.65,0.208,0.008,0,0.037,0.559,5.8,0,0.25,87.72,34,0.038,0.017,0.12,30805
"Kale, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.9,0.072,0.259,2,0.041,0.228,5.63,1.25,0.4,91.2,28,0.052,0.03,0.193,30806
"Kale, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.9,0.072,0.023,2,0.041,0.228,5.63,1.25,0.4,91.2,28,0.052,0.03,0.193,30807
"Kale, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.84,0.138,0.251,2,0.0252,0.321,5.23,1.34,0.49,90.5,30,0.063,0.036,0.235,30808
"Kale, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.84,0.138,0.015,2,0.0252,0.321,5.23,1.34,0.49,90.5,30,0.063,0.036,0.235,30809
"Kale, frozen, unprepared",Vegetables and Vegetable Products,2.66,0.136,0.015,2,0.039299999999999995,0.333,4.9,0,0.46,91.12,28,0.059,0.034,0.219,30810
"Kale, raw",Vegetables and Vegetable Products,3.3,0.135,0.043,2,0.12,0.447,10.01,0,0.7,84.46,50,0.091,0.052,0.338,30811
"Kale, scotch, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.9,0.132,0.281,0,0.0528,0.274,5.62,0,0.41,91.2,28,0.053,0.03,0.196,30812
"Kale, scotch, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.9,0.132,0.045,1.2,0.0528,0.274,5.63,0,0.41,91.2,28,0.053,0.03,0.196,30813
"Kale, scotch, raw",Vegetables and Vegetable Products,2.8,0.205,0.07,1.7,0.13,0.45,8.32,0,0.6,87,42,0.078,0.045,0.289,30814
"Kanpyo, (dried gourd strips)",Vegetables and Vegetable Products,8.58,0.28,0.015,0,0.0002,1.582,65.04,0,0.56,19.97,258,0.045,0.103,0.244,30815
"Kohlrabi, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.8,0.025,0.257,1.1,0.054,0.34,6.69,2.8,0.11,90.3,29,0.014,0.008,0.053,30816
"Kohlrabi, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.8,0.025,0.021,1.1,0.054,0.34,6.69,2.8,0.11,90.3,29,0.014,0.008,0.053,30817
"Kohlrabi, raw",Vegetables and Vegetable Products,1.7,0.024,0.02,3.6,0.062,0.35,6.2,2.6,0.1,91,27,0.013,0.007,0.048,30818
"Lambs quarters, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,3.2,0.258,0.265,2.1,0.037,0.288,5,0.62,0.7,88.9,32,0.052,0.131,0.307,30819
"Lambsquarters, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,3.2,0.258,0.029,2.1,0.037,0.288,5,0.62,0.7,88.9,32,0.052,0.131,0.307,30820
"Lambsquarters, raw",Vegetables and Vegetable Products,4.2,0.309,0.043,4,0.08,0.452,7.3,0,0.8,84.3,43,0.059,0.15,0.351,30821
"Leeks, (bulb and lower leaf-portion), cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.81,0.03,0.246,1,0.004200000000000001,0.087,7.62,2.11,0.2,90.8,31,0.027,0.003,0.111,30822
"Leeks, (bulb and lower leaf-portion), cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.81,0.03,0.01,1,0.004200000000000001,0.087,7.62,2.11,0.2,90.8,31,0.027,0.003,0.111,30823
"Leeks, (bulb and lower leaf-portion), raw",Vegetables and Vegetable Products,1.5,0.059,0.02,1.8,0.012,0.18,14.15,3.9,0.3,83,61,0.04,0.004,0.166,30824
"Leeks, (bulb and lower-leaf portion), freeze-dried",Vegetables and Vegetable Products,15.2,0.36,0.035,10.4,0.118,2.4,74.65,0,2.1,2,321,0.279,0.029,1.161,30825
"Lemon grass (citronella), raw",Vegetables and Vegetable Products,1.82,0.065,0.006,0,0.0026,0.723,25.31,0,0.49,70.58,99,0.119,0.054,0.17,30826
"Lentils, sprouted, cooked, stir-fried, with salt",Vegetables and Vegetable Products,8.8,0.014,0.246,0,0.0126,0.284,21.25,0,0.45,68.7,101,0.053,0.095,0.201,30827
"Lentils, sprouted, cooked, stir-fried, without salt",Vegetables and Vegetable Products,8.8,0.014,0.01,0,0.0126,0.284,21.25,0,0.45,68.7,101,0.053,0.095,0.201,30828
"Lentils, sprouted, raw",Vegetables and Vegetable Products,8.96,0.025,0.011,0,0.0165,0.322,22.14,0,0.55,67.34,106,0.057,0.104,0.219,30829
"Lettuce, butterhead (includes boston and bibb types), raw",Vegetables and Vegetable Products,1.35,0.035,0.005,1.1,0.0037,0.238,2.23,0.94,0.22,95.63,13,0.029,0.008,0.117,30830
"Lettuce, cos or romaine, raw",Vegetables and Vegetable Products,1.23,0.033,0.008,2.1,0.004,0.247,3.29,1.19,0.3,94.61,17,0.039,0.012,0.16,30831
"Lettuce, green leaf, raw",Vegetables and Vegetable Products,1.36,0.036,0.028,1.3,0.0092,0.194,2.87,0.78,0.15,94.98,15,0.02,0.006,0.082,30832
"Lettuce, iceberg (includes crisphead types), raw",Vegetables and Vegetable Products,0.9,0.018,0.01,1.2,0.0028,0.141,2.97,1.97,0.14,95.64,14,0.018,0.006,0.074,30833
"Lettuce, red leaf, raw",Vegetables and Vegetable Products,1.33,0.033,0.025,0.9,0.0037,0.187,2.26,0.48,0.22,95.64,16,0,0,0,30834
"Lima beans, immature seeds, canned, no salt added, solids and liquids",Vegetables and Vegetable Products,4.07,0.028,0.004,3.6,0.0087,0.285,13.33,0.93,0.29,81.17,71,0.066,0.016,0.139,30835
"Lima beans, immature seeds, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,6.81,0.032,0.253,5.3,0.0101,0.57,23.64,1.63,0.32,67.17,123,0.073,0.019,0.154,30836
"Lima beans, immature seeds, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,6.81,0.032,0.017,5.3,0.0101,0.57,23.64,1.63,0.32,67.17,123,0.073,0.019,0.154,30837
"Lima beans, immature seeds, frozen, baby, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,6.65,0.028,0.265,6,0.0058,0.411,19.45,1.37,0.3,72.35,105,0.068,0.017,0.145,30838
"Lima beans, immature seeds, frozen, baby, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,6.65,0.028,0.029,6,0.0058,0.411,19.45,1.37,0.3,72.35,105,0.068,0.017,0.145,30839
"Lima beans, immature seeds, frozen, baby, unprepared",Vegetables and Vegetable Products,7.59,0.035,0.052,6,0.0083,0.452,25.14,0,0.44,65.46,132,0.102,0.026,0.216,30840
"Lima beans, immature seeds, frozen, fordhook, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,6.07,0.03,0.289,5.8,0.0128,0.304,19.32,1.34,0.34,72.98,103,0.077,0.02,0.163,30841
"Lima beans, immature seeds, frozen, fordhook, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,6.07,0.03,0.069,5.8,0.0128,0.304,19.32,1.34,0.34,72.98,103,0.078,0.02,0.167,30842
"Lima beans, immature seeds, frozen, fordhook, unprepared",Vegetables and Vegetable Products,6.4,0.024,0.058,5.5,0.0193,0.478,19.83,1.39,0.35,72.05,106,0.081,0.021,0.172,30843
"Lima beans, immature seeds, raw",Vegetables and Vegetable Products,6.84,0.034,0.008,4.9,0.023399999999999997,0.467,20.17,1.48,0.86,70.24,113,0.198,0.05,0.419,30844
"Lotus root, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.58,0.026,0.281,3.1,0.027399999999999997,0.363,16.02,0,0.07,81.42,66,0.021,0.014,0.014,30845
"Lotus root, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.58,0.026,0.045,3.1,0.027399999999999997,0.363,16.02,0.5,0.07,81.42,66,0.021,0.014,0.014,30846
"Lotus root, raw",Vegetables and Vegetable Products,2.6,0.045,0.04,4.9,0.044,0.556,17.23,0,0.1,79.1,74,0.03,0.02,0.02,30847
"Malabar spinach, cooked",Vegetables and Vegetable Products,2.98,0.124,0.055,2.1,0.005900000000000001,0.256,2.71,0,0.78,92.5,23,0,0,0,30848
"Mountain yam, hawaii, cooked, steamed, with salt",Vegetables and Vegetable Products,1.73,0.008,0.248,0,0,0.495,19.99,0,0.08,77.14,82,0.018,0.003,0.036,30849
"Mountain yam, hawaii, cooked, steamed, without salt",Vegetables and Vegetable Products,1.73,0.008,0.012,0,0,0.495,20,0,0.08,77.14,82,0.018,0.003,0.036,30850
"Mountain yam, hawaii, raw",Vegetables and Vegetable Products,1.34,0.026,0.013,0,0.0026,0.418,16.31,0,0.1,81.44,67,0.022,0.004,0.045,30851
"Mung beans, mature seeds, sprouted, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.03,0.012,0.246,0.8,0.0114,0.101,3.6,2.84,0.09,93.39,19,0.025,0.012,0.032,30852
"Mung beans, mature seeds, sprouted, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.03,0.012,0.01,0.8,0.0114,0.101,4.19,2.84,0.09,93.39,21,0.025,0.012,0.032,30853
"Mung beans, mature seeds, sprouted, cooked, stir-fried",Vegetables and Vegetable Products,4.3,0.013,0.009,1.9,0.016,0.219,10.59,0,0.21,84.3,50,0.039,0.056,0.068,30854
"Mung beans, mature seeds, sprouted, raw",Vegetables and Vegetable Products,3.04,0.013,0.006,1.8,0.0132,0.149,5.94,4.13,0.18,90.4,30,0.046,0.022,0.058,30855
"Mushrooms, Chanterelle, raw",Vegetables and Vegetable Products,1.49,0.015,0.009,3.8,0,0.506,6.86,1.16,0.53,89.85,38,0,0,0,30856
"Mushrooms, brown, Italian, or Crimini, raw",Vegetables and Vegetable Products,2.5,0.018,0.006,0.6,0,0.448,4.3,1.72,0.1,92.12,22,0.014,0.002,0.042,30857
"Mushrooms, canned, drained solids",Vegetables and Vegetable Products,1.87,0.011,0.425,2.4,0,0.129,5.09,2.34,0.29,91.08,25,0.038,0.005,0.113,30858
"Mushrooms, enoki, raw",Vegetables and Vegetable Products,2.66,0,0.003,2.7,0,0.359,7.81,0.22,0.29,88.34,37,0.02,0,0.09,30859
"Mushrooms, maitake, raw",Vegetables and Vegetable Products,1.94,0.001,0.001,2.7,0,0.204,6.97,2.07,0.19,90.37,31,0.03,0.03,0.09,30860
"Mushrooms, morel, raw",Vegetables and Vegetable Products,3.12,0.043,0.021,2.8,0,0.411,5.1,0.6,0.57,89.61,31,0.065,0.052,0.433,30861
"Mushrooms, oyster, raw",Vegetables and Vegetable Products,3.31,0.003,0.018,2.3,0,0.42,6.09,1.11,0.41,89.18,33,0.062,0.031,0.123,30862
"Mushrooms, portabella, exposed to ultraviolet light, grilled",Vegetables and Vegetable Products,3.28,0.003,0.011,2.2,0,0.437,4.44,2.26,0.58,90.66,29,0.079,0.011,0.287,30863
"Mushrooms, portabella, exposed to ultraviolet light, raw",Vegetables and Vegetable Products,2.11,0.003,0.009,1.3,0,0.364,3.87,2.5,0.35,92.82,22,0.06,0.02,0.117,30864
"Mushrooms, portabella, grilled",Vegetables and Vegetable Products,3.28,0.003,0.011,2.2,0,0.437,4.44,2.26,0.58,90.66,29,0.079,0.011,0.287,30865
"Mushrooms, portabella, raw",Vegetables and Vegetable Products,2.11,0.003,0.009,1.3,0,0.364,3.87,2.5,0.35,92.82,22,0.06,0.02,0.117,30866
"Mushrooms, shiitake, cooked, with salt",Vegetables and Vegetable Products,1.56,0.003,0.24,2.1,0.0003,0.117,14.39,3.84,0.22,83.48,56,0.05,0.07,0.034,30867
"Mushrooms, shiitake, cooked, without salt",Vegetables and Vegetable Products,1.56,0.003,0.004,2.1,0.0003,0.117,14.39,3.84,0.22,83.48,56,0.05,0.07,0.034,30868
"Mushrooms, shiitake, dried",Vegetables and Vegetable Products,9.58,0.011,0.013,11.5,0.0035,1.534,75.37,2.21,0.99,9.5,296,0.225,0.317,0.151,30869
"Mushrooms, shiitake, raw",Vegetables and Vegetable Products,2.24,0.002,0.009,2.5,0,0.304,6.79,2.38,0.49,89.74,34,0,0,0,30870
"Mushrooms, shiitake, stir-fried",Vegetables and Vegetable Products,3.45,0.002,0.005,3.6,0,0.326,7.68,0.3,0.35,87.74,39,0.03,0,0.14,30871
"Mushrooms, straw, canned, drained solids",Vegetables and Vegetable Products,3.83,0.01,0.384,2.5,0,0.078,4.64,0,0.68,89.88,32,0.089,0.012,0.263,30872
"Mushrooms, white, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.17,0.006,0.238,2.2,0.004,0.356,5.29,2.34,0.47,91.08,28,0.061,0.008,0.183,30873
"Mushrooms, white, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.17,0.006,0.002,2.2,0.004,0.356,5.29,2.34,0.47,91.08,28,0.061,0.008,0.183,30874
"Mushrooms, white, microwaved",Vegetables and Vegetable Products,3.91,0.006,0.017,2.5,0,0.488,6.04,0,0.46,88.52,35,0.06,0.02,0.28,30875
"Mushrooms, white, raw",Vegetables and Vegetable Products,3.09,0.003,0.005,1,0.0021000000000000003,0.318,3.26,1.98,0.34,92.45,22,0.05,0,0.16,30876
"Mushrooms, white, stir-fried",Vegetables and Vegetable Products,3.58,0.004,0.012,1.8,0,0.396,4.04,0,0.33,91.1,26,0.04,0,0.16,30877
"Mustard greens, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.26,0.074,0.252,2,0.0253,0.202,2.1,0.1,0.24,94.46,15,0.012,0.11,0.046,30878
"Mustard greens, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.26,0.074,0.016,2,0.0253,0.202,2.1,0.1,0.24,94.46,15,0.012,0.11,0.046,30879
"Mustard greens, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.27,0.101,0.261,2.8,0.013800000000000002,0.139,3.11,0.32,0.25,93.8,19,0.013,0.113,0.047,30880
"Mustard greens, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.27,0.101,0.025,2.8,0.013800000000000002,0.139,3.11,0.32,0.25,93.8,19,0.013,0.113,0.047,30881
"Mustard greens, frozen, unprepared",Vegetables and Vegetable Products,2.49,0.116,0.029,3.3,0.0253,0.17,3.41,0,0.27,93.21,20,0.014,0.124,0.051,30882
"Mustard greens, raw",Vegetables and Vegetable Products,2.7,0.103,0.025,3.3,0.07,0.354,4.9,1.6,0.2,90.8,26,0.01,0.092,0.038,30883
"Mustard spinach, (tendergreen), cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.7,0.158,0.25,2,0.065,0.285,2.8,0,0.2,94.5,16,0,0,0,30884
"Mustard spinach, (tendergreen), cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.7,0.158,0.014,2,0.065,0.285,2.8,0,0.2,94.5,16,0,0,0,30885
"Mustard spinach, (tendergreen), raw",Vegetables and Vegetable Products,2.2,0.21,0.021,2.8,0.13,0.449,3.9,0,0.3,92.2,22,0.015,0.138,0.057,30886
"New Zealand spinach, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.3,0.048,0.107,0,0.016,0.102,2.2,0,0.17,94.8,12,0.027,0.005,0.071,30887
"New Zealand spinach, raw",Vegetables and Vegetable Products,1.5,0.058,0.13,0,0.03,0.13,2.5,0,0.2,94,14,0.032,0.005,0.084,30888
"New zealand spinach, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.3,0.048,0.343,0,0.016,0.102,2.13,0,0.17,94.8,12,0.027,0.005,0.071,30889
"Nopales, cooked, without salt",Vegetables and Vegetable Products,1.35,0.164,0.02,2,0.0053,0.195,3.28,1.12,0.05,94.31,15,0.006,0.007,0.021,30890
"Nopales, raw",Vegetables and Vegetable Products,1.32,0.164,0.021,2.2,0.009300000000000001,0.257,3.33,1.15,0.09,94.12,16,0.016,0.018,0.05,30891
"Okra, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.87,0.077,0.241,2.5,0.016300000000000002,0.135,4.51,2.4,0.21,92.57,22,0.045,0.028,0.046,30892
"Okra, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.87,0.077,0.006,2.5,0.016300000000000002,0.135,4.51,2.4,0.21,92.57,22,0.045,0.028,0.046,30893
"Okra, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.63,0.074,0.239,2.1,0.0096,0.184,6.41,2.87,0.24,91.12,34,0.063,0.04,0.064,30894
"Okra, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.63,0.074,0.003,2.1,0.0096,0.184,6.41,2.87,0.24,91.12,29,0.063,0.04,0.064,30895
"Okra, frozen, unprepared",Vegetables and Vegetable Products,1.69,0.081,0.003,2.2,0.0124,0.211,6.63,2.97,0.25,90.82,30,0.065,0.041,0.066,30896
"Okra, raw",Vegetables and Vegetable Products,2,0.081,0.008,3.2,0.0211,0.303,7.03,1.2,0.1,90.17,31,0.026,0.017,0.027,30897
"Onion rings, breaded, par fried, frozen, prepared, heated in oven",Vegetables and Vegetable Products,5.34,0.031,0.375,1.3,0.0014,0.129,38.16,0,26.7,28.5,407,8.585,10.866,5.113,30898
"Onion rings, breaded, par fried, frozen, unprepared",Vegetables and Vegetable Products,3.15,0.046,0.246,1.8,0.0046,0.19,30.53,0,14.1,51.22,258,4.534,5.738,2.7,30899
"Onions, canned, solids and liquids",Vegetables and Vegetable Products,0.85,0.045,0.371,1.2,0.0043,0.111,4.02,2.2,0.09,94.1,19,0.016,0.013,0.036,30900
"Onions, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.36,0.022,0.239,1.4,0.0052,0.166,9.56,4.73,0.19,87.86,42,0.031,0.027,0.073,30901
"Onions, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.36,0.022,0.003,1.4,0.0052,0.166,10.15,4.73,0.19,87.86,44,0.031,0.027,0.073,30902
"Onions, dehydrated flakes",Vegetables and Vegetable Products,8.95,0.257,0.021,9.2,0.075,1.622,83.28,37.41,0.46,3.93,349,0.078,0.064,0.18,30903
"Onions, frozen, chopped, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.77,0.016,0.248,1.7,0.0026,0.108,6,2.9,0.1,92.24,26,0.016,0.013,0.038,30904
"Onions, frozen, chopped, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.77,0.016,0.012,1.8,0.0026,0.108,6.59,2.9,0.1,92.24,28,0.016,0.013,0.038,30905
"Onions, frozen, chopped, unprepared",Vegetables and Vegetable Products,0.79,0.017,0.012,1.8,0.0033,0.124,6.81,0,0.1,91.99,29,0.017,0.014,0.039,30906
"Onions, frozen, whole, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.71,0.027,0.244,1.4,0.0050999999999999995,0.101,6.11,2.9,0.05,92.24,26,0.009,0.007,0.02,30907
"Onions, frozen, whole, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.71,0.027,0.008,1.4,0.0050999999999999995,0.101,6.7,2.9,0.05,92.24,28,0.009,0.007,0.02,30908
"Onions, frozen, whole, unprepared",Vegetables and Vegetable Products,0.89,0.036,0.01,1.7,0.008,0.142,8.45,3.81,0.06,90.22,35,0.011,0.009,0.025,30909
"Onions, raw",Vegetables and Vegetable Products,1.1,0.023,0.004,1.7,0.0074,0.146,9.34,4.24,0.1,89.11,40,0.042,0.013,0.017,30910
"Onions, spring or scallions (includes tops and bulb), raw",Vegetables and Vegetable Products,1.83,0.072,0.016,2.6,0.0188,0.276,7.34,2.33,0.19,89.83,32,0.032,0.027,0.074,30911
"Onions, sweet, raw",Vegetables and Vegetable Products,0.8,0.02,0.008,0.9,0.0048,0.119,7.55,5.02,0.08,91.24,32,0,0,0,30912
"Onions, welsh, raw",Vegetables and Vegetable Products,1.9,0.018,0.017,0,0.027,0.212,6.5,0,0.4,90.5,34,0.067,0.056,0.156,30913
"Onions, yellow, sauteed",Vegetables and Vegetable Products,0.95,0.02,0.012,1.7,0.0018,0.133,7.86,0,10.8,80.01,132,1.477,2.185,5.47,30914
"Onions, young green, tops only",Vegetables and Vegetable Products,0.97,0.052,0.015,1.8,0.0134,0.159,5.74,4.95,0.47,92.32,27,0.087,0.022,0.064,30915
"Palm hearts, raw",Vegetables and Vegetable Products,2.7,0.018,0.014,1.5,0.008,1.806,25.61,17.16,0.2,69.5,115,0.046,0.005,0.089,30916
"Parsley, freeze-dried",Vegetables and Vegetable Products,31.3,0.176,0.391,32.7,0.149,6.3,42.38,0,5.2,2,271,0,0,0,30917
"Parsley, raw",Vegetables and Vegetable Products,2.97,0.138,0.056,3.3,0.133,0.554,6.33,0.85,0.79,87.71,36,0.132,0.295,0.124,30918
"Parsnips, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.32,0.037,0.246,4,0.013,0.367,17.01,4.8,0.3,80.24,71,0.05,0.112,0.047,30919
"Parsnips, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.32,0.037,0.01,3.6,0.013,0.367,17.01,4.8,0.3,80.24,71,0.05,0.112,0.047,30920
"Parsnips, raw",Vegetables and Vegetable Products,1.2,0.036,0.01,4.9,0.017,0.375,17.99,4.8,0.3,79.53,75,0.05,0.112,0.047,30921
"Peas and carrots, canned, no salt added, solids and liquids",Vegetables and Vegetable Products,2.17,0.023,0.004,3.3,0.0066,0.1,8.48,2.83,0.27,88.15,38,0.049,0.023,0.129,30922
"Peas and carrots, canned, regular pack, solids and liquids",Vegetables and Vegetable Products,2.17,0.023,0.26,2,0.0066,0.1,8.48,0,0.27,88.15,38,0.049,0.023,0.129,30923
"Peas and carrots, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,3.09,0.023,0.304,3.1,0.0081,0.158,10.12,4.36,0.42,85.8,48,0.077,0.035,0.201,30924
"Peas and carrots, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,3.09,0.023,0.068,3.1,0.0081,0.158,10.12,4.36,0.42,85.8,48,0.077,0.035,0.201,30925
"Peas and carrots, frozen, unprepared",Vegetables and Vegetable Products,3.4,0.027,0.079,3.4,0.0112,0.194,11.15,0,0.47,84.36,53,0.084,0.039,0.221,30926
"Peas and onions, canned, solids and liquids",Vegetables and Vegetable Products,3.28,0.017,0.442,2.3,0.003,0.096,8.57,0,0.38,86.4,51,0.068,0.036,0.176,30927
"Peas and onions, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.54,0.014,0.273,2.2,0.006900000000000001,0.117,8.63,3.77,0.2,88.18,45,0.036,0.019,0.094,30928
"Peas and onions, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.54,0.014,0.037,2.2,0.006900000000000001,0.117,8.63,3.77,0.2,88.18,45,0.036,0.019,0.094,30929
"Peas and onions, frozen, unprepared",Vegetables and Vegetable Products,3.98,0.023,0.061,3.5,0.014,0.203,13.51,0,0.32,81.5,70,0.057,0.031,0.148,30930
"Peas, edible-podded, boiled, drained, without salt",Vegetables and Vegetable Products,3.27,0.042,0.004,2.8,0.0479,0.24,7.05,3.99,0.23,88.91,42,0.044,0.023,0.1,30931
"Peas, edible-podded, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,3.27,0.042,0.24,2.8,0.0479,0.24,6.46,3.99,0.23,88.91,40,0.044,0.023,0.1,30932
"Peas, edible-podded, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,3.5,0.059,0.241,3.1,0.022,0.217,8.43,4.82,0.38,86.6,50,0.073,0.039,0.167,30933
"Peas, edible-podded, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,3.5,0.059,0.005,3.1,0.022,0.217,9.02,4.82,0.38,86.6,52,0.073,0.039,0.167,30934
"Peas, edible-podded, frozen, unprepared",Vegetables and Vegetable Products,2.8,0.05,0.004,3.1,0.022,0.192,7.2,0,0.3,89.3,42,0.058,0.031,0.133,30935
"Peas, edible-podded, raw",Vegetables and Vegetable Products,2.8,0.043,0.004,2.6,0.06,0.2,7.55,4,0.2,88.89,42,0.039,0.021,0.089,30936
"Peas, green (includes baby and lesuer types), canned, drained solids, unprepared",Vegetables and Vegetable Products,4.47,0.023,0.291,4.9,0.0096,0.103,11.38,2.99,0.62,82.4,69,0.062,0.031,0.163,30937
"Peas, green, canned, no salt added, drained solids",Vegetables and Vegetable Products,4.42,0.02,0.002,4.1,0.0096,0.173,12.58,4.16,0.35,81.7,69,0.062,0.031,0.163,30938
"Peas, green, canned, no salt added, solids and liquids",Vegetables and Vegetable Products,3.19,0.018,0.009,3.3,0.009800000000000001,0.1,9.75,3.2,0.3,85.92,53,0.054,0.027,0.142,30939
"Peas, green, canned, regular pack, solids and liquids",Vegetables and Vegetable Products,3.19,0.018,0.25,3.3,0.009800000000000001,0.1,9.75,3.2,0.3,85.92,53,0.05,0.025,0.132,30940
"Peas, green, canned, seasoned, solids and liquids",Vegetables and Vegetable Products,3.09,0.015,0.254,2,0.0115,0.122,9.25,0,0.27,86.51,50,0.048,0.024,0.127,30941
"Peas, green, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,5.36,0.027,0.239,5.5,0.014199999999999999,0.271,15.63,5.93,0.22,77.87,84,0.039,0.019,0.102,30942
"Peas, green, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,5.36,0.027,0.003,5.5,0.014199999999999999,0.271,15.63,5.93,0.22,77.87,84,0.039,0.019,0.102,30943
"Peas, green, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,5.15,0.024,0.323,5.5,0.0099,0.11,14.26,4.65,0.27,79.52,78,0.049,0.024,0.129,30944
"Peas, green, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,5.15,0.024,0.072,5.5,0.0099,0.11,14.26,4.65,0.27,79.52,78,0.049,0.024,0.129,30945
"Peas, green, frozen, unprepared",Vegetables and Vegetable Products,5.22,0.022,0.108,4.5,0.018,0.153,13.62,5,0.4,79.98,77,0.066,0.033,0.175,30946
"Peas, green, raw",Vegetables and Vegetable Products,5.42,0.025,0.005,5.1,0.04,0.244,14.45,5.67,0.4,78.86,81,0.071,0.035,0.187,30947
"Peas, mature seeds, sprouted, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,7.05,0.026,0.239,0,0.0066,0.268,17.08,0,0.51,74.37,98,0.09,0.045,0.24,30948
"Peas, mature seeds, sprouted, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,7.05,0.026,0.003,0,0.0066,0.268,17.08,0,0.51,74.37,98,0.09,0.045,0.24,30949
"Peas, mature seeds, sprouted, raw",Vegetables and Vegetable Products,8.8,0.036,0.02,0,0.0104,0.381,27.11,0,0.68,62.27,124,0.124,0.061,0.326,30950
"Pepeao, dried",Vegetables and Vegetable Products,4.82,0.113,0.07,0,0.0014,0.708,81.03,0,0.44,11.14,298,0,0,0,30951
"Pepper, ancho, dried",Vegetables and Vegetable Products,11.86,0.061,0.043,21.6,0.002,2.411,51.41,0,8.2,22.63,281,0.82,0.492,4.511,30952
"Pepper, banana, raw",Vegetables and Vegetable Products,1.66,0.014,0.013,3.4,0.08270000000000001,0.256,5.35,1.95,0.45,91.81,27,0.048,0.027,0.243,30953
"Pepper, serrano, raw",Vegetables and Vegetable Products,1.74,0.011,0.01,3.7,0.044899999999999995,0.305,6.7,3.83,0.44,90.25,32,0.059,0.023,0.222,30954
"Peppers, chili, green, canned",Vegetables and Vegetable Products,0.72,0.036,0.397,1.7,0.0342,0.113,4.6,0,0.27,93.25,21,0.028,0.017,0.153,30955
"Peppers, hot chile, sun-dried",Vegetables and Vegetable Products,10.58,0.045,0.091,28.7,0.0314,1.87,69.86,41.06,5.81,7.15,324,0.813,0.468,3.079,30956
"Peppers, hot chili, green, canned, pods, excluding seeds, solids and liquids",Vegetables and Vegetable Products,0.9,0.007,1.173,1.3,0.068,0.187,5.1,3.12,0.1,92.5,21,0.01,0.006,0.055,30957
"Peppers, hot chili, green, raw",Vegetables and Vegetable Products,2,0.018,0.007,1.5,0.2425,0.34,9.46,5.1,0.2,87.74,40,0.021,0.011,0.109,30958
"Peppers, hot chili, red, canned, excluding seeds, solids and liquids",Vegetables and Vegetable Products,0.9,0.007,1.173,1.3,0.068,0.187,5.1,3.32,0.1,92.5,21,0.01,0.006,0.055,30959
"Peppers, hot chili, red, raw",Vegetables and Vegetable Products,1.87,0.014,0.009,1.5,0.1437,0.322,8.81,5.3,0.44,88.02,40,0.042,0.024,0.239,30960
"Peppers, hungarian, raw",Vegetables and Vegetable Products,0.8,0.012,0.001,0,0.09290000000000001,0.202,6.68,0,0.41,91.51,29,0.046,0.024,0.24,30961
"Peppers, jalapeno, canned, solids and liquids",Vegetables and Vegetable Products,0.92,0.023,1.671,2.6,0.01,0.193,4.74,2.14,0.94,88.89,27,0.097,0.053,0.514,30962
"Peppers, jalapeno, raw",Vegetables and Vegetable Products,0.91,0.012,0.003,2.8,0.1186,0.248,6.5,4.12,0.37,91.69,29,0.092,0.029,0.112,30963
"Peppers, pasilla, dried",Vegetables and Vegetable Products,12.35,0.097,0.089,26.8,0.0064,2.222,51.13,0,15.85,14.84,345,0,0,0,30964
"Peppers, sweet, green, canned, solids and liquids",Vegetables and Vegetable Products,0.8,0.041,1.369,1.2,0.0465,0.146,3.9,0,0.3,91.25,18,0.045,0.02,0.161,30965
"Peppers, sweet, green, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.92,0.009,0.238,1.2,0.07440000000000001,0.166,6.11,3.19,0.2,91.87,26,0.029,0.013,0.106,30966
"Peppers, sweet, green, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.92,0.009,0.002,1.2,0.07440000000000001,0.166,6.7,3.19,0.2,91.87,28,0.029,0.013,0.106,30967
"Peppers, sweet, green, freeze-dried",Vegetables and Vegetable Products,17.9,0.134,0.193,21.3,1.9,3.17,68.7,38.48,3,2,314,0.447,0.202,1.607,30968
"Peppers, sweet, green, frozen, chopped, boiled, drained, without salt",Vegetables and Vegetable Products,0.95,0.008,0.004,0.9,0.0412,0.072,3.9,0,0.18,94.7,18,0.027,0.012,0.099,30969
"Peppers, sweet, green, frozen, chopped, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.95,0.008,0.24,0,0.0412,0.072,3.31,0,0.18,94.7,16,0.027,0.012,0.099,30970
"Peppers, sweet, green, frozen, chopped, unprepared",Vegetables and Vegetable Products,1.08,0.009,0.005,1.6,0.0587,0.091,4.45,0,0.21,93.96,20,0.031,0.014,0.113,30971
"Peppers, sweet, green, raw",Vegetables and Vegetable Products,0.86,0.01,0.003,1.7,0.0804,0.175,4.64,2.4,0.17,93.89,20,0.058,0.008,0.062,30972
"Peppers, sweet, green, sauteed",Vegetables and Vegetable Products,0.78,0.008,0.017,1.8,0.177,0.134,4.22,2.17,11.85,82.65,127,1.59,2.337,5.9,30973
"Peppers, sweet, red, canned, solids and liquids",Vegetables and Vegetable Products,0.8,0.041,1.369,1.2,0.0465,0.146,3.9,0,0.3,91.25,18,0.045,0.02,0.161,30974
"Peppers, sweet, red, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.92,0.009,0.238,1.2,0.171,0.166,6.11,4.39,0.2,91.87,26,0.029,0.013,0.106,30975
"Peppers, sweet, red, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.92,0.009,0.002,1.2,0.171,0.166,6.7,4.39,0.2,91.87,28,0.029,0.013,0.106,30976
"Peppers, sweet, red, freeze-dried",Vegetables and Vegetable Products,17.9,0.134,0.193,21.3,1.9,3.17,68.7,40.77,3,2,314,0.447,0.202,1.607,30977
"Peppers, sweet, red, frozen, chopped, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.95,0.008,0.24,0,0.0412,0.072,3.31,2.86,0.18,94.7,16,0.027,0.012,0.099,30978
"Peppers, sweet, red, frozen, chopped, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.95,0.008,0.004,0,0.0412,0.072,3.31,2.86,0.18,94.7,16,0.026,0.012,0.097,30979
"Peppers, sweet, red, frozen, chopped, unprepared",Vegetables and Vegetable Products,1.08,0.009,0.005,1.6,0.0587,0.091,4.45,3.26,0.21,93.96,20,0.031,0.014,0.113,30980
"Peppers, sweet, red, raw",Vegetables and Vegetable Products,0.99,0.007,0.004,2.1,0.1277,0.211,6.03,4.2,0.3,92.21,31,0.027,0.003,0.07,30981
"Peppers, sweet, red, sauteed",Vegetables and Vegetable Products,1.04,0.007,0.021,1.8,0.1628,0.193,6.57,4.28,12.75,78.95,133,1.557,2.287,5.719,30982
"Peppers, sweet, yellow, raw",Vegetables and Vegetable Products,1,0.011,0.002,0.9,0.1835,0.212,6.32,0,0.21,92.02,27,0.031,0,0,30983
"Pickle relish, hamburger",Vegetables and Vegetable Products,0.63,0.004,1.096,3.2,0.0023,0.076,34.48,0,0.54,61.12,129,0.052,0.269,0.131,30984
"Pickle relish, hot dog",Vegetables and Vegetable Products,1.5,0.005,1.091,1.5,0.001,0.078,23.35,0,0.46,71.65,91,0.044,0.227,0.11,30985
"Pickle relish, sweet",Vegetables and Vegetable Products,0.37,0.003,0.811,1.1,0.001,0.025,35.06,29.13,0.47,62.07,130,0.054,0.209,0.122,30986
"Pickles, chowchow, with cauliflower onion mustard, sweet",Vegetables and Vegetable Products,1.5,0.023,0.527,1.5,0.006,0.2,26.64,23.88,0.9,68.9,121,0.149,0.17,0.397,30987
"Pickles, cucumber, dill or kosher dill",Vegetables and Vegetable Products,0.6,0.042,0.875,1.1,0.0008,0.092,2.59,1.31,0.14,94.33,12,0.036,0.002,0.056,30988
"Pickles, cucumber, dill, low sodium",Vegetables and Vegetable Products,0.62,0.009,0.018,1.2,0.0019,0.116,4.13,1.92,0.19,91.67,18,0.05,0.003,0.077,30989
"Pickles, cucumber, sour",Vegetables and Vegetable Products,0.33,0,1.208,1.2,0.001,0.023,2.26,1.06,0.2,94.08,11,0.052,0.003,0.081,30990
"Pickles, cucumber, sour, low sodium",Vegetables and Vegetable Products,0.33,0,0.018,1.2,0.001,0.023,2.26,1.06,0.2,94.08,11,0.052,0.003,0.081,30991
"Pickles, cucumber, sweet (includes bread and butter pickles)",Vegetables and Vegetable Products,0.58,0.061,0.457,1,0.0007,0.1,21.15,18.27,0.41,76.2,91,0.067,0.004,0.106,30992
"Pickles, cucumber, sweet, low sodium (includes bread and butter pickles)",Vegetables and Vegetable Products,0.37,0.004,0.018,1.1,0.0012,0.032,33.73,26.68,0.26,65.26,122,0.067,0.004,0.106,30993
"Pigeonpeas, immature seeds, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,5.96,0.041,0.24,6.2,0.0281,0.456,19.49,2.48,1.36,71.8,111,0.112,0.017,1.149,30994
"Pigeonpeas, immature seeds, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,5.96,0.041,0.005,6.2,0.0281,0.456,19.49,2.48,1.36,71.8,111,0.112,0.017,1.149,30995
"Pigeonpeas, immature seeds, raw",Vegetables and Vegetable Products,7.2,0.042,0.005,5.1,0.039,0.552,23.88,3,1.64,65.88,136,0.354,0.013,0.873,30996
"Pimento, canned",Vegetables and Vegetable Products,1.1,0.006,0.014,1.9,0.0849,0.158,5.1,2.71,0.3,93.1,23,0.045,0.02,0.161,30997
Poi,Vegetables and Vegetable Products,0.38,0.016,0.012,0.4,0.004,0.183,27.23,0.39,0.14,71.64,112,0.029,0.011,0.058,30998
"Pokeberry shoots, (poke), cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.3,0.053,0.254,1.5,0.082,0.184,3.1,1.6,0.4,92.9,20,0,0,0,30999
"Pokeberry shoots, (poke), cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.3,0.053,0.018,1.5,0.082,0.184,3.1,1.6,0.4,92.9,20,0.092,0.013,0.176,31000
"Pokeberry shoots, (poke), raw",Vegetables and Vegetable Products,2.6,0.053,0.023,1.7,0.136,0.242,3.7,0,0.4,91.6,23,0,0,0,31001
Potato flour,Vegetables and Vegetable Products,6.9,0.065,0.055,5.9,0.0038,1.001,83.1,3.52,0.34,6.52,357,0.09,0.008,0.15,31002
Potato pancakes,Vegetables and Vegetable Products,6.08,0.032,0.764,3.3,0.027600000000000003,0.622,27.81,1.79,14.76,47.77,268,2.496,3.73,7.516,31003
"Potato puffs, frozen, oven-heated",Vegetables and Vegetable Products,2.05,0.014,0.48,2.5,0.0063,0.312,27.75,0.25,8.62,59.7,190,1.823,6.03,0.447,31004
"Potato puffs, frozen, unprepared",Vegetables and Vegetable Products,2.03,0.013,0.457,1.9,0.0075,0.285,25.97,0.21,7.77,62.42,175,1.641,5.413,0.403,31005
"Potato salad, home-prepared",Vegetables and Vegetable Products,2.68,0.019,0.529,1.3,0.01,0.254,11.17,0,8.2,76,143,1.429,2.48,3.737,31006
"Potato, baked, flesh and skin, without salt",Vegetables and Vegetable Products,2.5,0.015,0.01,2.2,0.0096,0.535,21.15,1.18,0.13,74.89,93,0.035,0.003,0.058,31007
"Potato, flesh and skin, raw",Vegetables and Vegetable Products,2.02,0.012,0.006,2.2,0.0197,0.421,17.47,0.78,0.09,79.34,77,0.026,0.002,0.043,31008
"Potatoes, Russet, flesh and skin, baked",Vegetables and Vegetable Products,2.63,0.018,0.014,2.3,0.0083,0.55,21.44,1.08,0.13,74.45,97,0.034,0.003,0.056,31009
"Potatoes, au gratin, dry mix, prepared with water, whole milk and butter",Vegetables and Vegetable Products,2.3,0.083,0.439,0.9,0.0031,0.219,12.84,0,4.12,78.98,93,2.586,1.175,0.134,31010
"Potatoes, au gratin, dry mix, unprepared",Vegetables and Vegetable Products,8.9,0.311,2.095,4.1,0.0155,0.99,74.31,0,3.7,4.97,314,2.323,1.055,0.121,31011
"Potatoes, au gratin, home-prepared from recipe using butter",Vegetables and Vegetable Products,5.06,0.119,0.433,1.8,0.0099,0.396,11.27,0,7.59,74,132,4.733,2.149,0.276,31012
"Potatoes, au gratin, home-prepared from recipe using margarine",Vegetables and Vegetable Products,5.06,0.119,0.433,1.8,0.0099,0.396,11.27,0,7.59,74,132,3.53,2.587,1.076,31013
"Potatoes, baked, flesh and skin, with salt",Vegetables and Vegetable Products,2.5,0.015,0.01,2.2,0.0096,0.535,21.15,1.18,0.13,74.89,93,0.035,0.003,0.058,31014
"Potatoes, baked, flesh, with salt",Vegetables and Vegetable Products,1.96,0.005,0.241,1.5,0.0128,0.391,21.55,1.7,0.1,75.42,93,0.026,0.002,0.043,31015
"Potatoes, baked, flesh, without salt",Vegetables and Vegetable Products,1.96,0.005,0.005,1.5,0.0128,0.391,21.55,1.7,0.1,75.42,93,0.026,0.002,0.043,31016
"Potatoes, baked, skin, with salt",Vegetables and Vegetable Products,4.29,0.034,0.257,7.9,0.0135,0.573,46.06,1.4,0.1,47.31,198,0.026,0.002,0.043,31017
"Potatoes, baked, skin, without salt",Vegetables and Vegetable Products,4.29,0.034,0.021,7.9,0.0135,0.573,46.06,1.4,0.1,47.31,198,0.026,0.002,0.043,31018
"Potatoes, boiled, cooked in skin, flesh, with salt",Vegetables and Vegetable Products,1.87,0.005,0.24,2,0.013,0.379,20.13,0.87,0.1,76.98,87,0.026,0.002,0.043,31019
"Potatoes, boiled, cooked in skin, flesh, without salt",Vegetables and Vegetable Products,1.87,0.005,0.004,1.8,0.013,0.379,20.13,0.87,0.1,76.98,87,0.026,0.002,0.043,31020
"Potatoes, boiled, cooked in skin, skin, with salt",Vegetables and Vegetable Products,2.86,0.045,0.25,3.3,0.0052,0.407,17.2,0,0.1,77.8,78,0.026,0.002,0.043,31021
"Potatoes, boiled, cooked in skin, skin, without salt",Vegetables and Vegetable Products,2.86,0.045,0.014,3.3,0.0052,0.407,17.21,0,0.1,77.8,78,0.026,0.002,0.043,31022
"Potatoes, boiled, cooked without skin, flesh, with salt",Vegetables and Vegetable Products,1.71,0.008,0.241,2,0.0074,0.328,20.01,0.85,0.1,77.46,86,0.026,0.002,0.043,31023
"Potatoes, boiled, cooked without skin, flesh, without salt",Vegetables and Vegetable Products,1.71,0.008,0.005,1.8,0.0074,0.328,20.01,0.85,0.1,77.46,86,0.026,0.002,0.043,31024
"Potatoes, canned, drained solids",Vegetables and Vegetable Products,1.41,0.005,0.219,2.3,0.0050999999999999995,0.229,13.61,0,0.21,84.28,60,0.054,0.005,0.089,31025
"Potatoes, canned, drained solids, no salt added",Vegetables and Vegetable Products,1.4,0.005,0.005,2.4,0.0050999999999999995,0.229,13.6,0.59,0.2,84.3,62,0.051,0.005,0.085,31026
"Potatoes, canned, solids and liquids",Vegetables and Vegetable Products,1.2,0.039,0.217,1.4,0.0076,0.205,9.89,0,0.11,87.83,44,0.029,0.002,0.048,31027
"Potatoes, french fried, all types, salt added in processing, frozen, home-prepared, oven heated",Vegetables and Vegetable Products,2.66,0.012,0.388,2.8,0.013300000000000001,0.451,27.74,0.28,5.22,62.48,164,1.029,3.237,0.321,31028
"Potatoes, french fried, all types, salt added in processing, frozen, unprepared",Vegetables and Vegetable Products,2.24,0.009,0.332,1.9,0.0173,0.408,24.81,0.2,4.66,66.61,147,1.005,3.164,0.303,31029
"Potatoes, french fried, all types, salt not added in processing, frozen, as purchased",Vegetables and Vegetable Products,2.24,0.009,0.023,1.9,0.0173,0.408,24.81,0.2,4.66,66.61,150,0.94,2.96,0.265,31030
"Potatoes, french fried, all types, salt not added in processing, frozen, oven-heated",Vegetables and Vegetable Products,2.66,0.012,0.032,2.6,0.013300000000000001,0.451,28.71,0.28,5.22,61.51,172,1.029,3.237,0.321,31031
"Potatoes, french fried, crinkle or regular cut, salt added in processing, frozen, as purchased",Vegetables and Vegetable Products,2.12,0.009,0.358,2.7,0.0161,0.416,29.92,0.21,5.05,61.08,174,0.975,3.102,0.287,31032
"Potatoes, french fried, crinkle or regular cut, salt added in processing, frozen, oven-heated",Vegetables and Vegetable Products,2.51,0.013,0.391,2.3,0.011800000000000001,0.471,27.5,0.29,5.13,63.07,166,1.082,3.446,0.319,31033
"Potatoes, french fried, shoestring, salt added in processing, frozen, as purchased",Vegetables and Vegetable Products,2.41,0.01,0.322,2.2,0.0175,0.409,26.78,0.2,5.53,63.7,167,1.157,3.754,0.307,31034
"Potatoes, french fried, shoestring, salt added in processing, frozen, oven-heated",Vegetables and Vegetable Products,2.9,0.012,0.4,2.8,0.014199999999999999,0.505,31.66,0.31,6.76,56.62,199,1.267,4.058,0.345,31035
"Potatoes, french fried, steak fries, salt added in processing, frozen, as purchased",Vegetables and Vegetable Products,2.19,0.009,0.317,1.9,0.0184,0.4,23.51,0.2,3.39,69.29,133,0.688,2.025,0.218,31036
"Potatoes, french fried, steak fries, salt added in processing, frozen, oven-heated",Vegetables and Vegetable Products,2.57,0.01,0.373,2.6,0.014,0.459,26.98,0.25,3.76,64.85,152,0.737,2.207,0.252,31037
"Potatoes, frozen, french fried, par fried, cottage-cut, prepared, heated in oven, with salt",Vegetables and Vegetable Products,3.44,0.01,0.281,3.2,0.0095,0.48,34.03,0,8.2,52.9,218,3.894,3.328,0.615,31038
"Potatoes, frozen, french fried, par fried, cottage-cut, prepared, heated in oven, without salt",Vegetables and Vegetable Products,3.44,0.01,0.045,3.2,0.0095,0.48,34.03,0,8.2,52.9,218,3.894,3.328,0.615,31039
"Potatoes, frozen, french fried, par fried, cottage-cut, unprepared",Vegetables and Vegetable Products,2.42,0.007,0.032,3,0.008400000000000001,0.338,23.98,0,5.78,66.82,153,2.743,2.345,0.433,31040
"Potatoes, frozen, french fried, par fried, extruded, prepared, heated in oven, without salt",Vegetables and Vegetable Products,3.55,0.012,0.613,3.2,0.0062,0.539,39.68,0,18.71,35.4,333,5.96,11.338,1.405,31041
"Potatoes, frozen, french fried, par fried, extruded, unprepared",Vegetables and Vegetable Products,2.83,0.009,0.49,4.5,0.0063,0.43,30.15,0,14.95,49.94,260,4.544,8.659,1.072,31042
"Potatoes, frozen, whole, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.98,0.007,0.256,1.4,0.0094,0.287,13.93,0,0.13,82.8,63,0.034,0.003,0.056,31043
"Potatoes, frozen, whole, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.98,0.007,0.02,1.4,0.0094,0.287,14.52,0,0.13,82.8,65,0.034,0.003,0.056,31044
"Potatoes, frozen, whole, unprepared",Vegetables and Vegetable Products,2.38,0.008,0.025,1.2,0.014199999999999999,0.346,17.47,0.78,0.16,79.3,78,0.041,0.003,0.067,31045
"Potatoes, hashed brown, frozen, plain, prepared",Vegetables and Vegetable Products,3.16,0.015,0.034,2,0.0063,0.436,28.1,1.57,11.5,56.1,218,4.493,5.137,1.325,31046
"Potatoes, hashed brown, frozen, plain, unprepared",Vegetables and Vegetable Products,2.06,0.01,0.022,1.4,0.008199999999999999,0.285,17.72,0,0.62,78.85,82,0.163,0.014,0.269,31047
"Potatoes, hashed brown, frozen, with butter sauce, prepared",Vegetables and Vegetable Products,2.46,0.033,0.101,3.8,0.0038,0.327,24.13,0,8.79,63.71,178,3.373,3.149,1.814,31048
"Potatoes, hashed brown, frozen, with butter sauce, unprepared",Vegetables and Vegetable Products,1.87,0.025,0.077,2.9,0.0057,0.248,18.28,0,6.66,72.51,135,2.556,2.386,1.374,31049
"Potatoes, hashed brown, home-prepared",Vegetables and Vegetable Products,3,0.014,0.342,3.2,0.013,0.576,35.11,1.49,12.52,47.25,265,1.883,5.299,4.703,31050
"Potatoes, mashed, dehydrated, flakes without milk, dry form",Vegetables and Vegetable Products,8.34,0.027,0.104,6.6,0.081,1.098,81.17,3.36,0.41,6.58,354,0.169,0.009,0.143,31051
"Potatoes, mashed, dehydrated, granules with milk, dry form",Vegetables and Vegetable Products,10.9,0.142,0.082,6.6,0.016,1.848,77.7,3.37,1.1,6.3,357,0.461,0.152,0.285,31052
"Potatoes, mashed, dehydrated, granules without milk, dry form",Vegetables and Vegetable Products,8.22,0.041,0.067,7.1,0.037,0.703,85.51,3.47,0.54,3.63,372,0.138,0.011,0.238,31053
"Potatoes, mashed, dehydrated, prepared from flakes without milk, whole milk and butter added",Vegetables and Vegetable Products,1.77,0.032,0.164,0.8,0.009699999999999999,0.164,10.87,1.61,5.13,81.2,97,3.191,1.31,0.222,31054
"Potatoes, mashed, dehydrated, prepared from granules with milk, water and margarine added",Vegetables and Vegetable Products,2.13,0.035,0.172,1.3,0.0065,0.155,16.13,1.74,4.8,76.01,116,1.179,2.108,1.291,31055
"Potatoes, mashed, dehydrated, prepared from granules without milk, whole milk and butter added",Vegetables and Vegetable Products,2.05,0.035,0.257,2.2,0.006,0.144,14.36,0,4.96,77.49,108,3.056,1.405,0.227,31056
"Potatoes, mashed, home-prepared, whole milk added",Vegetables and Vegetable Products,1.91,0.024,0.302,1.5,0.0062,0.296,17.57,1.51,0.57,78.48,83,0.302,0.124,0.065,31057
"Potatoes, mashed, home-prepared, whole milk and butter added",Vegetables and Vegetable Products,1.86,0.024,0.317,1.5,0.006,0.284,16.81,1.43,4.22,75.61,113,2.613,1.07,0.199,31058
"Potatoes, mashed, home-prepared, whole milk and margarine added",Vegetables and Vegetable Products,1.96,0.021,0.333,1.5,0.0105,0.326,16.94,1.44,4.2,75.25,111,0.972,1.879,1.164,31059
"Potatoes, mashed, prepared from flakes, without milk, whole milk and margarine",Vegetables and Vegetable Products,1.9,0.049,0.332,2.3,0.009699999999999999,0.233,15.02,0,5.6,76.3,113,1.46,2.307,1.551,31060
"Potatoes, mashed, prepared from granules, without milk, whole milk and margarine",Vegetables and Vegetable Products,2.05,0.035,0.263,2.2,0.006,0.145,14.4,0,4.93,77.48,108,1.284,2.041,1.363,31061
"Potatoes, microwaved, cooked in skin, flesh and skin, without salt",Vegetables and Vegetable Products,2.44,0.011,0.008,2.3,0.015099999999999999,0.447,24.24,0,0.1,72.04,105,0.026,0.002,0.043,31062
"Potatoes, microwaved, cooked in skin, flesh, with salt",Vegetables and Vegetable Products,2.1,0.005,0.243,1.6,0.015099999999999999,0.411,23.28,0,0.1,73.55,100,0.026,0.002,0.043,31063
"Potatoes, microwaved, cooked in skin, flesh, without salt",Vegetables and Vegetable Products,2.1,0.005,0.007,1.6,0.015099999999999999,0.411,23.28,0,0.1,73.55,100,0.026,0.002,0.043,31064
"Potatoes, microwaved, cooked in skin, skin, without salt",Vegetables and Vegetable Products,4.39,0.046,0.016,5.5,0.015300000000000001,0.65,29.63,0,0.1,63.5,132,0.026,0.002,0.043,31065
"Potatoes, microwaved, cooked, in skin, flesh and skin, with salt",Vegetables and Vegetable Products,2.44,0.011,0.244,2.3,0.015099999999999999,0.447,24.24,0,0.1,72.04,105,0.026,0.002,0.043,31066
"Potatoes, microwaved, cooked, in skin, skin with salt",Vegetables and Vegetable Products,4.39,0.046,0.252,5.5,0.015300000000000001,0.65,29.63,0,0.1,63.5,132,0.026,0.002,0.043,31067
"Potatoes, o'brien, frozen, prepared",Vegetables and Vegetable Products,2.22,0.02,0.043,1.7,0.0104,0.473,21.86,0,13.21,61.96,204,3.313,5.824,3.474,31068
"Potatoes, o'brien, frozen, unprepared",Vegetables and Vegetable Products,1.83,0.013,0.033,1.9,0.011300000000000001,0.249,17.47,0,0.14,79.97,76,0.032,0.006,0.061,31069
"Potatoes, o'brien, home-prepared",Vegetables and Vegetable Products,2.35,0.036,0.217,0,0.0167,0.266,15.47,0,1.28,79.6,81,0.797,0.347,0.055,31070
"Potatoes, raw, skin",Vegetables and Vegetable Products,2.57,0.03,0.01,2.5,0.0114,0.413,12.44,0,0.1,83.29,58,0.026,0.002,0.043,31071
"Potatoes, red, flesh and skin, baked",Vegetables and Vegetable Products,2.3,0.009,0.012,1.8,0.0126,0.545,19.59,1.43,0.15,76.67,89,0.026,0.002,0.043,31072
"Potatoes, red, flesh and skin, raw",Vegetables and Vegetable Products,1.89,0.01,0.018,1.7,0.0086,0.455,15.9,1.29,0.14,80.96,70,0.035,0.003,0.059,31073
"Potatoes, russet, flesh and skin, raw",Vegetables and Vegetable Products,2.14,0.013,0.005,1.3,0.0057,0.417,18.07,0.62,0.08,78.58,79,0.026,0.002,0.043,31074
"Potatoes, scalloped, dry mix, prepared with water, whole milk and butter",Vegetables and Vegetable Products,2.12,0.036,0.341,1.1,0.0033,0.203,12.77,0,4.3,79.17,93,2.633,1.213,0.194,31075
"Potatoes, scalloped, dry mix, unprepared",Vegetables and Vegetable Products,7.77,0.062,1.578,8.6,0.0165,0.905,73.93,0,4.59,6.22,358,1.2,0.1,1.985,31076
"Potatoes, scalloped, home-prepared with butter",Vegetables and Vegetable Products,2.87,0.057,0.335,1.9,0.0106,0.378,10.78,0,3.68,80.94,88,2.255,1.039,0.166,31077
"Potatoes, scalloped, home-prepared with margarine",Vegetables and Vegetable Products,2.87,0.057,0.335,1.9,0.0106,0.378,10.78,0,3.68,80.94,88,1.377,1.352,0.746,31078
"Potatoes, white, flesh and skin, baked",Vegetables and Vegetable Products,2.1,0.01,0.007,2.1,0.0126,0.544,21.08,1.53,0.15,75.43,94,0.022,0.002,0.037,31079
"Potatoes, white, flesh and skin, raw",Vegetables and Vegetable Products,1.68,0.009,0.016,2.4,0.0197,0.407,15.71,1.15,0.1,81.58,69,0.026,0.002,0.043,31080
"Pumpkin flowers, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.09,0.037,0.006,0.9,0.005,0.106,3.3,2.4,0.08,95.2,15,0.041,0.01,0.004,31081
"Pumpkin flowers, raw",Vegetables and Vegetable Products,1.03,0.039,0.005,0,0.028,0.173,3.28,0,0.07,95.15,15,0.036,0.009,0.004,31082
"Pumpkin leaves, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.72,0.043,0.244,2.7,0.001,0.438,3.39,0.69,0.22,92.51,21,0.114,0.029,0.012,31083
"Pumpkin leaves, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.72,0.043,0.008,2.7,0.001,0.438,3.39,0.69,0.22,92.51,21,0.114,0.029,0.012,31084
"Pumpkin leaves, raw",Vegetables and Vegetable Products,3.15,0.039,0.011,0,0.011,0.436,2.33,0,0.4,92.88,19,0.207,0.052,0.022,31085
"Pumpkin pie mix, canned",Vegetables and Vegetable Products,1.09,0.037,0.208,8.3,0.0035,0.138,26.39,0,0.13,71.49,104,0.065,0.016,0.007,31086
"Pumpkin, canned, with salt",Vegetables and Vegetable Products,1.1,0.026,0.241,2.9,0.004200000000000001,0.206,8.09,3.3,0.28,89.97,34,0.146,0.037,0.015,31087
"Pumpkin, canned, without salt",Vegetables and Vegetable Products,1.1,0.026,0.005,2.9,0.004200000000000001,0.206,8.09,3.3,0.28,89.97,34,0.146,0.037,0.015,31088
"Pumpkin, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.72,0.015,0.237,1.1,0.0047,0.23,4.9,1.02,0.07,93.69,20,0.037,0.009,0.004,31089
"Pumpkin, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.72,0.015,0.001,1.1,0.0047,0.23,4.9,1.02,0.07,93.69,20,0.037,0.009,0.004,31090
"Pumpkin, flowers, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.09,0.037,0.242,0.9,0.005,0.106,3.18,2.4,0.08,95.2,15,0.041,0.01,0.004,31091
"Pumpkin, raw",Vegetables and Vegetable Products,1,0.021,0.001,0.5,0.009,0.34,6.5,1.36,0.1,91.6,26,0.052,0.013,0.005,31092
"Purslane, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.49,0.078,0.28,0,0.0105,0.488,3.55,0,0.19,93.52,18,0,0,0,31093
"Purslane, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.49,0.078,0.044,0,0.0105,0.488,3.55,0,0.19,93.52,18,0,0,0,31094
"Purslane, raw",Vegetables and Vegetable Products,1.3,0.065,0.045,0,0.021,0.494,3.43,0,0.1,93.92,16,0,0,0,31095
"Radicchio, raw",Vegetables and Vegetable Products,1.43,0.019,0.022,0.9,0.008,0.302,4.48,0.6,0.25,93.14,23,0.06,0.01,0.11,31096
"Radish seeds, sprouted, raw",Vegetables and Vegetable Products,3.81,0.051,0.006,0,0.0289,0.086,3.6,0,2.53,90.07,43,0.767,0.419,1.141,31097
"Radishes, hawaiian style, pickled",Vegetables and Vegetable Products,1.1,0.028,0.789,2.2,0,0.333,5.2,2,0.3,91.5,28,0.092,0.05,0.135,31098
"Radishes, oriental, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.67,0.017,0.249,1.6,0.015099999999999999,0.285,3.43,1.83,0.24,95.04,17,0.073,0.04,0.108,31099
"Radishes, oriental, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.67,0.017,0.013,1.6,0.015099999999999999,0.285,3.43,1.83,0.24,95.04,17,0.073,0.04,0.108,31100
"Radishes, oriental, dried",Vegetables and Vegetable Products,7.9,0.629,0.278,0,0,3.494,63.37,0,0.72,19.68,271,0.218,0.119,0.325,31101
"Radishes, oriental, raw",Vegetables and Vegetable Products,0.6,0.027,0.021,1.6,0.022,0.227,4.1,2.5,0.1,94.62,18,0.03,0.017,0.045,31102
"Radishes, raw",Vegetables and Vegetable Products,0.68,0.025,0.039,1.6,0.0148,0.233,3.4,1.86,0.1,95.27,16,0.032,0.017,0.048,31103
"Radishes, white icicle, raw",Vegetables and Vegetable Products,1.1,0.027,0.016,1.4,0.029,0.28,2.63,0,0.1,95.37,14,0.03,0.016,0.045,31104
"Rutabagas, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.29,0.048,0.254,0,0.0188,0.326,8.74,6.02,0.22,88.88,39,0.029,0.027,0.095,31105
"Rutabagas, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.29,0.048,0.02,1.8,0.0188,0.326,8.74,6.02,0.22,88.88,39,0.029,0.027,0.095,31106
"Rutabagas, raw",Vegetables and Vegetable Products,1.2,0.047,0.02,2.5,0.025,0.337,8.13,5.6,0.2,89.66,36,0.027,0.025,0.088,31107
"Salsify, (vegetable oyster), raw",Vegetables and Vegetable Products,3.3,0.06,0.02,3.3,0.008,0.38,18.6,0,0.2,77,82,0,0,0,31108
"Salsify, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.73,0.047,0.252,3.1,0.0046,0.283,15.36,2.9,0.17,81,68,0,0,0,31109
"Salsify, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.73,0.047,0.016,3.1,0.0046,0.283,15.36,2.9,0.17,81,68,0.041,0.003,0.074,31110
"Sauerkraut, canned, low sodium",Vegetables and Vegetable Products,0.9,0.03,0.308,2.5,0.0147,0.17,4.35,1.78,0.1,92.5,22,0.026,0.01,0.051,31111
"Sauerkraut, canned, solids and liquids",Vegetables and Vegetable Products,0.91,0.03,0.661,2.9,0.0147,0.17,4.28,1.78,0.14,92.52,19,0.034,0.013,0.067,31112
"Seaweed, agar, dried",Vegetables and Vegetable Products,6.21,0.625,0.102,7.7,0,1.125,80.88,2.97,0.3,8.68,306,0.061,0.027,0.102,31113
"Seaweed, agar, raw",Vegetables and Vegetable Products,0.54,0.054,0.009,0.5,0,0.226,6.75,0.28,0.03,91.32,26,0.006,0.003,0.01,31114
"Seaweed, irishmoss, raw",Vegetables and Vegetable Products,1.51,0.072,0.067,1.3,0.003,0.063,12.29,0.61,0.16,81.34,49,0.033,0.015,0.055,31115
"Seaweed, kelp, raw",Vegetables and Vegetable Products,1.68,0.168,0.233,1.3,0.003,0.089,9.57,0.6,0.56,81.58,43,0.247,0.098,0.047,31116
"Seaweed, laver, raw",Vegetables and Vegetable Products,5.81,0.07,0.048,0.3,0.039,0.356,5.11,0.49,0.28,85.03,35,0.061,0.025,0.11,31117
"Seaweed, spirulina, dried",Vegetables and Vegetable Products,57.47,0.12,1.048,3.6,0.0101,1.363,23.9,3.1,7.72,4.68,290,2.65,0.675,2.08,31118
"Seaweed, spirulina, raw",Vegetables and Vegetable Products,5.92,0.012,0.098,0,0.0009,0.127,2.42,0,0.39,90.67,26,0.135,0.034,0.106,31119
"Seaweed, wakame, raw",Vegetables and Vegetable Products,3.03,0.15,0.872,0.5,0.003,0.05,9.14,0.65,0.64,79.99,45,0.13,0.058,0.218,31120
"Sesbania flower, cooked, steamed, with salt",Vegetables and Vegetable Products,1.14,0.022,0.247,0,0.037,0.107,5.1,0,0.05,93.3,21,0,0,0,31121
"Sesbania flower, cooked, steamed, without salt",Vegetables and Vegetable Products,1.14,0.022,0.011,0,0.037,0.107,5.23,0,0.05,93.3,22,0,0,0,31122
"Sesbania flower, raw",Vegetables and Vegetable Products,1.28,0.019,0.015,0,0.073,0.184,6.73,0,0.04,91.58,27,0,0,0,31123
"Shallots, freeze-dried",Vegetables and Vegetable Products,12.3,0.183,0.059,0,0.039,1.65,80.7,0,0.5,2,348,0.084,0.07,0.196,31124
"Shallots, raw",Vegetables and Vegetable Products,2.5,0.037,0.012,0,0.008,0.334,16.8,0,0.1,79.8,72,0.017,0.014,0.039,31125
"Soybeans, green, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,12.35,0.145,0.25,4.2,0.017,0.539,11.05,0,6.4,68.6,141,0.74,1.209,3.011,31126
"Soybeans, green, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,12.35,0.145,0.014,4.2,0.017,0.539,11.05,0,6.4,68.6,141,0.74,1.209,3.011,31127
"Soybeans, green, raw",Vegetables and Vegetable Products,12.95,0.197,0.015,4.2,0.029,0.62,11.05,0,6.8,67.5,147,0.786,1.284,3.2,31128
"Soybeans, mature seeds, sprouted, cooked, steamed",Vegetables and Vegetable Products,8.47,0.059,0.01,0.8,0.0083,0.355,6.53,0.52,4.45,79.45,81,0.617,1.009,2.513,31129
"Soybeans, mature seeds, sprouted, cooked, steamed, with salt",Vegetables and Vegetable Products,8.47,0.059,0.246,0.8,0.0083,0.355,6.53,0.43,4.45,79.45,81,0.617,1.009,2.513,31130
"Soybeans, mature seeds, sprouted, cooked, stir-fried",Vegetables and Vegetable Products,13.1,0.082,0.014,0.8,0.012,0.567,9.4,0,7.1,67.2,125,0.985,1.609,4.009,31131
"Soybeans, mature seeds, sprouted, cooked, stir-fried, with salt",Vegetables and Vegetable Products,13.1,0.082,0.25,0.8,0.012,0.567,9.4,0,7.1,67.2,125,0,0,0,31132
"Soybeans, mature seeds, sprouted, raw",Vegetables and Vegetable Products,13.09,0.067,0.014,1.1,0.015300000000000001,0.484,9.57,0,6.7,69.05,122,0.929,1.518,3.783,31133
Spinach souffle,Vegetables and Vegetable Products,7.89,0.165,0.566,0.7,0.0073,0.231,5.9,1.85,12.95,70.73,172,6.091,3.021,0.61,31134
"Spinach, canned, no salt added, solids and liquids",Vegetables and Vegetable Products,2.11,0.083,0.075,2.2,0.0135,0.23,2.92,0,0.37,93.22,19,0.06,0.01,0.155,31135
"Spinach, canned, regular pack, drained solids",Vegetables and Vegetable Products,2.81,0.127,0.322,2.4,0.0143,0.346,3.4,0.4,0.5,91.78,23,0.081,0.013,0.211,31136
"Spinach, canned, regular pack, solids and liquids",Vegetables and Vegetable Products,2.11,0.083,0.319,1.6,0.0135,0.23,2.92,0,0.37,93.22,19,0.06,0.01,0.155,31137
"Spinach, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.97,0.136,0.306,2.4,0.009800000000000001,0.466,3.75,0.43,0.26,91.21,23,0.043,0.006,0.109,31138
"Spinach, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.97,0.136,0.07,2.4,0.009800000000000001,0.466,3.75,0.43,0.26,91.21,23,0.043,0.006,0.109,31139
"Spinach, frozen, chopped or leaf, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,4.01,0.153,0.322,3.7,0.0022,0.302,4.8,0.51,0.87,88.94,34,0.036,0,0.086,31140
"Spinach, frozen, chopped or leaf, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,4.01,0.153,0.097,3.7,0.0022,0.302,4.8,0.51,0.87,88.94,34,0.157,0,0.371,31141
"Spinach, frozen, chopped or leaf, unprepared",Vegetables and Vegetable Products,3.63,0.129,0.074,2.9,0.0055,0.346,4.21,0.65,0.57,90.17,29,0.041,0,0.082,31142
"Spinach, raw",Vegetables and Vegetable Products,2.86,0.099,0.079,2.2,0.0281,0.558,3.63,0.42,0.39,91.4,23,0.063,0.01,0.165,31143
"Squash, summer, all varieties, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.91,0.027,0.237,1.4,0.0055,0.192,4.31,2.59,0.31,93.7,20,0.064,0.023,0.131,31144
"Squash, summer, all varieties, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.91,0.027,0.001,1.4,0.0055,0.192,4.31,2.59,0.31,93.7,20,0.064,0.023,0.131,31145
"Squash, summer, all varieties, raw",Vegetables and Vegetable Products,1.21,0.015,0.002,1.1,0.017,0.262,3.35,2.2,0.18,94.64,16,0.044,0.016,0.089,31146
"Squash, summer, crookneck and straightneck, canned, drained, solid, without salt",Vegetables and Vegetable Products,0.61,0.012,0.005,1.4,0.0027,0.096,2.96,1.19,0.07,96.04,13,0.015,0.005,0.031,31147
"Squash, summer, crookneck and straightneck, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.04,0.022,0.237,1.1,0.0116,0.177,3.79,2.48,0.39,94.25,19,0.064,0.023,0.131,31148
"Squash, summer, crookneck and straightneck, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.04,0.022,0.001,1.1,0.0116,0.177,3.79,2.48,0.39,94.25,23,0.064,0.023,0.131,31149
"Squash, summer, crookneck and straightneck, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.28,0.02,0.242,1.4,0.0068,0.253,5.54,2.33,0.2,92.24,25,0.04,0.015,0.082,31150
"Squash, summer, crookneck and straightneck, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.28,0.02,0.006,1.4,0.0068,0.253,5.54,2.33,0.2,92.24,25,0.04,0.015,0.082,31151
"Squash, summer, crookneck and straightneck, frozen, unprepared",Vegetables and Vegetable Products,0.83,0.018,0.005,1.2,0.0064,0.209,4.8,0,0.14,93.66,20,0.029,0.011,0.06,31152
"Squash, summer, crookneck and straightneck, raw",Vegetables and Vegetable Products,1.01,0.021,0.002,1,0.0193,0.222,3.88,3.53,0.27,94.28,19,0.093,0.013,0.082,31153
"Squash, summer, scallop, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.03,0.015,0.237,1.9,0.0108,0.14,3.3,1.5,0.17,95,16,0.035,0.013,0.072,31154
"Squash, summer, scallop, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.03,0.015,0.001,1.9,0.0108,0.14,3.3,1.5,0.17,95,16,0.035,0.013,0.072,31155
"Squash, summer, scallop, raw",Vegetables and Vegetable Products,1.2,0.019,0.001,0,0.018,0.182,3.84,0,0.2,94.18,18,0.041,0.015,0.084,31156
"Squash, summer, zucchini, includes skin, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.14,0.018,0.239,1,0.0129,0.264,2.69,1.71,0.36,95.22,15,0.072,0.029,0.151,31157
"Squash, summer, zucchini, includes skin, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.14,0.018,0.003,1,0.0129,0.264,2.69,1.71,0.36,95.22,15,0.072,0.029,0.151,31158
"Squash, summer, zucchini, includes skin, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.15,0.017,0.238,1.3,0.0037,0.194,2.97,1.69,0.13,94.74,14,0.027,0.01,0.055,31159
"Squash, summer, zucchini, includes skin, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.15,0.017,0.002,1.3,0.0037,0.194,3.56,1.69,0.13,94.74,17,0.027,0.01,0.055,31160
"Squash, summer, zucchini, includes skin, frozen, unprepared",Vegetables and Vegetable Products,1.16,0.018,0.002,1.3,0.0053,0.218,3.59,1.71,0.13,94.7,17,0.027,0.01,0.056,31161
"Squash, summer, zucchini, includes skin, raw",Vegetables and Vegetable Products,1.21,0.016,0.008,1,0.0179,0.261,3.11,2.5,0.32,94.79,17,0.084,0.011,0.091,31162
"Squash, summer, zucchini, italian style, canned",Vegetables and Vegetable Products,1.03,0.017,0.374,0,0.0023,0.274,6.85,0,0.11,90.61,29,0.023,0.008,0.047,31163
"Squash, winter, acorn, cooked, baked, with salt",Vegetables and Vegetable Products,1.12,0.044,0.24,4.4,0.0108,0.437,14.58,0,0.14,82.9,56,0.029,0.01,0.059,31164
"Squash, winter, acorn, cooked, baked, without salt",Vegetables and Vegetable Products,1.12,0.044,0.004,4.4,0.0108,0.437,14.58,0,0.14,82.9,56,0.029,0.01,0.059,31165
"Squash, winter, acorn, cooked, boiled, mashed, with salt",Vegetables and Vegetable Products,0.67,0.026,0.239,2.6,0.0065,0.263,8.79,0,0.08,89.7,34,0.017,0.006,0.035,31166
"Squash, winter, acorn, cooked, boiled, mashed, without salt",Vegetables and Vegetable Products,0.67,0.026,0.003,2.6,0.0065,0.263,8.79,0,0.08,89.7,34,0.017,0.006,0.035,31167
"Squash, winter, acorn, raw",Vegetables and Vegetable Products,0.8,0.033,0.003,1.5,0.011,0.347,10.42,0,0.1,87.78,40,0.021,0.007,0.042,31168
"Squash, winter, all varieties, cooked, baked, with salt",Vegetables and Vegetable Products,0.89,0.014,0.237,2.8,0.0096,0.437,8.74,3.3,0.63,89.02,39,0.13,0.047,0.265,31169
"Squash, winter, all varieties, cooked, baked, without salt",Vegetables and Vegetable Products,0.89,0.022,0.001,2.8,0.0096,0.241,8.85,3.3,0.35,89.21,37,0.072,0.026,0.147,31170
"Squash, winter, all varieties, raw",Vegetables and Vegetable Products,0.95,0.028,0.004,1.5,0.0123,0.35,8.59,2.2,0.13,89.76,34,0.027,0.01,0.056,31171
"Squash, winter, butternut, cooked, baked, with salt",Vegetables and Vegetable Products,0.9,0.041,0.24,3.2,0.015099999999999999,0.284,10.49,1.97,0.09,87.8,40,0.019,0.007,0.038,31172
"Squash, winter, butternut, cooked, baked, without salt",Vegetables and Vegetable Products,0.9,0.041,0.004,3.2,0.015099999999999999,0.284,10.49,1.97,0.09,87.8,40,0.019,0.007,0.038,31173
"Squash, winter, butternut, frozen, cooked, boiled, with salt",Vegetables and Vegetable Products,1.23,0.019,0.238,0,0.0035,0.133,10.04,0,0.07,87.8,39,0.014,0.005,0.029,31174
"Squash, winter, butternut, frozen, cooked, boiled, without salt",Vegetables and Vegetable Products,1.23,0.019,0.002,0,0.0035,0.133,10.05,0,0.07,87.8,39,0.014,0.005,0.029,31175
"Squash, winter, butternut, frozen, unprepared",Vegetables and Vegetable Products,1.76,0.029,0.002,1.3,0.0062,0.212,14.41,2.83,0.1,82.5,57,0.021,0.007,0.042,31176
"Squash, winter, butternut, raw",Vegetables and Vegetable Products,1,0.048,0.004,2,0.021,0.352,11.69,2.2,0.1,86.41,45,0.021,0.007,0.042,31177
"Squash, winter, hubbard, cooked, baked, with salt",Vegetables and Vegetable Products,2.48,0.017,0.244,0,0.0095,0.358,10.81,0,0.62,85.1,50,0.128,0.046,0.26,31178
"Squash, winter, hubbard, cooked, baked, without salt",Vegetables and Vegetable Products,2.48,0.017,0.008,0,0.0095,0.358,10.81,0,0.62,85.1,50,0.128,0.046,0.26,31179
"Squash, winter, hubbard, cooked, boiled, mashed, with salt",Vegetables and Vegetable Products,1.48,0.01,0.241,2.9,0.0065,0.214,6.46,2.93,0.37,91.1,30,0.076,0.028,0.156,31180
"Squash, winter, hubbard, cooked, boiled, mashed, without salt",Vegetables and Vegetable Products,1.48,0.01,0.005,2.9,0.0065,0.214,6.46,2.93,0.37,91.1,30,0.076,0.028,0.156,31181
"Squash, winter, hubbard, raw",Vegetables and Vegetable Products,2,0.014,0.007,0,0.011,0.32,8.7,0,0.5,88,40,0.103,0.036,0.211,31182
"Squash, winter, spaghetti, cooked, boiled, drained, or baked, with salt",Vegetables and Vegetable Products,0.66,0.021,0.254,1.4,0.0035,0.117,6.46,2.53,0.26,92.3,27,0.062,0.022,0.126,31183
"Squash, winter, spaghetti, cooked, boiled, drained, or baked, without salt",Vegetables and Vegetable Products,0.66,0.021,0.018,1.4,0.0035,0.117,6.46,2.53,0.26,92.3,27,0.062,0.022,0.126,31184
"Squash, winter, spaghetti, raw",Vegetables and Vegetable Products,0.64,0.023,0.017,0,0.0021000000000000003,0.108,6.91,0,0.57,91.6,31,0.117,0.042,0.239,31185
"Squash, zucchini, baby, raw",Vegetables and Vegetable Products,2.71,0.021,0.003,1.1,0.0341,0.459,3.11,0,0.4,92.73,21,0.083,0.031,0.169,31186
"Succotash, (corn and limas), canned, with cream style corn",Vegetables and Vegetable Products,2.64,0.011,0.245,3,0.0064,0.183,17.61,0,0.54,78.17,77,0.101,0.105,0.26,31187
"Succotash, (corn and limas), canned, with whole kernel corn, solids and liquids",Vegetables and Vegetable Products,2.6,0.011,0.221,2.6,0.0046,0.163,13.98,0,0.49,81.96,63,0.092,0.096,0.236,31188
"Succotash, (corn and limas), cooked, boiled, drained, with salt",Vegetables and Vegetable Products,5.07,0.017,0.253,0,0.008199999999999999,0.41,24.37,0,0.8,68.37,111,0.148,0.155,0.381,31189
"Succotash, (corn and limas), cooked, boiled, drained, without salt",Vegetables and Vegetable Products,5.07,0.017,0.017,4.5,0.008199999999999999,0.41,24.38,0,0.8,68.37,115,0.148,0.155,0.381,31190
"Succotash, (corn and limas), frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,4.31,0.015,0.281,4.1,0.005900000000000001,0.265,19.95,2.21,0.89,74.1,93,0.166,0.173,0.427,31191
"Succotash, (corn and limas), frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,4.31,0.015,0.045,4.1,0.005900000000000001,0.265,19.95,2.21,0.89,74.1,93,0.166,0.173,0.427,31192
"Succotash, (corn and limas), frozen, unprepared",Vegetables and Vegetable Products,4.31,0.016,0.045,4,0.0085,0.295,19.94,0,0.89,74.11,93,0.166,0.173,0.427,31193
"Succotash, (corn and limas), raw",Vegetables and Vegetable Products,5.03,0.018,0.004,3.8,0.015099999999999999,0.369,19.59,0,1.02,73.1,99,0.19,0.198,0.489,31194
"Swamp cabbage, (skunk cabbage), raw",Vegetables and Vegetable Products,2.6,0.077,0.113,2.1,0.055,0.312,3.14,0,0.2,92.47,19,0,0,0,31195
"Swamp cabbage, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.08,0.054,0.358,1.9,0.016,0.284,3.71,0,0.24,92.93,20,0,0,0,31196
"Swamp cabbage, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.08,0.054,0.122,1.9,0.016,0.284,3.71,0,0.24,92.93,20,0.039,0.007,0.1,31197
"Sweet potato leaves, cooked, steamed, with salt",Vegetables and Vegetable Products,2.32,0.024,0.249,1.9,0.0015,0.477,7.32,5.42,0.3,88.71,34,0.065,0.012,0.134,31198
"Sweet potato leaves, cooked, steamed, without salt",Vegetables and Vegetable Products,2.32,0.024,0.013,1.9,0.0015,0.477,7.32,5.42,0.3,88.71,34,0.065,0.012,0.134,31199
"Sweet potato leaves, raw",Vegetables and Vegetable Products,4,0.037,0.009,2,0.011,0.518,6.38,0,0.3,87.96,35,0.065,0.012,0.134,31200
"Sweet potato, canned, mashed",Vegetables and Vegetable Products,1.98,0.03,0.075,1.7,0.0052,0.21,23.19,5.45,0.2,73.88,101,0.041,0.008,0.084,31201
"Sweet potato, canned, syrup pack, drained solids",Vegetables and Vegetable Products,1.28,0.017,0.039,3,0.0108,0.193,25.36,5.74,0.32,72.47,108,0.069,0.012,0.141,31202
"Sweet potato, canned, syrup pack, solids and liquids",Vegetables and Vegetable Products,0.98,0.015,0.044,2.5,0.0105,0.185,20.93,15.4,0.2,77.39,89,0.041,0.008,0.084,31203
"Sweet potato, canned, vacuum pack",Vegetables and Vegetable Products,1.65,0.022,0.053,1.8,0.0264,0.312,21.12,5,0.2,76.03,91,0.041,0.008,0.084,31204
"Sweet potato, cooked, baked in skin, with salt",Vegetables and Vegetable Products,2.01,0.038,0.246,3.3,0.019600000000000003,0.475,20.71,11.09,0.15,75.78,92,0.052,0.002,0.092,31205
"Sweet potato, cooked, baked in skin, without salt",Vegetables and Vegetable Products,2.01,0.038,0.036,3.3,0.019600000000000003,0.475,20.71,6.48,0.15,75.78,90,0.034,0,0.064,31206
"Sweet potato, cooked, boiled, without skin",Vegetables and Vegetable Products,1.37,0.027,0.027,2.5,0.0128,0.23,17.72,5.74,0.14,80.13,76,0.031,0,0.061,31207
"Sweet potato, cooked, boiled, without skin, with salt",Vegetables and Vegetable Products,1.37,0.027,0.263,2.5,0.0128,0.23,17.72,5.74,0.14,80.13,76,0.04,0,0.08,31208
"Sweet potato, cooked, candied, home-prepared",Vegetables and Vegetable Products,0.87,0.026,0.07,2.4,0.0067,0.189,27.86,0,3.25,66.94,144,1.35,0.627,0.147,31209
"Sweet potato, frozen, cooked, baked, with salt",Vegetables and Vegetable Products,1.71,0.035,0.244,1.8,0.0091,0.377,23.4,0,0.12,73.7,100,0.026,0.005,0.053,31210
"Sweet potato, frozen, cooked, baked, without salt",Vegetables and Vegetable Products,1.71,0.035,0.008,1.8,0.0091,0.377,23.4,9.17,0.12,73.7,100,0.025,0.005,0.049,31211
"Sweet potato, frozen, unprepared",Vegetables and Vegetable Products,1.71,0.037,0.006,1.7,0.013300000000000001,0.365,22.22,0,0.18,74.89,96,0.039,0.007,0.08,31212
"Sweet potato, raw, unprepared",Vegetables and Vegetable Products,1.57,0.03,0.055,3,0.0024,0.337,20.12,4.18,0.05,77.28,86,0.018,0,0.014,31213
"Taro leaves, cooked, steamed, without salt",Vegetables and Vegetable Products,2.72,0.086,0.002,2,0.0355,0.46,4.02,0,0.41,92.15,24,0.083,0.033,0.168,31214
"Taro leaves, raw",Vegetables and Vegetable Products,4.98,0.107,0.003,3.7,0.052,0.648,6.7,3.01,0.74,85.66,42,0.151,0.06,0.307,31215
"Taro shoots, cooked, without salt",Vegetables and Vegetable Products,0.73,0.014,0.002,0,0.0189,0.344,3.2,0,0.08,95.3,14,0.016,0.006,0.033,31216
"Taro shoots, raw",Vegetables and Vegetable Products,0.92,0.012,0.001,0,0.021,0.332,2.32,0,0.09,95.82,11,0.018,0.007,0.037,31217
"Taro, cooked, with salt",Vegetables and Vegetable Products,0.52,0.018,0.251,5.1,0.005,0.484,34.6,0.49,0.11,63.8,142,0.023,0.009,0.046,31218
"Taro, cooked, without salt",Vegetables and Vegetable Products,0.52,0.018,0.015,5.1,0.005,0.484,34.6,0.49,0.11,63.8,142,0.023,0.009,0.046,31219
"Taro, leaves, cooked, steamed, with salt",Vegetables and Vegetable Products,2.72,0.086,0.238,2,0.0355,0.46,3.89,0,0.41,92.15,24,0.083,0.033,0.168,31220
"Taro, raw",Vegetables and Vegetable Products,1.5,0.043,0.011,4.1,0.0045,0.591,26.46,0.4,0.2,70.64,112,0.041,0.016,0.083,31221
"Taro, shoots, cooked, with salt",Vegetables and Vegetable Products,0.73,0.014,0.238,0,0.0189,0.344,3.19,0,0.08,95.3,14,0.016,0.006,0.033,31222
"Taro, tahitian, cooked, with salt",Vegetables and Vegetable Products,4.16,0.149,0.29,0,0.038,0.623,6.85,0,0.68,86.46,44,0.139,0.055,0.282,31223
"Taro, tahitian, cooked, without salt",Vegetables and Vegetable Products,4.16,0.149,0.054,0,0.038,0.623,6.85,0,0.68,86.46,44,0.139,0.055,0.282,31224
"Taro, tahitian, raw",Vegetables and Vegetable Products,2.79,0.129,0.05,0,0.096,0.606,6.91,0,0.97,87.96,44,0.197,0.078,0.4,31225
"Tomatillos, raw",Vegetables and Vegetable Products,0.96,0.007,0.001,1.9,0.011699999999999999,0.268,5.84,3.93,1.02,91.63,32,0.139,0.155,0.417,31226
"Tomato and vegetable juice, low sodium",Vegetables and Vegetable Products,0.6,0.011,0.07,0.8,0.0277,0.193,4.59,3.8,0.1,93.5,22,0.014,0.016,0.042,31227
"Tomato juice, canned, with salt added",Vegetables and Vegetable Products,0.76,0.01,0.269,0.4,0.0183,0.229,4.24,3.56,0.05,93.9,17,0.008,0.009,0.024,31228
"Tomato juice, canned, without salt added",Vegetables and Vegetable Products,0.76,0.01,0.01,0.4,0.0183,0.229,4.24,3.56,0.05,93.9,17,0.008,0.009,0.024,31229
Tomato powder,Vegetables and Vegetable Products,12.91,0.166,0.134,16.5,0.1167,1.927,74.68,43.9,0.44,3.06,302,0.062,0.066,0.179,31230
"Tomato products, canned, paste, with salt added",Vegetables and Vegetable Products,4.32,0.036,0.79,4.1,0.0219,1.014,18.91,12.18,0.47,73.5,82,0.1,0.067,0.159,31231
"Tomato products, canned, paste, without salt added",Vegetables and Vegetable Products,4.32,0.036,0.098,4.1,0.0219,1.014,18.91,12.18,0.47,73.5,82,0.1,0.067,0.16,31232
"Tomato products, canned, puree, with salt added",Vegetables and Vegetable Products,1.65,0.018,0.399,1.9,0.0106,0.439,8.98,4.83,0.21,87.88,38,0.029,0.031,0.086,31233
"Tomato products, canned, puree, without salt added",Vegetables and Vegetable Products,1.65,0.018,0.028,1.9,0.0106,0.439,8.98,4.83,0.21,87.88,38,0.029,0.031,0.086,31234
"Tomato products, canned, sauce",Vegetables and Vegetable Products,1.32,0.013,0.524,1.5,0.007,0.331,5.38,4.25,0.18,91.12,24,0.025,0.027,0.073,31235
"Tomato products, canned, sauce, spanish style",Vegetables and Vegetable Products,1.44,0.017,0.472,1.4,0.0086,0.369,7.24,0,0.27,89.08,33,0.038,0.04,0.108,31236
"Tomato products, canned, sauce, with herbs and cheese",Vegetables and Vegetable Products,2.13,0.037,0.543,2.2,0.01,0.356,10.24,0,1.93,83.43,59,0.627,0.38,0.825,31237
"Tomato products, canned, sauce, with mushrooms",Vegetables and Vegetable Products,1.45,0.013,0.452,1.5,0.0124,0.38,8.43,5.77,0.13,87.97,35,0.017,0.01,0.05,31238
"Tomato products, canned, sauce, with onions",Vegetables and Vegetable Products,1.56,0.017,0.551,1.8,0.0127,0.413,9.94,0,0.19,86.09,42,0.03,0.028,0.077,31239
"Tomato products, canned, sauce, with onions, green peppers, and celery",Vegetables and Vegetable Products,0.94,0.013,0.546,1.4,0.0132,0.398,8.77,7.36,0.74,88.28,41,0.133,0.102,0.337,31240
"Tomato products, canned, sauce, with tomato tidbits",Vegetables and Vegetable Products,1.32,0.01,0.015,1.4,0.0215,0.373,7.09,0,0.39,89.09,32,0.055,0.058,0.157,31241
"Tomato sauce, no salt added",Vegetables and Vegetable Products,1.3,0.014,0.011,1.5,0.013099999999999999,0.371,8.71,5.22,0.2,89.1,42,0.028,0.029,0.079,31242
"Tomatoes, crushed, canned",Vegetables and Vegetable Products,1.64,0.034,0.132,1.9,0.0092,0.293,7.29,0,0.28,89.44,32,0.04,0.043,0.113,31243
"Tomatoes, green, raw",Vegetables and Vegetable Products,1.2,0.013,0.013,1.1,0.023399999999999997,0.204,5.1,4,0.2,93,23,0.028,0.03,0.081,31244
"Tomatoes, orange, raw",Vegetables and Vegetable Products,1.16,0.005,0.042,0.9,0.016,0.212,3.18,0,0.19,94.78,16,0.025,0.028,0.076,31245
"Tomatoes, red, ripe, canned, packed in tomato juice",Vegetables and Vegetable Products,0.78,0.031,0.143,1,0.009300000000000001,0.188,4,2.38,0.13,94.28,17,0.018,0.021,0.052,31246
"Tomatoes, red, ripe, canned, packed in tomato juice, no salt added",Vegetables and Vegetable Products,0.78,0.031,0.01,1,0.009300000000000001,0.188,4,2.38,0.13,94.28,17,0.018,0.021,0.052,31247
"Tomatoes, red, ripe, canned, stewed",Vegetables and Vegetable Products,0.91,0.034,0.221,1,0.0079,0.207,6.19,3.52,0.19,91.54,26,0.026,0.029,0.077,31248
"Tomatoes, red, ripe, canned, with green chilies",Vegetables and Vegetable Products,0.69,0.02,0.401,0,0.0062,0.107,3.62,0,0.08,94.23,15,0.011,0.011,0.03,31249
"Tomatoes, red, ripe, cooked",Vegetables and Vegetable Products,0.95,0.011,0.011,0.7,0.0228,0.218,4.01,2.49,0.11,94.34,18,0.015,0.016,0.044,31250
"Tomatoes, red, ripe, cooked, stewed",Vegetables and Vegetable Products,1.96,0.026,0.455,1.7,0.0182,0.247,13.05,0,2.68,80.63,79,0.521,1.048,0.878,31251
"Tomatoes, red, ripe, cooked, with salt",Vegetables and Vegetable Products,0.95,0.011,0.247,0.7,0.0228,0.218,4.01,2.49,0.11,94.34,18,0.015,0.016,0.044,31252
"Tomatoes, red, ripe, raw, year round average",Vegetables and Vegetable Products,0.88,0.01,0.005,1.2,0.013699999999999999,0.237,3.89,2.63,0.2,94.52,18,0.028,0.031,0.083,31253
"Tomatoes, sun-dried",Vegetables and Vegetable Products,14.11,0.11,2.095,12.3,0.039200000000000006,3.427,55.76,37.59,2.97,14.56,258,0.426,0.487,1.115,31254
"Tomatoes, sun-dried, packed in oil, drained",Vegetables and Vegetable Products,5.06,0.047,0.266,5.8,0.1018,1.565,23.33,0,14.08,53.83,213,1.893,8.663,2.06,31255
"Tomatoes, yellow, raw",Vegetables and Vegetable Products,0.98,0.011,0.023,0.7,0.009,0.258,2.98,0,0.26,95.28,15,0.036,0.04,0.108,31256
"Tree fern, cooked, with salt",Vegetables and Vegetable Products,0.29,0.008,0.241,3.7,0.03,0.005,10.78,0,0.07,88.6,40,0,0,0,31257
"Tree fern, cooked, without salt",Vegetables and Vegetable Products,0.29,0.008,0.005,3.7,0.03,0.005,10.98,0,0.07,88.6,40,0.009,0.005,0.034,31258
"Turnip greens and turnips, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.99,0.128,0.255,3.1,0.0182,0.216,4.74,0.95,0.38,91.04,34,0.085,0.024,0.16,31259
"Turnip greens and turnips, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.99,0.128,0.019,3.1,0.0182,0.216,4.85,1.07,0.38,91.04,35,0.085,0.024,0.16,31260
"Turnip greens and turnips, frozen, unprepared",Vegetables and Vegetable Products,2.46,0.114,0.018,2.4,0.0258,0.082,3.4,0,0.19,93.13,21,0.032,0.012,0.088,31261
"Turnip greens, canned, no salt added",Vegetables and Vegetable Products,1.36,0.118,0.029,1.3,0.0155,0.141,2.81,0.42,0.3,94.69,19,0.07,0.02,0.12,31262
"Turnip greens, canned, solids and liquids",Vegetables and Vegetable Products,1.36,0.118,0.277,1.7,0.0155,0.141,2.42,0,0.3,94.69,14,0.07,0.02,0.12,31263
"Turnip greens, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.14,0.137,0.265,3.5,0.027399999999999997,0.203,4.36,0.53,0.23,93.2,20,0.053,0.015,0.091,31264
"Turnip greens, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.14,0.137,0.029,3.5,0.027399999999999997,0.203,4.36,0.53,0.23,93.2,20,0.053,0.015,0.091,31265
"Turnip greens, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,3.35,0.152,0.251,3.4,0.0218,0.224,4.98,0.75,0.42,90.4,29,0.099,0.028,0.17,31266
"Turnip greens, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,3.35,0.152,0.015,3.4,0.0218,0.224,4.98,0.75,0.42,90.4,29,0.099,0.028,0.17,31267
"Turnip greens, frozen, unprepared",Vegetables and Vegetable Products,2.47,0.118,0.012,2.5,0.0268,0.184,3.67,0,0.31,92.93,22,0.073,0.02,0.125,31268
"Turnip greens, raw",Vegetables and Vegetable Products,1.5,0.19,0.04,3.2,0.06,0.296,7.13,0.81,0.3,89.67,32,0.07,0.02,0.12,31269
"Turnips, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.71,0.022,0.286,2,0.0116,0.135,5.06,2.99,0.08,93.6,22,0.008,0.005,0.042,31270
"Turnips, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.71,0.033,0.016,2,0.0116,0.177,5.06,2.99,0.08,93.6,22,0.008,0.005,0.042,31271
"Turnips, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,1.53,0.032,0.272,2,0.0039,0.182,3.76,1.76,0.24,93.6,21,0.025,0.015,0.125,31272
"Turnips, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,1.53,0.032,0.036,2,0.0039,0.182,4.35,2.35,0.24,93.6,23,0.025,0.015,0.125,31273
"Turnips, frozen, unprepared",Vegetables and Vegetable Products,1.04,0.023,0.025,1.8,0.0044,0.137,2.94,0,0.16,95.67,16,0.017,0.01,0.085,31274
"Turnips, raw",Vegetables and Vegetable Products,0.9,0.03,0.067,1.8,0.021,0.191,6.43,3.8,0.1,91.87,28,0.011,0.006,0.053,31275
"USDA Commodity, Potato wedges, frozen",Vegetables and Vegetable Products,2.7,0.015,0.049,2,0.0112,0.394,25.5,0.3,2.2,68.3,123,0.55,1.451,0.102,31276
"Vegetable juice cocktail, canned",Vegetables and Vegetable Products,0.63,0.011,0.198,0.8,0.0277,0.193,4.55,3.3,0.09,93.52,19,0.013,0.014,0.038,31277
"Vegetables, mixed (corn, lima beans, peas, green beans, carrots) canned, no salt added",Vegetables and Vegetable Products,1.4,0.021,0.026,3.1,0.0038,0.138,7.31,2.41,0.2,90.2,37,0.039,0.022,0.098,31278
"Vegetables, mixed, canned, drained solids",Vegetables and Vegetable Products,2.59,0.027,0.149,3,0.005,0.291,9.26,2.41,0.25,87.01,49,0.051,0.016,0.119,31279
"Vegetables, mixed, canned, solids and liquids",Vegetables and Vegetable Products,1.42,0.021,0.224,3.8,0.0038,0.138,7.13,0,0.25,90.24,36,0.051,0.016,0.12,31280
"Vegetables, mixed, frozen, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.86,0.025,0.271,4.4,0.0032,0.169,13.09,3.12,0.15,83.23,60,0.031,0.01,0.072,31281
"Vegetables, mixed, frozen, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.86,0.025,0.035,4.4,0.0032,0.169,13.09,3.12,0.15,83.23,65,0.031,0.01,0.072,31282
"Vegetables, mixed, frozen, unprepared",Vegetables and Vegetable Products,3.33,0.025,0.047,4,0.0104,0.212,13.46,0,0.52,82.08,64,0.098,0.031,0.235,31283
"Vinespinach, (basella), raw",Vegetables and Vegetable Products,1.8,0.109,0.024,0,0.102,0.51,3.4,0,0.3,93.1,19,0,0,0,31284
"Wasabi, root, raw",Vegetables and Vegetable Products,4.8,0.128,0.017,7.8,0.0419,0.568,23.54,0,0.63,69.11,109,0,0,0,31285
"Waterchestnuts, chinese, (matai), raw",Vegetables and Vegetable Products,1.4,0.011,0.014,3,0.004,0.584,23.94,4.8,0.1,73.46,97,0.026,0.002,0.043,31286
"Waterchestnuts, chinese, canned, solids and liquids",Vegetables and Vegetable Products,0.88,0.004,0.008,2.5,0.0013,0.118,12.3,2.46,0.06,86.42,50,0.016,0,0.026,31287
"Watercress, raw",Vegetables and Vegetable Products,2.3,0.12,0.041,0.5,0.043,0.33,1.29,0.2,0.1,95.11,11,0.027,0.008,0.035,31288
"Waxgourd, (chinese preserving melon), cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.4,0.018,0.343,1,0.0105,0.005,2.45,1.18,0.2,96.06,11,0.016,0.037,0.088,31289
"Waxgourd, (chinese preserving melon), cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.4,0.018,0.107,1,0.0105,0.005,3.04,1.18,0.2,96.06,14,0.016,0.037,0.088,31290
"Waxgourd, (chinese preserving melon), raw",Vegetables and Vegetable Products,0.4,0.019,0.111,2.9,0.013,0.006,3,0,0.2,96.1,13,0.016,0.037,0.087,31291
"Winged bean leaves, raw",Vegetables and Vegetable Products,5.85,0.224,0.009,0,0.045,0.176,14.1,0,1.1,76.85,74,0.272,0.285,0.213,31292
"Winged bean tuber, raw",Vegetables and Vegetable Products,11.6,0.03,0.035,0,0,0.586,28.1,0,0.9,57.4,148,0.222,0.234,0.174,31293
"Winged bean, immature seeds, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,5.31,0.061,0.24,0,0.009800000000000001,0.274,3.21,0,0.66,90.11,37,0.101,0.237,0.177,31294
"Winged beans, immature seeds, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,5.31,0.061,0.004,0,0.009800000000000001,0.274,3.21,0,0.66,90.11,38,0.181,0.189,0.142,31295
"Winged beans, immature seeds, raw",Vegetables and Vegetable Products,6.95,0.084,0.004,0,0.0183,0.223,4.31,0,0.87,87.04,49,0.238,0.25,0.186,31296
"Yam, cooked, boiled, drained, or baked, with salt",Vegetables and Vegetable Products,1.49,0.014,0.244,3.9,0.0121,0.67,26.99,0.49,0.14,70.13,114,0.029,0.005,0.06,31297
"Yam, cooked, boiled, drained, or baked, without salt",Vegetables and Vegetable Products,1.49,0.014,0.008,3.9,0.0121,0.67,27.48,0.49,0.14,70.13,116,0.029,0.005,0.06,31298
"Yam, raw",Vegetables and Vegetable Products,1.53,0.017,0.009,4.1,0.0171,0.816,27.88,0.5,0.17,69.6,118,0.037,0.006,0.076,31299
"Yambean (jicama), cooked, boiled, drained, with salt",Vegetables and Vegetable Products,0.72,0.011,0.242,0,0.0141,0.135,8.23,0,0.09,90.07,36,0,0,0,31300
"Yambean (jicama), cooked, boiled, drained, without salt",Vegetables and Vegetable Products,0.72,0.011,0.004,0,0.0141,0.135,8.82,0,0.09,90.07,38,0,0,0,31301
"Yambean (jicama), raw",Vegetables and Vegetable Products,0.72,0.012,0.004,4.9,0.0202,0.15,8.82,1.8,0.09,90.07,38,0.021,0.005,0.043,31302
"Yardlong bean, cooked, boiled, drained, with salt",Vegetables and Vegetable Products,2.53,0.044,0.24,0,0.0162,0.29,9.17,0,0.1,87.47,47,0.026,0.009,0.042,31303
"Yardlong bean, cooked, boiled, drained, without salt",Vegetables and Vegetable Products,2.53,0.044,0.004,0,0.0162,0.29,9.18,0,0.1,87.47,47,0.026,0.009,0.042,31304
"Yardlong bean, raw",Vegetables and Vegetable Products,2.8,0.05,0.004,0,0.0188,0.24,8.35,0,0.4,87.85,47,0.105,0.036,0.169,31305
"Yautia (tannier), raw",Vegetables and Vegetable Products,1.46,0.009,0.021,1.5,0.0052,0.598,23.63,0,0.4,73.06,98,0.082,0,0,31306
Yeast extract spread,Vegetables and Vegetable Products,27.8,0.086,3.6,3,0,2.6,11.8,0,0,37,158,0,0,0,31307
"Nuts, acorn flour, full fat",Nut and Seed Products,7.49,0.043,0,0,0,0.712,54.65,0,30.17,6,501,3.923,19.11,5.813,31308
"Nuts, acorns, dried",Nut and Seed Products,8.1,0.054,0,0,0,0.709,53.66,0,31.41,5.06,509,4.084,19.896,6.052,31309
"Nuts, acorns, raw",Nut and Seed Products,6.15,0.041,0,0,0,0.539,40.75,0,23.86,27.9,387,3.102,15.109,4.596,31310
"Nuts, almond butter, plain, with salt added",Nut and Seed Products,20.96,0.347,0.215,10.3,0,0.748,18.82,4.43,55.5,1.64,614,4.152,32.445,13.613,31311
"Nuts, almond butter, plain, without salt added",Nut and Seed Products,20.96,0.347,0.007,10.3,0,0.748,18.82,4.43,55.5,1.64,614,4.152,32.445,13.613,31312
"Nuts, almond paste",Nut and Seed Products,9,0.172,0.009,4.8,0.0001,0.314,47.81,36.25,27.74,14.08,458,2.629,18.012,5.821,31313
"Nuts, almonds",Nut and Seed Products,21.22,0.264,0.001,12.2,0,0.705,21.67,3.89,49.42,4.7,575,3.731,30.889,12.07,31314
"Nuts, almonds, blanched",Nut and Seed Products,21.4,0.236,0.019,9.9,0,0.659,18.67,4.63,52.52,4.51,590,3.953,33.415,12.368,31315
"Nuts, almonds, dry roasted, with salt added",Nut and Seed Products,21.06,0.267,0.339,10.9,0,0.712,21.2,4.93,52.05,2.53,595,4.03,32.383,12.995,31316
"Nuts, almonds, dry roasted, without salt added",Nut and Seed Products,21.06,0.267,0.003,10.9,0,0.712,21.2,4.93,52.05,2.53,595,4.03,32.383,12.995,31317
"Nuts, almonds, honey roasted, unblanched",Nut and Seed Products,18.17,0.263,0.13,13.7,0.0007,0.56,27.9,0,49.9,1.7,594,4.73,32.402,10.472,31318
"Nuts, almonds, oil roasted, with salt added",Nut and Seed Products,21.23,0.291,0.339,10.5,0,0.699,17.68,4.55,55.17,2.8,607,4.208,34.793,13.519,31319
"Nuts, almonds, oil roasted, without salt added",Nut and Seed Products,21.23,0.291,0.001,10.5,0,0.699,17.68,4.55,55.17,2.8,607,4.208,34.793,13.519,31320
"Nuts, beechnuts, dried",Nut and Seed Products,6.2,0.001,0.038,0,0.0155,1.017,33.5,0,50,6.6,576,5.719,21.892,20.089,31321
"Nuts, brazilnuts, dried, unblanched",Nut and Seed Products,14.32,0.16,0.003,7.5,0.0007,0.659,12.27,2.33,66.43,3.48,656,15.137,24.548,20.577,31322
"Nuts, butternuts, dried",Nut and Seed Products,24.9,0.053,0.001,4.7,0.0032,0.421,12.05,0,56.98,3.34,612,1.306,10.425,42.741,31323
"Nuts, cashew butter, plain, with salt added",Nut and Seed Products,17.56,0.043,0.614,2,0,0.546,27.57,5.01,49.41,2.96,587,9.763,29.122,8.354,31324
"Nuts, cashew butter, plain, without salt added",Nut and Seed Products,17.56,0.043,0.015,2,0,0.546,27.57,0,49.41,2.96,587,9.763,29.122,8.354,31325
"Nuts, cashew nuts, dry roasted, with salt added",Nut and Seed Products,15.31,0.045,0.64,3,0,0.565,32.69,5.01,46.35,1.7,574,9.157,27.317,7.836,31326
"Nuts, cashew nuts, dry roasted, without salt added",Nut and Seed Products,15.31,0.045,0.016,3,0,0.565,32.69,5.01,46.35,1.7,574,9.157,27.317,7.836,31327
"Nuts, cashew nuts, oil roasted, with salt added",Nut and Seed Products,16.84,0.043,0.308,3.3,0.0003,0.632,30.16,5.01,47.77,2.34,581,8.478,25.923,8.546,31328
"Nuts, cashew nuts, oil roasted, without salt added",Nut and Seed Products,16.84,0.043,0.013,3.3,0.0003,0.632,29.87,5.01,47.77,3.48,580,8.478,25.923,8.546,31329
"Nuts, cashew nuts, raw",Nut and Seed Products,18.22,0.037,0.012,3.3,0.0005,0.66,30.19,5.91,43.85,5.2,553,7.783,23.797,7.845,31330
"Nuts, chestnuts, chinese, boiled and steamed",Nut and Seed Products,2.88,0.012,0.002,0,0.0247,0.306,33.64,0,0.76,61.57,153,0.112,0.399,0.197,31331
"Nuts, chestnuts, chinese, dried",Nut and Seed Products,6.82,0.029,0.005,0,0.0585,0.726,79.76,0,1.81,8.9,363,0.266,0.945,0.468,31332
"Nuts, chestnuts, chinese, raw",Nut and Seed Products,4.2,0.018,0.003,0,0.036,0.447,49.07,0,1.11,43.95,224,0.164,0.581,0.288,31333
"Nuts, chestnuts, chinese, roasted",Nut and Seed Products,4.48,0.019,0.004,0,0.0384,0.477,52.36,0,1.19,40.2,239,0.175,0.62,0.307,31334
"Nuts, chestnuts, european, boiled and steamed",Nut and Seed Products,2,0.046,0.027,0,0.026699999999999998,0.715,27.76,0,1.38,68.15,131,0.26,0.476,0.545,31335
"Nuts, chestnuts, european, dried, peeled",Nut and Seed Products,5.01,0.064,0.037,0,0.015099999999999999,0.991,78.43,0,3.91,9,369,0.736,1.349,1.546,31336
"Nuts, chestnuts, european, dried, unpeeled",Nut and Seed Products,6.39,0.067,0.037,11.7,0.015,0.986,77.31,0,4.45,9.45,374,0.837,1.535,1.758,31337
"Nuts, chestnuts, european, raw, peeled",Nut and Seed Products,1.63,0.019,0.002,0,0.0402,0.484,44.17,0,1.25,52,196,0.235,0.43,0.493,31338
"Nuts, chestnuts, european, raw, unpeeled",Nut and Seed Products,2.42,0.027,0.003,8.1,0.043,0.518,45.54,0,2.26,48.65,213,0.425,0.78,0.894,31339
"Nuts, chestnuts, european, roasted",Nut and Seed Products,3.17,0.029,0.002,5.1,0.026,0.592,52.96,10.6,2.2,40.48,245,0.414,0.759,0.869,31340
"Nuts, chestnuts, japanese, boiled and steamed",Nut and Seed Products,0.82,0.011,0.005,0,0.0095,0.119,12.64,0,0.19,86.03,56,0.028,0.101,0.05,31341
"Nuts, chestnuts, japanese, dried",Nut and Seed Products,5.25,0.072,0.034,0,0.0613,0.768,81.43,0,1.24,9.96,360,0.183,0.65,0.322,31342
"Nuts, chestnuts, japanese, raw",Nut and Seed Products,2.25,0.031,0.014,0,0.0263,0.329,34.91,0,0.53,61.41,154,0.078,0.278,0.138,31343
"Nuts, chestnuts, japanese, roasted",Nut and Seed Products,2.97,0.035,0.019,0,0.028,0.427,45.13,0,0.8,49.9,201,0.118,0.419,0.207,31344
"Nuts, coconut cream, canned, sweetened",Nut and Seed Products,1.17,0.004,0.036,0.2,0,0.101,53.21,51.5,16.31,28.97,357,15.472,0.679,0.159,31345
"Nuts, coconut cream, raw (liquid expressed from grated meat)",Nut and Seed Products,3.63,0.011,0.004,2.2,0.0028,0.325,6.65,0,34.68,53.9,330,30.753,1.475,0.379,31346
"Nuts, coconut meat, dried (desiccated), creamed",Nut and Seed Products,5.3,0.026,0.037,0,0.0015,0.551,21.52,0,69.08,1.67,684,61.257,2.939,0.756,31347
"Nuts, coconut meat, dried (desiccated), not sweetened",Nut and Seed Products,6.88,0.026,0.037,16.3,0.0015,0.543,23.65,7.35,64.53,3,660,57.218,2.745,0.706,31348
"Nuts, coconut meat, dried (desiccated), sweetened, flaked, canned",Nut and Seed Products,3.35,0.014,0.02,4.5,0,0.324,40.91,0,31.69,23.27,443,28.101,1.348,0.347,31349
"Nuts, coconut meat, dried (desiccated), sweetened, flaked, packaged",Nut and Seed Products,3.13,0.011,0.285,9.9,0,0.361,51.85,36.75,27.99,15.46,456,26.396,1.377,0.222,31350
"Nuts, coconut meat, dried (desiccated), sweetened, shredded",Nut and Seed Products,2.88,0.015,0.262,4.5,0.0007,0.337,47.67,43.17,35.49,12.55,501,31.468,1.51,0.388,31351
"Nuts, coconut meat, dried (desiccated), toasted",Nut and Seed Products,5.3,0.027,0.037,0,0.0015,0.554,44.4,0,47,1,592,41.678,1.999,0.514,31352
"Nuts, coconut meat, raw",Nut and Seed Products,3.33,0.014,0.02,9,0.0033,0.356,15.23,6.23,33.49,46.99,354,29.698,1.425,0.366,31353
"Nuts, coconut milk, canned (liquid expressed from grated meat and water)",Nut and Seed Products,2.02,0.018,0.013,0,0.001,0.22,2.81,0,21.33,72.88,197,18.915,0.907,0.233,31354
"Nuts, coconut milk, frozen (liquid expressed from grated meat and water)",Nut and Seed Products,1.61,0.004,0.012,0,0.0011,0.232,5.58,0,20.8,71.42,202,18.445,0.885,0.228,31355
"Nuts, coconut milk, raw (liquid expressed from grated meat and water)",Nut and Seed Products,2.29,0.016,0.015,2.2,0.0028,0.263,5.54,3.34,23.84,67.62,230,21.14,1.014,0.261,31356
"Nuts, coconut water (liquid from coconuts)",Nut and Seed Products,0.72,0.024,0.105,1.1,0.0024,0.25,3.71,2.61,0.2,94.99,19,0.176,0.008,0.002,31357
"Nuts, formulated, wheat-based, all flavors except macadamia, without salt",Nut and Seed Products,13.11,0.022,0.091,5.2,0.0001,0.32,20.79,0,62.3,2,647,9.37,25.722,24.284,31358
"Nuts, formulated, wheat-based, flavored, macadamia flavored, without salt",Nut and Seed Products,11.19,0.02,0.047,5.2,0.0001,0.261,27.91,0,56.5,3.1,619,8.482,23.521,21.867,31359
"Nuts, formulated, wheat-based, unflavored, with salt added",Nut and Seed Products,13.82,0.026,0.505,5.2,0.0001,0.318,23.68,0,57.7,2.5,622,8.701,23.545,22.714,31360
"Nuts, ginkgo nuts, canned",Nut and Seed Products,2.29,0.004,0.307,9.3,0.0091,0.18,22.1,0,1.62,72.95,111,0.309,0.599,0.597,31361
"Nuts, ginkgo nuts, dried",Nut and Seed Products,10.35,0.02,0.013,0,0.0293,0.998,72.45,0,2,12.4,348,0.381,0.739,0.737,31362
"Nuts, ginkgo nuts, raw",Nut and Seed Products,4.32,0.002,0.007,0,0.015,0.51,37.6,0,1.68,55.2,182,0.319,0.619,0.618,31363
"Nuts, hazelnuts or filberts",Nut and Seed Products,14.95,0.114,0,9.7,0.0063,0.68,16.7,4.34,60.75,5.31,628,4.464,45.652,7.92,31364
"Nuts, hazelnuts or filberts, blanched",Nut and Seed Products,13.7,0.149,0,11,0.002,0.658,17,3.49,61.15,5.79,629,4.669,48.192,5.558,31365
"Nuts, hazelnuts or filberts, dry roasted, without salt added",Nut and Seed Products,15.03,0.123,0,9.4,0.0038,0.755,17.6,4.89,62.4,2.52,646,4.511,46.608,8.463,31366
"Nuts, hickorynuts, dried",Nut and Seed Products,12.72,0.061,0.001,6.4,0.002,0.436,18.25,0,64.37,2.65,657,7.038,32.611,21.886,31367
"Nuts, macadamia nuts, dry roasted, with salt added",Nut and Seed Products,7.79,0.07,0.265,8,0.0007,0.363,12.83,4.14,76.08,1.61,716,11.947,59.275,1.498,31368
"Nuts, macadamia nuts, dry roasted, without salt added",Nut and Seed Products,7.79,0.07,0.004,8,0.0007,0.363,13.38,4.14,76.08,1.61,718,11.947,59.275,1.498,31369
"Nuts, macadamia nuts, raw",Nut and Seed Products,7.91,0.085,0.005,8.6,0.0012,0.368,13.82,4.57,75.77,1.36,718,12.061,58.877,1.502,31370
"Nuts, mixed nuts, dry roasted, with peanuts, with salt added",Nut and Seed Products,17.3,0.07,0.669,9,0.0004,0.597,25.35,4.64,51.45,1.75,594,6.899,31.395,10.768,31371
"Nuts, mixed nuts, dry roasted, with peanuts, without salt added",Nut and Seed Products,17.3,0.07,0.012,9,0.0004,0.597,25.35,0,51.45,1.75,594,6.899,31.395,10.768,31372
"Nuts, mixed nuts, oil roasted, with peanuts, without salt added",Nut and Seed Products,16.76,0.108,0.011,9.9,0.0005,0.581,21.41,0,56.33,2.03,617,8.725,31.695,13.299,31373
"Nuts, mixed nuts, oil roasted, without peanuts, without salt added",Nut and Seed Products,15.52,0.106,0.011,5.5,0.0005,0.544,22.27,0,56.17,3.15,615,9.087,33.137,11.449,31374
"Nuts, mixed nuts, with peanuts, oil roasted, with salt added",Nut and Seed Products,16.76,0.108,0.419,9,0.0005,0.581,21.41,4.28,56.33,2.03,617,8.725,31.695,13.299,31375
"Nuts, mixed nuts, without peanuts, oil roasted, with salt added",Nut and Seed Products,15.52,0.106,0.306,5.5,0.0005,0.544,22.27,4.38,56.17,3.15,615,9.087,33.137,11.449,31376
"Nuts, pecans",Nut and Seed Products,9.17,0.07,0,9.6,0.0011,0.41,13.86,3.97,71.97,3.52,691,6.18,40.801,21.614,31377
"Nuts, pecans, dry roasted, with salt added",Nut and Seed Products,9.5,0.072,0.383,9.4,0.0007,0.424,13.55,4.06,74.27,1.12,710,6.283,43.957,20.572,31378
"Nuts, pecans, dry roasted, without salt added",Nut and Seed Products,9.5,0.072,0.001,9.4,0.0007,0.424,13.55,4.06,74.27,1.12,710,6.283,43.957,20.572,31379
"Nuts, pecans, oil roasted, with salt added",Nut and Seed Products,9.2,0.067,0.393,9.5,0.0007,0.392,13.01,3.97,75.23,1.13,715,7.238,40.97,23.584,31380
"Nuts, pecans, oil roasted, without salt added",Nut and Seed Products,9.2,0.067,0.001,9.5,0.0007,0.392,13.01,3.97,75.23,1.13,715,7.238,40.97,23.584,31381
"Nuts, pilinuts, dried",Nut and Seed Products,10.8,0.145,0.003,0,0.0006,0.507,3.98,0,79.55,2.77,719,31.184,37.229,7.605,31382
"Nuts, pine nuts, dried",Nut and Seed Products,13.69,0.016,0.002,3.7,0.0008,0.597,13.08,3.59,68.37,2.28,673,4.899,18.764,34.071,31383
"Nuts, pine nuts, pinyon, dried",Nut and Seed Products,11.57,0.008,0.072,10.7,0.002,0.628,19.3,0,60.98,5.9,629,9.377,22.942,25.668,31384
"Nuts, pistachio nuts, dry roasted, with salt added",Nut and Seed Products,20.95,0.107,0.428,9.9,0.003,1.007,28.66,7.74,44.82,1.79,564,5.456,23.676,13.448,31385
"Nuts, pistachio nuts, dry roasted, without salt added",Nut and Seed Products,20.95,0.107,0.006,9.9,0.003,1.007,29.38,7.74,44.82,1.85,567,5.456,23.676,13.448,31386
"Nuts, pistachio nuts, raw",Nut and Seed Products,20.27,0.105,0.001,10.3,0.0056,1.025,27.51,7.66,45.39,3.91,562,5.556,23.82,13.744,31387
"Nuts, walnuts, black, dried",Nut and Seed Products,24.06,0.061,0.002,6.8,0.0017,0.523,9.91,1.1,59,4.56,618,3.368,15.004,35.077,31388
"Nuts, walnuts, english",Nut and Seed Products,15.23,0.098,0.002,6.7,0.0013,0.441,13.71,2.61,65.21,4.07,654,6.126,8.933,47.174,31389
"Seeds, breadfruit seeds, boiled",Nut and Seed Products,5.3,0.061,0.023,4.8,0.0060999999999999995,0.875,32,0,2.3,59.3,168,0.621,0.293,1.225,31390
"Seeds, breadfruit seeds, raw",Nut and Seed Products,7.4,0.036,0.025,5.2,0.0066,0.941,29.24,0,5.59,56.27,191,1.509,0.712,2.977,31391
"Seeds, breadfruit seeds, roasted",Nut and Seed Products,6.2,0.086,0.028,6,0.0076,1.082,40.1,0,2.7,49.7,207,0.729,0.344,1.438,31392
"Seeds, breadnut tree seeds, dried",Nut and Seed Products,8.62,0.094,0.053,14.9,0.0466,2.011,79.39,0,1.68,6.5,367,0.454,0.214,0.896,31393
"Seeds, breadnut tree seeds, raw",Nut and Seed Products,5.97,0.098,0.031,0,0.027399999999999997,1.183,46.28,0,0.99,45,217,0.267,0.126,0.527,31394
"Seeds, chia seeds, dried",Nut and Seed Products,15.62,0.631,0.019,37.7,0,0.16,43.85,0,30.75,4.9,490,3.176,2.115,23.335,31395
"Seeds, cottonseed flour, low fat (glandless)",Nut and Seed Products,49.83,0.474,0.035,0,0.0024,1.761,36.1,0,1.41,6.9,332,0.31,0.221,0.577,31396
"Seeds, cottonseed flour, partially defatted (glandless)",Nut and Seed Products,40.96,0.478,0.035,3,0.0024,1.772,40.54,0,6.2,6.3,359,1.588,1.133,2.958,31397
"Seeds, cottonseed kernels, roasted (glandless)",Nut and Seed Products,32.59,0.1,0.025,5.5,0.009,1.35,21.9,0,36.29,4.65,506,9.699,6.919,18.068,31398
"Seeds, cottonseed meal, partially defatted (glandless)",Nut and Seed Products,49.1,0.504,0.037,0,0.0025,1.869,38.43,0,4.77,1.2,367,1.207,0.861,2.248,31399
"Seeds, flaxseed",Nut and Seed Products,18.29,0.255,0.03,27.3,0.0006,0.813,28.88,1.55,42.16,6.96,534,3.663,7.527,28.73,31400
"Seeds, lotus seeds, dried",Nut and Seed Products,15.41,0.163,0.005,0,0,1.368,64.47,0,1.97,14.16,332,0.33,0.388,1.166,31401
"Seeds, lotus seeds, raw",Nut and Seed Products,4.13,0.044,0.001,0,0,0.367,17.28,0,0.53,77,89,0.088,0.104,0.312,31402
"Seeds, pumpkin and squash seed kernels, dried",Nut and Seed Products,30.23,0.046,0.007,6,0.0019,0.809,10.71,1.4,49.05,5.23,559,8.659,16.242,20.976,31403
"Seeds, pumpkin and squash seed kernels, roasted, with salt added",Nut and Seed Products,29.84,0.052,0.256,6.5,0.0018,0.788,14.71,1.29,49.05,2.03,574,8.544,15.734,19.856,31404
"Seeds, pumpkin and squash seed kernels, roasted, without salt",Nut and Seed Products,29.84,0.052,0.018,6.5,0.0018,0.788,14.71,1.29,49.05,2.03,574,8.544,15.734,19.856,31405
"Seeds, pumpkin and squash seeds, whole, roasted, with salt added",Nut and Seed Products,18.55,0.055,2.541,18.4,0.0003,0.919,53.75,0,19.4,4.5,446,3.67,6.032,8.844,31406
"Seeds, pumpkin and squash seeds, whole, roasted, without salt",Nut and Seed Products,18.55,0.055,0.018,18.4,0.0003,0.919,53.75,0,19.4,4.5,446,3.67,6.032,8.844,31407
"Seeds, safflower seed kernels, dried",Nut and Seed Products,16.18,0.078,0.003,0,0,0.687,34.29,0,38.45,5.62,517,3.682,4.848,28.223,31408
"Seeds, safflower seed meal, partially defatted",Nut and Seed Products,35.62,0.077,0.003,0,0,0.068,48.73,0,2.39,6.41,342,0.207,0.272,1.584,31409
"Seeds, sesame butter, paste",Nut and Seed Products,18.08,0.96,0.012,5.5,0,0.582,24.05,0,50.87,1.6,586,7.124,19.209,22.296,31410
"Seeds, sesame butter, tahini, from raw and stone ground kernels",Nut and Seed Products,17.81,0.42,0.074,9.3,0,0.414,26.19,0,48,3,570,6.722,18.127,21.039,31411
"Seeds, sesame butter, tahini, from roasted and toasted kernels (most common type)",Nut and Seed Products,17,0.426,0.115,9.3,0,0.414,21.19,0.49,53.76,3.05,595,7.529,20.302,23.564,31412
"Seeds, sesame butter, tahini, from unroasted kernels (non-chemically removed seed coat)",Nut and Seed Products,17.95,0.141,0.001,9.3,0,0.459,17.89,0,56.44,3,607,7.904,21.313,24.737,31413
"Seeds, sesame butter, tahini, type of kernels unspecified",Nut and Seed Products,17.4,0.141,0.035,4.7,0.004200000000000001,0.459,21.5,0,53.01,3,592,7.423,20.016,23.232,31414
"Seeds, sesame flour, high-fat",Nut and Seed Products,30.78,0.159,0.041,0,0,0.423,26.62,0,37.1,0.9,526,5.196,14.01,16.261,31415
"Seeds, sesame flour, low-fat",Nut and Seed Products,50.14,0.149,0.039,0,0,0.397,35.51,0,1.75,7.1,333,0.201,0.543,0.63,31416
"Seeds, sesame flour, partially defatted",Nut and Seed Products,40.32,0.15,0.041,0,0,0.425,35.14,0,11.89,6.61,382,1.634,4.405,5.113,31417
"Seeds, sesame meal, partially defatted",Nut and Seed Products,16.96,0.153,0.039,0,0,0.406,26.04,0,48,5,567,6.722,18.127,21.039,31418
"Seeds, sesame seed kernels, dried (decorticated)",Nut and Seed Products,20.45,0.06,0.047,11.6,0,0.37,11.73,0.48,61.21,3.75,631,9.055,23.924,25.491,31419
"Seeds, sesame seed kernels, toasted, with salt added (decorticated)",Nut and Seed Products,16.96,0.131,0.588,16.9,0,0.406,26.04,0.48,48,5,567,6.722,18.127,21.039,31420
"Seeds, sesame seed kernels, toasted, without salt added (decorticated)",Nut and Seed Products,16.96,0.131,0.039,16.9,0,0.406,26.04,0.48,48,5,567,6.722,18.127,21.039,31421
"Seeds, sesame seeds, whole, dried",Nut and Seed Products,17.73,0.975,0.011,11.8,0,0.468,23.45,0.3,49.67,4.69,573,6.957,18.759,21.773,31422
"Seeds, sesame seeds, whole, roasted and toasted",Nut and Seed Products,16.96,0.989,0.011,14,0,0.475,25.74,0,48,3.3,565,6.722,18.127,21.039,31423
"Seeds, sisymbrium sp. seeds, whole, dried",Nut and Seed Products,12.14,1.633,0.092,0,0.030699999999999998,2.13,58.26,0,4.6,6.1,318,0.902,1.486,2.023,31424
"Seeds, sunflower seed butter, with salt added",Nut and Seed Products,17.28,0.064,0.331,5.7,0.0027,0.576,23.32,10.54,55.2,0.62,617,4.678,39.025,9.805,31425
"Seeds, sunflower seed butter, without salt",Nut and Seed Products,17.28,0.064,0.003,5.7,0.0027,0.576,23.32,10.54,55.2,0.62,617,4.678,39.025,9.805,31426
"Seeds, sunflower seed flour, partially defatted",Nut and Seed Products,48.06,0.114,0.003,5.2,0.0013,0.067,35.83,0,1.61,7.47,326,0.138,0.252,0.87,31427
"Seeds, sunflower seed kernels, dried",Nut and Seed Products,20.78,0.078,0.009,8.6,0.0014,0.645,20,2.62,51.46,4.73,584,4.455,18.528,23.137,31428
"Seeds, sunflower seed kernels, dry roasted, with salt added",Nut and Seed Products,19.33,0.07,0.41,9,0.0014,0.85,24.07,2.73,49.8,1.2,582,5.219,9.505,32.884,31429
"Seeds, sunflower seed kernels, dry roasted, without salt",Nut and Seed Products,19.33,0.07,0.003,11.1,0.0014,0.85,24.07,2.73,49.8,1.2,582,5.219,9.505,32.884,31430
"Seeds, sunflower seed kernels, oil roasted, with salt added",Nut and Seed Products,20.06,0.087,0.41,10.6,0.0011,0.483,22.89,3.11,51.3,1.54,592,7.068,8.063,34.294,31431
"Seeds, sunflower seed kernels, oil roasted, without salt",Nut and Seed Products,20.06,0.087,0.003,10.6,0.0011,0.483,22.89,3.11,51.3,1.54,592,7.068,8.063,34.294,31432
"Seeds, sunflower seed kernels, toasted, with salt added",Nut and Seed Products,17.21,0.057,0.613,11.5,0.0014,0.491,20.59,0,56.8,1,619,5.953,10.841,37.507,31433
"Seeds, sunflower seed kernels, toasted, without salt",Nut and Seed Products,17.21,0.057,0.003,11.5,0.0014,0.491,20.59,0,56.8,1,619,5.953,10.841,37.507,31434
"Seeds, watermelon seed kernels, dried",Nut and Seed Products,28.33,0.054,0.099,0,0,0.648,15.31,0,47.37,5.05,557,9.779,7.407,28.094,31435
"Beef, bottom sirloin, tri-tip roast, separable lean and fat, trimmed to 0"" fat, all grades, cooked, roasted",Beef Products,26.05,0.019,0.053,0,0,0.323,0,0,11.07,62.63,211,4.071,5.481,0.363,31436
"Beef, bottom sirloin, tri-tip roast, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,20.64,0.025,0.052,0,0,0.317,0,0,8.55,70.08,165,3.14,4.246,0.394,31437
"Beef, bottom sirloin, tri-tip roast, separable lean and fat, trimmed to 0"" fat, choice, cooked, roasted",Beef Products,25.66,0.017,0.05,0,0,0.308,0,0,12.36,61.82,221,4.546,6.121,0.406,31438
"Beef, bottom sirloin, tri-tip roast, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,20.64,0.025,0.051,0,0,0.313,0,0,9.51,69.37,174,3.493,4.724,0.438,31439
"Beef, bottom sirloin, tri-tip roast, separable lean and fat, trimmed to 0"" fat, select, cooked, roasted",Beef Products,26.44,0.021,0.056,0,0,0.338,0,0,9.78,63.45,201,3.595,4.841,0.321,31440
"Beef, bottom sirloin, tri-tip roast, separable lean and fat, trimmed to 0"" fat, select, raw",Beef Products,20.64,0.024,0.053,0,0,0.321,0,0,7.68,70.73,157,2.822,3.816,0.354,31441
"Beef, bottom sirloin, tri-tip roast, separable lean only, trimmed to 0"" fat, all grades, raw",Beef Products,21.26,0.023,0.054,0,0,0.333,0,0,5.63,72.41,142,1.729,2.47,0.234,31442
"Beef, bottom sirloin, tri-tip roast, separable lean only, trimmed to 0"" fat, choice, cooked, roasted",Beef Products,26.34,0.015,0.054,0,0,0.324,0,0,9.73,63.79,193,3.527,4.907,0.312,31443
"Beef, bottom sirloin, tri-tip roast, separable lean only, trimmed to 0"" fat, choice, raw",Beef Products,21.17,0.027,0.054,0,0,0.323,0,0,7.06,71.33,154,2.132,3.045,0.288,31444
"Beef, bottom sirloin, tri-tip roast, separable lean only, trimmed to 0"" fat, select, cooked, roasted",Beef Products,27.17,0.02,0.058,0,0,0.359,0,0,6.95,65.55,179,2.517,3.502,0.223,31445
"Beef, bottom sirloin, tri-tip roast, separable lean only, trimmed to 0"" fat, select, raw",Beef Products,21.34,0.022,0.054,0,0,0.344,0,0,4.21,73.48,129,1.5,2.144,0.203,31446
"Beef, brisket, flat half, boneless separable lean only, trimmed to 0"" fat, all grades, raw",Beef Products,21.47,0.013,0.083,0,0,0.362,0,0,5.11,72.7,132,1.844,2.728,0.253,31447
"Beef, brisket, flat half, boneless, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,20.32,0.013,0.08,0,0,0.344,0,0,9.29,69.89,165,3.592,4.586,0.691,31448
"Beef, brisket, flat half, boneless, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,20.15,0.013,0.079,0,0,0.349,0,0,9.86,69.47,169,3.704,4.772,0.701,31449
"Beef, brisket, flat half, boneless, separable lean and fat, trimmed to 0"" fat, select, raw",Beef Products,20.57,0.012,0.082,0,0,0.336,0,0,8.45,70.52,158,3.424,4.307,0.677,31450
"Beef, brisket, flat half, boneless, separable lean only, trimmed to 0"" fat, choice, raw",Beef Products,21.28,0.013,0.082,0,0,0.367,0,0,5.75,72.21,137,1.993,2.96,0.262,31451
"Beef, brisket, flat half, boneless, separable lean only, trimmed to 0"" fat, select, raw",Beef Products,21.74,0.013,0.085,0,0,0.353,0,0,4.14,73.43,124,1.621,2.38,0.241,31452
"Beef, brisket, flat half, separable lean and fat, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,32.9,0.019,0.054,0,0,0.264,0,0,8.01,58.9,213,3.152,3.444,0.296,31453
"Beef, brisket, flat half, separable lean and fat, trimmed to 0"" fat, choice, cooked, braised",Beef Products,32.21,0.017,0.052,0,0,0.254,0,0,9.24,58.42,221,3.638,3.975,0.341,31454
"Beef, brisket, flat half, separable lean and fat, trimmed to 0"" fat, select, cooked, braised",Beef Products,33.59,0.02,0.057,0,0,0.275,0,0,6.77,59.38,205,2.666,2.913,0.25,31455
"Beef, brisket, flat half, separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, braised",Beef Products,28.82,0.016,0.048,0,0,0.232,0,0,18.42,52.48,289,7.305,7.934,0.681,31456
"Beef, brisket, flat half, separable lean and fat, trimmed to 1/8"" fat, all grades, raw",Beef Products,17.94,0.016,0.059,0,0,0.276,0,0,22.18,59.07,277,8.951,9.498,0.844,31457
"Beef, brisket, flat half, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, braised",Beef Products,28.66,0.016,0.046,0,0,0.227,0,0,19.47,51.58,298,8.219,8.388,0.72,31458
"Beef, brisket, flat half, separable lean and fat, trimmed to 1/8"" fat, choice, raw",Beef Products,18.12,0.015,0.061,0,0,0.281,0.12,0,22.15,58.8,278,8.939,9.485,0.843,31459
"Beef, brisket, flat half, separable lean and fat, trimmed to 1/8"" fat, select, cooked, braised",Beef Products,28.97,0.017,0.049,0,0,0.237,0,0,17.37,53.39,280,6.888,7.481,0.642,31460
"Beef, brisket, flat half, separable lean and fat, trimmed to 1/8"" fat, select, raw",Beef Products,17.77,0.017,0.058,0,0,0.271,0,0,22.21,59.34,276,8.963,9.511,0.845,31461
"Beef, brisket, flat half, separable lean only, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,33.26,0.016,0.054,0,0,0.267,0,0,6.99,59.55,205,2.647,2.978,0.244,31462
"Beef, brisket, flat half, separable lean only, trimmed to 0"" fat, choice, cooked, braised",Beef Products,32.62,0.014,0.052,0,0,0.256,0,0,8.07,59.18,212,3.053,3.436,0.282,31463
"Beef, brisket, flat half, separable lean only, trimmed to 0"" fat, select, cooked, braised",Beef Products,33.9,0.017,0.056,0,0,0.279,0,0,5.92,59.92,198,2.241,2.521,0.207,31464
"Beef, brisket, flat half, separable lean only, trimmed to 1/8"" fat, all grades, cooked, braised",Beef Products,33.15,0.016,0.054,0,0,0.266,0,0,6,60.61,196,2.274,2.554,0.207,31465
"Beef, brisket, flat half, separable lean only, trimmed to 1/8"" fat, all grades, raw",Beef Products,21.57,0.016,0.074,0,0,0.336,0,0,3.84,73.76,127,1.425,1.611,0.166,31466
"Beef, brisket, flat half, separable lean only, trimmed to 1/8"" fat, choice, cooked, braised",Beef Products,33.13,0.014,0.053,0,0,0.261,0,0,6.79,59.82,203,2.572,2.889,0.235,31467
"Beef, brisket, flat half, separable lean only, trimmed to 1/8"" fat, choice, raw",Beef Products,21.69,0.015,0.075,0,0,0.335,0,0,4.06,73.27,129,1.508,1.705,0.176,31468
"Beef, brisket, flat half, separable lean only, trimmed to 1/8"" fat, select, cooked, braised",Beef Products,33.18,0.017,0.055,0,0,0.273,0,0,5.21,61.39,189,1.973,2.216,0.18,31469
"Beef, brisket, flat half, separable lean only, trimmed to 1/8"" fat, select, raw",Beef Products,21.45,0.018,0.076,0,0,0.333,0,0,3.61,74.26,124,1.34,1.514,0.156,31470
"Beef, brisket, point half, separable lean and fat, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,23.53,0.008,0.068,0,0,0.233,0,0,28.5,46.05,358,11.24,12.7,0.96,31471
"Beef, brisket, point half, separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, braised",Beef Products,24.4,0.008,0.069,0,0,0.241,0,0,27.17,48.3,349,10.64,12.21,0.88,31472
"Beef, brisket, point half, separable lean and fat, trimmed to 1/8"" fat, all grades, raw",Beef Products,17.65,0.006,0.072,0,0,0.264,0,0,20.98,59.96,265,8.42,9.53,0.69,31473
"Beef, brisket, point half, separable lean only, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,28.05,0.006,0.077,0,0,0.273,0,0,13.8,55.7,244,5.18,6.46,0.36,31474
"Beef, brisket, whole, separable lean and fat, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,26.79,0.007,0.065,0,0,0.259,0,0,19.52,51.54,291,7.53,8.68,0.67,31475
"Beef, brisket, whole, separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, braised",Beef Products,25.85,0.007,0.064,0,0,0.251,0,0,24.5,49.58,331,9.44,10.89,0.85,31476
"Beef, brisket, whole, separable lean and fat, trimmed to 1/8"" fat, all grades, raw",Beef Products,18.42,0.006,0.069,0,0,0.282,0,0,19.06,61.08,251,7.53,8.52,0.66,31477
"Beef, brisket, whole, separable lean only, all grades, raw",Beef Products,20.72,0.005,0.079,0,0,0.33,0,0,7.37,70.29,155,2.59,3.46,0.23,31478
"Beef, brisket, whole, separable lean only, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,29.75,0.006,0.07,0,0,0.285,0,0,10.08,57.71,218,3.63,4.65,0.3,31479
"Beef, carcass, separable lean and fat, choice, raw",Beef Products,17.32,0.008,0.059,0,0,0.267,0,0,24.05,57.26,291,9.75,10.47,0.92,31480
"Beef, carcass, separable lean and fat, select, raw",Beef Products,17.48,0.008,0.059,0,0,0.271,0,0,22.55,58.21,278,9.16,9.82,0.86,31481
"Beef, chuck eye Country-Style ribs, boneless, separable lean and fat, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,27.69,0.016,0.065,0,0,0.247,0,0,20.55,51.91,296,9.009,9.997,1.566,31482
"Beef, chuck eye Country-Style ribs, boneless, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,18.97,0.014,0.077,0,0,0.291,0,0,14.32,66.33,205,6.154,6.957,1.069,31483
"Beef, chuck eye Country-Style ribs, boneless, separable lean and fat, trimmed to 0"" fat, choice, cooked, braised",Beef Products,27.16,0.016,0.065,0,0,0.241,0,0,21.56,51.36,303,9.268,10.359,1.554,31484
"Beef, chuck eye Country-Style ribs, boneless, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,18.87,0.015,0.076,0,0,0.302,0,0,14.99,65.78,210,6.387,7.274,1.085,31485
"Beef, chuck eye Country-Style ribs, boneless, separable lean and fat, trimmed to 0"" fat, select, cooked, braised",Beef Products,28.47,0.015,0.066,0,0,0.255,0,0,19.03,52.74,285,8.621,9.454,1.584,31486
"Beef, chuck eye Country-Style ribs, boneless, separable lean and fat, trimmed to 0"" fat, select, raw",Beef Products,19.1,0.014,0.077,0,0,0.271,0,0,13.33,67.17,196,5.805,6.482,1.045,31487
"Beef, chuck eye Country-Style ribs, boneless, separable lean only, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,31.41,0.017,0.069,0,0,0.286,0,0,11.43,57,228,5.269,6.067,0.58,31488
"Beef, chuck eye Country-Style ribs, boneless, separable lean only, trimmed to 0"" fat, all grades, raw",Beef Products,20.96,0.015,0.082,0,0,0.317,0,0,6.95,71.19,146,3.126,3.676,0.366,31489
"Beef, chuck eye Country-Style ribs, boneless, separable lean only, trimmed to 0"" fat, choice, cooked, braised",Beef Products,30.95,0.017,0.069,0,0,0.279,0,0,12.22,56.64,234,5.447,6.366,0.564,31490
"Beef, chuck eye Country-Style ribs, boneless, separable lean only, trimmed to 0"" fat, choice, raw",Beef Products,20.87,0.015,0.081,0,0,0.33,0,0,7.67,70.58,152,3.419,4.071,0.378,31491
"Beef, chuck eye Country-Style ribs, boneless, separable lean only, trimmed to 0"" fat, select, cooked, braised",Beef Products,32.1,0.016,0.07,0,0,0.292,0,0,10.24,57.54,221,5.002,5.618,0.605,31492
"Beef, chuck eye Country-Style ribs, boneless, separable lean only, trimmed to 0"" fat, select, raw",Beef Products,21.1,0.015,0.082,0,0,0.293,0,0,5.89,72.11,137,2.686,3.084,0.347,31493
"Beef, chuck eye roast, boneless, America's Beef Roast, separable lean and fat, trimmed to 0"" fat, all grades, cooked, roasted",Beef Products,24.63,0.018,0.076,0,0,0.308,0,0,15.29,60.3,236,6.354,7.243,1.168,31494
"Beef, chuck eye roast, boneless, America's Beef Roast, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,19.18,0.017,0.081,0,0,0.332,0,0,11.48,68.8,180,5.042,5.837,0.931,31495
"Beef, chuck eye roast, boneless, America's Beef Roast, separable lean and fat, trimmed to 0"" fat, choice, cooked, roasted",Beef Products,24.47,0.018,0.075,0,0,0.307,0,0,15.87,59.63,241,6.462,7.509,1.147,31496
"Beef, chuck eye roast, boneless, America's Beef Roast, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,19.14,0.017,0.08,0,0,0.31,0,0,12.02,68.26,185,5.072,5.908,0.933,31497
"Beef, chuck eye roast, boneless, America's Beef Roast, separable lean and fat, trimmed to 0"" fat, select, cooked, roasted",Beef Products,24.86,0.019,0.076,0,0,0.312,0,0,14.41,61.29,229,6.192,6.843,1.2,31498
"Beef, chuck eye roast, boneless, America's Beef Roast, separable lean and fat, trimmed to 0"" fat, select, raw",Beef Products,19.25,0.018,0.082,0,0,0.367,0,0,10.67,69.61,173,4.998,5.732,0.928,31499
"Beef, chuck eye roast, boneless, America's Beef Roast, separable lean only, trimmed to 0"" fat, all grades, cooked, roasted",Beef Products,26.65,0.019,0.08,0,0,0.344,0,0,8.46,64.79,183,3.422,4.167,0.426,31500
"Beef, chuck eye roast, boneless, America's Beef Roast, separable lean only, trimmed to 0"" fat, all grades, raw",Beef Products,20.61,0.018,0.085,0,0,0.357,0,0,6.01,72.48,137,2.733,3.35,0.345,31501
"Beef, chuck eye roast, boneless, America's Beef Roast, separable lean only, trimmed to 0"" fat, choice, cooked, roasted",Beef Products,26.41,0.018,0.079,0,0,0.339,0,0,9.36,63.88,190,3.751,4.691,0.431,31502
"Beef, chuck eye roast, boneless, America's Beef Roast, separable lean only, trimmed to 0"" fat, choice, raw",Beef Products,20.67,0.017,0.085,0,0,0.332,0,0,6.21,72.16,139,2.644,3.299,0.336,31503
"Beef, chuck eye roast, boneless, America's Beef Roast, separable lean only, trimmed to 0"" fat, select, cooked, roasted",Beef Products,27.02,0.02,0.081,0,0,0.351,0,0,7.12,66.16,172,2.929,3.38,0.418,31504
"Beef, chuck eye roast, boneless, America's Beef Roast, separable lean only, trimmed to 0"" fat, select, raw",Beef Products,20.52,0.019,0.086,0,0,0.393,0,0,5.71,72.96,133,2.867,3.427,0.359,31505
"Beef, chuck eye steak, boneless, separable lean and fat, trimmed to 0"" fat, all grades, cooked, grilled",Beef Products,24.98,0.016,0.071,0,0,0.325,0,0,19.64,55.22,277,8.66,9.457,1.428,31506
"Beef, chuck eye steak, boneless, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,18.86,0.014,0.066,0,0,0.329,0,0,16.35,64.48,223,6.963,7.739,1.177,31507
"Beef, chuck eye steak, boneless, separable lean and fat, trimmed to 0"" fat, choice, cooked, grilled",Beef Products,24.95,0.015,0.071,0,0,0.333,0,0,20.35,54.74,283,8.677,9.479,1.409,31508
"Beef, chuck eye steak, boneless, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,18.86,0.014,0.066,0,0,0.33,0,0,16.85,63.98,227,7.176,7.978,1.149,31509
"Beef, chuck eye steak, boneless, separable lean and fat, trimmed to 0"" fat, select, cooked, grilled",Beef Products,25.03,0.017,0.07,0,0,0.307,0,0,18.57,55.93,267,8.633,9.422,1.457,31510
"Beef, chuck eye steak, boneless, separable lean and fat, trimmed to 0"" fat, select, raw",Beef Products,18.86,0.014,0.068,0,0,0.342,0,0,15.6,65.24,216,6.644,7.381,1.219,31511
"Beef, chuck eye steak, boneless, separable lean only, trimmed to 0"" fat, all grades, cooked, grilled",Beef Products,27.94,0.017,0.075,0,0,0.38,0,0,10.79,60.79,209,4.938,5.503,0.497,31512
"Beef, chuck eye steak, boneless, separable lean only, trimmed to 0"" fat, all grades, raw",Beef Products,21.29,0.015,0.07,0,0,0.371,0,0,7.56,70.25,153,3.267,3.71,0.372,31513
"Beef, chuck eye steak, boneless, separable lean only, trimmed to 0"" fat, choice, cooked, grilled",Beef Products,27.97,0.016,0.075,0,0,0.391,0,0,11.47,60.35,215,4.895,5.461,0.478,31514
"Beef, chuck eye steak, boneless, separable lean only, trimmed to 0"" fat, choice, raw",Beef Products,21.31,0.015,0.07,0,0,0.371,0,0,8.29,69.48,160,3.605,4.088,0.359,31515
"Beef, chuck eye steak, boneless, separable lean only, trimmed to 0"" fat, select, cooked, grilled",Beef Products,27.88,0.019,0.074,0,0,0.356,0,0,9.77,61.45,199,5.004,5.566,0.526,31516
"Beef, chuck eye steak, boneless, separable lean only, trimmed to 0"" fat, select, raw",Beef Products,21.28,0.015,0.073,0,0,0.388,0,0,6.47,71.41,143,2.759,3.143,0.392,31517
"Beef, chuck for stew, separable lean and fat, all grades, cooked, braised",Beef Products,32.41,0.016,0.067,0,0,0.319,0,0,6.82,60.08,191,2.894,3.646,0.467,31518
"Beef, chuck for stew, separable lean and fat, all grades, raw",Beef Products,21.75,0.013,0.08,0,0,0.367,0.16,0,4.48,72.51,128,1.941,2.425,0.306,31519
"Beef, chuck for stew, separable lean and fat, choice, cooked, braised",Beef Products,32.49,0.017,0.065,0,0,0.315,0,0,7.14,59.49,194,3.147,3.968,0.489,31520
"Beef, chuck for stew, separable lean and fat, choice, raw",Beef Products,21.64,0.013,0.079,0,0,0.373,0.12,0,4.81,72.32,130,2.07,2.602,0.318,31521
"Beef, chuck for stew, separable lean and fat, select, cooked, braised",Beef Products,32.29,0.015,0.068,0,0,0.32,0,0,6.34,60.97,186,2.514,3.164,0.432,31522
"Beef, chuck for stew, separable lean and fat, select, raw",Beef Products,21.9,0.014,0.081,0,0,0.359,0.21,0,3.99,72.79,124,1.748,2.16,0.287,31523
"Beef, chuck, arm pot roast, separable lean and fat, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,28.94,0.016,0.047,0,0,0.231,0,0,19.17,51.9,297,7.548,8.175,0.708,31524
"Beef, chuck, arm pot roast, separable lean and fat, trimmed to 0"" fat, select, cooked, braised",Beef Products,29.23,0.017,0.048,0,0,0.235,0,0,17.56,53.44,283,6.911,7.551,0.649,31525
"Beef, chuck, arm pot roast, separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, braised",Beef Products,30.12,0.017,0.05,0,0,0.242,0,0,19.22,50.45,302,7.62,8.276,0.71,31526
"Beef, chuck, arm pot roast, separable lean and fat, trimmed to 1/8"" fat, all grades, raw",Beef Products,19.23,0.017,0.062,0,0,0.29,0,0,17.98,62.33,244,7.254,7.697,0.684,31527
"Beef, chuck, arm pot roast, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, braised",Beef Products,30.2,0.016,0.049,0,0,0.241,0,0,19.93,49.35,309,7.902,8.583,0.737,31528
"Beef, chuck, arm pot roast, separable lean and fat, trimmed to 1/8"" fat, choice, raw",Beef Products,19.14,0.016,0.063,0,0,0.29,0,0,18.57,61.78,249,7.492,7.95,0.706,31529
"Beef, chuck, arm pot roast, separable lean and fat, trimmed to 1/8"" fat, select, cooked, braised",Beef Products,30.05,0.018,0.05,0,0,0.243,0,0,18.5,51.56,295,7.337,7.969,0.684,31530
"Beef, chuck, arm pot roast, separable lean and fat, trimmed to 1/8"" fat, select, raw",Beef Products,19.33,0.018,0.062,0,0,0.29,0,0,17.39,62.88,239,7.016,7.444,0.661,31531
"Beef, chuck, arm pot roast, separable lean only, trimmed to 0"" fat, choice, cooked, braised",Beef Products,33.36,0.014,0.054,0,0,0.262,0,0,7.67,58.83,212,2.903,3.267,0.268,31532
"Beef, chuck, arm pot roast, separable lean only, trimmed to 0"" fat, select, cooked, braised",Beef Products,33.37,0.017,0.055,0,0,0.27,0,0,5.8,61.2,195,2.194,2.469,0.202,31533
"Beef, chuck, arm pot roast, separable lean only, trimmed to 1/8"" fat, all grades, cooked, braised",Beef Products,34.66,0.016,0.056,0,0,0.277,0,0,7.36,57.84,214,2.788,3.132,0.254,31534
"Beef, chuck, arm pot roast, separable lean only, trimmed to 1/8"" fat, all grades, raw",Beef Products,22.11,0.016,0.074,0,0,0.337,0,0,4.19,73.32,132,1.553,1.756,0.181,31535
"Beef, chuck, arm pot roast, separable lean only, trimmed to 1/8"" fat, choice, cooked, braised",Beef Products,34.72,0.015,0.056,0,0,0.275,0,0,8.37,56.39,224,3.17,3.561,0.289,31536
"Beef, chuck, arm pot roast, separable lean only, trimmed to 1/8"" fat, choice, raw",Beef Products,21.96,0.015,0.074,0,0,0.332,0,0,5.05,72.51,139,1.875,2.119,0.218,31537
"Beef, chuck, arm pot roast, separable lean only, trimmed to 1/8"" fat, select, cooked, braised",Beef Products,34.6,0.017,0.057,0,0,0.281,0,0,6.35,59.29,205,2.406,2.703,0.22,31538
"Beef, chuck, arm pot roast, separable lean only, trimmed to 1/8"" fat, select, raw",Beef Products,22.26,0.018,0.077,0,0,0.339,0,0,3.32,74.12,125,1.232,1.392,0.143,31539
"Beef, chuck, blade roast, separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, braised",Beef Products,26.78,0.013,0.065,0,0,0.233,0,0,25.12,47.67,341,10,10.86,0.9,31540
"Beef, chuck, blade roast, separable lean and fat, trimmed to 1/8"" fat, all grades, raw",Beef Products,17.16,0.01,0.068,0,0,0.271,0,0,19.41,61.23,248,7.82,8.52,0.71,31541
"Beef, chuck, blade roast, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, braised",Beef Products,26.37,0.013,0.064,0,0,0.23,0,0,27.26,46.32,359,10.86,11.78,0.97,31542
"Beef, chuck, blade roast, separable lean and fat, trimmed to 1/8"" fat, choice, raw",Beef Products,16.98,0.01,0.067,0,0,0.267,0,0,21.31,59.93,265,8.59,9.36,0.78,31543
"Beef, chuck, blade roast, separable lean and fat, trimmed to 1/8"" fat, select, cooked, braised",Beef Products,27.33,0.013,0.066,0,0,0.237,0,0,22.35,49.4,318,8.89,9.66,0.8,31544
"Beef, chuck, blade roast, separable lean and fat, trimmed to 1/8"" fat, select, raw",Beef Products,17.37,0.01,0.069,0,0,0.276,0,0,17.33,62.83,230,6.99,7.6,0.63,31545
"Beef, chuck, blade roast, separable lean only, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,31.06,0.013,0.071,0,0,0.263,0,0,13.3,54.8,253,5.16,5.73,0.44,31546
"Beef, chuck, clod roast, separable lean and fat, trimmed to 0"" fat, all grades, cooked, roasted",Beef Products,25.7,0.007,0.071,0,0,0.367,0,0,10.84,61.09,207,3.904,5.179,0.455,31547
"Beef, chuck, clod roast, separable lean and fat, trimmed to 0"" fat, choice, cooked, roasted",Beef Products,24.61,0.008,0.071,0,0,0.363,0,0,12.26,60.38,216,4.285,5.87,0.461,31548
"Beef, chuck, clod roast, separable lean and fat, trimmed to 0"" fat, select, cooked, roasted",Beef Products,27.3,0.007,0.072,0,0,0.372,0,0,8.76,62.13,196,3.349,4.173,0.446,31549
"Beef, chuck, clod roast, separable lean only, trimmed to 0"" fat, all grades, cooked, roasted",Beef Products,26.82,0.007,0.074,0,0,0.385,0,0,6.34,64.32,172,2.037,3.27,0.287,31550
"Beef, chuck, clod roast, separable lean only, trimmed to 0"" fat, choice, cooked, roasted",Beef Products,25.95,0.007,0.074,0,0,0.386,0,0,6.69,64.39,171,1.96,3.51,0.25,31551
"Beef, chuck, clod roast, separable lean only, trimmed to 0"" fat, select, cooked, roasted",Beef Products,28.09,0.007,0.074,0,0,0.384,0,0,5.82,64.21,172,2.15,2.92,0.34,31552
"Beef, chuck, clod roast, separable lean only, trimmed to 1/4"" fat, all grades, cooked, roasted",Beef Products,26.36,0.007,0.071,0,0,0.372,0,0,6.75,64.92,173,2.144,3.294,0.29,31553
"Beef, chuck, clod roast, separable lean only, trimmed to 1/4"" fat, all grades, raw",Beef Products,19.63,0.006,0.064,0,0,0.354,0,0,5.02,73.41,129,1.414,1.965,0.286,31554
"Beef, chuck, clod steak, separable lean only, trimmed to 1/4"" fat, all grades, cooked, braised",Beef Products,29.34,0.008,0.06,0,0,0.287,0,0,7.04,60.02,189,2.271,3.45,0.308,31555
"Beef, chuck, mock tender steak, boneless, separable lean and fat, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,32.07,0.014,0.067,0,0,0.298,0,0,10.18,57.29,220,3.638,4.225,1.044,31556
"Beef, chuck, mock tender steak, boneless, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,21.13,0.011,0.08,0,0,0.343,0,0,4.79,73.3,128,2.059,2.457,0.674,31557
"Beef, chuck, mock tender steak, boneless, separable lean and fat, trimmed to 0"" fat, choice, cooked, braised",Beef Products,32.21,0.015,0.066,0,0,0.303,0,0,10.74,56.78,225,3.888,4.521,1.066,31558
"Beef, chuck, mock tender steak, boneless, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,21.19,0.011,0.082,0,0,0.347,0,0,5.18,72.83,131,2.114,2.576,0.649,31559
"Beef, chuck, mock tender steak, boneless, separable lean and fat, trimmed to 0"" fat, select, cooked, braised",Beef Products,31.86,0.015,0.069,0,0,0.288,0,0,9.34,58.05,211,3.263,3.782,1.012,31560
"Beef, chuck, mock tender steak, boneless, separable lean and fat, trimmed to 0"" fat, select, raw",Beef Products,21.05,0.011,0.076,0,0,0.341,0,0,4.2,74.01,122,1.976,2.279,0.713,31561
"Beef, chuck, mock tender steak, boneless, separable lean only, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,33.31,0.015,0.068,0,0,0.309,0,0,6.34,59.82,190,2.52,3.043,0.511,31562
"Beef, chuck, mock tender steak, boneless, separable lean only, trimmed to 0"" fat, all grades, raw",Beef Products,21.3,0.011,0.081,0,0,0.346,0,0,4.17,73.71,123,1.804,2.181,0.359,31563
"Beef, chuck, mock tender steak, boneless, separable lean only, trimmed to 0"" fat, choice, cooked, braised",Beef Products,33.55,0.015,0.067,0,0,0.315,0,0,6.94,59.17,197,2.708,3.275,0.522,31564
"Beef, chuck, mock tender steak, boneless, separable lean only, trimmed to 0"" fat, choice, raw",Beef Products,21.36,0.011,0.083,0,0,0.349,0,0,4.6,73.2,127,1.884,2.328,0.348,31565
"Beef, chuck, mock tender steak, boneless, separable lean only, trimmed to 0"" fat, select, cooked, braised",Beef Products,32.96,0.015,0.07,0,0,0.298,0,0,5.44,60.8,181,2.238,2.695,0.493,31566
"Beef, chuck, mock tender steak, boneless, separable lean only, trimmed to 0"" fat, select, raw",Beef Products,21.22,0.011,0.076,0,0,0.344,0,0,3.53,74.46,117,1.684,1.961,0.374,31567
"Beef, chuck, mock tender steak, separable lean and fat, trimmed to 0"" fat, USDA choice, cooked, broiled",Beef Products,25.73,0.008,0.073,0,0,0.293,0,0,5.72,65.57,161,1.721,2.721,0.401,31568
"Beef, chuck, mock tender steak, separable lean and fat, trimmed to 0"" fat, USDA select, cooked, broiled",Beef Products,26.08,0.007,0.068,0,0,0.293,0,0,5.24,67.09,159,2.09,2.555,0.368,31569
"Beef, chuck, mock tender steak, separable lean and fat, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,25.87,0.008,0.071,0,0,0.293,0,0,5.52,66.19,160,1.871,2.654,0.387,31570
"Beef, chuck, mock tender steak, separable lean only, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,25.9,0.008,0.071,0,0,0.293,0,0,5.42,66.27,159,1.828,2.608,0.384,31571
"Beef, chuck, mock tender steak, separable lean only, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,25.74,0.008,0.073,0,0,0.293,0,0,5.69,65.59,161,1.71,2.71,0.4,31572
"Beef, chuck, mock tender steak, separable lean only, trimmed to 0"" fat, select, cooked, broiled",Beef Products,26.13,0.007,0.068,0,0,0.293,0,0,5.02,67.26,157,2,2.46,0.36,31573
"Beef, chuck, short ribs, boneless, separable lean and fat, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,25.48,0.014,0.07,0,0,0.239,0,0,22.58,52.01,305,10.04,11.436,1.732,31574
"Beef, chuck, short ribs, boneless, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,17.48,0.012,0.078,0,0,0.277,0,0,18.33,63.84,235,7.979,9.206,1.286,31575
"Beef, chuck, short ribs, boneless, separable lean and fat, trimmed to 0"" fat, choice, cooked, braised",Beef Products,25.26,0.014,0.07,0,0,0.228,0,0,24.05,50.69,317,10.638,12.282,1.793,31576
"Beef, chuck, short ribs, boneless, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,17.22,0.011,0.075,0,0,0.282,0,0,19.03,63.23,240,8.141,9.532,1.278,31577
"Beef, chuck, short ribs, boneless, separable lean and fat, trimmed to 0"" fat, select, cooked, braised",Beef Products,25.81,0.014,0.071,0,0,0.269,0,0,20.38,53.99,287,9.144,10.166,1.641,31578
"Beef, chuck, short ribs, boneless, separable lean and fat, trimmed to 0"" fat, select, raw",Beef Products,17.87,0.012,0.085,0,0,0.273,0,0,17.28,64.76,227,7.738,8.716,1.297,31579
"Beef, chuck, short ribs, boneless, separable lean only, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,28.82,0.014,0.075,0,0,0.276,0,0,13.8,57.05,240,6.493,7.799,0.684,31580
"Beef, chuck, short ribs, boneless, separable lean only, trimmed to 0"" fat, all grades, raw",Beef Products,19.68,0.012,0.085,0,0,0.307,0.05,0,10.02,69.33,169,4.477,5.479,0.442,31581
"Beef, chuck, short ribs, boneless, separable lean only, trimmed to 0"" fat, choice, cooked, braised",Beef Products,28.84,0.014,0.075,0,0,0.264,0,0,14.95,55.94,250,6.968,8.576,0.705,31582
"Beef, chuck, short ribs, boneless, separable lean only, trimmed to 0"" fat, choice, raw",Beef Products,19.38,0.011,0.081,0,0,0.313,0.29,0,10.7,68.73,175,4.691,5.9,0.44,31583
"Beef, chuck, short ribs, boneless, separable lean only, trimmed to 0"" fat, select, cooked, braised",Beef Products,28.79,0.014,0.075,0,0,0.308,0,0,12.08,58.71,224,5.78,6.635,0.654,31584
"Beef, chuck, short ribs, boneless, separable lean only, trimmed to 0"" fat, select, raw",Beef Products,20.14,0.012,0.093,0,0,0.303,0,0,8.99,70.24,161,4.156,4.848,0.446,31585
"Beef, chuck, shoulder clod, shoulder tender, medallion, separable lean and fat, trimmed to 0"" fat, all grades, cooked, grilled",Beef Products,26.22,0.005,0.059,0,0,0.356,0,0,7.2,65.65,177,2.833,2.933,0.41,31586
"Beef, chuck, shoulder clod, shoulder tender, medallion, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,20.54,0.005,0.058,0,0,0.327,0,0,6.22,72.8,144,2.088,2.136,0.34,31587
"Beef, chuck, shoulder clod, shoulder tender, medallion, separable lean and fat, trimmed to 0"" fat, choice, cooked, grilled",Beef Products,26.07,0.005,0.06,0,0,0.361,0,0,7.68,65.57,181,2.737,2.872,0.381,31588
"Beef, chuck, shoulder clod, shoulder tender, medallion, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,20.51,0.005,0.059,0,0,0.339,0,0,6.36,72.87,145,2.328,2.43,0.326,31589
"Beef, chuck, shoulder clod, shoulder tender, medallion, separable lean and fat, trimmed to 0"" fat, select, cooked, grilled",Beef Products,26.45,0.005,0.058,0,0,0.348,0,0,6.43,65.79,172,1.7,3.284,0.49,31590
"Beef, chuck, shoulder clod, shoulder tender, medallion, separable lean and fat, trimmed to 0"" fat, select, raw",Beef Products,20.93,0.005,0.06,0,0,0.366,0,0,5.79,72.79,142,1.988,1.979,0.337,31591
"Beef, chuck, shoulder clod, shoulder top and center steaks, separable lean and fat, trimmed to 0"" fat, all grades, cooked, grilled",Beef Products,26.3,0.005,0.06,0,0,0.361,0,0,7.66,64.88,182,2.765,3.044,0.395,31592
"Beef, chuck, shoulder clod, shoulder top and center steaks, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,20.67,0.004,0.06,0,0,0.355,0,0,5.88,72.51,141,1.985,2.937,0.397,31593
"Beef, chuck, shoulder clod, shoulder top and center steaks, separable lean and fat, trimmed to 0"" fat, choice, cooked, grilled",Beef Products,26.07,0.005,0.059,0,0,0.342,0,0,8.11,65.02,184,3.031,3.38,0.405,31594
"Beef, chuck, shoulder clod, shoulder top and center steaks, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,20.39,0.004,0.061,0,0,0.347,0,0,6.12,72.49,143,2.083,3.439,0.554,31595
"Beef, chuck, shoulder clod, shoulder top and center steaks, separable lean and fat, trimmed to 0"" fat, select, cooked, grilled",Beef Products,26.66,0.005,0.062,0,0,0.401,0,0,6.94,64.65,176,2.235,2.373,0.376,31596
"Beef, chuck, shoulder clod, shoulder top and center steaks, separable lean and fat, trimmed to 0"" fat, select, raw",Beef Products,21.13,0.004,0.058,0,0,0.37,0,0,5.51,72.54,140,1.791,1.933,0.341,31597
"Beef, chuck, shoulder clod, top blade, steak, separable lean and fat, trimmed to 0"" fat, all grades, cooked, grilled",Beef Products,24.93,0.006,0.077,0,0,0.322,0,0,12.79,62.04,222,5.095,5.33,0.563,31598
"Beef, chuck, shoulder clod, top blade, steak, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,18.99,0.006,0.074,0,0,0.309,0,0,10.52,70.46,176,3.945,4.144,0.475,31599
"Beef, chuck, shoulder clod, top blade, steak, separable lean and fat, trimmed to 0"" fat, choice, cooked, grilled",Beef Products,24.7,0.006,0.078,0,0,0.317,0,0,13.59,61.93,228,5.432,5.711,0.558,31600
"Beef, chuck, shoulder clod, top blade, steak, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,18.75,0.005,0.074,0,0,0.302,0,0,11.33,70,182,4.186,4.408,0.463,31601
"Beef, chuck, shoulder clod, top blade, steak, separable lean and fat, trimmed to 0"" fat, select, cooked, grilled",Beef Products,25.29,0.006,0.076,0,0,0.333,0,0,11.52,62.22,212,4.423,4.568,0.572,31602
"Beef, chuck, shoulder clod, top blade, steak, separable lean and fat, trimmed to 0"" fat, select, raw",Beef Products,19.38,0.006,0.075,0,0,0.324,0,0,9.22,71.19,166,3.464,3.616,0.498,31603
"Beef, chuck, top blade, separable lean and fat, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,25.73,0.007,0.067,0,0,0.299,0,0,11.73,61.57,216,3.906,5.425,0.429,31604
"Beef, chuck, top blade, separable lean and fat, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,25.77,0.007,0.068,0,0,0.3,0,0,12.93,60.69,227,4.162,6.269,0.459,31605
"Beef, chuck, top blade, separable lean and fat, trimmed to 0"" fat, select, cooked, broiled",Beef Products,25.67,0.007,0.067,0,0,0.298,0,0,9.99,62.86,200,3.533,4.195,0.385,31606
"Beef, chuck, top blade, separable lean only, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,26.13,0.007,0.068,0,0,0.304,0,0,10.16,62.72,203,3.25,4.753,0.369,31607
"Beef, chuck, top blade, separable lean only, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,26.11,0.007,0.068,0,0,0.304,0,0,11.65,61.63,217,3.62,5.73,0.41,31608
"Beef, chuck, top blade, separable lean only, trimmed to 0"" fat, select, cooked, broiled",Beef Products,26.16,0.007,0.068,0,0,0.304,0,0,8,64.32,184,2.71,3.33,0.31,31609
"Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean and fat, trimmed to 0"" fat, all grades, cooked, grilled",Beef Products,26.18,0.013,0.073,0,0,0.31,0.21,0,13.54,59.08,227,5.692,6.809,1.405,31610
"Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,18.99,0.011,0.076,0,0,0.315,0.36,0,11.64,68.11,182,4.831,5.643,1.037,31611
"Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean and fat, trimmed to 0"" fat, choice, cooked, grilled",Beef Products,26.1,0.012,0.073,0,0,0.313,0.4,0,14.41,58.12,236,5.963,7.02,1.433,31612
"Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,18.85,0.011,0.075,0,0,0.312,0.53,0,12.4,67.31,189,5.137,6.04,1.104,31613
"Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean and fat, trimmed to 0"" fat, select, cooked, grilled",Beef Products,26.3,0.016,0.073,0,0,0.301,0,0,12.25,60.52,215,5.285,6.491,1.363,31614
"Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean and fat, trimmed to 0"" fat, select, raw",Beef Products,19.2,0.011,0.076,0,0,0.32,0.09,0,10.5,69.3,172,4.37,5.047,0.936,31615
"Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean only, trimmed to 0"" fat, all grades, cooked, grilled",Beef Products,26.5,0.014,0.073,0,0,0.314,0.14,0,12.64,59.72,220,5.28,6.384,0.761,31616
"Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean only, trimmed to 0"" fat, all grades, raw",Beef Products,19.42,0.011,0.077,0,0,0.321,0.49,0,9.99,69.19,170,4.132,4.889,0.526,31617
"Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean only, trimmed to 0"" fat, choice, cooked, grilled",Beef Products,26.49,0.012,0.073,0,0,0.318,0.28,0,13.42,58.83,228,5.565,6.607,0.777,31618
"Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean only, trimmed to 0"" fat, choice, raw",Beef Products,19.23,0.012,0.076,0,0,0.317,0.67,0,10.96,68.24,178,4.588,5.45,0.576,31619
"Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean only, trimmed to 0"" fat, select, cooked, grilled",Beef Products,26.53,0.016,0.074,0,0,0.306,0,0,11.46,61.06,209,4.853,6.049,0.736,31620
"Beef, chuck, under blade center steak, boneless, Denver Cut, separable lean only, trimmed to 0"" fat, select, raw",Beef Products,19.71,0.011,0.077,0,0,0.329,0.21,0,8.54,70.62,157,3.449,4.048,0.452,31621
"Beef, chuck, under blade pot roast or steak, boneless, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,19.17,0.012,0.076,0,0,0.336,0,0,13.26,66.7,196,5.726,6.568,1.02,31622
"Beef, chuck, under blade pot roast or steak, boneless, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,19.15,0.012,0.076,0,0,0.329,0,0,13.93,66,202,5.896,6.794,1.04,31623
"Beef, chuck, under blade pot roast or steak, boneless, separable lean and fat, trimmed to 0"" fat, select, raw",Beef Products,19.2,0.012,0.077,0,0,0.347,0,0,12.27,67.76,187,5.473,6.229,0.989,31624
"Beef, chuck, under blade pot roast or steak, boneless, separable lean only, trimmed to 0"" fat, all grades, raw",Beef Products,21.13,0.012,0.081,0,0,0.369,0.31,0,6.07,71.41,140,2.728,3.333,0.34,31625
"Beef, chuck, under blade pot roast or steak, boneless, separable lean only, trimmed to 0"" fat, choice, raw",Beef Products,21.19,0.012,0.08,0,0,0.361,0.39,0,6.51,70.82,145,2.833,3.493,0.346,31626
"Beef, chuck, under blade pot roast or steak, boneless, separable lean only, trimmed to 0"" fat, select, raw",Beef Products,21.03,0.012,0.081,0,0,0.38,0.19,0,5.41,72.3,134,2.572,3.091,0.329,31627
"Beef, chuck, under blade pot roast, boneless, separable lean and fat, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,26.7,0.014,0.062,0,0,0.262,0,0,20.49,52.51,291,8.261,9.478,1.502,31628
"Beef, chuck, under blade pot roast, boneless, separable lean and fat, trimmed to 0"" fat, choice, cooked, braised",Beef Products,26.39,0.014,0.061,0,0,0.262,0,0,21.48,51.93,306,8.532,9.954,1.5,31629
"Beef, chuck, under blade pot roast, boneless, separable lean and fat, trimmed to 0"" fat, select, cooked, braised",Beef Products,27.17,0.014,0.064,0,0,0.261,0,0,19.02,53.39,288,7.854,8.763,1.505,31630
"Beef, chuck, under blade pot roast, boneless, separable lean only, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,30.54,0.014,0.065,0,0,0.306,0,0,10.46,58.22,216,4.002,5.068,0.519,31631
"Beef, chuck, under blade pot roast, boneless, separable lean only, trimmed to 0"" fat, choice, cooked, braised",Beef Products,30.45,0.014,0.063,0,0,0.307,0,0,11.14,57.85,231,4.344,5.67,0.519,31632
"Beef, chuck, under blade pot roast, boneless, separable lean only, trimmed to 0"" fat, select, cooked, braised",Beef Products,30.68,0.014,0.067,0,0,0.306,0,0,9.44,58.79,216,3.487,4.164,0.52,31633
"Beef, chuck, under blade steak, boneless, separable lean and fat, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,28.23,0.014,0.065,0,0,0.273,0,0,18,53.8,275,7.256,8.325,1.319,31634
"Beef, chuck, under blade steak, boneless, separable lean and fat, trimmed to 0"" fat, choice, cooked, braised",Beef Products,27.6,0.014,0.064,0,0,0.275,0,0,19.3,52.71,284,7.666,8.944,1.347,31635
"Beef, chuck, under blade steak, boneless, separable lean and fat, trimmed to 0"" fat, select, cooked, braised",Beef Products,29.18,0.014,0.066,0,0,0.27,0,0,16.05,55.43,261,6.629,7.397,1.271,31636
"Beef, chuck, under blade steak, boneless, separable lean only, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,31.35,0.014,0.065,0,0,0.308,0,0,10.41,58.11,219,3.981,5.041,0.516,31637
"Beef, chuck, under blade steak, boneless, separable lean only, trimmed to 0"" fat, choice, cooked, braised",Beef Products,30.91,0.014,0.065,0,0,0.312,0,0,10.91,57.56,222,4.253,5.551,0.508,31638
"Beef, chuck, under blade steak, boneless, separable lean only, trimmed to 0"" fat, select, cooked, braised",Beef Products,32.01,0.014,0.066,0,0,0.303,0,0,9.66,58.92,215,3.567,4.259,0.531,31639
"Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 0"" fat, all grades, cooked",Beef Products,27.33,0.009,0.062,0,0,0.323,0,0,17.37,53.93,273,6.85,7.41,0.63,31640
"Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 0"" fat, choice, cooked",Beef Products,27.21,0.009,0.062,0,0,0.322,0,0,18.54,53.22,283,7.31,7.91,0.67,31641
"Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 0"" fat, select, cooked",Beef Products,27.47,0.009,0.063,0,0,0.325,0,0,15.92,54.84,261,6.27,6.78,0.57,31642
"Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8"" fat, all grades, cooked",Beef Products,26.42,0.009,0.063,0,0,0.319,0,0,19.71,52.77,291,7.77,8.46,0.71,31643
"Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8"" fat, all grades, raw",Beef Products,18.68,0.007,0.058,0,0,0.306,0,0,17.15,62.02,234,6.91,7.42,0.63,31644
"Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8"" fat, choice, cooked",Beef Products,26.21,0.009,0.062,0,0,0.317,0,0,20.91,52.03,301,8.24,8.97,0.75,31645
"Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8"" fat, choice, raw",Beef Products,18.57,0.007,0.057,0,0,0.304,0,0,18.19,61.34,243,7.32,7.88,0.67,31646
"Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8"" fat, prime, cooked",Beef Products,26.22,0.009,0.063,0,0,0.356,0,0,20.75,52.87,299,8.32,8.84,0.75,31647
"Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8"" fat, prime, raw",Beef Products,18.72,0.007,0.054,0,0,0.308,0,0,20.49,59.75,265,8.37,8.87,0.76,31648
"Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8"" fat, select, cooked",Beef Products,26.63,0.009,0.063,0,0,0.321,0,0,18.21,53.73,278,7.2,7.81,0.66,31649
"Beef, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8"" fat, select, raw",Beef Products,18.87,0.007,0.058,0,0,0.309,0,0,15.75,63.03,223,6.37,6.82,0.58,31650
"Beef, composite of trimmed retail cuts, separable lean only, trimmed to 0"" fat, all grades, cooked",Beef Products,29.88,0.008,0.066,0,0,0.355,0,0,9.28,59.33,211,3.54,3.9,0.32,31651
"Beef, composite of trimmed retail cuts, separable lean only, trimmed to 0"" fat, choice, cooked",Beef Products,29.88,0.008,0.066,0,0,0.355,0,0,10.15,58.83,219,3.87,4.26,0.35,31652
"Beef, composite of trimmed retail cuts, separable lean only, trimmed to 0"" fat, select, cooked",Beef Products,29.89,0.008,0.066,0,0,0.354,0,0,8.09,60.05,201,3.09,3.4,0.28,31653
"Beef, cured, breakfast strips, cooked",Beef Products,31.3,0.009,2.253,0,0,0.412,1.4,0,34.4,26.2,449,14.35,16.85,1.58,31654
"Beef, cured, breakfast strips, raw or unheated",Beef Products,12.5,0.004,0.955,0,0.024,0.153,0.7,0,38.8,45.2,406,15.95,18.68,2.19,31655
"Beef, cured, corned beef, brisket, cooked",Beef Products,18.17,0.008,1.134,0,0,0.145,0.47,0,18.98,59.79,251,6.34,9.22,0.67,31656
"Beef, cured, corned beef, brisket, raw",Beef Products,14.68,0.007,1.217,0,0.027,0.297,0.14,0,14.9,66.56,198,4.73,7.18,0.53,31657
"Beef, flank, steak, separable lean and fat, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,27.66,0.02,0.056,0,0,0.339,0,0,8.23,64.17,192,3.395,3.317,0.322,31658
"Beef, flank, steak, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,21.22,0.025,0.054,0,0,0.328,0,0,7.17,70.73,155,2.978,2.924,0.277,31659
"Beef, flank, steak, separable lean and fat, trimmed to 0"" fat, choice, cooked, braised",Beef Products,26.98,0.006,0.07,0,0,0.337,0,0,16.44,54.95,263,6.92,6.94,0.53,31660
"Beef, flank, steak, separable lean and fat, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,27.55,0.018,0.053,0,0,0.326,0,0,9.31,63.33,202,3.84,3.751,0.364,31661
"Beef, flank, steak, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,21.22,0.027,0.054,0,0,0.33,0,0,8.29,69.46,165,3.443,3.38,0.32,31662
"Beef, flank, steak, separable lean and fat, trimmed to 0"" fat, select, cooked, broiled",Beef Products,27.78,0.022,0.058,0,0,0.351,0,0,7.15,65.01,183,2.951,2.882,0.28,31663
"Beef, flank, steak, separable lean and fat, trimmed to 0"" fat, select, raw",Beef Products,21.22,0.024,0.054,0,0,0.326,0,0,6.06,71.99,145,2.514,2.469,0.234,31664
"Beef, flank, steak, separable lean only, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,27.89,0.018,0.057,0,0,0.35,0,0,7.4,64.79,186,3.07,2.917,0.292,31665
"Beef, flank, steak, separable lean only, trimmed to 0"" fat, all grades, raw",Beef Products,21.57,0.024,0.055,0,0,0.34,0,0,5.47,72.08,141,2.058,1.951,0.193,31666
"Beef, flank, steak, separable lean only, trimmed to 0"" fat, choice, cooked, braised",Beef Products,28.02,0.006,0.072,0,0,0.351,0,0,13,57.27,237,5.54,5.44,0.39,31667
"Beef, flank, steak, separable lean only, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,27.82,0.015,0.056,0,0,0.338,0,0,8.32,64.06,194,3.452,3.279,0.329,31668
"Beef, flank, steak, separable lean only, trimmed to 0"" fat, choice, raw",Beef Products,21.72,0.028,0.057,0,0,0.34,0,0,6.29,68.72,149,2.612,2.477,0.245,31669
"Beef, flank, steak, separable lean only, trimmed to 0"" fat, select, cooked, broiled",Beef Products,27.96,0.02,0.059,0,0,0.365,0,0,6.48,65.51,178,2.688,2.554,0.256,31670
"Beef, flank, steak, separable lean only, trimmed to 0"" fat, select, raw",Beef Products,21.43,0.021,0.053,0,0,0.341,0,0,5,72.84,137,2.074,1.967,0.195,31671
"Beef, grass-fed, ground, raw",Beef Products,19.42,0.012,0.068,0,0,0.289,0,0,12.73,67.13,192,5.335,4.8,0.532,31672
"Beef, grass-fed, strip steaks, lean only, raw",Beef Products,23.07,0.009,0.055,0,0,0.342,0,0,2.69,73.42,117,1.032,0.995,0.108,31673
"Beef, ground beef calculator - user selects lean/fat percentage",Beef Products,0,0,0,0,0,0,0,0,0,0,0,0,0,0,31674
"Beef, ground, 70% lean meat / 30% fat, crumbles, cooked, pan-browned",Beef Products,25.56,0.041,0.096,0,0,0.328,0,0,17.86,55.78,270,7.191,8.659,0.487,31675
"Beef, ground, 70% lean meat / 30% fat, loaf, cooked, baked",Beef Products,23.87,0.033,0.073,0,0,0.241,0,0,15.37,58.37,241,6.188,7.451,0.419,31676
"Beef, ground, 70% lean meat / 30% fat, patty cooked, pan-broiled",Beef Products,22.86,0.037,0.092,0,0,0.308,0,0,15.54,59.36,238,6.256,7.533,0.424,31677
"Beef, ground, 70% lean meat / 30% fat, patty, cooked, broiled",Beef Products,25.38,0.035,0.081,0,0,0.275,0,0,18.21,56.26,273,7.329,8.825,0.497,31678
"Beef, ground, 70% lean meat / 30% fat, raw",Beef Products,14.35,0.024,0.067,0,0,0.218,0,0,30,54.38,332,11.289,13.161,0.696,31679
"Beef, ground, 75% lean meat / 25% fat, crumbles, cooked, pan-browned",Beef Products,26.28,0.034,0.093,0,0,0.354,0,0,18.21,54.5,277,7.044,8.373,0.509,31680
"Beef, ground, 75% lean meat / 25% fat, loaf, cooked, baked",Beef Products,24.56,0.028,0.07,0,0,0.256,0,0,16.5,57.5,254,6.382,7.586,0.461,31681
"Beef, ground, 75% lean meat / 25% fat, patty, cooked, broiled",Beef Products,25.56,0.03,0.078,0,0,0.289,0,0,18.72,55.49,278,7.238,8.604,0.523,31682
"Beef, ground, 75% lean meat / 25% fat, patty, cooked, pan-broiled",Beef Products,23.45,0.032,0.087,0,0,0.322,0,0,16.44,58.69,248,6.36,7.56,0.459,31683
"Beef, ground, 75% lean meat / 25% fat, raw",Beef Products,15.76,0.021,0.067,0,0,0.244,0,0,25,58.16,293,9.481,8.629,0.52,31684
"Beef, ground, 80% lean meat / 20% fat, crumbles, cooked, pan-browned",Beef Products,27,0.028,0.091,0,0,0.38,0,0,17.36,54.54,272,6.593,7.696,0.503,31685
"Beef, ground, 80% lean meat / 20% fat, loaf, cooked, baked",Beef Products,25.25,0.023,0.067,0,0,0.271,0,0,16.17,57.7,254,6.138,7.166,0.469,31686
"Beef, ground, 80% lean meat / 20% fat, patty, cooked, broiled",Beef Products,25.75,0.024,0.075,0,0,0.304,0,0,17.82,56.08,271,6.758,7.883,0.519,31687
"Beef, ground, 80% lean meat / 20% fat, patty, cooked, pan-broiled",Beef Products,24.04,0.026,0.083,0,0,0.335,0,0,15.94,59.11,246,6.051,7.064,0.462,31688
"Beef, ground, 80% lean meat / 20% fat, raw",Beef Products,17.17,0.018,0.067,0,0,0.27,0,0,20,61.94,254,7.673,8.757,0.52,31689
"Beef, ground, 85% lean meat / 15% fat, crumbles, cooked, pan-browned",Beef Products,27.73,0.022,0.089,0,0,0.407,0,0,15.3,55.9,256,5.821,6.586,0.479,31690
"Beef, ground, 85% lean meat / 15% fat, loaf, cooked, baked",Beef Products,25.93,0.018,0.064,0,0,0.286,0,0,14.36,58.98,240,5.464,6.183,0.45,31691
"Beef, ground, 85% lean meat / 15% fat, patty, cooked, broiled",Beef Products,25.93,0.018,0.072,0,0,0.318,0,0,15.48,58.03,250,5.887,6.662,0.485,31692
"Beef, ground, 85% lean meat / 15% fat, patty, cooked, pan-broiled",Beef Products,24.62,0.02,0.079,0,0,0.349,0,0,14.02,60.61,232,5.335,6.037,0.439,31693
"Beef, ground, 85% lean meat / 15% fat, raw",Beef Products,18.59,0.015,0.066,0,0,0.295,0,0,15,65.72,215,5.866,6.555,0.432,31694
"Beef, ground, 90% lean meat / 10% fat, crumbles, cooked, pan-browned",Beef Products,28.45,0.016,0.087,0,0,0.433,0,0,12.04,58.58,230,4.752,5.074,0.432,31695
"Beef, ground, 90% lean meat / 10% fat, loaf, cooked, baked",Beef Products,26.62,0.013,0.061,0,0,0.3,0,0,11.1,61.33,214,4.379,4.676,0.398,31696
"Beef, ground, 90% lean meat / 10% fat, patty, cooked, broiled",Beef Products,26.11,0.013,0.068,0,0,0.333,0,0,11.73,61.35,217,4.627,4.941,0.421,31697
"Beef, ground, 90% lean meat / 10% fat, patty, cooked, pan-broiled",Beef Products,25.21,0.015,0.075,0,0,0.363,0,0,10.68,63.21,204,4.218,4.501,0.384,31698
"Beef, ground, 90% lean meat / 10% fat, raw",Beef Products,20,0.012,0.066,0,0,0.321,0,0,10,69.5,176,4.058,4.353,0.344,31699
"Beef, ground, 95% lean meat / 5% fat, crumbles, cooked, pan-browned",Beef Products,29.17,0.009,0.085,0,0,0.459,0,0,7.58,62.58,193,3.441,3.145,0.377,31700
"Beef, ground, 95% lean meat / 5% fat, loaf, cooked, baked",Beef Products,27.31,0.008,0.058,0,0,0.315,0,0,6.37,64.76,174,2.891,2.642,0.317,31701
"Beef, ground, 95% lean meat / 5% fat, patty, cooked, broiled",Beef Products,26.29,0.007,0.065,0,0,0.348,0,0,6.55,66.03,171,2.977,2.721,0.327,31702
"Beef, ground, 95% lean meat / 5% fat, patty, cooked, pan-broiled",Beef Products,25.8,0.009,0.071,0,0,0.376,0,0,5.94,66.9,164,2.698,2.466,0.296,31703
"Beef, ground, 95% lean meat / 5% fat, raw",Beef Products,21.41,0.009,0.066,0,0,0.346,0,0,5,73.28,137,2.25,2.151,0.257,31704
"Beef, ground, patties, frozen, cooked, broiled",Beef Products,23.05,0.011,0.077,0,0,0.305,0,0,21.83,53.91,295,8.805,9.358,0.636,31705
"Beef, loin, bottom sirloin butt, tri-tip roast, separable lean only, trimmed to 0"" fat, all grades, cooked, roasted",Beef Products,26.75,0.017,0.055,0,0,0.34,0,0,8.34,64.67,182,3.108,4.205,0.268,31706
"Beef, loin, bottom sirloin butt, tri-tip steak, separable lean and fat, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,29.97,0.012,0.072,0,0,0.437,0,0,15.18,50.5,265,5.707,7.763,0.538,31707
"Beef, loin, bottom sirloin butt, tri-tip steak, separable lean only, trimmed 0"" fat, all grades, cooked, broiled",Beef Products,30.68,0.012,0.073,0,0,0.449,0,0,13.16,51.67,250,4.87,6.93,0.46,31708
"Beef, plate, inside skirt steak, separable lean and fat, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,26.13,0.011,0.075,0,0,0.289,0,0,12.05,60.34,220,4.666,6.055,0.436,31709
"Beef, plate, inside skirt steak, separable lean only, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,26.66,0.011,0.076,0,0,0.295,0,0,10.06,61.77,205,3.85,5.22,0.36,31710
"Beef, plate, inside skirt steak, separable lean only, trimmed to 1/4"" fat, all grades, raw",Beef Products,21.08,0.008,0.067,0,0,0.369,0,0,8.24,70.62,164,3.14,4.22,0.36,31711
"Beef, plate, outside skirt steak, separable lean and fat, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,23.51,0.011,0.092,0,0,0.379,0,0,17.13,57.16,255,7.083,8.52,0.702,31712
"Beef, plate, outside skirt steak, separable lean only, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,24.18,0.01,0.094,0,0,0.393,0,0,14.37,59.17,233,5.97,7.38,0.6,31713
"Beef, plate, outside skirt steak, separable lean only, trimmed to 1/4"" fat, all grades, raw",Beef Products,19.62,0.009,0.07,0,0,0.386,0,0,8.95,68.95,165,3.47,4.72,0.41,31714
"Beef, retail cuts, separable fat, cooked",Beef Products,10.65,0.019,0.023,0,0,0.099,0,0,70.33,18.58,680,28.5,30.45,2.66,31715
"Beef, retail cuts, separable fat, raw",Beef Products,8.21,0.026,0.026,0,0,0.096,0,0,70.89,20.21,674,29.45,30.94,2.56,31716
"Beef, rib eye, small end (ribs 10-12), separable lean and fat, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,27.27,0.02,0.056,0,0,0.34,0,0,14.74,57.52,249,5.72,6.034,0.547,31717
"Beef, rib eye, small end (ribs 10-12), separable lean and fat, trimmed to 0"" fat, select, cooked, broiled",Beef Products,27.95,0.022,0.059,0,0,0.355,0,0,12.71,59.16,234,4.934,5.205,0.472,31718
"Beef, rib eye, small end (ribs 10-12), separable lean only, trimmed to 0"" fat, select, raw",Beef Products,21.17,0.01,0.052,0,0,0.297,0,0,6.57,71.33,149,2.65,2.771,0.189,31719
"Beef, rib, eye, small end (ribs 10- 12) separable lean only, trimmed to 0"" fat, select, cooked, broiled",Beef Products,29.93,0.021,0.063,0,0,0.392,0,0,6.05,63.87,182,2.305,2.416,0.223,31720
"Beef, rib, eye, small end (ribs 10-12), separable lean and fat, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,26.58,0.018,0.053,0,0,0.326,0,0,16.76,55.87,265,6.506,6.863,0.622,31721
"Beef, rib, eye, small end (ribs 10-12), separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,17.51,0.01,0.056,0,0,0.305,0,0,22.07,58.03,274,9,9.58,0.77,31722
"Beef, rib, eye, small end (ribs 10-12), separable lean only, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,28.88,0.016,0.06,0,0,0.363,0,0,9.01,61.3,205,3.43,3.595,0.332,31723
"Beef, rib, eye, small end (ribs 10-12), separable lean only, trimmed to 0"" fat, choice, raw",Beef Products,20.13,0.01,0.063,0,0,0.373,0,0,8.3,68.7,161,3.23,3.56,0.27,31724
"Beef, rib, large end (ribs 6-9), separable lean and fat, trimmed to 0"" fat, choice, cooked, roasted",Beef Products,22.8,0.01,0.064,0,0,0.29,0,0,30.49,46.1,372,12.29,13.04,1.05,31725
"Beef, rib, large end (ribs 6-9), separable lean and fat, trimmed to 0"" fat, select, cooked, roasted",Beef Products,23.48,0.009,0.065,0,0,0.3,0,0,25.54,49.53,331,10.3,10.93,0.89,31726
"Beef, rib, large end (ribs 6-9), separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, broiled",Beef Products,21.55,0.01,0.064,0,0,0.309,0,0,27.22,48.74,338,11.06,11.51,1.03,31727
"Beef, rib, large end (ribs 6-9), separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, roasted",Beef Products,23.01,0.01,0.064,0,0,0.293,0,0,28.51,47.73,355,11.5,12.2,0.99,31728
"Beef, rib, large end (ribs 6-9), separable lean and fat, trimmed to 1/8"" fat, all grades, raw",Beef Products,16.26,0.008,0.055,0,0,0.26,0,0,27.29,54.51,316,11.29,11.69,1,31729
"Beef, rib, large end (ribs 6-9), separable lean and fat, trimmed to 1/8"" fat, choice, cooked, broiled",Beef Products,20.86,0.01,0.063,0,0,0.297,0,0,31.18,45.96,370,12.67,13.21,1.18,31730
"Beef, rib, large end (ribs 6-9), separable lean and fat, trimmed to 1/8"" fat, choice, cooked, roasted",Beef Products,22.5,0.01,0.063,0,0,0.286,0,0,31.28,45.9,378,12.62,13.39,1.09,31731
"Beef, rib, large end (ribs 6-9), separable lean and fat, trimmed to 1/8"" fat, choice, raw",Beef Products,16.03,0.008,0.054,0,0,0.254,0,0,29.34,53,333,12.13,12.56,1.07,31732
"Beef, rib, large end (ribs 6-9), separable lean and fat, trimmed to 1/8"" fat, prime, cooked, broiled",Beef Products,20.65,0.01,0.062,0,0,0.298,0,0,34.97,43.48,404,14.51,15.29,1.21,31733
"Beef, rib, large end (ribs 6-9), separable lean and fat, trimmed to 1/8"" fat, prime, cooked, roasted",Beef Products,22.86,0.01,0.064,0,0,0.291,0,0,32.74,44.49,393,13.59,14.29,1.13,31734
"Beef, rib, large end (ribs 6-9), separable lean and fat, trimmed to 1/8"" fat, prime, raw",Beef Products,15.77,0.008,0.053,0,0,0.248,0,0,33.26,50.4,367,13.96,14.37,1.23,31735
"Beef, rib, large end (ribs 6-9), separable lean and fat, trimmed to 1/8"" fat, select, cooked, broiled",Beef Products,21.55,0.01,0.064,0,0,0.309,0,0,25.71,50.25,324,10.43,10.85,0.97,31736
"Beef, rib, large end (ribs 6-9), separable lean and fat, trimmed to 1/8"" fat, select, cooked, roasted",Beef Products,23.4,0.009,0.065,0,0,0.299,0,0,25.84,49.4,333,10.42,11.06,0.9,31737
"Beef, rib, large end (ribs 6-9), separable lean and fat, trimmed to 1/8"" fat, select, raw",Beef Products,16.52,0.008,0.056,0,0,0.266,0,0,24.85,56.35,295,10.28,10.65,0.91,31738
"Beef, rib, large end (ribs 6-9), separable lean only, trimmed to 0"" fat, all grades, cooked, roasted",Beef Products,27.53,0.008,0.073,0,0,0.357,0,0,13.4,57.9,238,5.35,5.6,0.38,31739
"Beef, rib, large end (ribs 6-9), separable lean only, trimmed to 0"" fat, choice, cooked, roasted",Beef Products,27.53,0.008,0.073,0,0,0.357,0,0,15,56.8,253,5.99,6.27,0.43,31740
"Beef, rib, large end (ribs 6-9), separable lean only, trimmed to 0"" fat, select, cooked, roasted",Beef Products,27.53,0.008,0.073,0,0,0.357,0,0,11.4,59.3,220,4.55,4.77,0.33,31741
"Beef, rib, shortribs, separable lean and fat, choice, cooked, braised",Beef Products,21.57,0.012,0.05,0,0,0.224,0,0,41.98,35.72,471,17.8,18.88,1.53,31742
"Beef, rib, shortribs, separable lean and fat, choice, raw",Beef Products,14.4,0.009,0.049,0,0,0.232,0,0,36.23,48.29,388,15.76,16.39,1.32,31743
"Beef, rib, shortribs, separable lean only, choice, cooked, braised",Beef Products,30.76,0.011,0.058,0,0,0.313,0,0,18.13,50.15,295,7.74,7.98,0.55,31744
"Beef, rib, shortribs, separable lean only, choice, raw",Beef Products,19.05,0.008,0.065,0,0,0.357,0,0,10.19,69.38,173,4.33,4.38,0.38,31745
"Beef, rib, small end (ribs 10-12), separable lean and fat, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,27.27,0.02,0.056,0,0,0.34,0,0,14.74,57.52,249,5.72,6.034,0.547,31746
"Beef, rib, small end (ribs 10-12), separable lean and fat, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,24.73,0.013,0.064,0,0,0.342,0,0,22.84,51.08,312,9.24,9.78,0.78,31747
"Beef, rib, small end (ribs 10-12), separable lean and fat, trimmed to 0"" fat, select, cooked, broiled",Beef Products,24.91,0.013,0.064,0,0,0.344,0,0,19.79,52.79,285,8.01,8.49,0.68,31748
"Beef, rib, small end (ribs 10-12), separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, broiled",Beef Products,25.85,0.019,0.053,0,0,0.323,0,0,20.04,53.65,291,7.894,8.355,0.742,31749
"Beef, rib, small end (ribs 10-12), separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, roasted",Beef Products,22.54,0.013,0.063,0,0,0.324,0,0,27.14,48.97,341,10.92,11.73,0.99,31750
"Beef, rib, small end (ribs 10-12), separable lean and fat, trimmed to 1/8"" fat, all grades, raw",Beef Products,19.33,0.023,0.049,0,0,0.295,0,0,19.06,61.05,254,7.692,8.162,0.725,31751
"Beef, rib, small end (ribs 10-12), separable lean and fat, trimmed to 1/8"" fat, choice, cooked, broiled",Beef Products,24.53,0.016,0.049,0,0,0.298,0,0,22.09,52.93,304,8.7,9.208,0.818,31752
"Beef, rib, small end (ribs 10-12), separable lean and fat, trimmed to 1/8"" fat, choice, cooked, roasted",Beef Products,22.28,0.013,0.063,0,0,0.319,0,0,29.21,47.18,359,11.78,12.63,1.07,31753
"Beef, rib, small end (ribs 10-12), separable lean and fat, trimmed to 1/8"" fat, choice, raw",Beef Products,19.09,0.024,0.048,0,0,0.293,0,0,20.13,60.11,263,8.121,8.617,0.766,31754
"Beef, rib, small end (ribs 10-12), separable lean and fat, trimmed to 1/8"" fat, prime, cooked, broiled",Beef Products,24.13,0.013,0.063,0,0,0.332,0,0,27.86,47.36,354,11.51,12.12,0.96,31755
"Beef, rib, small end (ribs 10-12), separable lean and fat, trimmed to 1/8"" fat, prime, cooked, roasted",Beef Products,22.15,0.013,0.065,0,0,0.323,0,0,35.12,41.82,411,14.51,15.28,1.22,31756
"Beef, rib, small end (ribs 10-12), separable lean and fat, trimmed to 1/8"" fat, prime, raw",Beef Products,16.74,0.01,0.053,0,0,0.286,0,0,29.18,53.22,335,12.07,12.84,1.04,31757
"Beef, rib, small end (ribs 10-12), separable lean and fat, trimmed to 1/8"" fat, select, cooked, broiled",Beef Products,27.17,0.022,0.058,0,0,0.349,0,0,18,54.36,278,7.088,7.502,0.667,31758
"Beef, rib, small end (ribs 10-12), separable lean and fat, trimmed to 1/8"" fat, select, cooked, roasted",Beef Products,22.76,0.013,0.064,0,0,0.328,0,0,25.02,50.84,323,10.05,10.81,0.91,31759
"Beef, rib, small end (ribs 10-12), separable lean and fat, trimmed to 1/8"" fat, select, raw",Beef Products,19.56,0.022,0.049,0,0,0.297,0,0,18,62,246,7.264,7.708,0.685,31760
"Beef, rib, small end (ribs 10-12), separable lean only, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,29.41,0.019,0.061,0,0,0.376,0,0,7.53,62.59,193,2.868,3.005,0.277,31761
"Beef, rib, small end (ribs 10-12), separable lean only, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,28.04,0.013,0.069,0,0,0.394,0,0,11.7,58.7,225,4.73,4.94,0.33,31762
"Beef, rib, small end (ribs 10-12), separable lean only, trimmed to 0"" fat, select, cooked, broiled",Beef Products,28.04,0.013,0.069,0,0,0.394,0,0,8.7,60.3,198,3.51,3.67,0.25,31763
"Beef, rib, small end (ribs 10-12), separable lean only, trimmed to 1/8"" fat, all grades, cooked, broiled",Beef Products,29.58,0.019,0.062,0,0,0.378,0,0,7.63,64.27,195,2.907,3.048,0.273,31764
"Beef, rib, small end (ribs 10-12), separable lean only, trimmed to 1/8"" fat, all grades, raw",Beef Products,22.33,0.024,0.056,0,0,0.347,0,0,5.04,69.95,141,1.864,2.029,0.218,31765
"Beef, rib, small end (ribs 10-12), separable lean only, trimmed to 1/8"" fat, choice, raw",Beef Products,22.12,0.028,0.057,0,0,0.341,0,0,5.91,71.28,148,2.183,2.376,0.256,31766
"Beef, rib, small end (ribs 10-12), separable lean only, trimmed to 1/8"" fat, select, cooked, broiled",Beef Products,30.87,0.022,0.066,0,0,0.409,0,0,6.22,62.45,188,2.369,2.484,0.222,31767
"Beef, rib, small end (ribs 10-12), separable lean only, trimmed to 1/8"" fat, select, raw",Beef Products,22.53,0.022,0.055,0,0,0.353,0,0,4.2,72.9,134,1.553,1.69,0.182,31768
"Beef, rib, small end (ribs 10-12), separable lean only, trimmed to 1/8""fat, choice, cooked, broiled",Beef Products,28.29,0.016,0.058,0,0,0.352,0,0,9.05,62.22,202,3.445,3.612,0.323,31769
"Beef, rib, whole (ribs 6-12), separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, broiled",Beef Products,22.42,0.011,0.063,0,0,0.315,0,0,26.75,48.47,337,10.85,11.38,0.98,31770
"Beef, rib, whole (ribs 6-12), separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, roasted",Beef Products,22.77,0.011,0.064,0,0,0.305,0,0,28.11,48.12,351,11.33,12.08,1,31771
"Beef, rib, whole (ribs 6-12), separable lean and fat, trimmed to 1/8"" fat, all grades, raw",Beef Products,16.53,0.009,0.055,0,0,0.272,0,0,26.1,55.24,306,10.76,11.24,0.94,31772
"Beef, rib, whole (ribs 6-12), separable lean and fat, trimmed to 1/8"" fat, choice, cooked, broiled",Beef Products,22.26,0.011,0.063,0,0,0.313,0,0,28.5,47.33,352,11.57,12.12,1.04,31773
"Beef, rib, whole (ribs 6-12), separable lean and fat, trimmed to 1/8"" fat, choice, cooked, roasted",Beef Products,22.6,0.011,0.064,0,0,0.302,0,0,29.79,46.87,365,12.01,12.8,1.05,31774
"Beef, rib, whole (ribs 6-12), separable lean and fat, trimmed to 1/8"" fat, choice, raw",Beef Products,16.34,0.009,0.054,0,0,0.267,0,0,27.93,53.85,322,11.51,12.02,1.01,31775
"Beef, rib, whole (ribs 6-12), separable lean and fat, trimmed to 1/8"" fat, prime, cooked, broiled",Beef Products,21.95,0.011,0.062,0,0,0.31,0,0,32.38,44.87,386,13.42,14.13,1.12,31776
"Beef, rib, whole (ribs 6-12), separable lean and fat, trimmed to 1/8"" fat, prime, cooked, roasted",Beef Products,22.57,0.011,0.065,0,0,0.304,0,0,33.7,43.4,400,13.96,14.69,1.17,31777
"Beef, rib, whole (ribs 6-12), separable lean and fat, trimmed to 1/8"" fat, prime, raw",Beef Products,16.15,0.009,0.053,0,0,0.263,0,0,31.66,51.51,355,13.22,13.77,1.15,31778
"Beef, rib, whole (ribs 6-12), separable lean and fat, trimmed to 1/8"" fat, select, cooked, broiled",Beef Products,22.73,0.011,0.064,0,0,0.321,0,0,24.2,50.41,315,9.81,10.28,0.89,31779
"Beef, rib, whole (ribs 6-12), separable lean and fat, trimmed to 1/8"" fat, select, cooked, roasted",Beef Products,23.1,0.011,0.065,0,0,0.31,0,0,25.63,49.9,330,10.32,11.01,0.91,31780
"Beef, rib, whole (ribs 6-12), separable lean and fat, trimmed to 1/8"" fat, select, raw",Beef Products,16.75,0.009,0.055,0,0,0.278,0,0,23.95,57.48,288,9.88,10.32,0.86,31781
"Beef, round, bottom round , roast, separable lean only, trimmed to 1/8"" fat, select, cooked, roasted",Beef Products,28.45,0.006,0.038,0,0,0.238,0,0,4.67,66.61,164,1.608,1.951,0.188,31782
"Beef, round, bottom round roast, separable lean only, trimmed to 0"" fat, select, cooked, roasted",Beef Products,28.29,0.006,0.038,0,0,0.238,0,0,5.33,65.9,169,1.86,2.247,0.199,31783
"Beef, round, bottom round, roast, separable lean and fat, trimmed to 0"" fat, all grades, cooked, roasted",Beef Products,27.42,0.007,0.036,0,0,0.223,0,0,7.72,64.46,187,2.773,3.266,0.291,31784
"Beef, round, bottom round, roast, separable lean and fat, trimmed to 0"" fat, choice, cooked, roasted",Beef Products,26.76,0.006,0.035,0,0,0.215,0,0,9.37,63.54,199,3.366,3.966,0.353,31785
"Beef, round, bottom round, roast, separable lean and fat, trimmed to 0"" fat, select, cooked, roasted",Beef Products,28.08,0.007,0.037,0,0,0.232,0,0,6.06,65.37,175,2.179,2.567,0.228,31786
"Beef, round, bottom round, roast, separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, roasted",Beef Products,26.41,0.006,0.035,0,0,0.214,0,0,11.64,61.6,218,4.418,4.964,0.449,31787
"Beef, round, bottom round, roast, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, roasted",Beef Products,26.05,0.006,0.034,0,0,0.21,0,0,12.44,61.09,223,4.721,5.304,0.479,31788
"Beef, round, bottom round, roast, separable lean and fat, trimmed to 1/8"" fat, select, cooked, roasted",Beef Products,26.77,0.007,0.035,0,0,0.219,0,0,10.85,62.1,212,4.116,4.625,0.418,31789
"Beef, round, bottom round, roast, separable lean only, trimmed to 0"" fat, all grades, cooked, roasted",Beef Products,27.76,0.006,0.036,0,0,0.228,0,0,6.48,65.37,177,2.26,2.73,0.242,31790
"Beef, round, bottom round, roast, separable lean only, trimmed to 0"" fat, choice, cooked, roasted",Beef Products,27.23,0.006,0.036,0,0,0.222,0,0,7.63,64.83,185,2.661,3.214,0.285,31791
"Beef, round, bottom round, roast, separable lean only, trimmed to 1/8"" fat, all grades, cooked",Beef Products,28,0.006,0.037,0,0,0.23,0,0,5.72,65.93,163,1.972,2.391,0.231,31792
"Beef, round, bottom round, roast, separable lean only, trimmed to 1/8"" fat, all grades, raw",Beef Products,22.19,0.02,0.059,0,0,0.35,0,0,4.31,72.58,128,1.476,1.816,0.185,31793
"Beef, round, bottom round, roast, separable lean only, trimmed to 1/8"" fat, choice, cooked, roasted",Beef Products,27.56,0.006,0.036,0,0,0.225,0,0,6.77,65.26,179,2.334,2.831,0.274,31794
"Beef, round, bottom round, roast, separable lean only, trimmed to 1/8"" fat, choice, raw",Beef Products,22.22,0.019,0.056,0,0,0.341,0,0,4.96,71.99,140,1.699,2.09,0.213,31795
"Beef, round, bottom round, roast, separable lean only, trimmed to 1/8"" fat, select, raw",Beef Products,22.18,0.021,0.062,0,0,0.361,0,0,3.66,73.17,128,1.253,1.542,0.157,31796
"Beef, round, bottom round, steak, separable lean and fat, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,33.56,0.008,0.044,0,0,0.271,0,0,8.86,57.33,223,3.184,3.751,0.334,31797
"Beef, round, bottom round, steak, separable lean and fat, trimmed to 0"" fat, choice, cooked, braised",Beef Products,32.73,0.007,0.042,0,0,0.264,0,0,10,56.74,230,3.593,4.233,0.377,31798
"Beef, round, bottom round, steak, separable lean and fat, trimmed to 0"" fat, select, cooked, braised",Beef Products,34.39,0.009,0.045,0,0,0.279,0,0,7.72,57.92,217,2.775,3.269,0.291,31799
"Beef, round, bottom round, steak, separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, braised",Beef Products,32.76,0.008,0.043,0,0,0.266,0,0,11.87,54.97,247,4.506,5.064,0.458,31800
"Beef, round, bottom round, steak, separable lean and fat, trimmed to 1/8"" fat, all grades, raw",Beef Products,20.7,0.02,0.056,0,0,0.327,0,0,11.54,66.88,192,4.544,4.987,0.441,31801
"Beef, round, bottom round, steak, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, braised",Beef Products,32.85,0.007,0.042,0,0,0.263,0,0,12.56,54.27,254,4.766,5.355,0.484,31802
"Beef, round, bottom round, steak, separable lean and fat, trimmed to 1/8"" fat, choice, raw",Beef Products,20.71,0.016,0.053,0,0,0.319,0,0,12.15,66.32,198,4.784,5.251,0.464,31803
"Beef, round, bottom round, steak, separable lean and fat, trimmed to 1/8"" fat, select, cooked, braised",Beef Products,32.67,0.008,0.043,0,0,0.269,0,0,11.19,55.67,240,4.247,4.772,0.431,31804
"Beef, round, bottom round, steak, separable lean and fat, trimmed to 1/8"" fat, select, raw",Beef Products,20.68,0.023,0.06,0,0,0.335,0,0,10.93,67.44,187,4.303,4.723,0.417,31805
"Beef, round, bottom round, steak, separable lean only, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,34,0.007,0.044,0,0,0.278,0,0,7.67,58.09,214,2.675,3.231,0.286,31806
"Beef, round, bottom round, steak, separable lean only, trimmed to 0"" fat, choice, cooked, braised",Beef Products,33.08,0.007,0.043,0,0,0.271,0,0,9.03,57.36,223,3.152,3.807,0.337,31807
"Beef, round, bottom round, steak, separable lean only, trimmed to 0"" fat, select, cooked, braised",Beef Products,34.93,0.007,0.046,0,0,0.289,0,0,6.3,58.82,206,2.198,2.655,0.235,31808
"Beef, round, bottom round, steak, separable lean only, trimmed to 1/8"" fat, all grades, cooked, braised",Beef Products,34.34,0.007,0.045,0,0,0.282,0,0,7.73,57.54,216,2.663,3.23,0.312,31809
"Beef, round, bottom round, steak, separable lean only, trimmed to 1/8"" fat, choice, cooked, braised",Beef Products,34.22,0.007,0.045,0,0,0.278,0,0,9.02,56.44,228,3.109,3.771,0.364,31810
"Beef, round, bottom round, steak, separable lean only, trimmed to 1/8"" fat, select, cooked, braised",Beef Products,34.46,0.007,0.046,0,0,0.289,0,0,6.43,58.64,205,2.216,2.688,0.26,31811
"Beef, round, eye of round, roast, separable lean and fat, trimmed to 0"" fat, all grades, cooked, roasted",Beef Products,29.13,0.007,0.038,0,0,0.235,0,0,4.8,65.93,168,1.724,2.031,0.181,31812
"Beef, round, eye of round, roast, separable lean and fat, trimmed to 0"" fat, choice, cooked, roasted",Beef Products,28.58,0.006,0.037,0,0,0.231,0,0,4.87,66.02,166,1.75,2.062,0.183,31813
"Beef, round, eye of round, roast, separable lean and fat, trimmed to 0"" fat, select, cooked, roasted",Beef Products,29.67,0.007,0.039,0,0,0.239,0,0,4.72,65.84,169,1.697,2,0.178,31814
"Beef, round, eye of round, roast, separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, roasted",Beef Products,28.31,0.007,0.037,0,0,0.227,0,0,9.65,61.97,208,3.664,4.117,0.372,31815
"Beef, round, eye of round, roast, separable lean and fat, trimmed to 1/8"" fat, all grades, raw",Beef Products,21.49,0.02,0.058,0,0,0.34,0,0,8.24,69.31,166,3.245,3.561,0.315,31816
"Beef, round, eye of round, roast, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, roasted",Beef Products,28.48,0.006,0.037,0,0,0.227,0,0,10.05,61.36,212,3.813,4.285,0.387,31817
"Beef, round, eye of round, roast, separable lean and fat, trimmed to 1/8"" fat, choice, raw",Beef Products,21.68,0.016,0.055,0,0,0.334,0,0,8.91,68.57,173,3.509,3.851,0.34,31818
"Beef, round, eye of round, roast, separable lean and fat, trimmed to 1/8"" fat, select, cooked, roasted",Beef Products,28.13,0.007,0.037,0,0,0.228,0,0,9.26,62.58,204,3.515,3.949,0.357,31819
"Beef, round, eye of round, roast, separable lean and fat, trimmed to 1/8"" fat, select, raw",Beef Products,21.3,0.024,0.062,0,0,0.346,0,0,7.57,70.06,159,2.98,3.271,0.289,31820
"Beef, round, eye of round, roast, separable lean only, trimmed to 0"" fat, all grades, cooked, roasted",Beef Products,29.33,0.006,0.038,0,0,0.239,0,0,4.09,66.45,162,1.426,1.722,0.153,31821
"Beef, round, eye of round, roast, separable lean only, trimmed to 0"" fat, choice, cooked, roasted",Beef Products,28.74,0.006,0.038,0,0,0.236,0,0,4.3,66.43,162,1.502,1.814,0.161,31822
"Beef, round, eye of round, roast, separable lean only, trimmed to 0"" fat, select, cooked, roasted",Beef Products,29.92,0.006,0.039,0,0,0.246,0,0,3.87,66.46,163,1.349,1.63,0.144,31823
"Beef, round, eye of round, roast, separable lean only, trimmed to 1/8"" fat, all grades, cooked, roasted",Beef Products,29.73,0.006,0.038,0,0,0.241,0,0,4.71,65.52,169,1.624,1.97,0.19,31824
"Beef, round, eye of round, roast, separable lean only, trimmed to 1/8"" fat, all grades, raw",Beef Products,22.6,0.02,0.06,0,0,0.357,0,0,3,73.43,124,1.027,1.263,0.129,31825
"Beef, round, eye of round, roast, separable lean only, trimmed to 1/8"" fat, choice, cooked, roasted",Beef Products,29.87,0.006,0.039,0,0,0.241,0,0,5.3,64.73,175,1.828,2.217,0.214,31826
"Beef, round, eye of round, roast, separable lean only, trimmed to 1/8"" fat, choice, raw",Beef Products,22.88,0.02,0.057,0,0,0.351,0,0,3.38,72.89,128,1.158,1.425,0.145,31827
"Beef, round, eye of round, roast, separable lean only, trimmed to 1/8"" fat, select, cooked, roasted",Beef Products,29.59,0.006,0.039,0,0,0.245,0,0,4.11,66.31,163,1.416,1.718,0.166,31828
"Beef, round, eye of round, roast, separable lean only, trimmed to 1/8"" fat, select, raw",Beef Products,22.31,0.022,0.062,0,0,0.364,0,0,2.62,73.97,119,0.898,1.105,0.113,31829
"Beef, round, full cut, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, broiled",Beef Products,27.54,0.006,0.062,0,0,0.395,0,0,12.98,56.73,235,4.9,5.56,0.52,31830
"Beef, round, full cut, separable lean and fat, trimmed to 1/8"" fat, choice, raw",Beef Products,20.56,0.004,0.054,0,0,0.342,0,0,11.92,65.44,195,4.63,5.14,0.46,31831
"Beef, round, full cut, separable lean and fat, trimmed to 1/8"" fat, select, cooked, broiled",Beef Products,27.58,0.006,0.062,0,0,0.395,0,0,11.08,58.49,218,4.23,4.76,0.44,31832
"Beef, round, full cut, separable lean and fat, trimmed to 1/8"" fat, select, raw",Beef Products,20.56,0.004,0.054,0,0,0.342,0,0,10.68,66.62,184,4.2,4.62,0.41,31833
"Beef, round, full cut, separable lean only, trimmed to 1/4"" fat, choice, cooked, broiled",Beef Products,29.21,0.005,0.064,0,0,0.422,0,0,7.31,60.5,191,2.56,3.1,0.31,31834
"Beef, round, full cut, separable lean only, trimmed to 1/4"" fat, select, cooked, broiled",Beef Products,29.25,0.005,0.064,0,0,0.423,0,0,5.22,62.44,172,1.83,2.22,0.22,31835
"Beef, round, knuckle, tip center, steak, separable lean and fat, trimmed to 0"" fat, all grades, cooked, grilled",Beef Products,27.12,0.005,0.052,0,0,0.377,0,0,6.78,65.46,177,2.527,2.712,0.358,31836
"Beef, round, knuckle, tip center, steak, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,20.93,0.005,0.054,0,0,0.363,0,0,5.89,72.45,143,2.163,2.336,0.312,31837
"Beef, round, knuckle, tip center, steak, separable lean and fat, trimmed to 0"" fat, choice, cooked, grilled",Beef Products,26.88,0.005,0.051,0,0,0.373,0,0,8.13,64.53,188,2.759,2.983,0.367,31838
"Beef, round, knuckle, tip center, steak, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,20.74,0.005,0.054,0,0,0.365,0,0,6.85,72.05,150,2.3,2.497,0.321,31839
"Beef, round, knuckle, tip center, steak, separable lean and fat, trimmed to 0"" fat, select, cooked, grilled",Beef Products,26.61,0.005,0.053,0,0,0.386,0,0,5.32,67.13,162,2.063,2.172,0.341,31840
"Beef, round, knuckle, tip center, steak, separable lean and fat, trimmed to 0"" fat, select, raw",Beef Products,20.98,0.005,0.053,0,0,0.36,0,0,5.21,72.72,137,1.89,2.014,0.295,31841
"Beef, round, knuckle, tip side, steak, separable lean and fat , trimmed to 0"" fat, select, raw",Beef Products,21.96,0.005,0.057,0,0,0.386,0,0,3.24,73.52,124,1.225,1.338,0.269,31842
"Beef, round, knuckle, tip side, steak, separable lean and fat, trimmed to 0"" fat, all grades, cooked, grilled",Beef Products,29.08,0.005,0.054,0,0,0.365,0,0,4.84,65.68,168,1.901,2.239,0.301,31843
"Beef, round, knuckle, tip side, steak, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,21.69,0.005,0.061,0,0,0.381,0,0,4,73.25,129,1.578,1.881,0.275,31844
"Beef, round, knuckle, tip side, steak, separable lean and fat, trimmed to 0"" fat, choice, cooked, grilled",Beef Products,28.79,0.005,0.055,0,0,0.365,0,0,5.71,65.1,174,2.218,2.666,0.325,31845
"Beef, round, knuckle, tip side, steak, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,21.41,0.005,0.062,0,0,0.378,0,0,4.66,73,133,1.755,2.152,0.277,31846
"Beef, round, knuckle, tip side, steak, separable lean and fat, trimmed to 0"" fat, select, cooked, grilled",Beef Products,29.24,0.005,0.052,0,0,0.365,0,0,3.91,66.28,160,1.267,1.385,0.255,31847
"Beef, round, outside round, bottom round, steak, separable lean and fat, trimmed to 0"" fat, all grades, cooked, grilled",Beef Products,27.52,0.005,0.058,0,0,0.363,0,0,7.1,64.28,182,2.541,3.174,0.321,31848
"Beef, round, outside round, bottom round, steak, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,21.59,0.005,0.062,0,0,0.345,0,0,5.53,72.19,142,2,2.503,0.309,31849
"Beef, round, outside round, bottom round, steak, separable lean and fat, trimmed to 0"" fat, choice, cooked, grilled",Beef Products,27.22,0.005,0.057,0,0,0.361,0,0,8.3,63.35,191,2.954,3.759,0.338,31850
"Beef, round, outside round, bottom round, steak, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,21.24,0.005,0.063,0,0,0.338,0,0,6.59,71.51,150,2.301,2.94,0.309,31851
"Beef, round, outside round, bottom round, steak, separable lean and fat, trimmed to 0"" fat, select, cooked, grilled",Beef Products,28.01,0.005,0.06,0,0,0.368,0,0,5.17,65.76,166,1.716,2.006,0.286,31852
"Beef, round, outside round, bottom round, steak, separable lean and fat, trimmed to 0"" fat, select, raw",Beef Products,22.15,0.005,0.062,0,0,0.36,0,0,3.83,73.28,129,1.4,1.63,0.309,31853
"Beef, round, tip round, roast, separable lean and fat, trimmed to 0"" fat, all grades, cooked, roasted",Beef Products,26.79,0.006,0.035,0,0,0.217,0,0,8.21,64.67,188,3.007,3.405,0.305,31854
"Beef, round, tip round, roast, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,20.48,0.019,0.055,0,0,0.318,0,0,7.01,72.01,151,2.778,3.011,0.276,31855
"Beef, round, tip round, roast, separable lean and fat, trimmed to 0"" fat, choice, cooked, roasted",Beef Products,27.01,0.006,0.035,0,0,0.216,0,0,8.9,63.8,196,3.257,3.689,0.33,31856
"Beef, round, tip round, roast, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,20.16,0.015,0.051,0,0,0.309,0,0,7.73,71.4,156,3.066,3.324,0.305,31857
"Beef, round, tip round, roast, separable lean and fat, trimmed to 0"" fat, select, cooked, roasted",Beef Products,26.57,0.007,0.035,0,0,0.218,0,0,7.53,65.54,181,2.756,3.121,0.28,31858
"Beef, round, tip round, roast, separable lean and fat, trimmed to 0"" fat, select, raw",Beef Products,20.8,0.023,0.058,0,0,0.327,0,0,6.28,72.62,145,2.489,2.699,0.247,31859
"Beef, round, tip round, roast, separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, roasted",Beef Products,27.45,0.006,0.063,0,0,0.367,0,0,11.34,61.56,219,4.24,4.68,0.44,31860
"Beef, round, tip round, roast, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, roasted",Beef Products,27.27,0.006,0.063,0,0,0.365,0,0,12.34,60.92,228,4.63,5.11,0.48,31861
"Beef, round, tip round, roast, separable lean and fat, trimmed to 1/8"" fat, select, cooked, roasted",Beef Products,27.63,0.006,0.064,0,0,0.37,0,0,10.24,62.31,210,3.81,4.22,0.4,31862
"Beef, round, tip round, roast, separable lean only, trimmed to 0"" fat, all grades, cooked, roasted",Beef Products,27.53,0.006,0.036,0,0,0.226,0,0,6.2,66.75,174,2.209,2.537,0.228,31863
"Beef, round, tip round, roast, separable lean only, trimmed to 0"" fat, all grades, raw",Beef Products,21.07,0.018,0.054,0,0,0.323,0,0,3.95,74.48,126,1.376,1.641,0.201,31864
"Beef, round, tip round, roast, separable lean only, trimmed to 0"" fat, choice, cooked, roasted",Beef Products,27.68,0.006,0.036,0,0,0.225,0,0,6.42,65.62,176,2.288,2.626,0.236,31865
"Beef, round, tip round, roast, separable lean only, trimmed to 0"" fat, choice, raw",Beef Products,20.76,0.018,0.052,0,0,0.318,0,0,4.55,73.98,130,1.556,1.855,0.227,31866
"Beef, round, tip round, roast, separable lean only, trimmed to 0"" fat, select, cooked, roasted",Beef Products,27.37,0.006,0.036,0,0,0.23,0,0,4.38,67.88,149,1.563,1.794,0.161,31867
"Beef, round, tip round, roast, separable lean only, trimmed to 0"" fat, select, raw",Beef Products,21.38,0.02,0.058,0,0,0.337,0,0,3.35,74.99,122,1.147,1.367,0.168,31868
"Beef, round, tip round, separable lean and fat, trimmed to 1/8"" fat, all grades, raw",Beef Products,19.6,0.005,0.058,0,0,0.329,0,0,11.67,66.1,189,4.6,5,0.46,31869
"Beef, round, tip round, separable lean and fat, trimmed to 1/8"" fat, choice, raw",Beef Products,19.48,0.005,0.058,0,0,0.326,0,0,12.83,65.43,199,5.04,5.49,0.51,31870
"Beef, round, tip round, separable lean and fat, trimmed to 1/8"" fat, select, raw",Beef Products,19.74,0.005,0.058,0,0,0.332,0,0,10.39,66.86,178,4.1,4.45,0.41,31871
"Beef, round, top round steak, separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, broiled",Beef Products,30.67,0.007,0.041,0,0,0.252,0,0,9,59.59,204,3.415,3.837,0.347,31872
"Beef, round, top round, separable lean and fat, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,35.62,0.004,0.045,0,0,0.33,0,0,6.31,57.9,209,2.25,2.51,0.27,31873
"Beef, round, top round, separable lean and fat, trimmed to 0"" fat, choice, cooked, braised",Beef Products,35.62,0.004,0.045,0,0,0.33,0,0,7.09,57.31,216,2.52,2.82,0.31,31874
"Beef, round, top round, separable lean and fat, trimmed to 0"" fat, select, cooked, braised",Beef Products,35.62,0.004,0.045,0,0,0.33,0,0,5.33,58.49,200,1.92,2.13,0.23,31875
"Beef, round, top round, separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, braised",Beef Products,34.34,0.005,0.045,0,0,0.319,0,0,10.13,54.68,238,3.78,4.15,0.42,31876
"Beef, round, top round, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, braised",Beef Products,34.09,0.005,0.045,0,0,0.317,0,0,11.61,53.83,250,4.33,4.76,0.48,31877
"Beef, round, top round, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, pan-fried",Beef Products,32.99,0.006,0.068,0,0,0.48,0,0,13.83,52.36,266,4.64,5.18,1.72,31878
"Beef, round, top round, separable lean and fat, trimmed to 1/8"" fat, prime, raw",Beef Products,22.24,0.003,0.051,0,0,0.371,0,0,8.67,68.73,173,3.3,3.59,0.38,31879
"Beef, round, top round, separable lean and fat, trimmed to 1/8"" fat, select, cooked, braised",Beef Products,34.6,0.004,0.045,0,0,0.321,0,0,8.54,55.63,225,3.2,3.51,0.35,31880
"Beef, round, top round, separable lean only, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,36.12,0.004,0.045,0,0,0.334,0,0,5,58.7,199,1.72,1.94,0.22,31881
"Beef, round, top round, separable lean only, trimmed to 0"" fat, choice, cooked, braised",Beef Products,36.12,0.004,0.045,0,0,0.334,0,0,5.8,58.1,207,1.99,2.25,0.26,31882
"Beef, round, top round, separable lean only, trimmed to 0"" fat, select, cooked, braised",Beef Products,36.12,0.004,0.045,0,0,0.334,0,0,4,59.3,190,1.37,1.55,0.18,31883
"Beef, round, top round, separable lean only, trimmed to 1/8"" fat, choice, cooked, pan-fried",Beef Products,33.93,0.006,0.065,0,0,0.484,2.03,0,8.33,54.18,228,2.399,3.031,1.504,31884
"Beef, round, top round, steak, separable lean and fat, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,31.61,0.007,0.041,0,0,0.252,0,0,5.89,61.85,188,2.116,2.493,0.222,31885
"Beef, round, top round, steak, separable lean and fat, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,31.57,0.007,0.042,0,0,0.26,0,0,7.17,60,200,2.578,3.037,0.27,31886
"Beef, round, top round, steak, separable lean and fat, trimmed to 0"" fat, select, cooked, broiled",Beef Products,31.65,0.008,0.042,0,0,0.257,0,0,4.6,63.71,177,1.654,1.949,0.173,31887
"Beef, round, top round, steak, separable lean and fat, trimmed to 1/8"" fat, all grades, raw",Beef Products,22.06,0.021,0.06,0,0,0.349,0,0,7.93,69.04,166,3.123,3.428,0.303,31888
"Beef, round, top round, steak, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, broiled",Beef Products,30.7,0.007,0.04,0,0,0.248,0,0,10.27,58.46,224,3.898,4.379,0.396,31889
"Beef, round, top round, steak, separable lean and fat, trimmed to 1/8"" fat, choice, raw",Beef Products,21.94,0.017,0.057,0,0,0.341,0,0,8.19,68.8,168,3.224,3.538,0.313,31890
"Beef, round, top round, steak, separable lean and fat, trimmed to 1/8"" fat, prime, cooked, broiled",Beef Products,31.27,0.006,0.061,0,0,0.436,0,0,10.1,59.51,225,3.61,4.01,0.46,31891
"Beef, round, top round, steak, separable lean and fat, trimmed to 1/8"" fat, select, cooked, broiled",Beef Products,30.63,0.008,0.041,0,0,0.256,0,0,7.73,60.72,201,2.933,3.296,0.298,31892
"Beef, round, top round, steak, separable lean and fat, trimmed to 1/8"" fat, select, raw",Beef Products,22.18,0.025,0.063,0,0,0.357,0,0,7.68,69.29,164,3.023,3.317,0.293,31893
"Beef, round, top round, steak, separable lean only, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,31.7,0.007,0.042,0,0,0.262,0,0,5.63,62.02,186,1.965,2.373,0.21,31894
"Beef, round, top round, steak, separable lean only, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,31.7,0.007,0.043,0,0,0.265,0,0,6.81,60.24,197,2.376,2.869,0.254,31895
"Beef, round, top round, steak, separable lean only, trimmed to 0"" fat, select, cooked, broiled",Beef Products,31.7,0.007,0.042,0,0,0.263,0,0,4.45,63.81,176,1.554,1.877,0.166,31896
"Beef, round, top round, steak, separable lean only, trimmed to 1/8"" fat, all grades, cooked, broiled",Beef Products,31.82,0.007,0.042,0,0,0.264,0,0,5.45,61.96,185,1.878,2.278,0.22,31897
"Beef, round, top round, steak, separable lean only, trimmed to 1/8"" fat, all grades, raw",Beef Products,22.91,0.02,0.061,0,0,0.362,0,0,4.09,75.02,135,1.399,1.721,0.175,31898
"Beef, round, top round, steak, separable lean only, trimmed to 1/8"" fat, choice, cooked, broiled",Beef Products,32.04,0.007,0.042,0,0,0.263,0,0,6.25,61.13,193,2.154,2.613,0.252,31899
"Beef, round, top round, steak, separable lean only, trimmed to 1/8"" fat, choice, raw",Beef Products,22.69,0.02,0.057,0,0,0.352,0,0,4.78,71.44,140,1.637,2.014,0.205,31900
"Beef, round, top round, steak, separable lean only, trimmed to 1/8"" fat, select, cooked, broiled",Beef Products,31.61,0.007,0.043,0,0,0.27,0,0,4.65,62.79,177,1.602,1.944,0.188,31901
"Beef, round, top round, steak, separable lean only, trimmed to 1/8"" fat, select, raw",Beef Products,23.13,0.022,0.064,0,0,0.373,0,0,3.37,72.63,129,1.154,1.42,0.145,31902
"Beef, sandwich steaks, flaked, chopped, formed and thinly sliced, raw",Beef Products,16.5,0.012,0.068,0,0,0.233,0,0,27,56,309,11.538,11.038,0.488,31903
"Beef, shank crosscuts, separable lean only, trimmed to 1/4"" fat, choice, cooked, simmered",Beef Products,33.68,0.032,0.064,0,0,0.447,0,0,6.36,58.21,201,2.29,2.86,0.21,31904
"Beef, shank crosscuts, separable lean only, trimmed to 1/4"" fat, choice, raw",Beef Products,21.75,0.02,0.063,0,0,0.387,0,0,3.85,73.03,128,1.28,1.76,0.15,31905
"Beef, short loin, porterhouse steak, separable lean and fat, trimmed to 0"" fat, USDA choice, cooked, broiled",Beef Products,23.61,0.007,0.065,0,0,0.278,0,0,20.15,53.95,283,7.43,9.275,0.676,31906
"Beef, short loin, porterhouse steak, separable lean and fat, trimmed to 0"" fat, USDA select, cooked, broiled",Beef Products,24.47,0.007,0.065,0,0,0.33,0,0,17.98,55.69,267,7.04,7.739,0.711,31907
"Beef, short loin, porterhouse steak, separable lean and fat, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,23.96,0.007,0.065,0,0,0.299,0,0,19.27,54.66,276,7.271,8.65,0.69,31908
"Beef, short loin, porterhouse steak, separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, broiled",Beef Products,23.51,0.008,0.064,0,0,0.321,0,0,21.83,52.62,297,8.42,9.59,0.82,31909
"Beef, short loin, porterhouse steak, separable lean and fat, trimmed to 1/8"" fat, all grades, raw",Beef Products,18.8,0.006,0.053,0,0,0.299,0,0,18.51,61.26,247,7.42,8.2,0.7,31910
"Beef, short loin, porterhouse steak, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, broiled",Beef Products,23.27,0.008,0.064,0,0,0.322,0,0,22.11,52.61,299,8.45,9.78,0.79,31911
"Beef, short loin, porterhouse steak, separable lean and fat, trimmed to 1/8"" fat, choice, raw",Beef Products,17.98,0.006,0.052,0,0,0.295,0,0,20.08,60.21,258,8.02,8.94,0.74,31912
"Beef, short loin, porterhouse steak, separable lean and fat, trimmed to 1/8"" fat, select, cooked, broiled",Beef Products,24.05,0.008,0.064,0,0,0.32,0,0,21.18,52.66,294,8.34,9.13,0.89,31913
"Beef, short loin, porterhouse steak, separable lean and fat, trimmed to 1/8"" fat, select, raw",Beef Products,20.72,0.006,0.054,0,0,0.309,0,0,14.82,63.7,222,6.01,6.46,0.63,31914
"Beef, short loin, porterhouse steak, separable lean only, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,26.07,0.006,0.069,0,0,0.328,0,0,11.18,60.38,212,3.912,5.192,0.379,31915
"Beef, short loin, porterhouse steak, separable lean only, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,25.51,0.006,0.069,0,0,0.301,0,0,12.8,59.13,224,4.345,6.175,0.385,31916
"Beef, short loin, porterhouse steak, separable lean only, trimmed to 0"" fat, select, cooked, broiled",Beef Products,26.89,0.006,0.069,0,0,0.367,0,0,8.81,62.19,194,3.28,3.76,0.37,31917
"Beef, short loin, t-bone steak, separable lean and fat, trimmed to 0"" fat, USDA choice, cooked, broiled",Beef Products,24.05,0.005,0.067,0,0,0.3,0,0,17.26,56.97,258,6.449,7.896,0.615,31918
"Beef, short loin, t-bone steak, separable lean and fat, trimmed to 0"" fat, USDA select, cooked, broiled",Beef Products,24.38,0.005,0.068,0,0,0.305,0,0,14,58.97,230,5.527,6.197,0.62,31919
"Beef, short loin, t-bone steak, separable lean and fat, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,24.18,0.005,0.067,0,0,0.302,0,0,15.93,57.78,247,6.074,7.205,0.617,31920
"Beef, short loin, t-bone steak, separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, broiled",Beef Products,24.33,0.008,0.066,0,0,0.336,0,0,19.47,54.57,280,7.56,8.54,0.7,31921
"Beef, short loin, t-bone steak, separable lean and fat, trimmed to 1/8"" fat, all grades, raw",Beef Products,19.19,0.006,0.054,0,0,0.311,0,0,15.32,63.48,220,6.14,6.76,0.6,31922
"Beef, short loin, t-bone steak, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, broiled",Beef Products,24.04,0.008,0.066,0,0,0.334,0,0,20.3,54.16,286,7.83,8.94,0.71,31923
"Beef, short loin, t-bone steak, separable lean and fat, trimmed to 1/8"" fat, choice, raw",Beef Products,18.9,0.006,0.053,0,0,0.308,0,0,16.81,62.28,232,6.7,7.47,0.62,31924
"Beef, short loin, t-bone steak, separable lean and fat, trimmed to 1/8"" fat, select, cooked, broiled",Beef Products,25.01,0.008,0.066,0,0,0.342,0,0,17.54,55.53,265,6.91,7.6,0.66,31925
"Beef, short loin, t-bone steak, separable lean and fat, trimmed to 1/8"" fat, select, raw",Beef Products,19.89,0.006,0.055,0,0,0.319,0,0,11.84,66.28,192,4.81,5.1,0.55,31926
"Beef, short loin, t-bone steak, separable lean only, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,25.99,0.004,0.071,0,0,0.327,0,0,8.69,63,189,3.087,4.114,0.344,31927
"Beef, short loin, t-bone steak, separable lean only, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,25.98,0.004,0.071,0,0,0.327,0,0,9.61,62.5,198,3.27,4.645,0.32,31928
"Beef, short loin, t-bone steak, separable lean only, trimmed to 0"" fat, select, cooked, broiled",Beef Products,26,0.004,0.071,0,0,0.327,0,0,7.36,63.73,177,2.82,3.34,0.38,31929
"Beef, short loin, t-bone steak, separable lean only, trimmed to 1/4"" fat, all grades, cooked, broiled",Beef Products,27.01,0.005,0.075,0,0,0.327,0,0,9.6,61.49,202,3.488,4.542,0.314,31930
"Beef, short loin, top loin, separable lean and fat, trimmed to 1/8"" fat, prime, cooked, broiled",Beef Products,25.92,0.009,0.064,0,0,0.354,0,0,22.12,51.34,310,8.91,9.39,0.8,31931
"Beef, short loin, top loin, steak, separable lean and fat, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,28.87,0.021,0.059,0,0,0.356,0,0,7.68,63.33,193,2.981,3.144,0.285,31932
"Beef, short loin, top loin, steak, separable lean and fat, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,28.45,0.018,0.056,0,0,0.342,0,0,9.22,62.1,205,3.58,3.776,0.342,31933
"Beef, short loin, top loin, steak, separable lean and fat, trimmed to 0"" fat, select, cooked, broiled",Beef Products,29.29,0.023,0.061,0,0,0.37,0,0,6.14,64.56,180,2.382,2.513,0.228,31934
"Beef, short loin, top loin, steak, separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, broiled",Beef Products,26.44,0.019,0.054,0,0,0.328,0,0,16.78,56.48,264,6.608,6.993,0.621,31935
"Beef, short loin, top loin, steak, separable lean and fat, trimmed to 1/8"" fat, all grades, raw",Beef Products,20.61,0.024,0.052,0,0,0.313,0,0,15.49,63.43,228,6.251,6.633,0.589,31936
"Beef, short loin, top loin, steak, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, broiled",Beef Products,26.16,0.017,0.052,0,0,0.316,0,0,18.45,54.99,278,7.264,7.688,0.683,31937
"Beef, short loin, top loin, steak, separable lean and fat, trimmed to 1/8"" fat, choice, raw",Beef Products,20.63,0.025,0.051,0,0,0.313,0,0,15.95,62.91,232,6.435,6.828,0.607,31938
"Beef, short loin, top loin, steak, separable lean and fat, trimmed to 1/8"" fat, prime, raw",Beef Products,19,0.006,0.053,0,0,0.295,0,0,22.17,58.42,281,9.08,9.78,0.8,31939
"Beef, short loin, top loin, steak, separable lean and fat, trimmed to 1/8"" fat, select, cooked, broiled",Beef Products,26.72,0.022,0.057,0,0,0.34,0,0,15.11,57.96,250,5.951,6.298,0.56,31940
"Beef, short loin, top loin, steak, separable lean and fat, trimmed to 1/8"" fat, select, raw",Beef Products,20.59,0.023,0.052,0,0,0.313,0,0,15.04,63.95,224,6.067,6.438,0.572,31941
"Beef, short loin, top loin, steak, separable lean only, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,29.25,0.019,0.06,0,0,0.37,0,0,6.37,64.27,182,2.425,2.542,0.234,31942
"Beef, short loin, top loin, steak, separable lean only, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,28.91,0.016,0.059,0,0,0.356,0,0,7.63,63.24,192,2.906,3.045,0.281,31943
"Beef, short loin, top loin, steak, separable lean only, trimmed to 0"" fat, select, cooked, broiled",Beef Products,29.59,0.021,0.062,0,0,0.386,0,0,5.11,65.3,172,1.945,2.038,0.188,31944
"Beef, short loin, top loin, steak, separable lean only, trimmed to 1/8"" fat, all grades, cooked, broiled",Beef Products,29.3,0.019,0.061,0,0,0.372,0,0,7.09,63.32,189,2.7,2.831,0.253,31945
"Beef, short loin, top loin, steak, separable lean only, trimmed to 1/8"" fat, all grades, raw",Beef Products,22.93,0.025,0.057,0,0,0.354,0,0,5.15,71.49,138,1.905,2.073,0.223,31946
"Beef, short loin, top loin, steak, separable lean only, trimmed to 1/8"" fat, choice, cooked, broiled",Beef Products,29.16,0.016,0.06,0,0,0.362,0,0,8.45,61.99,201,3.217,3.373,0.302,31947
"Beef, short loin, top loin, steak, separable lean only, trimmed to 1/8"" fat, choice, raw",Beef Products,22.78,0.029,0.058,0,0,0.348,0,0,6.43,70.3,155,2.378,2.588,0.278,31948
"Beef, short loin, top loin, steak, separable lean only, trimmed to 1/8"" fat, select, cooked, broiled",Beef Products,29.44,0.021,0.062,0,0,0.386,0,0,5.73,64.65,177,2.183,2.288,0.205,31949
"Beef, short loin, top loin, steak, separable lean only, trimmed to 1/8"" fat, select, raw",Beef Products,23.07,0.023,0.057,0,0,0.361,0,0,3.88,72.68,133,1.432,1.559,0.168,31950
"Beef, shoulder pot roast or steak, boneless, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,21.39,0.012,0.072,0,0,0.395,0,0,4.97,72.58,130,2.033,2.497,0.579,31951
"Beef, shoulder pot roast or steak, boneless, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,21.17,0.012,0.074,0,0,0.403,0.07,0,5.33,72.3,133,2.124,2.648,0.582,31952
"Beef, shoulder pot roast or steak, boneless, separable lean and fat, trimmed to 0"" fat, select, raw",Beef Products,21.72,0.012,0.07,0,0,0.383,0,0,4.44,73.01,127,1.897,2.271,0.575,31953
"Beef, shoulder pot roast or steak, boneless, separable lean only, trimmed to 0"" fat, all grades, raw",Beef Products,21.64,0.012,0.073,0,0,0.399,0,0,4.09,73.17,123,1.653,2.086,0.296,31954
"Beef, shoulder pot roast or steak, boneless, separable lean only, trimmed to 0"" fat, choice, raw",Beef Products,21.45,0.012,0.074,0,0,0.408,0.11,0,4.35,72.95,125,1.709,2.199,0.297,31955
"Beef, shoulder pot roast or steak, boneless, separable lean only, trimmed to 0"" fat, select, raw",Beef Products,21.93,0.012,0.071,0,0,0.386,0,0,3.7,73.51,121,1.57,1.917,0.295,31956
"Beef, shoulder pot roast, boneless, separable lean and fat, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,31.03,0.013,0.061,0,0,0.352,0,0,8.92,59.76,204,3.112,3.887,0.909,31957
"Beef, shoulder pot roast, boneless, separable lean and fat, trimmed to 0"" fat, choice, cooked, braised",Beef Products,30.93,0.013,0.06,0,0,0.353,0,0,9.25,59.49,207,3.264,4.203,0.879,31958
"Beef, shoulder pot roast, boneless, separable lean and fat, trimmed to 0"" fat, select, cooked, braised",Beef Products,31.18,0.013,0.062,0,0,0.352,0,0,8.41,60.15,200,2.884,3.414,0.953,31959
"Beef, shoulder pot roast, boneless, separable lean only, trimmed to 0"" fat, all grades, cooked, braised",Beef Products,31.48,0.013,0.061,0,0,0.359,0,0,7.78,60.41,196,2.596,3.346,0.474,31960
"Beef, shoulder pot roast, boneless, separable lean only, trimmed to 0"" fat, choice, cooked, braised",Beef Products,31.32,0.013,0.06,0,0,0.358,0,0,8.3,60.05,200,2.835,3.757,0.47,31961
"Beef, shoulder pot roast, boneless, separable lean only, trimmed to 0"" fat, select, cooked, braised",Beef Products,31.71,0.013,0.063,0,0,0.36,0,0,7.02,60.96,190,2.239,2.731,0.48,31962
"Beef, shoulder steak, boneless, separable lean and fat, trimmed to 0"" fat, all grades, cooked, grilled",Beef Products,28.29,0.012,0.067,0,0,0.366,0,0,6.8,64.02,182,2.911,3.503,0.716,31963
"Beef, shoulder steak, boneless, separable lean and fat, trimmed to 0"" fat, choice, cooked, grilled",Beef Products,28.22,0.012,0.067,0,0,0.367,0,0,7.25,63.61,186,3.09,3.785,0.786,31964
"Beef, shoulder steak, boneless, separable lean and fat, trimmed to 0"" fat, select, cooked, grilled",Beef Products,28.41,0.012,0.067,0,0,0.366,0,0,6.14,64.65,177,2.642,3.081,0.611,31965
"Beef, shoulder steak, boneless, separable lean only, trimmed to 0"" fat, all grades, cooked, grilled",Beef Products,28.6,0.012,0.068,0,0,0.372,0,0,5.82,64.62,175,2.489,3.058,0.375,31966
"Beef, shoulder steak, boneless, separable lean only, trimmed to 0"" fat, choice, cooked, grilled",Beef Products,28.54,0.012,0.068,0,0,0.372,0,0,6.25,64.21,178,2.687,3.362,0.421,31967
"Beef, shoulder steak, boneless, separable lean only, trimmed to 0"" fat, select, cooked, grilled",Beef Products,28.71,0.012,0.068,0,0,0.371,0,0,5.17,65.24,169,2.192,2.603,0.306,31968
"Beef, shoulder top blade steak, boneless, separable lean and fat, trimmed to 0"" fat, all grades, cooked, grilled",Beef Products,27.59,0.014,0.085,0,0,0.379,0,0,11.07,61.04,210,4.528,5.298,1.034,31969
"Beef, shoulder top blade steak, boneless, separable lean and fat, trimmed to 0"" fat, all grades, raw",Beef Products,20.16,0.012,0.082,0,0,0.335,0,0,7.25,71.89,146,3.153,3.771,0.738,31970
"Beef, shoulder top blade steak, boneless, separable lean and fat, trimmed to 0"" fat, choice, cooked, grilled",Beef Products,27.51,0.014,0.084,0,0,0.374,0,0,12.26,60.31,220,4.968,6.036,1.001,31971
"Beef, shoulder top blade steak, boneless, separable lean and fat, trimmed to 0"" fat, choice, raw",Beef Products,20.07,0.012,0.081,0,0,0.336,0,0,7.94,71.12,152,3.462,4.207,0.762,31972
"Beef, shoulder top blade steak, boneless, separable lean and fat, trimmed to 0"" fat, select, cooked, grilled",Beef Products,27.7,0.014,0.088,0,0,0.388,0,0,9.29,62.15,194,3.868,4.191,1.083,31973
"Beef, shoulder top blade steak, boneless, separable lean and fat, trimmed to 0"" fat, select, raw",Beef Products,20.28,0.012,0.086,0,0,0.344,0,0,6.21,73.04,137,2.69,3.118,0.701,31974
"Beef, shoulder top blade steak, boneless, separable lean only, trimmed to 0"" fat, all grades, cooked, grilled",Beef Products,28.15,0.014,0.087,0,0,0.39,0,0,9.23,62.11,196,3.771,4.509,0.52,31975
"Beef, shoulder top blade steak, boneless, separable lean only, trimmed to 0"" fat, all grades, raw",Beef Products,20.36,0.012,0.083,0,0,0.338,0,0,6.42,72.42,139,2.817,3.409,0.389,31976
"Beef, shoulder top blade steak, boneless, separable lean only, trimmed to 0"" fat, choice, cooked, grilled",Beef Products,28.28,0.014,0.085,0,0,0.389,0,0,9.83,61.73,202,3.984,5.018,0.484,31977
"Beef, shoulder top blade steak, boneless, separable lean only, trimmed to 0"" fat, choice, raw",Beef Products,20.35,0.012,0.082,0,0,0.34,0,0,6.88,71.79,143,3.047,3.762,0.398,31978
"Beef, shoulder top blade steak, boneless, separable lean only, trimmed to 0"" fat, select, cooked, grilled",Beef Products,27.96,0.014,0.089,0,0,0.394,0,0,8.34,62.67,187,3.451,3.746,0.573,31979
"Beef, shoulder top blade steak, boneless, separable lean only, trimmed to 0"" fat, select, raw",Beef Products,20.39,0.012,0.087,0,0,0.346,0,0,5.73,73.35,133,2.472,2.88,0.375,31980
"Beef, tenderloin, separable lean and fat, trimmed to 0"" fat, select, cooked, broiled",Beef Products,27.18,0.022,0.058,0,0,0.346,0,0,9.83,62.73,205,3.816,4.026,0.365,31981
"Beef, tenderloin, separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, roasted",Beef Products,23.9,0.009,0.057,0,0,0.331,0,0,24.6,48.37,324,9.72,10.27,1,31982
"Beef, tenderloin, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, roasted",Beef Products,23.9,0.009,0.065,0,0,0.407,0,0,25.39,47.99,331,10.02,10.5,0.96,31983
"Beef, tenderloin, separable lean and fat, trimmed to 1/8"" fat, prime, cooked, roasted",Beef Products,24.04,0.008,0.055,0,0,0.336,0,0,26.67,48.28,343,10.63,11.01,1.03,31984
"Beef, tenderloin, separable lean and fat, trimmed to 1/8"" fat, prime, raw",Beef Products,18.15,0.007,0.049,0,0,0.304,0,0,21.83,59.52,274,8.96,9.26,0.87,31985
"Beef, tenderloin, separable lean and fat, trimmed to 1/8"" fat, select, cooked, roasted",Beef Products,23.9,0.009,0.057,0,0,0.331,0,0,23.7,48.86,316,9.38,9.92,0.96,31986
"Beef, tenderloin, steak, separable lean and fat, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,27.58,0.02,0.056,0,0,0.341,0,0,11.12,61.07,218,4.315,4.551,0.413,31987
"Beef, tenderloin, steak, separable lean and fat, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,27.97,0.018,0.055,0,0,0.336,0,0,12.4,59.4,231,4.813,5.077,0.46,31988
"Beef, tenderloin, steak, separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, broiled",Beef Products,26.46,0.019,0.054,0,0,0.329,0,0,17.12,56.04,267,6.743,7.136,0.634,31989
"Beef, tenderloin, steak, separable lean and fat, trimmed to 1/8"" fat, all grades, raw",Beef Products,19.61,0.023,0.05,0,0,0.302,0,0,18.16,61.52,247,7.327,7.774,0.691,31990
"Beef, tenderloin, steak, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, broiled",Beef Products,26.43,0.017,0.052,0,0,0.318,0,0,17.78,55.53,273,7.001,7.409,0.658,31991
"Beef, tenderloin, steak, separable lean and fat, trimmed to 1/8"" fat, choice, raw",Beef Products,19.82,0.025,0.05,0,0,0.303,0,0,17.88,61.69,246,7.214,7.655,0.68,31992
"Beef, tenderloin, steak, separable lean and fat, trimmed to 1/8"" fat, prime, cooked, broiled",Beef Products,25.26,0.008,0.059,0,0,0.368,0,0,22.21,51.84,308,8.85,9.17,0.86,31993
"Beef, tenderloin, steak, separable lean and fat, trimmed to 1/8"" fat, select, cooked, broiled",Beef Products,26.48,0.022,0.057,0,0,0.34,0,0,16.53,56.5,262,6.511,6.89,0.612,31994
"Beef, tenderloin, steak, separable lean and fat, trimmed to 1/8"" fat, select, raw",Beef Products,19.37,0.022,0.05,0,0,0.3,0,0,18.46,61.33,249,7.451,7.906,0.702,31995
"Beef, tenderloin, steak, separable lean only, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,28.51,0.018,0.059,0,0,0.362,0,0,7.86,63.4,193,2.993,3.136,0.289,31996
"Beef, tenderloin, steak, separable lean only, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,28.95,0.016,0.059,0,0,0.357,0,0,9.14,61.71,206,3.48,3.646,0.336,31997
"Beef, tenderloin, steak, separable lean only, trimmed to 0"" fat, select, cooked, broiled",Beef Products,28.08,0.02,0.059,0,0,0.369,0,0,6.58,65.1,179,2.506,2.626,0.242,31998
"Beef, tenderloin, steak, separable lean only, trimmed to 1/8"" fat, all grades, cooked, broiled",Beef Products,29.04,0.019,0.06,0,0,0.37,0,0,8.39,62.93,200,3.195,3.35,0.3,31999
"Beef, tenderloin, steak, separable lean only, trimmed to 1/8"" fat, all grades, raw",Beef Products,22.12,0.024,0.056,0,0,0.347,0,0,6.52,70.62,153,2.412,2.624,0.282,32000
"Beef, tenderloin, steak, separable lean only, trimmed to 1/8"" fat, choice, cooked, broiled",Beef Products,29.01,0.016,0.059,0,0,0.358,0,0,9.1,61.68,206,3.465,3.633,0.325,32001
"Beef, tenderloin, steak, separable lean only, trimmed to 1/8"" fat, choice, raw",Beef Products,22.17,0.028,0.057,0,0,0.34,0,0,7.07,70.17,158,2.612,2.842,0.306,32002
"Beef, tenderloin, steak, separable lean only, trimmed to 1/8"" fat, select, cooked, broiled",Beef Products,29.07,0.021,0.062,0,0,0.386,0,0,7.76,62.67,194,2.953,3.096,0.277,32003
"Beef, tenderloin, steak, separable lean only, trimmed to 1/8"" fat, select, raw",Beef Products,22.06,0.022,0.055,0,0,0.354,0,0,5.93,71.11,148,2.191,2.384,0.256,32004
"Beef, top sirloin, steak, separable lean and fat, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,29.33,0.022,0.061,0,0,0.368,0,0,9.67,60.33,212,3.754,3.959,0.359,32005
"Beef, top sirloin, steak, separable lean and fat, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,29.02,0.019,0.058,0,0,0.355,0,0,10.54,59.68,219,4.09,4.314,0.391,32006
"Beef, top sirloin, steak, separable lean and fat, trimmed to 0"" fat, select, cooked, broiled",Beef Products,29.65,0.024,0.063,0,0,0.381,0,0,8.8,60.97,206,3.417,3.604,0.327,32007
"Beef, top sirloin, steak, separable lean and fat, trimmed to 1/8"" fat, all grades, cooked, broiled",Beef Products,26.96,0.02,0.056,0,0,0.336,0,0,14.23,58.36,243,5.603,5.93,0.527,32008
"Beef, top sirloin, steak, separable lean and fat, trimmed to 1/8"" fat, all grades, raw",Beef Products,20.3,0.024,0.052,0,0,0.315,0,0,12.71,66.09,201,5.127,5.44,0.483,32009
"Beef, top sirloin, steak, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, broiled",Beef Products,26.8,0.018,0.054,0,0,0.327,0,0,15.75,56.81,257,6.202,6.564,0.583,32010
"Beef, top sirloin, steak, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, pan-fried",Beef Products,28.77,0.012,0.071,0,0,0.406,0,0,21.06,49.56,313,8.18,8.86,1.71,32011
"Beef, top sirloin, steak, separable lean and fat, trimmed to 1/8"" fat, choice, raw",Beef Products,19.92,0.025,0.051,0,0,0.309,0,0,14.28,64.87,214,5.763,6.115,0.543,32012
"Beef, top sirloin, steak, separable lean and fat, trimmed to 1/8"" fat, select, cooked, broiled",Beef Products,27.12,0.022,0.057,0,0,0.345,0,0,12.71,59.92,230,5.004,5.296,0.471,32013
"Beef, top sirloin, steak, separable lean and fat, trimmed to 1/8"" fat, select, raw",Beef Products,20.68,0.024,0.053,0,0,0.321,0,0,11.13,67.32,189,4.491,4.765,0.423,32014
"Beef, top sirloin, steak, separable lean only, trimmed to 0"" fat, all grades, cooked, broiled",Beef Products,30.55,0.02,0.064,0,0,0.393,0,0,5.79,62.98,183,2.205,2.31,0.213,32015
"Beef, top sirloin, steak, separable lean only, trimmed to 0"" fat, choice, cooked, broiled",Beef Products,30.29,0.017,0.063,0,0,0.38,0,0,6.55,62.38,188,2.495,2.615,0.241,32016
"Beef, top sirloin, steak, separable lean only, trimmed to 0"" fat, select, cooked, broiled",Beef Products,30.8,0.022,0.066,0,0,0.41,0,0,5.03,63.58,177,1.915,2.006,0.185,32017
"Beef, top sirloin, steak, separable lean only, trimmed to 1/8"" fat, all grades, cooked, broiled",Beef Products,29.42,0.019,0.061,0,0,0.376,0,0,5.84,64.29,178,2.224,2.331,0.208,32018
"Beef, top sirloin, steak, separable lean only, trimmed to 1/8"" fat, all grades, raw",Beef Products,22.09,0.025,0.056,0,0,0.349,0,0,4.08,72.91,131,1.508,1.641,0.176,32019
"Beef, top sirloin, steak, separable lean only, trimmed to 1/8"" fat, choice, cooked, broiled",Beef Products,29.51,0.017,0.061,0,0,0.369,0,0,6.72,63.27,187,2.56,2.684,0.24,32020
"Beef, top sirloin, steak, separable lean only, trimmed to 1/8"" fat, choice, raw",Beef Products,21.91,0.028,0.057,0,0,0.342,0,0,4.62,72.51,135,1.709,1.86,0.2,32021
"Beef, top sirloin, steak, separable lean only, trimmed to 1/8"" fat, select, cooked, broiled",Beef Products,29.34,0.021,0.062,0,0,0.386,0,0,4.96,65.47,170,1.889,1.98,0.177,32022
"Beef, top sirloin, steak, separable lean only, trimmed to 1/8"" fat, select, raw",Beef Products,22.27,0.022,0.056,0,0,0.357,0,0,3.54,73.31,127,1.307,1.422,0.153,32023
"Beef, variety meats and by-products, brain, cooked, pan-fried",Beef Products,12.57,0.009,0.158,0,0.0033,0.354,0,0,15.83,70.75,196,3.74,3.98,2.31,32024
"Beef, variety meats and by-products, brain, cooked, simmered",Beef Products,11.67,0.009,0.108,0,0.0105,0.244,1.48,0,10.53,74.86,151,2.394,1.882,1.632,32025
"Beef, variety meats and by-products, brain, raw",Beef Products,10.86,0.043,0.126,0,0.0107,0.274,1.05,0,10.3,76.29,143,2.3,1.89,1.586,32026
"Beef, variety meats and by-products, heart, cooked, simmered",Beef Products,28.48,0.005,0.059,0,0,0.219,0.15,0,4.73,65.67,165,1.404,1.011,0.985,32027
"Beef, variety meats and by-products, heart, raw",Beef Products,17.72,0.007,0.098,0,0.002,0.287,0.14,0,3.94,77.11,112,1.383,1.137,0.546,32028
"Beef, variety meats and by-products, kidneys, cooked, simmered",Beef Products,27.27,0.019,0.094,0,0,0.135,0,0,4.65,66.88,158,1.066,0.711,0.827,32029
"Beef, variety meats and by-products, kidneys, raw",Beef Products,17.4,0.013,0.182,0,0.0094,0.262,0.29,0,3.09,77.89,99,0.868,0.586,0.545,32030
"Beef, variety meats and by-products, liver, cooked, braised",Beef Products,29.08,0.006,0.079,0,0.0019,0.352,5.13,0,5.26,58.81,191,1.696,0.647,0.639,32031
"Beef, variety meats and by-products, liver, cooked, pan-fried",Beef Products,26.52,0.006,0.077,0,0.0007,0.351,5.16,0,4.68,62.01,175,1.493,0.653,0.602,32032
"Beef, variety meats and by-products, liver, raw",Beef Products,20.36,0.005,0.069,0,0.0013,0.313,3.89,0,3.63,70.81,135,1.233,0.479,0.465,32033
"Beef, variety meats and by-products, lungs, cooked, braised",Beef Products,20.4,0.011,0.101,0,0.0327,0.173,0,0,3.7,76.4,120,1.27,0.95,0.51,32034
"Beef, variety meats and by-products, lungs, raw",Beef Products,16.2,0.01,0.198,0,0.0385,0.34,0,0,2.5,79.38,92,0.86,0.64,0.34,32035
"Beef, variety meats and by-products, mechanically separated beef, raw",Beef Products,14.97,0.485,0.057,0,0,0.277,0,0,23.52,59.39,276,11.78,8.44,0.76,32036
"Beef, variety meats and by-products, pancreas, cooked, braised",Beef Products,27.1,0.016,0.06,0,0.020300000000000002,0.246,0,0,17.2,55.6,271,5.9,5.93,3.2,32037
"Beef, variety meats and by-products, pancreas, raw",Beef Products,15.7,0.009,0.067,0,0.013699999999999999,0.276,0,0,18.6,65.2,235,6.41,6.44,3.47,32038
"Beef, variety meats and by-products, spleen, cooked, braised",Beef Products,25.1,0.012,0.057,0,0.0503,0.284,0,0,4.2,69.98,145,1.39,1.12,0.31,32039
"Beef, variety meats and by-products, spleen, raw",Beef Products,18.3,0.009,0.085,0,0.0455,0.429,0,0,3,77.2,105,1,0.78,0.22,32040
"Beef, variety meats and by-products, suet, raw",Beef Products,1.5,0.002,0.007,0,0,0.016,0,0,94,4,854,52.3,31.52,3.17,32041
"Beef, variety meats and by-products, thymus, cooked, braised",Beef Products,21.85,0.01,0.116,0,0.030199999999999998,0.433,0,0,24.98,52.83,319,8.61,8.64,4.66,32042
"Beef, variety meats and by-products, thymus, raw",Beef Products,12.18,0.007,0.096,0,0.034,0.36,0,0,20.35,67.8,236,7.01,7.05,3.8,32043
"Beef, variety meats and by-products, tongue, cooked, simmered",Beef Products,19.29,0.005,0.065,0,0.0013,0.184,0,0,22.3,57.87,284,8.125,10.102,0.746,32044
"Beef, variety meats and by-products, tongue, raw",Beef Products,14.9,0.006,0.069,0,0.0031,0.315,3.68,0,16.09,64.53,224,7,7.24,0.9,32045
"Beef, variety meats and by-products, tripe, cooked, simmered",Beef Products,11.71,0.081,0.068,0,0,0.042,1.99,0,4.05,81.65,94,1.357,1.604,0.208,32046
"Beef, variety meats and by-products, tripe, raw",Beef Products,12.07,0.069,0.097,0,0,0.067,0,0,3.69,84.16,85,1.291,1.533,0.18,32047
"USDA Commodity, beef patties with VPP, frozen, cooked",Beef Products,15.64,0.039,0.066,1.4,0,0.325,7.89,0,16.94,58.54,247,6.253,7.709,0.626,32048
"USDA Commodity, beef patties with VPP, frozen, raw",Beef Products,15.21,0.029,0.055,1.3,0,0.294,3.84,0,16.48,63.35,225,5.872,7.648,0.573,32049
"USDA Commodity, beef, canned",Beef Products,20.52,0.01,0.187,0,0,0.227,0,0,17.57,61.19,246,8.59,7.89,0.865,32050
"USDA Commodity, beef, ground bulk/coarse ground, frozen, cooked",Beef Products,26.06,0.009,0.095,0,0,0.333,0,0,16.34,56.49,259,5.744,7.504,0.62,32051
"USDA Commodity, beef, ground, bulk/coarse ground, frozen, raw",Beef Products,17.37,0.007,0.057,0,0,0.246,0,0,17.07,65.81,228,6.813,7.413,0.71,32052
"USDA Commodity, beef, patties (100%), frozen, cooked",Beef Products,22.98,0.008,0.066,0,0,0.233,0.91,0,16.37,58.77,249,6.252,8.081,0.589,32053
"USDA Commodity, beef, patties (100%), frozen, raw",Beef Products,14.63,0.007,0.074,0,0,0.269,0,0,15.69,64.72,204,6.333,6.861,0.643,32054
"Alcoholic Beverage, wine, table, red, Barbera",Beverages,0.07,0,0,0,0,0,2.79,0,0,86.29,85,0,0,0,32055
"Alcoholic Beverage, wine, table, red, Burgundy",Beverages,0.07,0,0,0,0,0,3.69,0,0,85.62,86,0,0,0,32056
"Alcoholic Beverage, wine, table, red, Cabernet Franc",Beverages,0.07,0,0,0,0,0,2.45,0,0,86.59,83,0,0,0,32057
"Alcoholic Beverage, wine, table, red, Cabernet Sauvignon",Beverages,0.07,0,0,0,0,0,2.6,0,0,86.56,83,0,0,0,32058
"Alcoholic Beverage, wine, table, red, Carignane",Beverages,0.07,0,0,0,0,0,2.4,0,0,87.88,74,0,0,0,32059
"Alcoholic Beverage, wine, table, red, Claret",Beverages,0.07,0,0,0,0,0,3.01,0,0,86.38,83,0,0,0,32060
"Alcoholic Beverage, wine, table, red, Gamay",Beverages,0.07,0,0,0,0,0,2.38,0,0,87.49,78,0,0,0,32061
"Alcoholic Beverage, wine, table, red, Lemberger",Beverages,0.07,0,0,0,0,0,2.46,0,0,87.02,80,0,0,0,32062
"Alcoholic Beverage, wine, table, red, Merlot",Beverages,0.07,0.008,0.004,0,0,0.127,2.51,0.62,0,86.59,83,0,0,0,32063
"Alcoholic Beverage, wine, table, red, Mouvedre",Beverages,0.07,0,0,0,0,0,2.64,0,0,85.81,88,0,0,0,32064
"Alcoholic Beverage, wine, table, red, Petite Sirah",Beverages,0.07,0,0,0,0,0,2.68,0,0,86.22,85,0,0,0,32065
"Alcoholic Beverage, wine, table, red, Pinot Noir",Beverages,0.07,0,0,0,0,0,2.31,0,0,86.94,82,0,0,0,32066
"Alcoholic Beverage, wine, table, red, Sangiovese",Beverages,0.07,0,0,0,0,0,2.62,0,0,86.17,86,0,0,0,32067
"Alcoholic Beverage, wine, table, red, Syrah",Beverages,0.07,0,0,0,0,0,2.58,0,0,86.53,83,0,0,0,32068
"Alcoholic Beverage, wine, table, red, Zinfandel",Beverages,0.07,0,0,0,0,0,2.86,0,0,85.71,88,0,0,0,32069
"Alcoholic beverage, beer, light",Beverages,0.24,0.004,0.004,0,0,0.021,1.64,0.09,0,94.88,29,0,0,0,32070
"Alcoholic beverage, beer, light, BUD LIGHT",Beverages,0.25,0.003,0.003,0,0,0.026,1.86,0,0,95,31,0,0,0,32071
"Alcoholic beverage, beer, light, BUDWEISER SELECT",Beverages,0.2,0.004,0.003,0,0,0.026,0.87,0,0,95.3,28,0,0,0,32072
"Alcoholic beverage, beer, light, MICHELOB ULTRA",Beverages,0.17,0.004,0.003,0,0,0.017,0.73,0,0,95.4,27,0,0,0,32073
"Alcoholic beverage, beer, regular, BUDWEISER",Beverages,0.36,0.004,0.003,0,0,0.033,2.97,0,0,92.77,41,0,0,0,32074
"Alcoholic beverage, beer, regular, all",Beverages,0.46,0.004,0.004,0,0,0.027,3.55,0,0,91.96,43,0,0,0,32075
"Alcoholic beverage, creme de menthe, 72 proof",Beverages,0,0,0.005,0,0,0,41.6,41.6,0.3,28.3,371,0.014,0.015,0.167,32076
"Alcoholic beverage, daiquiri, canned",Beverages,0,0,0.04,0,0.0013,0.011,15.7,0,0,74.6,125,0,0,0,32077
"Alcoholic beverage, daiquiri, prepared-from-recipe",Beverages,0.06,0.003,0.005,0.1,0.0016,0.021,6.94,5.58,0.06,69.74,186,0.006,0.006,0.016,32078
"Alcoholic beverage, distilled, all (gin, rum, vodka, whiskey) 100 proof",Beverages,0,0,0.001,0,0,0.002,0,0,0,57.5,295,0,0,0,32079
"Alcoholic beverage, distilled, all (gin, rum, vodka, whiskey) 80 proof",Beverages,0,0,0.001,0,0,0.002,0,0,0,66.6,231,0,0,0,32080
"Alcoholic beverage, distilled, all (gin, rum, vodka, whiskey) 86 proof",Beverages,0,0,0.001,0,0,0.002,0.1,0,0,63.9,250,0,0,0,32081
"Alcoholic beverage, distilled, all (gin, rum, vodka, whiskey) 90 proof",Beverages,0,0,0.001,0,0,0.002,0,0,0,62.1,263,0,0,0,32082
"Alcoholic beverage, distilled, all (gin, rum, vodka, whiskey) 94 proof",Beverages,0,0,0.001,0,0,0.002,0,0,0,60.3,275,0,0,0,32083
"Alcoholic beverage, distilled, gin, 90 proof",Beverages,0,0,0.002,0,0,0,0,0,0,62.1,263,0,0,0,32084
"Alcoholic beverage, distilled, rum, 80 proof",Beverages,0,0,0.001,0,0,0.002,0,0,0,66.6,231,0,0,0,32085
"Alcoholic beverage, distilled, vodka, 80 proof",Beverages,0,0,0.001,0,0,0.001,0,0,0,66.6,231,0,0,0,32086
"Alcoholic beverage, distilled, whiskey, 86 proof",Beverages,0,0,0,0,0,0.001,0.1,0.1,0,63.9,250,0,0,0,32087
"Alcoholic beverage, liqueur, coffee with cream, 34 proof",Beverages,2.8,0.016,0.092,0,0.0002,0.032,20.9,19.76,15.7,46.5,327,9.664,4.458,0.669,32088
"Alcoholic beverage, liqueur, coffee, 53 proof",Beverages,0.1,0.001,0.008,0,0,0.03,46.8,46.43,0.3,31,326,0.106,0.022,0.105,32089
"Alcoholic beverage, liqueur, coffee, 63 proof",Beverages,0.1,0.001,0.008,0,0,0.03,32.2,32.2,0.3,41.4,308,0.106,0.022,0.105,32090
"Alcoholic beverage, pina colada, canned",Beverages,0.6,0.001,0.071,0.1,0.0015,0.083,27.6,0,7.6,54.9,237,6.571,0.442,0.137,32091
"Alcoholic beverage, pina colada, prepared-from-recipe",Beverages,0.42,0.008,0.006,0.3,0.004900000000000001,0.071,22.66,22.33,1.88,64.99,174,1.636,0.082,0.033,32092
"Alcoholic beverage, rice (sake)",Beverages,0.5,0.005,0.002,0,0,0.025,5,0,0,78.4,134,0,0,0,32093
"Alcoholic beverage, tequila sunrise, canned",Beverages,0.3,0,0.057,0,0.0193,0.01,11.3,0,0.1,78.8,110,0.013,0,0,32094
"Alcoholic beverage, whiskey sour, canned",Beverages,0,0,0.044,0.1,0.0016,0.011,13.4,0,0,76.9,119,0,0,0,32095
"Alcoholic beverage, whiskey sour, prepared from item 14028",Beverages,0.06,0.001,0.061,0,0.0016,0.018,12.82,12.78,0.06,72.44,153,0.005,0,0.011,32096
"Alcoholic beverage, whiskey sour, prepared from item 14530",Beverages,0,0.001,0.02,0,0.0016,0.005,13.1,0,0,72.6,149,0.005,0,0.012,32097
"Alcoholic beverage, whiskey sour, prepared with water, whiskey and powder mix",Beverages,0.1,0.046,0.047,0,0.0004,0.004,15.85,15.81,0.02,69.15,164,0.003,0,0.006,32098
"Alcoholic beverage, wine, cooking",Beverages,0.5,0.009,0.626,0,0,0.088,6.3,1.55,0,88.9,50,0,0,0,32099
"Alcoholic beverage, wine, dessert, dry",Beverages,0.2,0.008,0.009,0,0,0.092,11.67,1.09,0,72.53,152,0,0,0,32100
"Alcoholic beverage, wine, dessert, sweet",Beverages,0.2,0.008,0.009,0,0,0.092,13.69,7.78,0,70.51,160,0,0,0,32101
"Alcoholic beverage, wine, light",Beverages,0.07,0.009,0.007,0,0,0.088,1.17,1.15,0,92.23,49,0,0,0,32102
"Alcoholic beverage, wine, table, all",Beverages,0.07,0.008,0.005,0,0,0.099,2.72,0.79,0,86.58,83,0,0,0,32103
"Alcoholic beverage, wine, table, red",Beverages,0.07,0.008,0.004,0,0,0.127,2.61,0.62,0,86.49,85,0,0,0,32104
"Alcoholic beverage, wine, table, white",Beverages,0.07,0.009,0.005,0,0,0.071,2.6,0.96,0,86.86,82,0,0,0,32105
"Alcoholic beverage, wine, table, white, Chenin Blanc",Beverages,0.07,0,0,0,0,0,3.31,0,0,86.78,80,0,0,0,32106
"Alcoholic beverage, wine, table, white, Fume Blanc",Beverages,0.07,0,0,0,0,0,2.27,0,0,86.98,82,0,0,0,32107
"Alcoholic beverage, wine, table, white, Gewurztraminer",Beverages,0.07,0,0,0,0,0,2.6,0,0,87.03,81,0,0,0,32108
"Alcoholic beverage, wine, table, white, Muller Thurgau",Beverages,0.07,0,0,0,0,0,3.48,0,0,87.28,76,0,0,0,32109
"Alcoholic beverage, wine, table, white, Muscat",Beverages,0.07,0,0,0,0,0,5.23,0,0,85.69,82,0,0,0,32110
"Alcoholic beverage, wine, table, white, Pinot Blanc",Beverages,0.07,0,0,0,0,0,1.94,0,0,87.14,81,0,0,0,32111
"Alcoholic beverage, wine, table, white, Pinot Gris (Grigio)",Beverages,0.07,0,0,0,0,0,2.06,0,0,86.98,83,0,0,0,32112
"Alcoholic beverage, wine, table, white, Riesling",Beverages,0.07,0,0,0,0,0,3.74,0,0,86.56,80,0,0,0,32113
"Alcoholic beverage, wine, table, white, Sauvignon Blanc",Beverages,0.07,0,0,0,0,0,2.05,0,0,87.16,81,0,0,0,32114
"Alcoholic beverage, wine, table, white, Semillon",Beverages,0.07,0,0,0,0,0,3.12,0,0,86.54,82,0,0,0,32115
"Alcoholic beverage, wine, table, white, late harvest",Beverages,0.07,0,0,0,0,0,13.39,0,0,77.76,112,0,0,0,32116
"Apple cider-flavored drink, powder, added vitamin C and sugar",Beverages,0,0.465,0.095,0.1,0.363,0.007,98.8,80.95,0,0.3,395,0,0,0,32117
"Apple cider-flavored drink, powder, low calorie, with vitamin C, prepared",Beverages,0,0.011,0.014,0,0.025,0,0.3,0,0,99.7,1,0,0,0,32118
"Beef broth and tomato juice, canned",Beverages,0.6,0.011,0.131,0.1,0.0009,0.096,8.5,0,0.1,89.9,37,0.032,0.029,0.022,32119
"Beverage, Horchata, as served in restaurant",Beverages,0.48,0.018,0.014,0,0,0.034,11.52,9.36,0.71,87.1,54,0,0,0,32120
"Beverage, Horchata, dry mix, unprepared, variety of brands, all with morro seeds",Beverages,7.5,0.06,0.003,4,0.0003,0.18,79.05,38.9,7.46,4.89,413,2.086,3.174,2.069,32121
"Beverages, fruit juice drink, reduced sugar, with vitamin E added",Beverages,0,0.005,0.002,0,0,0.011,10,9,0.07,87.58,39,0.008,0.012,0.014,32122
"Beverages, vegetable and fruit juice blend, 100% juice, with added vitamins A, C, E",Beverages,0.3,0.008,0.029,0,0.0285,0.101,11.15,10.37,0.01,88.4,46,0,0,0,32123
"Breakfast type drink, orange flavor, reduced sugar",Beverages,0.04,0.011,0.005,0,0.0251,0.033,0.9,0.03,0,98.87,4,0,0,0,32124
"CAMPBELL Soup Company, V8 SPLASH Juice Drinks, Berry Blend",Beverages,0,0,0.021,0,0.0247,0.014,7.41,7.41,0,92.2,29,0,0,0,32125
"CAMPBELL Soup Company, V8 SPLASH Juice Drinks, Diet Berry Blend",Beverages,0,0,0.014,0,0.0247,0.033,1.23,0.41,0,98.7,4,0,0,0,32126
"CAMPBELL Soup Company, V8 SPLASH Juice Drinks, Diet Fruit Medley",Beverages,0,0,0.013,0,0.0252,0.015,1.26,0.84,0,98.8,4,0,0,0,32127
"CAMPBELL Soup Company, V8 SPLASH Juice Drinks, Diet Strawberry Kiwi",Beverages,0,0.008,0.013,0,0.0252,0.015,1.26,0.84,0,98.8,4,0,0,0,32128
"CAMPBELL Soup Company, V8 SPLASH Juice Drinks, Diet Tropical Blend",Beverages,0,0,0.015,0,0.0252,0.034,1.26,0.42,0,98.8,4,0,0,0,32129
"CAMPBELL Soup Company, V8 SPLASH Juice Drinks, Fruit Medley",Beverages,0,0,0.021,0,0.0247,0.01,7.82,7.82,0,91.8,33,0,0,0,32130
"CAMPBELL Soup Company, V8 SPLASH Juice Drinks, Guava Passion Fruit",Beverages,0,0,0.014,0,0.0247,0.012,7.82,7.82,0,91.9,33,0,0,0,32131
"CAMPBELL Soup Company, V8 SPLASH Juice Drinks, Mango Peach",Beverages,0,0,0.016,0,0.0247,0.012,8.23,8.23,0,91.6,33,0,0,0,32132
"CAMPBELL Soup Company, V8 SPLASH Juice Drinks, Orange Pineapple",Beverages,0,0,0.021,0,0.0247,0.01,7.41,7.41,0,91.9,29,0,0,0,32133
"CAMPBELL Soup Company, V8 SPLASH Juice Drinks, Orchard Blend",Beverages,0,0,0.021,0,0.0247,0.01,7.82,7.82,0,92.2,33,0,0,0,32134
"CAMPBELL Soup Company, V8 SPLASH Juice Drinks, Strawberry Banana",Beverages,0,0,0.021,0,0.0247,0.008,7.41,7.41,0,92.2,29,0,0,0,32135
"CAMPBELL Soup Company, V8 SPLASH Juice Drinks, Strawberry Kiwi",Beverages,0,0,0.021,0,0.0247,0.014,7.41,7.41,0,92.4,29,0,0,0,32136
"CAMPBELL Soup Company, V8 SPLASH Juice Drinks, Tropical Blend",Beverages,0,0,0.021,0,0.0247,0.012,7.41,7.41,0,92.4,29,0,0,0,32137
"CAMPBELL Soup Company, V8 SPLASH Smoothies, Peach Mango",Beverages,1.22,0.041,0.029,0,0.0245,0.024,7.76,7.35,0,87.2,37,0,0,0,32138
"CAMPBELL Soup Company, V8 SPLASH Smoothies, Strawberry Banana",Beverages,1.22,0.041,0.029,0,0.0245,0.024,8.16,7.35,0,86.5,37,0,0,0,32139
"CAMPBELL Soup Company, V8 SPLASH Smoothies, Tropical Colada",Beverages,1.22,0.041,0.02,0.4,0.024399999999999998,0.024,8.54,7.32,0,86.7,41,0,0,0,32140
"CAMPBELL Soup Company, V8 V. FUSION Juices, Acai Berry",Beverages,0,0.001,0.028,0,0.0407,0.098,10.98,10.57,0,88.4,45,0,0,0,32141
"CAMPBELL Soup Company, V8 V. FUSION Juices, Peach Mango",Beverages,0.41,0.008,0.028,0,0.024399999999999998,0.085,11.38,10.57,0,87.8,49,0,0,0,32142
"CAMPBELL Soup Company, V8 V. FUSION Juices, Strawberry Banana",Beverages,0.41,0.008,0.028,0,0.024399999999999998,0.102,11.79,10.16,0,87.9,49,0,0,0,32143
"CAMPBELL Soup Company, V8 V. FUSION Juices, Tropical",Beverages,0.41,0.008,0.033,0,0.024399999999999998,0.118,11.38,10.16,0,88,49,0,0,0,32144
"Carbonated beverage, SPRITE, lemon-lime, without caffeine",Beverages,0.05,0.002,0.009,0,0,0.001,10.14,8.98,0.02,89.78,40,0,0,0,32145
"Carbonated beverage, chocolate-flavored soda",Beverages,0,0.004,0.088,0,0,0.05,10.7,10.7,0,89.2,42,0,0,0,32146
"Carbonated beverage, club soda",Beverages,0,0.005,0.021,0,0,0.002,0,0,0,99.9,0,0,0,0,32147
"Carbonated beverage, cola, contains caffeine",Beverages,0.07,0.002,0.004,0,0,0.002,9.56,8.97,0.02,90.31,37,0,0,0,32148
"Carbonated beverage, cola, with higher caffeine",Beverages,0,0.002,0.004,0,0,0.003,10.58,10.58,0,89.62,41,0,0,0,32149
"Carbonated beverage, cola, without caffeine",Beverages,0,0.002,0.004,0,0,0.003,10.58,10.58,0,89.62,41,0,0,0,32150
"Carbonated beverage, cream soda",Beverages,0,0.005,0.012,0,0,0.001,13.3,13.3,0,86.7,51,0,0,0,32151
"Carbonated beverage, ginger ale",Beverages,0,0.003,0.007,0,0,0.001,8.77,8.7,0,91.23,34,0,0,0,32152
"Carbonated beverage, grape soda",Beverages,0,0.003,0.015,0,0,0.001,11.2,0,0,88.8,43,0,0,0,32153
"Carbonated beverage, lemon-lime soda, contains caffeine",Beverages,0.09,0.002,0.01,0,0,0.001,10.42,10.19,0,89.49,41,0,0,0,32154
"Carbonated beverage, low calorie, cola or pepper-type, with aspartame, contains caffeine",Beverages,0.11,0.003,0.008,0,0,0.008,0.29,0,0.03,99.54,2,0,0,0,32155
"Carbonated beverage, low calorie, cola or pepper-type, with aspartame, without caffeine",Beverages,0.12,0.003,0.004,0,0,0.007,0.15,0,0,99.74,1,0,0,0,32156
"Carbonated beverage, low calorie, cola or pepper-types, with sodium saccharin, contains caffeine",Beverages,0,0.004,0.016,0,0,0.004,0.1,0,0,99.8,0,0,0,0,32157
"Carbonated beverage, low calorie, other than cola or pepper, without caffeine",Beverages,0.1,0.004,0.006,0,0,0.002,0,0,0,99.8,0,0,0,0,32158
"Carbonated beverage, low calorie, other than cola or pepper, with aspartame, contains caffeine",Beverages,0.1,0.004,0.006,0,0,0.002,0,0,0,99.8,0,0,0,0,32159
"Carbonated beverage, low calorie, other than cola or pepper, with sodium saccharin, without caffeine",Beverages,0,0.004,0.016,0,0,0.002,0.1,0,0,99.8,0,0,0,0,32160
"Carbonated beverage, orange",Beverages,0,0.005,0.012,0,0,0.002,12.3,0,0,87.6,48,0,0,0,32161
"Carbonated beverage, pepper-type, contains caffeine",Beverages,0,0.003,0.01,0,0,0.001,10.4,0,0.1,89.4,41,0.07,0,0,32162
"Carbonated beverage, reduced sugar, cola, contains caffeine and sweeteners",Beverages,0,0.002,0.004,0,0,0.003,5.16,5.16,0,94.78,20,0,0,0,32163
"Carbonated beverage, root beer",Beverages,0,0.005,0.013,0,0,0.001,10.6,10.6,0,89.3,41,0,0,0,32164
"Carbonated beverage, tonic water",Beverages,0,0.001,0.012,0,0,0,8.8,8.8,0,91.1,34,0,0,0,32165
"Carob-flavor beverage mix, powder",Beverages,1.8,0.032,0.103,8,0,0.104,93.3,0,0.2,2.9,372,0.03,0.06,0.068,32166
"Carob-flavor beverage mix, powder, prepared with whole milk",Beverages,3.16,0.098,0.046,0.4,0,0.131,8.68,0,3.11,84.32,75,1.779,0.777,0.189,32167
Chocolate syrup,Beverages,2.1,0.014,0.072,2.6,0.0002,0.224,65.1,49.65,1.13,31,279,0.519,0.286,0.026,32168
"Chocolate syrup, prepared with whole milk",Beverages,3.07,0.089,0.047,0.3,0,0.145,12.78,11.31,2.96,80.51,90,1.681,0.74,0.172,32169
"Chocolate-flavor beverage mix for milk, powder, with added nutrients",Beverages,4.55,0.455,0.136,4.5,0.0273,0.28,90.28,81.82,2.27,1.1,400,2.273,0,0,32170
"Chocolate-flavor beverage mix for milk, powder, with added nutrients, prepared with whole milk",Beverages,3.27,0.141,0.05,0.4,0.0023,0.144,11.87,11.59,3.17,80.93,89,1.899,0.745,0.178,32171
"Chocolate-flavor beverage mix for milk, powder, without added nutrients",Beverages,3.3,0.037,0.21,4.8,0.0007,0.591,90.9,83.88,3.1,0.9,405,1.834,1.009,0.091,32172
"Chocolate-flavor beverage mix, powder, prepared with whole milk",Beverages,3.23,0.095,0.058,0.4,0.0001,0.172,11.91,0,3.24,80.79,85,1.86,0.829,0.186,32173
"Chocolate-flavored drink, whey and milk based",Beverages,0.64,0.1,0.091,0.6,0.002,0.079,10.68,8.68,0.4,87.63,49,0.31,0.049,0.009,32174
"Citrus fruit juice drink, frozen concentrate",Beverages,1.2,0.025,0.003,0.2,0.0953,0.393,40.2,28.63,0.1,57.4,162,0.007,0.007,0.015,32175
"Citrus fruit juice drink, frozen concentrate, prepared with water",Beverages,0.34,0.009,0.004,0.1,0.027100000000000003,0.112,11.42,8.13,0.03,87.83,46,0.002,0.002,0.004,32176
"Clam and tomato juice, canned",Beverages,0.6,0.008,0.362,0.4,0.005,0.089,10.95,3.31,0.2,87.4,48,0,0,0,32177
"Cocktail mix, non-alcoholic, concentrated, frozen",Beverages,0.08,0.002,0,0,0.004200000000000001,0.023,71.6,48.44,0.01,28.24,287,0.003,0,0.009,32178
"Cocoa mix, NESTLE, Hot Cocoa Mix Rich Chocolate With Marshmallows",Beverages,2.8,0.08,0.8,3.7,0,0,75,65,15,4.18,400,15,0,0,32179
"Cocoa mix, NESTLE, Rich Chocolate Hot Cocoa Mix",Beverages,3,0.1,0.85,4,0,0,75,60,15,3.73,400,10,0,0,32180
"Cocoa mix, no sugar added, powder",Beverages,15.49,0.576,0.876,7.5,0,2.702,71.93,37.65,3,3.4,377,1.777,0.979,0.088,32181
"Cocoa mix, powder",Beverages,6.67,0.133,0.504,3.7,0.0002,0.712,83.73,65.55,4,1.5,398,2.377,1.325,0.114,32182
"Cocoa mix, powder, prepared with water",Beverages,0.92,0.021,0.073,0.5,0,0.099,11.54,9.03,0.55,86.34,55,0.328,0.183,0.016,32183
"Cocoa mix, with aspartame, low calorie, powder, with added calcium phosphorus, without added sodium or vitamin A",Beverages,25.1,1.44,0.653,1.1,0,2.702,58,55.58,3,3.4,359,1.777,0.979,0.088,32184
"Cocoa mix, with aspartame, powder, prepared with water",Beverages,1.21,0.048,0.072,0.6,0,0.211,5.61,2.93,0.23,92.38,29,0.139,0.076,0.007,32185
"Coffee and cocoa (mocha) powder, with whitener and low calorie sweetener",Beverages,9,0.06,0.5,4.8,0,1.856,71.4,34.98,13.21,3.1,257,11.376,0.954,0.349,32186
"Coffee and cocoa (mocha) powder, with whitener and low calorie sweetener, decaffeinated",Beverages,9,0.06,0.5,4.8,0,1.856,71.4,34.98,13.21,3.1,257,11.376,0.954,0.349,32187
"Coffee substitute, cereal grain beverage, powder",Beverages,6.01,0.058,0.083,23.3,0,2.443,78.42,4.63,2.52,7.99,360,0.603,0.356,1.225,32188
"Coffee substitute, cereal grain beverage, powder, prepared with whole milk",Beverages,3.3,0.119,0.049,0.1,0.0009,0.172,5.6,0,3.3,87,65,2.061,0.958,0.144,32189
"Coffee substitute, cereal grain beverage, prepared with water",Beverages,0.1,0.004,0.005,0.4,0,0.041,1.3,0.08,0.04,98.37,6,0.01,0.006,0.02,32190
"Coffee substitute, roasted grain beverage, NATURAL TOUCH KAFFREE ROMA, powder",Beverages,4.5,0.043,0.126,0,0,0.999,84.3,3.5,0.3,2.2,358,0,0,0,32191
"Coffee, brewed from grounds, prepared with tap water",Beverages,0.12,0.002,0.002,0,0,0.049,0,0,0.02,99.39,1,0.002,0.015,0,32192
"Coffee, brewed from grounds, prepared with tap water, decaffeinated",Beverages,0.1,0.002,0.002,0,0,0.054,0,0,0,99.3,0,0.002,0,0.002,32193
"Coffee, brewed, espresso, restaurant-prepared",Beverages,0.12,0.002,0.014,0,0.0002,0.115,0,0,0.18,97.8,2,0.092,0,0.092,32194
"Coffee, brewed, espresso, restaurant-prepared, decaffeinated",Beverages,0.1,0.002,0.014,0,0.0002,0.115,0,0,0.18,97.8,0,0.092,0,0.092,32195
"Coffee, dry, powder, with whitener, reduced calorie",Beverages,1.96,0.018,0.819,0.5,0,0.909,59.94,59,29.1,6.16,509,25.128,1.705,0.576,32196
"Coffee, instant, decaffeinated, powder",Beverages,11.6,0.14,0.023,0,0,3.501,42.6,0,0.2,3.2,224,0.101,0.021,0.101,32197
"Coffee, instant, decaffeinated, powder, prepared with water",Beverages,0.12,0.004,0.004,0,0,0.036,0.43,0,0,98.93,2,0,0,0,32198
"Coffee, instant, regular, powder",Beverages,12.2,0.141,0.037,0,0,3.535,41.1,0,0.5,3.1,241,0.197,0.041,0.196,32199
"Coffee, instant, regular, powder, half the caffeine",Beverages,14.42,0.141,0.037,0,0,3.535,70.6,0,0.5,3.1,350,0.197,0.041,0.196,32200
"Coffee, instant, regular, prepared with water",Beverages,0.1,0.004,0.004,0,0,0.03,0.34,0,0,99.09,2,0.002,0,0.002,32201
"Coffee, instant, with chicory, powder",Beverages,9.3,0.103,0.277,0.5,0,3.395,74.2,0,0.2,4.1,351,0.071,0.015,0.07,32202
"Coffee, instant, with chicory, prepared with water",Beverages,0.09,0.004,0.007,0,0,0.035,0.75,0,0,98.94,3,0,0,0,32203
"Coffee, instant, with sugar, cappuccino-flavor powder",Beverages,3.1,0.133,0.18,1.2,0.0005,0.831,85.94,69.16,5.56,1.3,406,3.422,1.604,0.241,32204
"Coffee, instant, with sugar, french-flavor, powder",Beverages,4.5,0.036,0.557,0,0,1.184,65.67,41.74,22.63,2.4,484,4.997,14.001,2.419,32205
"Coffee, instant, with sugar, mocha-flavor, powder",Beverages,5.29,0.271,0.317,1.9,0.0006,1.033,74.04,58.2,15.87,1.7,460,4.934,8.934,1.053,32206
Corn beverage,Beverages,0.8,0.003,0.14,0.4,0,0.017,8.7,0.18,0.2,90.16,40,0,0,0,32207
"Cranberry juice cocktail, bottled",Beverages,0,0.003,0.002,0,0.0423,0.014,13.52,11.87,0.1,86.17,54,0.009,0.019,0.059,32208
"Cranberry juice cocktail, bottled, low calorie, with calcium, saccharin and corn sweetener",Beverages,0.02,0.009,0.003,0,0.0322,0.025,4.6,4.59,0.01,95.2,19,0,0,0,32209
"Cranberry juice cocktail, frozen concentrate",Beverages,0.05,0.011,0.004,0.2,0.0458,0.049,51.45,42.5,0,48.4,201,0,0,0,32210
"Cranberry juice cocktail, frozen concentrate, prepared with water",Beverages,0.01,0.005,0.004,0,0.0105,0.012,11.81,9.76,0,88.08,47,0,0,0,32211
"Cranberry-apple juice drink, bottled",Beverages,0,0.003,0.002,0,0.0395,0.017,15.85,14.5,0.11,83.78,63,0.013,0.014,0.052,32212
"Cranberry-apple juice drink, low calorie, with vitamin C added",Beverages,0.1,0.01,0.005,0.1,0.032,0.045,4.7,4.6,0,95.2,19,0,0,0,32213
"Cranberry-apricot juice drink, bottled",Beverages,0.2,0.009,0.002,0.1,0,0.061,16.2,0,0,83.5,64,0,0,0,32214
"Cranberry-grape juice drink, bottled",Beverages,0.2,0.008,0.003,0.1,0.032,0.024,14,0,0.1,85.6,56,0.033,0.004,0.023,32215
"Dairy drink mix, chocolate, reduced calorie, with aspartame, powder, prepared with water and ice",Beverages,2.19,0.127,0.061,0.8,0.0005,0.197,4.51,2.89,0.23,92.26,29,0.164,0.043,0.005,32216
"Dairy drink mix, chocolate, reduced calorie, with low-calorie sweeteners, powder",Beverages,25,1.412,0.659,9.4,0.0056,2.24,51.4,32.94,2.6,12.8,329,1.871,0.496,0.061,32217
"Drink mix, QUAKER OATS, GATORADE, orange flavor, powder",Beverages,0,0.04,0.063,0,0.0004,0.03,94.11,80.5,1.23,2.51,388,0,0,0,32218
"Eggnog-flavor mix, powder, prepared with whole milk",Beverages,2.93,0.092,0.055,0,0,0.121,14.2,12.66,3.02,79.16,95,1.701,0.767,0.186,32219
"Energy Drink, Monster",Beverages,0,0.003,0.075,0,0,0.003,11.25,11.25,0,88.45,42,0,0,0,32220
"Energy drink, AMP",Beverages,0.25,0.013,0.027,0,0,0.003,12.08,12.08,0.08,87.3,46,0,0,0,32221
"Energy drink, AMP, sugar free",Beverages,0,0,0.031,0,0,0.002,1.03,0,0,98.35,2,0,0,0,32222
"Energy drink, FULL THROTTLE",Beverages,0.25,0.013,0.035,0,0,0.003,12.08,12.08,0.08,87.3,46,0,0,0,32223
"Energy drink, RED BULL, sugar free, with added caffeine, niacin, pantothenic acid, vitamins B6 and B12",Beverages,0.25,0.013,0.039,0,0,0.003,0.7,0,0.08,98.35,5,0,0,0,32224
"Energy drink, RED BULL, with added caffeine, niacin, pantothenic acid, vitamins B6 and B12",Beverages,0.25,0.013,0.038,0,0,0.003,10.94,10.06,0.08,88.45,45,0,0,0,32225
"Energy drink, ROCKSTAR",Beverages,0,0.013,0.017,0,0,0.003,12.7,12.7,0,87,58,0,0,0,32226
"Energy drink, ROCKSTAR, sugar free",Beverages,0.25,0.013,0.052,0,0,0.003,0.7,0,0.08,98.35,4,0,0,0,32227
"Energy drink, VAULT Zero, sugar-free, citrus flavor",Beverages,0.25,0.002,0.014,0,0,0.003,0.7,0,0.08,98.35,1,0,0,0,32228
"Energy drink, VAULT, citrus flavor",Beverages,0,0.002,0.012,0,0,0.003,12.99,12.99,0,86.73,49,0,0,0,32229
"Fruit punch drink, frozen concentrate",Beverages,0.2,0.008,0.008,0.4,0.1556,0.044,41.4,0,0,58.2,162,0.003,0.003,0.006,32230
"Fruit punch drink, frozen concentrate, prepared with water",Beverages,0.06,0.004,0.005,0.1,0.0438,0.013,11.66,0,0,88.15,46,0,0,0.002,32231
"Fruit punch drink, with added nutrients, canned",Beverages,0,0.008,0.038,0.2,0.0296,0.031,11.97,11.29,0,87.97,47,0,0.002,0.003,32232
"Fruit punch drink, without added nutrients, canned",Beverages,0,0.008,0.01,0,0.0004,0.025,11.97,11.29,0,87.97,48,0,0,0,32233
"Fruit punch juice drink, frozen concentrate",Beverages,0.3,0.02,0.01,0.2,0.0197,0.27,43.1,0,0.7,55.5,175,0.087,0.086,0.173,32234
"Fruit punch juice drink, frozen concentrate, prepared with water",Beverages,0.07,0.007,0.005,0,0.0048,0.066,10.44,0,0.17,89.15,42,0.021,0.021,0.042,32235
"Fruit punch-flavor drink, powder, without added sodium",Beverages,0,0.142,0.042,0,0.12190000000000001,0.005,97.7,95.2,0.1,1.4,382,0.057,0.007,0.011,32236
"Fruit punch-flavor drink, powder, without added sodium, prepared with water",Beverages,0,0.017,0.007,0,0.011800000000000001,0.001,9.47,9.23,0.01,90.35,37,0.006,0,0,32237
"Fruit-flavored drink mix, powder, unsweetened",Beverages,0,1.105,2.727,0,1.0909,0.001,91.3,0,0,0.96,226,0,0,0,32238
"Fruit-flavored drink, dry powdered mix, low calorie, with aspartame",Beverages,0.45,1.047,0.404,0.1,0.56,0.498,86.85,0,0.04,1.84,217,0,0,0,32239
"Fruit-flavored drink, powder, with high vitamin C with other added vitamins, low calorie",Beverages,0.25,0.8,0.014,2.2,2.4,2.518,91,0,0.16,1.32,227,0.01,0.038,0.034,32240
"Grape drink, canned",Beverages,0,0.052,0.016,0,0.0314,0.012,15.75,13.06,0,84.2,61,0,0,0,32241
"Grape juice drink, canned",Beverages,0,0.007,0.009,0.1,0.0265,0.033,14.55,14.11,0,85.3,57,0,0,0,32242
"Lemonade, frozen concentrate, pink",Beverages,0.22,0.008,0.004,0.3,0.0141,0.076,48.86,46.46,0.69,50.09,192,0.026,0.008,0.059,32243
"Lemonade, frozen concentrate, pink, prepared with water",Beverages,0.05,0.004,0.004,0.1,0.0031,0.017,10.81,10.28,0.15,88.88,43,0.006,0.002,0.013,32244
"Lemonade, frozen concentrate, white",Beverages,0.22,0.007,0.007,0.3,0.013099999999999999,0.072,49.89,44.46,0.7,49.06,196,0.025,0.008,0.059,32245
"Lemonade, frozen concentrate, white, prepared with water",Beverages,0.07,0.004,0.004,0,0.0039,0.015,10.42,9.98,0.04,89.35,40,0.006,0.002,0.013,32246
"Lemonade, low calorie, with aspartame, powder",Beverages,3.6,3.098,0.01,0.1,0.394,0.011,83.7,0,0.3,1.8,332,0.039,0.01,0.09,32247
"Lemonade, low calorie, with aspartame, powder, prepared with water",Beverages,0.03,0.028,0.004,0,0.0032,0.001,0.68,0,0,99.1,3,0,0,0,32248
"Lemonade, powder",Beverages,0,0.02,0.051,0.4,0.0399,0.147,97.23,94.7,1.05,1.43,378,0,0,0,32249
"Lemonade, powder, prepared with water",Beverages,0,0.004,0.006,0,0.0015,0.006,3.59,3.5,0.04,96.26,14,0,0,0,32250
"Lemonade-flavor drink, powder",Beverages,0,0.011,0.13,0,0.040600000000000004,0.039,97.57,97.15,1.01,0.52,379,0.003,0,0,32251
"Lemonade-flavor drink, powder, prepared with water",Beverages,0,0.004,0.013,0,0.0029,0.003,6.9,6.87,0.07,92.87,27,0,0,0,32252
"Limeade, frozen concentrate",Beverages,0,0,0,0,0.0141,0.043,62.32,59.96,0,37.46,247,0,0,0,32253
"Limeade, frozen concentrate, prepared with water",Beverages,0,0.002,0.003,0,0.0031,0.01,13.79,13.27,0,86.08,52,0,0,0,32254
Malt beverage,Beverages,0.21,0.007,0.013,0,0.0005,0.008,8.05,8.05,0.12,91.15,37,0.024,0.015,0.056,32255
"Malted drink mix, chocolate, powder",Beverages,5.1,0.06,0.19,4.8,0.0015,0.618,86.94,66.67,4.76,1.3,411,2.381,1.167,0.669,32256
"Malted drink mix, chocolate, powder, prepared with whole milk",Beverages,3.37,0.098,0.06,0.5,0.0001,0.172,11.2,6.68,3.29,81.34,85,1.883,0.827,0.208,32257
"Malted drink mix, chocolate, with added nutrients, powder",Beverages,4.9,0.443,0.594,4.7,0.15009999999999998,1.193,84.6,70.56,3.4,2.5,389,1.964,0.951,0.325,32258
"Malted drink mix, chocolate, with added nutrients, powder, prepared with whole milk",Beverages,3.29,0.139,0.087,0.4,0.012,0.217,11.19,10.49,3.26,81.26,87,1.873,0.823,0.205,32259
"Malted drink mix, natural, powder",Beverages,14.29,0.298,0.405,0.1,0.0029,0.758,71.21,47.62,9.52,2,428,4.762,2.157,1.429,32260
"Malted drink mix, natural, powder, prepared with whole milk",Beverages,3.86,0.117,0.079,0.1,0.0002,0.183,10.23,9.56,3.62,81.4,88,2.039,0.903,0.276,32261
"Malted drink mix, natural, with added nutrients, powder",Beverages,9.41,0.376,0.259,1.9,0.127,0.591,83.59,63.38,1.33,3.1,372,0.679,0.343,0.169,32262
"Malted drink mix, natural, with added nutrients, powder, prepared with whole milk",Beverages,3.67,0.123,0.072,0,0.0104,0.2,10.67,8.37,3.21,81.49,86,1.83,0.802,0.213,32263
"Meal supplement drink, NESTLE, SUPLIGEN, canned, peanut flavor",Beverages,3.5,0.11,0.054,0,0.0007,0.16,14.74,0,3.07,77.79,101,0.571,1.922,0.178,32264
"Mixed vegetable and fruit juice drink, with added nutrients",Beverages,0.04,0.003,0.021,0,0.0325,0.019,7.47,2.1,0.01,92.34,29,0,0,0,32265
"Orange and apricot juice drink, canned",Beverages,0.3,0.005,0.002,0.1,0.02,0.08,12.7,12.06,0.1,86.7,51,0.01,0.031,0.022,32266
"Orange breakfast drink, ready-to-drink",Beverages,0,0.004,0.002,0.2,0.013,0.041,10.8,6.56,0,88.9,43,0,0,0,32267
"Orange breakfast drink, ready-to-drink, with added nutrients",Beverages,0,0.1,0.054,0.1,0.024,0.083,13.2,8.02,0,85.4,53,0,0,0,32268
"Orange drink, breakfast type, with juice and pulp, frozen concentrate",Beverages,0.4,0.399,0.026,0.1,0.18969999999999998,0.465,39,38.05,0,59.2,153,0.002,0.003,0.004,32269
"Orange drink, breakfast type, with juice and pulp, frozen concentrate, prepared with water",Beverages,0.12,0.118,0.01,0,0.0551,0.167,11.32,11.05,0,88.08,45,0,0,0,32270
"Orange drink, canned, with added vitamin C",Beverages,0,0.005,0.003,0,0.0573,0.018,12.34,11.03,0.07,87.47,49,0.002,0.002,0.003,32271
Orange juice drink,Beverages,0.2,0.002,0.002,0.2,0.015,0.042,13.41,9.36,0,86.2,54,0,0.01,0.01,32272
"Orange-flavor drink, breakfast type, low calorie, powder",Beverages,3.6,1.378,0.081,3.8,2.4,3.132,85.9,2.6,0,1.8,217,0,0,0,32273
"Orange-flavor drink, breakfast type, powder",Beverages,0,0.385,0.017,0.4,0.2308,0.19,98.94,92.31,0,0.14,386,0,0,0,32274
"Orange-flavor drink, breakfast type, powder, prepared with water",Beverages,0,0.052,0.005,0.1,0.0295,0.025,12.65,11.8,0,87.15,49,0,0,0,32275
"Orange-flavor drink, breakfast type, with pulp, frozen concentrate",Beverages,0.1,0.13,0.024,0.2,0.24309999999999998,0.435,42.9,41.85,0.5,55.2,172,0.078,0.226,0.197,32276
"Orange-flavor drink, breakfast type, with pulp, frozen concentrate, prepared with water",Beverages,0.03,0.039,0.01,0,0.0692,0.124,12.21,11.91,0.14,87.18,49,0.022,0.064,0.056,32277
"Pineapple and grapefruit juice drink, canned",Beverages,0.2,0.007,0.014,0.1,0.046,0.061,11.6,11.52,0.1,87.9,47,0.006,0.01,0.028,32278
"Pineapple and orange juice drink, canned",Beverages,1.3,0.005,0.003,0.1,0.0225,0.046,11.8,11.59,0,86.9,50,0,0,0,32279
"QUAKER OATS, PROPEL Fitness Water, fruit-flavored, non-carbonated",Beverages,0,0.001,0.013,0,0.0088,0.016,1.22,1.22,0,98.78,5,0,0,0,32280
"Ready-to-drink reduced fat milk beverage, flavored and sweetened, with added calcium, vitamin A and vitamin D",Beverages,3.05,0.164,0.049,0.4,0.0002,0.13,12.08,11.4,1.83,82.32,77,1.124,0.47,0.075,32281
"Rice drink, unsweetened, with added calcium, vitamins A and D",Beverages,0.28,0.118,0.039,0.3,0,0.027,9.17,5.28,0.97,89.28,47,0,0.625,0.313,32282
"Shake, fast food, chocolate",Beverages,3.4,0.113,0.097,1.9,0.0004,0.2,20.5,18.6,3.7,71.5,127,2.313,1.075,0.14,32283
"Shake, fast food, strawberry",Beverages,3.4,0.113,0.083,0.4,0.0008,0.182,18.9,0,2.8,74.1,113,1.734,0,0,32284
"Shake, fast food, vanilla",Beverages,3.37,0.115,0.081,0.9,0,0.166,19.59,13.63,6.52,69.65,148,3.962,1.785,0.316,32285
"Sports drink, COCA-COLA, POWERADE, lemon-lime flavored, ready-to-drink",Beverages,0,0.001,0.022,0,0,0.018,7.84,6.11,0.05,91.91,32,0.008,0.011,0.028,32286
"Sports drink, PEPSICO QUAKER GATORADE, GATORADE, original, fruit-flavored, ready-to-drink",Beverages,0,0.001,0.039,0,0.0004,0.015,6.43,5.24,0,93.38,26,0,0,0,32287
"Sports drink, fruit-flavored, low calorie, ready-to-drink",Beverages,0,0,0.035,0,0.0063,0.01,3,0,0,96.8,11,0,0,0,32288
"Strawberry-flavor beverage mix, powder",Beverages,0.1,0.004,0.038,0,0.0003,0.006,99.1,95.45,0.2,0.4,389,0,0,0,32289
"Strawberry-flavor beverage mix, powder, prepared with whole milk",Beverages,3,0.11,0.048,0,0.0009,0.139,12.3,0,3.1,80.9,88,1.91,0.886,0.114,32290
"Tea, brewed, prepared with distilled water",Beverages,0,0,0,0,0,0.021,0.3,0,0,99.7,1,0.002,0,0.004,32291
"Tea, brewed, prepared with tap water",Beverages,0,0,0.003,0,0,0.037,0.3,0,0,99.7,1,0.002,0,0.004,32292
"Tea, brewed, prepared with tap water, decaffeinated",Beverages,0,0,0.003,0,0,0.037,0.3,0,0,99.7,1,0.002,0,0.004,32293
"Tea, herb, chamomile, brewed",Beverages,0,0.002,0.001,0,0,0.009,0.2,0,0,99.7,1,0.002,0,0.005,32294
"Tea, herb, other than chamomile, brewed",Beverages,0,0.002,0.001,0,0,0.009,0.2,0,0,99.7,1,0.002,0,0.005,32295
"Tea, instant, sweetened with sodium saccharin, lemon-flavored, powder",Beverages,3.3,0.021,0.412,0,0,2.568,85.4,1.88,0.6,3.5,338,0,0,0,32296
"Tea, instant, sweetened with sodium saccharin, lemon-flavored, powder, decaffeinated",Beverages,3.3,0.021,0.412,0,0,2.568,85.4,1.88,0.6,3.5,338,0,0,0,32297
"Tea, instant, sweetened with sodium saccharin, lemon-flavored, prepared",Beverages,0.02,0.003,0.006,0,0,0.014,0.44,0.01,0,99.41,2,0,0,0,32298
"Tea, instant, sweetened with sugar, lemon-flavored, with added ascorbic acid, powder",Beverages,0.6,0.003,0.005,0,0.1,0.217,97.6,0,0.3,0.3,385,0.037,0.011,0.091,32299
"Tea, instant, sweetened with sugar, lemon-flavored, without added ascorbic acid, powder",Beverages,0.12,0.002,0.005,0.7,0,0.169,98.55,95.29,0.73,0.13,401,0.097,0.033,0.218,32300
"Tea, instant, sweetened with sugar, lemon-flavored, without added ascorbic acid, powder, decaffeinated",Beverages,0.12,0.002,0.005,0,0,0.169,98.55,95.29,0.73,0.13,385,0.091,0.027,0.209,32301
"Tea, instant, sweetened with sugar, lemon-flavored, without added ascorbic acid, powder, prepared",Beverages,0.01,0.002,0.002,0.1,0,0.015,8.61,8.33,0.06,91.18,35,0.008,0.002,0.018,32302
"Tea, instant, unsweetened, lemon-flavored, powder",Beverages,7.4,0.028,0.055,5,0,3.453,78.52,4.52,0.18,4.3,345,0.047,0.012,0.117,32303
"Tea, instant, unsweetened, powder",Beverages,20.21,0.118,0.072,8.5,0,6.04,58.66,5.53,0,5.09,315,0,0,0,32304
"Tea, instant, unsweetened, powder, decaffeinated",Beverages,20.21,0.118,0.072,8.5,0,6.04,58.66,5.53,0,5.09,315,0,0,0,32305
"Tea, instant, unsweetened, powder, prepared",Beverages,0.06,0.003,0.004,0,0,0.018,0.17,0.02,0,99.62,1,0,0,0,32306
"Tea, ready-to-drink, ARIZONA iced tea, with lemon flavor",Beverages,0,0.003,0.004,0,0,0.01,9.77,9.63,0,90.15,39,0,0,0,32307
"Tea, ready-to-drink, LIPTON BRISK iced tea, with lemon flavor",Beverages,0,0.001,0.021,0,0,0.019,8.81,8.69,0,91.06,35,0,0,0,32308
"Tea, ready-to-drink, NESTLE, COOL NESTEA ice tea lemon flavor",Beverages,0,0.003,0.021,0,0,0.019,9.09,9.09,0,91.18,36,0,0,0,32309
"Tea, ready-to-drink, unsweetened, WENDY'S, fast food, without ice",Beverages,0.22,0.002,0.003,0,0,0.014,0,0,0,99.79,1,0,0,0,32310
"Vegetable and fruit juice drink, reduced calorie, with low-calorie sweetener, added vitamin C",Beverages,0,0.008,0.014,0,0.0251,0.024,1.1,0.63,0,98.78,4,0,0,0,32311
"Water with added vitamins and minerals, bottles, sweetened, assorted fruit flavors",Beverages,0,0.017,0,0,0.0127,0,5.49,5.49,0,94.44,22,0,0,0,32312
"Water, bottled, PERRIER",Beverages,0,0.014,0.001,0,0,0,0,0,0,99.9,0,0,0,0,32313
"Water, bottled, POLAND SPRING",Beverages,0,0.001,0.001,0,0,0,0,0,0,100,0,0,0,0,32314
"Water, bottled, generic",Beverages,0,0.01,0.002,0,0,0,0,0,0,99.98,0,0,0,0,32315
"Water, bottled, non-carbonated, CALISTOGA",Beverages,0,0,0,0,0,0,0,0,0,100,0,0,0,0,32316
"Water, bottled, non-carbonated, CRYSTAL GEYSER",Beverages,0,0.003,0.001,0,0,0,0,0,0,100,0,0,0,0,32317
"Water, bottled, non-carbonated, DANNON",Beverages,0,0.003,0,0,0,0,0,0,0,99.98,0,0,0,0,32318
"Water, bottled, non-carbonated, DANNON Fluoride To Go",Beverages,0,0.003,0.001,0,0,0,0.03,0,0,99.97,0,0,0,0,32319
"Water, bottled, non-carbonated, DASANI",Beverages,0,0,0,0,0,0,0,0,0,99.96,0,0,0,0,32320
"Water, bottled, non-carbonated, EVIAN",Beverages,0,0.008,0,0,0,0,0,0,0,99.97,0,0,0,0,32321
"Water, bottled, non-carbonated, NAYA",Beverages,0,0.004,0.001,0,0,0,0,0,0,100,0,0,0,0,32322
"Water, bottled, non-carbonated, PEPSI, AQUAFINA",Beverages,0,0,0,0,0,0,0,0,0,99.97,0,0,0,0,32323
"Water, non-carbonated, bottles, natural fruit flavors, sweetened with low calorie sweetener",Beverages,0,0.001,0.003,0,0,0.004,0.15,0,0,99.85,1,0,0,0,32324
"Water, tap, drinking",Beverages,0,0.003,0.004,0,0,0,0,0,0,99.9,0,0,0,0,32325
"Water, tap, municipal",Beverages,0,0.003,0.003,0,0,0.001,0,0,0,99.9,0,0,0,0,32326
"Water, tap, well",Beverages,0,0.003,0.005,0,0,0,0,0,0,99.9,0,0,0,0,32327
"Water, with corn syrup and/or sugar and low calorie sweetener, fruit flavored",Beverages,0,0.017,0.008,0,0.0127,0,4.5,4.5,0,94.44,18,0,0,0,32328
"Whiskey sour mix, bottled",Beverages,0.1,0.002,0.102,0,0.0027,0.028,21.4,21.4,0.1,78.2,87,0.008,0.002,0.02,32329
"Whiskey sour mix, bottled, with added potassium and sodium",Beverages,0.1,0.002,0.033,0,0.0027,0.007,21.4,0,0.1,78.2,84,0.008,0.002,0.02,32330
"Whiskey sour mix, powder",Beverages,0.6,0.272,0.274,0,0.0027,0.019,97.3,97.3,0.1,0.6,383,0.019,0.006,0.037,32331
"Wine, non-alcoholic",Beverages,0.5,0.009,0.007,0,0,0.088,1.1,1.1,0,98.2,6,0,0,0,32332
"Crustaceans, crab, alaska king, cooked, moist heat",Finfish and Shellfish Products,19.35,0.059,1.072,0,0.0076,0.262,0,0,1.54,77.55,97,0.133,0.185,0.536,32333
"Crustaceans, crab, alaska king, imitation, made from surimi",Finfish and Shellfish Products,7.62,0.013,0.841,0.5,0,0.09,15,6.25,0.46,74.66,95,0.176,0.117,0.102,32334
"Crustaceans, crab, alaska king, raw",Finfish and Shellfish Products,18.29,0.046,0.836,0,0.007,0.204,0,0,0.6,79.57,84,0.09,0.08,0.13,32335
"Crustaceans, crab, blue, canned",Finfish and Shellfish Products,17.88,0.091,0.395,0,0.0033,0.259,0,0,0.74,79.69,83,0.201,0.129,0.258,32336
"Crustaceans, crab, blue, cooked, moist heat",Finfish and Shellfish Products,17.88,0.091,0.395,0,0.0033,0.259,0,0,0.74,79.69,83,0.201,0.129,0.258,32337
"Crustaceans, crab, blue, crab cakes",Finfish and Shellfish Products,20.21,0.105,0.33,0,0.0028,0.324,0.48,0,7.52,71,155,1.483,2.815,2.273,32338
"Crustaceans, crab, blue, raw",Finfish and Shellfish Products,18.06,0.089,0.293,0,0.003,0.329,0.04,0,1.08,79.02,87,0.222,0.192,0.387,32339
"Crustaceans, crab, dungeness, cooked, moist heat",Finfish and Shellfish Products,22.32,0.059,0.378,0,0.0036,0.408,0.95,0,1.24,73.31,110,0.168,0.214,0.407,32340
"Crustaceans, crab, dungeness, raw",Finfish and Shellfish Products,17.41,0.046,0.295,0,0.0035,0.354,0.74,0,0.97,79.18,86,0.132,0.167,0.317,32341
"Crustaceans, crab, queen, cooked, moist heat",Finfish and Shellfish Products,23.72,0.033,0.691,0,0.0072,0.2,0,0,1.51,75.1,115,0.183,0.328,0.54,32342
"Crustaceans, crab, queen, raw",Finfish and Shellfish Products,18.5,0.026,0.539,0,0.007,0.173,0,0,1.18,80.58,90,0.143,0.256,0.422,32343
"Crustaceans, crayfish, mixed species, farmed, cooked, moist heat",Finfish and Shellfish Products,17.52,0.051,0.097,0,0.0005,0.238,0,0,1.3,80.8,87,0.216,0.251,0.413,32344
"Crustaceans, crayfish, mixed species, farmed, raw",Finfish and Shellfish Products,14.85,0.025,0.062,0,0.0005,0.261,0,0,0.97,84.05,72,0.163,0.178,0.313,32345
"Crustaceans, crayfish, mixed species, wild, cooked, moist heat",Finfish and Shellfish Products,16.77,0.06,0.094,0,0.0009,0.296,0,0,1.2,79.37,82,0.181,0.244,0.367,32346
"Crustaceans, crayfish, mixed species, wild, raw",Finfish and Shellfish Products,15.97,0.027,0.058,0,0.0012,0.302,0,0,0.95,82.24,77,0.159,0.174,0.293,32347
"Crustaceans, lobster, northern, cooked, moist heat",Finfish and Shellfish Products,19,0.096,0.486,0,0,0.23,0,0,0.86,78.11,89,0.208,0.253,0.34,32348
"Crustaceans, lobster, northern, raw",Finfish and Shellfish Products,16.52,0.084,0.423,0,0,0.2,0,0,0.75,80.95,77,0.181,0.22,0.296,32349
"Crustaceans, shrimp, mixed species, canned",Finfish and Shellfish Products,20.42,0.145,0.777,0,0.0040999999999999995,0.08,0,0,1.36,75.85,100,0.188,0.108,0.684,32350
"Crustaceans, shrimp, mixed species, cooked, breaded and fried",Finfish and Shellfish Products,21.39,0.067,0.344,0.4,0.0015,0.225,11.47,0.8,12.28,52.86,242,2.087,3.812,5.085,32351
"Crustaceans, shrimp, mixed species, cooked, moist heat",Finfish and Shellfish Products,22.78,0.091,0.947,0,0,0.17,1.52,0,1.7,71.56,119,0.192,0.133,0.218,32352
"Crustaceans, shrimp, mixed species, imitation, made from surimi",Finfish and Shellfish Products,12.39,0.019,0.705,0,0,0.089,9.13,0,1.47,74.91,101,0.29,0.22,0.75,32353
"Crustaceans, shrimp, mixed species, raw",Finfish and Shellfish Products,13.61,0.054,0.566,0,0,0.113,0.91,0,1.01,83.01,71,0.115,0.08,0.13,32354
"Crustaceans, spiny lobster, mixed species, cooked, moist heat",Finfish and Shellfish Products,26.41,0.063,0.227,0,0.0021000000000000003,0.208,3.12,0,1.94,66.76,143,0.303,0.353,0.756,32355
"Crustaceans, spiny lobster, mixed species, raw",Finfish and Shellfish Products,20.6,0.049,0.177,0,0.002,0.18,2.43,0,1.51,74.07,112,0.237,0.275,0.59,32356
"Fish, anchovy, european, canned in oil, drained solids",Finfish and Shellfish Products,28.89,0.232,3.668,0,0,0.544,0,0,9.71,50.3,210,2.203,3.77,2.562,32357
"Fish, anchovy, european, raw",Finfish and Shellfish Products,20.35,0.147,0.104,0,0,0.383,0,0,4.84,73.37,131,1.282,1.182,1.637,32358
"Fish, bass, fresh water, mixed species, raw",Finfish and Shellfish Products,18.86,0.08,0.07,0,0.002,0.356,0,0,3.69,75.66,114,0.78,1.431,1.062,32359
"Fish, bass, freshwater, mixed species, cooked, dry heat",Finfish and Shellfish Products,24.18,0.103,0.09,0,0.0021000000000000003,0.456,0,0,4.73,68.79,146,1.001,1.835,1.36,32360
"Fish, bass, striped, cooked, dry heat",Finfish and Shellfish Products,22.73,0.019,0.088,0,0,0.328,0,0,2.99,73.36,124,0.65,0.846,1.005,32361
"Fish, bass, striped, raw",Finfish and Shellfish Products,17.73,0.015,0.069,0,0,0.256,0,0,2.33,79.22,97,0.507,0.66,0.784,32362
"Fish, bluefish, cooked, dry heat",Finfish and Shellfish Products,25.69,0.009,0.077,0,0,0.477,0,0,5.44,62.64,159,1.172,2.297,1.358,32363
"Fish, bluefish, raw",Finfish and Shellfish Products,20.04,0.007,0.06,0,0,0.372,0,0,4.24,70.86,124,0.915,1.793,1.06,32364
"Fish, burbot, cooked, dry heat",Finfish and Shellfish Products,24.76,0.064,0.124,0,0,0.518,0,0,1.04,73.41,115,0.209,0.17,0.381,32365
"Fish, burbot, raw",Finfish and Shellfish Products,19.31,0.05,0.097,0,0,0.404,0,0,0.81,79.26,90,0.163,0.133,0.297,32366
"Fish, butterfish, cooked, dry heat",Finfish and Shellfish Products,22.15,0.028,0.114,0,0,0.481,0,0,10.28,66.83,187,0,0,0,32367
"Fish, butterfish, raw",Finfish and Shellfish Products,17.28,0.022,0.089,0,0,0.375,0,0,8.02,74.13,146,3.38,3.38,0.59,32368
"Fish, carp, cooked, dry heat",Finfish and Shellfish Products,22.86,0.052,0.063,0,0.0016,0.427,0,0,7.17,69.63,162,1.388,2.985,1.835,32369
"Fish, carp, raw",Finfish and Shellfish Products,17.83,0.041,0.049,0,0.0016,0.333,0,0,5.6,76.31,127,1.083,2.328,1.431,32370
"Fish, catfish, channel, cooked, breaded and fried",Finfish and Shellfish Products,18.09,0.044,0.28,0.7,0,0.34,8.04,0,13.33,58.81,229,3.288,5.611,3.325,32371
"Fish, catfish, channel, farmed, cooked, dry heat",Finfish and Shellfish Products,18.44,0.009,0.119,0,0,0.366,0,0,7.19,74.65,144,1.586,3.115,1.354,32372
"Fish, catfish, channel, farmed, raw",Finfish and Shellfish Products,15.23,0.008,0.098,0,0,0.302,0,0,5.94,79.06,119,1.31,2.573,1.119,32373
"Fish, catfish, channel, wild, cooked, dry heat",Finfish and Shellfish Products,18.47,0.011,0.05,0,0.0008,0.419,0,0,2.85,77.67,105,0.744,1.099,0.636,32374
"Fish, catfish, channel, wild, raw",Finfish and Shellfish Products,16.38,0.014,0.043,0,0.0007,0.358,0,0,2.82,80.36,95,0.722,0.844,0.865,32375
"Fish, caviar, black and red, granular",Finfish and Shellfish Products,24.6,0.275,1.5,0,0,0.181,4,0,17.9,47.5,264,4.06,4.631,7.405,32376
"Fish, cisco, raw",Finfish and Shellfish Products,18.99,0.012,0.055,0,0,0.354,0,0,1.91,78.93,98,0.421,0.469,0.613,32377
"Fish, cisco, smoked",Finfish and Shellfish Products,16.36,0.026,0.481,0,0,0.293,0,0,11.9,69.8,177,1.741,5.484,2.274,32378
"Fish, cod, Atlantic, canned, solids and liquid",Finfish and Shellfish Products,22.76,0.021,0.218,0,0.001,0.528,0,0,0.86,75.61,105,0.167,0.124,0.291,32379
"Fish, cod, Atlantic, cooked, dry heat",Finfish and Shellfish Products,22.83,0.014,0.078,0,0.001,0.244,0,0,0.86,75.92,105,0.168,0.124,0.292,32380
"Fish, cod, Atlantic, dried and salted",Finfish and Shellfish Products,62.82,0.16,7.027,0,0.0035,1.458,0,0,2.37,16.14,290,0.462,0.342,0.804,32381
"Fish, cod, Atlantic, raw",Finfish and Shellfish Products,17.81,0.016,0.054,0,0.001,0.413,0,0,0.67,81.22,82,0.131,0.094,0.231,32382
"Fish, cod, Pacific, cooked, dry heat",Finfish and Shellfish Products,18.73,0.01,0.372,0,0,0.289,0,0,0.5,80.31,85,0.105,0.089,0.201,32383
"Fish, cod, Pacific, raw",Finfish and Shellfish Products,15.27,0.008,0.303,0,0,0.235,0,0,0.41,83.95,69,0.085,0.073,0.164,32384
"Fish, croaker, Atlantic, cooked, breaded and fried",Finfish and Shellfish Products,18.2,0.032,0.348,0.4,0,0.34,7.54,0,12.67,59.76,221,3.476,5.32,2.917,32385
"Fish, croaker, Atlantic, raw",Finfish and Shellfish Products,17.78,0.015,0.056,0,0,0.345,0,0,3.17,78.03,104,1.088,1.149,0.464,32386
"Fish, cusk, cooked, dry heat",Finfish and Shellfish Products,24.35,0.013,0.04,0,0,0.503,0,0,0.88,69.68,112,0,0,0,32387
"Fish, cusk, raw",Finfish and Shellfish Products,18.99,0.01,0.031,0,0,0.392,0,0,0.69,76.35,87,0.13,0.09,0.28,32388
"Fish, dolphinfish, cooked, dry heat",Finfish and Shellfish Products,23.72,0.019,0.113,0,0,0.533,0,0,0.9,71.22,109,0.241,0.155,0.211,32389
"Fish, dolphinfish, raw",Finfish and Shellfish Products,18.5,0.015,0.088,0,0,0.416,0,0,0.7,77.55,85,0.188,0.121,0.165,32390
"Fish, drum, freshwater, cooked, dry heat",Finfish and Shellfish Products,22.49,0.077,0.096,0,0.001,0.353,0,0,6.32,70.94,153,1.434,2.807,1.479,32391
"Fish, drum, freshwater, raw",Finfish and Shellfish Products,17.54,0.06,0.075,0,0.001,0.275,0,0,4.93,77.33,119,1.119,2.19,1.154,32392
"Fish, eel, mixed species, cooked, dry heat",Finfish and Shellfish Products,23.65,0.026,0.065,0,0.0018,0.349,0,0,14.95,59.31,236,3.023,9.218,1.214,32393
"Fish, eel, mixed species, raw",Finfish and Shellfish Products,18.44,0.02,0.051,0,0.0018,0.272,0,0,11.66,68.26,184,2.358,7.19,0.947,32394
"Fish, fish portions and sticks, frozen, preheated",Finfish and Shellfish Products,11.04,0.026,0.421,1.4,0,0.216,21.18,2.5,13.25,53.08,249,2.758,4.21,5.701,32395
"Fish, flatfish (flounder and sole species), cooked, dry heat",Finfish and Shellfish Products,15.24,0.025,0.363,0,0,0.197,0,0,2.37,81.11,86,0.542,0.657,0.459,32396
"Fish, flatfish (flounder and sole species), raw",Finfish and Shellfish Products,12.41,0.021,0.296,0,0,0.16,0,0,1.93,84.63,70,0.441,0.535,0.374,32397
"Fish, gefiltefish, commercial, sweet recipe",Finfish and Shellfish Products,9.07,0.023,0.524,0,0.0008,0.091,7.41,0,1.73,80.35,84,0.412,0.824,0.285,32398
"Fish, grouper, mixed species, cooked, dry heat",Finfish and Shellfish Products,24.84,0.021,0.053,0,0,0.475,0,0,1.3,73.36,118,0.299,0.268,0.403,32399
"Fish, grouper, mixed species, raw",Finfish and Shellfish Products,19.38,0.027,0.053,0,0,0.483,0,0,1.02,79.22,92,0.233,0.202,0.321,32400
"Fish, haddock, cooked, dry heat",Finfish and Shellfish Products,19.99,0.014,0.261,0,0,0.351,0,0,0.55,79.65,90,0.111,0.074,0.204,32401
"Fish, haddock, raw",Finfish and Shellfish Products,16.32,0.011,0.213,0,0,0.286,0,0,0.45,83.38,74,0.091,0.061,0.166,32402
"Fish, haddock, smoked",Finfish and Shellfish Products,25.23,0.049,0.763,0,0,0.415,0,0,0.96,71.48,116,0.173,0.157,0.321,32403
"Fish, halibut, Atlantic and Pacific, cooked, dry heat",Finfish and Shellfish Products,22.54,0.009,0.082,0,0,0.528,0,0,1.61,76.12,111,0.354,0.573,0.352,32404
"Fish, halibut, Atlantic and Pacific, raw",Finfish and Shellfish Products,18.56,0.007,0.068,0,0,0.435,0,0,1.33,80.34,91,0.292,0.471,0.29,32405
"Fish, halibut, Greenland, raw",Finfish and Shellfish Products,14.37,0.003,0.08,0,0,0.268,0,0,13.84,70.27,186,2.419,8.378,1.367,32406
"Fish, halibut, greenland, cooked, dry heat",Finfish and Shellfish Products,18.42,0.004,0.103,0,0,0.344,0,0,17.74,61.88,239,3.102,10.742,1.753,32407
"Fish, herring, Atlantic, cooked, dry heat",Finfish and Shellfish Products,23.03,0.074,0.115,0,0.0007,0.419,0,0,11.59,64.16,203,2.615,4.79,2.735,32408
"Fish, herring, Atlantic, kippered",Finfish and Shellfish Products,24.58,0.084,0.918,0,0.001,0.447,0,0,12.37,59.7,217,2.791,5.112,2.919,32409
"Fish, herring, Atlantic, pickled",Finfish and Shellfish Products,14.19,0.077,0.87,0,0,0.069,9.64,7.71,18,55.22,262,2.381,11.947,1.679,32410
"Fish, herring, Atlantic, raw",Finfish and Shellfish Products,17.96,0.057,0.09,0,0.0007,0.327,0,0,9.04,72.05,158,2.04,3.736,2.133,32411
"Fish, herring, Pacific, cooked, dry heat",Finfish and Shellfish Products,21.01,0.106,0.095,0,0,0.542,0,0,17.79,63.49,250,4.174,8.807,3.106,32412
"Fish, herring, Pacific, raw",Finfish and Shellfish Products,16.39,0.083,0.074,0,0,0.423,0,0,13.88,71.52,195,3.257,6.872,2.423,32413
"Fish, ling, cooked, dry heat",Finfish and Shellfish Products,24.35,0.044,0.173,0,0,0.486,0,0,0.82,73.88,111,0,0,0,32414
"Fish, ling, raw",Finfish and Shellfish Products,18.99,0.034,0.135,0,0,0.379,0,0,0.64,79.63,87,0.12,0.09,0.22,32415
"Fish, lingcod, cooked, dry heat",Finfish and Shellfish Products,22.64,0.018,0.076,0,0,0.56,0,0,1.36,75.68,109,0.255,0.447,0.383,32416
"Fish, lingcod, raw",Finfish and Shellfish Products,17.66,0.014,0.059,0,0,0.437,0,0,1.06,81.03,85,0.197,0.35,0.3,32417
"Fish, mackerel, Atlantic, cooked, dry heat",Finfish and Shellfish Products,23.85,0.015,0.083,0,0.0004,0.401,0,0,17.81,53.27,262,4.176,7.006,4.3,32418
"Fish, mackerel, Atlantic, raw",Finfish and Shellfish Products,18.6,0.012,0.09,0,0.0004,0.314,0,0,13.89,63.55,205,3.257,5.456,3.35,32419
"Fish, mackerel, Pacific and jack, mixed species, cooked, dry heat",Finfish and Shellfish Products,25.73,0.029,0.11,0,0.0021000000000000003,0.521,0,0,10.12,61.73,201,2.881,3.371,2.488,32420
"Fish, mackerel, Pacific and jack, mixed species, raw",Finfish and Shellfish Products,20.07,0.023,0.086,0,0.002,0.406,0,0,7.89,70.15,158,2.247,2.629,1.94,32421
"Fish, mackerel, jack, canned, drained solids",Finfish and Shellfish Products,23.19,0.241,0.379,0,0.0009,0.194,0,0,6.3,69.17,156,1.857,2.225,1.651,32422
"Fish, mackerel, king, cooked, dry heat",Finfish and Shellfish Products,26,0.04,0.203,0,0.0016,0.558,0,0,2.56,69.04,134,0.465,0.979,0.589,32423
"Fish, mackerel, king, raw",Finfish and Shellfish Products,20.28,0.031,0.158,0,0.0016,0.435,0,0,2,75.85,105,0.363,0.764,0.46,32424
"Fish, mackerel, salted",Finfish and Shellfish Products,18.5,0.066,4.45,0,0,0.52,0,0,25.1,43,305,7.148,8.32,6.21,32425
"Fish, mackerel, spanish, cooked, dry heat",Finfish and Shellfish Products,23.59,0.013,0.066,0,0.0016,0.554,0,0,6.32,68.46,158,1.801,2.139,1.805,32426
"Fish, mackerel, spanish, raw",Finfish and Shellfish Products,19.29,0.011,0.059,0,0.0016,0.446,0,0,6.3,71.67,139,1.828,1.53,1.739,32427
"Fish, milkfish, cooked, dry heat",Finfish and Shellfish Products,26.32,0.065,0.092,0,0,0.374,0,0,8.63,62.63,190,0,0,0,32428
"Fish, milkfish, raw",Finfish and Shellfish Products,20.53,0.051,0.072,0,0,0.292,0,0,6.73,70.85,148,1.66,2.58,1.84,32429
"Fish, monkfish, cooked, dry heat",Finfish and Shellfish Products,18.56,0.01,0.023,0,0.001,0.513,0,0,1.95,78.51,97,0,0,0,32430
"Fish, monkfish, raw",Finfish and Shellfish Products,14.48,0.008,0.018,0,0.001,0.4,0,0,1.52,83.24,76,0.34,0.24,0.61,32431
"Fish, mullet, striped, cooked, dry heat",Finfish and Shellfish Products,24.81,0.031,0.071,0,0.0012,0.458,0,0,4.86,70.52,150,1.431,1.382,0.917,32432
"Fish, mullet, striped, raw",Finfish and Shellfish Products,19.35,0.041,0.065,0,0.0012,0.357,0,0,3.79,77.01,117,1.116,1.078,0.715,32433
"Fish, ocean perch, Atlantic, cooked, dry heat",Finfish and Shellfish Products,18.51,0.034,0.347,0,0,0.226,0,0,1.87,74.24,96,0.33,0.575,0.368,32434
"Fish, ocean perch, Atlantic, raw",Finfish and Shellfish Products,15.31,0.028,0.287,0,0,0.187,0,0,1.54,78.7,79,0.273,0.475,0.304,32435
"Fish, perch, mixed species, cooked, dry heat",Finfish and Shellfish Products,24.86,0.102,0.079,0,0.0017,0.344,0,0,1.18,73.25,117,0.237,0.195,0.472,32436
"Fish, perch, mixed species, raw",Finfish and Shellfish Products,19.39,0.08,0.062,0,0.0017,0.269,0,0,0.92,79.13,91,0.185,0.152,0.368,32437
"Fish, pike, northern, cooked, dry heat",Finfish and Shellfish Products,24.69,0.073,0.049,0,0.0038,0.331,0,0,0.88,72.97,113,0.151,0.201,0.259,32438
"Fish, pike, northern, raw",Finfish and Shellfish Products,19.26,0.057,0.039,0,0.0038,0.259,0,0,0.69,78.92,88,0.118,0.157,0.202,32439
"Fish, pike, walleye, cooked, dry heat",Finfish and Shellfish Products,24.54,0.141,0.065,0,0,0.499,0,0,1.56,73.47,119,0.319,0.377,0.573,32440
"Fish, pike, walleye, raw",Finfish and Shellfish Products,19.14,0.11,0.051,0,0,0.389,0,0,1.22,79.31,93,0.249,0.294,0.447,32441
"Fish, pollock, Atlantic, cooked, dry heat",Finfish and Shellfish Products,24.92,0.077,0.11,0,0,0.456,0,0,1.26,72.03,118,0.17,0.143,0.622,32442
"Fish, pollock, Atlantic, raw",Finfish and Shellfish Products,19.44,0.06,0.086,0,0,0.356,0,0,0.98,78.18,92,0.135,0.112,0.483,32443
"Fish, pollock, walleye, cooked, dry heat",Finfish and Shellfish Products,23.48,0.072,0.104,0,0,0.43,0,0,1.18,73.65,111,0.159,0.134,0.583,32444
"Fish, pollock, walleye, raw",Finfish and Shellfish Products,12.19,0.015,0.333,0,0,0.16,0,0,0.41,86.75,56,0.124,0.079,0.195,32445
"Fish, pompano, florida, cooked, dry heat",Finfish and Shellfish Products,23.69,0.043,0.076,0,0,0.636,0,0,12.14,62.97,211,4.499,3.315,1.458,32446
"Fish, pompano, florida, raw",Finfish and Shellfish Products,18.48,0.022,0.065,0,0,0.381,0,0,9.47,71.12,164,3.509,2.586,1.137,32447
"Fish, pout, ocean, cooked, dry heat",Finfish and Shellfish Products,21.33,0.013,0.078,0,0,0.513,0,0,1.17,76.1,102,0.41,0.422,0.041,32448
"Fish, pout, ocean, raw",Finfish and Shellfish Products,16.64,0.01,0.061,0,0,0.4,0,0,0.91,81.36,79,0.32,0.329,0.032,32449
"Fish, rockfish, Pacific, mixed species, cooked, dry heat",Finfish and Shellfish Products,22.23,0.017,0.089,0,0,0.467,0,0,1.62,74.67,109,0.42,0.466,0.485,32450
"Fish, rockfish, Pacific, mixed species, raw",Finfish and Shellfish Products,18.36,0.014,0.074,0,0,0.386,0,0,1.34,79.08,90,0.347,0.385,0.401,32451
"Fish, roe, mixed species, cooked, dry heat",Finfish and Shellfish Products,28.62,0.028,0.117,0,0.016399999999999998,0.283,1.92,0,8.23,58.63,204,1.866,2.129,3.404,32452
"Fish, roe, mixed species, raw",Finfish and Shellfish Products,22.32,0.022,0.091,0,0.016,0.221,1.5,0,6.42,67.73,143,1.456,1.661,2.656,32453
"Fish, roughy, orange, cooked, dry heat",Finfish and Shellfish Products,22.64,0.011,0.069,0,0,0.181,0,0,0.9,66.97,105,0.034,0.439,0.184,32454
"Fish, roughy, orange, raw",Finfish and Shellfish Products,16.41,0.009,0.072,0,0,0.167,0,0,0.7,75.67,76,0.015,0.239,0.109,32455
"Fish, sablefish, cooked, dry heat",Finfish and Shellfish Products,17.19,0.045,0.072,0,0,0.459,0,0,19.62,62.85,250,4.099,10.328,2.618,32456
"Fish, sablefish, raw",Finfish and Shellfish Products,13.41,0.035,0.056,0,0,0.358,0,0,15.3,71.02,195,3.201,8.057,2.042,32457
"Fish, sablefish, smoked",Finfish and Shellfish Products,17.65,0.05,0.737,0,0,0.471,0,0,20.14,60.14,257,4.213,10.605,2.688,32458
"Fish, salmon, Atlantic, farmed, cooked, dry heat",Finfish and Shellfish Products,22.1,0.015,0.061,0,0.0037,0.384,0,0,12.35,64.75,206,2.504,4.432,4.426,32459
"Fish, salmon, Atlantic, farmed, raw",Finfish and Shellfish Products,20.42,0.009,0.059,0,0.0039,0.363,0,0,13.42,64.89,208,3.05,3.77,3.886,32460
"Fish, salmon, Atlantic, wild, cooked, dry heat",Finfish and Shellfish Products,25.44,0.015,0.056,0,0,0.628,0,0,8.13,59.62,182,1.257,2.697,3.256,32461
"Fish, salmon, Atlantic, wild, raw",Finfish and Shellfish Products,19.84,0.012,0.044,0,0,0.49,0,0,6.34,68.5,142,0.981,2.103,2.539,32462
"Fish, salmon, chinook, cooked, dry heat",Finfish and Shellfish Products,25.72,0.028,0.06,0,0.0040999999999999995,0.505,0,0,13.38,65.6,231,3.214,5.742,2.662,32463
"Fish, salmon, chinook, raw",Finfish and Shellfish Products,19.93,0.026,0.047,0,0.004,0.394,0,0,10.43,71.64,179,3.1,4.399,2.799,32464
"Fish, salmon, chinook, smoked",Finfish and Shellfish Products,18.28,0.011,0.784,0,0,0.175,0,0,4.32,72,117,0.929,2.023,0.995,32465
"Fish, salmon, chinook, smoked, (lox), regular",Finfish and Shellfish Products,18.28,0.011,2,0,0,0.175,0,0,4.32,72,117,0.929,2.023,0.995,32466
"Fish, salmon, chum, canned, without salt, drained solids with bone",Finfish and Shellfish Products,21.43,0.249,0.075,0,0,0.3,0,0,5.5,70.77,141,1.486,1.919,1.517,32467
"Fish, salmon, chum, cooked, dry heat",Finfish and Shellfish Products,25.82,0.014,0.064,0,0,0.55,0,0,4.83,68.44,154,1.077,1.976,1.152,32468
"Fish, salmon, chum, drained solids with bone",Finfish and Shellfish Products,21.43,0.249,0.487,0,0,0.3,0,0,5.5,70.77,141,1.486,1.919,1.517,32469
"Fish, salmon, chum, raw",Finfish and Shellfish Products,20.14,0.011,0.05,0,0,0.429,0,0,3.77,75.38,120,0.84,1.541,0.898,32470
"Fish, salmon, coho, farmed, cooked, dry heat",Finfish and Shellfish Products,24.3,0.012,0.052,0,0.0015,0.46,0,0,8.23,67,178,1.944,3.618,1.963,32471
"Fish, salmon, coho, farmed, raw",Finfish and Shellfish Products,21.27,0.012,0.047,0,0.0011,0.45,0,0,7.67,70.47,160,1.816,3.33,1.861,32472
"Fish, salmon, coho, wild, cooked, dry heat",Finfish and Shellfish Products,23.45,0.045,0.058,0,0.0014,0.434,0,0,4.3,71.5,139,1.054,1.581,1.273,32473
"Fish, salmon, coho, wild, cooked, moist heat",Finfish and Shellfish Products,27.36,0.046,0.053,0,0.001,0.455,0,0,7.5,65.39,184,1.595,2.702,2.521,32474
"Fish, salmon, coho, wild, raw",Finfish and Shellfish Products,21.62,0.036,0.046,0,0.001,0.423,0,0,5.93,72.66,146,1.26,2.134,1.992,32475
"Fish, salmon, pink, canned, drained solids with bone",Finfish and Shellfish Products,23.08,0.277,0.399,0,0,0.311,0,0,4.83,70.52,136,0.848,0.887,1.345,32476
"Fish, salmon, pink, canned, solids with bone and liquid",Finfish and Shellfish Products,19.78,0.213,0.554,0,0,0.326,0,0,6.05,68.81,139,1.535,1.807,2.049,32477
"Fish, salmon, pink, canned, without salt, solids with bone and liquid",Finfish and Shellfish Products,19.78,0.213,0.075,0,0,0.326,0,0,6.05,70.03,139,1.535,1.825,2.049,32478
"Fish, salmon, pink, cooked, dry heat",Finfish and Shellfish Products,24.58,0.008,0.09,0,0,0.439,0,0,5.28,70.65,153,0.971,1.617,0.973,32479
"Fish, salmon, pink, raw",Finfish and Shellfish Products,20.5,0.007,0.075,0,0,0.366,0,0,4.4,75.52,127,0.81,1.348,0.811,32480
"Fish, salmon, sockeye, canned, drained solids with bone",Finfish and Shellfish Products,23.33,0.221,0.36,0,0,0.288,0,0,7.31,67.51,166,1.567,2.026,1.865,32481
"Fish, salmon, sockeye, canned, without salt, drained solids with bone",Finfish and Shellfish Products,20.47,0.239,0.075,0,0,0.377,0,0,7.31,68.72,153,1.644,2.776,2.277,32482
"Fish, salmon, sockeye, cooked, dry heat",Finfish and Shellfish Products,25.4,0.012,0.134,0,0,0.408,0,0,6.69,68,169,0.909,1.432,1.495,32483
"Fish, salmon, sockeye, raw",Finfish and Shellfish Products,21.31,0.01,0.112,0,0,0.343,0,0,5.61,73.15,142,0.763,1.202,1.255,32484
"Fish, sardine, Atlantic, canned in oil, drained solids with bone",Finfish and Shellfish Products,24.62,0.382,0.505,0,0,0.397,0,0,11.45,59.61,208,1.528,3.869,5.148,32485
"Fish, sardine, Pacific, canned in tomato sauce, drained solids with bone",Finfish and Shellfish Products,20.86,0.24,0.414,0.1,0.001,0.341,0.54,0.43,10.45,66.86,185,2.684,4.818,2.111,32486
"Fish, scup, cooked, dry heat",Finfish and Shellfish Products,24.21,0.051,0.054,0,0,0.368,0,0,3.5,68.42,135,0,0,0,32487
"Fish, scup, raw",Finfish and Shellfish Products,18.88,0.04,0.042,0,0,0.287,0,0,2.73,75.37,105,0.64,0.56,1.03,32488
"Fish, sea bass, mixed species, cooked, dry heat",Finfish and Shellfish Products,23.63,0.013,0.087,0,0,0.328,0,0,2.56,72.14,124,0.655,0.544,0.953,32489
"Fish, sea bass, mixed species, raw",Finfish and Shellfish Products,18.43,0.01,0.068,0,0,0.256,0,0,2,78.27,97,0.511,0.424,0.743,32490
"Fish, seatrout, mixed species, cooked, dry heat",Finfish and Shellfish Products,21.46,0.022,0.074,0,0,0.437,0,0,4.63,71.91,133,1.293,1.133,0.929,32491
"Fish, seatrout, mixed species, raw",Finfish and Shellfish Products,16.74,0.017,0.058,0,0,0.341,0,0,3.61,78.09,104,1.009,0.884,0.725,32492
"Fish, shad, american, cooked, dry heat",Finfish and Shellfish Products,21.71,0.06,0.065,0,0,0.492,0,0,17.65,59.22,252,0,0,0,32493
"Fish, shad, american, raw",Finfish and Shellfish Products,16.93,0.047,0.051,0,0,0.384,0,0,13.77,68.19,197,3.126,5.724,3.268,32494
"Fish, shark, mixed species, cooked, batter-dipped and fried",Finfish and Shellfish Products,18.62,0.05,0.122,0,0,0.155,6.39,0,13.82,60.09,228,3.205,5.935,3.701,32495
"Fish, shark, mixed species, raw",Finfish and Shellfish Products,20.98,0.034,0.079,0,0,0.16,0,0,4.51,73.58,130,0.925,1.808,1.195,32496
"Fish, sheepshead, cooked, dry heat",Finfish and Shellfish Products,26.02,0.037,0.073,0,0,0.512,0,0,1.63,69.04,126,0.36,0.35,0.382,32497
"Fish, sheepshead, raw",Finfish and Shellfish Products,20.21,0.021,0.071,0,0,0.404,0,0,2.41,77.97,108,0.609,0.728,0.518,32498
"Fish, smelt, rainbow, cooked, dry heat",Finfish and Shellfish Products,22.6,0.077,0.077,0,0,0.372,0,0,3.1,72.79,124,0.579,0.822,1.135,32499
"Fish, smelt, rainbow, raw",Finfish and Shellfish Products,17.63,0.06,0.06,0,0,0.29,0,0,2.42,78.77,97,0.452,0.641,0.885,32500
"Fish, snapper, mixed species, cooked, dry heat",Finfish and Shellfish Products,26.3,0.04,0.057,0,0.0016,0.522,0,0,1.72,70.35,128,0.365,0.322,0.588,32501
"Fish, snapper, mixed species, raw",Finfish and Shellfish Products,20.51,0.032,0.064,0,0.0016,0.417,0,0,1.34,76.87,100,0.285,0.251,0.459,32502
"Fish, spot, cooked, dry heat",Finfish and Shellfish Products,23.73,0.018,0.037,0,0,0.636,0,0,6.28,69.17,158,1.859,1.706,1.398,32503
"Fish, spot, raw",Finfish and Shellfish Products,18.51,0.014,0.029,0,0,0.496,0,0,4.9,75.95,123,1.45,1.33,1.09,32504
"Fish, sturgeon, mixed species, cooked, dry heat",Finfish and Shellfish Products,20.7,0.017,0.069,0,0,0.364,0,0,5.18,69.94,135,1.173,2.486,0.885,32505
"Fish, sturgeon, mixed species, raw",Finfish and Shellfish Products,16.14,0.013,0.054,0,0,0.284,0,0,4.04,76.55,105,0.915,1.939,0.69,32506
"Fish, sturgeon, mixed species, smoked",Finfish and Shellfish Products,31.2,0.017,0.739,0,0,0.379,0,0,4.4,62.5,173,1.037,2.356,0.436,32507
"Fish, sucker, white, cooked, dry heat",Finfish and Shellfish Products,21.49,0.09,0.051,0,0,0.487,0,0,2.97,73.99,119,0.579,0.905,1.035,32508
"Fish, sucker, white, raw",Finfish and Shellfish Products,16.76,0.07,0.04,0,0,0.38,0,0,2.32,79.71,92,0.452,0.706,0.807,32509
"Fish, sunfish, pumpkin seed, cooked, dry heat",Finfish and Shellfish Products,24.87,0.103,0.103,0,0.001,0.449,0,0,0.9,73.72,114,0.178,0.15,0.315,32510
"Fish, sunfish, pumpkin seed, raw",Finfish and Shellfish Products,19.4,0.08,0.08,0,0.001,0.35,0,0,0.7,79.5,89,0.139,0.117,0.246,32511
"Fish, surimi",Finfish and Shellfish Products,15.18,0.009,0.143,0,0,0.112,6.85,0,0.9,76.34,99,0.191,0.149,0.443,32512
"Fish, swordfish, cooked, dry heat",Finfish and Shellfish Products,23.45,0.006,0.097,0,0,0.499,0,0,7.93,68.26,172,1.911,3.544,1.368,32513
"Fish, swordfish, raw",Finfish and Shellfish Products,19.66,0.005,0.081,0,0,0.418,0,0,6.65,73.38,144,1.602,2.971,1.147,32514
"Fish, tilapia, cooked, dry heat",Finfish and Shellfish Products,26.15,0.014,0.056,0,0,0.38,0,0,2.65,71.59,128,0.94,0.955,0.6,32515
"Fish, tilapia, raw",Finfish and Shellfish Products,20.08,0.01,0.052,0,0,0.302,0,0,1.7,78.08,96,0.585,0.498,0.363,32516
"Fish, tilefish, cooked, dry heat",Finfish and Shellfish Products,24.49,0.026,0.059,0,0,0.512,0,0,4.69,70.24,147,0.868,1.332,1.246,32517
"Fish, tilefish, raw",Finfish and Shellfish Products,17.5,0.026,0.053,0,0,0.433,0,0,2.31,78.9,96,0.441,0.571,0.599,32518
"Fish, trout, mixed species, cooked, dry heat",Finfish and Shellfish Products,26.63,0.055,0.067,0,0.0005,0.463,0,0,8.47,63.36,190,1.474,4.172,1.922,32519
"Fish, trout, mixed species, raw",Finfish and Shellfish Products,20.77,0.043,0.052,0,0.0005,0.361,0,0,6.61,71.42,148,1.149,3.254,1.499,32520
"Fish, trout, rainbow, farmed, cooked, dry heat",Finfish and Shellfish Products,23.8,0.03,0.061,0,0.0029,0.45,0,0,7.38,68.72,168,1.651,2.363,1.799,32521
"Fish, trout, rainbow, farmed, raw",Finfish and Shellfish Products,19.94,0.025,0.051,0,0.0029,0.377,0,0,6.18,73.8,141,1.383,1.979,1.507,32522
"Fish, trout, rainbow, wild, cooked, dry heat",Finfish and Shellfish Products,22.92,0.086,0.056,0,0.002,0.448,0,0,5.82,70.5,150,1.619,1.746,1.831,32523
"Fish, trout, rainbow, wild, raw",Finfish and Shellfish Products,20.48,0.067,0.031,0,0.0024,0.481,0,0,3.46,71.87,119,0.722,1.129,1.237,32524
"Fish, tuna salad",Finfish and Shellfish Products,16.04,0.017,0.402,0,0.0022,0.178,9.41,0,9.26,63.16,187,1.544,2.887,4.122,32525
"Fish, tuna, fresh, bluefin, cooked, dry heat",Finfish and Shellfish Products,29.91,0.01,0.05,0,0,0.323,0,0,6.28,59.09,184,1.612,2.053,1.844,32526
"Fish, tuna, fresh, bluefin, raw",Finfish and Shellfish Products,23.33,0.008,0.039,0,0,0.252,0,0,4.9,68.09,144,1.257,1.6,1.433,32527
"Fish, tuna, fresh, skipjack, raw",Finfish and Shellfish Products,22,0.029,0.037,0,0.001,0.407,0,0,1.01,70.58,103,0.328,0.19,0.315,32528
"Fish, tuna, fresh, yellowfin, raw",Finfish and Shellfish Products,24.4,0.004,0.045,0,0,0.441,0,0,0.49,74.03,109,0.172,0.116,0.147,32529
"Fish, tuna, light, canned in oil, drained solids",Finfish and Shellfish Products,29.13,0.013,0.354,0,0,0.207,0,0,8.21,59.83,198,1.534,2.949,2.885,32530
"Fish, tuna, light, canned in oil, without salt, drained solids",Finfish and Shellfish Products,29.13,0.013,0.05,0,0,0.207,0,0,8.21,59.83,198,1.534,2.949,2.885,32531
"Fish, tuna, light, canned in water, drained solids",Finfish and Shellfish Products,25.51,0.011,0.338,0,0,0.237,0,0,0.82,74.51,116,0.234,0.159,0.337,32532
"Fish, tuna, light, canned in water, without salt, drained solids",Finfish and Shellfish Products,25.51,0.011,0.05,0,0,0.237,0,0,0.82,75.21,116,0.234,0.159,0.337,32533
"Fish, tuna, skipjack, fresh, cooked, dry heat",Finfish and Shellfish Products,28.21,0.037,0.047,0,0.001,0.522,0,0,1.29,62.28,132,0.42,0.243,0.403,32534
"Fish, tuna, white, canned in oil, drained solids",Finfish and Shellfish Products,26.53,0.004,0.396,0,0,0.333,0,0,8.08,64.02,186,1.28,3.262,2.972,32535
"Fish, tuna, white, canned in oil, without salt, drained solids",Finfish and Shellfish Products,26.53,0.004,0.05,0,0,0.333,0,0,8.08,64.02,186,1.65,2.48,3.38,32536
"Fish, tuna, white, canned in water, drained solids",Finfish and Shellfish Products,23.62,0.014,0.377,0,0,0.237,0,0,2.97,73.19,128,0.792,0.784,1.109,32537
"Fish, tuna, white, canned in water, without salt, drained solids",Finfish and Shellfish Products,23.62,0.014,0.05,0,0,0.237,0,0,2.97,74.02,128,0.792,0.784,1.109,32538
"Fish, tuna, yellowfin, fresh, cooked, dry heat",Finfish and Shellfish Products,29.15,0.004,0.054,0,0,0.527,0,0,0.59,68.98,130,0.205,0.138,0.175,32539
"Fish, turbot, european, cooked, dry heat",Finfish and Shellfish Products,20.58,0.023,0.192,0,0.0017,0.305,0,0,3.78,70.45,122,0,0,0,32540
"Fish, turbot, european, raw",Finfish and Shellfish Products,16.05,0.018,0.15,0,0.0017,0.238,0,0,2.95,76.95,95,0.75,0.61,0.88,32541
"Fish, whitefish, mixed species, cooked, dry heat",Finfish and Shellfish Products,24.47,0.033,0.065,0,0,0.406,0,0,7.51,65.09,172,1.162,2.559,2.755,32542
"Fish, whitefish, mixed species, raw",Finfish and Shellfish Products,19.09,0.026,0.051,0,0,0.317,0,0,5.86,72.77,134,0.906,1.996,2.149,32543
"Fish, whitefish, mixed species, smoked",Finfish and Shellfish Products,23.4,0.018,1.019,0,0,0.423,0,0,0.93,70.83,108,0.228,0.278,0.293,32544
"Fish, whiting, mixed species, cooked, dry heat",Finfish and Shellfish Products,23.48,0.062,0.132,0,0,0.434,0,0,1.69,74.71,116,0.4,0.445,0.587,32545
"Fish, whiting, mixed species, raw",Finfish and Shellfish Products,18.31,0.048,0.072,0,0,0.249,0,0,1.31,80.27,90,0.247,0.279,0.422,32546
"Fish, wolffish, Atlantic, cooked, dry heat",Finfish and Shellfish Products,22.44,0.008,0.109,0,0,0.385,0,0,3.06,74.23,123,0.468,1.072,1.083,32547
"Fish, wolffish, Atlantic, raw",Finfish and Shellfish Products,17.5,0.006,0.085,0,0,0.3,0,0,2.39,79.9,96,0.365,0.837,0.845,32548
"Fish, yellowtail, mixed species, cooked, dry heat",Finfish and Shellfish Products,29.67,0.029,0.05,0,0.0029,0.538,0,0,6.72,67.33,187,0,0,0,32549
"Fish, yellowtail, mixed species, raw",Finfish and Shellfish Products,23.14,0.023,0.039,0,0.0028,0.42,0,0,5.24,74.52,146,1.28,1.99,1.42,32550
"Frog legs, raw",Finfish and Shellfish Products,16.4,0.018,0.058,0,0,0.285,0,0,0.3,81.9,73,0.076,0.053,0.102,32551
"Jellyfish, dried, salted",Finfish and Shellfish Products,5.5,0.002,9.69,0,0,0.003,0,0,1.4,68,36,0.273,0.202,0.475,32552
"Mollusks, abalone, mixed species, cooked, fried",Finfish and Shellfish Products,19.63,0.037,0.591,0,0.0018,0.284,11.05,0,6.78,60.1,189,1.646,2.741,1.676,32553
"Mollusks, abalone, mixed species, raw",Finfish and Shellfish Products,17.1,0.031,0.301,0,0.002,0.25,6.01,0,0.76,74.56,105,0.149,0.107,0.104,32554
"Mollusks, clam, mixed species, canned, drained solids",Finfish and Shellfish Products,24.25,0.065,0.112,0,0,0.628,5.9,0,1.59,65.26,142,0.309,0.198,0.318,32555
"Mollusks, clam, mixed species, canned, liquid",Finfish and Shellfish Products,0.4,0.013,0.215,0,0.001,0.149,0.1,0,0.02,97.7,2,0.002,0.002,0.006,32556
"Mollusks, clam, mixed species, cooked, breaded and fried",Finfish and Shellfish Products,14.24,0.063,0.364,0,0.01,0.326,10.33,0,11.15,61.55,202,2.683,4.545,2.87,32557
"Mollusks, clam, mixed species, cooked, moist heat",Finfish and Shellfish Products,25.55,0.092,0.112,0,0.0221,0.628,5.13,0,1.95,63.64,148,0.188,0.172,0.552,32558
"Mollusks, clam, mixed species, raw",Finfish and Shellfish Products,14.67,0.039,0.601,0,0,0.046,3.57,0,0.96,78.98,86,0.187,0.12,0.192,32559
"Mollusks, conch, baked or broiled",Finfish and Shellfish Products,26.3,0.098,0.153,0,0,0.163,1.7,0,1.2,69.4,130,0.37,0.334,0.274,32560
"Mollusks, cuttlefish, mixed species, cooked, moist heat",Finfish and Shellfish Products,32.48,0.18,0.744,0,0.0085,0.637,1.64,0,1.4,61.12,158,0.236,0.162,0.268,32561
"Mollusks, cuttlefish, mixed species, raw",Finfish and Shellfish Products,16.24,0.09,0.372,0,0.0053,0.354,0.82,0,0.7,80.56,79,0.118,0.081,0.134,32562
"Mollusks, mussel, blue, cooked, moist heat",Finfish and Shellfish Products,23.8,0.033,0.369,0,0.0136,0.268,7.39,0,4.48,61.15,172,0.85,1.014,1.212,32563
"Mollusks, mussel, blue, raw",Finfish and Shellfish Products,11.9,0.026,0.286,0,0.008,0.32,3.69,0,2.24,80.58,86,0.425,0.507,0.606,32564
"Mollusks, octopus, common, cooked, moist heat",Finfish and Shellfish Products,29.82,0.106,0.46,0,0.008,0.63,4.4,0,2.08,60.5,164,0.453,0.324,0.477,32565
"Mollusks, octopus, common, raw",Finfish and Shellfish Products,14.91,0.053,0.23,0,0.005,0.35,2.2,0,1.04,80.25,82,0.227,0.162,0.239,32566
"Mollusks, oyster, Pacific, cooked, moist heat",Finfish and Shellfish Products,18.9,0.016,0.212,0,0.0128,0.302,9.9,0,4.6,64.12,163,1.02,0.776,1.788,32567
"Mollusks, oyster, Pacific, raw",Finfish and Shellfish Products,9.45,0.008,0.106,0,0.008,0.168,4.95,0,2.3,82.06,81,0.51,0.358,0.894,32568
"Mollusks, oyster, eastern, canned",Finfish and Shellfish Products,7.06,0.045,0.112,0,0.005,0.229,3.91,0,2.47,85.14,68,0.631,0.25,0.739,32569
"Mollusks, oyster, eastern, cooked, breaded and fried",Finfish and Shellfish Products,8.77,0.062,0.417,0,0.0038,0.244,11.62,0,12.58,64.72,199,3.197,4.702,3.313,32570
"Mollusks, oyster, eastern, farmed, cooked, dry heat",Finfish and Shellfish Products,7,0.056,0.163,0,0.006,0.152,7.28,0,2.12,81.95,79,0.683,0.23,0.713,32571
"Mollusks, oyster, eastern, farmed, raw",Finfish and Shellfish Products,5.22,0.044,0.178,0,0.0047,0.124,5.53,0,1.55,86.2,59,0.443,0.152,0.591,32572
"Mollusks, oyster, eastern, wild, cooked, dry heat",Finfish and Shellfish Products,8.87,0.092,0.132,0,0,0.242,4.23,0.96,2.65,82.98,79,0.736,0.393,0.82,32573
"Mollusks, oyster, eastern, wild, cooked, moist heat",Finfish and Shellfish Products,11.42,0.116,0.166,0,0,0.139,5.45,1.23,3.42,78.19,102,0.948,0.506,1.056,32574
"Mollusks, oyster, eastern, wild, raw",Finfish and Shellfish Products,5.71,0.059,0.085,0,0,0.156,2.72,0.62,1.71,89.04,51,0.474,0.253,0.528,32575
"Mollusks, scallop, (bay and sea), cooked, steamed",Finfish and Shellfish Products,20.54,0.01,0.667,0,0,0.314,5.41,0,0.84,70.25,111,0.218,0.082,0.222,32576
"Mollusks, scallop, mixed species, cooked, breaded and fried",Finfish and Shellfish Products,18.07,0.042,0.464,0,0.0023,0.333,10.13,0,10.94,58.44,216,2.669,4.498,2.856,32577
"Mollusks, scallop, mixed species, imitation, made from surimi",Finfish and Shellfish Products,12.77,0.008,0.795,0,0,0.103,10.62,0,0.41,73.82,99,0.08,0.06,0.21,32578
"Mollusks, scallop, mixed species, raw",Finfish and Shellfish Products,12.06,0.006,0.392,0,0,0.205,3.18,0,0.49,82.53,69,0.128,0.048,0.13,32579
"Mollusks, snail, raw",Finfish and Shellfish Products,16.1,0.01,0.07,0,0,0.382,2,0,1.4,79.2,90,0.361,0.259,0.252,32580
"Mollusks, squid, mixed species, cooked, fried",Finfish and Shellfish Products,17.94,0.039,0.306,0,0.004200000000000001,0.279,7.79,0,7.48,64.54,175,1.878,2.749,2.136,32581
"Mollusks, squid, mixed species, raw",Finfish and Shellfish Products,15.58,0.032,0.044,0,0.0047,0.246,3.08,0,1.38,78.55,92,0.358,0.107,0.524,32582
"Mollusks, whelk, unspecified, cooked, moist heat",Finfish and Shellfish Products,47.68,0.113,0.412,0,0.0068,0.694,15.52,0,0.8,32,275,0.062,0.056,0.046,32583
"Mollusks, whelk, unspecified, raw",Finfish and Shellfish Products,23.84,0.057,0.206,0,0.004,0.347,7.76,0,0.4,66,137,0.031,0.028,0.023,32584
"Turtle, green, raw",Finfish and Shellfish Products,19.8,0.118,0.068,0,0,0.23,0,0,0.5,78.5,89,0.127,0.088,0.17,32585
"USDA Commodity, salmon nuggets, breaded, frozen, heated",Finfish and Shellfish Products,12.69,0.008,0.173,0,0,0.165,13.96,0,11.72,60.38,212,1.57,4.625,3.028,32586
"USDA Commodity, salmon nuggets, cooked as purchased, unheated",Finfish and Shellfish Products,11.97,0.009,0.167,0,0,0.161,11.85,0,10.43,64.57,189,1.497,4.33,2.863,32587
"Bacon bits, meatless",Legumes and Legume Products,32,0.101,1.77,10.2,0.0019,0.145,28.6,0,25.9,8,476,4.055,6.225,13.548,32588
"Bacon, meatless",Legumes and Legume Products,10.68,0.023,1.465,2.6,0,0.17,6.32,0,29.52,48.98,310,4.622,7.095,15.441,32589
"Baked beans, canned, no salt added",Legumes and Legume Products,4.8,0.05,0.001,5.5,0.0031,0.296,20.49,7.78,0.4,72.6,105,0.103,0.035,0.172,32590
Bean beverage,Legumes and Legume Products,2.8,0.017,0.002,0,0,0.337,5.8,0.08,0,90.9,34,0,0,0,32591
"Beans, adzuki, mature seed, cooked, boiled, with salt",Legumes and Legume Products,7.52,0.028,0.244,7.3,0,0.532,24.77,0,0.1,66.29,128,0.036,0,0,32592
"Beans, adzuki, mature seeds, canned, sweetened",Legumes and Legume Products,3.8,0.022,0.218,0,0,0.119,55.01,0,0.03,40.58,237,0.011,0,0,32593
"Beans, adzuki, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,7.52,0.028,0.008,7.3,0,0.532,24.77,0,0.1,66.29,128,0.036,0,0,32594
"Beans, adzuki, mature seeds, raw",Legumes and Legume Products,19.87,0.066,0.005,12.7,0,1.254,62.9,0,0.53,13.44,329,0.191,0,0,32595
"Beans, adzuki, yokan, mature seeds",Legumes and Legume Products,3.29,0.027,0.083,0,0,0.045,60.72,0,0.12,35.45,260,0.043,0,0,32596
"Beans, baked, canned, plain or vegetarian",Legumes and Legume Products,4.75,0.034,0.343,4.1,0,0.224,21.14,7.96,0.37,72,94,0.071,0.095,0.121,32597
"Beans, baked, canned, with beef",Legumes and Legume Products,6.38,0.045,0.475,0,0.0018,0.32,16.91,0,3.45,71.33,121,1.677,1.385,0.205,32598
"Beans, baked, canned, with franks",Legumes and Legume Products,6.75,0.048,0.43,6.9,0.0023,0.235,15.39,6.53,6.57,69.34,142,2.352,2.83,0.836,32599
"Beans, baked, canned, with pork",Legumes and Legume Products,5.19,0.053,0.414,5.5,0.002,0.309,19.99,0,1.55,71.46,106,0.599,0.673,0.199,32600
"Beans, baked, canned, with pork and sweet sauce",Legumes and Legume Products,5.29,0.059,0.334,4.2,0.0028,0.258,21.12,8.56,1.44,70.61,112,0.491,0.498,0.376,32601
"Beans, baked, canned, with pork and tomato sauce",Legumes and Legume Products,5.15,0.056,0.437,4,0.003,0.295,18.69,5.67,0.93,73.5,94,0.293,0.277,0.157,32602
"Beans, baked, home prepared",Legumes and Legume Products,5.54,0.061,0.422,5.5,0.0011,0.358,21.63,0,5.15,65.17,155,1.948,2.133,0.74,32603
"Beans, black turtle soup, mature seeds, canned",Legumes and Legume Products,6.03,0.035,0.384,6.9,0.0027,0.308,16.56,0,0.29,75.64,91,0.075,0.025,0.125,32604
"Beans, black turtle soup, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,8.18,0.055,0.239,5.3,0,0.433,24.35,0.32,0.35,65.74,130,0.089,0.03,0.149,32605
"Beans, black turtle soup, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,8.18,0.055,0.003,5.3,0,0.433,24.35,0.32,0.35,65.74,130,0.089,0.03,0.149,32606
"Beans, black turtle soup, mature seeds, raw",Legumes and Legume Products,21.25,0.16,0.009,24.9,0,1.5,63.25,2.25,0.9,11,339,0.232,0.078,0.387,32607
"Beans, black, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,8.86,0.027,0.237,8.7,0,0.355,23.71,0,0.54,65.74,132,0.139,0.047,0.231,32608
"Beans, black, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,8.86,0.027,0.001,8.7,0,0.355,23.71,0,0.54,65.74,132,0.139,0.047,0.231,32609
"Beans, black, mature seeds, raw",Legumes and Legume Products,21.6,0.123,0.005,15.2,0,1.483,62.36,2.12,1.42,11.02,341,0.366,0.123,0.61,32610
"Beans, chili, barbecue, ranch style, cooked",Legumes and Legume Products,5,0.031,0.725,4.2,0.0017,0.45,16.9,5.25,1,75.5,97,0.145,0.077,0.555,32611
"Beans, cranberry (roman), mature seeds, canned",Legumes and Legume Products,5.54,0.034,0.332,6.3,0.0008,0.26,15.12,0,0.28,77.56,83,0.072,0.024,0.12,32612
"Beans, cranberry (roman), mature seeds, cooked, boiled, with salt",Legumes and Legume Products,9.34,0.05,0.237,10,0,0.387,24.46,0,0.46,64.65,136,0.119,0.04,0.199,32613
"Beans, cranberry (roman), mature seeds, cooked, boiled, without salt",Legumes and Legume Products,9.34,0.05,0.001,10,0,0.387,24.46,0,0.46,64.65,136,0.119,0.04,0.199,32614
"Beans, cranberry (roman), mature seeds, raw",Legumes and Legume Products,23.03,0.127,0.006,24.7,0,1.332,60.05,0,1.23,12.39,335,0.316,0.106,0.527,32615
"Beans, french, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,7.05,0.063,0.242,9.4,0.0012,0.37,24.02,0,0.76,66.57,129,0.083,0.052,0.453,32616
"Beans, french, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,7.05,0.063,0.006,9.4,0.0012,0.37,24.02,0,0.76,66.57,129,0.083,0.052,0.453,32617
"Beans, french, mature seeds, raw",Legumes and Legume Products,18.81,0.186,0.018,25.2,0.0046,1.316,64.11,0,2.02,10.77,343,0.221,0.138,1.207,32618
"Beans, great northern, mature seeds, canned",Legumes and Legume Products,7.37,0.053,0.004,4.9,0.0013,0.351,21.02,0,0.39,69.89,114,0.12,0.018,0.161,32619
"Beans, great northern, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,8.33,0.068,0.238,7,0.0013,0.391,21.09,0,0.45,69,118,0.14,0.021,0.187,32620
"Beans, great northern, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,8.33,0.068,0.002,7,0.0013,0.391,21.09,0,0.45,69,118,0.14,0.021,0.187,32621
"Beans, great northern, mature seeds, raw",Legumes and Legume Products,21.86,0.175,0.014,20.2,0.0053,1.387,62.37,2.26,1.14,10.7,339,0.356,0.053,0.477,32622
"Beans, kidney, all types, mature seeds, canned",Legumes and Legume Products,5.22,0.034,0.296,5.3,0.0012,0.237,14.5,1.85,0.6,78.04,84,0.141,0.382,0.263,32623
"Beans, kidney, all types, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,8.67,0.028,0.238,6.4,0.0012,0.403,22.8,0.32,0.5,66.94,127,0.072,0.039,0.275,32624
"Beans, kidney, all types, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,8.67,0.035,0.001,6.4,0.0012,0.405,22.8,0.32,0.5,66.94,127,0.073,0.039,0.278,32625
"Beans, kidney, all types, mature seeds, raw",Legumes and Legume Products,23.58,0.143,0.024,24.9,0.0045,1.406,60.01,2.23,0.83,11.75,333,0.12,0.064,0.457,32626
"Beans, kidney, california red, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,9.13,0.066,0.24,9.3,0.0012,0.419,22.41,0,0.09,66.93,124,0.014,0.007,0.052,32627
"Beans, kidney, california red, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,9.13,0.066,0.004,9.3,0.0012,0.419,22.41,0,0.09,66.93,124,0.014,0.007,0.052,32628
"Beans, kidney, california red, mature seeds, raw",Legumes and Legume Products,24.37,0.195,0.011,24.9,0.0045,1.49,59.8,0,0.25,11.75,330,0.036,0.019,0.138,32629
"Beans, kidney, red, mature seeds, canned",Legumes and Legume Products,5.26,0.025,0.258,5.4,0.0011,0.256,15.54,1.86,0.36,77.37,84,0.085,0.171,0.151,32630
"Beans, kidney, red, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,8.67,0.028,0.238,7.4,0.0012,0.403,22.8,0.32,0.5,66.94,127,0.072,0.039,0.275,32631
"Beans, kidney, red, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,8.67,0.028,0.002,7.4,0.0012,0.403,22.8,0.32,0.5,66.94,127,0.072,0.039,0.275,32632
"Beans, kidney, red, mature seeds, raw",Legumes and Legume Products,22.53,0.083,0.012,15.2,0.0045,1.359,61.29,2.1,1.06,11.75,337,0.154,0.082,0.586,32633
"Beans, kidney, royal red, mature seeds, cooked, boiled with salt",Legumes and Legume Products,9.49,0.044,0.241,9.3,0.0012,0.378,21.85,0,0.17,66.99,123,0.024,0.013,0.093,32634
"Beans, kidney, royal red, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,9.49,0.044,0.005,9.3,0.0012,0.378,21.85,0,0.17,66.99,123,0.024,0.013,0.093,32635
"Beans, kidney, royal red, mature seeds, raw",Legumes and Legume Products,25.33,0.131,0.013,24.9,0.0045,1.346,58.33,0,0.45,11.9,329,0.065,0.035,0.249,32636
"Beans, liquid from stewed kidney beans",Legumes and Legume Products,1.8,0.013,0.002,0.1,0,0.408,2.8,0,3.2,91.5,47,1.174,1.536,0.34,32637
"Beans, navy, mature seeds, canned",Legumes and Legume Products,7.53,0.047,0.448,5.1,0.0007,0.288,20.45,0.28,0.43,70.45,113,0.112,0.038,0.186,32638
"Beans, navy, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,8.23,0.069,0.237,10.5,0,0.389,26.05,0.37,0.62,63.81,140,0.098,0.142,0.49,32639
"Beans, navy, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,8.23,0.069,0,10.5,0.0009,0.389,26.05,0.37,0.62,63.81,140,0.098,0.142,0.49,32640
"Beans, navy, mature seeds, raw",Legumes and Legume Products,22.33,0.147,0.005,24.4,0,1.185,60.75,3.88,1.5,12.1,337,0.17,0.245,1.411,32641
"Beans, pink, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,9.06,0.052,0.238,5.3,0,0.508,27.91,0.36,0.49,61.2,149,0.126,0.042,0.21,32642
"Beans, pink, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,9.06,0.052,0.002,5.3,0,0.508,27.91,0.36,0.49,61.2,149,0.126,0.042,0.21,32643
"Beans, pink, mature seeds, raw",Legumes and Legume Products,20.96,0.13,0.008,12.7,0,1.464,64.19,2.14,1.13,10.06,343,0.292,0.098,0.487,32644
"Beans, pinto, mature seeds, canned",Legumes and Legume Products,4.86,0.043,0.294,4.6,0.0009,0.243,15.25,0.21,0.81,77.54,86,0.167,0.162,0.289,32645
"Beans, pinto, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,9.01,0.046,0.238,9,0.0008,0.436,26.22,0.34,0.65,62.95,143,0.109,0.106,0.188,32646
"Beans, pinto, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,9.01,0.046,0.001,9,0.0008,0.436,26.22,0.34,0.65,62.95,143,0.136,0.133,0.235,32647
"Beans, pinto, mature seeds, raw",Legumes and Legume Products,21.42,0.113,0.012,15.5,0.0063,1.393,62.55,2.11,1.23,11.33,347,0.235,0.229,0.407,32648
"Beans, small white, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,8.97,0.073,0.238,10.4,0,0.463,25.81,0,0.64,63.24,142,0.166,0.056,0.276,32649
"Beans, small white, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,8.97,0.073,0.002,10.4,0,0.463,25.81,0,0.64,63.24,142,0.166,0.056,0.276,32650
"Beans, small white, mature seeds, raw",Legumes and Legume Products,21.11,0.173,0.012,24.9,0,1.542,62.25,0,1.18,11.71,336,0.304,0.102,0.507,32651
"Beans, white, mature seeds, canned",Legumes and Legume Products,7.26,0.073,0.005,4.8,0,0.454,21.2,0.29,0.29,70.1,114,0.076,0.026,0.126,32652
"Beans, white, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,9.73,0.09,0.242,6.3,0,0.561,25.09,0.34,0.35,63.08,139,0.091,0.031,0.152,32653
"Beans, white, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,9.73,0.09,0.006,6.3,0,0.561,25.09,0.34,0.35,63.08,139,0.091,0.031,0.152,32654
"Beans, white, mature seeds, raw",Legumes and Legume Products,23.36,0.24,0.016,15.2,0,1.795,60.27,2.11,0.85,11.32,333,0.219,0.074,0.364,32655
"Beans, yellow, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,9.16,0.062,0.241,10.4,0.0018,0.325,25.27,0,1.08,62.98,144,0.279,0.094,0.466,32656
"Beans, yellow, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,9.16,0.062,0.005,10.4,0.0018,0.325,25.27,0,1.08,62.98,144,0.279,0.094,0.466,32657
"Beans, yellow, mature seeds, raw",Legumes and Legume Products,22,0.166,0.012,25.1,0,1.042,60.7,0,2.6,11.1,345,0.671,0.226,1.118,32658
"Broadbeans (fava beans), mature seeds, canned",Legumes and Legume Products,5.47,0.026,0.453,3.7,0.0018,0.242,12.41,0,0.22,80.32,71,0.037,0.044,0.09,32659
"Broadbeans (fava beans), mature seeds, cooked, boiled, with salt",Legumes and Legume Products,7.6,0.036,0.241,5.4,0.0003,0.268,19.65,1.82,0.4,71.54,110,0.066,0.079,0.164,32660
"Broadbeans (fava beans), mature seeds, cooked, boiled, without salt",Legumes and Legume Products,7.6,0.036,0.005,5.4,0.0003,0.268,19.65,1.82,0.4,71.54,110,0.066,0.079,0.164,32661
"Broadbeans (fava beans), mature seeds, raw",Legumes and Legume Products,26.12,0.103,0.013,25,0.0014,1.062,58.29,5.7,1.53,10.98,341,0.254,0.303,0.627,32662
"CAMPBELL Soup Company, Campbell's Brown Sugar And Bacon Flavored Baked Beans",Legumes and Legume Products,3.85,0.031,0.362,6.2,0,0,23.08,10,1.92,69.4,123,0.385,0,0,32663
"CAMPBELL Soup Company, Campbell's Pork and Beans",Legumes and Legume Products,4.62,0.031,0.338,5.4,0,0,19.23,6.15,1.15,73.7,108,0.385,0,0,32664
"CAMPBELL Soup Company, PACE, Salsa Refried Beans",Legumes and Legume Products,3.33,0.033,0.492,3.3,0,0,11.67,3.33,0,82.2,60,0,0,0,32665
"CAMPBELL Soup Company, PACE, Spicy Jalapeno Refried Beans",Legumes and Legume Products,4.17,0.033,0.492,4.2,0,0,11.67,3.33,0,82.4,63,0,0,0,32666
"CAMPBELL Soup Company, PACE, Traditional Refried Beans",Legumes and Legume Products,4.17,0.033,0.575,4.2,0,0,10.83,2.5,0,82.7,67,0,0,0,32667
Carob flour,Legumes and Legume Products,4.62,0.348,0.035,39.8,0.0002,0.827,88.88,49.08,0.65,3.58,222,0.09,0.197,0.216,32668
"Chicken, meatless",Legumes and Legume Products,23.64,0.035,0.709,3.6,0,0.054,3.64,0,12.73,58.84,224,1.82,2.727,7.273,32669
"Chicken, meatless, breaded, fried",Legumes and Legume Products,21.28,0.042,0.4,4.3,0,0.3,8.51,0,12.77,56.44,234,1.13,3.226,4.581,32670
Chickpea flour (besan),Legumes and Legume Products,22.39,0.045,0.064,10.8,0,0.846,57.82,10.85,6.69,10.28,387,0.693,1.504,2.983,32671
"Chickpeas (garbanzo beans, bengal gram), mature seeds, canned",Legumes and Legume Products,4.95,0.032,0.299,4.4,0.0038,0.172,22.61,0,1.14,69.69,119,0.118,0.257,0.51,32672
"Chickpeas (garbanzo beans, bengal gram), mature seeds, cooked, boiled, with salt",Legumes and Legume Products,8.86,0.049,0.243,7.6,0.0013,0.291,27.42,4.8,2.59,60.21,164,0.269,0.583,1.156,32673
"Chickpeas (garbanzo beans, bengal gram), mature seeds, cooked, boiled, without salt",Legumes and Legume Products,8.86,0.049,0.007,7.6,0.0013,0.291,27.42,4.8,2.59,60.21,164,0.269,0.583,1.156,32674
"Chickpeas (garbanzo beans, bengal gram), mature seeds, raw",Legumes and Legume Products,19.3,0.105,0.024,17.4,0.004,0.875,60.65,10.7,6.04,11.53,364,0.626,1.358,2.694,32675
"Chili with beans, canned",Legumes and Legume Products,5.71,0.047,0.522,4.4,0.0017,0.365,11.91,1.19,5.49,75.51,112,2.352,2.332,0.362,32676
"Cowpeas, catjang, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,8.13,0.026,0.255,3.6,0.0004,0.375,20.32,0,0.71,69.7,117,0.185,0.059,0.303,32677
"Cowpeas, catjang, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,8.13,0.026,0.019,3.6,0.0004,0.375,20.32,0,0.71,69.7,117,0.185,0.059,0.303,32678
"Cowpeas, catjang, mature seeds, raw",Legumes and Legume Products,23.85,0.085,0.058,10.7,0.0015,1.375,59.64,0,2.07,11.05,343,0.542,0.173,0.889,32679
"Cowpeas, common (blackeyes, crowder, southern), mature seeds, canned with pork",Legumes and Legume Products,2.74,0.017,0.35,3.3,0.0002,0.178,16.53,0,1.6,77.59,83,0.605,0.656,0.229,32680
"Cowpeas, common (blackeyes, crowder, southern), mature seeds, canned, plain",Legumes and Legume Products,4.74,0.02,0.299,3.3,0.0027,0.172,13.63,0,0.55,79.63,77,0.144,0.046,0.237,32681
"Cowpeas, common (blackeyes, crowder, southern), mature seeds, cooked, boiled, with salt",Legumes and Legume Products,7.73,0.024,0.24,6.5,0.0004,0.278,20.76,3.3,0.53,70.04,116,0.138,0.044,0.225,32682
"Cowpeas, common (blackeyes, crowder, southern), mature seeds, cooked, boiled, without salt",Legumes and Legume Products,7.73,0.024,0.004,6.5,0.0004,0.278,20.76,3.3,0.53,70.04,116,0.138,0.044,0.225,32683
"Cowpeas, common (blackeyes, crowder, southern), mature seeds, raw",Legumes and Legume Products,23.52,0.11,0.016,10.6,0.0015,1.112,60.03,6.9,1.26,11.95,336,0.331,0.106,0.542,32684
"ENSURE FIBER WITH FOS, liquid",Legumes and Legume Products,3.43,0.136,0.078,1.1,0.011699999999999999,0.144,16.36,4.68,2.38,76,101,0.152,0.967,0.547,32685
"Falafel, home-prepared",Legumes and Legume Products,13.31,0.054,0.294,0,0.0016,0.585,31.84,0,17.8,34.62,333,2.383,10.171,4.16,32686
"Fish sticks, meatless",Legumes and Legume Products,23,0.095,0.49,6.1,0,0.6,9,0.8,18,45,290,2.849,4.376,9.332,32687
"Frankfurter, meatless",Legumes and Legume Products,19.61,0.033,0.471,3.9,0,0.098,7.7,0,13.73,58,233,1.961,3.922,7.843,32688
"Frijoles rojos volteados (Refried beans, red, canned)",Legumes and Legume Products,5,0.033,0.375,4.7,0,0.31,15.47,0,6.93,70.71,144,0.926,1.676,4.379,32689
GARDENBURGER Breaded Chik'n Veggie Patties,Legumes and Legume Products,12.5,0.087,0.695,5,0,0.408,18.5,0.8,12.4,52.1,218,1.8,6.9,3.6,32690
"GARDENBURGER, Black Bean Chipotle Burger",Legumes and Legume Products,6.6,0.048,0.545,6.4,0.017,0.204,22.3,1.5,3.9,65.5,134,0.4,2.2,1.1,32691
"GARDENBURGER, California Burger",Legumes and Legume Products,5.1,0.048,0.564,3.6,0.016,0.204,21.5,1.5,5.2,66.2,144,0.5,3,1.5,32692
"GARDENBURGER, Flame Grilled Burger",Legumes and Legume Products,17.5,0.097,0.705,7.2,0,0.397,8.6,0.4,4.7,67.2,123,0.4,2.4,1.8,32693
"GARDENBURGER, Garden Vegan",Legumes and Legume Products,12.5,0.023,0.385,6.1,0.0003,0.102,17,0.3,1,68.3,106,0.2,0.2,0.5,32694
"GARDENBURGER, Gourmet Baja Steak",Legumes and Legume Products,4.4,0.058,0.441,6.2,0.041,0.189,22.9,2.1,3.9,67.1,123,1.1,1.8,0.8,32695
"GARDENBURGER, Gourmet Fire Dragon Steak",Legumes and Legume Products,3.1,0.041,0.363,7.6,0.027,0.153,17.9,4.3,4.9,72.6,101,1.5,1.7,1.6,32696
"GARDENBURGER, Gourmet Hula Steak",Legumes and Legume Products,3.8,0.027,0.386,6.9,0.003,0.129,30.5,8,5.4,58.9,162,3.8,0.7,0.6,32697
"GARDENBURGER, Gourmet Tuscany Steak",Legumes and Legume Products,7.5,0.091,0.479,8.3,0.044,0.148,22.8,2.4,4.9,63,138,2.4,1.5,0.7,32698
"GARDENBURGER, Herb Crusted Cutlet",Legumes and Legume Products,13.1,0.063,0.581,4.5,0,0.262,17.7,0.9,10,57,200,0.8,6,2.6,32699
"GARDENBURGER, Homestyle Classic Veggie Burger",Legumes and Legume Products,17.5,0.111,0.695,6.4,0,0.464,10.6,0.6,8.8,59.9,171,0.7,4.7,2.9,32700
"GARDENBURGER, Malibu Burger made with organic whole grains, corn and carrots",Legumes and Legume Products,5.6,0.077,0.671,5,0.018,0.249,23.1,2.4,9.3,59.3,188,0.9,5.7,2,32701
"GARDENBURGER, Original",Legumes and Legume Products,6.4,0.065,0.565,6.5,0.0056,0.156,25.3,1,4.5,61.9,145,1.4,1.7,1,32702
"GARDENBURGER, Savory Portabella Veggie Burger",Legumes and Legume Products,6.3,0.067,0.695,7.1,0.003,0.15,23.4,1.2,3.6,63.1,139,1.1,1,0.7,32703
"GARDENBURGER, Sun-Dried Tomato Basil Burger",Legumes and Legume Products,5.5,0.05,0.387,5.2,0.012,0.078,23.7,2.6,3.6,65.5,137,1,1.2,0.8,32704
"GARDENBURGER, Veggie Medley Burger",Legumes and Legume Products,4.6,0.033,0.53,7.3,0.02,0.178,24.3,1.6,3.7,65.4,135,0.4,1.5,1.6,32705
"GREEN GIANT, HARVEST BURGER, Original Flavor, All Vegetable Protein Patties, frozen",Legumes and Legume Products,20,0.113,0.457,6.3,0,0.48,7.8,0,4.6,65,153,1.13,2.372,0.295,32706
"Hummus, commercial",Legumes and Legume Products,7.9,0.038,0.379,6,0,0.228,14.29,0,9.6,66.59,166,1.437,4.039,3.613,32707
"Hummus, home prepared",Legumes and Legume Products,4.86,0.049,0.242,4,0.0079,0.173,20.12,0.27,8.59,64.87,177,1.141,4.865,2.109,32708
"Hyacinth beans, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,8.14,0.04,0.243,0,0,0.337,20.7,0,0.58,69.13,117,0.099,0,0,32709
"Hyacinth beans, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,8.14,0.04,0.007,0,0,0.337,20.7,0,0.58,69.13,117,0.099,0,0,32710
"Hyacinth beans, mature seeds, raw",Legumes and Legume Products,23.9,0.13,0.021,0,0,1.235,60.76,0,1.69,9.38,344,0.288,0,0,32711
"LOMA LINDA Big Franks, canned, unprepared",Legumes and Legume Products,21.5,0.017,0.426,3.5,0,0.116,5.8,0.4,12.1,59.1,218,1.6,3.1,7.4,32712
"LOMA LINDA Linketts, canned, unprepared",Legumes and Legume Products,21.3,0.011,0.401,3,0,0.064,4.4,0.3,11.8,61.2,209,1.6,2.9,7,32713
"LOMA LINDA Little Links, canned, unprepared",Legumes and Legume Products,19.4,0.019,0.472,4.2,0,0.056,5.5,0.8,13.5,60,221,2,3.5,7.7,32714
"LOMA LINDA Low Fat Big Franks, canned, unprepared",Legumes and Legume Products,23.1,0.017,0.481,4.1,0,0.1,4.9,0.3,4.7,65.6,154,0.5,1.1,3.1,32715
"LOMA LINDA Redi-Burger, canned, unprepared",Legumes and Legume Products,21.9,0.017,0.508,4.3,0,0.17,8.2,1.2,2.8,64.8,146,0.4,0.6,1.8,32716
"LOMA LINDA Swiss Stake with Gravy, canned, unprepared",Legumes and Legume Products,10.2,0.035,0.471,3.2,0,0.226,10.4,0.9,6.2,70.9,138,0.8,1.6,3.7,32717
"LOMA LINDA Tender Bits, canned, unprepared",Legumes and Legume Products,15.1,0.017,0.613,4.3,0,0.065,8.3,0.6,4.6,70,135,0.7,1.1,2.6,32718
"LOMA LINDA Tender Rounds with Gravy, canned, unprepared",Legumes and Legume Products,16.3,0.014,0.443,3.5,0,0.095,7.4,1,5.6,69.1,145,0.7,1.3,2.3,32719
"LOMA LINDA Vege-Burger, canned, unprepared",Legumes and Legume Products,22.2,0.016,0.222,2.6,0,0.074,3.7,0.8,1.2,72,114,0.2,0.2,0.8,32720
"Lentils, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,9.02,0.019,0.238,7.9,0.0015,0.369,19.54,1.8,0.38,69.64,114,0.053,0.064,0.175,32721
"Lentils, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,9.02,0.019,0.002,7.9,0.0015,0.369,20.13,1.8,0.38,69.64,116,0.053,0.064,0.175,32722
"Lentils, pink, raw",Legumes and Legume Products,24.95,0.041,0.007,10.8,0.0017,0.578,59.15,0,2.17,11.79,345,0.379,0.5,1.137,32723
"Lentils, raw",Legumes and Legume Products,25.8,0.056,0.006,30.5,0.0044,0.955,60.08,2.03,1.06,10.4,353,0.156,0.189,0.516,32724
"Lima beans, large, mature seeds, canned",Legumes and Legume Products,4.93,0.021,0.336,4.8,0,0.22,14.91,0,0.17,77.08,79,0.039,0.015,0.074,32725
"Lima beans, large, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,7.8,0.017,0.238,7,0,0.508,20.88,2.9,0.38,69.79,115,0.089,0.034,0.171,32726
"Lima beans, large, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,7.8,0.017,0.002,7,0,0.508,20.88,2.9,0.38,69.79,115,0.089,0.034,0.171,32727
"Lima beans, large, mature seeds, raw",Legumes and Legume Products,21.46,0.081,0.018,19,0,1.724,63.38,8.5,0.69,10.17,338,0.161,0.062,0.309,32728
"Lima beans, thin seeded (baby), mature seeds, cooked, boiled, with salt",Legumes and Legume Products,8.04,0.029,0.239,7.7,0,0.401,23.31,0,0.38,67.15,126,0.088,0.034,0.169,32729
"Lima beans, thin seeded (baby), mature seeds, cooked, boiled, without salt",Legumes and Legume Products,8.04,0.029,0.003,7.7,0,0.401,23.31,0,0.38,67.15,126,0.088,0.034,0.169,32730
"Lima beans, thin seeded (baby), mature seeds, raw",Legumes and Legume Products,20.62,0.081,0.013,20.6,0,1.403,62.83,8.32,0.93,12.07,335,0.219,0.084,0.42,32731
"Luncheon slices, meatless",Legumes and Legume Products,17.78,0.041,0.711,0,0,0.2,4.44,2.22,11.11,65.01,189,1.338,2.055,4.383,32732
"Lupins, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,15.57,0.051,0.24,2.8,0.0011,0.245,9.29,0,2.92,71.08,116,0.346,1.18,0.73,32733
"Lupins, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,15.57,0.051,0.004,2.8,0.0011,0.245,9.88,0,2.92,71.08,119,0.346,1.18,0.73,32734
"Lupins, mature seeds, raw",Legumes and Legume Products,36.17,0.176,0.015,0,0.0048,1.013,40.38,0,9.74,10.44,371,1.156,3.94,2.439,32735
"MORI-NU, Tofu, silken, extra firm",Legumes and Legume Products,7.4,0.031,0.063,0.1,0,0.154,2,0.99,1.9,88.1,55,0.3,0.345,1.061,32736
"MORI-NU, Tofu, silken, firm",Legumes and Legume Products,6.9,0.032,0.036,0.1,0,0.194,2.4,1.27,2.7,87.4,62,0.406,0.539,1.485,32737
"MORI-NU, Tofu, silken, lite extra firm",Legumes and Legume Products,7,0.043,0.098,0,0,0.057,1,0.42,0.7,90.9,38,0.116,0.118,0.395,32738
"MORI-NU, Tofu, silken, lite firm",Legumes and Legume Products,6.3,0.036,0.085,0,0,0.063,1.1,0.45,0.8,91.4,37,0.133,0.136,0.449,32739
"MORI-NU, Tofu, silken, soft",Legumes and Legume Products,4.8,0.031,0.005,0.1,0,0.18,2.9,1.31,2.7,89,55,0.357,0.522,1.55,32740
"MORNINGSTAR FARMS Asian Veggie Patties, frozen, unprepared",Legumes and Legume Products,10.9,0.051,0.725,3,0.003,0.392,14.9,4.9,6.2,64.75,155,0.8,1.7,3.7,32741
"MORNINGSTAR FARMS BBQ Riblets, frozen, unprepared",Legumes and Legume Products,13.1,0.1,0.574,4.8,0.0009,0.411,24.4,16.8,2.5,57.4,157,0.2,1.6,0.7,32742
"MORNINGSTAR FARMS Breakfast Pattie made with Organic Soy, frozen, unprepared",Legumes and Legume Products,22.1,0.045,0.632,4.3,0,0.458,10.6,1.5,8.2,55.4,205,1.1,3.3,3.8,32743
"MORNINGSTAR FARMS California Turk'y Burger, frozen, unprepared",Legumes and Legume Products,14.1,0.063,0.609,7.8,0.025,0.422,10.91,1.6,7.9,62.59,142,0.8,2.29,3.09,32744
"MORNINGSTAR FARMS Chik Patties, frozen, unprepared",Legumes and Legume Products,11.8,0.041,0.835,3,0,0.392,22.9,2,7,54,197,0.8,1.4,4.7,32745
"MORNINGSTAR FARMS Chik'n Grill Veggie Patties, frozen, unprepared",Legumes and Legume Products,12.8,0.054,0.519,5.7,0,0.32,10.2,0.2,4.8,70.8,118,0.4,2.4,1.5,32746
"MORNINGSTAR FARMS Chik'n Nuggets, frozen, unprepared",Legumes and Legume Products,14.4,0.075,0.702,4.9,0.0002,0.379,21.8,2.2,10,50.9,221,1.5,2.7,5.3,32747
"MORNINGSTAR FARMS Garden Veggie Patties, frozen, unprepared",Legumes and Legume Products,17.8,0.059,0.525,4.5,0.0011,0.21,13.7,1.8,5.6,59.9,176,0.8,1.5,3.2,32748
"MORNINGSTAR FARMS Grillers Burger Style Recipe Crumbles, frozen, unprepared",Legumes and Legume Products,17.8,0.033,0.427,4.7,0,0.189,8.3,1.1,4.3,66,140,0.5,0.9,2.87,32749
"MORNINGSTAR FARMS Grillers Original, frozen, unprepared",Legumes and Legume Products,23.9,0.061,0.422,4.4,0,0.182,8.5,1.2,9.3,55.6,213,1.7,2.6,5,32750
"MORNINGSTAR FARMS Grillers Prime, frozen, unprepared",Legumes and Legume Products,24,0.058,0.501,2.6,0,0.224,5.9,0.3,13.2,54.3,238,1.6,5.6,6,32751
"MORNINGSTAR FARMS Grillers Quarter Pound Veggie Burger, frozen, unprepared",Legumes and Legume Products,22.79,0.079,0.429,2.5,0.028,0.235,8.92,0.6,10.5,55.59,221,1.89,2,4.9,32752
"MORNINGSTAR FARMS Grillers Vegan, frozen, unprepared",Legumes and Legume Products,16.3,0.037,0.395,5.2,0,0.498,9.1,0.4,3.4,68.2,132,0.5,1.2,1.7,32753
"MORNINGSTAR FARMS Hot and Spicy Veggie Sausage Patties, frozen, unprepared",Legumes and Legume Products,21.79,0.04,0.551,2.3,0.003,0.297,8.52,0.2,7.4,59.79,188,0.89,1.7,4.09,32754
"MORNINGSTAR FARMS Italian Herb Chik Patties, frozen, unprepared",Legumes and Legume Products,13.6,0.05,0.682,3.4,0,0.483,31.1,1.5,7,47.4,236,0.8,1.3,4.85,32755
"MORNINGSTAR FARMS Lasagna with Veggie Sausage, frozen, unpreapred",Legumes and Legume Products,7.19,0.093,0.208,2.3,0.003,0.229,14.42,1.7,2.29,75,107,1,0.5,0.4,32756
"MORNINGSTAR FARMS Maple Flavored Veggie Sausage Patties, frozen, unprepared",Legumes and Legume Products,26.3,0.037,0.656,2,0,0.279,13.6,5.9,7.2,51,222,0.9,1.6,4,32757
"MORNINGSTAR FARMS Meal Starters Chik'n Strips, frozen, unprepared",Legumes and Legume Products,26.8,0.045,0.597,1.4,0,0.124,6.7,1.2,3.9,60.3,164,0.6,1.5,1.69,32758
"MORNINGSTAR FARMS MeatFree Buffalo Wings, frozen, unprepared",Legumes and Legume Products,14.5,0.052,0.758,3.7,0.0005,0.493,23,2.1,9.4,50.4,230,1.2,2,6.14,32759
"MORNINGSTAR FARMS Mushroom Lover's Burger, frozen, unprepared",Legumes and Legume Products,11.6,0,0.346,1.2,0,0,11.8,1.6,8.8,66.1,169,1.3,2.5,4.9,32760
"MORNINGSTAR FARMS Original Chik'n Tenders, frozen, unprepared",Legumes and Legume Products,14.7,0.052,0.714,3.6,0,0.247,25.2,0.6,8.1,50.4,233,1.1,2.4,4.55,32761
"MORNINGSTAR FARMS Sausage Style Recipe Crumbles, frozen, unprepared",Legumes and Legume Products,20.2,0.044,0.758,4.6,0,0.165,9.92,1.39,4.59,62,162,0.5,0.89,2,32762
"MORNINGSTAR FARMS Spicy Black Bean Burger, frozen, unprepared",Legumes and Legume Products,16.1,0.103,0.52,6.8,0.001,0.38,19.7,2.8,5.7,56.5,171,0.8,1.5,3.3,32763
"MORNINGSTAR FARMS Tomato & Basil Pizza Burger, frozen, unprepared",Legumes and Legume Products,15.5,0.048,0.389,3.7,0.022,0.238,11.1,2.4,8.3,62.8,181,2.2,2.1,3.86,32764
"MORNINGSTAR FARMS Veggie Breakfast Bacon Strips, frozen, unprepared",Legumes and Legume Products,12.4,0.042,1.463,5.1,0,0.097,14.3,0.8,26.6,41.9,346,4.2,6.2,16.2,32765
"MORNINGSTAR FARMS Veggie Breakfast Sausage Links, frozen, unprepared",Legumes and Legume Products,19.2,0.026,0.67,4,0,0.109,6.8,1,6.1,64,159,0.7,1.8,3.6,32766
"MORNINGSTAR FARMS Veggie Breakfast Sausage Patties, frozen, unprepared",Legumes and Legume Products,25.8,0.044,0.671,4.1,0,0.328,8.8,1.7,7.9,54.4,210,1.1,1.9,4.9,32767
"MORNINGSTAR FARMS Veggie Italian Style Sausage, frozen, unprepared",Legumes and Legume Products,17.5,0.039,0.549,2,0.001,0.194,10.6,1,8.7,62.2,187,1.1,1.6,3.9,32768
"MORNINGSTAR FARMS Veggie Sweet and Sour Chik'n, frozen, unprepared",Legumes and Legume Products,5,0.007,0.192,1.3,0.0184,0.123,20.42,4.19,2.29,70.9,122,0.2,1,0.6,32769
Meat extender,Legumes and Legume Products,38.11,0.204,0.01,17.5,0,1.902,38.32,0,2.97,7.48,313,0.424,0.648,1.657,32770
"Meatballs, meatless",Legumes and Legume Products,21,0.025,0.55,4.6,0,0.18,8,1.25,9,58,197,1.425,2.188,4.666,32771
Miso,Legumes and Legume Products,11.69,0.057,3.728,5.4,0,0.21,26.47,6.2,6.01,43.02,199,1.139,1.242,3.204,32772
"Mothbeans, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,7.81,0.003,0.246,0,0.001,0.304,20.96,0,0.55,69.23,117,0.124,0.044,0.256,32773
"Mothbeans, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,7.81,0.003,0.01,0,0.001,0.304,20.96,0,0.55,69.23,117,0.124,0.044,0.256,32774
"Mothbeans, mature seeds, raw",Legumes and Legume Products,22.94,0.15,0.03,0,0.004,1.191,61.52,0,1.61,9.68,343,0.364,0.129,0.75,32775
"Mung beans, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,7.02,0.027,0.238,7.6,0.001,0.266,19.15,2,0.38,72.66,105,0.116,0.054,0.128,32776
"Mung beans, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,7.02,0.027,0.002,7.6,0.001,0.266,19.15,2,0.38,72.66,105,0.116,0.054,0.128,32777
"Mung beans, mature seeds, raw",Legumes and Legume Products,23.86,0.132,0.015,16.3,0.0048,1.246,62.62,6.6,1.15,9.05,347,0.348,0.161,0.384,32778
"Mungo beans, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,7.54,0.053,0.243,6.4,0.001,0.231,18.34,2.01,0.55,72.51,105,0.038,0.029,0.359,32779
"Mungo beans, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,7.54,0.053,0.007,6.4,0.001,0.231,18.34,2.01,0.55,72.51,105,0.038,0.029,0.359,32780
"Mungo beans, mature seeds, raw",Legumes and Legume Products,25.21,0.138,0.038,18.3,0,0.983,58.99,0,1.64,10.8,341,0.114,0.085,1.071,32781
Natto,Legumes and Legume Products,17.72,0.217,0.007,5.4,0.013,0.729,14.36,4.89,11,55.02,212,1.591,2.43,6.21,32782
"Noodles, chinese, cellophane or long rice (mung beans), dehydrated",Legumes and Legume Products,0.16,0.025,0.01,0.5,0,0.01,86.09,0,0.06,13.42,351,0.017,0.008,0.018,32783
Papad,Legumes and Legume Products,25.56,0.143,1.745,18.6,0,1,59.87,0,3.25,3.49,371,1.084,0.532,1.148,32784
"Peanut butter with omega-3, creamy",Legumes and Legume Products,24.47,0.045,0.356,6.1,0,0.78,17,3.11,54.17,0.99,608,9.691,36.614,6.95,32785
"Peanut butter, chunk style, with salt",Legumes and Legume Products,24.06,0.045,0.486,8,0,0.745,21.57,8.41,49.94,1.14,589,8.081,24.561,14.798,32786
"Peanut butter, chunk style, without salt",Legumes and Legume Products,24.06,0.045,0.017,8,0,0.745,21.57,8.41,49.94,1.14,589,8.081,24.561,14.798,32787
"Peanut butter, chunky, vitamin and mineral fortified",Legumes and Legume Products,26.06,0.045,0.366,5.7,0,0.744,17.69,10.94,51.47,1.48,593,7.972,24.366,14.648,32788
"Peanut butter, reduced sodium",Legumes and Legume Products,24,0.041,0.203,6.6,0,0.747,21.83,9.29,49.9,1.1,590,7.716,23.582,14.363,32789
"Peanut butter, smooth style, with salt",Legumes and Legume Products,25.09,0.043,0.459,6,0,0.649,19.56,9.22,50.39,1.81,588,10.51,24.216,14.161,32790
"Peanut butter, smooth style, without salt",Legumes and Legume Products,25.09,0.043,0.017,6,0,0.649,19.56,9.22,50.39,1.81,588,10.292,23.713,13.867,32791
"Peanut butter, smooth, reduced fat",Legumes and Legume Products,25.9,0.035,0.54,5.2,0,0.669,35.65,9.28,34,1.2,520,5.76,16.156,9.182,32792
"Peanut butter, smooth, vitamin and mineral fortified",Legumes and Legume Products,25.72,0.043,0.42,5.6,0,0.667,18.75,10.47,50.81,1.47,591,10.125,24.832,13.847,32793
"Peanut flour, defatted",Legumes and Legume Products,52.2,0.14,0.18,15.8,0,1.29,34.7,8.22,0.55,7.8,327,0.063,0.225,0.143,32794
"Peanut flour, low fat",Legumes and Legume Products,33.8,0.13,0.001,15.8,0,1.358,31.27,0,21.9,7.8,428,3.04,10.866,6.921,32795
"Peanut spread, reduced sugar",Legumes and Legume Products,24.8,0.072,0.447,7.8,0,0.818,14.23,3.34,54.89,1.93,650,10.22,26.997,16.266,32796
"Peanuts, all types, cooked, boiled, with salt",Legumes and Legume Products,13.5,0.055,0.751,8.8,0,0.18,21.26,2.47,22.01,41.78,318,3.055,10.921,6.956,32797
"Peanuts, all types, dry-roasted, with salt",Legumes and Legume Products,23.68,0.054,0.813,8,0,0.658,21.51,4.18,49.66,1.55,585,6.893,24.64,15.694,32798
"Peanuts, all types, dry-roasted, without salt",Legumes and Legume Products,23.68,0.054,0.006,8,0,0.658,21.51,4.18,49.66,1.55,585,6.893,24.64,15.694,32799
"Peanuts, all types, oil-roasted, with salt",Legumes and Legume Products,28.03,0.061,0.32,9.4,0.0008,0.726,15.26,4.18,52.5,1.45,599,8.686,25.976,15.292,32800
"Peanuts, all types, oil-roasted, without salt",Legumes and Legume Products,26.35,0.088,0.006,6.9,0,0.682,18.92,4.16,49.3,1.95,581,6.843,24.462,15.58,32801
"Peanuts, all types, raw",Legumes and Legume Products,25.8,0.092,0.018,8.5,0,0.705,16.13,3.97,49.24,6.5,567,6.834,24.429,15.559,32802
"Peanuts, spanish, oil-roasted, with salt",Legumes and Legume Products,28.01,0.1,0.433,8.9,0,0.776,17.45,0,49.04,1.78,579,7.555,22.073,17.008,32803
"Peanuts, spanish, oil-roasted, without salt",Legumes and Legume Products,28.01,0.1,0.006,8.9,0,0.776,17.45,0,49.04,1.78,579,7.555,22.073,17.008,32804
"Peanuts, spanish, raw",Legumes and Legume Products,26.15,0.106,0.022,9.5,0,0.744,15.82,0,49.6,6.39,570,7.642,22.328,17.204,32805
"Peanuts, valencia, oil-roasted, with salt",Legumes and Legume Products,27.04,0.054,0.772,8.9,0,0.612,16.3,0,51.24,2.12,589,7.894,23.065,17.772,32806
"Peanuts, valencia, oil-roasted, without salt",Legumes and Legume Products,27.04,0.054,0.006,8.9,0,0.612,16.3,0,51.24,2.12,589,7.894,23.065,17.772,32807
"Peanuts, valencia, raw",Legumes and Legume Products,25.09,0.062,0.001,8.7,0,0.332,20.91,0,47.58,4.26,570,7.329,21.414,16.5,32808
"Peanuts, virginia, oil-roasted, with salt",Legumes and Legume Products,25.87,0.086,0.433,8.9,0,0.652,19.86,0,48.62,2.17,578,6.345,25.222,14.669,32809
"Peanuts, virginia, oil-roasted, without salt",Legumes and Legume Products,25.87,0.086,0.006,8.9,0,0.652,19.86,0,48.62,2.17,578,6.345,25.222,14.669,32810
"Peanuts, virginia, raw",Legumes and Legume Products,25.19,0.089,0.01,8.5,0,0.69,16.54,3.95,48.75,6.91,563,6.361,25.289,14.708,32811
"Peas, split, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,8.34,0.014,0.238,8.3,0.0004,0.362,20.51,2.9,0.39,69.49,116,0.054,0.081,0.165,32812
"Peas, split, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,8.34,0.014,0.002,8.3,0.0004,0.362,21.1,2.9,0.39,69.49,118,0.054,0.081,0.165,32813
"Peas, split, mature seeds, raw",Legumes and Legume Products,24.55,0.055,0.015,25.5,0.0018,0.981,60.37,8,1.16,11.27,341,0.161,0.242,0.495,32814
"Pigeon peas (red gram), mature seeds, cooked, boiled, with salt",Legumes and Legume Products,6.76,0.043,0.241,6.7,0,0.384,23.25,0,0.38,68.55,121,0.083,0.003,0.205,32815
"Pigeon peas (red gram), mature seeds, cooked, boiled, without salt",Legumes and Legume Products,6.76,0.043,0.005,6.7,0,0.384,23.25,0,0.38,68.55,121,0.083,0.003,0.205,32816
"Pigeon peas (red gram), mature seeds, raw",Legumes and Legume Products,21.7,0.13,0.017,15,0,1.392,62.78,0,1.49,10.59,343,0.33,0.012,0.814,32817
"Refried beans, canned, fat-free",Legumes and Legume Products,5.34,0.034,0.438,4.7,0.0009,0.344,13.5,0.61,0.45,78.69,79,0.093,0.044,0.287,32818
"Refried beans, canned, traditional style (includes USDA commodity)",Legumes and Legume Products,5.41,0.033,0.449,5.1,0.006,0.336,15.26,0.46,1.17,76.15,91,0.391,0.381,0.332,32819
"Refried beans, canned, vegetarian",Legumes and Legume Products,5.28,0.035,0.43,4.7,0,0.344,13.5,0.59,0.87,78.39,83,0.142,0.206,0.471,32820
SILK Banana-Strawberry soy Yogurt,Legumes and Legume Products,2.35,0.176,0.015,0.6,0.0176,0,17.06,10.59,1.18,78.18,88,0,0,0,32821
SILK Black Cherry soy Yogurt,Legumes and Legume Products,2.35,0.176,0.012,0.6,0.0176,0,17.06,11.76,1.18,78.18,88,0,0,0,32822
SILK Blueberry soy Yogurt,Legumes and Legume Products,2.35,0.176,0.015,0.6,0.0176,0,17.06,12.35,1.18,78.18,88,0,0,0,32823
"SILK Chai, soymilk",Legumes and Legume Products,2.47,0.123,0.041,0,0,0.123,7.82,5.76,1.44,87.62,53,0.206,0,0,32824
"SILK Chocolate, soymilk",Legumes and Legume Products,2.06,0.123,0.041,0.8,0,0.144,9.47,7.82,1.44,86.39,58,0.206,0,0,32825
"SILK Coffee, soymilk",Legumes and Legume Products,2.06,0.123,0.041,0,0,0.132,10.29,9.47,1.44,85.56,62,0.206,0,0,32826
SILK French Vanilla Creamer,Legumes and Legume Products,0,0,0.067,0,0,0,20,20,6.67,72.95,133,0,0,0,32827
SILK Hazelnut Creamer,Legumes and Legume Products,0,0,0.067,0,0.2,0,20,20,6.67,72.95,133,0,0,0,32828
SILK Key Lime soy Yogurt,Legumes and Legume Products,2.35,0.176,0.015,0.6,0.0176,0,17.65,12.35,1.18,77.59,88,0,0,0,32829
"SILK Light Chocolate, soymilk",Legumes and Legume Products,2.06,0.123,0.041,0.8,0,0.144,9.05,7.82,0.62,87.63,49,0,0,0,32830
"SILK Light Plain, soymilk",Legumes and Legume Products,2.47,0.123,0.049,0.4,0,0.123,3.29,2.47,0.82,92.77,29,0,0,0,32831
"SILK Light Vanilla, soymilk",Legumes and Legume Products,2.47,0.123,0.039,0.4,0,0.123,4.12,2.88,0.82,91.95,33,0,0,0,32832
"SILK Mocha, soymilk",Legumes and Legume Products,2.06,0.123,0.041,0,0,0.123,9.05,7.41,1.44,86.8,58,0.206,0,0,32833
"SILK Nog, soymilk",Legumes and Legume Products,2.46,0.016,0.061,0,0,0.123,12.3,9.84,1.64,82.96,74,0,0,0,32834
SILK Original Creamer,Legumes and Legume Products,0,0,0.067,0,0,0,6.67,0,6.67,86.29,100,0,0,0,32835
SILK Peach soy Yogurt,Legumes and Legume Products,2.35,0.176,0.015,0.6,0.0176,0,18.82,14.71,1.18,76.41,94,0,0,0,32836
SILK Plain soy yogurt,Legumes and Legume Products,2.64,0.132,0.013,0.4,0.0132,0,9.69,5.29,1.76,84.67,66,0.22,0,0,32837
"SILK Plain, soymilk",Legumes and Legume Products,2.88,0.123,0.049,0.4,0,0.123,3.29,2.47,1.65,91.53,41,0.206,0,0,32838
"SILK Plus Fiber, soymilk",Legumes and Legume Products,2.47,0.123,0.039,2.1,0,0.123,5.76,2.88,1.44,89.69,41,0.206,0,0,32839
"SILK Plus Omega-3 DHA, soymilk",Legumes and Legume Products,2.88,0.144,0.049,0.4,0,0.144,3.29,2.47,2.06,91.13,45,0.206,0,0,32840
"SILK Plus for Bone Health, soymilk",Legumes and Legume Products,2.47,0.165,0.039,0.8,0,0.123,4.53,2.88,1.44,90.92,41,0.206,0,0,32841
SILK Raspberry soy yogurt,Legumes and Legume Products,2.35,0.176,0.015,0.6,0.0176,0,17.65,12.94,1.18,77.59,88,0,0,0,32842
SILK Strawberry soy yogurt,Legumes and Legume Products,2.35,0.176,0.015,0.6,0.0176,0,18.24,12.94,1.18,77,94,0,0,0,32843
"SILK Unsweetened, soymilk",Legumes and Legume Products,2.88,0.123,0.035,0.4,0,0.123,1.65,0.41,1.65,93.18,33,0.206,0,0,32844
SILK Vanilla soy Yogurt (Family size),Legumes and Legume Products,2.64,0.132,0.013,0.4,0,0,13.66,10.57,1.76,80.7,79,0.22,0,0,32845
SILK Vanilla soy yogurt (single serving size),Legumes and Legume Products,2.94,0.176,0.012,0.6,0.0176,0,14.71,10.59,1.76,79.35,88,0,0,0,32846
"SILK Vanilla, soymilk",Legumes and Legume Products,2.47,0.123,0.039,0.4,0,0.123,4.12,2.88,1.44,91.32,41,0.206,0,0,32847
"SILK Very Vanilla, soymilk",Legumes and Legume Products,2.47,0.144,0.058,0.4,0.0086,0.123,7.82,6.58,1.65,87.41,53,0.206,0,0,32848
"Sandwich spread, meatless",Legumes and Legume Products,8,0.044,0.63,3.3,0,0.34,9,4.58,9,70,149,1.425,2.188,4.666,32849
"Sausage, meatless",Legumes and Legume Products,18.53,0.063,0.888,2.8,0,0.231,9.84,0,18.16,50.4,257,2.926,4.498,9.279,32850
"Soy flour, defatted",Legumes and Legume Products,47.01,0.241,0.02,17.5,0,2.384,38.37,18.88,1.22,7.25,330,0.136,0.208,0.533,32851
"Soy flour, defatted, crude protein basis (N x 6.25)",Legumes and Legume Products,49.81,0.285,0.009,16,0,2.09,30.63,14.58,8.9,4.61,372,1.29,1.46,4.215,32852
"Soy flour, full-fat, raw",Legumes and Legume Products,34.54,0.206,0.013,9.6,0,2.515,35.19,7.5,20.65,5.16,436,2.987,4.561,11.657,32853
"Soy flour, full-fat, raw, crude protein basis (N x 6.25)",Legumes and Legume Products,37.8,0.206,0.013,9.6,0,2.515,31.93,0,20.65,5.16,434,2.987,4.561,11.657,32854
"Soy flour, full-fat, roasted",Legumes and Legume Products,34.8,0.188,0.012,9.7,0,2.041,33.67,7.61,21.86,3.81,441,3.162,4.829,12.341,32855
"Soy flour, full-fat, roasted, crude protein basis (N x 6.25)",Legumes and Legume Products,38.09,0.188,0.012,0,0,2.041,30.38,0,21.86,3.81,439,3.162,4.829,12.341,32856
"Soy flour, low-fat",Legumes and Legume Products,45.51,0.285,0.009,16,0,2.09,34.93,10.53,8.9,4.61,375,1.29,1.46,4.215,32857
"Soy flour, low-fat, crude protein basis (N x 6.25)",Legumes and Legume Products,50.93,0.188,0.018,10.2,0,2.57,33.58,19.8,6.7,2.7,369,0.969,1.48,3.782,32858
"Soy meal, defatted, raw",Legumes and Legume Products,44.95,0.244,0.003,0,0,2.49,40.14,0,2.39,6.94,339,0.268,0.409,1.045,32859
"Soy meal, defatted, raw, crude protein basis (N x 6.25)",Legumes and Legume Products,49.2,0.244,0.003,0,0,2.49,35.89,0,2.39,6.94,337,0.268,0.409,1.045,32860
"Soy protein concentrate, crude protein basis (N x 6.25), produced by acid wash",Legumes and Legume Products,63.63,0.363,0.9,5.5,0,0.45,25.41,0,0.46,5.8,328,0.052,0.079,0.201,32861
"Soy protein concentrate, produced by acid wash",Legumes and Legume Products,58.13,0.363,0.9,5.5,0,0.45,30.91,20,0.46,5.8,331,0.052,0.079,0.201,32862
"Soy protein concentrate, produced by alcohol extraction",Legumes and Legume Products,58.13,0.363,0.003,5.5,0,2.202,30.91,20,0.46,5.8,331,0.052,0.079,0.201,32863
Soy protein isolate,Legumes and Legume Products,80.69,0.178,1.005,5.6,0,0.081,7.36,0,3.39,4.98,338,0.422,0.645,1.648,32864
"Soy protein isolate, PROTEIN TECHNOLOGIES INTERNATIONAL, ProPlus",Legumes and Legume Products,86,0.2,0.04,0,0,1.6,0,0,4,5,380,0.4,0.7,1.7,32865
"Soy protein isolate, PROTEIN TECHNOLOGIES INTERNATIONAL, SUPRO",Legumes and Legume Products,87.75,0.2,1.188,0,0,0.1,0,0,4,4.42,388,0.871,0.671,1.729,32866
"Soy protein isolate, potassium type",Legumes and Legume Products,80.69,0.178,0.05,5.6,0,1.59,10.22,0,0.53,4.98,326,0.077,0.117,0.299,32867
"Soy protein isolate, potassium type, crude protein basis",Legumes and Legume Products,88.32,0.178,0.05,2,0,1.59,2.59,0,0.53,4.98,321,0.066,0.101,0.258,32868
Soy sauce made from hydrolyzed vegetable protein,Legumes and Legume Products,2.43,0.005,5.689,0.5,0,0.152,7.73,1.7,0.08,75.66,40,0.006,0.009,0.022,32869
Soy sauce made from soy (tamari),Legumes and Legume Products,10.51,0.02,5.586,0.8,0,0.212,5.57,1.7,0.1,66,60,0.011,0.017,0.044,32870
Soy sauce made from soy and wheat (shoyu),Legumes and Legume Products,6.28,0.019,5.637,0.8,0,0.217,7.61,1.7,0.04,70.77,53,0.005,0.006,0.019,32871
"Soy sauce made from soy and wheat (shoyu), low sodium",Legumes and Legume Products,5.17,0.017,3.333,0.8,0,0.18,8.51,1.7,0.08,71.09,53,0.01,0.012,0.036,32872
"Soybean, curd cheese",Legumes and Legume Products,12.5,0.188,0.02,0,0,0.199,6.9,1.6,8.1,70.9,151,1.172,1.789,4.572,32873
"Soybeans, mature cooked, boiled, without salt",Legumes and Legume Products,16.64,0.102,0.001,6,0.0017,0.515,9.93,3,8.97,62.55,173,1.297,1.981,5.064,32874
"Soybeans, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,16.64,0.102,0.237,6,0.0017,0.515,9.93,3,8.97,62.55,173,1.297,1.981,5.064,32875
"Soybeans, mature seeds, dry roasted",Legumes and Legume Products,39.58,0.14,0.002,8.1,0.0046,1.364,32.72,0,21.62,0.8,451,3.127,4.776,12.207,32876
"Soybeans, mature seeds, raw",Legumes and Legume Products,36.49,0.277,0.002,9.3,0.006,1.797,30.16,7.33,19.94,8.54,446,2.884,4.404,11.255,32877
"Soybeans, mature seeds, roasted, no salt added",Legumes and Legume Products,35.22,0.138,0.004,17.7,0.0022,1.47,33.55,0,25.4,1.95,471,3.674,5.61,14.339,32878
"Soybeans, mature seeds, roasted, salted",Legumes and Legume Products,35.22,0.138,0.163,17.7,0.0022,1.47,33.55,4.2,25.4,1.95,471,3.674,5.61,14.339,32879
"Soymilk (All flavors), enhanced",Legumes and Legume Products,2.94,0.14,0.05,0.4,0.0072,0.141,3.45,2.53,1.99,90.98,45,0.206,0.412,1.19,32880
"Soymilk (All flavors), lowfat, with added calcium, vitamins A and D",Legumes and Legume Products,1.65,0.082,0.037,0.8,0,0.064,7.2,3.5,0.62,89.89,43,0,0.206,0.412,32881
"Soymilk (all flavors), nonfat, with added calcium, vitamins A and D",Legumes and Legume Products,2.47,0.116,0.057,0.2,0,0.105,4.14,3.65,0.04,92.67,28,0,0,0.036,32882
"Soymilk (all flavors), unsweetened, with added calcium, vitamins A and D",Legumes and Legume Products,2.86,0.124,0.037,0.5,0,0.12,1.74,0.41,1.61,93.14,33,0.206,0.392,1.009,32883
"Soymilk, chocolate and other flavors, light, with added calcium, vitamins A and D",Legumes and Legume Products,2.1,0.123,0.046,0.7,0,0.144,8.24,7.13,0.64,88.38,47,0.032,0.037,0.405,32884
"Soymilk, chocolate, nonfat, with added calcium, vitamins A and D",Legumes and Legume Products,2.47,0.116,0.057,0.2,0,0.105,8.51,3.65,0.04,88.38,44,0,0,0.036,32885
"Soymilk, chocolate, unfortified",Legumes and Legume Products,2.26,0.025,0.053,0.4,0.0017,0.143,9.95,7.86,1.53,85.61,63,0.24,0.379,0.836,32886
"Soymilk, chocolate, with added calcium, vitamins A and D",Legumes and Legume Products,2.26,0.126,0.053,0.4,0.0017,0.143,9.95,7.86,1.53,85.61,63,0.24,0.379,0.836,32887
"Soymilk, original and vanilla, light, unsweetened, with added calcium, vitamins A and D",Legumes and Legume Products,2.62,0.123,0.063,0.6,0,0.117,3.85,0.41,0.85,92.03,34,0.078,0.263,0.558,32888
"Soymilk, original and vanilla, light, with added calcium, vitamins A and D",Legumes and Legume Products,2.38,0.123,0.048,0.3,0,0.117,3.51,2.61,0.77,92.69,30,0.005,0.202,0.426,32889
"Soymilk, original and vanilla, unfortified",Legumes and Legume Products,3.27,0.025,0.051,0.6,0,0.118,6.28,3.99,1.75,88.05,54,0.205,0.401,0.961,32890
"Soymilk, original and vanilla, with added calcium, vitamins A and D",Legumes and Legume Products,2.6,0.123,0.047,0.2,0,0.122,4.92,3.65,1.47,90.36,43,0.205,0.382,0.858,32891
Tempeh,Legumes and Legume Products,18.54,0.111,0.009,0,0,0.412,9.39,0,10.8,59.65,193,2.22,3,3.827,32892
"Tempeh, cooked",Legumes and Legume Products,18.19,0.096,0.014,0,0,0.401,9.35,0,11.38,59.56,196,3.4,3.745,2.638,32893
Tofu yogurt,Legumes and Legume Products,3.5,0.118,0.035,0.2,0.0025,0.047,15.96,1.24,1.8,77.5,94,0.259,0.4,1.017,32894
"Tofu, dried-frozen (koyadofu)",Legumes and Legume Products,47.94,0.364,0.006,7.2,0.0007,0.02,14.56,0,30.34,5.78,480,4.388,6.701,17.128,32895
"Tofu, dried-frozen (koyadofu), prepared with calcium sulfate",Legumes and Legume Products,47.94,2.134,0.006,1.2,0.0007,0.02,12.79,0,30.34,5.78,472,4.388,6.701,17.128,32896
"Tofu, extra firm, prepared with nigari",Legumes and Legume Products,9.89,0.175,0.008,0.4,0.0005,0.132,2,0.5,5.83,81.28,91,0.542,4.338,0.542,32897
"Tofu, firm, prepared with calcium sulfate and magnesium chloride (nigari)",Legumes and Legume Products,8.19,0.201,0.012,0.9,0.0002,0.148,1.69,0.6,4.17,84.95,70,0.863,1.226,1.794,32898
"Tofu, fried",Legumes and Legume Products,17.19,0.372,0.016,3.9,0,0.146,10.49,2.72,20.18,50.52,271,2.918,4.456,11.39,32899
"Tofu, fried, prepared with calcium sulfate",Legumes and Legume Products,17.19,0.961,0.016,3.9,0,0.146,10.5,0,20.18,50.52,271,2.918,4.456,11.39,32900
"Tofu, hard, prepared with nigari",Legumes and Legume Products,12.68,0.345,0.002,0.6,0.0003,0.146,4.39,0,9.99,71.12,146,1.445,2.205,5.638,32901
"Tofu, okara",Legumes and Legume Products,3.22,0.08,0.009,0,0,0.213,12.54,0,1.73,81.64,77,0.193,0.295,0.755,32902
"Tofu, raw, firm, prepared with calcium sulfate",Legumes and Legume Products,15.78,0.683,0.014,2.3,0.0002,0.237,4.27,0,8.72,69.83,145,1.261,1.925,4.921,32903
"Tofu, raw, regular, prepared with calcium sulfate",Legumes and Legume Products,8.08,0.35,0.007,0.3,0.0001,0.121,1.88,0,4.78,84.55,76,0.691,1.056,2.699,32904
"Tofu, salted and fermented (fuyu)",Legumes and Legume Products,8.15,0.046,2.873,0,0.0002,0.075,5.15,0,8,70.01,116,1.157,1.767,4.516,32905
"Tofu, salted and fermented (fuyu), prepared with calcium sulfate",Legumes and Legume Products,8.15,1.229,2.873,0,0.0002,0.075,5.15,0,8,70.01,116,1.157,1.767,4.516,32906
"Tofu, soft, prepared with calcium sulfate and magnesium chloride (nigari)",Legumes and Legume Products,6.55,0.111,0.008,0.2,0.0002,0.12,1.8,0.7,3.69,87.26,61,0.533,0.814,2.081,32907
"USDA Commodity, Peanut Butter, smooth",Legumes and Legume Products,21.93,0.054,0.476,5.7,0,0.592,23.98,6.5,49.54,1.55,588,9.519,20.726,11.331,32908
Vegetarian fillets,Legumes and Legume Products,23,0.095,0.49,6.1,0,0.6,9,0.8,18,45,290,2.849,4.376,9.332,32909
Vegetarian meatloaf or patties,Legumes and Legume Products,21,0.029,0.55,4.6,0,0.18,8,1.2,9,58,197,1.425,2.188,4.666,32910
Vegetarian stew,Legumes and Legume Products,17,0.031,0.4,1.1,0,0.12,7,1.25,3,70,123,0.475,0.729,1.555,32911
"Veggie burgers or soyburgers, unprepared",Legumes and Legume Products,15.7,0.136,0.569,4.9,0.0045,0.333,14.27,1.07,6.3,61.21,177,1.44,1.778,2.023,32912
"Vermicelli, made from soy",Legumes and Legume Products,0.1,0.055,0.004,3.9,0,0.003,82.32,17.44,0.1,11.9,331,0.014,0.013,0.041,32913
"Vitasoy USA, Nasoya Lite Firm Tofu",Legumes and Legume Products,8.3,0.184,0.034,0.6,0,0,1.3,0.2,1.7,87.74,54,0.2,0.3,0,32914
"Vitasoy USA, Organic Nasoya Extra Firm Tofu",Legumes and Legume Products,10.1,0.077,0.004,1.3,0,0,2.8,0.4,5.2,80.96,98,0.7,1.4,3.1,32915
"Vitasoy USA, Organic Nasoya Firm Tofu",Legumes and Legume Products,8.9,0.125,0.004,0.8,0,0,2.3,0.3,4.4,83.81,84,0.5,1.2,2.7,32916
"Vitasoy USA, Organic Nasoya Super Firm Cubed Tofu",Legumes and Legume Products,12.4,0.188,0.006,2.2,0,0,3.9,0.6,6.3,76.4,122,1.3,1.3,3.8,32917
"Vitasoy USA, Vitasoy Light Vanilla Soymilk",Legumes and Legume Products,1.6,0.123,0.049,0.1,0,0.082,4.1,2.9,0.82,92.74,30,0.18,0.2,0.4,32918
"Vitasoy USA, Vitasoy Organic Creamy Original Soymilk",Legumes and Legume Products,2.9,0.127,0.066,0.4,0,0.131,4.5,2,1.6,90.27,44,0.2,0.4,1,32919
"Vitasoy USA, Vitasoy Orgnaic Classic Original Soymilk",Legumes and Legume Products,3.2,0.017,0.066,0.4,0,0.156,4.5,2,1.8,89.76,47,0.21,0.5,1.1,32920
"WORTHINGTON Chic-Ketts, frozen, unprepared",Legumes and Legume Products,23.6,0.039,0.642,1.2,0,0.152,5.1,0.1,9.7,59.1,200,1.5,2.3,5.7,32921
"WORTHINGTON Chili, canned, unprepared",Legumes and Legume Products,10.4,0.018,0.453,3.4,0,0.161,10.9,1.3,4.5,72.8,126,0.7,0.9,2.9,32922
"WORTHINGTON Choplets, canned, unprepared",Legumes and Legume Products,19.4,0.008,0.456,2.8,0,0.038,4.1,0.4,1,74.1,103,0.1,0.1,0.5,32923
"WORTHINGTON Diced Chik, canned, unprepared",Legumes and Legume Products,14.7,0.055,0.344,1.4,0,0.216,3.7,0.1,0.7,79,80,0,0,0,32924
"WORTHINGTON Dinner Roast, frozen, unprepared",Legumes and Legume Products,16,0.046,0.671,3.1,0,0.15,6.9,1,13.5,61.7,213,2.4,5.4,5.6,32925
"WORTHINGTON FriChik Original, canned, unprepared",Legumes and Legume Products,13.4,0.033,0.401,1.5,0,0.154,3.4,0.1,10.3,71.1,160,1.4,2.4,5.5,32926
"WORTHINGTON FriPats, frozen, unprepared",Legumes and Legume Products,23.7,0.097,0.517,2.8,0,0.185,8,1.4,9.1,56.8,209,1.4,2.2,5.2,32927
"WORTHINGTON Leanies, frozen, unprepared",Legumes and Legume Products,19.5,0.073,1.078,3.8,0,0.103,6.1,1.2,16.5,54.3,251,2.8,6.2,7.3,32928
"WORTHINGTON Low Fat Fri Chik, canned, unprepared",Legumes and Legume Products,14.3,0.035,0.416,1,0,0.169,4.9,0,2.7,76.4,102,0.4,0.7,1.1,32929
"WORTHINGTON Low Fat Veja-Links, canned, unprepared",Legumes and Legume Products,15.8,0.02,0.612,1.2,0,0.071,4.3,0.7,4.8,73,123,0.7,1.1,2.6,32930
"WORTHINGTON Meatless Chicken Roll, frozen, unprepared",Legumes and Legume Products,16.1,0.272,0.452,2.4,0,0.412,4.3,0.8,8,68,154,1.2,1.8,4.6,32931
"WORTHINGTON Meatless Corned Beef Roll, frozen, unprepared",Legumes and Legume Products,18.6,0.024,0.758,0,0,0.216,9.9,2.1,14.5,54.1,245,2.3,3.5,8.6,32932
"WORTHINGTON Multigrain Cutlets, canned, unprepared",Legumes and Legume Products,18.1,0.01,0.313,3.1,0,0.032,6,0.6,1.3,73.4,108,0.5,0.2,0.6,32933
"WORTHINGTON Prime Stakes, canned, unprepared",Legumes and Legume Products,10.2,0.015,0.48,1.4,0,0.101,7.5,0.3,7.2,73.4,135,1,1.7,3.6,32934
"WORTHINGTON Prosage Links, frozen, unprepared",Legumes and Legume Products,20.2,0.026,0.819,2.8,0,0.114,5,0.8,4.7,67.5,143,0.9,1.8,2,32935
"WORTHINGTON Prosage Roll, frozen, unprepared",Legumes and Legume Products,19.6,0.028,0.667,3.5,0,0.139,6,0.2,17.6,54.4,261,3.4,4.5,8.3,32936
"WORTHINGTON Saucettes, canned, unprepared",Legumes and Legume Products,15,0.04,0.532,2.8,0,0.069,5.6,0.2,15.2,62.4,219,0,0,0,32937
"WORTHINGTON Smoked Turkey Roll, frozen, unprepared",Legumes and Legume Products,18.5,0.147,0.859,1.1,0,0.098,7.9,2.4,16.1,53.7,251,2.3,3.9,8.9,32938
"WORTHINGTON Stakelets, frozen, unprepared",Legumes and Legume Products,19.7,0.073,0.651,2.8,0,0.191,9.6,1.2,10.4,57.5,211,1.8,3.5,5.1,32939
"WORTHINGTON Stripples, frozen, unprepared",Legumes and Legume Products,12.4,0.042,1.463,5.1,0,0.097,14.3,0.8,26.6,41.9,346,4.2,6.2,16.2,32940
"WORTHINGTON Super Links, canned, unprepared",Legumes and Legume Products,14.5,0.003,0.708,1.8,0,0.077,5.5,0.6,15.5,62.1,219,2.1,3.1,8.4,32941
"WORTHINGTON Vegetable Skallops, canned, unprepared",Legumes and Legume Products,19.9,0.007,0.46,3.4,0,0.017,4.6,0.3,1.2,73,109,0.2,0.1,0.5,32942
"WORTHINGTON Vegetable Steaks, canned, unprepared",Legumes and Legume Products,20.7,0.006,0.417,2.2,0,0.032,5.1,0.5,1.2,71.8,113,0,0,0,32943
"WORTHINGTON Vegetarian Burger, canned, unprepared",Legumes and Legume Products,18.6,0.008,0.451,2.7,0,0.044,6.2,0.6,2.9,70.7,124,0.5,0.5,1.7,32944
"WORTHINGTON Veja-Links, canned, unprepared",Legumes and Legume Products,14.6,0.023,0.53,3.3,0,0.056,4.1,0.5,8.9,70.4,155,1.3,2,4.5,32945
"WORTHINGTON Wham (roll), frozen, unprepared",Legumes and Legume Products,17.8,0.045,0.717,0,0,0.194,5.7,3.2,11.3,62.1,196,1.6,2.5,5.8,32946
"Winged beans, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,10.62,0.142,0.249,0,0,0.28,14.94,0,5.84,67.19,147,0.825,2.153,1.551,32947
"Winged beans, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,10.62,0.142,0.013,0,0,0.28,14.94,0,5.84,67.19,147,0.825,2.153,1.551,32948
"Winged beans, mature seeds, raw",Legumes and Legume Products,29.65,0.44,0.038,0,0,0.977,41.71,0,16.32,8.34,409,2.303,6.012,4.33,32949
"Yardlong beans, mature seeds, cooked, boiled, with salt",Legumes and Legume Products,8.29,0.042,0.241,3.8,0.0004,0.315,21.09,0,0.45,68.8,118,0.116,0.039,0.193,32950
"Yardlong beans, mature seeds, cooked, boiled, without salt",Legumes and Legume Products,8.29,0.042,0.005,3.8,0.0004,0.315,21.09,0,0.45,68.8,118,0.116,0.039,0.193,32951
"Yardlong beans, mature seeds, raw",Legumes and Legume Products,24.33,0.138,0.017,11,0.0016,1.157,61.91,0,1.31,8.43,347,0.339,0.114,0.565,32952
"Bison, ground, grass-fed, cooked","Lamb, Veal, and Game Products",25.45,0.014,0.076,0,0,0.353,0,0,8.62,65.09,179,3.489,3.293,0.402,32953
"Bison, ground, grass-fed, raw","Lamb, Veal, and Game Products",20.23,0.011,0.07,0,0,0.328,0.05,0,7.21,71.59,146,2.917,2.753,0.336,32954
"Game meat , bison, ground, raw","Lamb, Veal, and Game Products",18.67,0.011,0.066,0,0,0.307,0,0,15.93,64.25,223,0,0,0,32955
"Game meat , bison, top sirloin, separable lean only, 1"" steak, cooked, broiled","Lamb, Veal, and Game Products",28.05,0.005,0.053,0,0,0.387,0,0,5.65,65.1,171,2.412,2.206,0.266,32956
"Game meat, antelope, cooked, roasted","Lamb, Veal, and Game Products",29.45,0.004,0.054,0,0,0.372,0,0,2.67,65.9,150,0.97,0.63,0.58,32957
"Game meat, antelope, raw","Lamb, Veal, and Game Products",22.38,0.003,0.051,0,0,0.353,0,0,2.03,74.08,114,0.74,0.48,0.44,32958
"Game meat, bear, cooked, simmered","Lamb, Veal, and Game Products",32.42,0.005,0.071,0,0,0.263,0,0,13.39,53.55,259,3.54,5.66,2.4,32959
"Game meat, bear, raw","Lamb, Veal, and Game Products",20.1,0.003,0,0,0,0,0,0,8.3,71.2,161,0,0,0,32960
"Game meat, beaver, cooked, roasted","Lamb, Veal, and Game Products",34.85,0.022,0.059,0,0.003,0.403,0,0,6.96,57.92,212,2.07,1.89,1.35,32961
"Game meat, beaver, raw","Lamb, Veal, and Game Products",24.05,0.015,0.051,0,0.002,0.348,0,0,4.8,70.97,146,0,0,0,32962
"Game meat, beefalo, composite of cuts, cooked, roasted","Lamb, Veal, and Game Products",30.66,0.024,0.082,0,0.009,0.459,0,0,6.32,61.7,188,2.68,2.68,0.2,32963
"Game meat, beefalo, composite of cuts, raw","Lamb, Veal, and Game Products",23.3,0.018,0.078,0,0.007,0.436,0,0,4.8,70.89,143,2.04,2.04,0.15,32964
"Game meat, bison, chuck, shoulder clod, separable lean only, 3-5 lb roast, cooked, braised","Lamb, Veal, and Game Products",33.78,0.007,0.057,0,0,0.316,0,0,5.43,60.48,193,2.32,2.122,0.255,32965
"Game meat, bison, chuck, shoulder clod, separable lean only, 3-5 lb roast, raw","Lamb, Veal, and Game Products",21.12,0.005,0.062,0,0,0.353,0,0,3.15,75.5,119,1.345,1.23,0.148,32966
"Game meat, bison, ground, cooked, pan-broiled","Lamb, Veal, and Game Products",23.77,0.013,0.073,0,0,0.341,0,0,15.13,59.53,238,0,0,0,32967
"Game meat, bison, ribeye, separable lean only, 1"" steak, cooked, broiled","Lamb, Veal, and Game Products",29.45,0.007,0.052,0,0,0.371,0,0,5.67,64.48,177,2.419,2.213,0.266,32968
"Game meat, bison, ribeye, separable lean only, trimmed to 0"" fat, raw","Lamb, Veal, and Game Products",22.1,0.006,0.048,0,0,0.344,0,0,2.4,74,116,0.898,0.996,0.192,32969
"Game meat, bison, separable lean only, cooked, roasted","Lamb, Veal, and Game Products",28.44,0.008,0.057,0,0,0.361,0,0,2.42,66.54,143,0.91,0.95,0.24,32970
"Game meat, bison, separable lean only, raw","Lamb, Veal, and Game Products",21.62,0.006,0.054,0,0,0.343,0,0,1.84,74.57,109,0.69,0.72,0.19,32971
"Game meat, bison, shoulder clod, separable lean only, trimmed to 0"" fat, raw","Lamb, Veal, and Game Products",21.1,0.004,0.059,0,0,0.322,0,0,2.1,75.4,109,0.749,0.861,0.217,32972
"Game meat, bison, top round, separable lean only, 1"" steak, cooked, broiled","Lamb, Veal, and Game Products",30.18,0.005,0.041,0,0,0.382,0,0,4.96,65.08,174,1.957,1.79,0.215,32973
"Game meat, bison, top round, separable lean only, 1"" steak, raw","Lamb, Veal, and Game Products",23.32,0.005,0.047,0,0,0.39,0,0,2.43,73.95,122,1.039,0.95,0.114,32974
"Game meat, bison, top sirloin, separable lean only, trimmed to 0"" fat, raw","Lamb, Veal, and Game Products",21.4,0.005,0.051,0,0,0.335,0,0,2.4,74.4,113,0.885,0.986,0.217,32975
"Game meat, boar, wild, cooked, roasted","Lamb, Veal, and Game Products",28.3,0.016,0.06,0,0,0.396,0,0,4.38,63.87,160,1.3,1.71,0.64,32976
"Game meat, boar, wild, raw","Lamb, Veal, and Game Products",21.51,0.012,0,0,0,0,0,0,3.33,72.54,122,0.99,1.3,0.48,32977
"Game meat, buffalo, water, cooked, roasted","Lamb, Veal, and Game Products",26.83,0.015,0.056,0,0,0.313,0,0,1.8,68.81,131,0.6,0.55,0.36,32978
"Game meat, buffalo, water, raw","Lamb, Veal, and Game Products",20.39,0.012,0.053,0,0,0.297,0,0,1.37,76.3,99,0.46,0.42,0.27,32979
"Game meat, caribou, cooked, roasted","Lamb, Veal, and Game Products",29.77,0.022,0.06,0,0.003,0.31,0,0,4.42,62.43,167,1.7,1.33,0.62,32980
"Game meat, caribou, raw","Lamb, Veal, and Game Products",22.63,0.017,0.057,0,0,0.295,0,0,3.36,71.45,127,1.29,1.01,0.47,32981
"Game meat, deer, cooked, roasted","Lamb, Veal, and Game Products",30.21,0.007,0.054,0,0,0.335,0,0,3.19,65.23,158,1.25,0.88,0.62,32982
"Game meat, deer, ground, cooked, pan-broiled","Lamb, Veal, and Game Products",26.45,0.014,0.078,0,0,0.364,0,0,8.22,64.23,187,3.993,1.939,0.444,32983
"Game meat, deer, ground, raw","Lamb, Veal, and Game Products",21.78,0.011,0.075,0,0,0.33,0,0,7.13,71.15,157,3.361,1.344,0.394,32984
"Game meat, deer, loin, separable lean only, 1"" steak, cooked, broiled","Lamb, Veal, and Game Products",30.2,0.006,0.057,0,0,0.398,0,0,2.38,67.07,150,0.878,0.351,0.103,32985
"Game meat, deer, raw","Lamb, Veal, and Game Products",22.96,0.005,0.051,0,0,0.318,0,0,2.42,73.57,120,0.95,0.67,0.47,32986
"Game meat, deer, shoulder clod, separable lean only, 3-5 lb roast, cooked, braised","Lamb, Veal, and Game Products",36.28,0.006,0.052,0,0,0.313,0,0,3.95,59.78,191,1.959,0.783,0.23,32987
"Game meat, deer, tenderloin, separable lean only, 0.5-1 lb roast, cooked, broiled","Lamb, Veal, and Game Products",29.9,0.005,0.057,0,0,0.434,0,0,2.35,67.2,149,1.142,0.554,0.127,32988
"Game meat, deer, top round, separable lean only, 1"" steak, cooked, broiled","Lamb, Veal, and Game Products",31.47,0.004,0.045,0,0,0.377,0,0,1.92,66.1,152,1.03,0.412,0.121,32989
"Game meat, elk, cooked, roasted","Lamb, Veal, and Game Products",30.19,0.005,0.061,0,0,0.328,0,0,1.9,66.28,146,0.7,0.48,0.4,32990
"Game meat, elk, ground, cooked, pan-broiled","Lamb, Veal, and Game Products",26.64,0.01,0.085,0,0,0.354,0,0,8.74,64.14,193,4.002,2.757,0.412,32991
"Game meat, elk, ground, raw","Lamb, Veal, and Game Products",21.76,0.012,0.079,0,0,0.322,0,0,8.82,68.84,172,3.469,2.507,0.415,32992
"Game meat, elk, loin, separable lean only, cooked, broiled","Lamb, Veal, and Game Products",31,0.005,0.054,0,0,0.404,0,0,3.84,64.5,167,1.511,1.092,0.181,32993
"Game meat, elk, raw","Lamb, Veal, and Game Products",22.95,0.004,0.058,0,0,0.312,0,0,1.45,74.38,111,0.53,0.36,0.3,32994
"Game meat, elk, round, separable lean only, cooked, broiled","Lamb, Veal, and Game Products",30.94,0.005,0.051,0,0,0.392,0,0,2.64,65.43,156,1.037,0.75,0.124,32995
"Game meat, elk, tenderloin, separable lean only, cooked, broiled","Lamb, Veal, and Game Products",30.76,0.005,0.05,0,0,0.392,0,0,3.41,64.93,162,1.342,0.97,0.161,32996
"Game meat, goat, cooked, roasted","Lamb, Veal, and Game Products",27.1,0.017,0.086,0,0,0.405,0,0,3.03,68.21,143,0.93,1.36,0.23,32997
"Game meat, horse, cooked, roasted","Lamb, Veal, and Game Products",28.14,0.008,0.055,0,0.002,0.379,0,0,6.05,63.98,175,1.9,2.12,0.85,32998
"Game meat, horse, raw","Lamb, Veal, and Game Products",21.39,0.006,0.053,0,0.001,0.36,0,0,4.6,72.63,133,1.44,1.61,0.65,32999
"Game meat, moose, cooked, roasted","Lamb, Veal, and Game Products",29.27,0.006,0.069,0,0.005,0.334,0,0,0.97,67.83,134,0.29,0.2,0.31,33000
"Game meat, moose, raw","Lamb, Veal, and Game Products",22.24,0.005,0.065,0,0.004,0.317,0,0,0.74,75.55,102,0.22,0.15,0.24,33001
"Game meat, muskrat, cooked, roasted","Lamb, Veal, and Game Products",30.09,0.036,0.095,0,0.007,0.32,0,0,11.74,55.58,234,0,0,0,33002
"Game meat, muskrat, raw","Lamb, Veal, and Game Products",20.76,0.025,0.082,0,0.005,0.276,0,0,8.1,69.35,162,0,0,0,33003
"Game meat, opossum, cooked, roasted","Lamb, Veal, and Game Products",30.2,0.017,0.058,0,0,0.438,0,0,10.2,58.3,221,1.206,3.773,2.983,33004
"Game meat, rabbit, domesticated, composite of cuts, cooked, roasted","Lamb, Veal, and Game Products",29.06,0.019,0.047,0,0,0.383,0,0,8.05,60.61,197,2.4,2.17,1.56,33005
"Game meat, rabbit, domesticated, composite of cuts, cooked, stewed","Lamb, Veal, and Game Products",30.38,0.02,0.037,0,0,0.3,0,0,8.41,58.82,206,2.51,2.27,1.63,33006
"Game meat, rabbit, domesticated, composite of cuts, raw","Lamb, Veal, and Game Products",20.05,0.013,0.041,0,0,0.33,0,0,5.55,72.82,136,1.66,1.5,1.08,33007
"Game meat, rabbit, wild, cooked, stewed","Lamb, Veal, and Game Products",33.02,0.018,0.045,0,0,0.343,0,0,3.51,61.37,173,1.05,0.95,0.68,33008
"Game meat, rabbit, wild, raw","Lamb, Veal, and Game Products",21.79,0.012,0.05,0,0,0.378,0,0,2.32,74.51,114,0.69,0.63,0.45,33009
"Game meat, raccoon, cooked, roasted","Lamb, Veal, and Game Products",29.2,0.014,0.079,0,0,0.398,0,0,14.5,54.3,255,4.07,5.21,2.09,33010
"Game meat, squirrel, cooked, roasted","Lamb, Veal, and Game Products",30.77,0.003,0.119,0,0,0.352,0,0,4.69,62.07,173,0.85,1.33,1.46,33011
"Game meat, squirrel, raw","Lamb, Veal, and Game Products",21.23,0.002,0.103,0,0,0.304,0,0,3.21,73.83,120,0.38,1.18,0.94,33012
"Goat, raw","Lamb, Veal, and Game Products",20.6,0.013,0.082,0,0,0.385,0,0,2.31,75.84,109,0.71,1.03,0.17,33013
"Lamb, Australian, imported, fresh, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8"" fat, cooked","Lamb, Veal, and Game Products",24.52,0.017,0.076,0,0,0.301,0,0,16.82,58.71,256,7.94,6.711,0.66,33014
"Lamb, Australian, imported, fresh, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",17.84,0.013,0.074,0,0,0.284,0,0,16.97,64.93,229,8.189,6.91,0.718,33015
"Lamb, Australian, imported, fresh, composite of trimmed retail cuts, separable lean only, trimmed to 1/8"" fat, cooked","Lamb, Veal, and Game Products",26.71,0.016,0.08,0,0,0.318,0,0,9.63,63.75,201,4.048,3.857,0.418,33016
"Lamb, Australian, imported, fresh, composite of trimmed retail cuts, separable lean only, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",20.25,0.012,0.083,0,0,0.32,0,0,6.18,73.22,142,2.54,2.53,0.276,33017
"Lamb, Australian, imported, fresh, foreshank, separable lean and fat, trimmed to 1/8"" fat, cooked, braised","Lamb, Veal, and Game Products",24.78,0.016,0.093,0,0,0.244,0,0,14.44,60.47,236,6.794,5.959,0.601,33018
"Lamb, Australian, imported, fresh, foreshank, separable lean and fat, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",18.85,0.012,0.096,0,0,0.27,0,0,12.68,68.31,195,5.981,5.293,0.596,33019
"Lamb, Australian, imported, fresh, foreshank, separable lean only, trimmed to 1/8"" fat, cooked, braised","Lamb, Veal, and Game Products",27.5,0.014,0.1,0,0,0.255,0,0,5.22,66.93,165,1.884,2.338,0.294,33020
"Lamb, Australian, imported, fresh, foreshank, separable lean only, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",20.83,0.011,0.106,0,0,0.295,0,0,3.81,75.13,123,1.344,1.712,0.241,33021
"Lamb, Australian, imported, fresh, leg, center slice, bone-in, separable lean and fat, trimmed to 1/8"" fat, cooked, broiled","Lamb, Veal, and Game Products",25.54,0.014,0.065,0,0,0.342,0,0,11.78,61.99,215,5.32,4.755,0.526,33022
"Lamb, Australian, imported, fresh, leg, center slice, bone-in, separable lean and fat, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",19.17,0.01,0.061,0,0,0.318,0,0,12.56,68.46,195,5.813,5.146,0.591,33023
"Lamb, Australian, imported, fresh, leg, center slice, bone-in, separable lean only, trimmed to 1/8"" fat, cooked, broiled","Lamb, Veal, and Game Products",26.75,0.013,0.066,0,0,0.354,0,0,7.68,64.85,183,3.111,3.129,0.39,33024
"Lamb, Australian, imported, fresh, leg, center slice, bone-in, separable lean only, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",20.65,0.009,0.064,0,0,0.342,0,0,6.08,73.45,143,2.413,2.519,0.331,33025
"Lamb, Australian, imported, fresh, leg, shank half, separable lean and fat, trimmed to 1/8"" fat, cooked, roasted","Lamb, Veal, and Game Products",25.25,0.01,0.067,0,0,0.313,0,0,13.69,60.82,231,6.341,5.554,0.563,33026
"Lamb, Australian, imported, fresh, leg, shank half, separable lean and fat, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",18.59,0.009,0.075,0,0,0.301,0,0,13.48,67.64,201,6.389,5.549,0.57,33027
"Lamb, Australian, imported, fresh, leg, shank half, separable lean only, trimmed to 1/8"" fat, cooked, roasted","Lamb, Veal, and Game Products",27.18,0.008,0.069,0,0,0.329,0,0,7.27,65.3,182,2.918,3.021,0.348,33028
"Lamb, Australian, imported, fresh, leg, shank half, separable lean only, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",20.45,0.007,0.081,0,0,0.329,0,0,5.1,74.08,133,2.008,2.157,0.246,33029
"Lamb, Australian, imported, fresh, leg, sirloin chops, boneless, separable lean and fat, trimmed to 1/8"" fat, cooked, broiled","Lamb, Veal, and Game Products",25.75,0.014,0.064,0,0,0.336,0,0,13.83,59.95,235,6.414,5.567,0.595,33030
"Lamb, Australian, imported, fresh, leg, sirloin chops, boneless, separable lean and fat, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",18.33,0.011,0.059,0,0,0.308,0,0,14.38,66.86,208,6.898,5.878,0.649,33031
"Lamb, Australian, imported, fresh, leg, sirloin chops, boneless, separable lean only, trimmed to 1/8"" fat, cooked, broiled","Lamb, Veal, and Game Products",27.63,0.013,0.066,0,0,0.354,0,0,7.8,64.08,188,3.159,3.177,0.396,33032
"Lamb, Australian, imported, fresh, leg, sirloin chops, boneless, separable lean only, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",20.43,0.009,0.064,0,0,0.342,0,0,4.91,74.13,132,1.948,2.034,0.267,33033
"Lamb, Australian, imported, fresh, leg, sirloin half, boneless, separable lean and fat, trimmed to 1/8"" fat, cooked, roasted","Lamb, Veal, and Game Products",24.88,0.015,0.078,0,0,0.297,0,0,19.38,56.2,281,9.245,7.658,0.771,33034
"Lamb, Australian, imported, fresh, leg, sirloin half, boneless, separable lean and fat, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",17.25,0.012,0.07,0,0,0.279,0,0,20,62.63,254,9.754,8.101,0.823,33035
"Lamb, Australian, imported, fresh, leg, sirloin half, boneless, separable lean only, trimmed to 1/8"" fat, cooked, roasted","Lamb, Veal, and Game Products",27.75,0.013,0.083,0,0,0.318,0,0,10.65,62.18,215,4.508,4.182,0.482,33036
"Lamb, Australian, imported, fresh, leg, sirloin half, boneless, separable lean only, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",20.48,0.01,0.08,0,0,0.328,0,0,5.64,73.68,138,2.308,2.293,0.267,33037
"Lamb, Australian, imported, fresh, leg, whole (shank and sirloin), separable lean and fat, trimmed to 1/8"" fat, cooked, roasted","Lamb, Veal, and Game Products",25.16,0.011,0.07,0,0,0.309,0,0,15.13,59.65,244,7.091,6.087,0.615,33038
"Lamb, Australian, imported, fresh, leg, whole (shank and sirloin), separable lean and fat, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",18.24,0.01,0.073,0,0,0.295,0,0,15.19,66.33,215,7.288,6.226,0.657,33039
"Lamb, Australian, imported, fresh, leg, whole (shank and sirloin), separable lean only, trimmed to 1/8"" fat, cooked, roasted","Lamb, Veal, and Game Products",27.31,0.009,0.072,0,0,0.326,0,0,8.1,64.54,190,3.297,3.304,0.38,33040
"Lamb, Australian, imported, fresh, leg, whole (shank and sirloin), separable lean only, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",20.46,0.008,0.081,0,0,0.329,0,0,5.23,73.98,135,2.081,2.19,0.251,33041
"Lamb, Australian, imported, fresh, loin, separable lean and fat, trimmed to 1/8"" fat, cooked, broiled","Lamb, Veal, and Game Products",25.49,0.021,0.078,0,0,0.331,0,0,12.25,62,219,5.572,4.888,0.486,33042
"Lamb, Australian, imported, fresh, loin, separable lean and fat, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",19.32,0.015,0.07,0,0,0.302,0,0,13.38,66.8,203,6.401,5.374,0.54,33043
"Lamb, Australian, imported, fresh, loin, separable lean only, trimmed to 1/8"" fat, cooked, broiled","Lamb, Veal, and Game Products",26.53,0.021,0.08,0,0,0.34,0,0,8.75,64.45,192,3.687,3.499,0.367,33044
"Lamb, Australian, imported, fresh, loin, separable lean only, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",21,0.015,0.075,0,0,0.327,0,0,6.24,72.17,146,2.673,2.463,0.261,33045
"Lamb, Australian, imported, fresh, rib, separable lean and fat, trimmed to 1/8"" fat, cooked, roasted","Lamb, Veal, and Game Products",22.24,0.017,0.077,0,0,0.282,0,0,20.21,57.53,277,9.734,7.94,0.728,33046
"Lamb, Australian, imported, fresh, rib, separable lean and fat, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",16.46,0.013,0.068,0,0,0.254,0,0,24.2,59.01,289,11.925,9.765,0.985,33047
"Lamb, Australian, imported, fresh, rib, separable lean only, trimmed to 1/8"" fat, cooked, roasted","Lamb, Veal, and Game Products",24.63,0.015,0.082,0,0,0.302,0,0,11.6,63.78,210,5.072,4.503,0.429,33048
"Lamb, Australian, imported, fresh, rib, separable lean only, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",20.12,0.01,0.081,0,0,0.305,0,0,8.2,71.17,160,3.533,3.242,0.32,33049
"Lamb, Australian, imported, fresh, separable fat, cooked","Lamb, Veal, and Game Products",9.42,0.027,0.051,0,0,0.179,0,0,66.4,24,639,34.751,26.377,2.331,33050
"Lamb, Australian, imported, fresh, separable fat, raw","Lamb, Veal, and Game Products",6.27,0.019,0.033,0,0,0.112,0,0,68.87,25.08,648,35.353,27.975,2.844,33051
"Lamb, Australian, imported, fresh, shoulder ,blade, separable lean only, trimmed to 1/8"" fat, cooked, broiled","Lamb, Veal, and Game Products",23.83,0.028,0.094,0,0,0.315,0,0,14.38,62.25,231,6.334,5.589,0.559,33052
"Lamb, Australian, imported, fresh, shoulder, arm, separable lean and fat, trimmed to 1/8"" fat, cooked, braised","Lamb, Veal, and Game Products",29.7,0.027,0.073,0,0,0.268,0,0,20.38,51.03,311,9.696,8.173,0.83,33053
"Lamb, Australian, imported, fresh, shoulder, arm, separable lean and fat, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",17.06,0.016,0.072,0,0,0.281,0,0,18.89,63.82,243,9.125,7.697,0.777,33054
"Lamb, Australian, imported, fresh, shoulder, arm, separable lean only, trimmed to 1/8"" fat, cooked, braised","Lamb, Veal, and Game Products",34.17,0.027,0.078,0,0,0.287,0,0,10.23,56.98,238,4.173,4.16,0.499,33055
"Lamb, Australian, imported, fresh, shoulder, arm, separable lean only, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",19.88,0.015,0.083,0,0,0.325,0,0,5.81,73.95,137,2.335,2.424,0.27,33056
"Lamb, Australian, imported, fresh, shoulder, blade, separable lean and fat, trimmed to 1/8"" fat, cooked, broiled","Lamb, Veal, and Game Products",21.71,0.028,0.088,0,0,0.295,0,0,22.03,56.63,291,10.51,8.644,0.82,33057
"Lamb, Australian, imported, fresh, shoulder, blade, separable lean and fat, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",16.48,0.019,0.078,0,0,0.26,0,0,21.28,62.13,262,10.3,8.579,0.864,33058
"Lamb, Australian, imported, fresh, shoulder, blade, separable lean only, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",19.1,0.019,0.09,0,0,0.298,0,0,9.09,71.62,164,3.883,3.611,0.357,33059
"Lamb, Australian, imported, fresh, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/8"" fat, cooked","Lamb, Veal, and Game Products",23.58,0.028,0.085,0,0,0.288,0,0,21.65,55.31,296,10.324,8.537,0.822,33060
"Lamb, Australian, imported, fresh, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",16.68,0.018,0.076,0,0,0.267,0,0,20.47,62.7,256,9.907,8.284,0.84,33061
"Lamb, Australian, imported, fresh, shoulder, whole (arm and blade), separable lean only, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",19.36,0.018,0.088,0,0,0.306,0,0,8.01,72.39,155,3.355,3.214,0.323,33062
"Lamb, Australian, imported, fresh, shoulder, whole (arm and blade), separable lean only, trimmed to 1/8"" fat, cooked","Lamb, Veal, and Game Products",26.18,0.028,0.091,0,0,0.308,0,0,13.44,61.05,233,5.843,5.264,0.546,33063
"Lamb, New Zealand, imported, frozen, composite of trimmed retail cuts, separable fat, cooked","Lamb, Veal, and Game Products",9.72,0.027,0.035,0,0,0.087,0,0,60.39,26.12,586,31.51,23.15,2.52,33064
"Lamb, New Zealand, imported, frozen, composite of trimmed retail cuts, separable fat, raw","Lamb, Veal, and Game Products",6.92,0.023,0.022,0,0,0.051,0,0,67.63,25.56,640,35.29,25.93,2.83,33065
"Lamb, New Zealand, imported, frozen, composite of trimmed retail cuts, separable lean and fat, cooked","Lamb, Veal, and Game Products",24.42,0.017,0.046,0,0,0.162,0,0,22.26,51.15,305,11.05,8.59,1.04,33066
"Lamb, New Zealand, imported, frozen, composite of trimmed retail cuts, separable lean and fat, raw","Lamb, Veal, and Game Products",16.74,0.013,0.039,0,0,0.136,0,0,22.74,59.8,277,11.57,8.72,0.98,33067
"Lamb, New Zealand, imported, frozen, composite of trimmed retail cuts, separable lean only, cooked","Lamb, Veal, and Game Products",29.59,0.013,0.05,0,0,0.188,0,0,8.86,59.95,206,3.86,3.48,0.52,33068
"Lamb, New Zealand, imported, frozen, composite of trimmed retail cuts, separable lean only, raw","Lamb, Veal, and Game Products",20.75,0.008,0.046,0,0,0.171,0,0,4.41,73.78,128,1.88,1.69,0.22,33069
"Lamb, New Zealand, imported, frozen, foreshank, separable lean and fat, cooked, braised","Lamb, Veal, and Game Products",26.97,0.014,0.047,0,0,0.118,0,0,15.83,56.76,258,7.82,6.1,0.73,33070
"Lamb, New Zealand, imported, frozen, foreshank, separable lean and fat, raw","Lamb, Veal, and Game Products",18.04,0.01,0.045,0,0,0.131,0,0,16.15,66.02,223,8.18,6.19,0.7,33071
"Lamb, New Zealand, imported, frozen, foreshank, separable lean only, cooked, braised","Lamb, Veal, and Game Products",30.76,0.01,0.049,0,0,0.125,0,0,6.04,63.49,186,2.61,2.35,0.34,33072
"Lamb, New Zealand, imported, frozen, foreshank, separable lean only, raw","Lamb, Veal, and Game Products",20.82,0.007,0.05,0,0,0.15,0,0,3.28,76.13,118,1.4,1.26,0.17,33073
"Lamb, New Zealand, imported, frozen, leg, whole (shank and sirloin), separable lean and fat, cooked, roasted","Lamb, Veal, and Game Products",24.81,0.01,0.043,0,0,0.167,0,0,15.56,57.87,246,7.61,6.01,0.75,33074
"Lamb, New Zealand, imported, frozen, leg, whole (shank and sirloin), separable lean and fat, raw","Lamb, Veal, and Game Products",18.34,0.008,0.04,0,0,0.156,0,0,15.29,65.58,216,7.68,5.86,0.67,33075
"Lamb, New Zealand, imported, frozen, leg, whole (shank and sirloin), separable lean only, cooked, roasted","Lamb, Veal, and Game Products",27.68,0.007,0.045,0,0,0.183,0,0,7.01,63.92,181,3.05,2.75,0.41,33076
"Lamb, New Zealand, imported, frozen, leg, whole (shank and sirloin), separable lean only, raw","Lamb, Veal, and Game Products",20.85,0.004,0.044,0,0,0.179,0,0,3.8,74.37,123,1.62,1.46,0.19,33077
"Lamb, New Zealand, imported, frozen, loin, separable lean and fat, cooked, broiled","Lamb, Veal, and Game Products",23.43,0.023,0.049,0,0,0.159,0,0,23.88,50.44,315,11.96,9.2,1.09,33078
"Lamb, New Zealand, imported, frozen, loin, separable lean and fat, raw","Lamb, Veal, and Game Products",16.33,0.017,0.037,0,0,0.121,0,0,25.9,57.09,303,13.24,9.93,1.11,33079
"Lamb, New Zealand, imported, frozen, loin, separable lean only, cooked, broiled","Lamb, Veal, and Game Products",29.31,0.021,0.055,0,0,0.189,0,0,8.24,60.86,199,3.58,3.22,0.47,33080
"Lamb, New Zealand, imported, frozen, loin, separable lean only, raw","Lamb, Veal, and Game Products",21.17,0.015,0.045,0,0,0.157,0,0,4.4,73.33,130,1.87,1.69,0.22,33081
"Lamb, New Zealand, imported, frozen, rib, separable lean and fat, cooked, roasted","Lamb, Veal, and Game Products",18.98,0.019,0.043,0,0,0.124,0,0,28.75,50.18,340,14.45,11.08,1.31,33082
"Lamb, New Zealand, imported, frozen, rib, separable lean and fat, raw","Lamb, Veal, and Game Products",14.92,0.016,0.04,0,0,0.113,0,0,31.31,53.32,346,15.99,12,1.34,33083
"Lamb, New Zealand, imported, frozen, rib, separable lean only, cooked, roasted","Lamb, Veal, and Game Products",24.42,0.014,0.048,0,0,0.146,0,0,10.16,64.31,196,4.43,3.99,0.59,33084
"Lamb, New Zealand, imported, frozen, rib, separable lean only, raw","Lamb, Veal, and Game Products",20.49,0.011,0.052,0,0,0.156,0,0,6.06,72.61,142,2.58,2.32,0.31,33085
"Lamb, New Zealand, imported, frozen, shoulder, whole (arm and blade), separable lean and fat, cooked, braised","Lamb, Veal, and Game Products",28.21,0.027,0.051,0,0,0.147,0,0,26.27,42.64,357,12.74,10.22,1.33,33086
"Lamb, New Zealand, imported, frozen, shoulder, whole (arm and blade), separable lean and fat, raw","Lamb, Veal, and Game Products",16.65,0.017,0.041,0,0,0.133,0,0,22.23,60.11,272,11.22,8.52,0.96,33087
"Lamb, New Zealand, imported, frozen, shoulder, whole (arm and blade), separable lean only, cooked, braised","Lamb, Veal, and Game Products",34.06,0.027,0.056,0,0,0.166,0,0,15.5,47.86,285,6.81,6.14,0.95,33088
"Lamb, New Zealand, imported, frozen, shoulder, whole (arm and blade), separable lean only, raw","Lamb, Veal, and Game Products",20.25,0.015,0.047,0,0,0.163,0,0,5.43,72.89,135,2.31,2.08,0.28,33089
"Lamb, domestic, composite of trimmed retail cuts, separable fat, trimmed to 1/4"" fat, choice, cooked","Lamb, Veal, and Game Products",12.16,0.023,0.058,0,0,0.194,0,0,59.18,26.14,586,27.02,24.4,4.48,33090
"Lamb, domestic, composite of trimmed retail cuts, separable fat, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",6.65,0.019,0.031,0,0,0.082,0,0,70.61,22.54,665,32.24,29.11,5.35,33091
"Lamb, domestic, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/4"" fat, choice, cooked","Lamb, Veal, and Game Products",24.52,0.017,0.072,0,0,0.31,0,0,20.94,53.72,294,8.83,8.82,1.51,33092
"Lamb, domestic, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",16.88,0.012,0.058,0,0,0.23,0,0,21.59,60.7,267,9.47,8.86,1.7,33093
"Lamb, domestic, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8"" fat, choice, cooked","Lamb, Veal, and Game Products",25.51,0.016,0.072,0,0,0.318,0,0,18.01,55.82,271,7.45,7.63,1.29,33094
"Lamb, domestic, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8"" fat, choice, raw","Lamb, Veal, and Game Products",17.54,0.012,0.059,0,0,0.239,0,0,18.66,63.17,243,8.07,7.65,1.48,33095
"Lamb, domestic, composite of trimmed retail cuts, separable lean only, trimmed to 1/4"" fat, choice, cooked","Lamb, Veal, and Game Products",28.22,0.015,0.076,0,0,0.344,0,0,9.52,61.96,206,3.4,4.17,0.62,33096
"Lamb, domestic, composite of trimmed retail cuts, separable lean only, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",20.29,0.01,0.066,0,0,0.28,0,0,5.25,73.42,134,1.88,2.11,0.48,33097
"Lamb, domestic, cubed for stew or kabob (leg and shoulder), separable lean only, trimmed to 1/4"" fat, cooked, braised","Lamb, Veal, and Game Products",33.69,0.015,0.07,0,0,0.26,0,0,8.8,56.23,223,3.15,3.54,0.81,33098
"Lamb, domestic, cubed for stew or kabob (leg and shoulder), separable lean only, trimmed to 1/4"" fat, cooked, broiled","Lamb, Veal, and Game Products",28.08,0.013,0.076,0,0,0.335,0,0,7.33,63.53,186,2.62,2.95,0.67,33099
"Lamb, domestic, cubed for stew or kabob (leg and shoulder), separable lean only, trimmed to 1/4"" fat, raw","Lamb, Veal, and Game Products",20.21,0.009,0.065,0,0,0.284,0,0,5.28,73.74,134,1.89,2.13,0.48,33100
"Lamb, domestic, foreshank, separable lean and fat, trimmed to 1/4"" fat, choice, cooked, braised","Lamb, Veal, and Game Products",28.37,0.02,0.072,0,0,0.257,0,0,13.46,56.8,243,5.63,5.68,0.97,33101
"Lamb, domestic, foreshank, separable lean and fat, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",18.91,0.011,0.072,0,0,0.214,0,0,13.38,67.01,201,5.83,5.49,1.06,33102
"Lamb, domestic, foreshank, separable lean and fat, trimmed to 1/8"" fat, choice, raw","Lamb, Veal, and Game Products",18.91,0.011,0.072,0,0,0.214,0,0,13.38,67.01,201,5.83,5.49,1.06,33103
"Lamb, domestic, foreshank, separable lean and fat, trimmed to 1/8"" fat, cooked, braised","Lamb, Veal, and Game Products",28.37,0.02,0.072,0,0,0.257,0,0,13.46,56.8,243,5.63,5.68,0.97,33104
"Lamb, domestic, foreshank, separable lean only, trimmed to 1/4"" fat, choice, cooked, braised","Lamb, Veal, and Game Products",31.01,0.02,0.074,0,0,0.267,0,0,6.02,61.79,187,2.15,2.64,0.39,33105
"Lamb, domestic, foreshank, separable lean only, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",21.08,0.009,0.079,0,0,0.237,0,0,3.29,74.86,120,1.18,1.32,0.3,33106
"Lamb, domestic, leg, shank half, separable lean and fat, trimmed to 1/4"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",26.41,0.01,0.065,0,0,0.326,0,0,12.45,60.66,225,5.09,5.29,0.88,33107
"Lamb, domestic, leg, shank half, separable lean and fat, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",18.58,0.008,0.057,0,0,0.261,0,0,13.49,67.17,201,5.8,5.53,1.08,33108
"Lamb, domestic, leg, shank half, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",26.73,0.009,0.065,0,0,0.329,0,0,11.4,61.43,217,4.6,4.86,0.8,33109
"Lamb, domestic, leg, shank half, separable lean and fat, trimmed to 1/8"" fat, choice, raw","Lamb, Veal, and Game Products",18.99,0.007,0.058,0,0,0.267,0,0,11.5,68.73,185,4.88,4.7,0.93,33110
"Lamb, domestic, leg, shank half, separable lean only, trimmed to 1/4"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",28.17,0.008,0.066,0,0,0.342,0,0,6.67,64.92,180,2.38,2.92,0.44,33111
"Lamb, domestic, leg, shank half, separable lean only, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",20.52,0.006,0.061,0,0,0.29,0,0,4.19,74.44,125,1.5,1.69,0.38,33112
"Lamb, domestic, leg, sirloin half, separable lean and fat, trimmed to 1/4"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",24.63,0.011,0.068,0,0,0.301,0,0,20.67,54.13,292,8.74,8.71,1.49,33113
"Lamb, domestic, leg, sirloin half, separable lean and fat, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",16.94,0.01,0.056,0,0,0.231,0,0,22.11,60.3,272,9.73,9.08,1.73,33114
"Lamb, domestic, leg, sirloin half, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",24.95,0.011,0.068,0,0,0.304,0,0,19.67,54.85,284,8.26,8.3,1.42,33115
"Lamb, domestic, leg, sirloin half, separable lean and fat, trimmed to 1/8"" fat, choice, raw","Lamb, Veal, and Game Products",17.21,0.01,0.056,0,0,0.235,0,0,20.8,61.32,261,9.12,8.54,1.64,33116
"Lamb, domestic, leg, sirloin half, separable lean only, trimmed to 1/4"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",28.35,0.008,0.071,0,0,0.333,0,0,9.17,62.49,204,3.28,4.02,0.6,33117
"Lamb, domestic, leg, sirloin half, separable lean only, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",20.55,0.007,0.064,0,0,0.284,0,0,5.08,73.57,134,1.82,2.04,0.46,33118
"Lamb, domestic, leg, whole (shank and sirloin), separable lean and fat, trimmed to 1/4"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",25.55,0.011,0.066,0,0,0.313,0,0,16.48,57.48,258,6.89,6.96,1.18,33119
"Lamb, domestic, leg, whole (shank and sirloin), separable lean and fat, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",17.91,0.009,0.056,0,0,0.249,0,0,17.07,64.32,230,7.43,7,1.35,33120
"Lamb, domestic, leg, whole (shank and sirloin), separable lean and fat, trimmed to 1/8"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",26.2,0.01,0.067,0,0,0.319,0,0,14.42,58.99,242,5.92,6.12,1.02,33121
"Lamb, domestic, leg, whole (shank and sirloin), separable lean and fat, trimmed to 1/8"" fat, choice, raw","Lamb, Veal, and Game Products",18.47,0.008,0.057,0,0,0.258,0,0,14.42,66.38,209,6.21,5.91,1.15,33122
"Lamb, domestic, leg, whole (shank and sirloin), separable lean only, trimmed to 1/4"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",28.3,0.008,0.068,0,0,0.338,0,0,7.74,63.89,191,2.76,3.39,0.51,33123
"Lamb, domestic, leg, whole (shank and sirloin), separable lean only, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",20.56,0.006,0.062,0,0,0.289,0,0,4.51,74.11,128,1.61,1.81,0.41,33124
"Lamb, domestic, loin, separable lean and fat, trimmed to 1/4"" fat, choice, cooked, broiled","Lamb, Veal, and Game Products",25.17,0.02,0.077,0,0,0.327,0,0,23.08,51.57,316,9.83,9.7,1.68,33125
"Lamb, domestic, loin, separable lean and fat, trimmed to 1/4"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",22.55,0.018,0.064,0,0,0.246,0,0,23.59,52.5,309,10.24,9.68,1.87,33126
"Lamb, domestic, loin, separable lean and fat, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",16.32,0.015,0.056,0,0,0.214,0,0,26.63,56.55,310,11.76,10.94,2.08,33127
"Lamb, domestic, loin, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, broiled","Lamb, Veal, and Game Products",26.06,0.02,0.078,0,0,0.336,0,0,20.61,53.31,297,8.65,8.69,1.48,33128
"Lamb, domestic, loin, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",23.27,0.018,0.064,0,0,0.25,0,0,21.12,54.33,290,9.08,8.65,1.69,33129
"Lamb, domestic, loin, separable lean and fat, trimmed to 1/8"" fat, choice, raw","Lamb, Veal, and Game Products",17.18,0.014,0.059,0,0,0.226,0,0,22.75,59.55,279,9.95,9.34,1.79,33130
"Lamb, domestic, loin, separable lean only, trimmed to 1/4"" fat, choice, cooked, broiled","Lamb, Veal, and Game Products",29.99,0.019,0.084,0,0,0.376,0,0,9.73,60.98,216,3.48,4.26,0.64,33131
"Lamb, domestic, loin, separable lean only, trimmed to 1/4"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",26.59,0.017,0.066,0,0,0.267,0,0,9.76,62.76,202,3.72,3.95,0.86,33132
"Lamb, domestic, loin, separable lean only, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",20.88,0.012,0.068,0,0,0.276,0,0,5.94,72.55,143,2.13,2.39,0.54,33133
"Lamb, domestic, rib, separable lean and fat, trimmed to 1/4"" fat, choice, cooked, broiled","Lamb, Veal, and Game Products",22.13,0.019,0.076,0,0,0.27,0,0,29.59,47.06,361,12.7,12.12,2.37,33134
"Lamb, domestic, rib, separable lean and fat, trimmed to 1/4"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",21.12,0.022,0.073,0,0,0.271,0,0,29.82,47.89,359,12.77,12.52,2.17,33135
"Lamb, domestic, rib, separable lean and fat, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",14.52,0.015,0.056,0,0,0.19,0,0,34.39,50.8,372,15.16,14.13,2.69,33136
"Lamb, domestic, rib, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, broiled","Lamb, Veal, and Game Products",23.06,0.018,0.077,0,0,0.277,0,0,26.82,49.03,340,11.36,10.97,2.17,33137
"Lamb, domestic, rib, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",21.82,0.022,0.074,0,0,0.277,0,0,27.53,49.59,341,11.66,11.59,1.99,33138
"Lamb, domestic, rib, separable lean and fat, trimmed to 1/8"" fat, choice, raw","Lamb, Veal, and Game Products",15.32,0.014,0.058,0,0,0.201,0,0,30.71,53.68,342,13.43,12.6,2.42,33139
"Lamb, domestic, rib, separable lean only, trimmed to 1/4"" fat, choice, cooked, broiled","Lamb, Veal, and Game Products",27.74,0.016,0.085,0,0,0.313,0,0,12.95,58.83,235,4.65,5.21,1.18,33140
"Lamb, domestic, rib, separable lean only, trimmed to 1/4"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",26.16,0.021,0.081,0,0,0.315,0,0,13.31,60.13,232,4.76,5.83,0.87,33141
"Lamb, domestic, rib, separable lean only, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",19.98,0.012,0.072,0,0,0.265,0,0,9.23,70.44,169,3.3,3.71,0.84,33142
"Lamb, domestic, shoulder, arm, separable lean and fat, trimmed to 1/4"" fat, choice, cooked, braised","Lamb, Veal, and Game Products",30.39,0.025,0.072,0,0,0.306,0,0,24,44.22,346,9.87,10.18,1.71,33143
"Lamb, domestic, shoulder, arm, separable lean and fat, trimmed to 1/4"" fat, choice, cooked, broiled","Lamb, Veal, and Game Products",24.44,0.018,0.077,0,0,0.309,0,0,19.55,54.69,281,8.37,8.01,1.57,33144
"Lamb, domestic, shoulder, arm, separable lean and fat, trimmed to 1/4"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",22.53,0.018,0.065,0,0,0.259,0,0,20.24,55.92,279,8.74,8.3,1.61,33145
"Lamb, domestic, shoulder, arm, separable lean and fat, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",16.79,0.014,0.06,0,0,0.238,0,0,20.9,61.76,260,9.15,8.58,1.65,33146
"Lamb, domestic, shoulder, arm, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, braised","Lamb, Veal, and Game Products",31.1,0.025,0.072,0,0,0.311,0,0,22.65,44.92,337,9.21,9.63,1.6,33147
"Lamb, domestic, shoulder, arm, separable lean and fat, trimmed to 1/8"" fat, choice, raw","Lamb, Veal, and Game Products",17.19,0.014,0.061,0,0,0.244,0,0,18.94,63.3,244,8.24,7.77,1.5,33148
"Lamb, domestic, shoulder, arm, separable lean and fat, trimmed to 1/8"" fat, choice, roasted","Lamb, Veal, and Game Products",22.93,0.017,0.065,0,0,0.262,0,0,18.75,57.07,267,8.04,7.68,1.5,33149
"Lamb, domestic, shoulder, arm, separable lean and fat, trimmed to 1/8"" fat, cooked, broiled","Lamb, Veal, and Game Products",24.91,0.018,0.078,0,0,0.314,0,0,18.05,55.77,269,7.66,7.38,1.46,33150
"Lamb, domestic, shoulder, arm, separable lean only, trimmed to 1/4"" fat, choice, cooked, braised","Lamb, Veal, and Game Products",35.54,0.026,0.076,0,0,0.338,0,0,14.08,49.32,279,5.03,6.17,0.92,33151
"Lamb, domestic, shoulder, arm, separable lean only, trimmed to 1/4"" fat, choice, cooked, broiled","Lamb, Veal, and Game Products",27.71,0.017,0.082,0,0,0.34,0,0,9.02,62.28,200,3.42,3.65,0.8,33152
"Lamb, domestic, shoulder, arm, separable lean only, trimmed to 1/4"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",25.46,0.016,0.067,0,0,0.277,0,0,9.26,64.32,192,3.59,3.76,0.8,33153
"Lamb, domestic, shoulder, arm, separable lean only, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",19.99,0.012,0.069,0,0,0.287,0,0,5.2,74.14,132,1.86,2.09,0.48,33154
"Lamb, domestic, shoulder, blade, separable lean and fat, trimmed to 1/4"" fat, choice, cooked, braised","Lamb, Veal, and Game Products",28.51,0.027,0.075,0,0,0.243,0,0,24.73,45.18,345,10.29,10.1,2.03,33155
"Lamb, domestic, shoulder, blade, separable lean and fat, trimmed to 1/4"" fat, choice, cooked, broiled","Lamb, Veal, and Game Products",23.08,0.024,0.082,0,0,0.336,0,0,19.94,55.92,278,8.18,8.46,1.42,33156
"Lamb, domestic, shoulder, blade, separable lean and fat, trimmed to 1/4"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",22.25,0.021,0.066,0,0,0.246,0,0,20.61,55.85,281,8.64,8.42,1.68,33157
"Lamb, domestic, shoulder, blade, separable lean and fat, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",16.63,0.017,0.062,0,0,0.229,0,0,20.86,61.9,259,8.93,8.54,1.68,33158
"Lamb, domestic, shoulder, blade, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, braised","Lamb, Veal, and Game Products",28.92,0.027,0.075,0,0,0.244,0,0,23.88,45.65,339,9.88,9.74,1.97,33159
"Lamb, domestic, shoulder, blade, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, broiled","Lamb, Veal, and Game Products",23.48,0.024,0.083,0,0,0.341,0,0,18.5,57.01,267,7.49,7.88,1.3,33160
"Lamb, domestic, shoulder, blade, separable lean and fat, trimmed to 1/8"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",22.62,0.021,0.067,0,0,0.248,0,0,19.19,56.95,270,7.96,7.83,1.58,33161
"Lamb, domestic, shoulder, blade, separable lean and fat, trimmed to 1/8"" fat, choice, raw","Lamb, Veal, and Game Products",17.01,0.017,0.063,0,0,0.234,0,0,18.97,63.39,244,8.04,7.76,1.54,33162
"Lamb, domestic, shoulder, blade, separable lean only, trimmed to 1/4"" fat, choice, cooked, braised","Lamb, Veal, and Game Products",32.35,0.028,0.079,0,0,0.254,0,0,16.64,49.64,288,6.37,6.74,1.46,33163
"Lamb, domestic, shoulder, blade, separable lean only, trimmed to 1/4"" fat, choice, cooked, broiled","Lamb, Veal, and Game Products",25.48,0.024,0.088,0,0,0.368,0,0,11.32,62.46,211,4.04,4.96,0.74,33164
"Lamb, domestic, shoulder, blade, separable lean only, trimmed to 1/4"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",24.61,0.021,0.068,0,0,0.258,0,0,11.57,62.82,209,4.34,4.68,1.03,33165
"Lamb, domestic, shoulder, blade, separable lean only, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",19.29,0.016,0.07,0,0,0.268,0,0,7.63,72.36,151,2.73,3.07,0.7,33166
"Lamb, domestic, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/4"" fat, choice, cooked, braised","Lamb, Veal, and Game Products",28.68,0.025,0.075,0,0,0.248,0,0,24.55,45.2,344,10.34,10.04,2,33167
"Lamb, domestic, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/4"" fat, choice, cooked, broiled","Lamb, Veal, and Game Products",24.42,0.021,0.078,0,0,0.301,0,0,19.26,55.03,278,8.04,7.87,1.58,33168
"Lamb, domestic, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/4"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",22.51,0.02,0.066,0,0,0.251,0,0,19.97,56.26,276,8.44,8.17,1.62,33169
"Lamb, domestic, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",16.58,0.016,0.061,0,0,0.23,0,0,21.45,61.39,264,9.28,8.79,1.71,33170
"Lamb, domestic, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/8"" fat, choice, cooked, braised","Lamb, Veal, and Game Products",29.46,0.027,0.074,0,0,0.261,0,0,23.57,45.46,338,9.71,9.72,1.88,33171
"Lamb, domestic, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/8"" fat, choice, cooked, broiled","Lamb, Veal, and Game Products",23.84,0.023,0.082,0,0,0.335,0,0,18.39,56.7,268,7.53,7.75,1.34,33172
"Lamb, domestic, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/8"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",22.7,0.02,0.066,0,0,0.251,0,0,19.08,56.98,269,7.98,7.79,1.56,33173
"Lamb, domestic, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/8"" fat, choice, raw","Lamb, Veal, and Game Products",17.07,0.016,0.063,0,0,0.237,0,0,18.96,63.36,244,8.1,7.76,1.52,33174
"Lamb, domestic, shoulder, whole (arm and blade), separable lean only, trimmed to 1/4"" fat, choice, cooked, braised","Lamb, Veal, and Game Products",32.81,0.026,0.079,0,0,0.261,0,0,15.89,49.96,283,6.17,6.45,1.38,33175
"Lamb, domestic, shoulder, whole (arm and blade), separable lean only, trimmed to 1/4"" fat, choice, cooked, broiled","Lamb, Veal, and Game Products",27.12,0.021,0.083,0,0,0.324,0,0,10.5,61.38,210,3.88,4.24,0.94,33176
"Lamb, domestic, shoulder, whole (arm and blade), separable lean only, trimmed to 1/4"" fat, choice, cooked, roasted","Lamb, Veal, and Game Products",24.94,0.019,0.068,0,0,0.265,0,0,10.77,63.32,204,4.08,4.36,0.95,33177
"Lamb, domestic, shoulder, whole (arm and blade), separable lean only, trimmed to 1/4"" fat, choice, raw","Lamb, Veal, and Game Products",19.55,0.015,0.07,0,0,0.274,0,0,6.76,72.99,144,2.42,2.72,0.62,33178
"Lamb, ground, cooked, broiled","Lamb, Veal, and Game Products",24.75,0.022,0.081,0,0,0.339,0,0,19.65,55.09,283,8.12,8.32,1.4,33179
"Lamb, ground, raw","Lamb, Veal, and Game Products",16.56,0.016,0.059,0,0,0.222,0,0,23.41,59.47,282,10.19,9.6,1.85,33180
"Lamb, new zealand, imported, frozen, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8"" fat, cooked","Lamb, Veal, and Game Products",25.26,0.015,0.046,0,0,0.161,0,0,17.98,54.8,270,8.76,6.96,0.88,33181
"Lamb, new zealand, imported, frozen, composite of trimmed retail cuts, separable lean and fat, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",17.95,0.011,0.041,0,0,0.147,0,0,17.2,64.03,232,8.64,6.6,0.75,33182
"Lamb, new zealand, imported, frozen, foreshank, separable lean and fat, trimmed to 1/8"" fat, cooked, braised","Lamb, Veal, and Game Products",26.97,0.014,0.047,0,0,0.118,0,0,15.83,56.76,258,7.82,6.1,0.73,33183
"Lamb, new zealand, imported, frozen, foreshank, separable lean and fat, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",18.04,0.01,0.045,0,0,0.131,0,0,16.15,66.02,223,8.18,6.19,0.7,33184
"Lamb, new zealand, imported, frozen, leg, whole (shank and sirloin), separable lean and fat, trimmed to 1/8"" fat, cooked, roasted","Lamb, Veal, and Game Products",25.34,0.009,0.044,0,0,0.17,0,0,13.95,59.01,234,6.75,5.4,0.68,33185
"Lamb, new zealand, imported, frozen, leg, whole (shank and sirloin), separable lean and fat, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",18.76,0.007,0.041,0,0,0.16,0,0,13.37,67.05,201,6.67,5.13,0.59,33186
"Lamb, new zealand, imported, frozen, loin, separable lean and fat, trimmed to 1/8"" fat, cooked, broiled","Lamb, Veal, and Game Products",24.41,0.023,0.05,0,0,0.164,0,0,21.28,52.17,296,10.56,8.2,0.99,33187
"Lamb, new zealand, imported, frozen, loin, separable lean and fat, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",17.18,0.017,0.039,0,0,0.128,0,0,22.1,59.95,273,11.23,8.47,0.95,33188
"Lamb, new zealand, imported, frozen, rib, separable lean and fat, trimmed to 1/8"" fat, cooked, roasted","Lamb, Veal, and Game Products",19.86,0.018,0.044,0,0,0.128,0,0,25.74,52.47,317,12.82,9.93,1.19,33189
"Lamb, new zealand, imported, frozen, rib, separable lean and fat, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",15.87,0.015,0.042,0,0,0.12,0,0,27,56.61,311,13.7,10.35,1.16,33190
"Lamb, new zealand, imported, frozen, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/8"" fat, cooked, braised","Lamb, Veal, and Game Products",29.43,0.027,0.052,0,0,0.151,0,0,24.03,43.73,342,11.5,9.37,1.25,33191
"Lamb, new zealand, imported, frozen, shoulder, whole (arm and blade), separable lean and fat, trimmed to 1/8"" fat, raw","Lamb, Veal, and Game Products",17.19,0.017,0.042,0,0,0.137,0,0,19.74,62,251,9.9,7.57,0.86,33192
"Lamb, variety meats and by-products, brain, cooked, braised","Lamb, Veal, and Game Products",12.55,0.012,0.134,0,0.012,0.205,0,0,10.17,75.73,145,2.6,1.84,1.04,33193
"Lamb, variety meats and by-products, brain, cooked, pan-fried","Lamb, Veal, and Game Products",16.97,0.021,0.157,0,0.023,0.358,0,0,22.19,60.71,273,5.67,4.02,2.28,33194
"Lamb, variety meats and by-products, brain, raw","Lamb, Veal, and Game Products",10.4,0.009,0.112,0,0.016,0.296,0,0,8.58,79.2,122,2.19,1.55,0.88,33195
"Lamb, variety meats and by-products, heart, cooked, braised","Lamb, Veal, and Game Products",24.97,0.014,0.063,0,0.007,0.188,1.93,0,7.91,64.2,185,3.14,2.22,0.77,33196
"Lamb, variety meats and by-products, heart, raw","Lamb, Veal, and Game Products",16.47,0.006,0.089,0,0.005,0.316,0.21,0,5.68,76.71,122,2.25,1.6,0.55,33197
"Lamb, variety meats and by-products, kidneys, cooked, braised","Lamb, Veal, and Game Products",23.65,0.018,0.151,0,0.012,0.178,0.99,0,3.62,70.45,137,1.23,0.77,0.67,33198
"Lamb, variety meats and by-products, kidneys, raw","Lamb, Veal, and Game Products",15.74,0.013,0.156,0,0.011,0.277,0.82,0,2.95,79.23,97,1,0.63,0.55,33199
"Lamb, variety meats and by-products, liver, cooked, braised","Lamb, Veal, and Game Products",30.57,0.008,0.056,0,0.004,0.221,2.53,0,8.81,56.67,220,3.41,1.84,1.31,33200
"Lamb, variety meats and by-products, liver, cooked, pan-fried","Lamb, Veal, and Game Products",25.53,0.009,0.124,0,0.013,0.352,3.78,0,12.65,56.2,238,4.9,2.64,1.89,33201
"Lamb, variety meats and by-products, liver, raw","Lamb, Veal, and Game Products",20.38,0.007,0.07,0,0.004,0.313,1.78,0,5.02,71.37,139,1.94,1.05,0.75,33202
"Lamb, variety meats and by-products, lungs, cooked, braised","Lamb, Veal, and Game Products",19.88,0.012,0.084,0,0.028,0.127,0,0,3.1,75.83,113,1.06,0.8,0.43,33203
"Lamb, variety meats and by-products, lungs, raw","Lamb, Veal, and Game Products",16.7,0.01,0.157,0,0.031,0.238,0,0,2.6,79.7,95,0.89,0.67,0.35,33204
"Lamb, variety meats and by-products, mechanically separated, raw","Lamb, Veal, and Game Products",14.97,0.162,0.059,0,0,0.288,0,0,23.54,58.68,276,11.79,8.45,0.76,33205
"Lamb, variety meats and by-products, pancreas, cooked, braised","Lamb, Veal, and Game Products",22.83,0.012,0.052,0,0.02,0.291,0,0,15.12,59.65,234,6.84,5.45,0.74,33206
"Lamb, variety meats and by-products, pancreas, raw","Lamb, Veal, and Game Products",14.84,0.008,0.075,0,0.018,0.42,0,0,9.82,73.77,152,4.44,3.54,0.48,33207
"Lamb, variety meats and by-products, spleen, cooked, braised","Lamb, Veal, and Game Products",26.46,0.013,0.058,0,0.026,0.248,0,0,4.77,66.38,156,1.58,1.27,0.35,33208
"Lamb, variety meats and by-products, spleen, raw","Lamb, Veal, and Game Products",17.2,0.009,0.084,0,0.023,0.358,0,0,3.1,78.15,101,1.03,0.81,0.23,33209
"Lamb, variety meats and by-products, tongue, cooked, braised","Lamb, Veal, and Game Products",21.57,0.01,0.067,0,0.007,0.158,0,0,20.28,57.86,275,7.83,10,1.25,33210
"Lamb, variety meats and by-products, tongue, raw","Lamb, Veal, and Game Products",15.7,0.009,0.078,0,0.006,0.257,0,0,17.17,66.6,222,6.63,8.46,1.06,33211
"Veal, breast, plate half, boneless, separable lean and fat, cooked, braised","Lamb, Veal, and Game Products",25.93,0.008,0.064,0,0,0.266,0,0,18.95,54.77,282,7.434,9.107,1.224,33212
"Veal, breast, point half, boneless, separable lean and fat, cooked, braised","Lamb, Veal, and Game Products",28.23,0.009,0.066,0,0,0.278,0,0,14.16,57.36,248,5.489,6.698,0.996,33213
"Veal, breast, separable fat, cooked","Lamb, Veal, and Game Products",9.4,0.006,0.049,0,0,0.181,0,0,53.35,36.15,521,21.407,26.41,2.865,33214
"Veal, breast, whole, boneless, separable lean and fat, cooked, braised","Lamb, Veal, and Game Products",26.97,0.009,0.065,0,0,0.272,0,0,16.77,55.95,266,6.55,8.012,1.12,33215
"Veal, breast, whole, boneless, separable lean and fat, raw","Lamb, Veal, and Game Products",17.47,0.007,0.071,0,0,0.286,0,0,14.75,67.67,208,5.896,7.164,0.952,33216
"Veal, breast, whole, boneless, separable lean only, cooked, braised","Lamb, Veal, and Game Products",30.32,0.009,0.068,0,0,0.289,0,0,9.8,59.72,218,3.72,4.508,0.788,33217
"Veal, composite of trimmed retail cuts, separable fat, cooked","Lamb, Veal, and Game Products",9.42,0.004,0.057,0,0,0.173,0,0,66.74,21.65,642,32.39,27.98,3.23,33218
"Veal, composite of trimmed retail cuts, separable fat, raw","Lamb, Veal, and Game Products",6.02,0.007,0.026,0,0,0.107,0,0,67.83,24.77,638,32.92,28.44,3.28,33219
"Veal, composite of trimmed retail cuts, separable lean and fat, cooked","Lamb, Veal, and Game Products",30.1,0.022,0.087,0,0,0.325,0,0,11.39,57.08,231,4.28,4.4,0.8,33220
"Veal, composite of trimmed retail cuts, separable lean and fat, raw","Lamb, Veal, and Game Products",19.35,0.015,0.082,0,0,0.315,0,0,6.77,72.84,144,2.79,2.57,0.48,33221
"Veal, composite of trimmed retail cuts, separable lean only, cooked","Lamb, Veal, and Game Products",31.9,0.024,0.089,0,0,0.338,0,0,6.58,60.16,196,1.84,2.35,0.59,33222
"Veal, composite of trimmed retail cuts, separable lean only, raw","Lamb, Veal, and Game Products",20.2,0.015,0.086,0,0,0.328,0,0,2.87,75.91,112,0.86,0.92,0.3,33223
"Veal, cubed for stew (leg and shoulder), separable lean only, cooked, braised","Lamb, Veal, and Game Products",34.94,0.029,0.093,0,0,0.342,0,0,4.31,59.29,188,1.3,1.39,0.45,33224
"Veal, cubed for stew (leg and shoulder), separable lean only, raw","Lamb, Veal, and Game Products",20.27,0.017,0.083,0,0,0.331,0,0,2.5,76.39,109,0.75,0.8,0.26,33225
"Veal, ground, cooked, broiled","Lamb, Veal, and Game Products",24.38,0.017,0.083,0,0,0.337,0,0,7.56,66.76,172,3.04,2.84,0.55,33226
"Veal, ground, raw","Lamb, Veal, and Game Products",19.35,0.015,0.082,0,0,0.315,0,0,6.77,72.84,144,2.79,2.57,0.48,33227
"Veal, leg (top round), separable lean and fat, cooked, braised","Lamb, Veal, and Game Products",36.16,0.008,0.067,0,0,0.383,0,0,6.33,55.49,211,2.53,2.36,0.47,33228
"Veal, leg (top round), separable lean and fat, cooked, pan-fried, breaded","Lamb, Veal, and Game Products",27.29,0.039,0.454,0.3,0,0.371,9.91,0.57,9.18,51.31,238,3.06,3.39,1.49,33229
"Veal, leg (top round), separable lean and fat, cooked, pan-fried, not breaded","Lamb, Veal, and Game Products",31.75,0.006,0.076,0,0,0.425,0,0,8.35,58.34,211,3.16,3.23,0.58,33230
"Veal, leg (top round), separable lean and fat, cooked, roasted","Lamb, Veal, and Game Products",27.7,0.006,0.068,0,0,0.389,0,0,4.65,66.1,160,1.84,1.73,0.35,33231
"Veal, leg (top round), separable lean and fat, raw","Lamb, Veal, and Game Products",20.98,0.005,0.063,0,0,0.367,0,0,3.08,74.8,117,1.18,1.12,0.24,33232
"Veal, leg (top round), separable lean only, cooked, braised","Lamb, Veal, and Game Products",36.71,0.009,0.067,0,0,0.387,0,0,5.09,56.18,203,1.92,1.84,0.41,33233
"Veal, leg (top round), separable lean only, cooked, pan-fried, breaded","Lamb, Veal, and Game Products",28.41,0.039,0.455,0.2,0,0.383,9.84,0.57,6.27,53.14,216,1.6,2.16,1.35,33234
"Veal, leg (top round), separable lean only, cooked, pan-fried, not breaded","Lamb, Veal, and Game Products",33.17,0.007,0.077,0,0,0.442,0,0,4.62,60.68,183,1.29,1.65,0.41,33235
"Veal, leg (top round), separable lean only, cooked, roasted","Lamb, Veal, and Game Products",28.07,0.006,0.068,0,0,0.393,0,0,3.39,67.01,150,1.22,1.19,0.29,33236
"Veal, leg (top round), separable lean only, raw","Lamb, Veal, and Game Products",21.28,0.005,0.064,0,0,0.372,0,0,1.76,75.82,107,0.53,0.56,0.18,33237
"Veal, loin, separable lean and fat, cooked, braised","Lamb, Veal, and Game Products",30.19,0.028,0.08,0,0,0.28,0,0,17.21,52.02,284,6.73,6.73,1.16,33238
"Veal, loin, separable lean and fat, cooked, roasted","Lamb, Veal, and Game Products",24.8,0.019,0.093,0,0,0.325,0,0,12.32,60.73,217,5.26,4.78,0.81,33239
"Veal, loin, separable lean and fat, raw","Lamb, Veal, and Game Products",18.89,0.016,0.085,0,0,0.304,0,0,9.14,70.38,163,3.88,3.54,0.61,33240
"Veal, loin, separable lean only, cooked, braised","Lamb, Veal, and Game Products",33.57,0.032,0.084,0,0,0.297,0,0,9.15,56.96,226,2.55,3.27,0.82,33241
"Veal, loin, separable lean only, cooked, roasted","Lamb, Veal, and Game Products",26.32,0.021,0.096,0,0,0.34,0,0,6.94,64.59,175,2.58,2.49,0.57,33242
"Veal, loin, separable lean only, raw","Lamb, Veal, and Game Products",20.17,0.017,0.091,0,0,0.324,0,0,3.34,74.89,116,1.01,1.07,0.34,33243
"Veal, rib, separable lean and fat, cooked, braised","Lamb, Veal, and Game Products",32.43,0.022,0.095,0,0,0.306,0,0,12.53,53.33,251,4.95,4.65,0.94,33244
"Veal, rib, separable lean and fat, cooked, roasted","Lamb, Veal, and Game Products",23.96,0.011,0.092,0,0,0.295,0,0,13.96,59.91,228,5.41,5.44,0.95,33245
"Veal, rib, separable lean and fat, raw","Lamb, Veal, and Game Products",18.86,0.013,0.089,0,0,0.29,0,0,9.01,71.15,162,3.71,3.43,0.63,33246
"Veal, rib, separable lean only, cooked, braised","Lamb, Veal, and Game Products",34.44,0.024,0.099,0,0,0.318,0,0,7.81,56.08,218,2.56,2.62,0.74,33247
"Veal, rib, separable lean only, cooked, roasted","Lamb, Veal, and Game Products",25.76,0.012,0.097,0,0,0.311,0,0,7.44,64.64,177,2.08,2.66,0.67,33248
"Veal, rib, separable lean only, raw","Lamb, Veal, and Game Products",19.97,0.014,0.095,0,0,0.306,0,0,3.89,75.18,120,1.17,1.25,0.4,33249
"Veal, shank (fore and hind), separable lean and fat, cooked, braised","Lamb, Veal, and Game Products",31.54,0.033,0.093,0,0,0.305,0,0,6.2,61.74,191,2.078,2.339,0.541,33250
"Veal, shank (fore and hind), separable lean and fat, raw","Lamb, Veal, and Game Products",19.15,0.02,0.084,0,0,0.314,0,0,3.48,76.92,113,1.06,1.257,0.363,33251
"Veal, shank (fore and hind), separable lean only, cooked, braised","Lamb, Veal, and Game Products",32.22,0.034,0.094,0,0,0.309,0,0,4.33,62.98,177,1.14,1.546,0.458,33252
"Veal, shank (fore and hind), separable lean only, raw","Lamb, Veal, and Game Products",19.28,0.02,0.085,0,0,0.316,0,0,2.83,77.45,108,0.738,0.982,0.334,33253
"Veal, shoulder, arm, separable lean and fat, cooked, braised","Lamb, Veal, and Game Products",33.63,0.028,0.087,0,0,0.333,0,0,10.24,55.28,236,3.96,3.99,0.7,33254
"Veal, shoulder, arm, separable lean and fat, cooked, roasted","Lamb, Veal, and Game Products",25.46,0.026,0.09,0,0,0.348,0,0,8.25,64.68,183,3.51,3.2,0.54,33255
"Veal, shoulder, arm, separable lean and fat, raw","Lamb, Veal, and Game Products",19.34,0.021,0.083,0,0,0.327,0,0,5.44,74.05,132,2.26,2.08,0.38,33256
"Veal, shoulder, arm, separable lean only, cooked, braised","Lamb, Veal, and Game Products",35.73,0.03,0.09,0,0,0.347,0,0,5.33,58.2,201,1.49,1.9,0.48,33257
"Veal, shoulder, arm, separable lean only, cooked, roasted","Lamb, Veal, and Game Products",26.13,0.027,0.091,0,0,0.356,0,0,5.81,66.47,164,2.31,2.16,0.43,33258
"Veal, shoulder, arm, separable lean only, raw","Lamb, Veal, and Game Products",20.04,0.022,0.086,0,0,0.339,0,0,2.16,76.64,105,0.65,0.69,0.22,33259
"Veal, shoulder, blade, separable lean and fat, cooked, braised","Lamb, Veal, and Game Products",31.26,0.038,0.098,0,0,0.297,0,0,10.09,56.98,225,3.64,3.85,0.74,33260
"Veal, shoulder, blade, separable lean and fat, cooked, roasted","Lamb, Veal, and Game Products",25.15,0.028,0.1,0,0,0.306,0,0,8.67,65.54,186,3.46,3.24,0.64,33261
"Veal, shoulder, blade, separable lean and fat, raw","Lamb, Veal, and Game Products",19.23,0.023,0.095,0,0,0.289,0,0,5.2,75.15,129,1.94,1.87,0.42,33262
"Veal, shoulder, blade, separable lean only, cooked, braised","Lamb, Veal, and Game Products",32.66,0.04,0.101,0,0,0.305,0,0,6.48,59.24,198,1.81,2.31,0.58,33263
"Veal, shoulder, blade, separable lean only, cooked, roasted","Lamb, Veal, and Game Products",25.64,0.028,0.102,0,0,0.31,0,0,6.88,66.9,171,2.57,2.47,0.56,33264
"Veal, shoulder, blade, separable lean only, raw","Lamb, Veal, and Game Products",19.64,0.023,0.097,0,0,0.295,0,0,3.26,76.71,113,0.98,1.05,0.34,33265
"Veal, shoulder, whole (arm and blade), separable lean and fat, cooked, braised","Lamb, Veal, and Game Products",32.06,0.035,0.095,0,0,0.309,0,0,10.14,56.4,228,3.75,3.9,0.73,33266
"Veal, shoulder, whole (arm and blade), separable lean and fat, cooked, roasted","Lamb, Veal, and Game Products",25.32,0.027,0.096,0,0,0.322,0,0,8.42,65.31,184,3.4,3.16,0.61,33267
"Veal, shoulder, whole (arm and blade), separable lean and fat, raw","Lamb, Veal, and Game Products",19.27,0.022,0.091,0,0,0.302,0,0,5.28,74.78,130,2.05,1.94,0.41,33268
"Veal, shoulder, whole (arm and blade), separable lean only, cooked, braised","Lamb, Veal, and Game Products",33.68,0.037,0.097,0,0,0.319,0,0,6.1,58.88,199,1.7,2.18,0.55,33269
"Veal, shoulder, whole (arm and blade), separable lean only, cooked, roasted","Lamb, Veal, and Game Products",25.81,0.027,0.097,0,0,0.327,0,0,6.62,66.66,170,2.5,2.4,0.53,33270
"Veal, shoulder, whole (arm and blade), separable lean only, raw","Lamb, Veal, and Game Products",19.79,0.022,0.092,0,0,0.311,0,0,3,76.58,112,0.9,0.96,0.31,33271
"Veal, sirloin, separable lean and fat, cooked, braised","Lamb, Veal, and Game Products",31.26,0.017,0.079,0,0,0.321,0,0,13.14,54.49,252,5.18,5.15,0.87,33272
"Veal, sirloin, separable lean and fat, cooked, roasted","Lamb, Veal, and Game Products",25.14,0.013,0.083,0,0,0.351,0,0,10.45,62.66,202,4.51,4.08,0.68,33273
"Veal, sirloin, separable lean and fat, raw","Lamb, Veal, and Game Products",19.07,0.011,0.076,0,0,0.329,0,0,7.81,71.85,152,3.35,3.04,0.51,33274
"Veal, sirloin, separable lean only, cooked, braised","Lamb, Veal, and Game Products",33.96,0.019,0.081,0,0,0.339,0,0,6.51,58.55,204,1.82,2.33,0.58,33275
"Veal, sirloin, separable lean only, cooked, roasted","Lamb, Veal, and Game Products",26.32,0.014,0.085,0,0,0.365,0,0,6.22,65.74,168,2.41,2.28,0.48,33276
"Veal, sirloin, separable lean only, raw","Lamb, Veal, and Game Products",20.2,0.011,0.08,0,0,0.348,0,0,2.59,75.94,110,0.78,0.83,0.27,33277
"Veal, variety meats and by-products, brain, cooked, braised","Lamb, Veal, and Game Products",11.48,0.016,0.156,0,0.013,0.214,0,0,9.63,76.89,136,2.18,1.74,1.49,33278
"Veal, variety meats and by-products, brain, cooked, pan-fried","Lamb, Veal, and Game Products",14.48,0.01,0.176,0,0.015,0.472,0,0,16.75,68.56,213,3.96,4.21,2.44,33279
"Veal, variety meats and by-products, brain, raw","Lamb, Veal, and Game Products",10.32,0.01,0.127,0,0.014,0.315,0,0,8.21,79.78,118,1.91,1.64,0.95,33280
"Veal, variety meats and by-products, heart, cooked, braised","Lamb, Veal, and Game Products",29.12,0.008,0.058,0,0.01,0.199,0.13,0,6.75,62.18,186,1.82,1.42,1.78,33281
"Veal, variety meats and by-products, heart, raw","Lamb, Veal, and Game Products",17.18,0.005,0.077,0,0.008,0.261,0.08,0,3.98,77.69,110,1.07,0.84,1.05,33282
"Veal, variety meats and by-products, kidneys, cooked, braised","Lamb, Veal, and Game Products",26.32,0.029,0.11,0,0.008,0.159,0,0,5.66,67.67,163,1.74,1.24,1.13,33283
"Veal, variety meats and by-products, kidneys, raw","Lamb, Veal, and Game Products",15.76,0.011,0.178,0,0.005,0.272,0.85,0,3.12,79.07,99,0.96,0.68,0.62,33284
"Veal, variety meats and by-products, liver, cooked, braised","Lamb, Veal, and Game Products",28.42,0.006,0.078,0,0.0011,0.329,3.77,0,6.26,59.86,192,1.986,1.146,1.069,33285
"Veal, variety meats and by-products, liver, cooked, pan-fried","Lamb, Veal, and Game Products",27.37,0.007,0.085,0,0.0007,0.353,4.47,0,6.51,59.87,193,2.109,1.194,1.159,33286
"Veal, variety meats and by-products, liver, raw","Lamb, Veal, and Game Products",19.93,0.005,0.077,0,0.0007,0.308,2.91,0,4.85,70.89,140,1.56,0.968,0.829,33287
"Veal, variety meats and by-products, lungs, cooked, braised","Lamb, Veal, and Game Products",18.74,0.007,0.056,0,0.034,0.142,0,0,2.64,77.59,104,0.91,0.68,0.36,33288
"Veal, variety meats and by-products, lungs, raw","Lamb, Veal, and Game Products",16.3,0.007,0.108,0,0.039,0.274,0,0,2.3,80.5,90,0.79,0.59,0.31,33289
"Veal, variety meats and by-products, pancreas, cooked, braised","Lamb, Veal, and Game Products",29.1,0.018,0.068,0,0.006,0.278,0,0,14.6,55.7,256,5.01,5.03,2.72,33290
"Veal, variety meats and by-products, pancreas, raw","Lamb, Veal, and Game Products",15,0.019,0.067,0,0.016,0.278,0,0,13.1,70.6,182,4.51,4.54,2.44,33291
"Veal, variety meats and by-products, spleen, cooked, braised","Lamb, Veal, and Game Products",24.08,0.007,0.058,0,0.04,0.215,0,0,2.89,71.25,129,0.96,0.78,0.21,33292
"Veal, variety meats and by-products, spleen, raw","Lamb, Veal, and Game Products",18.3,0.006,0.097,0,0.041,0.362,0,0,2.2,78.15,98,0.73,0.59,0.16,33293
"Veal, variety meats and by-products, thymus, cooked, braised","Lamb, Veal, and Game Products",22.67,0.004,0.059,0,0.0394,0.435,0,0,3.11,73.63,125,0.942,0.903,0.315,33294
"Veal, variety meats and by-products, thymus, raw","Lamb, Veal, and Game Products",17.21,0.003,0.067,0,0.0492,0.488,0,0,3.07,79.16,101,0.806,0.775,0.229,33295
"Veal, variety meats and by-products, tongue, cooked, braised","Lamb, Veal, and Game Products",25.85,0.009,0.064,0,0.006,0.162,0,0,10.1,64.08,202,4.35,4.61,0.38,33296
"Veal, variety meats and by-products, tongue, raw","Lamb, Veal, and Game Products",17.18,0.007,0.082,0,0.005,0.271,1.91,0,5.48,74.53,131,2.35,2.51,0.31,33297
"ARCHWAY Home Style Cookies, Apple Filled Oatmeal",Baked Products,4.47,0.032,0.331,1.7,0,0.18,67.21,32.38,11.78,14.99,393,2.7,4.76,1.02,33298
"ARCHWAY Home Style Cookies, Apricot Filled",Baked Products,4.52,0.02,0.318,2.2,0,0.135,65.86,30.06,13.17,14.99,400,5.12,4.11,1.1,33299
"ARCHWAY Home Style Cookies, Cherry Filled",Baked Products,4.62,0.025,0.347,2,0,0.138,64.75,31.31,14.12,15.01,405,5.57,4.5,1.09,33300
"ARCHWAY Home Style Cookies, Chocolate Chip Drop",Baked Products,3.95,0.014,0.393,1.6,0,0.095,63.28,28.97,15.33,15.81,407,5.29,5.4,1,33301
"ARCHWAY Home Style Cookies, Chocolate Chip Ice Box",Baked Products,4.28,0.014,0.27,2,0,0.127,65.02,34.06,24.4,5.01,497,8.08,8.94,1.43,33302
"ARCHWAY Home Style Cookies, Coconut Macaroon",Baked Products,3.02,0.005,0.2,5.1,0,0.123,61.23,45.16,22.55,11.5,460,20.1,1.61,0.81,33303
"ARCHWAY Home Style Cookies, Cookies Jar Hermits",Baked Products,5.28,0.058,0.657,1.8,0,0.171,70.33,34.53,9.77,11.82,390,2.31,3.88,0.84,33304
"ARCHWAY Home Style Cookies, Dark Molasses",Baked Products,4.16,0.044,0.555,1.1,0,0.348,69.38,35.05,12.59,9.93,407,2.88,5.02,0.98,33305
"ARCHWAY Home Style Cookies, Date Filled Oatmeal",Baked Products,4.67,0.028,0.331,2.1,0,0.159,68.16,34.27,12.05,13.49,400,2.76,4.82,1.11,33306
"ARCHWAY Home Style Cookies, Dutch Cocoa",Baked Products,4.5,0.013,0.384,2.6,0,0.239,69.44,35.35,14.99,9.32,431,3.62,5.94,1.18,33307
"ARCHWAY Home Style Cookies, Fat Free Devil's Food Cookie",Baked Products,4.82,0.01,0.393,2.8,0,0.269,79.27,41.2,0.9,12.99,338,0.32,0.29,0.28,33308
"ARCHWAY Home Style Cookies, Fat Free Oatmeal Raisin",Baked Products,4.12,0.032,0.576,2.1,0,0.276,79.56,47.07,1.51,12.1,348,0.32,0.58,0.61,33309
"ARCHWAY Home Style Cookies, Frosty Lemon",Baked Products,4.41,0.041,0.449,0.7,0,0.092,64.78,33.35,17.11,12.03,430,5.83,5.75,0.92,33310
"ARCHWAY Home Style Cookies, Fruit & Honey Bar",Baked Products,4.44,0.023,0.413,1.9,0,0.19,69.42,37.51,12.54,11.79,408,2.88,5.09,0.94,33311
"ARCHWAY Home Style Cookies, Gourmet Apple'n Raisin",Baked Products,5.1,0.034,0.527,2.5,0,0.232,66.33,33.97,16.64,9.51,435,3.61,6.36,2.42,33312
"ARCHWAY Home Style Cookies, Gourmet Oatmeal Pecan",Baked Products,5.42,0.025,0.345,3.1,0,0.145,60.48,27.71,23.13,9.28,472,7.58,8.61,2.21,33313
"ARCHWAY Home Style Cookies, Gourmet Rocky Road",Baked Products,4.86,0.018,0.283,2.6,0,0.24,65.94,34.92,19.71,8.02,461,5.13,6.82,3.48,33314
"ARCHWAY Home Style Cookies, Gourmet Ruth's Golden Oatmeal",Baked Products,5.67,0.036,0.391,2.7,0,0.232,64.97,33.74,16.44,10.9,431,3.29,5.67,4.11,33315
"ARCHWAY Home Style Cookies, Iced Molasses",Baked Products,3.5,0.024,0.605,1,0,0.125,69.12,38.43,14.43,10.34,420,4.16,4.75,2.44,33316
"ARCHWAY Home Style Cookies, Iced Oatmeal",Baked Products,4.89,0.032,0.377,2,0,0.185,66.76,37.2,16.53,9.99,435,4.61,5.61,2.72,33317
"ARCHWAY Home Style Cookies, Molasses",Baked Products,4.25,0.029,0.577,1.2,0,0.117,69.41,36.35,12.06,11.43,403,2.81,4.86,1.05,33318
"ARCHWAY Home Style Cookies, Oatmeal",Baked Products,5.48,0.035,0.394,2.9,0,0.219,68.17,34.5,14.08,10.28,421,3.2,5.66,1.33,33319
"ARCHWAY Home Style Cookies, Oatmeal Raisin",Baked Products,5.17,0.038,0.339,2.7,0,0.285,69.27,39.55,12.08,11.53,406,2.75,4.85,1.16,33320
"ARCHWAY Home Style Cookies, Old Fashioned Molasses",Baked Products,4.27,0.032,0.561,1.1,0,0,70.55,36.09,11.83,10.41,406,2.77,4.8,1,33321
"ARCHWAY Home Style Cookies, Old Fashioned Windmill Cookies",Baked Products,5.17,0.036,0.471,1.8,0,0.113,72.23,32.64,17.55,3,468,3.75,7.82,1.67,33322
"ARCHWAY Home Style Cookies, Peanut Butter",Baked Products,9,0.035,0.404,2.8,0,0.209,58.48,32.55,24.28,6.35,480,5.39,10.21,4.23,33323
"ARCHWAY Home Style Cookies, Pecan Ice Box",Baked Products,4.56,0.011,0.317,1.2,0,0.076,61.02,28.23,26.46,6.71,500,5.67,11.55,2.45,33324
"ARCHWAY Home Style Cookies, Raspberry Filled",Baked Products,4.35,0.02,0.334,2.2,0,0.118,65.92,31.76,13.26,14.99,400,5.16,4.14,1.1,33325
"ARCHWAY Home Style Cookies, Reduced Fat Ginger Snaps",Baked Products,4.67,0.062,0.406,1.1,0,0.309,76.23,36.28,11.14,5.22,424,2.6,3.58,1.65,33326
"ARCHWAY Home Style Cookies, Ruth's Oatmeal",Baked Products,5.94,0.031,0.44,2.6,0,0.184,66.15,34.09,15.88,9.99,428,3.53,5.63,2.37,33327
"ARCHWAY Home Style Cookies, Soft Sugar Drop",Baked Products,4.82,0.039,0.437,0.8,0,0.098,63.26,30.79,13.87,16.39,397,3.33,5.71,1.02,33328
"ARCHWAY Home Style Cookies, Strawberry Filled",Baked Products,4.35,0.02,0.377,2.2,0,0.118,65.92,31.76,13.26,14.99,400,5.16,4.14,1.1,33329
"ARCHWAY Home Style Cookies, Sugar",Baked Products,4.88,0.025,0.64,1.5,0,0.088,69.02,0,12.8,10.62,411,2.96,5.04,1.2,33330
"ARCHWAY Home Style Cookies, Sugar Free Chocolate Chip",Baked Products,4.51,0.261,0.267,1.1,0,0.076,66.11,0.9,22.71,4.99,487,6.3,9.13,1.35,33331
"ARCHWAY Home Style Cookies, Sugar Free Oatmeal",Baked Products,5.54,0.02,0.308,1.9,0,0.087,67.2,1.26,20.92,5,442,4.9,7.86,1.31,33332
"ARCHWAY Home Style Cookies, Sugar Free Rocky Road",Baked Products,5.87,0.174,0.282,2.8,0,0.257,64.41,0.71,20.52,7.3,466,5.05,7.19,4.16,33333
"Artificial Blueberry Muffin Mix,dry",Baked Products,4.7,0,0.76,0,0,0,77.45,0,8.7,6.4,407,1.865,3.742,1.679,33334
"Bagels, cinnamon-raisin",Baked Products,9.8,0.019,0.433,2.3,0.0007,0.148,55.2,5.98,1.7,32,273,0.274,0.175,0.671,33335
"Bagels, cinnamon-raisin, toasted",Baked Products,10.6,0.02,0.346,2.5,0.0006,0.163,59.3,6.43,1.8,26.9,294,0.295,0.188,0.722,33336
"Bagels, egg",Baked Products,10.6,0.013,0.505,2.3,0.0006,0.068,53,0,2.1,32.7,278,0.421,0.42,0.642,33337
"Bagels, oat bran",Baked Products,10.7,0.012,0.59,3.6,0.0002,0.115,53.3,1.63,1.2,32.9,255,0.191,0.249,0.486,33338
"Bagels, plain, enriched, with calcium propionate (includes onion, poppy, sesame)",Baked Products,10.02,0.089,0.517,2.2,0.001,0.075,50.5,5.05,1.62,36.28,257,0.393,0.521,0.64,33339
"Bagels, plain, enriched, without calcium propionate (includes onion, poppy, sesame)",Baked Products,10.5,0.018,0.534,2.3,0,0.101,53.4,0,1.6,32.6,275,0.22,0.131,0.696,33340
"Bagels, plain, toasted, enriched, with calcium propionate (includes onion, poppy, sesame)",Baked Products,11.14,0.099,0.48,2.6,0,0.085,57.11,5.82,1.72,28.27,288,0.283,0.418,0.944,33341
"Bagels, plain, unenriched, with calcium propionate (includes onion, poppy, sesame)",Baked Products,10.5,0.074,0.534,2.3,0,0.101,53.4,0,1.6,32.6,275,0.22,0.131,0.696,33342
"Bagels, plain, unenriched, without calcium propionate(includes onion, poppy, sesame)",Baked Products,10.5,0.018,0.534,2.3,0,0.101,53.4,0,1.6,32.6,275,0.22,0.131,0.696,33343
"Biscuits, mixed grain, refrigerated dough",Baked Products,6.1,0.017,0.67,0,0,0.456,47.4,0,5.6,37.8,263,1.366,2.932,0.88,33344
"Biscuits, plain or buttermilk, commercially baked",Baked Products,6.2,0.049,0.788,1.3,0,0.224,48.5,3.48,16.5,26.7,365,2.49,6.906,6.209,33345
"Biscuits, plain or buttermilk, dry mix",Baked Products,8,0.179,1.012,2.1,0.0003,0.163,63.3,11.65,15.4,9.2,428,3.965,8.584,2.011,33346
"Biscuits, plain or buttermilk, dry mix, prepared",Baked Products,7.3,0.185,0.955,1.8,0.0004,0.188,48.4,0,12.1,28.9,335,2.789,4.206,4.305,33347
"Biscuits, plain or buttermilk, prepared from recipe",Baked Products,7,0.235,0.58,1.5,0.0002,0.121,44.6,2.18,16.3,28.9,353,4.324,6.93,4.163,33348
"Biscuits, plain or buttermilk, refrigerated dough, higher fat",Baked Products,6.66,0.051,0.999,0.7,0,0.162,43.27,7.4,13.63,33.15,322,3.519,8.226,0.854,33349
"Biscuits, plain or buttermilk, refrigerated dough, higher fat, baked",Baked Products,7.38,0.054,1.08,0.9,0,0.177,46.63,8.02,15.09,27.33,352,3.823,8.963,0.937,33350
"Biscuits, plain or buttermilk, refrigerated dough, lower fat",Baked Products,6.7,0.017,0.987,1.6,0,0.159,47.6,7,4.5,37.9,257,1.113,2.405,0.671,33351
"Biscuits, plain or buttermilk, refrigerated dough, lower fat, baked",Baked Products,7.8,0.019,1.451,1.9,0,0.185,55.4,8.14,5.2,27.7,300,1.294,2.797,0.78,33352
"Bread crumbs, dry, grated, plain",Baked Products,13.35,0.183,0.732,4.5,0,0.196,71.98,6.2,5.3,6.51,395,1.203,1.023,2.06,33353
"Bread crumbs, dry, grated, seasoned",Baked Products,14.13,0.182,1.759,4.9,0.0027,0.231,68.49,5.74,5.48,6.35,383,1.391,1.168,2.3,33354
"Bread sticks, plain",Baked Products,12,0.022,0.657,3,0,0.124,68.4,1.26,9.5,6.1,412,1.41,3.568,3.628,33355
"Bread stuffing, bread, dry mix",Baked Products,11,0.097,1.405,3.2,0,0.246,76.2,8.27,3.4,4.2,386,0.845,1.493,0.713,33356
"Bread stuffing, bread, dry mix, prepared",Baked Products,3.2,0.032,0.524,2.9,0,0.074,21.7,2.11,8.6,64.8,177,1.734,3.808,2.604,33357
"Bread stuffing, cornbread, dry mix",Baked Products,10,0.078,1.283,14.3,0.0035,0.204,76.7,4.77,4.2,4.6,389,0.922,1.662,1.073,33358
"Bread stuffing, cornbread, dry mix, prepared",Baked Products,2.9,0.026,0.455,2.9,0.0008,0.062,21.9,3.92,8.8,64.9,179,1.755,3.856,2.706,33359
"Bread, Multi-Grain (includes whole-grain)",Baked Products,13.36,0.103,0.42,7.4,0.0001,0.23,43.34,6.39,4.23,36.94,265,0.872,0.76,1.872,33360
"Bread, Multi-Grain, toasted (includes whole-grain)",Baked Products,14.52,0.111,0.457,8.1,0.0001,0.25,47.11,6.94,4.6,31.46,288,0.948,0.826,2.035,33361
"Bread, banana, prepared from recipe, made with margarine",Baked Products,4.3,0.021,0.302,1.1,0.0017,0.134,54.6,0,10.5,29.2,326,2.237,4.48,3.13,33362
"Bread, boston brown, canned",Baked Products,5.2,0.07,0.631,4.7,0,0.318,43.3,2.42,1.5,47.2,195,0.282,0.207,0.564,33363
"Bread, cornbread, dry mix, enriched (includes corn muffin mix)",Baked Products,7,0.057,0.817,6.5,0.0001,0.113,69.5,20.34,12.2,7.8,418,3.091,6.719,1.682,33364
"Bread, cornbread, dry mix, prepared",Baked Products,7.2,0.073,0.778,2.4,0.0001,0.128,48.1,0,10,31.9,314,2.739,5.14,1.224,33365
"Bread, cornbread, dry mix, unenriched (includes corn muffin mix)",Baked Products,7,0.057,1.111,6.5,0.0001,0.113,69.5,0,12.2,7.8,418,3.091,6.719,1.682,33366
"Bread, cornbread, prepared from recipe, made with low fat (2%) milk",Baked Products,6.7,0.249,0.658,0,0.0003,0.147,43.5,0,7.1,39.1,266,1.555,1.83,3.206,33367
"Bread, cracked-wheat",Baked Products,8.7,0.043,0.538,5.5,0,0.177,49.5,0,3.9,35.8,260,0.916,1.902,0.682,33368
"Bread, egg",Baked Products,9.5,0.093,0.412,2.3,0,0.115,47.8,1.78,6,34.7,283,1.593,2.302,1.106,33369
"Bread, egg, toasted",Baked Products,10.5,0.102,0.54,2.5,0,0.126,52.6,1.94,6.6,28.3,315,1.616,2.989,1.149,33370
"Bread, french or vienna (includes sourdough)",Baked Products,11.75,0.044,0.513,2.4,0.0002,0.128,56.44,2.56,1.83,27.81,289,0.497,0.347,0.787,33371
"Bread, french or vienna, toasted (includes sourdough)",Baked Products,13,0.047,0.72,3.1,0.0002,0.14,61.93,3.59,2.14,20.61,319,0.502,0.386,0.83,33372
"Bread, irish soda, prepared from recipe",Baked Products,6.6,0.081,0.398,2.6,0.0008,0.266,56,0,5,30.1,290,1.111,1.999,1.478,33373
"Bread, italian",Baked Products,8.8,0.078,0.584,2.7,0,0.11,50,0.83,3.5,35.7,271,0.855,0.812,1.39,33374
"Bread, oat bran",Baked Products,10.4,0.065,0.407,4.5,0,0.147,39.8,7.7,4.4,44,236,0.697,1.59,1.694,33375
"Bread, oat bran, toasted",Baked Products,11.4,0.071,0.448,4.9,0,0.123,43.7,8.46,4.8,38.5,259,0.766,1.748,1.861,33376
"Bread, oatmeal",Baked Products,8.4,0.066,0.47,4,0,0.142,48.5,8.14,4.4,36.7,269,0.703,1.578,1.702,33377
"Bread, oatmeal, toasted",Baked Products,9.2,0.072,0.651,4.3,0.0003,0.154,52.7,8.84,4.8,31.2,292,0.764,1.715,1.85,33378
"Bread, pan dulce, sweet yeast bread",Baked Products,9.42,0.086,0.228,2.3,0,0.103,56.38,12.5,11.58,21.54,367,2.183,3.499,1.53,33379
"Bread, pita, white, enriched",Baked Products,9.1,0.086,0.536,2.2,0,0.12,55.7,1.3,1.2,32.1,275,0.166,0.105,0.535,33380
"Bread, pita, white, unenriched",Baked Products,9.1,0.086,0.536,2.2,0,0.12,55.7,0,1.2,32.1,275,0.166,0.105,0.535,33381
"Bread, pita, whole-wheat",Baked Products,9.8,0.015,0.532,7.4,0,0.17,55,0.82,2.6,30.6,266,0.41,0.349,1.055,33382
"Bread, pound cake type, pan de torta salvadoran",Baked Products,7.06,0.046,0.39,1.7,0,0.21,51.29,18.1,17.45,22.38,390,3.042,4.411,9.424,33383
"Bread, protein (includes gluten)",Baked Products,12.1,0.124,0.478,3,0,0.322,43.8,1.44,2.2,40,245,0.332,0.183,1.009,33384
"Bread, protein, toasted (includes gluten)",Baked Products,13.2,0.136,0.601,3.3,0,0.346,48.1,1.44,2.4,34,270,0.364,0.201,1.109,33385
"Bread, pumpernickel",Baked Products,8.7,0.068,0.671,6.5,0,0.208,47.5,0.53,3.1,37.9,250,0.437,0.932,1.237,33386
"Bread, pumpernickel, toasted",Baked Products,9.5,0.074,0.738,7.1,0,0.228,52.2,0.58,3.4,31.8,275,0.481,1.024,1.359,33387
"Bread, raisin, enriched",Baked Products,7.9,0.066,0.313,4.3,0.0001,0.227,52.3,5.68,4.4,33.6,274,1.081,2.294,0.679,33388
"Bread, raisin, toasted, enriched",Baked Products,8.6,0.072,0.424,4.7,0.0004,0.246,56.9,6.18,4.8,27.8,297,1.175,2.494,0.738,33389
"Bread, raisin, unenriched",Baked Products,7.9,0.066,0.39,4.3,0.0001,0.227,52.3,0,4.4,33.6,274,1.081,2.294,0.679,33390
"Bread, reduced-calorie, oat bran",Baked Products,8,0.057,0.573,12,0,0.102,41.3,3.52,3.2,46,201,0.445,0.684,1.67,33391
"Bread, reduced-calorie, oat bran, toasted",Baked Products,9.5,0.068,0.418,14.3,0,0.122,49.2,4.2,3.8,35.7,239,0.53,0.814,1.988,33392
"Bread, reduced-calorie, oatmeal",Baked Products,7.6,0.115,0.388,0,0.0002,0.124,43.3,0,3.5,44,210,0.599,0.822,1.351,33393
"Bread, reduced-calorie, rye",Baked Products,9.1,0.076,0.513,12,0.0004,0.098,40.5,2.29,2.9,46,203,0.368,0.672,0.752,33394
"Bread, reduced-calorie, wheat",Baked Products,9.1,0.08,0.511,12,0.0001,0.122,43.6,3.07,2.3,43.2,198,0.344,0.252,0.969,33395
"Bread, reduced-calorie, white",Baked Products,8.7,0.094,0.453,9.7,0.0005,0.076,44.3,4.76,2.5,42.9,207,0.549,1.077,0.559,33396
"Bread, rice bran",Baked Products,8.9,0.069,0.303,4.9,0,0.215,43.5,4.67,4.6,41,243,0.709,1.654,1.76,33397
"Bread, rice bran, toasted",Baked Products,9.7,0.075,0.478,5.3,0,0.212,47.3,5.08,5,35.9,264,0.771,1.798,1.913,33398
"Bread, rye",Baked Products,8.5,0.073,0.66,5.8,0.0004,0.166,48.3,3.85,3.3,37.3,258,0.626,1.311,0.799,33399
"Bread, rye, toasted",Baked Products,9.4,0.08,0.725,6.4,0.0002,0.183,53.1,4.23,3.6,31,284,0.688,1.44,0.879,33400
"Bread, salvadoran sweet cheese (quesadilla salvadorena)",Baked Products,7.12,0.073,0.51,0.7,0,0.35,47.84,24.9,17.12,25.48,374,4.612,6.794,4.428,33401
"Bread, wheat",Baked Products,10.91,0.142,0.521,3.6,0.0002,0.184,47.51,5.75,3.64,35.74,266,0.803,0.835,1.435,33402
"Bread, wheat bran",Baked Products,8.8,0.074,0.486,4,0,0.227,47.8,9.68,3.4,37.8,248,0.779,1.618,0.649,33403
"Bread, wheat germ",Baked Products,9.6,0.089,0.553,2.1,0.0002,0.254,48.3,3.73,2.9,37.1,261,0.657,1.275,0.668,33404
"Bread, wheat germ, toasted",Baked Products,10.7,0.1,0.621,2.3,0.0003,0.285,54.3,4.14,3.3,29.3,293,0.738,1.432,0.751,33405
"Bread, wheat, toasted",Baked Products,12.96,0.165,0.611,4.7,0.0002,0.223,55.77,6.42,4.27,24.23,313,0.989,1.019,1.72,33406
"Bread, white, commercially prepared (includes soft bread crumbs)",Baked Products,7.64,0.151,0.511,2.4,0,0.1,50.61,4.31,3.29,36.44,266,0.717,0.681,1.355,33407
"Bread, white, commercially prepared, low sodium no salt",Baked Products,8.2,0.108,0.027,2.3,0,0.119,49.5,4.31,3.6,36.7,267,0.811,1.612,0.744,33408
"Bread, white, commercially prepared, toasted",Baked Products,9,0.119,0.592,2.5,0,0.131,54.4,4.74,4,30.4,293,0.578,0.796,2.09,33409
"Bread, white, commercially prepared, toasted, low sodium no salt",Baked Products,9,0.119,0.03,0,0,0.131,54.4,0,4,30.4,293,0.892,1.771,0.818,33410
"Bread, white, prepared from recipe, made with low fat (2%) milk",Baked Products,7.9,0.057,0.359,2,0.0002,0.146,49.6,0,5.7,35.3,285,1.178,1.298,2.781,33411
"Bread, white, prepared from recipe, made with nonfat dry milk",Baked Products,7.7,0.032,0.336,2,0,0.111,53.6,0,2.6,34.8,274,0.391,0.527,1.401,33412
"Bread, whole-wheat, commercially prepared",Baked Products,12.95,0.107,0.472,6.8,0,0.248,41.29,5.57,3.35,38.58,247,0.747,1.597,0.6,33413
"Bread, whole-wheat, commercially prepared, toasted",Baked Products,16.27,0.13,0.583,9.2,0,0.326,51.16,5.77,4.07,24.25,306,0.922,1.972,0.74,33414
"Bread, whole-wheat, prepared from recipe",Baked Products,8.4,0.033,0.346,6,0,0.314,51.4,3.84,5.4,32.7,278,0.796,1.158,2.939,33415
"Bread, whole-wheat, prepared from recipe, toasted",Baked Products,9.2,0.036,0.381,6.7,0,0.345,56.4,4.22,5.9,26,305,0.874,1.272,3.23,33416
"Breakfast tart, low fat",Baked Products,3.99,0.044,0.301,1.5,0.0038,0.066,76.8,4.87,5.99,12.04,372,1.393,3.338,0.878,33417
"CONTINENTAL MILLS, KRUSTEAZ Almond Poppyseed Muffin Mix, Artificially Flavored, dry",Baked Products,5.6,0.082,0.59,1.7,0,0,75.6,40,10.3,5.7,418,2.1,0,0,33418
"Cake, angelfood, commercially prepared",Baked Products,5.9,0.14,0.749,1.5,0,0.093,57.8,0,0.8,33.2,258,0.121,0.071,0.367,33419
"Cake, angelfood, dry mix",Baked Products,8.9,0.121,0.488,0.3,0,0.197,85.1,44.2,0.4,2.8,373,0.063,0.038,0.18,33420
"Cake, angelfood, dry mix, prepared",Baked Products,6.1,0.084,0.511,0.2,0,0.135,58.7,30.51,0.3,32.9,257,0.043,0.026,0.124,33421
"Cake, boston cream pie, commercially prepared",Baked Products,2.4,0.023,0.254,1.4,0.0002,0.039,42.9,36.11,8.5,45.4,252,2.445,4.544,1.009,33422
"Cake, carrot, dry mix, pudding-type",Baked Products,5.1,0.172,0.567,0,0.0012,0.169,79.2,0,9.8,3.6,415,1.472,4.036,3.702,33423
"Cake, cherry fudge with chocolate frosting",Baked Products,2.4,0.048,0.261,1.2,0.0136,0.166,38,32.94,12.5,46.1,264,5.066,4.4,2.34,33424
"Cake, chocolate, commercially prepared with chocolate frosting",Baked Products,4.1,0.043,0.334,2.8,0.0001,0.2,54.6,0,16.4,22.9,367,4.771,8.76,1.845,33425
"Cake, chocolate, dry mix, pudding-type",Baked Products,4.6,0.133,0.893,3.5,0.0001,0.337,78.7,46.01,9.2,3.9,396,1.923,3.688,2.985,33426
"Cake, chocolate, dry mix, regular",Baked Products,5.9,0.15,0.825,2.4,0,0.33,73,38.3,15.6,3.1,428,3.264,6.31,5.044,33427
"Cake, chocolate, prepared from recipe without frosting",Baked Products,5.3,0.06,0.315,1.6,0.0002,0.14,53.4,0,15.1,24.4,371,5.43,6.039,2.761,33428
"Cake, fruitcake, commercially prepared",Baked Products,2.9,0.033,0.322,3.7,0.0005,0.153,61.6,29.84,9.1,25.3,324,1.048,4.2,3.323,33429
"Cake, german chocolate, dry mix, pudding-type",Baked Products,4,0.097,0.813,3.5,0,0.149,80.1,49.54,9.5,3.6,401,3.205,3.781,1.823,33430
"Cake, gingerbread, dry mix",Baked Products,4.4,0.094,0.657,1.7,0.0002,0.341,74.6,46.62,13.8,4.4,437,3.46,7.791,1.804,33431
"Cake, gingerbread, prepared from recipe",Baked Products,3.9,0.071,0.327,0,0.0001,0.439,49.2,0,16.4,28,356,4.122,7.124,4.216,33432
"Cake, marble, dry mix, pudding-type",Baked Products,3.4,0.076,0.656,2.9,0,0.123,79.3,57.59,11.7,3.1,416,2.45,4.75,3.779,33433
"Cake, pineapple upside-down, prepared from recipe",Baked Products,3.5,0.12,0.319,0.8,0.0012,0.112,50.5,0,12.1,32.3,319,2.915,5.194,3.282,33434
"Cake, pound, BIMBO Bakeries USA, Panque Casero, home baked style",Baked Products,6.62,0.052,0.357,1,0,0.101,48.94,28.77,21.7,21.29,418,0,0,0,33435
"Cake, pound, commercially prepared, butter",Baked Products,5.5,0.035,0.398,0.5,0,0.119,48.8,0,19.9,24.6,388,11.559,5.9,1.067,33436
"Cake, pound, commercially prepared, fat-free",Baked Products,5.4,0.043,0.341,1.1,0,0.11,61,34.34,1.2,31,283,0.259,0.166,0.424,33437
"Cake, pound, commercially prepared, other than all butter, enriched",Baked Products,5.2,0.064,0.4,1,0.0001,0.106,52.5,0,17.9,23.1,389,4.647,9.935,2.251,33438
"Cake, pound, commercially prepared, other than all butter, unenriched",Baked Products,5.2,0.064,0.4,1,0.0001,0.106,52.5,0,17.9,23.1,389,4.647,9.935,2.251,33439
"Cake, shortcake, biscuit-type, prepared from recipe",Baked Products,6.1,0.205,0.506,0,0.0002,0.106,48.5,0,14.2,28.4,346,3.772,6.045,3.632,33440
"Cake, snack cakes, creme-filled, chocolate with frosting",Baked Products,3.63,0.116,0.332,3.2,0.0019,0.176,60.31,37.76,15.93,18.57,399,4.884,8.525,1.822,33441
"Cake, snack cakes, creme-filled, sponge",Baked Products,3.47,0.024,0.512,1,0.0001,0.071,64.03,37.3,11.54,19.6,374,4.139,4.906,1.86,33442
"Cake, snack cakes, cupcakes, chocolate, with frosting, low-fat",Baked Products,4.3,0.036,0.413,4.3,0,0.224,67.2,0,3.7,22.8,305,1.084,1.849,0.487,33443
"Cake, sponge, commercially prepared",Baked Products,5.4,0.07,0.141,0.5,0,0.099,61.1,36.66,2.7,29.7,289,0.802,0.949,0.448,33444
"Cake, sponge, prepared from recipe",Baked Products,7.3,0.042,0.228,0,0,0.141,57.7,0,4.3,29.4,297,1.301,1.576,0.649,33445
"Cake, white, dry mix, pudding-type, enriched",Baked Products,3.9,0.077,0.665,0.7,0,0.072,81,48.95,9.5,3.5,423,2.34,4.092,2.544,33446
"Cake, white, dry mix, pudding-type, unenriched",Baked Products,3.9,0.077,0.665,0.7,0,0.072,81,0,9.5,3.5,423,2.34,4.092,2.544,33447
"Cake, white, dry mix, regular",Baked Products,4.5,0.192,0.664,0.9,0.0003,0.117,78,54.51,10.9,4,426,1.643,4.585,4.101,33448
"Cake, white, dry mix, special dietary (includes lemon-flavored)",Baked Products,3,0.026,0.26,0,0,0.149,79.6,0,8.4,6,397,1.251,3.48,3.183,33449
"Cake, white, prepared from recipe with coconut frosting",Baked Products,4.4,0.09,0.284,1,0.0001,0.099,63.2,57.39,10.3,20.7,356,3.897,3.692,2.162,33450
"Cake, white, prepared from recipe without frosting",Baked Products,5.4,0.13,0.327,0.8,0.0002,0.095,57.2,35.49,12.4,23.3,357,3.269,5.309,3.148,33451
"Cake, yellow, commercially prepared, with chocolate frosting",Baked Products,3.8,0.037,0.337,1.8,0,0.178,55.4,0,17.4,21.9,379,4.656,9.593,2.113,33452
"Cake, yellow, commercially prepared, with vanilla frosting",Baked Products,3.5,0.062,0.344,0.3,0,0.053,58.8,0,14.5,22.1,373,2.371,6.107,5.15,33453
"Cake, yellow, dry mix, light",Baked Products,4.7,0.155,0.604,1.3,0,0.065,84.1,0,5.5,3.1,404,1.367,2.973,0.796,33454
"Cake, yellow, dry mix, pudding-type",Baked Products,4,0.115,0.687,0.7,0,0.063,79.9,44.21,9.8,4,423,2.472,5.496,1.295,33455
"Cake, yellow, dry mix, regular, enriched",Baked Products,4.4,0.135,0.657,1.1,0.0002,0.082,78.1,43.33,11.6,3.7,432,1.738,4.86,4.378,33456
"Cake, yellow, dry mix, regular, unenriched",Baked Products,4.4,0.135,0.657,1.1,0.0002,0.082,78.1,0,11.6,3.7,432,1.738,4.86,4.378,33457
"Cake, yellow, prepared from recipe without frosting",Baked Products,5.3,0.146,0.343,0.7,0.0002,0.091,53,0,14.6,25.1,361,3.924,6.229,3.57,33458
Cheesecake commercially prepared,Baked Products,5.5,0.051,0.207,0.4,0.0004,0.09,25.5,21.8,22.5,45.6,321,9.921,8.634,1.602,33459
"Cheesecake prepared from mix, no-bake type",Baked Products,5.5,0.172,0.38,1.9,0.0005,0.211,35.5,0,12.7,44.2,274,6.691,4.519,0.808,33460
"Coffeecake, cheese",Baked Products,7,0.059,0.339,1,0.0001,0.289,44.3,0,15.2,32.2,339,5.391,7.129,1.645,33461
"Coffeecake, cinnamon with crumb topping, commercially prepared, enriched",Baked Products,6.8,0.054,0.351,2,0.0003,0.123,46.7,0,23.3,21.9,418,5.797,12.976,3.12,33462
"Coffeecake, cinnamon with crumb topping, commercially prepared, unenriched",Baked Products,6.8,0.054,0.351,2,0.0003,0.123,46.7,0,23.3,21.9,418,5.797,12.976,3.12,33463
"Coffeecake, cinnamon with crumb topping, dry mix",Baked Products,4.8,0.148,0.596,1.8,0.0002,0.087,77.7,42.52,12,3.3,436,2.957,5.172,3.204,33464
"Coffeecake, cinnamon with crumb topping, dry mix, prepared",Baked Products,5.5,0.136,0.421,1.2,0.0002,0.112,52.8,29.53,9.6,30.5,318,1.855,3.851,3.165,33465
"Coffeecake, creme-filled with chocolate frosting",Baked Products,5,0.038,0.323,2,0.0001,0.078,53.8,0,10.8,29.1,331,2.833,5.664,1.465,33466
"Coffeecake, fruit",Baked Products,5.2,0.045,0.385,2.5,0.0008,0.09,51.5,0,10.2,31.7,311,2.495,5.557,1.473,33467
"Cookies, animal crackers (includes arrowroot, tea biscuits)",Baked Products,6.9,0.043,0.482,1.1,0,0.1,74.1,13.93,13.8,3.9,446,3.463,7.666,1.873,33468
"Cookies, brownies, commercially prepared",Baked Products,4.8,0.029,0.257,2.1,0,0.149,63.9,36.61,16.3,13.6,405,4.235,8.965,2.259,33469
"Cookies, brownies, dry mix, regular",Baked Products,4,0.019,0.303,0,0.0003,0.219,76.6,0,14.9,2.8,434,2.519,5.158,6.373,33470
"Cookies, brownies, dry mix, special dietary",Baked Products,2.9,0.012,0.083,4.2,0,0.335,80.4,0,12.5,3.5,426,2.018,5.233,4.577,33471
"Cookies, brownies, dry mix, special dietary, prepared",Baked Products,3.8,0.012,0.094,3.7,0,0.314,71.3,0.09,11.1,13,384,5.096,4.573,0.806,33472
"Cookies, brownies, prepared from recipe",Baked Products,6.2,0.057,0.343,0,0.0003,0.176,50.2,0,29.1,12.6,466,7.319,10.839,9.412,33473
"Cookies, butter, commercially prepared, enriched",Baked Products,6.1,0.029,0.239,0.8,0,0.111,68.9,20.24,18.8,4.6,467,11.051,5.522,0.982,33474
"Cookies, butter, commercially prepared, unenriched",Baked Products,6.1,0.029,0.351,0.8,0,0.111,68.9,0,18.8,4.6,467,11.051,5.522,0.982,33475
"Cookies, chocolate chip, commercially prepared, regular, higher fat, enriched",Baked Products,5.12,0.026,0.344,2.4,0,0.147,63.86,35.14,23.31,6.48,474,9.949,7.279,2.681,33476
"Cookies, chocolate chip, commercially prepared, regular, higher fat, unenriched",Baked Products,5.4,0.025,0.315,2.5,0,0.135,66.8,0,22.6,4.1,481,7.476,11.671,2.367,33477
"Cookies, chocolate chip, commercially prepared, regular, lower fat",Baked Products,5.8,0.019,0.377,3.6,0,0.123,73.3,0,15.4,4.1,453,3.81,6.095,4.649,33478
"Cookies, chocolate chip, commercially prepared, soft-type",Baked Products,4.9,0.02,0.272,2.4,0,0.142,65.05,37.85,20.62,8.35,454,9.949,7.279,2.681,33479
"Cookies, chocolate chip, commercially prepared, special dietary",Baked Products,3.9,0.046,0.011,1.6,0,0.199,73.4,39.81,16.8,5.1,450,4.183,6.73,5.035,33480
"Cookies, chocolate chip, dry mix",Baked Products,4.6,0.043,0.29,0,0,0.208,66.1,0,25.2,3,497,8.316,13.077,2.62,33481
"Cookies, chocolate chip, prepared from recipe, made with butter",Baked Products,5.7,0.038,0.341,0,0.0002,0.221,58.2,0,28.4,5.7,488,14.071,8.241,4.541,33482
"Cookies, chocolate chip, prepared from recipe, made with margarine",Baked Products,5.7,0.039,0.361,2.8,0.0003,0.224,58.4,0,28.3,5.7,488,8.074,10.377,8.41,33483
"Cookies, chocolate chip, refrigerated dough",Baked Products,4.4,0.025,0.209,1.5,0,0.18,61.4,0,20.4,12.7,443,6.729,10.414,2.172,33484
"Cookies, chocolate chip, refrigerated dough, baked",Baked Products,4.9,0.028,0.232,1.7,0,0.2,68.2,0,22.6,3,492,7.759,11.258,2.319,33485
"Cookies, chocolate sandwich, with creme filling, regular",Baked Products,5.61,0.022,0.503,2.8,0,0.215,70.67,41.01,19.78,2.2,469,6.375,8.503,3.554,33486
"Cookies, chocolate sandwich, with creme filling, regular, chocolate-coated",Baked Products,3.6,0.035,0.326,5.2,0,0.24,66.1,63.4,26.4,2,481,7.426,14.584,3.001,33487
"Cookies, chocolate sandwich, with creme filling, special dietary",Baked Products,4.5,0.098,0.243,4.1,0,0.295,67.7,23.53,22.1,4.2,461,3.825,9.228,7.94,33488
"Cookies, chocolate sandwich, with extra creme filling",Baked Products,4.33,0.016,0.351,2.7,0,0.137,68.2,46,24.52,1.65,497,5.454,16.076,2.471,33489
"Cookies, chocolate wafers",Baked Products,6.6,0.031,0.69,3.4,0,0.21,72.4,29.66,14.2,4.5,433,4.241,4.865,4.153,33490
"Cookies, coconut macaroons, prepared from recipe",Baked Products,3.6,0.007,0.247,1.8,0,0.156,72.2,70.65,12.7,10.6,404,11.236,0.549,0.141,33491
"Cookies, fig bars",Baked Products,3.7,0.064,0.35,4.6,0.0003,0.207,70.9,46.36,7.3,16.5,348,1.123,3.003,2.772,33492
"Cookies, fortune",Baked Products,4.2,0.012,0.274,1.6,0,0.041,84,45.42,2.7,8,378,0.669,1.345,0.466,33493
"Cookies, fudge, cake-type (includes trolley cakes)",Baked Products,5,0.034,0.192,2.8,0.0001,0.138,78.3,0,3.7,11.8,349,1.112,1.813,0.466,33494
"Cookies, gingersnaps",Baked Products,5.6,0.077,0.558,2.2,0,0.346,76.9,19.92,9.8,5.3,416,2.451,5.365,1.372,33495
"Cookies, graham crackers, chocolate-coated",Baked Products,5.8,0.058,0.257,3.1,0,0.209,66.5,41.56,23.2,2.6,484,13.38,7.687,1.038,33496
"Cookies, graham crackers, plain or honey (includes cinnamon)",Baked Products,6.9,0.024,0.477,2.8,0,0.135,76.8,31.1,10.1,4.4,423,1.519,4.086,3.832,33497
"Cookies, ladyfingers, with lemon juice and rind",Baked Products,10.6,0.047,0.147,1,0.0037,0.113,59.7,25.39,9.1,19.5,365,3.069,3.754,1.422,33498
"Cookies, ladyfingers, without lemon juice and rind",Baked Products,10.6,0.047,0.147,1,0,0.113,59.7,0,9.1,19.5,365,3.477,4.253,1.611,33499
"Cookies, marshmallow, chocolate-coated (includes marshmallow pies)",Baked Products,4,0.046,0.168,2,0.0001,0.182,67.7,44.8,16.9,10.1,421,4.722,9.345,1.943,33500
"Cookies, molasses",Baked Products,5.6,0.074,0.459,1,0,0.346,73.8,17.6,12.8,5.8,430,3.212,7.13,1.729,33501
"Cookies, oatmeal, commercially prepared, fat-free",Baked Products,5.9,0.04,0.219,7.3,0,0.212,78.6,41.9,1.5,12.5,326,0.314,0.388,0.635,33502
"Cookies, oatmeal, commercially prepared, regular",Baked Products,6.2,0.037,0.383,2.8,0.0005,0.142,68.7,24.66,18.1,5.7,450,4.519,10.025,2.543,33503
"Cookies, oatmeal, commercially prepared, soft-type",Baked Products,6.1,0.09,0.349,2.7,0.0002,0.135,65.7,0,14.7,11,409,3.63,7.988,2.204,33504
"Cookies, oatmeal, commercially prepared, special dietary",Baked Products,4.8,0.054,0.009,2.9,0.0003,0.175,69.9,31.65,18,6.5,449,2.693,7.55,6.784,33505
"Cookies, oatmeal, dry mix",Baked Products,6.5,0.025,0.473,0,0.0001,0.183,67.3,0,19.2,5.3,462,4.755,10.567,2.792,33506
"Cookies, oatmeal, prepared from recipe, with raisins",Baked Products,6.5,0.1,0.538,0,0.0005,0.239,68.4,0,16.2,6.4,435,3.232,6.888,5.034,33507
"Cookies, oatmeal, prepared from recipe, without raisins",Baked Products,6.8,0.105,0.598,0,0.0002,0.182,66.4,0,17.9,6.2,447,3.581,7.669,5.589,33508
"Cookies, oatmeal, refrigerated dough",Baked Products,5.4,0.031,0.294,2.5,0,0.147,59.1,0,18.9,15.3,424,4.751,10.531,2.592,33509
"Cookies, oatmeal, refrigerated dough, baked",Baked Products,6,0.035,0.327,2.8,0.0001,0.163,65.7,0,21,5.8,471,5.339,11.729,2.976,33510
"Cookies, peanut butter sandwich, regular",Baked Products,8.8,0.053,0.368,1.9,0.0001,0.192,65.6,35.44,21.1,2.8,478,4.995,11.191,3.795,33511
"Cookies, peanut butter sandwich, special dietary",Baked Products,10,0.043,0.412,0,0,0.294,50.8,0,34,3.7,535,4.937,15.391,12.026,33512
"Cookies, peanut butter, commercially prepared, regular",Baked Products,9.6,0.035,0.415,1.8,0,0.167,58.9,31.83,23.6,6.1,477,4.485,12.368,5.518,33513
"Cookies, peanut butter, commercially prepared, soft-type",Baked Products,5.3,0.012,0.336,1.7,0,0.107,57.7,0,24.4,11.5,457,6.149,13.844,3.181,33514
"Cookies, peanut butter, prepared from recipe",Baked Products,9,0.039,0.518,0,0.0001,0.231,58.9,0,23.8,5.9,475,4.438,10.832,7.223,33515
"Cookies, peanut butter, refrigerated dough",Baked Products,8.2,0.101,0.397,1.1,0,0.308,52.1,0,25,12.6,458,5.791,13.124,4.787,33516
"Cookies, peanut butter, refrigerated dough, baked",Baked Products,9.1,0.111,0.436,1.2,0,0.338,57.3,0,27.5,4,503,6.199,14.526,4.889,33517
"Cookies, raisin, soft-type",Baked Products,4.1,0.046,0.338,1.2,0.0004,0.14,68,47.56,13.6,13.1,401,3.46,7.649,1.756,33518
"Cookies, shortbread, commercially prepared, pecan",Baked Products,4.9,0.03,0.281,1.8,0,0.073,58.3,0,32.5,3.3,542,8.204,18.628,4.122,33519
"Cookies, shortbread, commercially prepared, plain",Baked Products,6.1,0.035,0.455,1.8,0,0.1,64.5,15.08,24.1,3.7,502,6.106,13.42,3.233,33520
"Cookies, sugar wafers with creme filling, regular",Baked Products,3.84,0.027,0.103,1.6,0,0.117,70.64,42.95,23.24,1.66,502,11.899,7.697,2.651,33521
"Cookies, sugar wafers with creme filling, special dietary",Baked Products,3.1,0.053,0.009,0,0,0.061,66,0,25.7,4.4,502,3.829,10.932,9.684,33522
"Cookies, sugar, commercially prepared, regular (includes vanilla)",Baked Products,5.1,0.021,0.357,0.8,0.0001,0.063,67.9,37.7,21.1,4.8,478,5.435,11.836,2.66,33523
"Cookies, sugar, commercially prepared, special dietary",Baked Products,4.1,0.025,0.003,0.9,0,0.104,76.8,34.17,13,5.7,431,1.872,5.243,4.755,33524
"Cookies, sugar, prepared from recipe, made with margarine",Baked Products,5.9,0.073,0.491,1.2,0.0001,0.077,60,24.86,23.4,8.9,472,4.69,10.189,7.29,33525
"Cookies, sugar, refrigerated dough",Baked Products,4.2,0.081,0.422,0.7,0,0.147,59,21.56,20.7,14.5,436,5.265,11.761,2.62,33526
"Cookies, sugar, refrigerated dough, baked",Baked Products,4.7,0.09,0.468,0.8,0,0.163,65.6,23.96,23.1,5,484,5.906,13.01,2.891,33527
"Cookies, vanilla sandwich with creme filling",Baked Products,4.5,0.027,0.349,1.5,0,0.091,72.1,39.3,20,2.2,483,2.979,8.437,7.551,33528
"Cookies, vanilla wafers, higher fat",Baked Products,4.3,0.025,0.306,2,0,0.107,71.1,0,19.4,4.2,473,4.936,11.076,2.437,33529
"Cookies, vanilla wafers, lower fat",Baked Products,5,0.048,0.388,1.9,0,0.097,73.6,37.5,15.2,5.1,441,3.838,6.544,3.887,33530
Cracker meal,Baked Products,9.3,0.023,0.016,2.6,0,0.115,80.9,0.33,1.7,7.6,383,0.271,0.152,0.722,33531
"Crackers, cheese, low sodium",Baked Products,10.1,0.151,0.458,2.4,0,0.106,58.2,0.28,25.3,3.1,503,9.638,11.852,2.457,33532
"Crackers, cheese, regular",Baked Products,10.1,0.151,0.995,2.4,0,0.145,58.2,0.28,25.3,3.1,503,9.371,12.105,2.472,33533
"Crackers, cheese, sandwich-type with cheese filling",Baked Products,8.92,0.089,0.878,1.9,0.0026,0.295,58.76,12.33,24.41,4.81,490,0,0,0,33534
"Crackers, cheese, sandwich-type with peanut butter filling",Baked Products,12.41,0.05,0.913,3.4,0,0.218,56.74,6.98,25.12,3.28,496,4.406,13.003,5.093,33535
"Crackers, cream, GAMESA SABROSAS",Baked Products,7.01,0.026,1.148,2.4,0,0.129,64.55,9.37,20.37,4.45,484,5.362,10.625,2.45,33536
"Crackers, cream, LA MODERNA RIKIS CREAM CRACKERS",Baked Products,7.19,0.734,0.752,2.4,0,0.117,64.88,10.41,19.5,4.48,422,9.377,7.313,1.276,33537
"Crackers, crispbread, rye",Baked Products,7.9,0.031,0.453,16.5,0,0.319,82.2,1.07,1.3,6.1,366,0.145,0.165,0.557,33538
"Crackers, matzo, egg",Baked Products,12.3,0.04,0.021,2.8,0.0001,0.15,78.6,0,2.1,6.4,391,0.548,0.612,0.473,33539
"Crackers, matzo, egg and onion",Baked Products,10,0.036,0.285,5,0.0002,0.083,77.1,0,3.9,7.2,391,0.936,0.987,1.001,33540
"Crackers, matzo, plain",Baked Products,10,0.013,0,3,0,0.112,83.7,0.29,1.4,4.3,395,0.226,0.127,0.603,33541
"Crackers, matzo, whole-wheat",Baked Products,13.1,0.023,0.002,11.8,0,0.316,78.9,0,1.5,4.8,351,0.243,0.194,0.653,33542
"Crackers, melba toast, plain",Baked Products,12.1,0.093,0.598,6.3,0,0.202,76.6,0.94,3.2,5.1,390,0.445,0.782,1.282,33543
"Crackers, melba toast, plain, without salt",Baked Products,12.1,0.093,0.019,6.3,0,0.202,76.6,0,3.2,5.1,390,0.445,0.782,1.282,33544
"Crackers, melba toast, rye (includes pumpernickel)",Baked Products,11.6,0.078,0.899,8,0,0.193,77.3,0,3.4,4.9,389,0.453,0.907,1.347,33545
"Crackers, melba toast, wheat",Baked Products,12.9,0.043,0.837,7.4,0,0.148,76.4,0,2.3,5.5,374,0.337,0.544,0.91,33546
"Crackers, milk",Baked Products,7.6,0.172,0.592,1.9,0.0002,0.114,69.7,16.44,15.8,4.7,455,2.633,6.479,5.749,33547
"Crackers, rusk toast",Baked Products,13.5,0.027,0.253,0,0,0.245,72.3,0,7.2,5.5,407,1.376,2.755,2.31,33548
"Crackers, rye, sandwich-type with cheese filling",Baked Products,9.2,0.222,1.044,3.6,0.0004,0.342,60.8,0,22.3,3.8,481,5.987,12.236,2.863,33549
"Crackers, rye, wafers, plain",Baked Products,9.6,0.04,0.557,22.9,0.0001,0.495,80.4,1.01,0.9,5,334,0.108,0.152,0.397,33550
"Crackers, rye, wafers, seasoned",Baked Products,9,0.044,0.887,20.9,0.0001,0.454,73.8,0,9.2,4,381,1.287,3.27,3.608,33551
"Crackers, saltines (includes oyster, soda, soup)",Baked Products,9.5,0.021,1.116,2.9,0,0.159,74.34,2.22,8.85,4.03,421,2.001,2.247,4.068,33552
"Crackers, saltines, fat-free, low-sodium",Baked Products,10.5,0.022,0.716,2.7,0,0.115,82.3,0.38,1.6,3.4,393,0.244,0.142,0.685,33553
"Crackers, saltines, low salt (includes oyster, soda, soup)",Baked Products,9.5,0.119,0.198,2.9,0,0.724,74.34,2.22,8.85,4.03,421,2.001,2.247,4.068,33554
"Crackers, saltines, unsalted tops (includes oyster, soda, soup)",Baked Products,9.2,0.119,0.766,3,0,0.128,71.5,0,11.8,4.1,434,2.932,6.422,1.679,33555
"Crackers, snack, GOYA CRACKERS",Baked Products,14.25,0.016,0.665,3.8,0,0.124,64.35,2.35,13.35,5.93,433,6.323,4.697,1.536,33556
"Crackers, standard snack-type, regular",Baked Products,6.6,0.095,0.865,2.1,0,0.111,61.37,7.45,25.83,3.46,504,5.4,6.652,12.597,33557
"Crackers, standard snack-type, regular, low salt",Baked Products,7.4,0.12,0.216,1.6,0,0.355,61,1.79,25.3,3.5,502,3.776,10.64,9.545,33558
"Crackers, standard snack-type, sandwich, with cheese filling",Baked Products,9.3,0.257,0.888,1.9,0.0001,0.429,61.7,3.18,21.1,3.9,477,6.125,11.254,2.567,33559
"Crackers, standard snack-type, sandwich, with peanut butter filling",Baked Products,11.47,0.081,0.901,2.3,0,0.215,58.38,10.5,24.54,2.93,494,4.913,13.776,4.655,33560
"Crackers, wheat, low salt",Baked Products,8.6,0.049,0.19,4.5,0,0.203,64.9,13.04,20.6,3.1,473,5.178,11.448,2.796,33561
"Crackers, wheat, reduced fat",Baked Products,9.34,0.08,0.866,3.4,0,0.21,71.52,15.01,13.37,2.73,444,2.112,3.295,7.055,33562
"Crackers, wheat, regular",Baked Products,8.95,0.081,0.918,3.7,0,0.207,67.35,13.8,17.36,3.18,456,2.73,4.297,9.225,33563
"Crackers, wheat, sandwich, with cheese filling",Baked Products,9.8,0.204,0.913,3.1,0.0015,0.306,58.2,0,25,3.2,497,4.129,10.351,9.166,33564
"Crackers, wheat, sandwich, with peanut butter filling",Baked Products,13.5,0.17,0.807,4.4,0,0.297,53.8,0,26.7,3.4,495,4.602,11.766,8.862,33565
"Crackers, whole-wheat",Baked Products,10.58,0.036,0.704,10.3,0,0.345,69.55,1.19,14.13,2.94,427,2.063,3.248,6.945,33566
"Crackers, whole-wheat, low salt",Baked Products,8.8,0.05,0.186,10.5,0,0.297,68.6,0.37,17.2,2.7,443,3.393,5.88,6.596,33567
"Crackers, whole-wheat, reduced fat",Baked Products,11.34,0.039,0.745,10.9,0,0.373,75.52,1.22,7.59,2.59,416,1.067,1.609,3.568,33568
"Cream puffs, prepared from recipe, shell (includes eclair)",Baked Products,9,0.036,0.557,0.8,0,0.097,22.8,0.43,25.9,40.5,362,5.599,11.128,7.38,33569
"Cream puffs, prepared from recipe, shell, with custard filling",Baked Products,6.7,0.066,0.341,0.4,0.0003,0.115,22.9,9.49,15.5,53.5,258,3.679,6.527,4.156,33570
"Croissants, apple",Baked Products,7.4,0.03,0.274,2.5,0.0005,0.09,37.1,0,8.7,45.6,254,4.994,2.393,0.628,33571
"Croissants, butter",Baked Products,8.2,0.037,0.347,2.6,0.0002,0.118,45.8,11.26,21,23.2,406,11.659,5.525,1.094,33572
"Croissants, cheese",Baked Products,9.2,0.053,0.361,2.6,0.0002,0.132,47,11.35,20.9,21,414,10.628,6.509,2.384,33573
"Croutons, plain",Baked Products,11.9,0.076,0.698,5.1,0,0.124,73.5,0,6.6,5.5,407,1.51,3.059,1.273,33574
"Croutons, seasoned",Baked Products,10.8,0.096,1.089,5,0,0.181,63.5,4.41,18.3,3.6,465,5.247,9.498,2.368,33575
"Danish pastry, cheese",Baked Products,8,0.035,0.322,1,0.0001,0.098,37.2,6.95,21.9,31.4,374,6.794,11.313,2.575,33576
"Danish pastry, cinnamon, enriched",Baked Products,7,0.071,0.371,1.3,0.0001,0.125,44.6,19.78,22.4,24.3,403,5.681,12.515,2.933,33577
"Danish pastry, cinnamon, unenriched",Baked Products,7,0.071,0.371,1.2,0.0002,0.125,44.6,0,22.4,24.3,403,5.681,12.515,2.933,33578
"Danish pastry, fruit, enriched (includes apple, cinnamon, raisin, lemon, raspberry, strawberry)",Baked Products,5.4,0.046,0.354,1.9,0.0039,0.083,47.8,27.53,18.5,27.1,371,4.86,10.026,2.364,33579
"Danish pastry, fruit, unenriched (includes apple, cinnamon, raisin, strawberry)",Baked Products,5.4,0.046,0.354,1.9,0.0039,0.083,47.8,0,18.5,27.1,371,2.826,5.937,1.508,33580
"Danish pastry, lemon, unenriched",Baked Products,5.4,0.046,0.354,1.9,0.0039,0.083,47.8,0,18.5,27.1,371,2.826,5.937,1.508,33581
"Danish pastry, nut (includes almond, raisin nut, cinnamon nut)",Baked Products,7.1,0.094,0.298,2,0.0017,0.095,45.7,25.87,25.2,20.4,430,5.821,13.685,4.281,33582
"Danish pastry, raspberry, unenriched",Baked Products,5.4,0.046,0.354,1.9,0.0039,0.083,47.8,0,18.5,27.1,371,2.826,5.937,1.508,33583
"Doughnuts, cake-type, chocolate, sugared or glazed",Baked Products,4.5,0.213,0.397,2.2,0.0001,0.106,57.4,31.92,19.9,16.3,417,5.132,11.278,2.477,33584
"Doughnuts, cake-type, plain (includes unsugared, old-fashioned)",Baked Products,5.87,0.025,0.557,1.6,0.0013,0.113,45.63,16.28,23.55,22.88,418,7.127,12.732,2.659,33585
"Doughnuts, cake-type, plain, chocolate-coated or frosted",Baked Products,4.93,0.024,0.326,1.9,0.0013,0.201,51.33,26.65,25.25,16.76,452,13.47,8.618,1.96,33586
"Doughnuts, cake-type, plain, sugared or glazed",Baked Products,5.2,0.06,0.402,1.5,0.0001,0.102,50.8,0,22.9,19.6,426,5.926,12.697,2.909,33587
"Doughnuts, cake-type, wheat, sugared or glazed",Baked Products,6.3,0.049,0.427,2.2,0.0002,0.148,42.6,21.58,19.3,29.8,360,3.023,8.101,7.077,33588
"Doughnuts, french crullers, glazed",Baked Products,3.1,0.026,0.345,1.2,0,0.078,59.5,35.11,18.3,17.9,412,4.667,10.446,2.285,33589
"Doughnuts, yeast-leavened, glazed, enriched (includes honey buns)",Baked Products,6.21,0.046,0.316,2.1,0.0012,0.1,50.64,19.52,19.1,22.6,399,5.44,9.96,2.864,33590
"Doughnuts, yeast-leavened, glazed, unenriched (includes honey buns)",Baked Products,6.4,0.043,0.342,1.2,0.0001,0.108,44.3,0,22.8,25.4,403,5.813,12.865,2.902,33591
"Doughnuts, yeast-leavened, with creme filling",Baked Products,6.4,0.025,0.309,0.8,0,0.08,30,14.55,24.5,38.2,361,5.43,12.08,3.082,33592
"Doughnuts, yeast-leavened, with jelly filling",Baked Products,5.9,0.025,0.24,0.9,0,0.079,39,21.1,18.7,35.6,340,4.843,10.226,2.377,33593
"Eclairs, custard-filled with chocolate glaze, prepared from recipe",Baked Products,6.4,0.063,0.337,0.6,0.0003,0.117,24.2,6.6,15.7,52.4,262,4.119,6.484,3.948,33594
"English muffins, mixed-grain (includes granola)",Baked Products,9.1,0.196,0.334,2.8,0,0.156,46.3,0.81,1.8,40.2,235,0.23,0.827,0.559,33595
"English muffins, mixed-grain, toasted (includes granola)",Baked Products,9.9,0.213,0.453,3,0,0.169,50.3,0.88,1.9,35,255,0.25,0.899,0.608,33596
"English muffins, plain, enriched, with ca prop (includes sourdough)",Baked Products,8.87,0.163,0.362,3.5,0.0018,0.109,44.17,3.53,1.69,43.56,227,0.733,0.383,0.498,33597
"English muffins, plain, enriched, without calcium propionate(includes sourdough)",Baked Products,7.7,0.052,0.464,2.7,0,0.131,46,0,1.8,42.1,235,0.259,0.302,0.888,33598
"English muffins, plain, toasted, enriched, with calcium propionate (includes sourdough)",Baked Products,10.32,0.197,0.477,2.8,0.0017,0.129,52.65,3.47,2.02,32.97,270,0.533,0.478,0.921,33599
"English muffins, plain, unenriched, with calcium propionate (includes sourdough)",Baked Products,7.7,0.174,0.464,2.7,0,0.131,46,0,1.8,42.1,235,0.259,0.302,0.888,33600
"English muffins, plain, unenriched, without calcium propionate (includes sourdough)",Baked Products,7.7,0.052,0.464,2.7,0.0001,0.131,46,0,1.8,42.1,235,0.259,0.302,0.888,33601
"English muffins, raisin-cinnamon (includes apple-cinnamon)",Baked Products,7.91,0.114,0.277,2.6,0.0013,0.173,48.1,14.76,1.8,40.6,240,0.54,0.846,0.339,33602
"English muffins, raisin-cinnamon, toasted (includes apple-cinnamon)",Baked Products,8.87,0.139,0.369,3,0.0014,0.194,55.04,13.34,2.21,32.07,276,0.579,1.065,0.469,33603
"English muffins, wheat",Baked Products,8.7,0.178,0.382,4.6,0,0.186,44.8,1.56,2,42.3,223,0.287,0.28,0.834,33604
"English muffins, wheat, toasted",Baked Products,9.4,0.193,0.415,5,0,0.202,48.7,1.7,2.1,37.2,243,0.312,0.305,0.906,33605
"English muffins, whole-wheat",Baked Products,8.8,0.265,0.364,6.7,0,0.21,40.4,8.09,2.1,45.7,203,0.334,0.513,0.835,33606
"English muffins, whole-wheat, toasted",Baked Products,9.6,0.288,0.692,7.3,0,0.228,44.1,8.79,2.3,41,221,0.363,0.557,0.908,33607
"French toast, frozen, ready-to-heat",Baked Products,7.4,0.107,0.495,1.1,0.0003,0.134,32.1,0,6.1,52.6,213,1.533,2.04,1.227,33608
"French toast, prepared from recipe, made with low fat (2%) milk",Baked Products,7.7,0.1,0.479,0,0.0003,0.134,25,0,10.8,54.7,229,2.723,4.524,2.594,33609
"GENERAL MILLS, BETTY CROCKER SUPERMOIST Yellow Cake Mix, dry",Baked Products,3.8,0.161,0.672,0,0,0,81.3,43.9,8.1,4.2,413,2.28,0,0,33610
"GEORGE WESTON BAKERIES, Brownberry Sage and Onion Stuffing Mix, dry",Baked Products,13.3,0,1.68,5.4,0,0,72.7,0,5.1,4,390,0.9,0,0,33611
"GEORGE WESTON BAKERIES, Thomas English Muffins",Baked Products,8,0.18,0.345,0,0,0,46,0,1.8,42.6,232,0.308,0.303,0.92,33612
"HEINZ, WEIGHT WATCHER, Chocolate Eclair, frozen",Baked Products,4.4,0.048,0.3,2.1,0,0,40.3,16.8,6.9,47.3,241,1.4,2,2.9,33613
"Hush puppies, prepared from recipe",Baked Products,7.7,0.278,0.668,2.8,0.0002,0.144,46,2.03,13.5,29,337,2.108,3.263,7.219,33614
"INTERSTATE BRANDS CORP, WONDER Hamburger Rolls",Baked Products,8.07,0.087,0.488,2.6,0,0,50.84,11.06,4.15,34.2,273,1.013,0.848,2.177,33615
"Ice cream cones, cake or wafer-type",Baked Products,8.1,0.025,0.256,3,0,0.112,79,6,6.9,5.3,417,1.222,1.845,3.264,33616
"Ice cream cones, sugar, rolled-type",Baked Products,7.9,0.044,0.251,1.7,0,0.145,84.1,25.66,3.8,3,402,0.573,1.47,1.452,33617
"KEEBLER, KEEBLER Chocolate Graham SELECTS",Baked Products,7.1,0,0.357,0,0,0,71.8,26.5,16.6,3.1,465,3.09,0,0,33618
"KEEBLER, KEEBLER GOLDEN Vanilla Wafers, Artificially Flavored",Baked Products,5.2,0,0.386,0,0,0,69.7,27.4,19.5,4.2,475,3.58,0,0,33619
KELLOGG'S Eggo Golden Oat Waffles,Baked Products,6.83,0.066,0.385,3.5,0.0008,0.191,37.52,3.47,3.22,50.6,198,0.6,1.067,1.067,33620
KELLOGG'S Eggo Lowfat Blueberry Nutri-Grain Waffles,Baked Products,5.95,0.061,0.591,3.5,0,0.068,42.71,8.89,2.83,46.06,208,0.41,1.15,0.8,33621
KELLOGG'S Eggo Lowfat Homestyle Waffles,Baked Products,7.06,0.058,0.442,1,0,0.143,44.15,2.92,3.56,43.44,236,0.9,1,1.07,33622
KELLOGG'S Eggo Lowfat Nutri-Grain Waffles,Baked Products,6.28,0.061,0.559,3.7,0,0.072,40.27,5.73,3.2,47.75,203,0.47,1.34,0.9,33623
"KELLOGG'S Pop-Tarts Pastry Swirls, Apple Cinnamon Danish",Baked Products,4.8,0.032,0.306,1.4,0,0,59.7,17.7,17.7,16.8,413,4.8,5.8,7.1,33624
"KELLOGG'S Pop-Tarts Pastry Swirls, Cheese Danish",Baked Products,4.8,0.064,0.29,0.5,0,0,59.1,19.4,17.7,16.85,407,4.8,5.8,7.1,33625
"KELLOGG'S Pop-Tarts Pastry Swirls, Strawberry Danish",Baked Products,4.8,0.032,0.274,1.8,0,0,60,25.8,17.7,16.32,410,4.8,5.8,7.1,33626
"KELLOGG, KELLOG'S NUTRI-GRAIN CEREAL BARS, Mixed Berry",Baked Products,4.3,0.039,0.284,1.9,0,0.188,72.8,33.6,7.6,14.5,370,1.5,5,1.1,33627
"KELLOGG, KELLOGG'S EGGO, Banana Bread Waffles",Baked Products,6.8,0.051,0.359,2.5,0.0014,0.18,41.6,6.7,9.5,41.2,272,1.71,5.28,2.51,33628
"KELLOGG, KELLOGG'S EGGO, Buttermilk Pancake",Baked Products,6,0.035,0.508,1.1,0.0014,0.103,38.1,8.2,6.69,47.2,233,1.44,2.94,2.13,33629
"KELLOGG, KELLOGG'S LOW FAT POP TARTS, Frosted brown sugar cinnamon",Baked Products,4.7,0.014,0.419,1.2,0,0.061,78.3,36.2,5.5,10.5,376,1.2,2.9,1.3,33630
"KELLOGG, KELLOGG'S LOW FAT POP TARTS, Frosted chocolate fudge",Baked Products,5.1,0.026,0.478,1.1,0,0.119,76,36.2,5.8,12.5,366,1,2.4,1.7,33631
"KELLOGG, KELLOGG'S LOW FAT POP TARTS, Frosted strawberry",Baked Products,4.1,0.01,0.387,1.1,0,0.049,77.5,39.9,5.8,12.5,367,1.1,2.7,2,33632
"KELLOGG, KELLOGG'S LOW FAT POP TARTS, Strawberry",Baked Products,4.5,0.011,0.35,1.2,0,0.054,76.6,35.8,5.5,12.5,369,1.2,2.9,1.3,33633
"KRAFT FOODS, SHAKE 'N' BAKE ORIGINAL RECIPE, Coating for Pork, dry",Baked Products,6.1,0,2.182,0,0,0,79.8,0,3.7,3.2,377,0,0,0,33634
"KRAFT, STOVE TOP Stuffing Mix Chicken Flavor",Baked Products,12.6,0.063,1.532,2.5,0.0033,0.267,73.1,10.1,4.1,5.3,381,0.8,0,0,33635
"Keikitos (muffins), Latino bakery item",Baked Products,6.81,0.063,0.515,1.2,0,0.09,53.16,25.81,25.24,12.99,467,0,0,0,33636
"Leavening agents, baking powder, double-acting, sodium aluminum sulfate",Baked Products,0,5.876,10.6,0.2,0,0.02,27.7,0,0,5,53,0,0,0,33637
"Leavening agents, baking powder, double-acting, straight phosphate",Baked Products,0.1,7.364,7.893,0.2,0,0.005,24.1,0,0,4,51,0,0,0,33638
"Leavening agents, baking powder, low-sodium",Baked Products,0.1,4.332,0.09,2.2,0,10.1,46.9,0,0.4,6.2,97,0.073,0.006,0.121,33639
"Leavening agents, baking soda",Baked Products,0,0,27.36,0,0,0,0,0,0,0.2,0,0,0,0,33640
"Leavening agents, cream of tartar",Baked Products,0,0.008,0.052,0.2,0,16.5,61.5,0,0,1.7,258,0,0,0,33641
"Leavening agents, yeast, baker's, active dry",Baked Products,40.44,0.03,0.051,26.9,0.0003,0.955,41.22,0,7.61,5.08,325,1.001,4.309,0.017,33642
"Leavening agents, yeast, baker's, compressed",Baked Products,8.4,0.019,0.03,8.1,0.0001,0.601,18.1,0,1.9,69,105,0.243,1.047,0.004,33643
"MARTHA WHITE FOODS, Martha White's Buttermilk Biscuit Mix,dry",Baked Products,7.81,0.168,1.295,1.5,0.0002,0.212,59.41,4.46,13.23,10,388,4.07,0,0,33644
"MARTHA WHITE FOODS, Martha White's Chewy Fudge Brownie Mix,dry",Baked Products,4.42,0.017,0.457,2.9,0,0.051,83.58,54.76,6.16,4,407,1.7,0,0,33645
"MCKEE BAKING, LITTLE DEBBIE NUTTY BARS, Wafers with Peanut Butter, Chocolate Covered",Baked Products,8,0,0.223,0,0.002,0,55.2,34,32.8,3,548,6.29,0,0,33646
"MISSION FOODS, MISSION Flour Tortillas, Soft Taco, 8 inch",Baked Products,8.7,0.191,0.898,0,0,0,49.6,0,6,33.6,287,0.695,2.771,0.924,33647
"Muffin, blueberry, commercially prepared, low-fat",Baked Products,4.23,0.035,0.413,4.2,0.0009,0.096,50.05,27.05,4.22,39.94,255,1.549,0.86,1.363,33648
"Muffins, blueberry, commercially prepared (Includes mini-muffins)",Baked Products,5.28,0.035,0.349,1.7,0.0009,0.096,49.54,27.05,19.24,24.61,393,3.549,5.152,9.703,33649
"Muffins, blueberry, dry mix",Baked Products,4.9,0.025,0.548,0,0.0004,0.084,63.2,0,10,20,366,2.489,5.507,1.393,33650
"Muffins, blueberry, prepared from recipe, made with low fat (2%) milk",Baked Products,6.5,0.189,0.441,0,0.0015,0.123,40.7,0,10.8,39.5,285,2.03,2.596,5.388,33651
"Muffins, blueberry, toaster-type",Baked Products,4.6,0.013,0.247,1.8,0,0.083,53.3,4.85,9.5,30.8,313,1.398,2.162,5.351,33652
"Muffins, blueberry, toaster-type, toasted",Baked Products,4.9,0.014,0.509,1.9,0,0.088,56.7,12.95,10.1,26.4,333,1.551,2.371,5.477,33653
"Muffins, corn, commercially prepared",Baked Products,5.9,0.074,0.64,3.4,0,0.069,50.9,7.5,8.4,32.6,305,1.354,2.104,3.215,33654
"Muffins, corn, dry mix, prepared",Baked Products,7.4,0.075,0.795,2.4,0.0001,0.131,49.1,0,10.2,30.5,321,2.797,5.249,1.25,33655
"Muffins, corn, prepared from recipe, made with low fat (2%) milk",Baked Products,7.1,0.259,0.585,0,0.0003,0.145,44.2,0,12.3,33,316,2.311,3.012,6.162,33656
"Muffins, corn, toaster-type",Baked Products,5.3,0.019,0.43,1.6,0,0.092,57.9,0,11.3,23.6,346,1.681,2.625,6.324,33657
"Muffins, oat bran",Baked Products,7,0.063,0.393,4.6,0,0.507,48.3,8.22,7.4,35,270,1.087,1.695,4.129,33658
"Muffins, plain, prepared from recipe, made with low fat (2%) milk",Baked Products,6.9,0.2,0.467,2.7,0.0003,0.121,41.4,0,11.4,37.7,296,2.156,2.757,5.721,33659
"Muffins, wheat bran, dry mix",Baked Products,7.1,0.036,0.7,0,0,0.2,73,0,12,4.8,396,2.939,6.403,1.904,33660
"Muffins, wheat bran, toaster-type with raisins",Baked Products,5.2,0.037,0.495,7.7,0,0.166,52.2,13.98,8.8,31.4,295,1.357,2.036,4.822,33661
"Muffins, wheat bran, toaster-type with raisins, toasted",Baked Products,5.5,0.039,0.527,8.2,0,0.177,55.5,23.07,9.4,27,313,1.5,2.221,4.918,33662
"NABISCO, NABISCO GRAHAMS Crackers",Baked Products,6.99,0.079,0.659,3.4,0,0.177,76.2,23,10,3.6,424,1.56,3.6,0.46,33663
"NABISCO, NABISCO OREO CRUNCHIES, Cookie Crumb Topping",Baked Products,4.78,0.024,0.528,3.2,0,0.194,70.23,36,21.5,1.57,476,4.14,3.17,0.82,33664
"NABISCO, NABISCO RITZ Crackers",Baked Products,7.23,0.154,0.882,2.3,0,0.119,63.51,8.14,23.21,3.12,492,5.45,6.276,10.896,33665
"NABISCO, NABISCO SNACKWELL'S Fat Free Devil's Food Cookie Cakes",Baked Products,5,0.03,0.174,1.6,0.0001,0.111,74.25,43.38,1.09,17.65,305,0.427,0.246,0.196,33666
"PILLSBURY GRANDS, Buttermilk Biscuits, refrigerated dough",Baked Products,6.9,0.034,1.034,1.2,0,0,41.38,6.9,13.79,33.5,317,5.172,0,0,33667
"PILLSBURY Golden Layer Buttermilk Biscuits, Artificial Flavor, refrigerated dough",Baked Products,5.88,0,1.059,1.2,0,0,41.18,5.88,13.24,35.5,307,2.941,0,0,33668
"PILLSBURY, Buttermilk Biscuits, Artificial Flavor, refrigerated dough",Baked Products,6.25,0,0.891,1.6,0,0,45.31,6.25,3.13,40.6,234,0.469,0,0,33669
"PILLSBURY, Chocolate Chip Cookies, refrigerated dough",Baked Products,3.57,0,0.304,2.1,0,0,60.71,35.71,25,8.93,482,7.143,9.063,1.828,33670
"PILLSBURY, Cinnamon Rolls with Icing, refrigerated dough",Baked Products,4.55,0,0.773,1.1,0,0,52.27,22.73,11.36,28,330,3.409,0,0,33671
"PILLSBURY, Crusty French Loaf, refrigerated dough",Baked Products,7.69,0,0.577,1.7,0,0,46.15,3.85,2.89,40,241,0.962,0,0,33672
"PILLSBURY, Traditional Fudge Brownie Mix, dry",Baked Products,4.8,0,0.292,0,0,0,78.3,49.6,12.1,3.1,441,2.59,0,0,33673
"Pan Dulce, LA RICURA, Salpora de Arroz con Azucar, cookie-like, contains wheat flour and rice flour",Baked Products,8.81,0.052,0.445,1.2,0,0.094,66.28,21.86,16.11,7.12,445,0,0,0,33674
"Pancakes plain, frozen, ready-to-heat (includes buttermilk)",Baked Products,5.33,0.071,0.505,2.4,0.0003,0.124,39.29,9.73,5.17,48.22,225,0.81,1.891,1.081,33675
"Pancakes, blueberry, prepared from recipe",Baked Products,6.1,0.206,0.412,0,0.0022,0.138,29,0,9.2,53.2,222,1.986,2.316,4.162,33676
"Pancakes, buckwheat, dry mix, incomplete",Baked Products,10.9,0.476,0.767,8.5,0,0.316,71.3,7.21,2.7,9.1,340,0.439,0.581,1,33677
"Pancakes, buttermilk, prepared from recipe",Baked Products,6.8,0.157,0.522,0,0.0004,0.145,28.7,0,9.3,52.5,227,1.832,2.361,4.486,33678
"Pancakes, plain, dry mix, complete (includes buttermilk)",Baked Products,10.1,0.241,1.215,2.7,0.0004,0.341,71.3,0,4.9,8.8,376,0.984,1.71,1.592,33679
"Pancakes, plain, dry mix, complete, prepared",Baked Products,5.2,0.126,0.628,1.3,0.0002,0.175,36.7,0,2.5,53,194,0.507,0.881,0.82,33680
"Pancakes, plain, dry mix, incomplete (includes buttermilk)",Baked Products,10,0.342,1.089,5.4,0,0.191,73.6,2.21,1.7,9.1,355,0.25,0.324,0.726,33681
"Pancakes, plain, dry mix, incomplete, prepared",Baked Products,7.8,0.215,0.505,1.9,0.0006,0.199,28.9,0,7.7,52.9,218,2.045,2.07,2.923,33682
"Pancakes, plain, frozen, ready-to-heat, microwave (includes buttermilk)",Baked Products,5.88,0.072,0.566,2.5,0,0.122,43.33,7.06,4.73,43.8,239,0.949,2.721,1.07,33683
"Pancakes, plain, prepared from recipe",Baked Products,6.4,0.219,0.439,0,0.0003,0.132,28.3,0,9.7,52.9,227,2.122,2.474,4.447,33684
"Pancakes, special dietary, dry mix",Baked Products,8.9,0.099,0.456,0,0,0.676,73.9,0,1.4,10.8,349,0.202,0.276,0.611,33685
"Pancakes, whole-wheat, dry mix, incomplete",Baked Products,12.8,0.449,1.419,0,0,0.445,71,0,1.5,8.8,344,0.244,0.279,0.605,33686
"Pancakes, whole-wheat, dry mix, incomplete, prepared",Baked Products,8.5,0.25,0.572,2.8,0.0005,0.279,29.4,0,6.5,52.8,208,1.749,1.742,2.406,33687
"Pastry, Pastelitos de Guava (guava pastries)",Baked Products,5.48,0.014,0.231,2.2,0,0.103,47.76,17,18.5,27.26,379,5.535,8.543,2.835,33688
Phyllo dough,Baked Products,7.1,0.011,0.483,1.9,0,0.074,52.6,0.18,6,32.6,299,1.47,3.149,0.923,33689
"Pie Crust, Cookie-type, Chocolate, Ready Crust",Baked Products,6.08,0.032,0.503,2.7,0,0.187,64.48,26.31,22.42,4.99,484,4.725,14.501,2.035,33690
"Pie Crust, Cookie-type, Graham Cracker, Ready Crust",Baked Products,5.1,0.029,0.33,1.9,0,0.113,64.3,18.13,24.83,4.37,501,4.999,16.928,1.671,33691
"Pie crust, cookie-type, prepared from recipe, chocolate wafer, chilled",Baked Products,5.1,0.03,0.672,1.5,0,0.168,54.4,22.1,31.1,7.3,506,6.732,14.729,7.713,33692
"Pie crust, cookie-type, prepared from recipe, graham cracker, baked",Baked Products,4.2,0.021,0.571,1.5,0,0.088,65.2,38.34,24.9,4.2,494,5.196,11.37,6.908,33693
"Pie crust, cookie-type, prepared from recipe, graham cracker, chilled",Baked Products,4.1,0.02,0.56,1.5,0,0.086,63.9,37.57,24.4,6.1,484,5.092,11.142,6.769,33694
"Pie crust, cookie-type, prepared from recipe, vanilla wafer, chilled",Baked Products,3.7,0.042,0.515,0.1,0.0001,0.079,50.2,7.33,36.2,8.5,531,7.434,15.647,10.682,33695
"Pie crust, deep dish, frozen, baked, made with enriched flour",Baked Products,6.1,0.023,0.393,2.3,0,0.103,52.47,0,31.84,8.26,521,8.862,16.459,3.918,33696
"Pie crust, deep dish, frozen, unbaked, made with enriched flour",Baked Products,5.52,0.02,0.353,1.4,0,0.091,46.79,0,28.74,17.78,468,8.129,14.489,3.53,33697
"Pie crust, refrigerated, regular, baked",Baked Products,3.41,0.012,0.472,1.4,0,0.083,58.52,0,28.69,7.96,506,11.087,11.815,3.756,33698
"Pie crust, refrigerated, regular, unbaked",Baked Products,2.97,0.01,0.409,1.8,0,0.073,51.11,0,25.46,19.25,445,9.596,10.206,3.292,33699
"Pie crust, standard-type, dry mix",Baked Products,6.9,0.061,0.753,0,0,0.064,52.1,0,31.4,7.6,518,7.974,17.887,3.968,33700
"Pie crust, standard-type, dry mix, prepared, baked",Baked Products,6.7,0.06,0.729,1.8,0,0.062,50.4,0,30.4,10.6,501,7.711,17.289,3.847,33701
"Pie crust, standard-type, frozen, ready-to-bake, enriched",Baked Products,6.16,0.019,0.409,2.5,0,0.097,48.62,0,26.07,17.82,457,8.159,12.167,3.176,33702
"Pie crust, standard-type, frozen, ready-to-bake, enriched, baked",Baked Products,6.5,0.021,0.467,3.3,0,0.114,56.24,4.23,28.59,7.18,508,9.277,13.562,3.568,33703
"Pie crust, standard-type, frozen, ready-to-bake, unenriched",Baked Products,3.9,0.018,0.576,0.9,0,0.098,44.1,0,29.2,21,457,4.357,12.414,11,33704
"Pie crust, standard-type, prepared from recipe, baked",Baked Products,6.4,0.01,0.542,1.7,0,0.067,47.5,0.17,34.6,9.8,527,8.622,15.169,9.122,33705
"Pie crust, standard-type, prepared from recipe, unbaked",Baked Products,5.7,0.009,0.482,3.4,0,0.059,42.3,0.15,30.8,19.7,469,7.674,13.5,8.119,33706
"Pie, Dutch Apple, Commercially Prepared",Baked Products,2.17,0.014,0.2,1.6,0.0057,0.076,44.54,22.02,11.5,41.09,290,2.313,5.797,2.117,33707
"Pie, apple, commercially prepared, enriched flour",Baked Products,1.9,0.011,0.201,1.6,0.0032,0.065,34,15.65,11,52.2,237,3.797,4.388,2.198,33708
"Pie, apple, commercially prepared, unenriched flour",Baked Products,1.9,0.011,0.266,1.6,0.0032,0.065,34,0,11,52.2,237,3.797,4.388,2.198,33709
"Pie, apple, prepared from recipe",Baked Products,2.4,0.007,0.211,0,0.0017,0.079,37.1,0,12.5,47.3,265,3.05,5.393,3.338,33710
"Pie, banana cream, prepared from mix, no-bake type",Baked Products,3.4,0.073,0.29,0.6,0.0005,0.113,31.6,0,12.9,50.9,251,6.904,4.559,0.764,33711
"Pie, banana cream, prepared from recipe",Baked Products,4.4,0.075,0.24,0.7,0.0016,0.165,32.9,12.06,13.6,47.9,269,3.758,5.719,3.292,33712
"Pie, blueberry, commercially prepared",Baked Products,1.8,0.008,0.219,1,0.0027,0.05,34.9,9.89,10,52.5,232,1.679,4.244,3.524,33713
"Pie, blueberry, prepared from recipe",Baked Products,2.7,0.007,0.185,0,0.0007,0.05,33.5,0,11.9,51.2,245,2.911,5.121,3.08,33714
"Pie, cherry, commercially prepared",Baked Products,2,0.012,0.246,0.8,0.0009,0.081,39.8,14.29,11,46.2,260,2.562,5.837,2.055,33715
"Pie, cherry, prepared from recipe",Baked Products,2.8,0.01,0.191,0,0.001,0.077,38.5,0,12.2,45.8,270,2.985,5.314,3.247,33716
"Pie, chocolate creme, commercially prepared",Baked Products,2.6,0.036,0.136,2,0,0.127,33.6,0,19.4,43.5,304,4.968,11.117,2.398,33717
"Pie, chocolate mousse, prepared from mix, no-bake type",Baked Products,3.5,0.077,0.46,0,0.0005,0.285,29.6,0,15.4,49.7,260,8.195,5.084,0.814,33718
"Pie, coconut cream, prepared from mix, no-bake type",Baked Products,2.8,0.072,0.329,0.5,0.0006,0.141,28.5,0,17.6,49.7,276,8.934,6.554,1.217,33719
"Pie, coconut creme, commercially prepared",Baked Products,2.1,0.029,0.301,1.3,0,0.065,37.2,36.27,16.6,43.2,298,6.976,7.26,1.544,33720
"Pie, coconut custard, commercially prepared",Baked Products,5.9,0.081,0.335,1.8,0.0006,0.175,30.2,0,13.2,49.2,260,5.854,5.495,1.17,33721
"Pie, egg custard, commercially prepared",Baked Products,5.5,0.08,0.15,1.6,0.0006,0.106,20.8,11.58,11.6,60.9,210,2.349,4.797,3.723,33722
"Pie, fried pies, cherry",Baked Products,3,0.022,0.374,2.6,0.0013,0.065,42.6,0,16.1,37.6,316,2.457,7.449,5.376,33723
"Pie, fried pies, fruit",Baked Products,3,0.022,0.374,2.6,0.0013,0.065,42.6,21.4,16.1,37.6,316,2.457,7.449,5.376,33724
"Pie, fried pies, lemon",Baked Products,3,0.022,0.374,2.6,0,0.065,42.6,0,16.1,37.6,316,2.457,7.449,5.376,33725
"Pie, lemon meringue, commercially prepared",Baked Products,1.5,0.056,0.172,1.2,0.0032,0.089,47.2,23.86,8.7,41.7,268,1.766,2.685,3.648,33726
"Pie, lemon meringue, prepared from recipe",Baked Products,3.8,0.012,0.242,0,0.0033,0.065,39.1,0,12.9,43.3,285,3.185,5.582,3.331,33727
"Pie, mince, prepared from recipe",Baked Products,2.6,0.022,0.254,2.6,0.005900000000000001,0.203,48,28.31,10.8,37.4,289,2.682,4.654,2.842,33728
"Pie, peach",Baked Products,1.9,0.008,0.194,0.8,0.0009,0.125,32.9,6.19,10,54.4,223,1.508,4.241,3.749,33729
"Pie, pecan, commercially prepared",Baked Products,4.5,0.022,0.158,2.1,0,0.099,59.61,25.18,16.69,18.25,407,2.654,8.317,3.554,33730
"Pie, pecan, prepared from recipe",Baked Products,4.9,0.032,0.262,0,0.0002,0.133,52.2,0,22.2,19.5,412,3.989,11.181,5.71,33731
"Pie, pumpkin, commercially prepared",Baked Products,3.9,0.064,0.338,1.8,0,0.167,34.83,18.88,9.75,50.39,243,1.988,4.6,1.77,33732
"Pie, pumpkin, prepared from recipe",Baked Products,4.5,0.094,0.225,0,0.0017,0.186,26.4,0,9.3,58.5,204,3.171,3.697,1.81,33733
"Pie, vanilla cream, prepared from recipe",Baked Products,4.8,0.09,0.26,0.6,0.0005,0.126,32.6,12.68,14.4,47,278,4.03,6.043,3.438,33734
"Popovers, dry mix, enriched",Baked Products,10.4,0.032,0.906,0,0.0001,0.1,71,0,4.3,11.7,371,0.983,1.984,0.823,33735
"Popovers, dry mix, unenriched",Baked Products,10.4,0.032,0.906,0,0.0001,0.1,71,0,4.3,11.7,371,0.983,1.984,0.823,33736
"Puff pastry, frozen, ready-to-bake",Baked Products,7.3,0.01,0.249,1.5,0,0.061,45.1,0.74,38.1,8.5,551,9.643,21.597,4.894,33737
"Puff pastry, frozen, ready-to-bake, baked",Baked Products,7.4,0.01,0.253,1.5,0,0.062,45.7,0.75,38.5,7.4,558,5.502,8.828,22.228,33738
"Rolls, dinner, egg",Baked Products,9.5,0.059,0.459,3.7,0,0.104,52,4.3,6.4,30.4,307,1.577,2.93,1.128,33739
"Rolls, dinner, oat bran",Baked Products,9.5,0.085,0.413,4.1,0,0.121,40.2,6.67,4.6,44,236,0.619,1.488,1.568,33740
"Rolls, dinner, plain, commercially prepared (includes brown-and-serve)",Baked Products,10.86,0.178,0.536,2,0.0002,0.139,52.04,5.55,6.47,28.44,310,1.375,1.881,2.509,33741
"Rolls, dinner, plain, prepared from recipe, made with low fat (2%) milk",Baked Products,8.5,0.06,0.415,1.9,0.0002,0.152,53.4,0,7.3,29.1,316,1.795,2.881,2.006,33742
"Rolls, dinner, rye",Baked Products,10.3,0.03,0.65,4.9,0,0.18,53.1,1.17,3.4,30.1,286,0.605,1.236,0.708,33743
"Rolls, dinner, wheat",Baked Products,8.6,0.176,0.485,3.8,0,0.115,46,1.63,6.3,37,273,1.497,3.109,1.107,33744
"Rolls, dinner, whole-wheat",Baked Products,8.7,0.106,0.401,7.5,0,0.272,51.1,8.46,4.7,33.1,266,0.836,1.199,2.161,33745
"Rolls, french",Baked Products,8.6,0.091,0.507,3.2,0,0.114,50.2,0.31,4.3,34.8,277,0.962,1.961,0.834,33746
"Rolls, hamburger or hotdog, mixed-grain",Baked Products,9.6,0.095,0.458,3.8,0,0.16,44.6,6.25,6,38,263,1.39,2.839,1.153,33747
"Rolls, hamburger or hotdog, plain",Baked Products,9.5,0.138,0.479,2.1,0,0.094,49.45,6.25,4.33,34.7,279,1.092,1.112,1.968,33748
"Rolls, hamburger or hotdog, reduced-calorie",Baked Products,8.3,0.059,0.442,6.2,0.0002,0.078,42.1,4.76,2,46,196,0.325,0.524,0.763,33749
"Rolls, hard (includes kaiser)",Baked Products,9.9,0.095,0.544,2.3,0,0.108,52.7,1.77,4.3,31,293,0.606,1.133,1.719,33750
"Rolls, pumpernickel",Baked Products,10.8,0.067,0.707,5.4,0,0.208,52.8,0.4,2.8,32,277,0.495,0.609,1.184,33751
"Strudel, apple",Baked Products,3.3,0.015,0.157,2.2,0.0017,0.149,41.1,25.75,11.2,43.5,274,2.044,3.267,5.315,33752
"Sweet rolls, cheese",Baked Products,7.1,0.118,0.357,1.2,0.0002,0.137,43.7,0,18.3,29.4,360,6.059,9.057,2.032,33753
"Sweet rolls, cinnamon, commercially prepared with raisins",Baked Products,6.2,0.072,0.304,2.4,0.002,0.111,50.9,31.73,16.4,24.8,372,3.079,4.797,7.473,33754
"Sweet rolls, cinnamon, refrigerated dough with frosting",Baked Products,5,0.031,0.765,0,0.0002,0.058,51.6,0,12.2,28.9,333,3.081,6.839,1.604,33755
"Sweet rolls, cinnamon, refrigerated dough with frosting, baked",Baked Products,5.4,0.034,0.832,0,0.0002,0.063,56.1,0,13.2,22.7,362,3.348,7.421,1.728,33756
"Taco shells, baked",Baked Products,7.08,0.101,0.389,4.6,0,0.223,62.72,1.23,20.83,7.38,467,6.246,6.43,7.265,33757
"Taco shells, baked, without added salt",Baked Products,7.2,0.16,0.015,7.5,0,0.179,62.4,0,22.6,6,468,3.245,8.934,8.494,33758
"Toaster Pastries, KELLOGG, KELLOGG'S POP TARTS, Apple cinnamon",Baked Products,4.4,0.023,0.334,1.1,0,0.091,72.02,33.7,10.2,12.5,395,1.69,5.87,2.6,33759
"Toaster Pastries, KELLOGG, KELLOGG'S POP TARTS, Blueberry",Baked Products,4.6,0.024,0.35,1.1,0,0.095,68.38,30.9,13.3,12.5,408,2.02,6.5,4.81,33760
"Toaster Pastries, KELLOGG, KELLOGG'S POP TARTS, Brown sugar cinnamon",Baked Products,5.4,0.031,0.38,1.5,0,0.135,64.4,25.4,18.4,10.5,438,2.08,7.14,9.14,33761
"Toaster Pastries, KELLOGG, KELLOGG'S POP TARTS, Cherry",Baked Products,4.6,0.028,0.423,1.1,0,0.114,71.19,31.7,10.4,12.5,393,1.67,5.73,3,33762
"Toaster Pastries, KELLOGG, KELLOGG'S POP TARTS, Frosted blueberry",Baked Products,4.6,0.024,0.32,1.1,0,0.095,71.77,31.2,10,12.5,391,1.98,6.83,1.13,33763
"Toaster Pastries, KELLOGG, KELLOGG'S POP TARTS, Frosted brown sugar cinnamon",Baked Products,5,0.029,0.35,1.3,0,0.111,68.32,30.8,14.8,10.5,422,2.26,7.88,4.76,33764
"Toaster Pastries, KELLOGG, KELLOGG'S POP TARTS, Frosted cherry",Baked Products,4.2,0.025,0.32,1,0,0.095,71.96,36,10.2,12.5,393,1.94,6.69,1.58,33765
"Toaster Pastries, KELLOGG, KELLOGG'S POP TARTS, Frosted chocolate fudge",Baked Products,5.1,0.038,0.44,1.1,0,0.158,71.8,38,9.3,12.5,387,1.9,5.2,2.2,33766
"Toaster Pastries, KELLOGG, KELLOGG'S POP TARTS, Frosted chocolate vanilla creme",Baked Products,5,0.03,0.441,1,0,0.12,70.84,37.3,10.2,12.5,391,1.94,6.19,1.98,33767
"Toaster Pastries, KELLOGG, KELLOGG'S POP TARTS, Frosted grape",Baked Products,4.4,0.023,0.333,1,0,0.115,72.25,35.4,9.8,12.5,391,1.73,5.9,2.15,33768
"Toaster Pastries, KELLOGG, KELLOGG'S POP TARTS, Frosted raspberry",Baked Products,4.2,0.022,0.32,1,0,0.085,71.54,35.4,10.6,12.5,395,1.88,6.17,2.56,33769
"Toaster Pastries, KELLOGG, KELLOGG'S POP TARTS, Frosted strawberry",Baked Products,4.4,0.022,0.325,1,0,0.085,72.4,37.5,9.6,12.5,390,2.6,5.6,1.4,33770
"Toaster Pastries, KELLOGG, KELLOGG'S POP TARTS, Frosted wild berry",Baked Products,4.3,0.021,0.311,1,0,0.082,72.9,38.8,9.2,12.5,388,2.6,5.4,1.3,33771
"Toaster Pastries, KELLOGG, KELLOGG'S POP TARTS, S'mores",Baked Products,6.2,0.029,0.41,1.4,0,0.125,69.6,36.5,10.5,12.5,392,2.8,5.9,1.8,33772
"Toaster Pastries, KELLOGG, KELLOGG'S POP TARTS, Strawberry",Baked Products,4.7,0.024,0.355,1.1,0,0.093,71,33,10.5,12.5,394,2.9,6.2,1.5,33773
"Toaster Pastries, fruit, frosted (include apples, blueberry, cherry, strawberry)",Baked Products,4.23,0.011,0.312,0.7,0,0.071,70.81,35.52,10.08,13.79,391,2.672,5.733,1.235,33774
"Toaster pastries, brown-sugar-cinnamon",Baked Products,5.1,0.034,0.424,1,0.0001,0.114,68.1,0,14.2,10.8,412,3.639,8.032,1.802,33775
"Toaster pastries, fruit (includes apple, blueberry, cherry, strawberry)",Baked Products,4.67,0.011,0.334,1.1,0,0.075,69.23,28.59,10.56,14.43,391,2.618,6.404,1.082,33776
"Toaster pastries, fruit, toasted ( include apple, blueberry, cherry, strawberry)",Baked Products,4.7,0.011,0.354,1,0,0.081,72.7,28.99,11.03,10.46,409,2.637,6.55,1.281,33777
"Tortillas, ready-to-bake or -fry, corn",Baked Products,5.7,0.081,0.045,6.3,0,0.186,44.64,0.88,2.85,45.89,218,0.453,0.692,1.419,33778
"Tortillas, ready-to-bake or -fry, corn, without added salt",Baked Products,5.7,0.175,0.011,5.2,0,0.154,46.6,0,2.5,44.1,222,0.334,0.649,1.122,33779
"Tortillas, ready-to-bake or -fry, flour",Baked Products,8.29,0.129,0.636,3.1,0,0.155,51.35,1.92,7.75,30.22,312,1.886,3.894,1.584,33780
"Tortillas, ready-to-bake or -fry, flour, without added calcium",Baked Products,8.7,0.039,0.478,3.3,0,0.131,55.6,0,7.1,26.8,325,1.745,3.768,1.063,33781
"Tostada shells, corn",Baked Products,6.15,0.076,0.657,5.8,0,0.237,64.43,0,23.38,3.32,474,7.011,7.217,8.285,33782
"USDA Commodity, Bakery , Flour Mix Low-fat",Baked Products,9.07,0.181,1.36,3.5,0,0.141,70.59,0,4.7,10.51,361,0.85,1.83,0.65,33783
"USDA Commodity, Bakery, Flour Mix",Baked Products,8.47,0.187,1.41,7.1,0,0.124,62.53,0,12.9,10.62,400,3.09,6.79,1.96,33784
"Waffle, buttermilk, frozen, ready-to-heat, microwaved",Baked Products,6.92,0.125,0.663,2.4,0,0.11,44.16,4.5,9.4,36.48,289,2.057,4.864,1.615,33785
"Waffle, buttermilk, frozen, ready-to-heat, toasted",Baked Products,7.42,0.299,0.71,2.6,0,0.138,48.39,4.41,9.49,31.57,309,2.283,5.292,1.526,33786
"Waffle, plain, frozen, ready-to-heat, microwave",Baked Products,6.71,0.197,0.682,2.4,0,0.148,45.41,5.04,9.91,34.95,298,1.58,5.195,2.24,33787
"Waffles, buttermilk, frozen, ready-to-heat",Baked Products,6.58,0.279,0.621,2.2,0,0.126,41.05,4.3,9.22,40.34,273,1.89,4.53,1.539,33788
"Waffles, chocolate chip, frozen, ready-to-heat",Baked Products,5.71,0.143,0.543,1.4,0,0.071,41.48,12.86,10,40,279,3.571,2.564,3.272,33789
"Waffles, plain, frozen, ready -to-heat, toasted",Baked Products,7.19,0.307,0.73,2.4,0,0.144,49.29,5.02,9.61,30.59,312,1.638,4.914,2.197,33790
"Waffles, plain, frozen, ready-to-heat",Baked Products,6.47,0.308,0.638,2.2,0,0.125,42.98,4.91,9.7,37.95,285,1.531,5.129,2.165,33791
"Waffles, plain, prepared from recipe",Baked Products,7.9,0.255,0.511,0,0.0004,0.159,32.9,0,14.1,42,291,2.866,3.521,6.785,33792
Wonton wrappers (includes egg roll wrappers),Baked Products,9.8,0.047,0.572,1.8,0,0.082,57.9,0,1.5,28.8,291,0.263,0.195,0.61,33793
"Baking chocolate, MARS SNACKFOOD US, M&M's Milk Chocolate Mini Baking Bits",Sweets,4.78,0.116,0.068,2.7,0.0006,0,68.4,62.54,23.36,1.5,502,14.466,0,0,33794
"Baking chocolate, MARS SNACKFOOD US, M&M's Semisweet Chocolate Mini Baking Bits",Sweets,4.44,0.034,0.002,6.7,0,0,65.96,53.07,26.15,1.27,517,15.618,0,0,33795
"Baking chocolate, mexican, squares",Sweets,3.64,0.034,0.003,4,0.0001,0.397,77.41,69.4,15.59,1.59,426,8.606,5.044,1.162,33796
"Baking chocolate, unsweetened, liquid",Sweets,12.1,0.054,0.012,18.1,0,1.166,36.2,0,47.7,0.9,472,25.29,9.23,10.7,33797
"Baking chocolate, unsweetened, squares",Sweets,12.9,0.101,0.024,16.6,0,0.83,29.84,0.91,52.31,1.34,501,32.351,16.105,1.555,33798
"Candies, 5TH AVENUE Candy Bar",Sweets,8.78,0.073,0.225,3.1,0.0004,0.347,62.68,47.19,23.98,2.3,482,6.65,10.6,3.39,33799
"Candies, ALMOND JOY BITES",Sweets,5.58,0.109,0.039,4.3,0.001,0.306,57.54,51.71,34.5,1.26,563,20.6,8.85,1.46,33800
"Candies, ALMOND JOY Candy Bar",Sweets,4.13,0.064,0.142,5,0.0007,0.254,59.51,48.34,26.93,8.2,479,17.59,5.26,1.18,33801
"Candies, CARAMELLO Candy Bar",Sweets,6.19,0.213,0.122,1.2,0.0017,0.341,63.81,56.92,21.19,6.79,462,12.72,5.29,0.63,33802
"Candies, HEATH BITES",Sweets,3.94,0.086,0.245,2,0.0008,0.21,63.39,60.02,30.38,0.86,530,15.62,8.8,2.55,33803
"Candies, HERSHEY'S GOLDEN ALMOND SOLITAIRES",Sweets,11.97,0.203,0.052,4.4,0,0,46.91,37.13,37.13,0,570,15.23,0,0,33804
"Candies, HERSHEY'S MILK CHOCOLATE WITH ALMOND BITES",Sweets,9.76,0.22,0.074,3.6,0.0018,0.471,51,44.59,35.73,1.19,550,17.39,14.38,2.42,33805
"Candies, HERSHEY'S POT OF GOLD Almond Bar",Sweets,12.82,0.192,0.064,3.8,0,0,46.15,38.46,38.46,0,577,16.667,0,0,33806
"Candies, HERSHEY, KIT KAT BIG KAT Bar",Sweets,6.24,0.138,0.064,1.9,0.0013,0.294,63.64,53.96,27.84,1.1,520,17.99,4.91,0.51,33807
"Candies, HERSHEY, REESESTICKS crispy wafers, peanut butter, milk chocolate",Sweets,9.53,0.07,0.264,3.3,0.0006,0.295,55.38,40.33,31.34,1.6,521,13.21,10.82,4.68,33808
"Candies, KIT KAT Wafer Bar",Sweets,6.51,0.125,0.054,1,0,0.231,64.59,48.68,25.99,1.63,518,17.953,5.844,0.895,33809
"Candies, KRACKEL Chocolate Bar",Sweets,6.62,0.158,0.196,2.2,0.0008,0.325,63.96,52.53,26.58,1.17,512,15.92,6.25,0.57,33810
"Candies, MARS SNACKFOOD US, 3 MUSKETEERS Bar",Sweets,2.6,0.054,0.194,1.5,0.0002,0.133,77.77,66.89,12.75,5.8,436,8.646,2.373,0.316,33811
"Candies, MARS SNACKFOOD US, COCOAVIA Blueberry and Almond Chocolate Bar",Sweets,6.35,0.985,0.008,9.1,0.0342,0,60.37,38.99,28.68,2.9,525,13.07,0,0,33812
"Candies, MARS SNACKFOOD US, COCOAVIA Chocolate Bar",Sweets,5.81,1.164,0.007,8.7,0.0426,0,62.99,39.19,29.3,0.7,539,15.703,0,0,33813
"Candies, MARS SNACKFOOD US, COCOAVIA Chocolate Covered Almonds",Sweets,9.51,0.84,0.009,10.4,0.0273,0,50.22,26.92,37.07,1.5,573,11.745,0,0,33814
"Candies, MARS SNACKFOOD US, COCOAVIA Crispy Chocolate Bar",Sweets,8.21,1.07,0.04,7.8,0.037899999999999996,0,62.06,34.96,26.23,1.9,517,14.007,0,0,33815
"Candies, MARS SNACKFOOD US, DOVE Dark Chocolate",Sweets,5.19,0.037,0.004,7.6,0,0,59.4,46.27,32.45,1.66,520,19.209,0,0,33816
"Candies, MARS SNACKFOOD US, DOVE Milk Chocolate",Sweets,5.94,0.16,0.063,2.4,0.0008,0,59.78,55.45,31.72,1.61,546,19.24,0,0,33817
"Candies, MARS SNACKFOOD US, M&M's Almond Chocolate Candies",Sweets,7.53,0.155,0.045,5.6,0.0006,0.381,60.5,49.22,27.76,2.52,522,9.435,11.29,3.753,33818
"Candies, MARS SNACKFOOD US, M&M's Crispy Chocolate Candies",Sweets,4.28,0.09,0.136,2,0.0005,0,72.4,58.94,19.32,1.7,475,11.651,0,0,33819
"Candies, MARS SNACKFOOD US, M&M's MINIs Milk Chocolate Candies",Sweets,4.78,0.116,0.068,2.7,0.0006,0,68.4,62.54,23.36,1.19,502,14.466,0,0,33820
"Candies, MARS SNACKFOOD US, M&M's Milk Chocolate Candies",Sweets,4.33,0.105,0.061,2.8,0.0005,0.261,71.19,63.68,21.13,1.7,492,13.08,5.183,0.921,33821
"Candies, MARS SNACKFOOD US, M&M's Peanut Butter Chocolate Candies",Sweets,10.16,0.088,0.213,4,0.0005,0.33,56.89,47.15,29.32,2,529,18.485,4.448,0.542,33822
"Candies, MARS SNACKFOOD US, M&M's Peanut Chocolate Candies",Sweets,9.57,0.102,0.05,3.7,0,0.347,60.48,50.75,26.13,2.48,515,10.169,8.086,3.456,33823
"Candies, MARS SNACKFOOD US, MARS Almond Bar",Sweets,8.1,0.168,0.17,2,0.0007,0.325,62.7,52.1,23,4.5,467,7.268,10.693,3.98,33824
"Candies, MARS SNACKFOOD US, MILKY WAY Bar",Sweets,4.01,0.115,0.167,1,0.0007,0.124,71.17,59.69,17.23,6.3,456,12.016,2.23,0.26,33825
"Candies, MARS SNACKFOOD US, MILKY WAY Caramels, milk chocolate covered",Sweets,4.28,0.141,0.273,0.7,0.0007,0,68.49,58.65,19.17,6.99,463,13.157,0,0,33826
"Candies, MARS SNACKFOOD US, MILKY WAY Caramels. dark chocolate covered",Sweets,3.82,0.083,0.246,2.8,0.0004,0,67.56,54.08,20.42,6.99,458,13.814,0,0,33827
"Candies, MARS SNACKFOOD US, MILKY WAY Midnight Bar",Sweets,3.2,0.053,0.168,2.9,0.0002,0.196,71.22,57.81,17.5,7.2,443,11.474,4.639,0.514,33828
"Candies, MARS SNACKFOOD US, POP'ABLES 3 MUSKETEERS Brand Bite Size Candies",Sweets,2.59,0.06,0.172,1.3,0.0004,0,75.94,67,15.17,5.3,443,9.921,0,0,33829
"Candies, MARS SNACKFOOD US, POP'ABLES MILKY WAY Brand Bite Size Candies",Sweets,3.3,0.091,0.146,1,0.0006,0,71.85,61.08,18,5.78,463,8.889,0,0,33830
"Candies, MARS SNACKFOOD US, POP'ABLES SNICKERS Brand Bite Size Candies",Sweets,7.15,0.096,0.224,2.3,0.0005,0,61.07,51.69,24.32,5.78,480,9.891,0,0,33831
"Candies, MARS SNACKFOOD US, SKITTLES Original Bite Size Candies",Sweets,0.19,0,0.015,0,0.0668,0.012,90.78,75.84,4.37,3.83,405,4.115,0,0,33832
"Candies, MARS SNACKFOOD US, SKITTLES Sours Original",Sweets,0.18,0,0.014,0,0.0611,0,91.02,73.16,4,3.83,401,3.765,0,0,33833
"Candies, MARS SNACKFOOD US, SKITTLES Tropical Bite Size Candies",Sweets,0.19,0,0.015,0,0.06709999999999999,0,90.77,75.76,4.34,3.83,405,0,0,0,33834
"Candies, MARS SNACKFOOD US, SKITTLES Wild Berry Bite Size Candies",Sweets,0.19,0,0.015,0,0.06709999999999999,0,90.76,75.92,4.25,3.83,402,4.115,0,0,33835
"Candies, MARS SNACKFOOD US, SNICKERS Almond bar",Sweets,5.4,0.132,0.156,2.6,0.0007,0,64.67,53.93,22.4,5.45,472,8.534,0,0,33836
"Candies, MARS SNACKFOOD US, SNICKERS Bar",Sweets,7.53,0.093,0.239,2.3,0.0005,0.323,61.51,50.47,23.85,5.55,491,9.069,7.875,3.014,33837
"Candies, MARS SNACKFOOD US, SNICKERS CRUNCHER",Sweets,6.86,0.09,0.189,1.9,0.0005,0,62.85,46.54,24.38,4.47,488,12.703,0,0,33838
"Candies, MARS SNACKFOOD US, SNICKERS MUNCH bar",Sweets,15.25,0.054,0.358,4.7,0,0,43.64,30.87,36.22,2.99,536,8.819,0,0,33839
"Candies, MARS SNACKFOOD US, STARBURST Fruit Chews, Fruit and Creme",Sweets,0.41,0,0.002,0,0.0583,0,82.43,57.98,8.36,8.7,408,7.931,0,0,33840
"Candies, MARS SNACKFOOD US, STARBURST Fruit Chews, Original fruits",Sweets,0.41,0,0.002,0,0.0587,0.002,82.57,58.12,8.21,8.51,408,7.778,0,0,33841
"Candies, MARS SNACKFOOD US, STARBURST Fruit Chews, Tropical fruits",Sweets,0.41,0,0.002,0,0.0594,0,82.76,58.18,8.31,8.51,409,8.048,0,0,33842
"Candies, MARS SNACKFOOD US, STARBURST Sour Fruit Chews",Sweets,0.39,0,0.089,0,0.056600000000000004,0,79.73,56.1,7.78,10.89,400,7.52,0,0,33843
"Candies, MARS SNACKFOOD US, TWIX Caramel Cookie Bars",Sweets,4.91,0.106,0.198,1.1,0.0006,0.186,64.8,48.25,24.85,4.23,502,18.96,2.988,0.628,33844
"Candies, MARS SNACKFOOD US, TWIX Peanut Butter Cookie Bars",Sweets,9.18,0.075,0.226,3.1,0.0003,0.264,54.15,36.13,32.67,1.82,536,15.758,12.317,3.407,33845
"Candies, MARS SNACKFOOD US, TWIX chocolate fudge cookie bars",Sweets,7.3,0.13,0.266,3,0.001,0.309,56,53,33.3,2.2,550,4.962,14.319,12.521,33846
"Candies, MOUNDS Candy Bar",Sweets,4.6,0.021,0.145,3.7,0.0004,0.321,58.59,46.19,26.6,9.05,486,20.58,0.39,0.14,33847
"Candies, MR. GOODBAR Chocolate Bar",Sweets,10.22,0.11,0.041,3.8,0.0009,0.394,54.34,47.22,33.21,0.45,538,14.13,8.203,4.366,33848
"Candies, NESTLE, 100 GRAND Bar",Sweets,2.5,0.076,0.203,1,0.0007,0.163,70.97,51.9,19.33,6.1,468,11.9,6.039,1.388,33849
"Candies, NESTLE, AFTER EIGHT Mints",Sweets,1.67,0.013,0.001,2.4,0,0,79.53,66.6,11.9,6.3,432,8.33,0,0,33850
"Candies, NESTLE, BABY RUTH Bar",Sweets,5.4,0.047,0.23,2,0,0.249,64.8,54,21.6,6.99,459,12.1,5.557,2.638,33851
"Candies, NESTLE, BIT-O'-HONEY Candy Chews",Sweets,2,0.035,0.295,0.2,0.0008,0.044,80,48,7.5,8.3,375,5.5,1.03,0.338,33852
"Candies, NESTLE, BUTTERFINGER Bar",Sweets,5.4,0.036,0.23,2,0,0.22,72.9,45.9,18.9,1.23,459,9.5,5.119,3.164,33853
"Candies, NESTLE, CHUNKY Bar",Sweets,7.5,0.1,0.038,2.5,0,0,60,52.5,27.5,2.9,475,12.5,0,0,33854
"Candies, NESTLE, CRUNCH Bar and Dessert Topping",Sweets,5,0.1,0.15,1.9,0.0005,0.305,67,55,26,0.65,500,16,0,0,33855
"Candies, NESTLE, GOOBERS Chocolate Covered Peanuts",Sweets,9.7,0.089,0.036,9.7,0.0003,0.502,53,43,34,1.9,512,12.1,0,0,33856
"Candies, NESTLE, OH HENRY! Bar",Sweets,7.7,0.069,0.193,1.9,0,0.259,65.5,46.2,23,2.3,462,9.6,5.53,2.706,33857
"Candies, NESTLE, RAISINETS Chocolate Covered Raisins",Sweets,4.4,0.085,0.033,2.2,0.0016,0,71,59,17,6.4,422,11,0,0,33858
"Candies, REESE'S BITES",Sweets,11.34,0.112,0.179,3.1,0.0008,0.382,55.18,48.03,29.85,1.65,521,18,7.06,1.82,33859
"Candies, REESE'S NUTRAGEOUS Candy Bar",Sweets,11.28,0.068,0.141,3.9,0.0005,0.364,52.8,40.22,32.09,1.5,517,8.86,12.63,8.09,33860
"Candies, REESE'S PIECES Candy",Sweets,12.46,0.069,0.194,3,0,0.359,59.86,53.25,24.77,1,497,16.42,4.46,1.88,33861
"Candies, REESE'S Peanut Butter Cups",Sweets,10.24,0.078,0.357,3.6,0.0003,0.343,55.36,47.17,30.53,1.44,515,10.73,13.1,5.5,33862
"Candies, REESE'S, FAST BREAK, milk chocolate peanut butter and soft nougats",Sweets,8.66,0.061,0.33,2.9,0.0004,0.284,61.6,53.08,23.42,4.1,474,8.23,8.94,4.33,33863
"Candies, REESE's Fast Break, milk chocolate, peanut butter, soft nougats, candy bar",Sweets,8.93,0.036,0.321,3.6,0,0.257,63.9,53.57,23.21,1.66,495,8.036,8.104,4.241,33864
"Candies, ROLO Caramels in Milk Chocolate",Sweets,5.08,0.145,0.188,0.9,0.0009,0.188,67.95,63.93,20.93,4.6,474,14.542,2.104,0.265,33865
"Candies, SKOR Toffee Bar",Sweets,3.13,0,0.317,1.3,0.0005,0.153,61.72,59.89,32.18,1.57,535,18.78,9.29,1.28,33866
"Candies, SPECIAL DARK Chocolate Bar",Sweets,5.54,0.03,0.006,6.5,0,0.502,60.49,47.56,32.4,0.9,556,0,5.14,0.44,33867
"Candies, SYMPHONY Milk Chocolate Bar",Sweets,8.51,0.251,0.101,1.7,0.0022,0.438,58.01,53.96,30.57,0.94,531,18.34,7.91,0.69,33868
"Candies, TOOTSIE ROLL, chocolate-flavor roll",Sweets,1.59,0.036,0.044,0.1,0,0.116,87.73,56.32,3.31,6.69,387,0.967,1.929,0.266,33869
"Candies, TWIZZLERS CHERRY BITES",Sweets,2.97,0.012,0.261,0.1,0,0.014,79.38,0,1.7,15,338,0.25,0.49,0.04,33870
"Candies, TWIZZLERS NIBS CHERRY BITS",Sweets,2.3,0.008,0.195,0.6,0,0.037,79.37,51.35,2.64,15,347,0.54,1.88,0.14,33871
"Candies, TWIZZLERS Strawberry Twists Candy",Sweets,2.56,0,0.287,0,0,0,79.74,39.64,2.32,0,350,0,0,0,33872
"Candies, WHATCHAMACALLIT Candy Bar",Sweets,8.04,0.118,0.299,1.9,0.0009,0.303,63.23,48.86,23.68,3.04,494,17.04,3.78,0.83,33873
"Candies, YORK BITES",Sweets,1.78,0.01,0.046,2,0,0.108,81.64,75,7.32,9.09,394,4.43,0.48,0.1,33874
"Candies, YORK Peppermint Pattie",Sweets,2.19,0.011,0.028,2,0,0.111,80.99,63.78,7.17,9.03,384,4.34,0.41,0.11,33875
"Candies, butterscotch",Sweets,0.03,0.004,0.391,0,0,0.003,90.4,80.46,3.3,5.25,391,2.065,0.845,0.122,33876
"Candies, caramels",Sweets,4.6,0.138,0.245,0,0.0004,0.214,77,65.5,8.1,8.5,382,2.476,1.542,3.478,33877
"Candies, caramels, chocolate-flavor roll",Sweets,1.59,0.036,0.044,0.1,0,0.116,85.97,56.32,5.07,6.69,396,0.963,3.836,0.268,33878
"Candies, carob, unsweetened",Sweets,8.15,0.303,0.107,3.8,0.0005,0.633,56.29,34.11,31.36,1.54,540,29.018,0.483,0.295,33879
"Candies, chocolate covered, caramel with nuts",Sweets,9.5,0.078,0.024,4.3,0.0014,0.445,60.67,41.35,21,6.1,470,4.657,9.444,5.834,33880
"Candies, chocolate covered, dietetic or low calorie",Sweets,12.26,0.285,0.112,3.6,0.0019,0.606,38.55,14.57,43.27,3.23,593,21.91,10.942,4.354,33881
"Candies, chocolate, dark, NFS (45-59% cacao solids 90%; 60-69% cacao solids 5%; 70-85% cacao solids 5%)",Sweets,5.09,0.057,0.023,7.2,0,0.568,59.97,46.14,32.2,1,550,18.993,9.801,1.107,33882
"Candies, confectioner's coating, butterscotch",Sweets,2.2,0.034,0.089,0,0.0001,0.064,67.1,67.1,29.05,0.75,539,24.1,2.271,1.041,33883
"Candies, confectioner's coating, peanut butter",Sweets,18.3,0.11,0.25,5,0.0003,0.505,46.88,38.3,29.8,2.67,529,13.12,12.829,2.053,33884
"Candies, confectioner's coating, yogurt",Sweets,5.87,0.205,0.088,0,0.0005,0.29,63.94,62.36,27,1.91,522,24.104,0.526,0.524,33885
"Candies, crispy bar with peanut butter filling",Sweets,9.53,0.07,0.264,3.3,0.0006,0.295,55.53,40.33,31.34,1.6,542,12.959,10.82,4.68,33886
"Candies, dark chocolate coated coffee beans",Sweets,7.5,0.1,0.025,7.5,0,0.342,59.95,42.5,30,1.47,540,15,0,0,33887
"Candies, divinity, prepared-from-recipe",Sweets,1.32,0.005,0.034,0,0,0.023,89.05,78.96,0.06,9.16,364,0,0,0,33888
"Candies, fondant, prepared-from-recipe",Sweets,0,0.003,0.011,0,0,0.004,93.18,88.9,0.02,6.74,373,0,0,0,33889
"Candies, fudge, chocolate marshmallow, prepared-from-recipe",Sweets,2.26,0.045,0.085,1.7,0.0002,0.147,71.34,64.06,17.48,7.82,453,10.669,5.205,0.611,33890
"Candies, fudge, chocolate marshmallow, with nuts, prepared-by-recipe",Sweets,3.24,0.049,0.079,2.1,0.0003,0.17,67.69,0,21.11,7.12,474,10.378,5.506,4.081,33891
"Candies, fudge, chocolate, prepared-from-recipe",Sweets,2.39,0.049,0.045,1.7,0,0.134,76.44,73.12,10.41,9.81,411,6.448,2.943,0.373,33892
"Candies, fudge, chocolate, with nuts, prepared-from-recipe",Sweets,4.38,0.057,0.039,2.5,0.0002,0.183,67.93,63.37,18.93,7.66,461,6.489,3.9,7.529,33893
"Candies, fudge, peanut butter, prepared-from-recipe",Sweets,3.78,0.042,0.118,0.7,0,0.119,77.75,73.36,6.59,10.93,387,1.721,2.867,1.588,33894
"Candies, fudge, vanilla with nuts",Sweets,3,0.047,0.042,0.9,0.0001,0.103,74.61,71,13.69,7.99,435,3.828,2.454,6.614,33895
"Candies, fudge, vanilla, prepared-from-recipe",Sweets,1.05,0.038,0.047,0,0,0.049,82.15,79.77,5.45,10.85,383,3.382,1.399,0.227,33896
"Candies, gum drops, dietetic or low calorie (sorbitol)",Sweets,0,0,0.007,18,0,0,88,70,0.2,11.7,162,0.034,0.092,0.064,33897
"Candies, gumdrops, starch jelly pieces",Sweets,0,0.003,0.044,0.1,0,0.005,98.9,58.97,0,1,396,0,0,0,33898
"Candies, halavah, plain",Sweets,12.49,0.033,0.195,4.5,0.0001,0.187,60.49,0,21.52,3.67,469,4.127,8.194,8.481,33899
"Candies, hard",Sweets,0,0.003,0.038,0,0,0.005,98,62.9,0.2,1.3,394,0,0,0,33900
"Candies, hard, dietetic or low calorie (sorbitol)",Sweets,0,0,0,0,0,0,98.6,93,0,1.4,394,0,0,0,33901
"Candies, jellybeans",Sweets,0,0.003,0.05,0.2,0,0.037,93.55,70,0.05,6.3,375,0,0,0,33902
"Candies, marshmallows",Sweets,1.8,0.003,0.08,0.1,0,0.005,81.3,57.56,0.2,16.4,318,0.056,0.08,0.047,33903
"Candies, milk chocolate",Sweets,7.65,0.189,0.079,3.4,0,0.372,59.4,51.5,29.66,1.5,535,18.509,7.186,1.376,33904
"Candies, milk chocolate coated coffee beans",Sweets,7.41,0.169,0.07,5.7,0,0.413,55.25,50.75,33.18,2.42,549,18.138,13.735,1.31,33905
"Candies, milk chocolate coated peanuts",Sweets,13.1,0.104,0.041,4.7,0,0.502,49.7,37.59,33.5,1.9,519,14.6,12.92,4.33,33906
"Candies, milk chocolate coated raisins",Sweets,4.1,0.086,0.036,3.1,0.0002,0.514,68.4,62.22,14.8,11.2,390,10.272,3.134,0.571,33907
"Candies, milk chocolate, with almonds",Sweets,9,0.224,0.074,6.2,0.0002,0.444,53.4,43.9,34.4,1.5,526,17.701,11.96,3.019,33908
"Candies, milk chocolate, with rice cereal",Sweets,7.64,0.187,0.086,3.3,0.0006,0.37,59.67,51.06,29.37,1.52,511,15.89,8.64,0.78,33909
"Candies, nougat, with almonds",Sweets,3.33,0.032,0.033,3.3,0.0003,0.105,92.39,83.33,1.67,2.15,398,1.667,0,0,33910
"Candies, peanut bar",Sweets,15.5,0.078,0.156,4.1,0,0.407,47.4,42.22,33.7,1.6,522,4.678,16.721,10.65,33911
"Candies, peanut brittle, prepared-from-recipe",Sweets,7.57,0.027,0.445,2.5,0,0.168,71.24,51.33,18.98,0.7,486,4.144,8.064,4.558,33912
"Candies, praline, prepared-from-recipe",Sweets,3.3,0.043,0.048,3.5,0.0003,0.217,59.59,55.79,25.9,10.36,485,2.224,14.683,7.778,33913
"Candies, semisweet chocolate",Sweets,4.2,0.032,0.011,5.9,0,0.365,63.9,54.5,30,0.7,480,17.75,9.966,0.966,33914
"Candies, semisweet chocolate, made with butter",Sweets,4.2,0.032,0.011,5.9,0,0.365,63.4,0,29.7,0.7,477,17.53,9.93,0.95,33915
"Candies, sesame crunch",Sweets,11.6,0.639,0.167,7.7,0,0.307,50.3,31.17,33.3,2.2,516,4.662,12.571,14.591,33916
"Candies, soft fruit and nut squares",Sweets,2.31,0.017,0.131,2.4,0.0002,0.082,73.81,45.95,9.52,13.88,390,0.898,1.297,6.84,33917
"Candies, sugar-coated almonds",Sweets,10,0.1,0.013,2.5,0,0.255,68.26,62.5,17.93,2.3,474,1.948,12.078,3.904,33918
"Candies, sweet chocolate",Sweets,3.9,0.024,0.016,5.5,0,0.29,60.4,51.48,34.2,0.5,507,20.08,11.22,0.99,33919
"Candies, sweet chocolate coated fondant",Sweets,2.2,0.017,0.026,2.1,0,0.168,80.4,71.42,9.3,7.7,366,5.46,3.09,0.3,33920
"Candies, taffy, prepared-from-recipe",Sweets,0.03,0.008,0.052,0,0,0.003,91.56,68.48,3.33,4.85,397,2.049,0.838,0.121,33921
"Candies, toffee, prepared-from-recipe",Sweets,1.07,0.034,0.135,0,0.0002,0.051,64.72,63.47,32.75,0.55,560,20.565,8.964,1.222,33922
"Candies, truffles, prepared-from-recipe",Sweets,6.21,0.157,0.068,2.5,0.0001,0.297,44.88,38.31,33.76,13.47,510,18.488,12.636,1.013,33923
"Candies, white chocolate",Sweets,5.87,0.199,0.09,0.2,0.0005,0.286,59.24,59,32.09,1.3,539,19.412,9.097,1.013,33924
Chewing gum,Sweets,0,0,0.001,2.4,0,0.002,96.7,66.08,0.3,2.6,360,0.042,0.079,0.137,33925
"Chewing gum, sugarless",Sweets,0,0.02,0.007,2.4,0,0,94.8,0,0.4,3.5,268,0.058,0.093,0.232,33926
"Chocolate, dark, 45- 59% cacao solids",Sweets,4.88,0.056,0.024,7,0,0.559,61.17,47.9,31.28,0.97,546,18.519,9.54,1.092,33927
"Chocolate, dark, 60-69% cacao solids",Sweets,6.12,0.062,0.01,8,0,0.567,52.42,36.71,38.31,1.25,579,22.031,11.522,1.221,33928
"Chocolate, dark, 70-85% cacao solids",Sweets,7.79,0.073,0.02,10.9,0,0.715,45.9,23.99,42.63,1.37,598,24.489,12.781,1.257,33929
Chocolate-flavored hazelnut spread,Sweets,5.41,0.108,0.041,5.4,0,0.407,62.16,54.05,29.73,1.07,541,28.423,0,0,33930
"Cocoa, dry powder, hi-fat or breakfast, plain",Sweets,16.8,0.122,0.02,29.8,0,1.574,51.39,1.55,23.71,3,486,12.64,7.38,0.769,33931
"Cocoa, dry powder, hi-fat or breakfast, processed with alkali",Sweets,16.8,0.122,0.02,33.9,0,2.509,49.71,1.53,23.71,3,479,12.64,7.38,0.769,33932
"Cocoa, dry powder, unsweetened",Sweets,19.6,0.128,0.021,33.2,0,1.524,57.9,1.75,13.7,3,228,8.07,4.57,0.44,33933
"Cocoa, dry powder, unsweetened, HERSHEY'S European Style Cocoa",Sweets,20,0,0,20,0,0,60,0,10,0,400,0,0,0,33934
"Cocoa, dry powder, unsweetened, processed with alkali",Sweets,18.1,0.111,0.019,29.8,0,2.509,58.3,1.76,13.1,2.7,220,7.76,4.39,0.42,33935
"Desserts, apple crisp, prepared-from-recipe",Sweets,1.75,0.035,0.351,1.4,0.0022,0.078,30.84,19.68,3.43,62.91,161,0.685,1.334,1.144,33936
"Desserts, egg custard, baked, prepared-from-recipe",Sweets,5.02,0.107,0.061,0,0,0.148,11,11,4.58,78.86,104,2.164,1.379,0.41,33937
"Desserts, flan, caramel custard, prepared-from-recipe",Sweets,4.53,0.083,0.053,0,0,0.118,22.78,23.15,4.03,68.05,145,1.799,1.266,0.392,33938
"Desserts, mousse, chocolate, prepared-from-recipe",Sweets,4.14,0.096,0.038,0.6,0.0001,0.143,16.07,14.81,16,62.94,225,9.151,5.027,0.879,33939
"Desserts, rennin, chocolate, dry mix",Sweets,2.4,0.166,0.07,5.1,0,0.43,91.5,0,3.3,1,363,1.94,1.08,0.1,33940
"Desserts, rennin, tablets, unsweetened",Sweets,1,3.733,26.05,0,0,0.292,19.8,0,0.1,6.5,84,0.041,0.038,0.007,33941
"Desserts, rennin, vanilla, dry mix",Sweets,0,0.117,0.006,0,0,0.003,99,0,0,0.4,383,0,0,0,33942
"Egg custards, dry mix",Sweets,6.9,0.228,0.281,0,0.0004,0.54,82.8,0,6.4,1.7,410,2.03,2.4,0.85,33943
"Egg custards, dry mix, prepared with 2% milk",Sweets,4.13,0.146,0.087,0,0.0002,0.214,17.61,4.63,2.83,74.44,112,1.475,0.896,0.201,33944
"Egg custards, dry mix, prepared with whole milk",Sweets,3.99,0.139,0.084,0,0.0001,0.207,17.6,4.82,4,73.45,122,2.032,1.127,0.312,33945
"Flan, caramel custard, dry mix",Sweets,0,0.024,0.432,0,0,0.153,91.6,0,0,7.8,348,0,0,0,33946
"Flan, caramel custard, dry mix, prepared with 2% milk",Sweets,2.99,0.113,0.113,0,0.0007,0.163,18.82,0,1.72,75.71,103,1.035,0.472,0.064,33947
"Flan, caramel custard, dry mix, prepared with whole milk",Sweets,2.95,0.111,0.112,0,0.0007,0.16,18.68,0,3,74.63,113,1.8,0.821,0.111,33948
"Frostings, chocolate, creamy, dry mix",Sweets,1.3,0.011,0.076,2.4,0,0.18,92,0,5.2,0.6,389,0,0,0,33949
"Frostings, chocolate, creamy, dry mix, prepared with butter",Sweets,1.11,0.012,0.124,1.9,0,0.143,71.8,0.01,13.06,13.33,408,8.369,3.425,0.496,33950
"Frostings, chocolate, creamy, dry mix, prepared with margarine",Sweets,1.1,0.012,0.163,1.9,0,0.143,71.02,0,12.87,14.07,404,1.74,3.943,2.797,33951
"Frostings, chocolate, creamy, ready-to-eat",Sweets,1.1,0.008,0.183,0.9,0,0.196,63.2,57.7,17.6,17,397,5.526,9.02,2.13,33952
"Frostings, coconut-nut, ready-to-eat",Sweets,1.5,0.013,0.195,2.5,0.0002,0.186,52.7,40,24,21,433,8.631,10.786,3.264,33953
"Frostings, cream cheese-flavor, ready-to-eat",Sweets,0.1,0.003,0.191,0,0,0.035,67.32,63.54,17.3,15.1,415,4.545,3.755,6.153,33954
"Frostings, glaze, chocolate, prepared-from-recipe, with butter, NFSMI Recipe No. C-32",Sweets,1.42,0.032,0.132,1.1,0.0001,0.13,72.18,64.3,7.17,18.27,359,4.483,1.894,0.294,33955
"Frostings, glaze, prepared-from-recipe",Sweets,0.44,0.017,0.006,0,0,0.03,83.65,80.44,0.53,15.27,341,0.271,0.137,0.067,33956
"Frostings, vanilla, creamy, dry mix",Sweets,0.3,0.003,0.013,0.1,0,0.007,93.8,0,4.9,0.7,410,0,0,0,33957
"Frostings, vanilla, creamy, dry mix, prepared with margarine",Sweets,0.34,0.006,0.114,0.1,0,0.01,74.28,0,12.74,12.18,413,1.74,3.942,2.797,33958
"Frostings, vanilla, creamy, ready-to-eat",Sweets,0,0.003,0.184,0,0,0.034,67.89,63.1,16.23,15.07,418,2.974,4.86,7.951,33959
"Frostings, white, fluffy, dry mix",Sweets,2.3,0.004,0.234,0,0,0.117,94.9,0,0,1.9,371,0,0,0,33960
"Frostings, white, fluffy, dry mix, prepared with water",Sweets,1.5,0.004,0.156,0,0,0.077,62.6,0,0,35.2,244,0,0,0,33961
"Frozen novelites, juice type, juice with cream",Sweets,1.41,0.141,0.042,0.1,0.0212,0.12,24.11,20.61,1.41,72.57,115,0.705,0,0,33962
"Frozen novelties, Fat Free FUDGESICLE bars",Sweets,5.55,0.159,0.094,1.7,0.001,0,26.94,20.1,0.66,65.94,127,0.48,0,0,33963
"Frozen novelties, KLONDIKE, SLIM-A-BEAR Chocolate Cone",Sweets,4.09,0.125,0.159,4.3,0.0006,0,45.32,24.62,4.1,45.51,224,1.001,0,0,33964
"Frozen novelties, KLONDIKE, SLIM-A-BEAR Chocolate Sandwich",Sweets,6.21,0.144,0.188,4.8,0.0007,0,44.12,21.5,2.43,46.53,212,0.81,0,0,33965
"Frozen novelties, KLONDIKE, SLIM-A-BEAR Fudge Bar, 98% fat free, no sugar added",Sweets,4.31,0.138,0.12,6,0.0009,0,30.07,7.1,1.88,63.51,124,1,0,0,33966
"Frozen novelties, KLONDIKE, SLIM-A-BEAR Mint Sandwich",Sweets,5.96,0.142,0.191,4.3,0.0007,0,44.16,21.81,2.34,46.65,210,0.74,0,0,33967
"Frozen novelties, KLONDIKE, SLIM-A-BEAR Vanilla Cone",Sweets,3.72,0.122,0.16,3.8,0.0006,0,44.75,24.75,4,46.57,222,0.95,0,0,33968
"Frozen novelties, KLONDIKE, SLIM-A-BEAR Vanilla Sandwich",Sweets,5.96,0.142,0.191,4.3,0.0007,0,44.2,21.82,2.34,46.65,211,0.74,0,0,33969
"Frozen novelties, KLONDIKE, SLIM-A-BEAR, No Sugar Added, Stickless Bar",Sweets,5.16,0.289,0.086,4.8,0.001,0,27.09,8.64,11.93,54.69,224,9.51,0,0,33970
"Frozen novelties, No Sugar Added CREAMSICLE Pops",Sweets,1.78,0.137,0.041,0.3,0.0004,0,14.54,2.75,0.86,82.38,57,0.47,0,0,33971
"Frozen novelties, No Sugar Added, FUDGESICLE pops",Sweets,3.6,0.101,0.102,1.6,0.0007,0.196,22.28,3.93,0.97,71.85,105,0.591,0.157,0.089,33972
"Frozen novelties, Sugar Free, CREAMSICLE Pops",Sweets,1.37,0.023,0.006,7.5,0,0,11.95,0.43,2.33,83.91,49,2,0,0,33973
"Frozen novelties, fruit and juice bars",Sweets,1.2,0.005,0.004,1,0.0095,0.053,20.2,17.48,0.1,78.3,87,0,0,0.03,33974
"Frozen novelties, ice cream type, chocolate or caramel covered, with nuts",Sweets,4.4,0.252,0.092,0.6,0.0007,0.238,30.9,0,20.2,43.1,317,12.658,4.977,1.469,33975
"Frozen novelties, ice cream type, sundae, prepackaged",Sweets,4.3,0.12,0.095,0.3,0.0018,0.211,29.4,20.74,6,59.3,185,3.09,1.872,0.663,33976
"Frozen novelties, ice cream type, vanilla ice cream, light, no sugar added, chocolate coated",Sweets,6.4,0.222,0.104,0.8,0,0.305,26.11,8.52,10.1,56.2,221,5.528,2.7,1.359,33977
"Frozen novelties, ice type, fruit, no sugar added",Sweets,0.5,0.002,0.005,0,0,0.026,6.2,0,0.1,93.2,24,0,0,0,33978
"Frozen novelties, ice type, italian, restaurant-prepared",Sweets,0.03,0.001,0.004,0,0.0005,0.006,13.5,0,0.02,86.4,53,0,0,0,33979
"Frozen novelties, ice type, lime",Sweets,0.4,0.002,0.022,0,0.001,0.003,32.6,32.6,0,66.9,128,0,0,0,33980
"Frozen novelties, ice type, pineapple-coconut",Sweets,0,0,0.035,0.7,0.0132,0.017,23.9,0,2.6,73.3,113,2.304,0.111,0.029,33981
"Frozen novelties, ice type, pop",Sweets,0,0,0.007,0,0.0007,0.015,19.23,13.66,0.24,80.49,79,0.013,0.051,0.016,33982
"Frozen novelties, ice type, pop, with low calorie sweetener",Sweets,0,0,0.01,0,0.0104,0.011,5.92,1.52,0,94.03,24,0,0,0,33983
"Frozen novelties, ice type, sugar free, orange, cherry, and grape POPSICLE pops",Sweets,0,0,0.01,0,0.0104,0.011,5.14,1.52,0,94.03,21,0,0,0,33984
"Frozen novelties, juice type, POPSICLE SCRIBBLERS",Sweets,0,0.005,0.013,0,0.0012,0.023,19.68,14.57,0.24,79.96,81,0,0,0,33985
"Frozen novelties, juice type, orange",Sweets,0.5,0.009,0.008,0.1,0.0257,0.1,23.17,19.99,0,76.1,95,0,0,0,33986
"Frozen yogurts, chocolate",Sweets,3,0.1,0.063,2.3,0,0.234,21.6,19.24,3.6,71.2,127,2.272,0.961,0.102,33987
"Frozen yogurts, chocolate, nonfat milk, sweetened without sugar",Sweets,4.4,0.159,0.081,2,0.0007,0.339,19.7,12.61,0.8,73.5,107,0.505,0.214,0.03,33988
"Frozen yogurts, chocolate, soft-serve",Sweets,4,0.147,0.098,2.2,0.0003,0.261,24.9,0,6,63.8,160,3.63,1.75,0.22,33989
"Frozen yogurts, flavors other than chocolate",Sweets,3,0.1,0.063,0,0.0007,0.156,21.6,19.92,3.6,71.2,127,2.326,0.986,0.1,33990
"Frozen yogurts, vanilla, soft-serve",Sweets,4,0.143,0.087,0,0.0008,0.211,24.2,24,5.6,65.3,159,3.42,1.59,0.21,33991
"Fruit butters, apple",Sweets,0.39,0.014,0.015,1.5,0.0006,0.091,42.47,35.3,0.3,56.45,173,0.053,0.016,0.084,33992
"Gelatin desserts, dry mix",Sweets,7.8,0.003,0.466,0,0,0.007,90.5,86.04,0,1,381,0,0,0,33993
"Gelatin desserts, dry mix, prepared with water",Sweets,1.22,0.003,0.075,0,0,0.001,14.19,13.49,0,84.39,62,0,0,0,33994
"Gelatin desserts, dry mix, reduced calorie, with aspartame",Sweets,15.67,0.008,0.862,0.1,0,0.014,80.21,0,0,1.93,198,0,0,0,33995
"Gelatin desserts, dry mix, reduced calorie, with aspartame, added phosphorus, potassium, sodium, vitamin C",Sweets,55.3,0.002,2.751,0,0.49,1.985,33.3,0,0,6.7,345,0,0,0,33996
"Gelatin desserts, dry mix, reduced calorie, with aspartame, no added sodium",Sweets,55.3,0.002,0.158,0,0,0.014,33.3,0,0,6.7,345,0,0,0,33997
"Gelatin desserts, dry mix, reduced calorie, with aspartame, prepared with water",Sweets,0.83,0.003,0.048,0,0,0.001,4.22,0,0,94.74,20,0,0,0,33998
"Gelatin desserts, dry mix, with added ascorbic acid, sodium-citrate and salt",Sweets,7.8,0.003,0.491,0,0.0628,0.007,90.5,0,0,1,381,0,0,0,33999
"Gelatins, dry powder, unsweetened",Sweets,85.6,0.055,0.196,0,0,0.016,0,0,0.1,13,335,0.07,0.06,0.01,34000
Honey,Sweets,0.3,0.006,0.004,0.2,0.0005,0.052,82.4,82.12,0,17.1,304,0,0,0,34001
"Ice creams, BREYERS, 98% Fat Free Chocolate",Sweets,3.89,0.126,0.075,5.8,0.0007,0,30.18,20.29,2.17,63.2,136,1.38,0,0,34002
"Ice creams, BREYERS, 98% Fat Free Vanilla",Sweets,3.3,0.122,0.073,5.4,0.0008,0,30.51,20.93,2.2,63.42,137,1.41,0,0,34003
"Ice creams, BREYERS, All Natural Light French Chocolate",Sweets,5.3,0.158,0.075,1,0.001,0,29.68,24.04,7.28,56.58,201,4.28,0,0,34004
"Ice creams, BREYERS, All Natural Light French Vanilla",Sweets,4.82,0.155,0.073,0.2,0.0009,0,26.03,21.11,5.56,62.71,173,3.22,0,0,34005
"Ice creams, BREYERS, All Natural Light Mint Chocolate Chip",Sweets,4.69,0.16,0.067,0.6,0.0014,0,28.39,25.38,7.09,59.11,196,4.48,0,0,34006
"Ice creams, BREYERS, All Natural Light Vanilla",Sweets,4.84,0.169,0.071,0.2,0.0011,0,25.3,22.78,4.59,64.89,162,2.86,0,0,34007
"Ice creams, BREYERS, All Natural Light Vanilla Chocolate Strawberry",Sweets,4.69,0.158,0.069,0.4,0.0017,0,26.06,22.47,4.35,63.32,161,2.7,0,0,34008
"Ice creams, BREYERS, No Sugar Added, Butter Pecan",Sweets,3.99,0.13,0.164,0.9,0.0009,0,21.3,5.9,10.3,63.52,180,4.18,0,0,34009
"Ice creams, BREYERS, No Sugar Added, Chocolate Caramel",Sweets,3.54,0.121,0.077,1,0.0007,0,25.17,5.81,5.8,64.71,151,3.65,0,0,34010
"Ice creams, BREYERS, No Sugar Added, French Vanilla",Sweets,4.5,0.151,0.087,0.5,0.0009,0,20.75,7.7,7.05,66.48,154,4.17,0,0,34011
"Ice creams, BREYERS, No Sugar Added, Vanilla",Sweets,3.68,0.133,0.066,0.5,0.0008,0,21.92,6.09,6.2,67.86,143,3.9,0,0,34012
"Ice creams, BREYERS, No Sugar Added, Vanilla Chocolate Strawberry",Sweets,3.71,0.132,0.068,0.7,0.0008,0,21.7,6.15,6.26,67.34,143,3.94,0,0,34013
"Ice creams, BREYERS, No Sugar Added, Vanilla Fudge Twirl",Sweets,3.53,0.121,0.072,0.8,0.0007,0,25.63,5.49,5.68,64.4,153,3.57,0,0,34014
"Ice creams, chocolate",Sweets,3.8,0.109,0.076,1.2,0.0007,0.249,28.2,25.36,11,55.7,216,6.8,3.21,0.41,34015
"Ice creams, chocolate, light",Sweets,5,0.159,0.071,0.8,0.0012,0.17,25.7,24.71,7.19,61.43,187,4.38,2.055,0.241,34016
"Ice creams, chocolate, light, no sugar added",Sweets,3.54,0.121,0.075,0.9,0.0007,0.196,26.79,5.65,5.74,62.99,173,3.61,0.93,0.527,34017
"Ice creams, chocolate, rich",Sweets,4.72,0.142,0.057,0.9,0.0005,0.238,20.75,17.35,16.98,57.52,255,10.377,4.794,0.658,34018
"Ice creams, french vanilla, soft-serve",Sweets,4.1,0.131,0.061,0.7,0.0008,0.177,22.2,21.16,13,59.8,222,7.48,3.49,0.45,34019
"Ice creams, regular, low carbohydrate, chocolate",Sweets,3.8,0.109,0.076,4.8,0.0007,0.249,26.8,6.35,12.7,55.7,237,6.8,3.21,0.41,34020
"Ice creams, regular, low carbohydrate, vanilla",Sweets,3.17,0.128,0.048,4.8,0.0006,0.199,22.23,6.35,12.7,61,216,6.79,3.17,0.41,34021
"Ice creams, strawberry",Sweets,3.2,0.12,0.06,0.9,0.0077,0.188,27.6,0,8.4,60,192,5.19,0,0,34022
"Ice creams, vanilla",Sweets,3.5,0.128,0.08,0.7,0.0006,0.199,23.6,21.22,11,61,207,6.79,2.969,0.452,34023
"Ice creams, vanilla, fat free",Sweets,4.48,0.149,0.097,1,0,0.302,30.06,6.3,0,64.35,138,0,0,0,34024
"Ice creams, vanilla, light",Sweets,4.78,0.161,0.074,0.3,0.0012,0.208,29.46,22.12,4.83,59.85,180,2.927,1.281,0.219,34025
"Ice creams, vanilla, light, no sugar added",Sweets,3.97,0.136,0.096,0,0.0009,0.196,21.42,6.46,7.45,65.52,169,4.048,1.855,0.74,34026
"Ice creams, vanilla, light, soft-serve",Sweets,4.9,0.157,0.07,0,0.0009,0.221,21.8,18.69,2.6,69.6,126,1.63,0.76,0.1,34027
"Ice creams, vanilla, rich",Sweets,3.5,0.117,0.061,0,0,0.157,22.29,20.65,16.2,57.2,249,10.329,4.46,0.68,34028
Jams and preserves,Sweets,0.37,0.02,0.032,1.1,0.0088,0.077,68.86,48.5,0.07,30.47,278,0.01,0.038,0,34029
"Jams and preserves, apricot",Sweets,0.7,0.02,0.04,0.3,0.0088,0.077,64.4,43.4,0.2,34.5,242,0.01,0,0,34030
"Jams and preserves, dietetic (with sodium saccharin), any flavor",Sweets,0.3,0.009,0,2.5,0,0.069,53.6,37.81,0.3,45.8,132,0.016,0.042,0.151,34031
Jellies,Sweets,0.15,0.007,0.03,1,0.0009,0.054,69.95,51.22,0.02,29.77,266,0.005,0,0.004,34032
"Jellies, reduced sugar, home preserved",Sweets,0.3,0.005,0.002,0.8,0,0.071,46.1,45.3,0.03,53,179,0.009,0,0.008,34033
"Marmalade, orange",Sweets,0.3,0.038,0.056,0.7,0.0048,0.037,66.3,60,0,33.2,246,0,0,0,34034
"Milk dessert, frozen, milk-fat free, chocolate",Sweets,4.3,0.154,0.097,0,0.0007,0.333,37.7,37.7,1,56,167,0.597,0.329,0.03,34035
Molasses,Sweets,0,0.205,0.037,0,0,1.464,74.73,55.49,0.1,21.87,290,0.018,0.032,0.05,34036
"Pectin, liquid",Sweets,0,0,0,2.1,0,0,2.1,0,0,96.9,11,0,0,0,34037
"Pectin, unsweetened, dry mix",Sweets,0.3,0.007,0.2,8.6,0,0.007,90.4,0,0.3,8.7,325,0.05,0.01,0.09,34038
"Pie fillings, apple, canned",Sweets,0.1,0.004,0.047,1,0.0017,0.045,26.1,13.8,0.1,73.4,100,0,0,0,34039
"Pie fillings, blueberry, canned",Sweets,0.41,0.027,0.012,2.6,0.0007,0.115,44.38,37.75,0.2,54.66,181,0,0,0,34040
"Pie fillings, canned, cherry",Sweets,0.37,0.011,0.018,0.6,0.0036,0.105,28,0,0.07,71.23,115,0.018,0.021,0.021,34041
"Pie fillings, cherry, low calorie",Sweets,0.82,0.011,0.012,1.2,0.0028,0.118,11.98,9.15,0.16,86.76,53,0,0,0,34042
"Pudding, lemon, dry mix, regular, prepared with sugar, egg yolk and water",Sweets,0.65,0.008,0.063,0,0,0.006,24.2,13.51,1.12,73.62,109,0.382,0.47,0.168,34043
"Puddings, all flavors except chocolate, low calorie, instant, dry mix",Sweets,0.81,0.143,4.25,0.8,0,0.03,84.66,0.9,0.9,6.84,350,0.099,0.116,0.433,34044
"Puddings, all flavors except chocolate, low calorie, regular, dry mix",Sweets,1.6,0.049,1.765,0.9,0,0.018,86.04,2.9,0.1,10.4,351,0.018,0.032,0.05,34045
"Puddings, banana, dry mix, instant",Sweets,0,0.006,1.499,0,0,0.015,92.7,76.57,0.6,2.8,367,0.09,0.14,0.35,34046
"Puddings, banana, dry mix, instant, prepared with 2% milk",Sweets,2.76,0.102,0.296,0,0.0008,0.131,19.74,0,1.7,74.52,105,0.971,0.46,0.118,34047
"Puddings, banana, dry mix, instant, prepared with whole milk",Sweets,2.62,0.094,0.29,0,0,0.112,19.76,17.38,2.8,73.62,115,1.562,0.698,0.221,34048
"Puddings, banana, dry mix, instant, with added oil",Sweets,0,0.006,1.499,0,0,0.015,89,0,4.4,2.8,386,0.79,1.3,2.12,34049
"Puddings, banana, dry mix, regular",Sweets,0,0.02,0.788,0.3,0,0.017,93,74.32,0.4,4.1,366,0.09,0.03,0.16,34050
"Puddings, banana, dry mix, regular, prepared with 2% milk",Sweets,2.9,0.109,0.164,0,0.0007,0.137,18.43,0,1.73,75.9,101,1.015,0.462,0.088,34051
"Puddings, banana, dry mix, regular, prepared with whole milk",Sweets,2.74,0.101,0.158,0,0,0.118,18.44,15.98,2.89,74.96,111,1.634,0.711,0.195,34052
"Puddings, banana, dry mix, regular, with added oil",Sweets,0,0.02,0.788,0.3,0,0.017,88.4,0,5,4.1,387,0.9,1.48,2.41,34053
"Puddings, chocolate flavor, low calorie, instant, dry mix",Sweets,5.3,0.126,2.838,6.1,0,1.279,78.2,0.7,2.4,4.2,356,0.984,1.154,0.131,34054
"Puddings, chocolate flavor, low calorie, regular, dry mix",Sweets,10.08,1.512,3.326,10.1,0,0.57,74.42,0.7,3,6.8,365,1.578,1.15,0.13,34055
"Puddings, chocolate, dry mix, instant",Sweets,2.3,0.012,1.429,3.6,0,0.236,87.9,67.86,1.9,3.2,378,0.825,0.487,0.348,34056
"Puddings, chocolate, dry mix, instant, prepared with 2% milk",Sweets,3.15,0.104,0.284,0.4,0.0008,0.168,18.89,0,1.92,74.59,105,1.064,0.561,0.132,34057
"Puddings, chocolate, dry mix, instant, prepared with whole milk",Sweets,3.1,0.102,0.284,1,0.0009,0.166,18.8,0,3.1,73.6,111,1.83,0.92,0.18,34058
"Puddings, chocolate, dry mix, regular",Sweets,2.6,0.053,0.353,4.5,0,0.209,89.3,42.88,2.1,4,362,1.234,0.703,0.079,34059
"Puddings, chocolate, dry mix, regular, prepared with 2% milk",Sweets,3.28,0.112,0.102,0.8,0.0001,0.156,19.76,11.85,2.06,73.94,111,1.289,0.602,0.076,34060
"Puddings, chocolate, dry mix, regular, prepared with whole milk",Sweets,3.16,0.106,0.098,0.8,0,0.15,19.64,11.96,3.15,73.13,120,1.81,0.819,0.18,34061
"Puddings, chocolate, ready-to-eat",Sweets,2.09,0.051,0.152,0,0.0003,0.184,23.01,17.17,4.6,69.46,142,1.263,2.731,0.105,34062
"Puddings, chocolate, ready-to-eat, fat free",Sweets,1.93,0.039,0.11,0.3,0,0.208,20.87,15.75,0.3,76.23,93,0,0,0,34063
"Puddings, coconut cream, dry mix, instant",Sweets,0.9,0.008,1.04,4,0.0003,0.096,91.2,64,2.3,2.2,389,2.2,0,0,34064
"Puddings, coconut cream, dry mix, instant, prepared with 2% milk",Sweets,2.9,0.102,0.246,0.1,0.0008,0.132,19.2,0,2.3,74.4,107,1.37,0.62,0.19,34065
"Puddings, coconut cream, dry mix, instant, prepared with whole milk",Sweets,2.9,0.1,0.246,0.1,0.0008,0.129,19.1,0,3.5,73.4,117,2.1,0.96,0.24,34066
"Puddings, coconut cream, dry mix, regular",Sweets,1,0.008,0.682,1.6,0.0003,0.133,81.84,80.54,11.36,3.7,434,11.364,0,0,34067
"Puddings, coconut cream, dry mix, regular, prepared with 2% milk",Sweets,3.1,0.113,0.163,0.2,0.0007,0.159,17.8,0,2.5,75.6,104,1.8,0.52,0.07,34068
"Puddings, coconut cream, dry mix, regular, prepared with whole milk",Sweets,3,0.111,0.162,0.2,0.0007,0.157,17.7,0,3.8,74.6,114,2.57,0.88,0.12,34069
"Puddings, lemon, dry mix, instant",Sweets,0,0.002,1.332,0,0,0.008,95.4,0,0.7,0.7,378,0.1,0.28,0.25,34070
"Puddings, lemon, dry mix, instant, prepared with 2% milk",Sweets,2.76,0.101,0.268,0,0.0008,0.13,20.2,0,1.71,74.16,107,0.974,0.484,0.101,34071
"Puddings, lemon, dry mix, instant, prepared with whole milk",Sweets,2.7,0.099,0.267,0,0.0008,0.127,20.1,0,2.9,73.2,115,1.74,0.85,0.15,34072
"Puddings, lemon, dry mix, regular",Sweets,0.1,0.005,0.506,0.1,0,0.005,91.8,0,0.5,5.2,363,0,0,0,34073
"Puddings, lemon, dry mix, regular, with added oil, potassium, sodium",Sweets,0.1,0,0.849,0.1,0,0.257,90.3,0,1.5,5.2,366,0.27,0.44,0.72,34074
"Puddings, rice, dry mix",Sweets,2.7,0.014,0.366,0.7,0,0.005,91.2,0,0.1,4.8,376,0,0,0,34075
"Puddings, rice, dry mix, prepared with 2% milk",Sweets,3.29,0.105,0.109,0.1,0.0007,0.13,20.81,0,1.63,73.43,111,0.967,0.441,0.06,34076
"Puddings, rice, dry mix, prepared with whole milk",Sweets,3.25,0.103,0.108,0.1,0.0007,0.128,20.68,0,2.82,72.42,121,1.682,0.767,0.104,34077
"Puddings, rice, ready-to-eat",Sweets,3.53,0.052,0.123,0.9,0,0.06,19.4,14.11,2.65,73.34,118,1.764,0.438,0.12,34078
"Puddings, tapioca, dry mix",Sweets,0.1,0.004,0.477,0.2,0,0.005,94.3,65,0.1,4,369,0,0,0,34079
"Puddings, tapioca, dry mix, prepared with 2% milk",Sweets,2.88,0.105,0.121,0,0.0007,0.133,19.56,0,1.67,75.01,105,0.991,0.452,0.061,34080
"Puddings, tapioca, dry mix, prepared with whole milk",Sweets,2.84,0.103,0.12,0,0.0007,0.131,19.43,0,2.89,73.97,115,1.724,0.786,0.107,34081
"Puddings, tapioca, dry mix, with no added salt",Sweets,0.1,0.004,0.008,0.2,0,0.005,94.3,0,0.1,4,369,0,0,0,34082
"Puddings, tapioca, ready-to-eat",Sweets,1.95,0.071,0.145,0,0.0003,0.092,21.69,14.91,3.88,71.84,130,0.962,2.538,0.072,34083
"Puddings, tapioca, ready-to-eat, fat free",Sweets,1.44,0.052,0.187,0,0.0003,0.07,21.31,14.18,0.35,76.25,94,0.122,0.012,0.002,34084
"Puddings, vanilla, dry mix, instant",Sweets,0,0.012,1.441,0,0,0.012,92.9,92.9,0.6,2.6,377,0.432,0.12,0.01,34085
"Puddings, vanilla, dry mix, instant, prepared with whole milk",Sweets,2.7,0.101,0.286,0,0.0008,0.128,19.7,18.03,2.9,73.5,114,1.74,0.84,0.15,34086
"Puddings, vanilla, dry mix, regular",Sweets,0.3,0.005,0.635,0.6,0,0.02,93.5,79.34,0.4,3.9,379,0.09,0.03,0.17,34087
"Puddings, vanilla, dry mix, regular, prepared with 2% milk",Sweets,2.94,0.108,0.159,0,0.0007,0.137,18.53,0,1.73,75.86,101,1.017,0.462,0.088,34088
"Puddings, vanilla, dry mix, regular, prepared with whole milk",Sweets,2.8,0.099,0.156,0.1,0,0.119,18.92,17.09,2.9,74.49,113,1.643,0.714,0.197,34089
"Puddings, vanilla, dry mix, regular, with added oil",Sweets,0.3,0.015,0.754,0,0,0.02,92.4,0,1.1,3.9,369,0.2,0.32,0.53,34090
"Puddings, vanilla, ready-to-eat",Sweets,1.45,0.049,0.142,0,0.0002,0.065,22.6,16.97,3.78,71.66,130,1.016,2.286,0.087,34091
"Puddings, vanilla, ready-to-eat, fat free",Sweets,2.02,0.04,0.191,0,0.0012,0.108,20.16,15.12,0,76.93,89,0,0,0,34092
"Rennin, chocolate, dry mix, prepared with 2% milk",Sweets,3.24,0.126,0.052,0.5,0.0009,0.182,13.47,0,2.06,80.39,85,1.231,0.581,0.073,34093
"Rennin, chocolate, dry mix, prepared with whole milk",Sweets,3.2,0.124,0.051,0.5,0.0008,0.179,13.34,0,3.34,79.29,96,1.999,0.932,0.121,34094
"Rennin, vanilla, dry mix, prepared with 2% milk",Sweets,3.06,0.121,0.046,0,0.0009,0.142,12.34,0,1.77,82.11,77,1.061,0.484,0.065,34095
"Rennin, vanilla, dry mix, prepared with whole milk",Sweets,3.03,0.119,0.046,0,0.0009,0.14,12.21,0,3.07,80.98,89,1.846,0.842,0.114,34096
"Sherbet, orange",Sweets,1.1,0.054,0.046,1.3,0.0023,0.096,30.4,24.32,2,66.1,144,1.16,0.53,0.08,34097
"Snacks, fruit leather, pieces",Sweets,1,0.018,0.403,0,0.056,0.164,82.82,57.58,2.68,12.3,359,0.65,1.48,0.55,34098
"Snacks, fruit leather, rolls",Sweets,0.1,0.032,0.317,0,0.12,0.294,85.8,49.16,3,10.2,371,0.672,1.48,0.552,34099
"Sugar, turbinado",Sweets,0,0.012,0.003,0,0,0.029,99.8,99.19,0,0.03,399,0,0,0,34100
"Sugars, brown",Sweets,0.12,0.083,0.028,0,0,0.133,98.09,97.02,0,1.34,380,0,0,0,34101
"Sugars, granulated",Sweets,0,0.001,0.001,0,0,0.002,99.98,99.8,0,0.02,387,0,0,0,34102
"Sugars, maple",Sweets,0.1,0.09,0.011,0,0,0.274,90.9,84.87,0.2,8,354,0.036,0.064,0.1,34103
"Sugars, powdered",Sweets,0,0.001,0.002,0,0,0.002,99.77,97.81,0,0.23,389,0,0,0,34104
"Sweeteners, for baking, brown, contains sugar and sucralose",Sweets,0,0.063,0.011,0,0,0.13,97.11,45.66,0,2.44,388,0,0,0,34105
"Sweeteners, for baking, contains sugar and sucralose",Sweets,0,0.001,0.002,0,0,0.002,99.53,47.05,0,0.43,398,0,0,0,34106
"Sweeteners, sugar substitute, granulated, brown",Sweets,2.06,0.879,0.572,0.6,0,0.039,84.77,4.03,0,9.14,347,0,0,0,34107
"Sweeteners, tabletop, aspartame, EQUAL, packets",Sweets,2.17,0,0,0,0,0.004,89.08,80.7,0,8.75,365,0,0,0,34108
"Sweeteners, tabletop, fructose, dry, powder",Sweets,0,0,0.012,0,0,0,100,92.7,0,0,368,0,0,0,34109
"Sweeteners, tabletop, fructose, liquid",Sweets,0,0.001,0.002,0.1,0,0,76.1,76,0,23.9,279,0,0,0,34110
"Sweeteners, tabletop, saccharin",Sweets,0.94,0,0.428,0,0,0.004,89.11,85.19,0,8.75,360,0,0,0,34111
"Sweeteners, tabletop, sucralose, SPLENDA packets",Sweets,0,0,0,0,0,0.004,91.17,80.33,0,8.83,336,0,0,0,34112
"Syrups, chocolate, HERSHEY'S Genuine Chocolate Flavored Lite Syrup",Sweets,1.4,0.011,0.1,0,0,0.187,34.56,28.57,0.97,62.16,153,0,0,0,34113
"Syrups, chocolate, fudge-type",Sweets,4.6,0.049,0.346,2.8,0.0002,0.284,62.9,34.82,8.9,21.8,350,3.98,3.857,0.278,34114
"Syrups, corn, dark",Sweets,0,0.018,0.155,0,0,0.044,77.59,26.7,0,22.01,286,0,0,0,34115
"Syrups, corn, high-fructose",Sweets,0,0,0.002,0,0,0,76,26.36,0,24,281,0,0,0,34116
"Syrups, corn, light",Sweets,0,0.013,0.062,0,0,0.001,76.79,26.77,0.2,22.81,283,0,0,0,34117
"Syrups, dietetic",Sweets,0.8,0,0.021,3,0,0,49.2,0.01,0,50,40,0,0,0,34118
"Syrups, grenadine",Sweets,0,0.006,0.027,0,0,0.028,66.91,46.55,0,32.4,268,0,0,0,34119
"Syrups, malt",Sweets,6.2,0.061,0.035,0,0,0.32,71.3,71.3,0,21.1,318,0,0,0,34120
"Syrups, maple",Sweets,0.04,0.102,0.012,0,0,0.212,67.04,60.44,0.06,32.39,260,0.007,0.011,0.017,34121
"Syrups, sorghum",Sweets,0,0.15,0.008,0,0,1,74.9,74.9,0,22.7,290,0,0,0,34122
"Syrups, table blends, cane and 15% maple",Sweets,0,0.012,0.104,0.1,0,0.053,69.52,68.2,0.1,30.1,278,0.02,0.032,0.05,34123
"Syrups, table blends, corn, refiner, and sugar",Sweets,0,0.023,0.071,0,0,0.063,83.9,0,0,15.6,319,0,0,0,34124
"Syrups, table blends, pancake",Sweets,0,0.003,0.082,0,0,0.015,61.47,21.47,0,37.98,234,0,0,0,34125
"Syrups, table blends, pancake, reduced-calorie",Sweets,0,0.01,0.178,0,0,0.003,44.55,32.8,0,54.9,165,0,0,0,34126
"Syrups, table blends, pancake, with 2% maple",Sweets,0,0.005,0.061,0,0,0.006,69.6,42,0.1,30.1,265,0.018,0.032,0.05,34127
"Syrups, table blends, pancake, with 2% maple, with added potassium",Sweets,0,0.005,0.061,0,0,0.022,69.6,0,0.1,30.1,265,0,0,0,34128
"Syrups, table blends, pancake, with butter",Sweets,0,0.002,0.098,0,0,0.003,74.1,0,1.6,24,296,1.01,0.47,0.06,34129
"Toppings, butterscotch or caramel",Sweets,1.5,0.053,0.349,0.9,0.0003,0.084,65.9,0,0.1,32,252,0.11,0.02,0,34130
"Toppings, marshmallow cream",Sweets,0.8,0.003,0.08,0.1,0,0.005,79,46.8,0.3,19.8,322,0.056,0.08,0.047,34131
"Toppings, nuts in syrup",Sweets,4.5,0.035,0.042,2.3,0.0003,0.151,58.08,36.72,22,14.69,448,1.96,4.97,13.73,34132
"Toppings, pineapple",Sweets,0.1,0.006,0.042,0.4,0.0031,0.043,66.4,21.01,0.1,33,253,0.018,0.032,0.05,34133
"Toppings, strawberry",Sweets,0.2,0.006,0.021,0.7,0.013699999999999999,0.051,66.3,27.38,0.1,33,254,0.005,0.014,0.05,34134
"Amaranth grain, cooked",Cereal Grains and Pasta,3.8,0.047,0.006,2.1,0,0.135,18.69,0,1.58,75.16,102,0,0,0,34135
"Amaranth, uncooked",Cereal Grains and Pasta,13.56,0.159,0.004,6.7,0.004200000000000001,0.508,65.25,1.69,7.02,11.29,371,1.459,1.685,2.778,34136
Arrowroot flour,Cereal Grains and Pasta,0.3,0.04,0.002,3.4,0,0.011,88.15,0,0.1,11.37,357,0.019,0.002,0.045,34137
Barley flour or meal,Cereal Grains and Pasta,10.5,0.032,0.004,10.1,0,0.309,74.52,0.8,1.6,12.11,345,0.335,0.205,0.771,34138
Barley malt flour,Cereal Grains and Pasta,10.28,0.037,0.011,7.1,0.0006,0.224,78.3,0.8,1.84,8.21,361,0.386,0.254,0.953,34139
"Barley, hulled",Cereal Grains and Pasta,12.48,0.033,0.012,17.3,0,0.452,73.48,0.8,2.3,9.44,354,0.482,0.295,1.108,34140
"Barley, pearled, cooked",Cereal Grains and Pasta,2.26,0.011,0.003,3.8,0,0.093,28.22,0.28,0.44,68.8,123,0.093,0.057,0.214,34141
"Barley, pearled, raw",Cereal Grains and Pasta,9.91,0.029,0.009,15.6,0,0.28,77.72,0.8,1.16,10.09,352,0.244,0.149,0.56,34142
Buckwheat,Cereal Grains and Pasta,13.25,0.018,0.001,10,0,0.46,71.5,0,3.4,9.75,343,0.741,1.04,1.039,34143
"Buckwheat flour, whole-groat",Cereal Grains and Pasta,12.62,0.041,0.011,10,0,0.577,70.59,2.6,3.1,11.15,335,0.677,0.949,0.949,34144
"Buckwheat groats, roasted, cooked",Cereal Grains and Pasta,3.38,0.007,0.004,2.7,0,0.088,19.94,0.9,0.62,75.63,92,0.134,0.188,0.188,34145
"Buckwheat groats, roasted, dry",Cereal Grains and Pasta,11.73,0.017,0.011,10.3,0,0.32,74.95,0,2.71,8.41,346,0.591,0.828,0.828,34146
"Bulgur, cooked",Cereal Grains and Pasta,3.08,0.01,0.005,4.5,0,0.068,18.58,0.1,0.24,77.76,83,0.042,0.031,0.098,34147
"Bulgur, dry",Cereal Grains and Pasta,12.29,0.035,0.017,18.3,0,0.41,75.87,0.41,1.33,9,342,0.232,0.173,0.541,34148
"Corn bran, crude",Cereal Grains and Pasta,8.36,0.042,0.007,79,0,0.044,85.64,0,0.92,4.71,224,0.13,0.243,0.421,34149
"Corn flour, degermed, unenriched, yellow",Cereal Grains and Pasta,5.59,0.002,0.001,1.9,0,0.09,82.75,0.64,1.39,9.81,375,0.171,0.274,0.695,34150
"Corn flour, masa, enriched, white",Cereal Grains and Pasta,9.28,0.136,0.005,6.4,0,0.263,76.29,1.61,3.86,9.04,365,0.532,0.997,1.724,34151
"Corn flour, masa, enriched, yellow",Cereal Grains and Pasta,9.28,0.136,0.005,6.4,0,0.263,76.29,0,3.86,9.04,365,0.532,0.997,1.724,34152
"Corn flour, masa, unenriched, white",Cereal Grains and Pasta,9.28,0.136,0.005,6.4,0,0.263,76.29,1.61,3.86,9.04,365,0.532,0.997,1.724,34153
"Corn flour, whole-grain, blue (harina de maiz morado)",Cereal Grains and Pasta,8.75,0.005,0.005,8.4,0,0.381,73.89,0,5.09,10.83,364,0,0,0,34154
"Corn flour, whole-grain, white",Cereal Grains and Pasta,6.93,0.007,0.005,7.3,0,0.315,76.85,0.64,3.86,10.91,361,0.543,1.018,1.759,34155
"Corn flour, whole-grain, yellow",Cereal Grains and Pasta,6.93,0.007,0.005,7.3,0,0.315,76.85,0.64,3.86,10.91,361,0.543,1.018,1.759,34156
"Corn, white",Cereal Grains and Pasta,9.42,0.007,0.035,0,0,0.287,74.26,0,4.74,10.37,365,0.667,1.251,2.163,34157
"Corn, yellow",Cereal Grains and Pasta,9.42,0.007,0.035,7.3,0,0.287,74.26,0.64,4.74,10.37,365,0.667,1.251,2.163,34158
"Cornmeal, degermed, enriched, white",Cereal Grains and Pasta,7.11,0.003,0.007,3.9,0,0.142,79.45,1.61,1.75,11.18,370,0.169,0.299,0.634,34159
"Cornmeal, degermed, enriched, yellow",Cereal Grains and Pasta,7.11,0.003,0.007,3.9,0,0.142,79.45,1.61,1.75,11.18,370,0.169,0.299,0.634,34160
"Cornmeal, degermed, unenriched, white",Cereal Grains and Pasta,7.11,0.003,0.007,3.9,0,0.142,79.45,1.61,1.75,11.18,370,0.169,0.299,0.634,34161
"Cornmeal, degermed, unenriched, yellow",Cereal Grains and Pasta,7.11,0.003,0.007,3.9,0,0.142,79.45,1.61,1.75,11.18,370,0.169,0.299,0.634,34162
"Cornmeal, self-rising, bolted, plain, enriched, white",Cereal Grains and Pasta,8.28,0.361,1.247,6.7,0,0.255,70.28,0,3.4,12.59,334,0.478,0.897,1.55,34163
"Cornmeal, self-rising, bolted, plain, enriched, yellow",Cereal Grains and Pasta,8.28,0.361,1.247,6.7,0,0.255,70.28,0,3.4,12.59,334,0.478,0.897,1.55,34164
"Cornmeal, self-rising, bolted, with wheat flour added, enriched, white",Cereal Grains and Pasta,8.41,0.299,1.319,6.3,0,0.207,73.43,0,2.85,10.33,348,0.4,0.751,1.297,34165
"Cornmeal, self-rising, bolted, with wheat flour added, enriched, yellow",Cereal Grains and Pasta,8.41,0.299,1.319,6.3,0,0.207,73.43,0,2.85,10.33,348,0.4,0.751,1.297,34166
"Cornmeal, self-rising, degermed, enriched, white",Cereal Grains and Pasta,8.41,0.35,1.348,7.1,0,0.17,74.79,0,1.72,10.17,355,0.234,0.429,0.739,34167
"Cornmeal, self-rising, degermed, enriched, yellow",Cereal Grains and Pasta,8.41,0.35,1.348,7.1,0,0.17,74.79,0,1.72,10.17,355,0.234,0.429,0.739,34168
"Cornmeal, whole-grain, white",Cereal Grains and Pasta,8.12,0.006,0.035,7.3,0,0.287,76.89,0.64,3.59,10.26,362,0.505,0.948,1.638,34169
"Cornmeal, whole-grain, yellow",Cereal Grains and Pasta,8.12,0.006,0.035,7.3,0,0.287,76.89,0.64,3.59,10.26,362,0.505,0.948,1.638,34170
Cornstarch,Cereal Grains and Pasta,0.26,0.002,0.009,0.9,0,0.003,91.27,0,0.05,8.32,381,0.009,0.016,0.025,34171
"Couscous, cooked",Cereal Grains and Pasta,3.79,0.008,0.005,1.4,0,0.058,23.22,0.1,0.16,72.57,112,0.029,0.022,0.064,34172
"Couscous, dry",Cereal Grains and Pasta,12.76,0.024,0.01,5,0,0.166,77.43,0,0.64,8.56,376,0.117,0.089,0.252,34173
"Hominy, canned, white",Cereal Grains and Pasta,1.48,0.01,0.21,2.5,0,0.009,14.26,1.82,0.88,82.53,72,0.123,0.231,0.399,34174
"Hominy, canned, yellow",Cereal Grains and Pasta,1.48,0.01,0.21,2.5,0,0.009,14.26,0,0.88,82.53,72,0.123,0.231,0.399,34175
"Kamut, cooked",Cereal Grains and Pasta,6.45,0.01,0.006,3.9,0,0.202,30.46,0,0.91,61.42,146,0,0,0,34176
"Kamut, uncooked",Cereal Grains and Pasta,14.7,0.024,0.006,9.1,0,0.446,70.38,8.19,2.2,10.95,337,0.192,0.214,0.616,34177
"Macaroni, cooked, enriched",Cereal Grains and Pasta,5.8,0.007,0.001,1.8,0,0.044,30.86,0.56,0.93,62.13,158,0.176,0.131,0.319,34178
"Macaroni, cooked, unenriched",Cereal Grains and Pasta,5.8,0.007,0.001,1.8,0,0.044,30.86,0.56,0.93,62.13,158,0.176,0.131,0.319,34179
"Macaroni, dry, enriched",Cereal Grains and Pasta,13.04,0.021,0.006,3.2,0,0.223,74.67,2.67,1.51,9.9,371,0.277,0.171,0.564,34180
"Macaroni, dry, unenriched",Cereal Grains and Pasta,13.04,0.021,0.006,3.2,0,0.223,74.67,2.67,1.51,9.9,371,0.277,0.171,0.564,34181
"Macaroni, protein-fortified, cooked, enriched, (n x 5.70)",Cereal Grains and Pasta,8.08,0.01,0.005,0,0,0.042,31.66,0,0.21,59.73,164,0.032,0.026,0.095,34182
"Macaroni, protein-fortified, cooked, enriched, (n x 6.25)",Cereal Grains and Pasta,8.86,0.01,0.005,1.5,0,0.042,30.88,0,0.21,59.73,164,0.032,0.026,0.095,34183
"Macaroni, protein-fortified, dry, enriched, (n x 5.70)",Cereal Grains and Pasta,19.86,0.039,0.008,2.4,0,0.201,67.56,0,2.23,9.23,375,0.328,0.271,0.986,34184
"Macaroni, protein-fortified, dry, enriched, (n x 6.25)",Cereal Grains and Pasta,21.78,0.039,0.008,2.4,0,0.201,65.65,0,2.23,9.23,374,0.328,0.271,0.986,34185
"Macaroni, vegetable, cooked, enriched",Cereal Grains and Pasta,4.53,0.011,0.006,4.3,0,0.031,26.61,1.15,0.11,68.37,128,0.016,0.012,0.044,34186
"Macaroni, vegetable, dry, enriched",Cereal Grains and Pasta,13.14,0.034,0.043,4.3,0,0.285,74.88,0,1.04,9.89,367,0.15,0.12,0.426,34187
"Macaroni, whole-wheat, cooked",Cereal Grains and Pasta,5.33,0.015,0.003,2.8,0,0.044,26.54,0.8,0.54,67.15,124,0.099,0.075,0.213,34188
"Macaroni, whole-wheat, dry",Cereal Grains and Pasta,14.63,0.04,0.008,8.3,0,0.215,75.03,0,1.4,7.34,348,0.258,0.195,0.556,34189
Millet flour,Cereal Grains and Pasta,10.75,0.014,0.004,3.5,0,0.224,73.05,1.66,4.25,10.74,373,0.536,0.924,2.618,34190
"Millet, cooked",Cereal Grains and Pasta,3.51,0.003,0.002,1.3,0,0.062,23.67,0.13,1,71.41,119,0.172,0.184,0.508,34191
"Millet, raw",Cereal Grains and Pasta,11.02,0.008,0.005,8.5,0,0.195,72.85,0,4.22,8.67,378,0.723,0.773,2.134,34192
"Noodles, chinese, chow mein",Cereal Grains and Pasta,8.38,0.02,0.439,3.9,0,0.12,57.54,0.26,30.76,0.73,527,4.384,7.689,17.331,34193
"Noodles, egg, cooked, enriched",Cereal Grains and Pasta,4.54,0.012,0.005,1.2,0,0.038,25.16,0.4,2.07,67.73,138,0.419,0.581,0.552,34194
"Noodles, egg, cooked, enriched, with added salt",Cereal Grains and Pasta,4.54,0.012,0.165,1.2,0,0.038,25.16,0.4,2.07,67.73,138,0.419,0.581,0.552,34195
"Noodles, egg, cooked, unenriched, with added salt",Cereal Grains and Pasta,4.54,0.012,0.165,1.2,0,0.038,25.16,0.4,2.07,67.73,138,0.419,0.581,0.552,34196
"Noodles, egg, cooked, unenriched, without added salt",Cereal Grains and Pasta,4.54,0.012,0.005,1.2,0,0.038,25.16,0.4,2.07,67.73,138,0.419,0.581,0.552,34197
"Noodles, egg, dry, enriched",Cereal Grains and Pasta,14.16,0.035,0.021,3.3,0,0.244,71.27,1.88,4.44,9.01,384,1.18,1.252,1.331,34198
"Noodles, egg, dry, unenriched",Cereal Grains and Pasta,14.16,0.035,0.021,3.3,0,0.244,71.27,1.88,4.44,9.01,384,1.18,1.252,1.331,34199
"Noodles, egg, spinach, cooked, enriched",Cereal Grains and Pasta,5.04,0.019,0.012,2.3,0,0.037,24.25,0.71,1.57,68.52,132,0.361,0.493,0.349,34200
"Noodles, egg, spinach, dry, enriched",Cereal Grains and Pasta,14.61,0.056,0.072,6.8,0,0.355,70.32,0,4.55,8.72,382,1.047,1.43,1.012,34201
"Noodles, flat, crunchy, Chinese restaurant",Cereal Grains and Pasta,10.33,0.021,0.378,1.9,0,0.089,51.9,0.25,31.72,4.93,521,4.931,7.617,16.949,34202
"Noodles, japanese, soba, cooked",Cereal Grains and Pasta,5.06,0.004,0.06,0,0,0.035,21.44,0,0.1,73.01,99,0.019,0.026,0.031,34203
"Noodles, japanese, soba, dry",Cereal Grains and Pasta,14.38,0.035,0.792,0,0,0.252,74.62,0,0.71,6.88,336,0.136,0.185,0.22,34204
"Noodles, japanese, somen, cooked",Cereal Grains and Pasta,4,0.008,0.161,0,0,0.029,27.54,0,0.18,67.91,131,0.025,0.021,0.073,34205
"Noodles, japanese, somen, dry",Cereal Grains and Pasta,11.35,0.023,1.84,4.3,0,0.164,74.1,0,0.81,9.21,356,0.115,0.095,0.33,34206
"Oat bran, cooked",Cereal Grains and Pasta,3.21,0.01,0.001,2.6,0,0.092,11.44,0,0.86,84,40,0.163,0.291,0.339,34207
"Oat bran, raw",Cereal Grains and Pasta,17.3,0.058,0.004,15.4,0,0.566,66.22,1.45,7.03,6.55,246,1.328,2.376,2.766,34208
"Oat flour, partially debranned",Cereal Grains and Pasta,14.66,0.055,0.019,6.5,0,0.371,65.7,0.8,9.12,8.55,404,1.607,2.866,3.329,34209
Oats,Cereal Grains and Pasta,16.89,0.054,0.002,10.6,0,0.429,66.27,0,6.9,8.22,389,1.217,2.178,2.535,34210
"Pasta, corn, cooked",Cereal Grains and Pasta,2.63,0.001,0,4.8,0,0.031,27.91,0,0.73,68.31,126,0.102,0.191,0.325,34211
"Pasta, corn, dry",Cereal Grains and Pasta,7.46,0.004,0.003,11,0,0.294,79.26,0,2.08,10,357,0.29,0.543,0.924,34212
"Pasta, fresh-refrigerated, plain, as purchased",Cereal Grains and Pasta,11.31,0.015,0.026,0,0,0.179,54.73,0,2.3,31,288,0.328,0.271,0.942,34213
"Pasta, fresh-refrigerated, plain, cooked",Cereal Grains and Pasta,5.15,0.006,0.006,0,0,0.024,24.93,0,1.05,68.56,131,0.15,0.124,0.429,34214
"Pasta, fresh-refrigerated, spinach, as purchased",Cereal Grains and Pasta,11.26,0.043,0.027,0,0,0.272,55.72,0,2.1,30.1,289,0.483,0.659,0.467,34215
"Pasta, fresh-refrigerated, spinach, cooked",Cereal Grains and Pasta,5.06,0.018,0.006,0,0,0.037,25.04,0,0.94,68.58,130,0.217,0.296,0.21,34216
"Pasta, homemade, made with egg, cooked",Cereal Grains and Pasta,5.28,0.01,0.083,0,0,0.021,23.54,0,1.74,68.71,130,0.408,0.508,0.521,34217
"Pasta, homemade, made without egg, cooked",Cereal Grains and Pasta,4.37,0.006,0.074,0,0,0.019,25.12,0,0.98,68.86,124,0.14,0.187,0.508,34218
"Quinoa, cooked",Cereal Grains and Pasta,4.4,0.017,0.007,2.8,0,0.172,21.3,0,1.92,71.61,120,0,0,0,34219
"Quinoa, uncooked",Cereal Grains and Pasta,14.12,0.047,0.005,7,0,0.563,64.16,0,6.07,13.28,368,0.706,1.613,3.292,34220
"Rice bran, crude",Cereal Grains and Pasta,13.35,0.057,0.005,21,0,1.485,49.69,0.9,20.85,6.13,316,4.171,7.549,7.459,34221
"Rice flour, brown",Cereal Grains and Pasta,7.23,0.011,0.008,4.6,0,0.289,76.48,0.85,2.78,11.97,363,0.557,1.008,0.996,34222
"Rice flour, white",Cereal Grains and Pasta,5.95,0.01,0,2.4,0,0.076,80.13,0.12,1.42,11.89,366,0.386,0.442,0.379,34223
"Rice noodles, cooked",Cereal Grains and Pasta,0.91,0.004,0.019,1,0,0.004,24.9,0,0.2,73.82,109,0.023,0.026,0.023,34224
"Rice noodles, dry",Cereal Grains and Pasta,3.44,0.018,0.182,1.6,0,0.03,83.24,0,0.56,11.91,364,0.153,0.175,0.15,34225
"Rice, brown, long-grain, cooked",Cereal Grains and Pasta,2.58,0.01,0.005,1.8,0,0.043,22.96,0.35,0.9,73.09,111,0.18,0.327,0.323,34226
"Rice, brown, long-grain, raw",Cereal Grains and Pasta,7.94,0.023,0.007,3.5,0,0.223,77.24,0.85,2.92,10.37,370,0.584,1.056,1.044,34227
"Rice, brown, medium-grain, cooked",Cereal Grains and Pasta,2.32,0.01,0.001,1.8,0,0.079,23.51,0,0.83,72.96,112,0.165,0.3,0.296,34228
"Rice, brown, medium-grain, raw",Cereal Grains and Pasta,7.5,0.033,0.004,3.4,0,0.268,76.17,0,2.68,12.37,362,0.536,0.971,0.959,34229
"Rice, white, glutinous, cooked",Cereal Grains and Pasta,2.02,0.002,0.005,1,0,0.01,21.09,0.05,0.19,76.63,97,0.039,0.07,0.069,34230
"Rice, white, glutinous, raw",Cereal Grains and Pasta,6.81,0.011,0.007,2.8,0,0.077,81.68,0,0.55,10.46,370,0.111,0.2,0.198,34231
"Rice, white, long-grain, parboiled, enriched, cooked",Cereal Grains and Pasta,2.91,0.019,0.002,0.9,0,0.056,26.05,0.11,0.37,70.36,123,0.074,0.074,0.091,34232
"Rice, white, long-grain, parboiled, enriched, dry",Cereal Grains and Pasta,7.51,0.071,0.002,1.8,0,0.174,80.89,0.33,1.03,9.86,374,0.294,0.258,0.322,34233
"Rice, white, long-grain, parboiled, unenriched, cooked",Cereal Grains and Pasta,2.91,0.019,0.002,0.9,0,0.056,26.05,0.11,0.37,70.36,123,0.074,0.074,0.091,34234
"Rice, white, long-grain, parboiled, unenriched, dry",Cereal Grains and Pasta,7.51,0.071,0.002,1.8,0,0.174,80.89,0.33,1.03,9.86,374,0.294,0.258,0.322,34235
"Rice, white, long-grain, precooked or instant, enriched, dry",Cereal Grains and Pasta,7.82,0.022,0.01,1.9,0,0.027,82.32,0.05,0.94,8.38,380,0.152,0.144,0.219,34236
"Rice, white, long-grain, precooked or instant, enriched, prepared",Cereal Grains and Pasta,2.18,0.008,0.004,0.6,0,0.009,25.1,0,0.5,72,117,0.017,0.074,0.017,34237
"Rice, white, long-grain, regular, cooked",Cereal Grains and Pasta,2.69,0.01,0.001,0.4,0,0.035,28.17,0.05,0.28,68.44,130,0.077,0.088,0.076,34238
"Rice, white, long-grain, regular, cooked, enriched, with salt",Cereal Grains and Pasta,2.69,0.01,0.382,0.4,0,0.035,28.17,0.05,0.28,68.44,130,0.077,0.088,0.076,34239
"Rice, white, long-grain, regular, cooked, unenriched, with salt",Cereal Grains and Pasta,2.69,0.01,0.382,0.4,0,0.035,28.17,0.05,0.28,68.44,130,0.077,0.088,0.076,34240
"Rice, white, long-grain, regular, cooked, unenriched, without salt",Cereal Grains and Pasta,2.69,0.01,0.001,0.4,0,0.035,28.17,0.05,0.28,68.44,130,0.077,0.088,0.076,34241
"Rice, white, long-grain, regular, raw, enriched",Cereal Grains and Pasta,7.13,0.028,0.005,1.3,0,0.115,79.95,0.12,0.66,11.62,365,0.18,0.206,0.177,34242
"Rice, white, long-grain, regular, raw, unenriched",Cereal Grains and Pasta,7.13,0.028,0.005,1.3,0,0.115,79.95,0.12,0.66,11.62,365,0.18,0.206,0.177,34243
"Rice, white, medium-grain, cooked",Cereal Grains and Pasta,2.38,0.003,0,0.3,0,0.029,28.59,0,0.21,68.61,130,0.057,0.065,0.056,34244
"Rice, white, medium-grain, cooked, unenriched",Cereal Grains and Pasta,2.38,0.003,0,0,0,0.029,28.59,0,0.21,68.61,130,0.057,0.065,0.056,34245
"Rice, white, medium-grain, raw, enriched",Cereal Grains and Pasta,6.61,0.009,0.001,1.4,0,0.086,79.34,0,0.58,12.89,360,0.158,0.181,0.155,34246
"Rice, white, medium-grain, raw, unenriched",Cereal Grains and Pasta,6.61,0.009,0.001,0,0,0.086,79.34,0,0.58,12.89,360,0.158,0.181,0.155,34247
"Rice, white, short-grain, cooked",Cereal Grains and Pasta,2.36,0.001,0,0,0,0.026,28.73,0,0.19,68.53,130,0.051,0.058,0.05,34248
"Rice, white, short-grain, cooked, unenriched",Cereal Grains and Pasta,2.36,0.001,0,0,0,0.026,28.73,0,0.19,68.53,130,0.051,0.058,0.05,34249
"Rice, white, short-grain, raw",Cereal Grains and Pasta,6.5,0.003,0.001,2.8,0,0.076,79.15,0,0.52,13.29,358,0.14,0.161,0.138,34250
"Rice, white, short-grain, raw, unenriched",Cereal Grains and Pasta,6.5,0.003,0.001,0,0,0.076,79.15,0,0.52,13.29,358,0.14,0.161,0.138,34251
"Rice, white, steamed, Chinese restaurant",Cereal Grains and Pasta,3.2,0.005,0.005,0.9,0,0.02,33.88,0,0.27,62.5,151,0,0,0,34252
"Rice, white, with pasta, cooked",Cereal Grains and Pasta,2.54,0.008,0.568,2.5,0.0002,0.042,21.43,0,2.82,71.74,122,0.541,1.119,0.947,34253
"Rice, white, with pasta, dry",Cereal Grains and Pasta,9.37,0.046,1.866,0,0.0006,0.209,75.32,0,2.44,7.13,368,0.44,0.642,0.966,34254
Rye,Cereal Grains and Pasta,10.34,0.024,0.002,15.1,0,0.51,75.86,0.98,1.63,10.6,338,0.197,0.208,0.767,34255
"Rye flour, dark",Cereal Grains and Pasta,15.91,0.037,0.002,23.8,0,0.717,68.63,2.31,2.22,10.75,325,0.269,0.284,1.046,34256
"Rye flour, light",Cereal Grains and Pasta,9.82,0.013,0.002,8,0,0.224,76.68,0.93,1.33,11.4,357,0.15,0.158,0.584,34257
"Rye flour, medium",Cereal Grains and Pasta,10.88,0.024,0.002,11.8,0,0.374,75.43,1.1,1.52,10.97,349,0.18,0.189,0.695,34258
"Semolina, enriched",Cereal Grains and Pasta,12.68,0.017,0.001,3.9,0,0.186,72.83,0,1.05,12.67,360,0.15,0.124,0.43,34259
"Semolina, unenriched",Cereal Grains and Pasta,12.68,0.017,0.001,3.9,0,0.186,72.83,0,1.05,12.67,360,0.15,0.124,0.43,34260
Sorghum,Cereal Grains and Pasta,11.3,0.028,0.006,6.3,0,0.35,74.63,0,3.3,9.2,339,0.457,0.993,1.37,34261
Sorghum flour,Cereal Grains and Pasta,7.87,0.012,0.004,6.6,0,0.311,77.47,1.87,3.29,10.06,361,0.475,0.879,1.367,34262
"Spaghetti, cooked, enriched, with added salt",Cereal Grains and Pasta,5.8,0.007,0.131,1.8,0,0.044,30.59,0.56,0.93,62.13,157,0.176,0.131,0.319,34263
"Spaghetti, cooked, enriched, without added salt",Cereal Grains and Pasta,5.8,0.007,0.001,1.8,0,0.044,30.86,0.56,0.93,62.13,158,0.176,0.131,0.319,34264
"Spaghetti, cooked, unenriched, with added salt",Cereal Grains and Pasta,5.8,0.007,0.131,1.8,0,0.044,30.59,0.56,0.93,62.13,157,0.176,0.131,0.319,34265
"Spaghetti, cooked, unenriched, without added salt",Cereal Grains and Pasta,5.8,0.007,0.001,1.8,0,0.044,30.86,0.56,0.93,62.13,158,0.176,0.131,0.319,34266
"Spaghetti, dry, enriched",Cereal Grains and Pasta,13.04,0.021,0.006,3.2,0,0.223,74.67,2.67,1.51,9.9,371,0.277,0.171,0.564,34267
"Spaghetti, dry, unenriched",Cereal Grains and Pasta,13.04,0.021,0.006,3.2,0,0.223,74.67,2.67,1.51,9.9,371,0.277,0.171,0.564,34268
"Spaghetti, protein-fortified, cooked, enriched (N x 5.70)",Cereal Grains and Pasta,8.08,0.01,0.005,1.7,0,0.042,31.66,0,0.21,59.73,164,0.032,0.026,0.095,34269
"Spaghetti, protein-fortified, cooked, enriched (n x 6.25)",Cereal Grains and Pasta,8.86,0.01,0.005,2,0,0.042,30.88,0,0.21,59.73,164,0.032,0.026,0.095,34270
"Spaghetti, protein-fortified, dry, enriched (n x 5.70)",Cereal Grains and Pasta,19.86,0.039,0.008,0,0,0.201,67.56,0,2.23,9.23,375,0.328,0.271,0.986,34271
"Spaghetti, protein-fortified, dry, enriched (n x 6.25)",Cereal Grains and Pasta,21.78,0.039,0.008,2.4,0,0.201,65.65,0,2.23,9.23,374,0.328,0.271,0.986,34272
"Spaghetti, spinach, cooked",Cereal Grains and Pasta,4.58,0.03,0.014,0,0,0.058,26.15,0,0.63,68.14,130,0.091,0.072,0.257,34273
"Spaghetti, spinach, dry",Cereal Grains and Pasta,13.35,0.058,0.036,10.6,0,0.376,74.81,2.72,1.57,8.34,372,0.226,0.181,0.641,34274
"Spaghetti, whole-wheat, cooked",Cereal Grains and Pasta,5.33,0.015,0.003,4.5,0,0.044,26.54,0.8,0.54,67.15,124,0.099,0.075,0.213,34275
"Spaghetti, whole-wheat, dry",Cereal Grains and Pasta,14.63,0.04,0.008,0,0,0.215,75.03,0,1.4,7.34,348,0.258,0.195,0.556,34276
"Spelt, cooked",Cereal Grains and Pasta,5.5,0.01,0.005,3.9,0,0.143,26.44,0,0.85,66.56,127,0,0,0,34277
"Spelt, uncooked",Cereal Grains and Pasta,14.57,0.027,0.008,10.7,0,0.388,70.19,6.82,2.43,11.02,338,0.406,0.445,1.258,34278
"Tapioca, pearl, dry",Cereal Grains and Pasta,0.19,0.02,0.001,0.9,0,0.011,88.69,3.35,0.02,10.99,358,0.005,0.005,0.003,34279
"Teff, cooked",Cereal Grains and Pasta,3.87,0.049,0.008,2.8,0,0.107,19.86,0,0.65,74.93,101,0,0,0,34280
"Teff, uncooked",Cereal Grains and Pasta,13.3,0.18,0.012,8,0,0.427,73.13,1.84,2.38,8.82,367,0.449,0.589,1.071,34281
Triticale,Cereal Grains and Pasta,13.05,0.037,0.005,0,0,0.332,72.13,0,2.09,10.51,336,0.366,0.211,0.913,34282
"Triticale flour, whole-grain",Cereal Grains and Pasta,13.18,0.035,0.002,14.6,0,0.466,73.14,0,1.81,10.01,338,0.318,0.183,0.794,34283
Vital wheat gluten,Cereal Grains and Pasta,75.16,0.142,0.029,0.6,0,0.1,13.79,0,1.85,8.2,370,0.272,0.156,0.81,34284
"Wheat bran, crude",Cereal Grains and Pasta,15.55,0.073,0.002,42.8,0,1.182,64.51,0.41,4.25,9.89,216,0.63,0.637,2.212,34285
"Wheat flour, white (industrial), 10% protein, bleached, enriched",Cereal Grains and Pasta,9.71,0.02,0.002,2.4,0,0.149,76.22,0.49,1.48,12.01,366,0.302,0.193,0.845,34286
"Wheat flour, white (industrial), 10% protein, bleached, unenriched",Cereal Grains and Pasta,9.71,0.02,0.002,2.4,0,0.149,76.22,0.49,1.48,12.01,366,0.302,0.193,0.845,34287
"Wheat flour, white (industrial), 10% protein, unbleached, enriched",Cereal Grains and Pasta,9.71,0.02,0.002,2.4,0,0.149,76.22,0.49,1.48,12.01,366,0.302,0.193,0.845,34288
"Wheat flour, white (industrial), 11.5% protein, bleached, enriched",Cereal Grains and Pasta,11.5,0.02,0.002,2.4,0,0.138,73.81,1.12,1.45,12.68,363,0.268,0.166,0.749,34289
"Wheat flour, white (industrial), 11.5% protein, bleached, unenriched",Cereal Grains and Pasta,11.5,0.02,0.002,2.4,0,0.138,73.81,1.12,1.45,12.68,363,0.268,0.166,0.749,34290
"Wheat flour, white (industrial), 11.5% protein, unbleached, enriched",Cereal Grains and Pasta,11.5,0.02,0.002,2.4,0,0.138,73.81,1.12,1.45,12.68,363,0.268,0.166,0.749,34291
"Wheat flour, white (industrial), 13% protein, bleached, enriched",Cereal Grains and Pasta,13.07,0.024,0.002,2.4,0,0.128,72.2,1.1,1.38,12.82,362,0.189,0.152,0.683,34292
"Wheat flour, white (industrial), 13% protein, bleached, unenriched",Cereal Grains and Pasta,13.07,0.024,0.002,2.4,0,0.128,72.2,1.1,1.38,12.82,362,0.189,0.152,0.683,34293
"Wheat flour, white (industrial), 15% protein, bleached, enriched",Cereal Grains and Pasta,15.33,0.017,0.002,2.4,0,0.12,69.88,0.92,1.41,12.85,362,0.272,0.153,0.712,34294
"Wheat flour, white (industrial), 15% protein, bleached, unenriched",Cereal Grains and Pasta,15.33,0.017,0.002,2.4,0,0.12,69.88,0.92,1.41,12.85,362,0.272,0.153,0.712,34295
"Wheat flour, white (industrial), 9% protein, bleached, enriched",Cereal Grains and Pasta,8.89,0.02,0.002,0,0,0.131,77.32,0.54,1.43,11.85,367,0.34,0.12,0.602,34296
"Wheat flour, white (industrial), 9% protein, bleached, unenriched",Cereal Grains and Pasta,8.89,0.02,0.002,2.4,0,0.131,77.32,0.54,1.43,11.85,367,0.34,0.12,0.602,34297
"Wheat flour, white, all-purpose, enriched, bleached",Cereal Grains and Pasta,10.33,0.015,0.002,2.7,0,0.107,76.31,0.27,0.98,11.92,364,0.155,0.087,0.413,34298
"Wheat flour, white, all-purpose, enriched, calcium-fortified",Cereal Grains and Pasta,10.33,0.252,0.002,2.7,0,0.107,76.31,0,0.98,11.92,364,0.155,0.087,0.413,34299
"Wheat flour, white, all-purpose, enriched, unbleached",Cereal Grains and Pasta,10.33,0.015,0.002,2.7,0,0.107,76.31,0.27,0.98,11.92,364,0.155,0.087,0.413,34300
"Wheat flour, white, all-purpose, self-rising, enriched",Cereal Grains and Pasta,9.89,0.338,1.27,2.7,0,0.124,74.22,0.22,0.97,10.59,354,0.154,0.086,0.41,34301
"Wheat flour, white, all-purpose, unenriched",Cereal Grains and Pasta,10.33,0.015,0.002,2.7,0,0.107,76.31,0.27,0.98,11.92,364,0.155,0.087,0.413,34302
"Wheat flour, white, bread, enriched",Cereal Grains and Pasta,11.98,0.015,0.002,2.4,0,0.1,72.53,0.31,1.66,13.36,361,0.244,0.14,0.727,34303
"Wheat flour, white, cake, enriched",Cereal Grains and Pasta,8.2,0.014,0.002,1.7,0,0.105,78.03,0.31,0.86,12.51,362,0.127,0.073,0.379,34304
"Wheat flour, white, tortilla mix, enriched",Cereal Grains and Pasta,9.66,0.205,0.677,0,0,0.1,67.14,0,10.63,10.08,405,4.1,4.541,1.522,34305
"Wheat flour, whole-grain",Cereal Grains and Pasta,13.21,0.034,0.002,10.7,0,0.363,71.97,0.41,2.5,10.74,340,0.43,0.283,1.167,34306
"Wheat flours, bread, unenriched",Cereal Grains and Pasta,11.98,0.015,0.002,2.4,0,0.1,72.53,0.31,1.66,13.36,361,0.244,0.14,0.727,34307
"Wheat germ, crude",Cereal Grains and Pasta,23.15,0.039,0.012,13.2,0,0.892,51.8,0,9.72,11.12,360,1.665,1.365,6.01,34308
"Wheat, durum",Cereal Grains and Pasta,13.68,0.034,0.002,0,0,0.431,71.13,0,2.47,10.94,339,0.454,0.344,0.978,34309
"Wheat, hard red spring",Cereal Grains and Pasta,15.4,0.025,0.002,12.2,0,0.34,68.03,0.41,1.92,12.76,329,0.314,0.303,0.765,34310
"Wheat, hard red winter",Cereal Grains and Pasta,12.61,0.029,0.002,12.2,0,0.363,71.18,0.41,1.54,13.1,327,0.269,0.2,0.627,34311
"Wheat, hard white",Cereal Grains and Pasta,11.31,0.032,0.002,12.2,0,0.432,75.9,0.41,1.71,9.57,342,0.277,0.203,0.75,34312
"Wheat, soft red winter",Cereal Grains and Pasta,10.35,0.027,0.002,12.5,0,0.397,74.24,0.41,1.56,12.17,331,0.289,0.178,0.656,34313
"Wheat, soft white",Cereal Grains and Pasta,10.69,0.034,0.002,12.7,0,0.435,75.36,0.41,1.99,10.42,340,0.368,0.227,0.837,34314
"Wheat, sprouted",Cereal Grains and Pasta,7.49,0.028,0.016,1.1,0.0026,0.169,42.53,0,1.27,47.75,198,0.206,0.151,0.557,34315
"Wild rice, cooked",Cereal Grains and Pasta,3.99,0.003,0.003,1.8,0,0.101,21.34,0.73,0.34,73.93,101,0.049,0.05,0.213,34316
"Wild rice, raw",Cereal Grains and Pasta,14.73,0.021,0.007,6.2,0,0.427,74.9,2.5,1.08,7.76,357,0.156,0.159,0.676,34317
"BURGER KING, CHICKEN TENDERS",Fast Foods,17.25,0.015,0.721,2.3,0.0007,0.263,17.41,0.12,16.67,46.06,289,0,0,0,34318
"BURGER KING, CROISSAN'WICH with Egg and Cheese",Fast Foods,10.35,0.231,0.65,0.7,0,0.168,24.79,4.03,15.78,46.4,283,4.766,6.668,2.93,34319
"BURGER KING, CROISSAN'WICH with Sausage and Cheese",Fast Foods,13.73,0.107,0.782,0.7,0,0.205,23,3.36,25.45,35.08,376,8.595,12.025,3.114,34320
"BURGER KING, CROISSAN'WICH with Sausage, Egg and Cheese",Fast Foods,12.31,0.168,0.687,0.7,0,0.197,17.65,2.86,22.48,44.9,322,7.424,9.913,3.864,34321
"BURGER KING, Cheeseburger",Fast Foods,14.57,0.093,0.602,2.8,0.0002,0.178,23.71,4.49,14.81,44.59,286,6.842,5.704,1.521,34322
"BURGER KING, Chicken WHOPPER Sandwich",Fast Foods,11.77,0.033,0.433,1.5,0.0002,0.183,18.67,3.39,10.52,57.3,216,2.198,3.1,4.76,34323
"BURGER KING, DOUBLE WHOPPER, no cheese",Fast Foods,13.94,0.035,0.289,1.4,0.0002,0.192,13.74,3.52,15.66,55.29,252,5.773,6.117,3.016,34324
"BURGER KING, DOUBLE WHOPPER, with cheese",Fast Foods,14.47,0.078,0.387,1.6,0.0002,0.189,13.52,3.61,17.06,53.28,266,6.998,6.298,2.987,34325
"BURGER KING, French Fries",Fast Foods,3.5,0.009,0.455,2.9,0.0007,0.473,40.08,0.69,17.4,36.66,331,0,0,0,34326
"BURGER KING, French Toast Sticks",Fast Foods,6,0.053,0.428,1.4,0,0.111,41.21,9.89,17.74,33.57,349,3.989,9.502,2.566,34327
"BURGER KING, Hamburger",Fast Foods,14.18,0.051,0.455,2,0.0002,0.182,27.11,4.85,12.17,44.68,275,5.068,5.317,1.246,34328
"BURGER KING, Hash Brown Rounds",Fast Foods,2.87,0.016,0.511,2.9,0.0016,0.365,34.55,0.27,24.35,36.12,369,0,0,0,34329
"BURGER KING, Original Chicken Sandwich",Fast Foods,12.57,0.04,0.59,2.4,0.0002,0.185,24.35,2.71,15.4,45.52,286,0,0,0,34330
"BURGER KING, Vanilla Shake",Fast Foods,3.19,0.104,0.1,0,0,0.153,19.03,11.67,8.74,68.13,168,5.335,2.47,0.434,34331
"BURGER KING, WHOPPER, no cheese",Fast Foods,10.74,0.039,0.313,1.8,0.0002,0.169,18.55,4.22,12.84,56.43,233,4.25,4.685,3.389,34332
"BURGER KING, WHOPPER, with cheese",Fast Foods,11.19,0.082,0.453,1,0.0002,0.169,16.7,4.11,15.33,54.92,250,5.782,5.058,3.784,34333
"DIGIORNO Pizza, cheese topping, cheese stuffed crust, frozen, baked",Fast Foods,13.48,0.235,0.806,1.9,0.0005,0.185,29.86,0,11.68,41.78,279,5.63,2.905,1.79,34334
"DIGIORNO Pizza, cheese topping, rising crust, frozen, baked",Fast Foods,12.8,0.192,0.696,2.4,0.0015,0.196,31.78,5,8.6,43.95,256,3.514,1.854,1.461,34335
"DIGIORNO Pizza, cheese topping, thin crispy crust, frozen, baked",Fast Foods,12.96,0.275,0.506,3,0.0001,0.233,26.47,0,9.91,48.15,247,4.601,2.736,1.158,34336
"DIGIORNO Pizza, pepperoni topping, cheese stuffed crust, frozen, baked",Fast Foods,13.92,0.226,0.753,2.1,0.0011,0.179,29.46,0,11.71,41.87,279,5.007,3.289,2.001,34337
"DIGIORNO Pizza, pepperoni topping, rising crust, frozen, baked",Fast Foods,12.46,0.157,0.743,2.3,0.0013,0.212,31.15,5.1,10.11,43.32,265,3.761,2.77,1.775,34338
"DIGIORNO Pizza, pepperoni topping, thin crispy crust, frozen, baked",Fast Foods,13.22,0.209,0.663,2.8,0.0009,0.268,28.67,0,12.86,42.38,283,4.973,4.354,1.827,34339
"DIGIORNO Pizza, supreme topping, rising crust, frozen, baked",Fast Foods,11.74,0.139,0.712,2.3,0,0.21,27.93,4.71,10.68,46.8,255,3.965,3.372,1.891,34340
"DIGIORNO Pizza, supreme topping, thin crispy crust, frozen, baked",Fast Foods,11.41,0.161,0.555,2.8,0.0008,0.278,28.05,0,10.8,47.31,255,4.377,3.957,1.79,34341
"DOMINO'S 14"" Cheese Pizza, Classic Hand-Tossed Crust",Fast Foods,10.98,0.134,0.478,1.4,0,0.152,34.45,3.57,8.39,44.3,257,3.547,1.976,1.583,34342
"DOMINO'S 14"" Cheese Pizza, Crunchy Thin Crust",Fast Foods,12.96,0.222,0.497,2.1,0,0.184,28.01,3.17,16.82,39.72,315,6.756,4.217,3.646,34343
"DOMINO'S 14"" Cheese Pizza, Ultimate Deep Dish Crust",Fast Foods,11.36,0.148,0.561,2.3,0,0.164,33.93,3.28,10.34,42.09,274,4.025,2.937,1.921,34344
"DOMINO'S 14"" EXTRAVAGANZZA FEAST Pizza, Classic Hand-Tossed Crust",Fast Foods,10.33,0.113,0.456,2,0,0.174,25.72,3.3,11.1,50.77,244,4.386,3.627,1.425,34345
"DOMINO'S 14"" Pepperoni Pizza, Classic Hand-Tossed Crust",Fast Foods,11.6,0.122,0.507,1.5,0,0.176,32.29,3.45,10.54,43.45,270,4.255,3.137,1.75,34346
"DOMINO'S 14"" Pepperoni Pizza, Ultimate Deep Dish Crust",Fast Foods,12.11,0.112,0.526,2.1,0,0.176,32.3,3.26,12.28,40.85,288,4.905,4.027,2.03,34347
"Entrees, crab cake",Fast Foods,18.75,0.337,0.819,0.4,0.0003,0.27,8.52,0,17.25,53.38,266,3.74,7.18,5.13,34348
"Entrees, fish fillet, battered or breaded, and fried",Fast Foods,14.66,0.018,0.532,0.5,0,0.32,16.97,0,12.29,53.57,232,2.82,2.58,6.27,34349
"Fast Food, Pizza Chain, 14"" Pizza, cheese topping, regular crust",Fast Foods,11.91,0.177,0.535,1.7,0,0.154,32.58,4.02,9.51,43.76,264,4.221,2.352,1.788,34350
"Fast Food, Pizza Chain, 14"" Pizza, cheese topping, thick crust",Fast Foods,12,0.186,0.533,1.5,0,0.157,31.33,3.18,10.99,43.41,272,4.679,2.934,2.389,34351
"Fast Food, Pizza Chain, 14"" Pizza, cheese topping, thin crust",Fast Foods,14.17,0.3,0.581,2,0.0001,0.175,26.54,3.08,15.68,40.87,304,6.932,4.561,2.932,34352
"Fast Food, Pizza Chain, 14"" Pizza, pepperoni topping, thick crust",Fast Foods,12.43,0.16,0.617,1.8,0,0.171,30.4,3.02,12.49,42.27,284,4.977,3.762,2.603,34353
"Fast Food, Pizza Chain, 14"" pizza, meat and vegetable topping, regular crust",Fast Foods,11.02,0.12,0.589,2.2,0.0001,0.184,25.38,3.73,10.9,50.4,244,4.407,3.787,1.89,34354
"Fast Food, Pizza Chain, 14"" pizza, pepperoni topping, regular crust",Fast Foods,12.33,0.156,0.632,1.5,0,0.172,31.46,3.81,11.23,42.53,276,4.87,3.454,2.01,34355
"Fast Foods, Fried Chicken, Breast, meat and skin with breading",Fast Foods,21.91,0.027,0.551,0.5,0,0.273,7.79,0,14.8,53.05,252,3.92,5.551,3.979,34356
"Fast Foods, Fried Chicken, Breast, meat only, skin and breading removed",Fast Foods,27.24,0.02,0.434,0,0,0.321,0.59,0,4.5,65.53,152,1.265,1.682,1.113,34357
"Fast Foods, Fried Chicken, Drumstick, meat and skin with breading",Fast Foods,21.13,0.024,0.591,0.5,0,0.253,7.59,0,16.92,51.92,267,4.458,6.446,4.408,34358
"Fast Foods, Fried Chicken, Drumstick, meat only, skin and breading removed",Fast Foods,26.24,0.016,0.492,0,0,0.291,0,0,7.41,64.24,172,2.004,2.933,1.675,34359
"Fast Foods, Fried Chicken, Skin and breading from all pieces",Fast Foods,12.06,0.04,0.766,1.3,0,0.185,21.09,0,33.82,30.01,437,8.819,12.692,9.268,34360
"Fast Foods, Fried Chicken, Thigh, meat and skin and breading",Fast Foods,18.2,0.027,0.618,0.6,0,0.244,9.35,0,20.35,49.61,293,5.473,7.978,5.151,34361
"Fast Foods, Fried Chicken, Thigh, meat only, skin and breading removed",Fast Foods,22.97,0.018,0.504,0,0,0.289,0.25,0,9.88,64.81,182,2.875,4.319,1.956,34362
"Fast Foods, Fried Chicken, Wing, meat and skin and breading",Fast Foods,21.34,0.034,0.701,0.6,0,0.233,10.93,0,21.06,43.87,319,5.5,7.883,5.693,34363
"Fast Foods, Fried Chicken, Wing, meat only, skin and breading removed",Fast Foods,28.49,0.029,0.651,0,0,0.27,3.11,0,11.24,54.53,228,2.945,4.181,2.94,34364
"Fast Foods, biscuit, with egg and sausage",Fast Foods,11.13,0.051,0.672,0.2,0,0.149,21.05,0.95,20.77,44.48,312,6.449,9.985,2.967,34365
"Fast Foods, cheeseburger; double, large patty; with condiments, vegetables and mayonnaise",Fast Foods,15.5,0.068,0.405,1.3,0.0005,0.202,12.62,3.61,15.63,54.56,253,6.519,6.249,2.299,34366
"Fast Foods, crispy chicken filet sandwich, with lettuce, tomato and mayonnaise",Fast Foods,12.42,0.078,0.65,1.4,0.0003,0.205,22.46,0,11.7,51.06,245,2.119,3.74,5.47,34367
"Fast foods, bagel, with breakfast steak, egg, cheese, and condiments",Fast Foods,15.95,0.093,0.53,0.2,0,0.145,22.99,2.97,14.07,44.9,282,5.419,4.997,1.849,34368
"Fast foods, bagel, with egg, sausage patty, cheese, and condiments",Fast Foods,12.98,0.094,0.55,0.2,0.0005,0.123,22.64,2.87,16.97,45.17,295,6.396,6.026,2.603,34369
"Fast foods, bagel, with ham, egg, and cheese",Fast Foods,13.97,0.097,0.659,0.2,0,0.137,27.43,3.65,9.68,46.44,253,4.121,2.992,1.749,34370
"Fast foods, biscuit with egg and steak",Fast Foods,12.12,0.093,0.6,0,0.0001,0.207,14.37,0,19.21,52.51,277,5.81,7.91,3.95,34371
"Fast foods, biscuit, with egg",Fast Foods,8.53,0.06,0.655,0.6,0.0001,0.175,23.46,0,16.23,50.06,274,3.477,6.671,4.706,34372
"Fast foods, biscuit, with egg and bacon",Fast Foods,11.33,0.126,0.666,0.5,0.0018,0.167,19.06,2.19,20.73,46.67,305,5.3,8.96,4.98,34373
"Fast foods, biscuit, with egg and ham",Fast Foods,10.64,0.115,0.72,0.4,0,0.166,16.37,2.2,14.08,56.6,230,3.08,5.71,4.01,34374
"Fast foods, biscuit, with egg, cheese, and bacon",Fast Foods,12.01,0.103,0.816,0.2,0,0.131,24.44,1.74,17.48,42.99,301,5.893,8.035,1.922,34375
"Fast foods, biscuit, with ham",Fast Foods,11.85,0.142,1.268,0.7,0.0001,0.174,38.75,1.97,16.3,28.4,342,10.096,4.277,0.918,34376
"Fast foods, biscuit, with sausage",Fast Foods,9.67,0.047,0.814,0.4,0,0.153,29.99,1.59,24.42,32.82,371,7.427,12.157,3.107,34377
"Fast foods, brownie",Fast Foods,4.57,0.042,0.255,0,0.0053,0.139,64.95,0,16.84,12.65,405,5.22,6.377,4.398,34378
"Fast foods, burrito, with beans",Fast Foods,6.48,0.052,0.454,0,0.0009,0.301,32.92,0,6.22,52.53,206,3.174,2.184,0.551,34379
"Fast foods, burrito, with beans and cheese",Fast Foods,8.1,0.115,0.627,0,0.0009,0.267,29.55,0,6.29,53.91,203,3.682,1.335,0.959,34380
"Fast foods, burrito, with beans and chili peppers",Fast Foods,8.03,0.049,0.512,0,0.0006,0.284,28.47,0,7.19,54.37,202,3.729,2.632,0.473,34381
"Fast foods, burrito, with beans and meat",Fast Foods,9.73,0.046,0.578,0,0.0008,0.284,28.58,0,7.71,51.89,220,3.6,3.038,0.529,34382
"Fast foods, burrito, with beans, cheese, and beef",Fast Foods,7.18,0.064,0.488,0,0.0025,0.202,19.55,0,6.55,64.94,163,3.522,2.196,0.502,34383
"Fast foods, burrito, with beans, cheese, and chili peppers",Fast Foods,9.91,0.086,0.613,0,0.002,0.241,25.35,0,6.84,55.77,197,3.332,2.515,0.377,34384
"Fast foods, burrito, with beef",Fast Foods,12.09,0.038,0.678,0,0.0005,0.336,26.6,0,9.46,49.61,238,4.754,3.368,0.388,34385
"Fast foods, burrito, with beef and chili peppers",Fast Foods,10.7,0.043,0.555,0,0.0008,0.248,24.6,0,8.23,54.45,212,3.98,3.022,0.485,34386
"Fast foods, burrito, with beef, cheese, and chili peppers",Fast Foods,13.46,0.073,0.688,0,0.0012,0.219,20.96,0,8.15,55.19,208,3.42,3.267,0.732,34387
"Fast foods, burrito, with fruit (apple or cherry)",Fast Foods,3.38,0.021,0.286,0,0.001,0.141,47.27,0,12.87,35.63,312,6.175,4.621,1.426,34388
"Fast foods, cheeseburger, regular, double patty and bun, plain",Fast Foods,15.13,0.191,0.589,1,0,0.19,29.89,5.47,13.93,38.7,305,5.245,4.292,0.417,34389
"Fast foods, cheeseburger; double, large patty, with condiments and vegetables",Fast Foods,14.72,0.093,0.445,0,0.0004,0.231,15.37,0,16.92,51.09,273,6.85,6.726,1.821,34390
"Fast foods, cheeseburger; double, large patty; with condiments",Fast Foods,16.96,0.106,0.48,1,0.0006,0.242,14.43,3.49,16.22,50.34,272,6.473,5.855,0.48,34391
"Fast foods, cheeseburger; double, regular patty, with condiments and vegetables",Fast Foods,12.8,0.103,0.633,0,0.001,0.202,21.2,0,12.7,51.2,251,5.251,4.704,1.601,34392
"Fast foods, cheeseburger; double, regular patty; double decker bun with condiments and special sauce",Fast Foods,11.94,0.127,0.485,1.4,0.0003,0.182,21.53,3.26,14.1,50.54,261,5.045,5.088,3.826,34393
"Fast foods, cheeseburger; double, regular patty; plain",Fast Foods,17.16,0.18,0.621,0.7,0,0.216,20.8,4.02,17.4,42.17,308,7.08,5.794,0.562,34394
"Fast foods, cheeseburger; double, regular patty; with condiments",Fast Foods,14.89,0.16,0.657,0.8,0.0004,0.217,19.83,4.66,14.97,47.77,274,6.088,4.983,0.486,34395
"Fast foods, cheeseburger; double, regular, patty and bun; with condiments and vegetables",Fast Foods,13.04,0.074,0.404,0,0.0012,0.171,23.3,0,15.47,46.56,285,5.6,5.543,2.792,34396
"Fast foods, cheeseburger; single, large patty; plain",Fast Foods,17.28,0.167,0.533,1.4,0,0.217,20.8,3.83,17.02,42.63,305,6.76,5.52,0.516,34397
"Fast foods, cheeseburger; single, large patty; with condiments",Fast Foods,15.21,0.179,0.591,1.2,0.0003,0.223,19.72,3.6,14.4,48.36,269,7.033,5.959,1.264,34398
"Fast foods, cheeseburger; single, large patty; with condiments and bacon",Fast Foods,15.78,0.137,0.674,1.3,0.0007,0.238,18.89,4.63,15.86,46.85,282,6.126,5.427,0.672,34399
"Fast foods, cheeseburger; single, large patty; with condiments and vegetables",Fast Foods,11.59,0.095,0.385,1.4,0.0019,0.21,16.81,4,10.36,59.55,206,3.893,3.518,0.298,34400
"Fast foods, cheeseburger; single, large patty; with condiments, vegetables and ham",Fast Foods,15.55,0.119,0.674,0,0.0029,0.212,13.01,0,18.97,50.05,286,8.317,7.429,1.516,34401
"Fast foods, cheeseburger; single, regular patty, with condiments",Fast Foods,13.49,0.123,0.628,1.9,0.0004,0.184,25.46,5.24,12.9,45.78,270,5.797,5.035,1.363,34402
"Fast foods, cheeseburger; single, regular patty, with condiments and vegetables",Fast Foods,11.58,0.118,0.634,0,0.0015,0.149,18.27,0,12.85,55.2,233,5.966,4.654,0.955,34403
"Fast foods, cheeseburger; single, regular patty; plain",Fast Foods,15.13,0.191,0.589,1,0,0.19,29.89,5.47,13.93,38.7,303,5.245,4.292,0.417,34404
"Fast foods, cheeseburger; triple, regular patty; plain",Fast Foods,18.2,0.174,0.637,0.5,0,0.23,16.16,3.29,19.17,43.93,310,8.015,6.559,0.637,34405
"Fast foods, cheeseburger;single, large patty; with condiments, vegetables and mayonnaise",Fast Foods,13.03,0.078,0.465,1.2,0.0004,0.178,15.45,4.51,13.46,56.21,235,5.487,4.754,1.559,34406
"Fast foods, chicken fillet sandwich, plain",Fast Foods,13.25,0.033,0.526,0,0.004900000000000001,0.194,21.26,0,16.18,47.31,283,4.685,5.72,4.606,34407
"Fast foods, chicken fillet sandwich, with cheese",Fast Foods,12.9,0.113,0.543,0,0.0013,0.146,18.24,0,17,46.01,277,5.46,5.987,4.363,34408
"Fast foods, chicken tenders",Fast Foods,18.74,0.023,0.857,0.8,0,0.312,15.75,0,16.75,46.02,289,2.731,6.3,6.842,34409
"Fast foods, chicken, breaded and fried, boneless pieces, plain",Fast Foods,15.59,0.014,0.574,0.9,0.0006,0.261,16.32,0.88,18.82,47.08,297,4.022,7.977,5.32,34410
"Fast foods, chicken, breaded and fried, dark meat (drumstick or thigh)",Fast Foods,20.32,0.024,0.51,0,0,0.301,10.61,0,18.04,48.99,291,4.763,7.385,4.272,34411
"Fast foods, chicken, breaded and fried, light meat (breast or wing)",Fast Foods,21.91,0.037,0.598,0,0,0.347,12.01,0,18.11,45.7,303,4.812,7.502,4.163,34412
"Fast foods, chili con carne",Fast Foods,9.73,0.027,0.398,0,0.0006,0.273,8.67,0,3.27,76.7,101,1.356,1.347,0.209,34413
"Fast foods, chimichanga, with beef",Fast Foods,11.27,0.036,0.523,0,0.0027,0.337,24.6,0,11.31,50.68,244,4.888,4.635,0.653,34414
"Fast foods, chimichanga, with beef and cheese",Fast Foods,10.96,0.13,0.523,0,0.0015,0.111,21.49,0,12.81,52.7,242,6.108,5.155,0.398,34415
"Fast foods, chimichanga, with beef and red chili peppers",Fast Foods,9.53,0.037,0.615,0,0.0002,0.323,24.09,0,10.07,54.24,223,4.354,4.128,0.582,34416
"Fast foods, chimichanga, with beef, cheese, and red chili peppers",Fast Foods,8.15,0.121,0.497,0,0.001,0.183,21.26,0,9.75,59.19,202,4.649,3.923,0.303,34417
"Fast foods, clams, breaded and fried",Fast Foods,11.15,0.018,0.725,0,0,0.231,33.75,0,22.96,29.2,392,5.742,9.948,5.89,34418
"Fast foods, coleslaw",Fast Foods,0.95,0.03,0.203,1.9,0.0146,0.129,14.89,12.19,9.91,73.42,153,1.599,2.671,5.348,34419
"Fast foods, cookies, animal crackers",Fast Foods,6.18,0.017,0.408,0,0.0012,0.084,75.33,0,13.43,3.75,446,5.272,5.649,1.558,34420
"Fast foods, cookies, chocolate chip",Fast Foods,5.25,0.036,0.342,0,0.001,0.149,65.86,0,22.08,5.36,423,9.709,9.185,1.865,34421
"Fast foods, corn on the cob with butter",Fast Foods,3.06,0.003,0.02,0,0.0047,0.246,21.88,0,2.35,72.05,106,1.125,0.688,0.419,34422
"Fast foods, crispy chicken, bacon, and tomato club sandwich, with cheese, lettuce, and mayonnaise",Fast Foods,15.38,0.092,0.605,1.2,0.0038,0.256,22.61,4.99,11.76,48.02,257,3.372,4.5,3.207,34423
"Fast foods, croissant, with egg and cheese",Fast Foods,10.07,0.192,0.434,0,0.0001,0.137,19.14,0,19.45,45.47,290,11.075,5.938,1.076,34424
"Fast foods, croissant, with egg, cheese, and bacon",Fast Foods,12.58,0.117,0.689,0,0.0017,0.156,18.33,0,21.98,43.92,320,11.963,7.113,1.363,34425
"Fast foods, croissant, with egg, cheese, and ham",Fast Foods,12.45,0.095,0.711,0,0.0075,0.179,15.92,0,22.09,51.14,312,11.497,7.495,1.552,34426
"Fast foods, croissant, with egg, cheese, and sausage",Fast Foods,12.69,0.09,0.697,0,0.0001,0.177,15.45,0,23.85,45.87,327,11.391,8.908,1.881,34427
"Fast foods, danish pastry, cheese",Fast Foods,6.41,0.077,0.351,0,0.0029,0.128,31.53,0,27.06,33.8,388,5.63,17.144,2.663,34428
"Fast foods, danish pastry, cinnamon",Fast Foods,5.46,0.042,0.371,0,0.0029,0.109,53.24,0,19,20.9,397,3.953,12.037,1.87,34429
"Fast foods, danish pastry, fruit",Fast Foods,5.06,0.023,0.354,0,0.0017,0.117,47.94,0,16.95,29,356,3.527,10.74,1.668,34430
"Fast foods, egg and cheese sandwich",Fast Foods,10.69,0.154,0.551,0,0.001,0.129,17.76,0,13.3,56.3,233,4.538,5.662,1.769,34431
"Fast foods, egg, scrambled",Fast Foods,13.84,0.057,0.224,0,0.0033,0.147,2.08,1.64,16.18,66.7,212,6.153,5.889,1.969,34432
"Fast foods, enchilada, with cheese",Fast Foods,5.91,0.199,0.481,0,0.0006,0.147,17.51,0,11.56,63.23,196,6.496,3.872,0.501,34433
"Fast foods, enchilada, with cheese and beef",Fast Foods,6.21,0.119,0.687,0,0.0007,0.299,15.87,0,9.19,66.89,168,4.712,3.201,0.723,34434
"Fast foods, enchirito, with cheese, beef, and beans",Fast Foods,9.27,0.113,0.648,0,0.0024,0.29,17.51,0,8.33,62.67,178,4.118,3.377,0.17,34435
"Fast foods, english muffin, with butter",Fast Foods,7.73,0.163,0.613,0,0.0012,0.11,48.19,0,9.14,32.63,300,3.857,2.432,2.14,34436
"Fast foods, english muffin, with cheese and sausage",Fast Foods,13.28,0.21,0.668,0.5,0,0.179,25.28,1.98,20.67,37.93,338,8.152,7.905,3.115,34437
"Fast foods, english muffin, with egg, cheese, and canadian bacon",Fast Foods,13.53,0.193,0.556,0.4,0.0011,0.152,21.47,1.84,9.05,53.49,221,3.83,2.83,1.299,34438
"Fast foods, english muffin, with egg, cheese, and sausage",Fast Foods,13.38,0.168,0.47,0.2,0,0.147,17.44,1.49,18.1,48.82,286,6.928,6.765,2.772,34439
"Fast foods, fish sandwich, with tartar sauce",Fast Foods,10.72,0.053,0.389,0,0.0018,0.215,25.96,0,14.41,47.35,273,3.313,4.869,5.22,34440
"Fast foods, fish sandwich, with tartar sauce and cheese",Fast Foods,11.26,0.101,0.513,0,0.0015,0.193,26.03,0,15.63,45.18,286,4.448,4.874,5.154,34441
"Fast foods, french toast sticks",Fast Foods,6,0.053,0.428,1.4,0,0.111,41.21,9.89,17.74,33.57,340,3.989,9.502,2.566,34442
"Fast foods, french toast with butter",Fast Foods,7.66,0.054,0.38,0,0.0001,0.131,26.7,0,13.9,50.72,264,5.74,5.24,1.81,34443
"Fast foods, frijoles with cheese",Fast Foods,6.81,0.113,0.528,0,0.0009,0.362,17.19,0,4.66,69.11,135,2.44,1.567,0.417,34444
"Fast foods, griddle cake sandwich, egg, cheese, and bacon",Fast Foods,12.03,0.109,0.752,0.8,0.0018,0.158,26.19,9.56,13.19,45.85,272,4.201,4.686,1.775,34445
"Fast foods, griddle cake sandwich, egg, cheese, and sausage",Fast Foods,10.77,0.096,0.652,0.6,0,0.146,22.04,7.9,17.73,47.03,291,5.649,6.718,2.26,34446
"Fast foods, griddle cake sandwich, sausage",Fast Foods,8.41,0.063,0.737,1,0,0.145,31.25,11.23,17.76,40.11,318,5.363,7.531,2.414,34447
"Fast foods, grilled chicken, bacon and tomato club sandwich, with cheese, lettuce, and mayonnaise",Fast Foods,17.19,0.095,0.63,1.2,0.0044,0.226,19.87,4.68,8.05,52.52,220,3.109,2.949,1.88,34448
"Fast foods, ham and cheese sandwich",Fast Foods,14.17,0.089,0.528,0,0.0019,0.199,22.84,0,10.6,50.8,241,4.409,4.615,0.942,34449
"Fast foods, ham, egg, and cheese sandwich",Fast Foods,13.46,0.148,0.703,0,0.0019,0.147,21.64,0,11.4,51.15,243,5.175,4.017,1.182,34450
"Fast foods, hamburger, large, single patty, with condiments",Fast Foods,15.68,0.087,0.374,1.1,0.0003,0.225,22.14,3.67,11.6,48.81,256,4.785,5.404,1.179,34451
"Fast foods, hamburger, large, triple patty, with condiments",Fast Foods,19.3,0.025,0.275,0,0.0005,0.303,11.04,0,16.01,52.37,267,6.147,7.04,1.059,34452
"Fast foods, hamburger; double, large patty; with condiments and vegetables",Fast Foods,15.17,0.045,0.35,0,0.0005,0.252,17.82,0,11.75,53.74,239,4.654,4.57,1.237,34453
"Fast foods, hamburger; double, large patty; with condiments, vegetables and mayonnaise",Fast Foods,13.94,0.035,0.289,1.4,0.0002,0.192,13.74,3.52,15.66,55.29,252,5.773,6.117,3.016,34454
"Fast foods, hamburger; double, regular patty; with condiments",Fast Foods,14.8,0.043,0.345,0,0.0005,0.245,18.02,0,15.1,50.51,268,5.582,6.571,1.286,34455
"Fast foods, hamburger; double, regular, patty; plain",Fast Foods,17.08,0.102,0.414,0.9,0,0.226,24.1,4.15,14.36,42.67,295,5.096,5.495,0.38,34456
"Fast foods, hamburger; single, large patty; plain",Fast Foods,16.51,0.054,0.346,0,0,0.195,23.16,0,16.73,42.1,311,6.12,7.21,1.56,34457
"Fast foods, hamburger; single, large patty; with condiments",Fast Foods,15.68,0.087,0.374,1.1,0.0003,0.225,22.14,0,11.6,48.81,256,5.03,5.22,1.24,34458
"Fast foods, hamburger; single, large patty; with condiments and vegetables",Fast Foods,11.85,0.044,0.378,0,0.0012,0.22,18.35,0,12.55,55.7,235,4.78,5.24,1.01,34459
"Fast foods, hamburger; single, large patty; with condiments, vegetables and mayonnaise",Fast Foods,11.34,0.046,0.342,1.5,0.0009,0.188,17.33,4,12.37,57.52,226,3.972,4.67,3.144,34460
"Fast foods, hamburger; single, regular patty; double decker bun with condiments and special sauce",Fast Foods,12.17,0.103,0.386,1.4,0.0003,0.194,22.69,0,13.3,50.3,259,4.24,4.78,4.1,34461
"Fast foods, hamburger; single, regular patty; plain",Fast Foods,14.74,0.138,0.44,1.2,0,0.193,33.71,5.79,11.24,38.49,295,3.561,3.839,0.265,34462
"Fast foods, hamburger; single, regular patty; with condiments",Fast Foods,13.07,0.074,0.491,1.8,0.0003,0.183,28.79,5.22,10.07,46.14,258,4,4.637,1.138,34463
"Fast foods, hamburger; single, regular patty; with condiments and vegetables",Fast Foods,11.74,0.057,0.458,0,0.0015,0.206,24.81,0,12.25,49.41,254,3.755,4.809,2.343,34464
"Fast foods, hotdog, plain",Fast Foods,10.6,0.024,0.684,0,0.0001,0.146,18.4,0,14.84,53.96,247,5.213,6.993,1.741,34465
"Fast foods, hotdog, with chili",Fast Foods,11.85,0.017,0.421,0,0.0024,0.146,27.45,0,11.79,47.8,260,4.258,5.785,1.042,34466
"Fast foods, hotdog, with corn flour coating (corndog)",Fast Foods,9.6,0.058,0.556,0,0,0.15,31.88,0,10.8,46.7,263,2.949,5.205,1.998,34467
"Fast foods, hush puppies",Fast Foods,6.25,0.088,1.237,0,0,0.241,44.74,0,14.86,32.29,329,3.444,10.024,0.498,34468
"Fast foods, miniature cinnamon rolls",Fast Foods,7.02,0.054,0.554,2.2,0,0.131,53.38,17.98,17.95,19.62,403,4.701,9.221,2.415,34469
"Fast foods, nachos, with cheese",Fast Foods,8.05,0.241,0.722,0,0.0011,0.152,32.15,0,16.77,40.45,306,6.885,7.074,1.976,34470
"Fast foods, nachos, with cheese and jalapeno peppers",Fast Foods,8.24,0.304,0.851,0,0.0005,0.144,29.45,0,16.74,42.7,298,6.872,7.061,1.973,34471
"Fast foods, nachos, with cheese, beans, ground beef, and peppers",Fast Foods,7.76,0.151,0.706,0,0.0019,0.177,21.89,0,12.04,55.96,223,4.897,4.306,2.231,34472
"Fast foods, nachos, with cinnamon and sugar",Fast Foods,6.6,0.078,0.403,0,0.0073,0.072,58.16,0,33.01,1.01,543,16.707,10.864,3.786,34473
"Fast foods, onion rings, breaded and fried",Fast Foods,4.46,0.088,0.518,0,0.0007,0.156,37.74,0,18.69,37.09,332,8.377,8.013,0.801,34474
"Fast foods, oysters, battered or breaded, and fried",Fast Foods,9.02,0.02,0.487,0,0.003,0.131,28.69,0,12.9,47.97,265,3.294,4.979,3.337,34475
"Fast foods, pancakes with butter and syrup",Fast Foods,3.56,0.055,0.476,0,0.0015,0.108,39.18,0,6.03,49.72,224,2.522,2.271,0.844,34476
"Fast foods, potato, baked and topped with cheese sauce",Fast Foods,4.94,0.105,0.129,0,0.0088,0.394,15.71,0,9.71,65.75,160,3.567,3.615,2.042,34477
"Fast foods, potato, baked and topped with cheese sauce and bacon",Fast Foods,6.16,0.103,0.325,0,0.0096,0.394,14.86,0,8.66,65.02,151,3.389,3.249,1.589,34478
"Fast foods, potato, baked and topped with cheese sauce and broccoli",Fast Foods,4.03,0.099,0.143,0,0.0143,0.425,13.74,0,6.32,70.04,119,2.511,2.265,1.232,34479
"Fast foods, potato, baked and topped with cheese sauce and chili",Fast Foods,5.88,0.104,0.177,0,0.008,0.398,14.14,0,5.53,70.08,122,3.3,1.731,0.227,34480
"Fast foods, potato, baked and topped with sour cream and chives",Fast Foods,2.21,0.035,0.06,0,0.0112,0.458,16.56,0,7.39,69.45,130,3.315,2.607,1.098,34481
"Fast foods, potato, french fried in vegetable oil",Fast Foods,3.76,0.013,0.29,3.5,0.0027,0.55,38.44,0.69,16.12,39.76,314,2.084,7.805,4.664,34482
"Fast foods, potato, mashed",Fast Foods,2.31,0.021,0.227,0,0.0004,0.294,16.12,0,1.21,79.21,83,0.479,0.351,0.292,34483
"Fast foods, potatoes, hashed brown",Fast Foods,2.61,0.016,0.518,2.7,0.0029,0.356,32.2,0.24,21.65,41.49,327,4.947,11.545,3.796,34484
"Fast foods, roast beef sandwich, plain",Fast Foods,15.47,0.039,0.57,0,0.0015,0.227,24.06,0,9.9,48.64,249,2.594,4.895,1.227,34485
"Fast foods, salad, vegetable, tossed, without dressing",Fast Foods,1.25,0.013,0.026,0,0.0232,0.172,3.22,0,0.07,95.51,16,0.01,0.004,0.034,34486
"Fast foods, salad, vegetable, tossed, without dressing, with cheese and egg",Fast Foods,4.04,0.046,0.055,0,0.0045,0.171,2.19,0,2.67,90.46,47,1.371,0.81,0.219,34487
"Fast foods, salad, vegetable, tossed, without dressing, with chicken",Fast Foods,8,0.017,0.096,0,0.008,0.205,1.71,0,1,87.05,48,0.265,0.311,0.255,34488
"Fast foods, salad, vegetable, tossed, without dressing, with pasta and seafood",Fast Foods,3.94,0.017,0.377,0,0.0092,0.144,7.67,0,5,80.37,91,0.616,1.156,2.178,34489
"Fast foods, salad, vegetable, tossed, without dressing, with shrimp",Fast Foods,6.15,0.025,0.207,0,0.0039,0.171,2.8,0,1.05,89.1,45,0.276,0.348,0.205,34490
"Fast foods, salad, vegetables tossed, without dressing, with turkey, ham and cheese",Fast Foods,7.98,0.072,0.228,0,0.005,0.123,1.45,0,4.93,82.45,82,2.508,1.585,0.443,34491
"Fast foods, scallops, breaded and fried",Fast Foods,10.94,0.013,0.638,0,0,0.204,26.73,0,13.47,48.01,268,3.391,8.718,0.42,34492
"Fast foods, shrimp, breaded and fried",Fast Foods,11.51,0.051,0.882,0,0,0.112,24.39,0,15.18,47.82,277,3.28,10.597,0.393,34493
"Fast foods, submarine sandwich, with cold cuts",Fast Foods,9.58,0.083,0.724,0,0.0054,0.173,22.39,0,8.17,57.8,200,2.986,3.608,1.001,34494
"Fast foods, submarine sandwich, with roast beef",Fast Foods,13.26,0.019,0.391,0,0.0026,0.153,20.51,0,6,58.97,190,3.281,0.85,1.209,34495
"Fast foods, submarine sandwich, with tuna salad",Fast Foods,11.6,0.029,0.505,0,0.0014,0.131,21.63,0,10.93,54.31,228,2.082,5.235,2.85,34496
"Fast foods, sundae, caramel",Fast Foods,4.71,0.122,0.126,0,0.0022,0.205,31.81,0,5.98,56.5,196,2.91,1.957,0.653,34497
"Fast foods, sundae, hot fudge",Fast Foods,3.57,0.131,0.115,0,0.0015,0.25,30.17,0,5.46,59.7,180,3.179,1.475,0.511,34498
"Fast foods, sundae, strawberry",Fast Foods,4.09,0.105,0.06,0,0.0013,0.177,29.18,0,5.13,60.9,175,2.444,1.739,0.667,34499
"Fast foods, taco",Fast Foods,12.08,0.129,0.469,0,0.0013,0.277,15.63,0,12.02,58.4,217,6.648,3.846,0.561,34500
"Fast foods, taco salad",Fast Foods,6.68,0.097,0.385,0,0.0018,0.21,11.91,0,7.46,72.35,141,3.446,2.606,0.883,34501
"Fast foods, taco salad with chili con carne",Fast Foods,6.67,0.094,0.339,0,0.0013,0.15,10.18,0,5.03,76.79,111,2.299,1.739,0.589,34502
"Fast foods, tostada, with beans and cheese",Fast Foods,6.67,0.146,0.377,0,0.0009,0.28,18.42,0,6.85,66.24,155,3.727,2.121,0.52,34503
"Fast foods, tostada, with beans, beef, and cheese",Fast Foods,7.15,0.084,0.387,0,0.0018,0.218,13.18,0,7.53,70.44,148,5.1,1.561,0.265,34504
"Fast foods, tostada, with beef and cheese",Fast Foods,11.65,0.133,0.55,0,0.0016,0.351,13.97,0,10.03,62.05,193,6.377,2.052,0.598,34505
"Fast foods, vanilla, light, soft-serve ice cream, with cone",Fast Foods,4.24,0.129,0.067,0.1,0.0009,0.193,26.36,19.49,4.86,63.64,166,2.542,1.206,0.297,34506
"KENTUCKY FRIED CHICKEN, Biscuit, analyzed prior to January 2007",Fast Foods,7.25,0.06,1.053,1.5,0,0.115,42.51,3.22,17.45,28.96,356,4.197,11.686,1.159,34507
"KENTUCKY FRIED CHICKEN, Coleslaw",Fast Foods,0.91,0.028,0.189,2,0.0146,0.14,15.65,13.54,8.64,73.99,144,1.303,1.963,4.888,34508
"KENTUCKY FRIED CHICKEN, Crispy Chicken Strips",Fast Foods,20.25,0.025,0.948,1.4,0,0.333,13.66,0,15.42,47.93,274,2.887,4.308,6.881,34509
"KENTUCKY FRIED CHICKEN, Fried Chicken, EXTRA CRISPY, Breast, meat and skin with breading",Fast Foods,21.24,0.027,0.607,0,0,0.258,8.47,0,16.55,51.42,268,3.538,5.422,6.276,34510
"KENTUCKY FRIED CHICKEN, Fried Chicken, EXTRA CRISPY, Breast, meat only, skin and breading removed",Fast Foods,27.25,0.02,0.477,0,0,0.309,0.25,0,4.82,65.71,153,1.196,1.683,1.62,34511
"KENTUCKY FRIED CHICKEN, Fried Chicken, EXTRA CRISPY, Drumstick, meat and skin with breading",Fast Foods,20.62,0.025,0.632,0,0,0.237,7.96,0,17.73,51.44,274,3.887,6.049,6.353,34512
"KENTUCKY FRIED CHICKEN, Fried Chicken, EXTRA CRISPY, Drumstick, meat only, skin and breading removed",Fast Foods,25.9,0.016,0.526,0,0,0.273,0,0,7.43,64.78,170,1.893,2.903,2.051,34513
"KENTUCKY FRIED CHICKEN, Fried Chicken, EXTRA CRISPY, Skin and Breading",Fast Foods,10.96,0.041,0.828,1.5,0,0.17,22.54,0,36.61,26.98,464,7.541,11.816,14.238,34514
"KENTUCKY FRIED CHICKEN, Fried Chicken, EXTRA CRISPY, Thigh, meat and skin with breading",Fast Foods,17.17,0.03,0.682,0,0,0.225,10.3,0,22.14,47.99,309,4.917,7.661,7.738,34515
"KENTUCKY FRIED CHICKEN, Fried Chicken, EXTRA CRISPY, Thigh, meat only, skin and breading removed",Fast Foods,22.41,0.021,0.558,0,0,0.271,0,0,9.96,65.68,179,2.709,4.164,2.267,34516
"KENTUCKY FRIED CHICKEN, Fried Chicken, EXTRA CRISPY, Wing, meat and skin with breading",Fast Foods,20.8,0.035,0.765,0,0,0.223,11.66,0,23,41.91,337,4.796,7.424,8.57,34517
"KENTUCKY FRIED CHICKEN, Fried Chicken, EXTRA CRISPY, Wing, meat only, skin and breading removed",Fast Foods,28.67,0.03,0.714,0,0,0.265,2.97,0,12.13,53.85,236,2.603,3.917,4.045,34518
"KENTUCKY FRIED CHICKEN, Fried Chicken, ORIGINAL RECIPE, Breast, meat and skin with breading",Fast Foods,22.54,0.031,0.589,0,0,0.272,5.53,0,12.03,57.45,221,2.748,4.27,3.801,34519
"KENTUCKY FRIED CHICKEN, Fried Chicken, ORIGINAL RECIPE, Breast, meat only, skin and breading removed",Fast Foods,26.76,0.019,0.469,0,0,0.304,0.17,0,4.3,66.67,146,1.089,1.53,1.189,34520
"KENTUCKY FRIED CHICKEN, Fried Chicken, ORIGINAL RECIPE, Drumstick, meat and skin with breading",Fast Foods,22.3,0.029,0.625,0,0,0.25,5.39,0,14.22,55.6,239,3.24,5.115,4.29,34521
"KENTUCKY FRIED CHICKEN, Fried Chicken, ORIGINAL RECIPE, Drumstick, meat only, skin and breading removed",Fast Foods,26.3,0.016,0.524,0,0,0.273,0.11,0,7.66,63.77,175,1.838,2.816,1.963,34522
"KENTUCKY FRIED CHICKEN, Fried Chicken, ORIGINAL RECIPE, Skin and Breading",Fast Foods,13,0.059,0.86,0,0,0.199,17.63,0,29.47,36.63,388,6.495,10.454,9.696,34523
"KENTUCKY FRIED CHICKEN, Fried Chicken, ORIGINAL RECIPE, Thigh, meat and skin with breading",Fast Foods,18.75,0.036,0.667,0,0,0.237,7.16,0,17.38,54.16,260,4.203,6.676,5.119,34524
"KENTUCKY FRIED CHICKEN, Fried Chicken, ORIGINAL RECIPE, Thigh, meat only, skin and breading removed",Fast Foods,22.54,0.02,0.541,0,0,0.263,0.26,0,9.43,65.7,176,2.694,4.189,2.106,34525
"KENTUCKY FRIED CHICKEN, Fried Chicken, ORIGINAL RECIPE, Wing, meat and skin with breading",Fast Foods,21.94,0.041,0.754,0,0,0.232,8.69,0,18.14,48.36,286,4.073,6.462,5.901,34526
"KENTUCKY FRIED CHICKEN, Fried Chicken, ORIGINAL RECIPE, Wing, meat only, skin and breading removed",Fast Foods,27.88,0.029,0.683,0,0,0.254,2.75,0,10.62,56.14,218,2.465,3.813,3.382,34527
"KENTUCKY FRIED CHICKEN, Popcorn Chicken",Fast Foods,17.67,0.032,1.14,1,0,0.288,21.18,0,21.74,35.62,351,3.954,5.66,10.093,34528
"KENTUCKY FRIED CHICKEN, Potato Wedges, analyzed prior to January 2007",Fast Foods,4.05,0.026,0.757,3.1,0,0.522,33.55,0,14.77,44.19,283,2.981,8.858,1.799,34529
"LITTLE CAESARS 14"" Cheese Pizza, Large Deep Dish Crust",Fast Foods,12.63,0.224,0.432,1.3,0,0.16,30.1,3.66,10.22,44.9,263,4.237,2.757,1.728,34530
"LITTLE CAESARS 14"" Cheese Pizza, Thin Crust",Fast Foods,16.23,0.422,0.455,1.6,0,0.177,22.85,2.76,16.99,41.21,309,7.327,4.42,3.161,34531
"LITTLE CAESARS 14"" Original Round Cheese Pizza, Regular Crust",Fast Foods,13.39,0.233,0.454,1.7,0,0.17,31.5,4.02,9.54,43.39,265,4.206,2.38,1.461,34532
"LITTLE CAESARS 14"" Original Round Meat and Vegetable Pizza, Regular Crust",Fast Foods,12.12,0.167,0.578,2.1,0,0.195,23.1,3.74,11.35,51.1,243,4.561,4.063,1.72,34533
"LITTLE CAESARS 14"" Original Round Pepperoni Pizza, Regular Crust",Fast Foods,13.59,0.21,0.518,1.7,0,0.178,31.01,3.76,10.5,42.57,273,4.541,3.108,1.603,34534
"LITTLE CAESARS 14"" Pepperoni Pizza, Large Deep Dish Crust",Fast Foods,12.93,0.201,0.492,1.5,0,0.173,29.03,3.39,10.81,44.98,265,4.314,3.151,1.756,34535
"Light Ice Cream, soft serve, blended with cookie pieces",Fast Foods,3.97,0.129,0.075,0.1,0.0008,0.198,25.55,21.19,5.66,63.9,169,2.754,1.716,0.364,34536
"Light Ice Cream, soft serve, blended with milk chocolate candies",Fast Foods,4.02,0.135,0.054,0.2,0.0009,0.208,26.82,24.37,6.46,61.74,182,3.633,1.67,0.282,34537
McDONALD'S Bacon Ranch Salad with Crispy Chicken,Fast Foods,8.82,0.046,0.295,1,0.009699999999999999,0,6.08,1.53,6.3,77.38,110,1.622,2.467,1.619,34538
"McDONALD'S, Apple Dippers",Fast Foods,0,0.062,0,0,0.277,0,12.1,9.2,0,87.5,48,0,0,0,34539
"McDONALD'S, Apple Dippers with Low Fat Caramel Sauce",Fast Foods,0.45,0.064,0.04,0,0.21159999999999998,0,26.12,17.22,0.77,72.16,111,0.418,0.182,0.033,34540
"McDONALD'S, BIG 'N TASTY",Fast Foods,10.64,0.065,0.317,1.4,0.0019,0.2,16.6,3.79,13.68,57.71,226,3.726,4.195,2.995,34541
"McDONALD'S, BIG 'N TASTY (without mayonnaise)",Fast Foods,11.25,0.069,0.309,1.5,0.002,0.212,17.44,3.96,9.11,60.77,192,3.155,3.304,0.231,34542
"McDONALD'S, BIG 'N TASTY with Cheese",Fast Foods,11,0.091,0.387,1.3,0.0018,0.199,16.06,3.83,14.58,56.74,232,4.387,4.343,2.891,34543
"McDONALD'S, BIG 'N TASTY with Cheese (without mayonnaise)",Fast Foods,11.59,0.095,0.382,1.4,0.0019,0.21,16.81,4,10.36,59.55,201,3.893,3.518,0.298,34544
"McDONALD'S, BIG BREAKFAST",Fast Foods,10.16,0.051,0.549,1.1,0.0004,0.207,17.5,0.93,19.37,50.68,285,6.405,7.372,3.195,34545
"McDONALD'S, BIG MAC",Fast Foods,11.82,0.116,0.46,1.6,0.0004,0.181,20.08,3.97,14.96,51.3,257,3.803,3.474,0.306,34546
"McDONALD'S, BIG MAC (without Big Mac Sauce)",Fast Foods,12.79,0.124,0.454,1.7,0.0004,0.192,21.01,3.7,11.57,52.76,234,4.162,3.802,0.334,34547
"McDONALD'S, Bacon Egg & Cheese Biscuit",Fast Foods,13.45,0.109,0.863,0.9,0.0021000000000000003,0.121,22.26,2.18,18.77,42.29,304,8.262,5.546,2.625,34548
"McDONALD'S, Bacon Ranch Salad with Grilled Chicken",Fast Foods,10.28,0.048,0.313,1,0.010199999999999999,0,3.64,1.73,3.14,81.42,81,1.235,1.014,0.431,34549
"McDONALD'S, Bacon Ranch Salad without chicken",Fast Foods,4.14,0.063,0.132,1.5,0.0135,0.201,4.2,2.14,3.64,87.2,61,1.507,1.057,0.327,34550
"McDONALD'S, Bacon, Egg & Cheese McGRIDDLES",Fast Foods,12.03,0.109,0.752,0.8,0.0018,0,26.19,9.56,13.19,45.85,272,4.201,4.686,1.775,34551
"McDONALD'S, Baked Apple Pie",Fast Foods,3.07,0.019,0.199,2,0.032299999999999995,0.063,43.62,17.25,15.66,36.98,323,3.995,9.183,1.038,34552
"McDONALD'S, Barbeque Sauce",Fast Foods,1.55,0.011,0.91,1.3,0,0.197,36.93,34.31,1.22,57.68,165,0.14,0.271,0.504,34553
"McDONALD'S, Biscuit, large size",Fast Foods,6.16,0.076,0.974,2,0,0.12,43.76,2.96,16.01,30.71,344,9.26,3.749,2.227,34554
"McDONALD'S, Biscuit, regular size",Fast Foods,6.16,0.076,0.974,2,0,0.12,43.77,2.96,16,30.71,344,9.26,3.748,2.223,34555
"McDONALD'S, Caesar Salad with Crispy Chicken",Fast Foods,8.32,0.061,0.267,1.1,0.01,0.254,6.2,1.47,5.31,78.77,104,1.406,2.11,1.521,34556
"McDONALD'S, Caesar Salad with Grilled Chicken",Fast Foods,9.82,0.064,0.285,1.1,0.0106,0.255,3.67,1.61,2.04,82.95,71,1.005,0.606,0.294,34557
"McDONALD'S, Caesar Salad without chicken",Fast Foods,3.18,0.086,0.083,1.6,0.0141,0.216,4.28,2.08,2.05,89.71,44,1.182,0.461,0.143,34558
"McDONALD'S, Cheeseburger",Fast Foods,12.97,0.167,0.626,1.1,0.0006,0.2,27.81,6.22,11.79,45,263,4.435,3.631,0.355,34559
"McDONALD'S, Chicken McNUGGETS",Fast Foods,14.97,0.014,0.632,0.8,0.0012,0.251,17.22,0,18.07,47.44,291,3.133,8.177,5.896,34560
"McDONALD'S, Chicken SELECTS Premium Breast Strips",Fast Foods,17.23,0.02,0.767,0.2,0,0.291,17.83,0,18.08,44.12,303,2.575,8.129,6.597,34561
"McDONALD'S, Chocolate TRIPLE THICK Shake",Fast Foods,3.69,0.122,0.071,0.2,0,0.226,27.87,23.63,4.51,62.98,163,2.302,1.122,0.212,34562
"McDONALD'S, Creamy Ranch Sauce",Fast Foods,1.11,0.029,0.708,0,0,0.078,3.49,3.45,52.03,41.36,468,7.894,11.094,28.178,34563
"McDONALD'S, DOUBLE QUARTER POUNDER with Cheese",Fast Foods,16.96,0.106,0.476,1,0.0006,0.242,14.43,3.49,16.22,50.34,262,6.473,5.855,0.48,34564
"McDONALD'S, Deluxe Breakfast, with syrup and margarine",Fast Foods,7.56,0.05,0.437,1,0.0002,0.17,29.48,9.88,15.23,45.91,285,4.642,5.217,2.811,34565
"McDONALD'S, Deluxe Warm Cinnamon Roll",Fast Foods,5.62,0.048,0.407,2.5,0,0.094,51.8,22.23,16.24,24.97,367,4.606,7.452,1.985,34566
"McDONALD'S, Double Cheeseburger",Fast Foods,14.89,0.16,0.657,0.8,0.0004,0.217,19.83,4.66,14.97,47.77,265,6.088,4.983,0.486,34567
"McDONALD'S, Egg McMUFFIN",Fast Foods,12.65,0.198,0.619,1.1,0.0012,0.157,20.54,2.01,9.11,55.24,215,3.222,2.722,1.812,34568
"McDONALD'S, English Muffin",Fast Foods,9.26,0.292,0.485,2.6,0,0.128,44.36,2.57,7.87,36.26,285,1.554,1.936,4.092,34569
"McDONALD'S, FILET-O-FISH",Fast Foods,10.99,0.115,0.485,1,0.0001,0.175,27.45,2.65,13.47,46.18,275,2.597,3.85,5.633,34570
"McDONALD'S, FILET-O-FISH (without tartar sauce)",Fast Foods,12.47,0.128,0.464,1,0.0001,0.194,31.08,4.27,7.62,46.9,243,1.773,2.768,2.323,34571
"McDONALD'S, French Fries",Fast Foods,3.81,0.017,0.227,4.2,0.0073,0.56,39.02,0.2,16.12,39.76,316,2.084,7.805,4.664,34572
"McDONALD'S, Fruit & Walnut Salad",Fast Foods,1.81,0.065,0.032,0,0.1453,0,16.6,12.45,5.05,75.95,118,0.699,0.81,3.204,34573
"McDONALD'S, Fruit 'n Yogurt Parfait",Fast Foods,2.73,0.086,0.058,1,0.013900000000000001,0.167,20.72,12.83,1.3,74.61,105,0.041,0.106,0.095,34574
"McDONALD'S, Fruit 'n Yogurt Parfait (without granola)",Fast Foods,2.47,0.087,0.038,0.9,0.0145,0.165,17.67,11.9,1.13,78.18,90,0.004,0.01,0.033,34575
"McDONALD'S, Hamburger",Fast Foods,12.36,0.121,0.507,1.3,0.0006,0.203,30.69,6.58,9.29,45.67,252,2.938,3.17,0.223,34576
"McDONALD'S, Hash Browns",Fast Foods,2.26,0.014,0.548,2.8,0.0017,0.391,26.3,0.06,16.46,52.73,262,2.263,8.525,4.945,34577
"McDONALD'S, Honey",Fast Foods,0.09,0.005,0.007,0,0.0007,0.03,86.44,74.32,0,13.4,351,0,0,0,34578
"McDONALD'S, Hot Caramel Sundae",Fast Foods,3.58,0.127,0.08,0,0,0.186,33.36,23.54,4.89,57.27,188,2.468,1.21,0.232,34579
"McDONALD'S, Hot Fudge Sundae",Fast Foods,4.14,0.139,0.094,0.4,0,0.246,30.05,26.89,5.91,58.81,186,3.592,1.045,0.22,34580
"McDONALD'S, Hot Mustard Sauce",Fast Foods,2.6,0.032,0.902,5,0,0.083,29.08,21.76,7.31,58.65,190,0.987,1.724,3.406,34581
"McDONALD'S, Hotcakes (plain)",Fast Foods,6,0.084,0.358,1.4,0,0.173,38.27,8.84,5.84,48.24,228,1.158,1.236,2.978,34582
"McDONALD'S, Hotcakes (with 2 pats margarine & syrup)",Fast Foods,4.05,0.057,0.283,0.9,0,0.125,46.08,20.58,8.06,40.6,272,0.818,0.863,2.087,34583
"McDONALD'S, Hotcakes and Sausage",Fast Foods,5.87,0.051,0.354,0.8,0,0.148,38.79,17.3,13.21,40.7,294,2.747,3.426,2.468,34584
"McDONALD'S, Low Fat Caramel Sauce",Fast Foods,1.89,0.071,0.17,0,0,0.119,71.53,43.21,3.25,22.5,313,1.772,0.771,0.139,34585
"McDONALD'S, McCHICKEN Sandwich (without mayonnaise)",Fast Foods,11.08,0.096,0.571,1.4,0.0009,0.184,30.95,4.16,8.46,47.43,240,1.636,3.405,2.881,34586
"McDONALD'S, McDONALDLAND Chocolate Chip Cookies",Fast Foods,5.56,0.034,0.293,2,0,0.194,66.24,33.86,22.79,4.19,480,11.373,5.894,1.305,34587
"McDONALD'S, McDONALDLAND Cookies",Fast Foods,6.56,0.017,0.483,1.6,0,0.098,72.75,24.13,15.35,3.97,447,3.244,8.087,2.151,34588
"McDONALD'S, McFLURRY with M&M'S CANDIES",Fast Foods,4.02,0.135,0.054,0.2,0,0.208,26.82,24.37,6.46,61.74,177,3.402,1.67,0.282,34589
"McDONALD'S, McFLURRY with OREO cookies",Fast Foods,3.97,0.129,0.075,0.1,0,0.198,25.55,21.19,5.66,63.9,165,2.577,1.716,0.364,34590
"McDONALD'S, NEWMAN'S OWN Cobb Dressing",Fast Foods,1.69,0.05,0.709,0.3,0.0005,0.02,15.49,7.79,14.45,66.41,196,2.77,3.67,7.91,34591
"McDONALD'S, NEWMAN'S OWN Creamy Caesar Dressing",Fast Foods,3.56,0.104,0.851,0.1,0,0.027,6.85,3,31.5,54.9,319,5.86,7.75,16.3,34592
"McDONALD'S, NEWMAN'S OWN Low Fat Balsamic Vinaigrette",Fast Foods,0.19,0.008,1.562,0.4,0.004,0.02,24.65,6.74,5.94,66.05,86,0.87,2.19,2.8,34593
"McDONALD'S, NEWMAN'S OWN Ranch Dressing",Fast Foods,2.62,0.077,0.954,0.3,0.0008,0.12,16.75,6.57,26.57,51.35,313,4.36,15.35,6.35,34594
"McDONALD'S, Peanuts (for Sundaes)",Fast Foods,28.04,0.046,0,0,0,0.634,16.23,4.15,52.91,0.56,640,8.305,25.749,13.979,34595
"McDONALD'S, Premium Crispy Chicken Classic Sandwich",Fast Foods,12.08,0.034,0.512,1.4,0.0025,0.227,25.46,5.37,8.68,51.98,228,1.409,3.179,3.708,34596
"McDONALD'S, Premium Crispy Chicken Club Sandwich",Fast Foods,15.38,0.092,0.605,1.2,0.0038,0,22.61,4.99,11.76,48.02,250,3.333,4.223,3.557,34597
"McDONALD'S, Premium Crispy Chicken Ranch BLT Sandwich",Fast Foods,14.7,0.035,0.637,1.4,0.0043,0,24.93,5.53,9.55,48.61,244,1.971,3.773,3.231,34598
"McDONALD'S, Premium Grilled Chicken Classic Sandwich",Fast Foods,14.16,0.037,0.54,1.4,0.0032,0.228,22.28,5.01,4.29,57.3,183,0.865,1.157,2.074,34599
"McDONALD'S, Premium Grilled Chicken Club Sandwich",Fast Foods,17.19,0.095,0.63,1.2,0.0044,0,19.87,4.68,8.05,52.52,221,2.89,2.508,2.16,34600
"McDONALD'S, Premium Grilled Chicken Ranch BLT Sandwich",Fast Foods,16.7,0.038,0.666,1.4,0.004900000000000001,0,21.91,5.2,5.41,53.61,204,1.463,1.865,1.678,34601
"McDONALD'S, QUARTER POUNDER",Fast Foods,14.1,0.084,0.427,1.6,0.0009,0.227,22.17,5.13,11.55,50.37,244,4.008,4.202,0.283,34602
"McDONALD'S, QUARTER POUNDER with Cheese",Fast Foods,14.59,0.144,0.579,1.4,0.0008,0.219,19.95,4.91,14.22,48.9,258,5.643,4.608,0.433,34603
"McDONALD'S, Sausage Biscuit",Fast Foods,9.62,0.055,0.875,1.2,0,0.177,27.2,1.97,25.4,34.71,376,10.659,8.832,3.42,34604
"McDONALD'S, Sausage Biscuit with Egg",Fast Foods,11.27,0.058,0.664,0.8,0,0.165,19.28,1.43,22.26,44.62,311,8.628,7.748,3.169,34605
"McDONALD'S, Sausage Burrito",Fast Foods,11.54,0.18,0.675,1.1,0.0008,0.137,21.39,1.25,15.15,49.41,262,5.361,5.735,2.109,34606
"McDONALD'S, Sausage McGRIDDLES",Fast Foods,8.41,0.063,0.737,1,0,0.145,31.25,11.23,17.76,40.11,312,5.435,7.532,2.591,34607
"McDONALD'S, Sausage McMUFFIN",Fast Foods,12.66,0.224,0.693,1.4,0,0.188,24.52,1.93,21.06,38.94,333,7.253,7.749,3.234,34608
"McDONALD'S, Sausage McMuffin with Egg",Fast Foods,12.59,0.172,0.572,0.9,0,0.171,17.28,1.57,17.83,49.96,274,6.008,6.581,2.768,34609
"McDONALD'S, Sausage Patty",Fast Foods,15.19,0.023,0.721,0,0,0.268,1.4,0.43,39.58,41.21,405,12.83,16.719,4.824,34610
"McDONALD'S, Sausage, Egg & Cheese McGRIDDLES",Fast Foods,10.77,0.096,0.652,0.6,0,0.146,22.04,7.9,17.73,47.03,283,5.649,6.718,2.26,34611
"McDONALD'S, Scrambled Eggs",Fast Foods,15.06,0.066,0.192,0,0,0.139,1.85,0.26,14.69,66.94,193,4.062,5.22,2.195,34612
"McDONALD'S, Side Salad",Fast Foods,1.03,0.024,0.012,1.6,0.0157,0.22,4.3,2.24,0.19,93.93,20,0.02,0.006,0.079,34613
"McDONALD'S, Spicy Buffalo Sauce",Fast Foods,0.54,0.009,2.14,1.5,0,0.1,1.81,0,15.45,76,142,2.277,3.222,8.433,34614
"McDONALD'S, Strawberry Sundae",Fast Foods,3.19,0.11,0.047,0,0.0015,0.173,28.09,25.32,3.95,64.02,158,2.008,0.976,0.19,34615
"McDONALD'S, Strawberry TRIPLE THICK Shake",Fast Foods,3.51,0.122,0.049,0,0.0004,0.184,26.79,23.77,4.43,64.45,158,2.252,1.095,0.213,34616
"McDONALD'S, Sweet 'N Sour Sauce",Fast Foods,0.66,0.006,0.557,0,0.0012,0.099,39.38,35.79,1.09,57.37,170,0.148,0.241,0.514,34617
"McDONALD'S, Tangy Honey Mustard Sauce",Fast Foods,1.32,0.023,0.383,2.7,0,0.049,28.81,21.88,5.77,63,167,0.895,1.334,2.679,34618
"McDONALD'S, Vanilla Reduced Fat Ice Cream Cone",Fast Foods,4.24,0.129,0.067,0.1,0,0.193,26.36,19.49,4.86,63.64,162,2.41,1.206,0.297,34619
"McDONALD'S, Vanilla TRIPLE THICK Shake",Fast Foods,3.47,0.121,0.053,0,0,0.176,26.61,20.43,4.43,64.69,156,2.25,1.094,0.213,34620
"McDONALD'S, Warm Cinnamon Roll",Fast Foods,7.18,0.057,0.378,1.9,0,0.14,53.02,24.64,18.14,20.34,398,4.47,9.062,2.811,34621
"PAPA JOHN'S 14"" Cheese Pizza, Original Crust",Fast Foods,11.53,0.166,0.578,1.9,0,0.138,32.74,5.32,9.25,44.16,260,3.871,2.476,1.97,34622
"PAPA JOHN'S 14"" Cheese Pizza, Thin Crust",Fast Foods,12.29,0.238,0.528,2.3,0,0.16,26.26,3.85,15.66,43.3,295,6.202,6.002,1.663,34623
"PAPA JOHN'S 14"" Pepperoni Pizza, Original Crust",Fast Foods,11.97,0.152,0.671,1.2,0,0.15,30.04,4.84,11.86,43.6,275,4.908,3.912,2.206,34624
"PAPA JOHN'S 14"" The Works Pizza, Original Crust",Fast Foods,10.27,0.099,0.57,2.5,0,0.16,26.69,4.84,10.21,50.57,240,3.765,3.652,1.877,34625
"PIZZA HUT 12"" Cheese Pizza, Regular Crust",Fast Foods,11.93,0.209,0.685,1.8,0,0.173,31.22,3.37,10.89,43.29,271,5.04,2.919,1.839,34626
"PIZZA HUT 12"" Cheese Pizza, THIN 'N CRISPY Crust",Fast Foods,15.29,0.278,0.784,1.6,0,0.191,28.64,2.67,14.1,38.77,303,6.896,3.807,1.698,34627
"PIZZA HUT 12"" Cheese Pizza, Thick Crust",Fast Foods,11.73,0.208,0.624,1.7,0,0.168,29.93,3.21,12.56,43.4,280,5.184,3.213,2.752,34628
"PIZZA HUT 12"" Pepperoni Pizza, Regular Crust",Fast Foods,12.86,0.155,0.801,1.7,0,0.207,31.55,3.67,11.38,41.35,280,5.161,4.018,2.034,34629
"PIZZA HUT 12"" Pepperoni Pizza, Thick Crust",Fast Foods,11.97,0.146,0.692,1.8,0,0.199,30.49,3.2,14.21,40.8,298,5.271,4.415,3.465,34630
"PIZZA HUT 12"" Super Supreme Pizza, Regular Crust",Fast Foods,10.9,0.129,0.689,2,0,0.233,25.62,3.71,10.72,50.2,243,4.554,3.953,1.729,34631
"PIZZA HUT 14"" Cheese Pizza, Regular Crust",Fast Foods,11.75,0.189,0.632,1.1,0,0.157,31.63,3.16,10.87,43.19,271,4.421,2.576,1.796,34632
"PIZZA HUT 14"" Cheese Pizza, THIN 'N CRISPY Crust",Fast Foods,15.22,0.263,0.783,1.1,0.0004,0.178,29.03,2.52,13.25,39.25,296,6.394,3.605,1.71,34633
"PIZZA HUT 14"" Cheese Pizza, Thick Crust",Fast Foods,12,0.187,0.561,0.9,0,0.147,29.96,2.6,12.42,43.25,280,4.99,3.108,2.819,34634
"PIZZA HUT 14"" Pepperoni Pizza, Regular Crust",Fast Foods,12.17,0.139,0.744,2.6,0,0.184,32.48,3.2,12.02,40.53,287,4.726,3.649,2.074,34635
"PIZZA HUT 14"" Pepperoni Pizza, Thick Crust",Fast Foods,12.24,0.14,0.668,1.5,0,0.163,29.87,2.42,14.38,40.98,298,4.926,4.068,3.329,34636
"PIZZA HUT 14"" Super Supreme Pizza, Regular Crust",Fast Foods,11.34,0.104,0.658,2.3,0.0006,0.208,26.01,3.04,10.95,49.15,248,4.217,3.805,1.875,34637
"POPEYES, Batter Fries, analyzed prior to December 2006",Fast Foods,4.03,0.024,0.668,2.8,0.0011,0.436,35.06,0,16.2,41.95,302,6.175,8.385,1.147,34638
"POPEYES, Biscuit",Fast Foods,5.84,0.109,0.759,1.7,0,0.142,40.84,2.95,24.53,25.76,408,11.991,5.956,4.862,34639
"POPEYES, Coleslaw",Fast Foods,0.98,0.031,0.218,1.7,0.0147,0.118,14.12,10.83,11.18,72.84,161,1.887,3.339,5.852,34640
"POPEYES, Fried Chicken, Mild, Breast, meat and skin with breading",Fast Foods,22.14,0.023,0.53,0,0,0.288,9.17,0,15.54,50.61,265,5.438,6.862,1.732,34641
"POPEYES, Fried Chicken, Mild, Breast, meat only, skin and breading removed",Fast Foods,27.72,0.022,0.414,0,0,0.349,1.34,0,4.37,64.22,156,1.51,1.832,0.529,34642
"POPEYES, Fried Chicken, Mild, Drumstick, meat and skin with breading",Fast Foods,20.44,0.02,0.588,0,0,0.261,9.84,0,19.14,48.14,293,6.591,8.488,2.226,34643
"POPEYES, Fried Chicken, Mild, Drumstick, meat only, skin and breading removed",Fast Foods,26.51,0.016,0.478,0,0,0.321,0.04,0,7.14,64.18,170,2.282,3.079,1.011,34644
"POPEYES, Fried Chicken, Mild, Skin and Breading",Fast Foods,12.22,0.025,0.738,1.1,0,0.18,23.1,0,35.39,26.43,460,12.421,15.806,3.87,34645
"POPEYES, Fried Chicken, Mild, Thigh, meat and skin with breading",Fast Foods,18.6,0.02,0.595,0,0,0.256,10.83,0,21.75,46.33,313,7.426,9.723,2.58,34646
"POPEYES, Fried Chicken, Mild, Thigh, meat only, skin and breading removed",Fast Foods,23.97,0.015,0.474,0,0,0.32,0.5,0,10.27,63.07,190,3.223,4.603,1.495,34647
"POPEYES, Fried Chicken, Mild, Wing, meat and skin with breading",Fast Foods,19.95,0.027,0.689,0,0,0.232,14.08,0,24.08,39.02,353,8.415,10.717,2.723,34648
"POPEYES, Fried Chicken, Mild, Wing, meat only, skin and breading removed",Fast Foods,28.92,0.031,0.633,0,0,0.293,3.62,0,10.97,53.62,229,3.768,4.815,1.394,34649
"POPEYES, Mild Chicken Strips, analyzed 2006",Fast Foods,19.2,0.012,0.922,0.8,0,0.269,19.31,0,13.01,45.18,271,5.442,6.201,1.257,34650
"POPEYES, Spicy Chicken Strips, analyzed 2006",Fast Foods,19.61,0.012,0.997,1.2,0,0.289,18.52,0,11.2,47.11,253,4.743,5.321,1.112,34651
"Pizza, cheese topping, regular crust, frozen, cooked",Fast Foods,10.36,0.179,0.447,2.2,0.0012,0.152,29.02,3.57,12.28,46.28,268,4.272,4.246,1.903,34652
"Pizza, cheese topping, rising crust, frozen, cooked",Fast Foods,12.37,0.177,0.556,2.5,0.0019,0.175,32.91,5.09,8.78,43.46,260,3.825,2.198,1.381,34653
"Pizza, meat and vegetable topping, regular crust, frozen, cooked",Fast Foods,11.28,0.152,0.555,2.2,0.0034,0.209,25.14,4.85,14.43,46.71,276,5.083,5.89,2.578,34654
"Pizza, meat and vegetable topping, rising crust, frozen, cooked",Fast Foods,12.63,0.155,0.64,2.3,0.004200000000000001,0.189,28.78,7.26,11.75,44.24,271,4.586,4.343,1.89,34655
"Pizza, pepperoni topping, regular crust, frozen, cooked",Fast Foods,11.21,0.151,0.618,2.2,0.0019,0.198,28.51,0,15.2,42.44,296,4.792,6.865,2.356,34656
"Sandwiches and burgers, roast beef sandwich with cheese",Fast Foods,18.31,0.104,0.928,0,0,0.196,25.78,0,10.23,43.5,269,5.13,2.08,1.99,34657
"Sandwiches and burgers, steak sandwich",Fast Foods,14.87,0.045,0.391,0,0.0027,0.257,25.47,0,6.9,51.07,225,1.87,2.62,1.64,34658
"Side dishes, potato salad",Fast Foods,1.53,0.014,0.328,0,0.0011,0.27,13.53,0,6.03,78.73,114,1.03,1.69,3.02,34659
"TACO BELL, BURRITO SUPREME with beef",Fast Foods,8.05,0.093,0.574,3.2,0,0.245,21.07,0,8.05,60.6,189,3.047,3.277,0.817,34660
"TACO BELL, BURRITO SUPREME with chicken",Fast Foods,9.84,0.094,0.564,2.4,0,0.264,20.51,0,6.42,61,179,2.352,2.56,0.795,34661
"TACO BELL, BURRITO SUPREME with steak",Fast Foods,9.13,0.101,0.534,2.4,0,0.228,20.32,0,7.27,61.21,183,2.674,2.822,0.77,34662
"TACO BELL, Bean Burrito",Fast Foods,7.9,0.117,0.614,3.9,0,0.269,27.77,0,6.85,55.06,204,2.444,2.992,0.882,34663
"TACO BELL, Nachos",Fast Foods,5.15,0.089,0.514,3.8,0,0.182,36.38,0,22.17,34.29,366,4.699,12.715,2.58,34664
"TACO BELL, Nachos Supreme",Fast Foods,7.59,0.073,0.43,4.1,0,0.242,23.09,0,13.65,53.78,246,3.984,7.022,1.432,34665
"TACO BELL, Original Taco with beef",Fast Foods,10.79,0.079,0.447,3.6,0,0.216,17.95,0,13.48,55.91,236,4.659,5.445,2.097,34666
"TACO BELL, Soft Taco with beef",Fast Foods,11.8,0.116,0.632,2.6,0,0.181,19.74,0,10.35,55.72,219,4.199,4.36,1.012,34667
"TACO BELL, Soft Taco with chicken",Fast Foods,14.33,0.105,0.606,1.8,0,0.237,19.58,0,7.32,56.42,202,2.614,2.832,1.055,34668
"TACO BELL, Soft Taco with steak",Fast Foods,11.81,0.117,0.551,1.6,0,0.183,17.22,0,12.1,56.82,225,3.369,3.937,3.485,34669
"TACO BELL, Taco Salad",Fast Foods,6.69,0.095,0.363,3,0,0.229,15.1,0,9.17,67.32,170,2.979,3.972,0.752,34670
Tostada with guacamole,Fast Foods,4.78,0.162,0.306,0,0.0014,0.249,12.27,0,8.91,72.51,138,3.78,3.25,1.17,34671
"WENDY'S, CLASSIC DOUBLE, with cheese",Fast Foods,16.52,0.058,0.422,1.1,0.0007,0.214,11.71,0,14.2,55.84,241,5.89,5.74,1.3,34672
"WENDY'S, CLASSIC SINGLE Hamburger, no cheese",Fast Foods,12.63,0.034,0.395,1.3,0.0005,0.195,16.82,0,10.6,58.41,213,3.689,4.077,1.55,34673
"WENDY'S, CLASSIC SINGLE Hamburger, with cheese",Fast Foods,14.87,0.075,0.476,1.4,0.0005,0.187,14.2,0,11.6,57.5,221,5.191,4.407,1.405,34674
"WENDY'S, Chicken Nuggets",Fast Foods,15.93,0.024,0.679,1,0.0013,0.236,15.35,0,23.17,43.13,334,4.939,11.365,5.764,34675
"WENDY'S, French Fries",Fast Foods,3.89,0.015,0.172,3.7,0.0050999999999999995,0.575,39.44,0,16.23,38.81,319,3.201,8.518,3.728,34676
"WENDY'S, Frosty Dairy Dessert",Fast Foods,3.49,0.128,0.098,3.3,0,0.185,23.62,0,2.6,69.25,132,1.635,0.692,0.117,34677
"WENDY'S, Homestyle Chicken Fillet Sandwich",Fast Foods,13.78,0.023,0.401,1.3,0.0003,0.228,21.55,0,8.1,54.94,214,1.618,2.921,3.086,34678
"WENDY'S, Jr. Hamburger, with cheese",Fast Foods,13.06,0.092,0.66,1.4,0.0006,0.178,24.97,0,11.5,48.06,256,5.181,4.398,1.181,34679
"WENDY'S, Jr. Hamburger, without cheese",Fast Foods,12.62,0.045,0.539,1.7,0.0005,0.175,28.45,0,8.75,48.25,243,3.466,3.531,1.116,34680
"WENDY'S, Ultimate Chicken Grill Sandwich",Fast Foods,14.73,0.025,0.427,1.1,0.0011,0.221,18.88,0,5,59.67,179,1.03,1.46,1.81,34681
"Yogurt parfait, lowfat, with fruit and granola",Fast Foods,3.36,0.105,0.049,1.1,0.0143,0.189,15.86,11.68,1.01,78.98,84,0.525,0.307,0.122,34682
"Beef Pot Pie, frozen entree, prepared","Meals, Entrees, and Sidedishes",7.25,0.014,0.365,0.8,0.0003,0.115,22.05,0,11.39,58.05,220,4.149,5.312,1.462,34683
"Beef stew, canned entree","Meals, Entrees, and Sidedishes",4.41,0.012,0.388,0.9,0.0007,0.163,7.85,1.76,5.53,80.78,99,2.185,2.543,0.251,34684
"Beef, corned beef hash, with potato, canned","Meals, Entrees, and Sidedishes",8.73,0.019,0.412,1.1,0.0009,0.172,9.27,0.34,10.24,70.32,164,4.32,5.26,0.3,34685
"Burrito, bean and cheese, frozen","Meals, Entrees, and Sidedishes",6.92,0.042,0.412,10.2,0,0.217,32.4,1.41,5.94,53.05,211,1.573,2.262,1.443,34686
"Burrito, bean and cheese, microwaved","Meals, Entrees, and Sidedishes",8.6,0.068,0.637,10.4,0,0.273,40.37,1.37,7.17,41.77,260,1.933,2.788,1.856,34687
"Burrito, beef and bean, frozen","Meals, Entrees, and Sidedishes",7.26,0.034,0.587,4.2,0.0008,0.221,30.84,3.53,9.61,50.48,239,2.949,3.789,1.358,34688
"Burrito, beef and bean, microwaved","Meals, Entrees, and Sidedishes",8.73,0.039,0.659,6.9,0.001,0.271,38.95,1.36,11.94,38.14,298,4.072,5.035,1.763,34689
"CAMPBELL Soup Company, SPAGHETTIOS, Mini Beef Ravioli in Meat Sauce","Meals, Entrees, and Sidedishes",4.25,0.015,0.409,1.9,0.0005,0,16.6,5.41,1.74,75.6,99,0.772,0,0,34690
"CAMPBELL Soup Company, SPAGHETTIOS, Spaghetti in Tomato & Cheese Sauce","Meals, Entrees, and Sidedishes",2.78,0.008,0.377,1.2,0,0,15.87,5.16,0.6,79.7,80,0.198,0,0,34691
"CAMPBELL Soup Company, SPAGHETTIOS, SpaghettiO's with Meatballs","Meals, Entrees, and Sidedishes",4.37,0.06,0.238,1.6,0.0024,0.282,12.7,3.17,2.78,78.1,95,0.992,1.19,0.397,34692
"CAMPBELL Soup Company, SPAGHETTIOS, SpaghettiO's with Sliced Franks","Meals, Entrees, and Sidedishes",3.57,0.06,0.238,1.6,0.0024,0.333,12.7,3.57,2.38,79,87,0.992,0.992,0.397,34693
"CAMPBELL Soup Company, SPAGHETTIOS, SpaghettiOs A to Z's","Meals, Entrees, and Sidedishes",2.38,0.06,0.238,1.2,0.001,0.179,13.89,4.76,0.4,81.4,67,0.198,0,0.198,34694
"CAMPBELL Soup Company, SPAGHETTIOS, SpaghettiOs with Meatballs - Easy Open","Meals, Entrees, and Sidedishes",4.37,0.049,0.238,1.5,0.0029,0.248,11.65,3.4,2.43,78.1,87,0.971,1.214,0.243,34695
"CAMPBELL Soup Company, SPAGHETTIOS, SpaghettiOs A to Z's with Meatballs","Meals, Entrees, and Sidedishes",4.37,0.06,0.238,1.6,0.0024,0.282,12.7,3.17,2.78,78.1,95,0.992,1.19,0.397,34696
"CAMPBELL Soup Company, SPAGHETTIOS, SpaghettiOs Original","Meals, Entrees, and Sidedishes",2.38,0.008,0.238,1.2,0.001,0.179,13.89,4.37,0.4,81.4,67,0.198,0,0.198,34697
"CAMPBELL Soup Company, SPAGHETTIOS, SpaghettiOs Original, easy open","Meals, Entrees, and Sidedishes",2.35,0.009,0.225,1.4,0,0.178,14.55,4.23,0.47,81.4,70,0.235,0,0.235,34698
"CAMPBELL Soup Company, SPAGHETTIOS, SpaghettiOs RavioliOs Beef Ravioli in Meat Sauce","Meals, Entrees, and Sidedishes",4.37,0.016,0.433,1.6,0,0,15.08,3.57,3.17,76.1,106,1.389,0,0,34699
"CAMPBELL Soup Company, SPAGHETTIOS, SpaghettiOs in Meat Sauce","Meals, Entrees, and Sidedishes",3.17,0.06,0.353,1.2,0,0,12.3,4.76,0.79,82.4,69,0.397,0,0,34700
"CAMPBELL Soup Company, SPAGHETTIOS, SpaghettiOs plus Calcium","Meals, Entrees, and Sidedishes",2.38,0.119,0.238,1.2,0.001,0.119,13.89,5.16,0.4,81.7,67,0.198,0,0.198,34701
"CAMPBELL Soup Company, SUPPER BAKES MEAL KITS, Cheesy Chicken with pasta (chicken not included)","Meals, Entrees, and Sidedishes",7.06,0,0.988,1.2,0,0,31.76,4.71,4.71,51.3,198,1.176,0,0,34702
"CAMPBELL Soup Company, SUPPER BAKES MEAL KITS, Creamy Stroganoff Sauce with pasta","Meals, Entrees, and Sidedishes",4.8,0,0.52,0.8,0,0,24.8,2.4,3.2,51.5,147,1.2,0,0,34703
"CAMPBELL Soup Company, SUPPER BAKES MEAL KITS, Garlic Chicken with pasta (chicken not included)","Meals, Entrees, and Sidedishes",9.76,0,0.741,2,0,0,42.93,6.83,0.98,42,220,0.488,0,0,34704
"CAMPBELL Soup Company, SUPPER BAKES MEAL KITS, Herb Chicken with rice (chicken not included)","Meals, Entrees, and Sidedishes",4.26,0,0.83,1.1,0,0,42.55,2.13,1.06,48.8,197,0.532,0,0,34705
"CAMPBELL Soup Company, SUPPER BAKES MEAL KITS, Lemon Chicken with herb rice (chicken not included)","Meals, Entrees, and Sidedishes",4.26,0,0.83,2.1,0,0,45.74,3.19,1.06,46,210,0.532,0,0,34706
"CAMPBELL Soup Company, SUPPER BAKES MEAL KITS, Savory Pork Chops with herb stuffing (pork chop not included)","Meals, Entrees, and Sidedishes",5.91,0,0.922,2.4,0,0,36.64,4.73,1.18,52.3,181,0.591,0,0,34707
"CAMPBELL Soup Company, SUPPER BAKES MEAL KITS, Southwestern-Style Chicken w/rice (chicken not included)","Meals, Entrees, and Sidedishes",4.94,0,0.741,2.5,0,0,39.51,4.94,1.23,53.2,189,0.617,0,0,34708
"CAMPBELL Soup Company, SUPPER BAKES MEAL KITS, Traditional Roast Chicken with stuffing (chicken not included)","Meals, Entrees, and Sidedishes",5.94,0,0.879,2.4,0,0,34.44,3.56,3.56,53.5,190,1.188,0,0,34709
"CAMPBELL Soup Company, SWANSON, Chicken A La King","Meals, Entrees, and Sidedishes",4.7,0,0.46,0.7,0,0,4.03,0.67,4.03,80.1,71,1.174,0,0,34710
"CAMPBELL Soup Company, SWANSON, Chicken and Dumplings","Meals, Entrees, and Sidedishes",4.45,0.016,0.401,0.8,0,0,9.72,0.81,4.05,80.8,93,1.822,0,0,34711
"Chicken pot pie, frozen entree, prepared","Meals, Entrees, and Sidedishes",6.08,0.029,0.38,1.2,0.0003,0.124,23.03,3.81,10.83,58.68,214,3.613,3.7,1.387,34712
"Chili con carne with beans, canned entree","Meals, Entrees, and Sidedishes",7.09,0.038,0.424,3.9,0.0014,0.274,11.43,0,5.26,74.34,121,1.74,2.144,0.427,34713
"Chili, no beans, canned entree","Meals, Entrees, and Sidedishes",7.53,0.03,0.389,0,0.0018,0.185,6.1,0,7.1,77.68,118,2.255,2.469,0.467,34714
"Egg rolls, chicken, refrigerated, heated","Meals, Entrees, and Sidedishes",10.44,0.047,0.56,2.4,0.0112,0.281,28.54,5.57,4.51,54.18,197,0.964,1.329,1.813,34715
"Egg rolls, pork, refrigerated, heated","Meals, Entrees, and Sidedishes",9.94,0.034,0.525,2.1,0.0293,0.212,29.5,5.32,7.17,51.33,222,1.588,2.35,2.464,34716
"Egg rolls, vegetable, refrigerated, heated","Meals, Entrees, and Sidedishes",6.62,0.046,0.561,2.8,0.013699999999999999,0.25,31.99,6.85,4.67,54.46,196,0.756,1.271,2.191,34717
"HOT POCKETS Ham 'N Cheese Stuffed Sandwich, frozen","Meals, Entrees, and Sidedishes",9.16,0.152,0.504,1.5,0,0.188,30.55,7.6,9.14,48.96,241,3.545,2.547,1.936,34718
"HOT POCKETS, CROISSANT POCKETS Chicken, Broccoli, and Cheddar Stuffed Sandwich, frozen","Meals, Entrees, and Sidedishes",8.9,0,0.346,1.1,0.004900000000000001,0,30.4,0,8.6,50,235,2.62,3.42,1.3,34719
"HOT POCKETS, meatballs & mozzarella stuffed sandwich, frozen","Meals, Entrees, and Sidedishes",9.32,0.181,0.619,2.2,0.0006,0.204,30.45,7.73,10.47,47.21,253,4.007,2.787,2.611,34720
"LEAN POCKETS, Ham N Cheddar","Meals, Entrees, and Sidedishes",10.28,0.196,0.494,1.7,0,0.203,32.52,0,6.53,48.48,230,2.389,1.551,1.403,34721
"Lasagna with meat & sauce, frozen entree","Meals, Entrees, and Sidedishes",8.26,0.102,0.28,1.2,0.0215,0.158,12.87,2.13,4.73,72.72,127,2.245,1.781,0.336,34722
"Lasagna with meat & sauce, low-fat, frozen entree","Meals, Entrees, and Sidedishes",6.81,0.108,0.181,1.3,0.0179,0.15,13.5,0,2.23,76.36,101,0.967,0.698,0.279,34723
"Lasagna, Cheese, frozen, prepared","Meals, Entrees, and Sidedishes",6.54,0.111,0.284,1.7,0.0171,0.182,13.84,4.23,5.33,72.78,130,2.11,1.573,1.022,34724
"Lasagna, Vegetable, frozen, baked","Meals, Entrees, and Sidedishes",6.87,0.15,0.352,1.9,0.0011,0.17,14.18,2.54,6.04,71.24,139,2.422,1.455,1.13,34725
"Lean Pockets, Meatballs & Mozzarella","Meals, Entrees, and Sidedishes",10.49,0.196,0.557,2,0,0.221,32.28,8.81,7.7,46.94,240,3.087,1.971,1.479,34726
"Macaroni and Cheese, canned entree","Meals, Entrees, and Sidedishes",3.38,0.035,0.302,0.5,0,0.084,11.52,0.5,2.46,81.39,82,0.881,0.6,0.303,34727
"Macaroni and Cheese, canned, microwaveable","Meals, Entrees, and Sidedishes",5.98,0.057,0.335,0.4,0,0.072,13.96,1.41,5.99,72.94,134,3.398,1.644,0.4,34728
"Macaroni and cheese dinner with dry sauce mix, boxed, prepared with 2% milk and 60% vegetable oil spread","Meals, Entrees, and Sidedishes",6.07,0.072,0.336,1.3,0,0.126,24.31,5.47,5.77,62.53,173,1.176,2.339,1.545,34729
"Macaroni and cheese dinner with dry sauce mix, boxed, uncooked","Meals, Entrees, and Sidedishes",14.97,0.114,0.761,3.2,0.0007,0.394,69.21,9.61,3.42,8.92,368,1.151,0.665,0.813,34730
"Pasta with Sliced Franks in Tomato Sauce, canned entree","Meals, Entrees, and Sidedishes",4.37,0.06,0.238,1.6,0.0038,0.191,12.7,3.17,2.38,78.2,90,0.992,0.992,0.397,34731
"Pasta with meatballs in tomato sauce, canned entree","Meals, Entrees, and Sidedishes",4.17,0.012,0.291,2.7,0.003,0.141,11.11,2.83,5.1,78.24,107,1.98,2.216,0.36,34732
"Pasta with tomato sauce, no meat, canned","Meals, Entrees, and Sidedishes",2.54,0.031,0.25,1.3,0.0046,0.114,14.56,4.91,0.77,80.94,75,0.232,0.18,0.272,34733
"Rice bowl with chicken, frozen entree, prepared (includes fried, teriyaki, and sweet and sour varieties)","Meals, Entrees, and Sidedishes",5.65,0.013,0.333,0.7,0.003,0.123,22.46,4.07,1.56,69.09,126,0.327,0.503,0.415,34734
"Spaghetti with meat sauce, frozen entree","Meals, Entrees, and Sidedishes",5.05,0.018,0.212,1.8,0.0054,0.144,15.24,2.6,1.01,77.82,90,0.35,0.33,0.32,34735
"Spaghetti, with meatballs, canned","Meals, Entrees, and Sidedishes",4.17,0.012,0.406,0,0,0.141,11.11,0,5.1,78.24,107,1.98,2.216,0.36,34736
"Tortellini, pasta with cheese filling, fresh-refrigerated, as purchased","Meals, Entrees, and Sidedishes",13.5,0.152,0.616,1.9,0,0.089,47,0.95,7.23,30.5,307,3.6,2.066,0.461,34737
"Turkey Pot Pie, frozen entree","Meals, Entrees, and Sidedishes",6.5,0,0.35,1.1,0,0,17.7,0,8.8,65.7,176,2.88,3.46,1.38,34738
"Turkey, stuffing, mashed potatoes w/gravy, assorted vegetables, frozen, microwaved","Meals, Entrees, and Sidedishes",6.97,0.027,0.42,1.3,0.0008,0.23,16.32,4.82,3.89,71.16,128,0.877,1.235,1.087,34739
"Breakfast bar, corn flake crust with fruit",Snacks,4.4,0.041,0.167,2.1,0,0.197,72.9,35.1,7.5,14.5,377,1.5,5,0.9,34740
"Breakfast bars, oats, sugar, raisins, coconut (include granola bar)",Snacks,9.8,0.06,0.251,3.1,0.001,0.326,66.7,27.52,17.6,4.1,464,12.69,1.933,1.634,34741
"Cheese puffs and twists, corn based, baked, low fat",Snacks,8.5,0.357,0.847,10.7,0.0214,0.286,72.35,7.29,12.1,3,432,2.1,3.5,5.74,34742
"Formulated bar, LUNA BAR, NUTZ OVER CHOCOLATE",Snacks,20.75,0.886,0.386,4.3,0.068,0.412,52.49,16.9,12.19,9.38,403,5.523,2.933,2.714,34743
"Formulated bar, MARS SNACKFOOD US, COCOAVIA, Chocolate Almond Snack Bar",Snacks,7.72,1.209,0.26,5.2,0.0425,0,51.68,27.03,14.19,22.9,347,4.326,0,0,34744
"Formulated bar, MARS SNACKFOOD US, COCOAVIA, Chocolate Blueberry Snack Bar",Snacks,6.21,1.207,0.26,4.6,0.0424,0,57.87,33.4,9.27,20.1,325,3.87,0,0,34745
"Formulated bar, MARS SNACKFOOD US, SNICKERS MARATHON MULTIGRAIN CRUNCH BAR",Snacks,18.49,0.953,0.418,2.8,0.1443,0,57.27,33.04,13.18,7.99,422,4.794,0,0,34746
"Formulated bar, MARS SNACKFOOD US, SNICKERS MARATHON Protein Performance Bar, Caramel Nut Rush",Snacks,25,0.625,0.238,12.5,0.075,0.188,50.5,28.75,12.5,9,415,6.25,2.645,2.892,34747
"Formulated bar, MARS SNACKFOOD US, SNICKERS Marathon Chewy Chocolate Peanut Bar",Snacks,24.29,0.965,0.462,2.5,0.1444,0,47.24,32.86,13.12,12.9,396,4.728,0,0,34748
"Formulated bar, MARS SNACKFOOD US, SNICKERS Marathon Double Chocolate Nut Bar",Snacks,22.35,1.495,0.333,10.5,0.1762,0,52.47,22.67,8.99,14,343,4.847,0,0,34749
"Formulated bar, MARS SNACKFOOD US, SNICKERS Marathon Energy Bar, all flavors",Snacks,21.91,0.754,0.383,6.7,0.4899,0.351,50.3,28.71,10.79,14,386,4.753,3.92,1.917,34750
"Formulated bar, MARS SNACKFOOD US, SNICKERS Marathon Honey Nut Oat Bar",Snacks,22.5,0,0.318,11,0.1764,0,54.3,26.26,7.87,9.5,378,4.481,0,0,34751
"Formulated bar, POWER BAR, chocolate",Snacks,14.15,0.504,0.146,5.7,0.0923,0.362,69.63,30.07,3.11,9.83,363,1.32,1.05,0.589,34752
"Formulated bar, SLIM-FAST OPTIMA meal bar, milk chocolate peanut",Snacks,16.19,0.668,0.253,5.1,0.09659999999999999,0.289,60.21,25,8.92,10.87,386,5.36,2.428,1.132,34753
"Formulated bar, ZONE PERFECT CLASSIC CRUNCH BAR, mixed flavors",Snacks,30,0.2,0.45,2,0.12,0.25,45,27,14,7,422,4.499,3.828,4.804,34754
"Formulated bar, high fiber, chewy, oats and chocolate",Snacks,5,0.25,0.225,22.5,0.0012,0.447,69.78,25,10,12.04,350,3.397,3.522,2.334,34755
"Granola bar, soft, milk chocolate coated, peanut butter",Snacks,9.6,0.108,0.193,3.8,0.0006,0.339,54.1,23.63,31.2,3.2,536,17.065,6.561,1.906,34756
"Popcorn, microwave, low fat and sodium",Snacks,12.6,0.011,0.49,14.2,0,0.241,73.39,0.54,9.5,2.8,429,1.415,4.085,3.572,34757
"Popcorn, microwave, regular (butter) flavor, made with palm oil",Snacks,8.69,0.02,0.771,9.9,0,0.219,55.69,0.54,30,2.62,528,6.305,14.21,6.062,34758
"Popcorn, sugar syrup/caramel, fat-free",Snacks,2,0.018,0.286,2.5,0,0.11,90.06,64.68,1.4,6,381,0.2,0.26,0.634,34759
"Potato chips, without salt, reduced fat",Snacks,7.1,0.021,0.008,6.1,0.0257,1.744,67.8,0.22,20.8,1,487,4.16,4.8,10.94,34760
"Pretzels, soft",Snacks,8.2,0.023,0.805,1.7,0,0.088,69.39,0.25,3.1,15,338,0.695,1.071,0.948,34761
"Pretzels, soft, unsalted",Snacks,8.2,0.023,0.69,1.7,0,0.088,71.04,0.25,3.1,15,338,0.695,1.071,0.948,34762
"Rice cake, cracker (include hain mini rice cakes)",Snacks,7.1,0.011,0.071,4.2,0,0.428,81.1,0.88,4.3,5.8,392,0.875,1.582,1.52,34763
"Snack, potato chips, made from dried potatoes, plain",Snacks,4.45,0.027,0.388,3.1,0.008199999999999999,0.751,52.02,1.14,38.41,2.54,559,9.492,7.048,13.594,34764
"Snacks, DOO DADS snack mix, original flavor",Snacks,10.3,0.074,1.271,6.8,0.0001,0.277,64.3,0,18.5,3,456,3.53,0,0,34765
"Snacks, FARLEY CANDY, FARLEY Fruit Snacks, with vitamins A, C, and E",Snacks,4.4,0,0.036,0,0.09029999999999999,0,80.9,0,0,14.7,341,0,0,0,34766
"Snacks, FRITOLAY, SUNCHIPS, Multigrain Snack, Harvest Cheddar flavor",Snacks,8.08,0.041,0.539,8.1,0.0009,0.238,64.7,7.05,22.22,2.5,491,2.892,12.557,6.631,34767
"Snacks, FRITOLAY, SUNCHIPS, Multigrain Snack, original flavor",Snacks,7.95,0.02,0.327,6.8,0,0.232,67.26,7.2,21.11,2.04,491,2.166,11.501,6.513,34768
"Snacks, FRITOLAY, SUNCHIPS, multigrain, French onion flavor",Snacks,8.68,0.042,0.467,7.8,0.0009,0.257,65.49,8.82,22.15,2.04,496,2.469,12.698,6.513,34769
"Snacks, GENERAL MILLS, BETTY CROCKER Fruit Roll Ups, berry flavored, with vitamin C",Snacks,0.1,0,0.317,0,0.12,0,85.2,38.7,3.5,10.2,373,0.99,1.724,0.093,34770
"Snacks, KELLOGG'S, NUTRI-GRAIN Cereal Bars, fruit",Snacks,5.41,0.541,0.283,3.6,0.0078,0.25,70.33,32.43,8.11,14.43,376,1.215,1,3.62,34771
"Snacks, KELLOGG, KELLOGG'S Low Fat Granola Bar, Crunchy Almond/Brown Sugar",Snacks,8,0.035,0.291,6.2,0,0.249,78,0,7.4,5,390,1.1,1.8,4.5,34772
"Snacks, KELLOGG, KELLOGG'S RICE KRISPIES TREATS Squares",Snacks,3.4,0.003,0.351,0.6,0,0.039,80.5,0,9,5.9,414,1.4,2.5,5.1,34773
"Snacks, KRAFT, CORNNUTS, plain",Snacks,8.5,0.009,0.635,6.9,0,0.278,71.86,0.61,15.64,1.3,446,2.43,9.46,3.05,34774
"Snacks, M&M MARS, COMBOS Snacks Cheddar Cheese Pretzel",Snacks,9.34,0.18,1.553,0,0.0003,0.13,67.37,0,16.62,1.67,462,9.975,0,0,34775
"Snacks, M&M MARS, KUDOS Whole Grain Bar, M&M's milk chocolate",Snacks,3.78,0.943,0.341,2.4,0.0002,0,73.01,37.81,11.95,8,415,6.802,0,0,34776
"Snacks, M&M MARS, KUDOS Whole Grain Bar, chocolate chip",Snacks,4.47,1.084,0.246,2.6,0.0001,0.279,72.31,38.78,13.02,4.9,420,4.701,4.71,2.749,34777
"Snacks, M&M MARS, KUDOS Whole Grain Bars, peanut butter",Snacks,5.88,0.871,0.268,2.6,0.0003,0,64.69,44.29,20.78,7,463,10.411,0,0,34778
"Snacks, NUTRI-GRAIN FRUIT AND NUT BAR",Snacks,9.38,0.065,0.195,7.5,0.0002,0.328,66.72,32.45,10.93,11.26,403,1.93,5.705,2.581,34779
"Snacks, RALSTON PURINA, CHEX MIX",Snacks,8.83,0.034,1.203,3.9,0,0.196,74.77,4.85,10,2.64,424,1.499,2.921,4.473,34780
"Snacks, SUNKIST, SUNKIST Fruit Roll, strawberry, with vitamins A, C, and E",Snacks,0.6,0,0.111,7.7,0.08159999999999999,0,82.7,0,1,14.6,342,0,0,0,34781
"Snacks, bagel chips",Snacks,12.34,0.024,0.233,4.1,0,0.14,66.36,6.02,15.14,5.05,451,6.835,6.05,2.085,34782
"Snacks, banana chips",Snacks,2.3,0.018,0.006,7.7,0.0063,0.536,58.4,35.34,33.6,4.3,519,28.97,1.95,0.63,34783
"Snacks, beef jerky, chopped and formed",Snacks,33.2,0.02,2.081,1.8,0,0.597,11,9,25.6,23.36,410,10.85,11.305,1.011,34784
"Snacks, beef sticks, smoked",Snacks,21.5,0.068,1.531,0,0.0068,0.257,5.4,0,49.6,19.1,550,20.8,20.47,4.42,34785
"Snacks, candy bits, yogurt covered with vitamin C",Snacks,0,0.5,0.075,0.2,0.18,0.338,86.9,65,7.5,3.73,415,1.194,0.845,3.341,34786
"Snacks, candy rolls, yogurt-covered, fruit flavored with high vitamin C",Snacks,0.46,0.43,0.008,3.3,0.26080000000000003,0.1,74.64,39.15,6.53,17.59,359,5.096,0.753,0.238,34787
"Snacks, corn cakes",Snacks,8.1,0.019,0.488,1.9,0,0.157,83.4,23.47,2.4,4.6,387,0.42,0.76,0.93,34788
"Snacks, corn cakes, very low sodium",Snacks,8.1,0.019,0.028,0,0,0.157,83.4,0,2.4,4.6,387,0.42,0.76,0.93,34789
"Snacks, corn-based, extruded, chips, barbecue-flavor",Snacks,7,0.131,0.763,5.2,0.0017,0.236,56.2,0,32.7,1.2,523,4.46,9.48,16.17,34790
"Snacks, corn-based, extruded, chips, barbecue-flavor, made with enriched masa flour",Snacks,7,0.131,0.763,0,0.0017,0.236,56.2,0,32.7,1.2,523,4.46,9.48,16.17,34791
"Snacks, corn-based, extruded, chips, plain",Snacks,6.03,0.164,0.545,5.3,0,0.135,63.01,1.16,28.41,0.33,518,3.612,7.913,14.01,34792
"Snacks, corn-based, extruded, chips, unsalted",Snacks,6.6,0.124,0.015,4.4,0.002,0.185,57.4,1.41,33.4,1,557,4.344,9.04,17.093,34793
"Snacks, corn-based, extruded, cones, nacho-flavor",Snacks,6.5,0.036,0.952,1,0.0001,0.123,57.3,0,31.7,1.9,536,26.79,2.14,0.84,34794
"Snacks, corn-based, extruded, cones, plain",Snacks,5.8,0.003,1.022,1.1,0,0.081,62.9,0,26.9,2,510,22.75,1.7,0.78,34795
"Snacks, corn-based, extruded, onion-flavor",Snacks,7.7,0.029,0.95,3.9,0.0018,0.143,65.1,4.83,22.6,2,499,4.34,13.36,3.14,34796
"Snacks, corn-based, extruded, puffs or twists, cheese-flavor",Snacks,5.83,0.058,0.91,1.9,0,0.189,52.38,2.87,37.11,1.78,565,5.658,10.513,19.089,34797
"Snacks, corn-based, extruded, puffs or twists, cheese-flavor, unenriched",Snacks,5.76,0.058,0.896,2.2,0,0.197,54.1,2.76,35.76,1.5,558,5.512,10.437,18.649,34798
"Snacks, cornnuts, barbecue-flavor",Snacks,9,0.017,0.6,8.4,0.0004,0.286,71.7,0,14.3,1.6,436,2.58,7.36,3.22,34799
"Snacks, cornnuts, nacho-flavor",Snacks,9.4,0.035,0.634,8,0.0155,0.311,71.6,0,14.2,2.1,438,2.56,7.31,3.2,34800
"Snacks, crisped rice bar, chocolate chip",Snacks,5.1,0.021,0.278,2.2,0,0.168,73,0,13.5,7,404,5.24,3.99,3.65,34801
"Snacks, granola bar, KASHI GOLEAN, chewy, mixed flavors",Snacks,16.67,0.103,0.321,7.7,0.0004,0.429,63.42,42.31,7.69,9.23,390,3.227,2.558,1.228,34802
"Snacks, granola bar, KASHI GOLEAN, crunchy, mixed flavors",Snacks,17.88,0.434,0.486,5.9,0.0193,0.3,59.58,27.3,9.23,9.43,393,4.687,2.498,1.268,34803
"Snacks, granola bar, KASHI TLC Bar, chewy, mixed flavors",Snacks,18.57,0,0.293,11.4,0,0.415,53.26,17.14,15.71,9.34,429,1.213,9.486,4.181,34804
"Snacks, granola bar, KASHI TLC Bar, crunchy, mixed flavors",Snacks,15,0,0.4,10,0,0.545,62.78,20,15,2.74,446,1.436,7.932,5.006,34805
"Snacks, granola bar, chewy, reduced sugar, all flavors",Snacks,5.55,0.417,0.045,3.1,0.0001,0.199,69.4,20.8,12.5,11.65,412,5.008,5.303,1.44,34806
"Snacks, granola bar, fruit-filled, nonfat",Snacks,5.9,0.003,0.016,7.4,0.0017,0.22,77.6,55.34,0.9,13.7,342,0.2,0.24,0.35,34807
"Snacks, granola bar, with coconut, chocolate coated",Snacks,5.2,0.042,0.152,6.2,0.0005,0.254,55.2,34.27,32.2,6.1,531,22.72,4.97,3.1,34808
"Snacks, granola bars, QUAKER OATMEAL TO GO, all flavors",Snacks,6.67,0.333,0.367,4.6,0.0009,0.238,75.47,31.54,6.67,9.86,389,1.299,1.531,2.559,34809
"Snacks, granola bars, hard, almond",Snacks,7.7,0.032,0.256,4.8,0,0.273,62,0,25.5,3.1,495,12.51,7.74,3.76,34810
"Snacks, granola bars, hard, chocolate chip",Snacks,7.3,0.077,0.344,4.4,0.0001,0.251,72.1,0,16.3,2.4,438,11.41,2.63,1.27,34811
"Snacks, granola bars, hard, peanut",Snacks,11,0.039,0.278,4.3,0,0.305,63.7,33.96,21.4,2.3,479,2.52,5.76,11.88,34812
"Snacks, granola bars, hard, peanut butter",Snacks,9.8,0.041,0.283,2.9,0.0002,0.291,62.3,0,23.8,2.3,483,3.2,7,12.08,34813
"Snacks, granola bars, hard, plain",Snacks,10.1,0.061,0.294,5.3,0.0009,0.336,64.4,0,19.8,3.9,471,2.37,4.38,12.05,34814
"Snacks, granola bars, oats, fruits and nut",Snacks,7.7,0.036,0.251,5.3,0,0.238,77.42,43.5,6.3,7.2,397,0.73,0.17,4.83,34815
"Snacks, granola bars, soft, almond, confectioners coating",Snacks,8.6,0.136,0.486,4.3,0.0002,0.32,60.13,34,20,8.74,455,5.668,9.491,3.643,34816
"Snacks, granola bars, soft, coated, milk chocolate coating, chocolate chip",Snacks,5.8,0.103,0.2,3.4,0,0.313,63.8,0,24.9,3.6,466,14.22,7.77,1.82,34817
"Snacks, granola bars, soft, coated, milk chocolate coating, peanut butter",Snacks,10.2,0.108,0.193,2.8,0.0006,0.339,53.4,0,31.1,3.2,509,17.01,6.54,1.9,34818
"Snacks, granola bars, soft, uncoated, chocolate chip",Snacks,5.65,0.041,0.179,3.8,0,0.237,70.2,28.92,16.57,6.45,418,6.061,6.967,1.576,34819
"Snacks, granola bars, soft, uncoated, chocolate chip, graham and marshmallow",Snacks,6.1,0.089,0.316,4,0,0.275,70.8,0,15.5,6,427,9.18,2.92,2.55,34820
"Snacks, granola bars, soft, uncoated, nut and raisin",Snacks,8,0.084,0.254,5.6,0,0.392,63.6,0,20.4,6.1,454,9.54,4.22,5.52,34821
"Snacks, granola bars, soft, uncoated, peanut butter",Snacks,10.5,0.091,0.409,4.3,0,0.291,64.4,0,15.8,7.3,426,3.65,6.59,4.27,34822
"Snacks, granola bars, soft, uncoated, peanut butter and chocolate chip",Snacks,9.8,0.08,0.328,4.2,0,0.377,62.2,0,20,5.9,432,5.59,8.36,4.61,34823
"Snacks, granola bars, soft, uncoated, plain",Snacks,7.4,0.105,0.278,4.6,0,0.325,67.3,0,17.2,6.4,443,7.24,3.81,5.32,34824
"Snacks, granola bars, soft, uncoated, raisin",Snacks,7.6,0.101,0.282,4.2,0,0.362,66.4,0,17.8,6.4,448,9.57,2.84,3.21,34825
"Snacks, granola bites, mixed flavors",Snacks,7.17,0.041,0.033,5.7,0.0002,0.285,66.27,30,17.5,7.88,451,9.888,3.194,2.546,34826
"Snacks, oriental mix, rice-based",Snacks,17.31,0.054,0.413,13.2,0.0003,0.328,51.62,3,25.58,2.55,506,3.785,9.86,10.643,34827
"Snacks, pita chips, salted",Snacks,11.79,0.017,0.854,3.8,0,0.129,68.26,4.76,15.2,2,457,1.342,10.842,2.13,34828
"Snacks, plantain chips, salted",Snacks,2.28,0.009,0.202,3.5,0.032100000000000004,0.786,63.84,0.92,29.59,2.09,531,8.337,5.632,11.739,34829
"Snacks, popcorn, air-popped",Snacks,12.94,0.007,0.008,14.5,0,0.329,77.9,0.87,4.54,3.32,387,0.57,1.1,1.9,34830
"Snacks, popcorn, air-popped, white popcorn",Snacks,12,0.01,0.004,15.1,0,0.301,77.9,0,4.2,4.1,382,0.57,1.1,1.9,34831
"Snacks, popcorn, cakes",Snacks,9.7,0.009,0.288,2.9,0,0.327,80.1,0.72,3.1,5,384,0.48,0.92,1.35,34832
"Snacks, popcorn, caramel-coated, with peanuts",Snacks,6.4,0.066,0.295,3.8,0,0.355,80.7,45.36,7.8,3.3,400,1.04,2.73,3.27,34833
"Snacks, popcorn, caramel-coated, without peanuts",Snacks,3.8,0.043,0.206,5.2,0,0.109,79.1,53.19,12.8,2.8,431,3.61,2.88,4.48,34834
"Snacks, popcorn, cheese-flavor",Snacks,9.3,0.113,0.889,9.9,0.0005,0.261,51.6,0,33.2,2.5,526,6.41,9.7,15.37,34835
"Snacks, popcorn, home-prepared, oil-popped, unsalted",Snacks,9,0.01,0.003,10,0.0003,0.225,58.1,0.54,28.1,2.8,500,5.05,8.29,13.52,34836
"Snacks, popcorn, microwave, 94% fat free",Snacks,10.72,0.014,0.627,13.6,0,0.284,76.04,0.65,6.1,4.22,402,1.184,2.767,2.216,34837
"Snacks, popcorn, microwave, low fat",Snacks,12.6,0.011,0.884,14.2,0,0.241,72,0.92,9.5,2.8,424,1.415,4.085,3.572,34838
"Snacks, popcorn, microwave, regular (butter) flavor, made with partially hydrogenated oil",Snacks,8.69,0.02,0.771,9.9,0,0.219,55.71,0.54,29.97,2.62,527,6.904,0,0,34839
"Snacks, popcorn, oil-popped, microwave, regular flavor",Snacks,7.29,0.004,1.058,8.1,0.0003,0.182,45.06,0.46,43.55,1.2,583,6.82,9.809,23.191,34840
"Snacks, popcorn, oil-popped, white popcorn",Snacks,9,0.01,0.884,10,0.0003,0.225,57.2,0,28.1,2.8,500,4.89,8.17,13.42,34841
"Snacks, popcorn, unpopped kernels",Snacks,10.87,0.005,0.007,12.7,0,0.274,73.12,0.91,4.31,10.57,375,0.64,0.992,2.476,34842
"Snacks, pork skins, barbecue-flavor",Snacks,57.9,0.043,2.667,0,0.0015,0.18,1.6,0,31.8,2.1,538,11.56,15.03,3.45,34843
"Snacks, pork skins, plain",Snacks,61.3,0.03,1.818,0,0.0005,0.127,0,0,31.3,1.8,544,11.37,14.78,3.64,34844
"Snacks, potato chips, barbecue-flavor",Snacks,7.7,0.05,0.75,4.4,0.0339,1.261,52.8,0,32.4,1.9,491,8.05,6.54,16.37,34845
"Snacks, potato chips, cheese-flavor",Snacks,8.5,0.072,0.793,5.2,0.0541,1.528,57.7,0,27.2,1.8,496,8.6,7.72,9.56,34846
"Snacks, potato chips, fat free, salted",Snacks,9.64,0.035,0.643,7.5,0.009300000000000001,1.628,83.76,3.69,0.6,2,379,0.15,0.015,0.255,34847
"Snacks, potato chips, fat-free, made with olestra",Snacks,7.74,0.035,0.554,6.8,0.028300000000000002,1.16,65,0,0.7,2,274,0.24,0.18,0.304,34848
"Snacks, potato chips, made from dried potatoes, cheese-flavor",Snacks,7,0.11,0.755,3.4,0.0085,0.381,50.6,0,37,1.9,551,9.57,7.14,18.66,34849
"Snacks, potato chips, made from dried potatoes, fat-free, made with olestra",Snacks,5.06,0.02,0.429,7.3,0.1088,0.931,56,0.59,0.93,2.63,253,0.355,0.3,0.19,34850
"Snacks, potato chips, made from dried potatoes, reduced fat",Snacks,4.56,0.029,0.411,3.2,0.012,0.76,64.9,0.63,26.14,2.22,502,6.759,4.553,12.201,34851
"Snacks, potato chips, made from dried potatoes, sour-cream and onion-flavor",Snacks,6.6,0.064,0.72,1.2,0.0095,0.496,51.3,0,37,2,547,9.47,7.12,18.78,34852
"Snacks, potato chips, plain, made with partially hydrogenated soybean oil, salted",Snacks,7,0.024,0.594,4.8,0.031100000000000003,1.275,52.9,0,34.6,1.9,536,5.43,18,9.16,34853
"Snacks, potato chips, plain, made with partially hydrogenated soybean oil, unsalted",Snacks,7,0.024,0.008,4.8,0.031100000000000003,1.275,52.9,0,34.6,1.9,536,5.43,18,9.16,34854
"Snacks, potato chips, plain, salted",Snacks,6.56,0.024,0.525,4.4,0.018600000000000002,1.642,50.81,0.37,36.4,2.28,542,4.014,15.999,15.998,34855
"Snacks, potato chips, plain, unsalted",Snacks,7,0.024,0.008,4.8,0.031100000000000003,1.275,52.9,0.22,34.6,1.9,536,10.96,9.84,12.17,34856
"Snacks, potato chips, reduced fat",Snacks,7.1,0.021,0.492,5.9,0.0257,1.744,66.9,0.22,20.8,1,471,4.16,4.8,10.94,34857
"Snacks, potato chips, sour-cream-and-onion-flavor",Snacks,8.1,0.072,0.625,5.2,0.0373,1.331,51.5,0,33.9,1.8,531,8.89,6.12,17.42,34858
"Snacks, potato chips, white, restructured, baked",Snacks,5,0.125,0.634,4.8,0,0.721,71.4,5.01,18.2,1.4,469,2.631,9.91,4.234,34859
"Snacks, potato sticks",Snacks,6.7,0.018,0.25,3.4,0.047299999999999995,1.237,53.3,0.22,34.4,2.2,522,8.88,6.16,17.9,34860
"Snacks, pretzels, hard, confectioner's coating, chocolate-flavor",Snacks,7.5,0.074,0.569,0,0.0005,0.225,70.9,0,16.7,2.5,458,7.68,5.39,2.15,34861
"Snacks, pretzels, hard, plain, made with enriched flour, unsalted",Snacks,9.1,0.036,0.289,2.8,0,0.146,79.2,2.21,3.5,3.3,381,0.75,1.36,1.22,34862
"Snacks, pretzels, hard, plain, made with unenriched flour, salted",Snacks,9.1,0.036,1.715,2.8,0,0.146,79.2,0,3.5,3.3,381,0.75,1.36,1.22,34863
"Snacks, pretzels, hard, plain, made with unenriched flour, unsalted",Snacks,9.1,0.036,0.289,2.8,0,0.146,79.2,0,3.5,3.3,381,0.75,1.36,1.22,34864
"Snacks, pretzels, hard, plain, salted",Snacks,10.34,0.018,1.357,3,0,0.136,79.76,2.76,2.63,3.44,380,0.501,1.222,1.107,34865
"Snacks, pretzels, hard, whole-wheat",Snacks,11.1,0.028,0.203,7.7,0.001,0.43,81.2,0,2.6,3.9,362,0.56,1.03,0.83,34866
"Snacks, rice cakes, brown rice, buckwheat",Snacks,9,0.011,0.116,3.8,0,0.299,80.1,0,3.5,5.9,380,0.64,1.11,1.11,34867
"Snacks, rice cakes, brown rice, buckwheat, unsalted",Snacks,9,0.011,0.004,0,0,0.299,80.1,0,3.5,5.9,380,0.64,1.11,1.11,34868
"Snacks, rice cakes, brown rice, corn",Snacks,8.4,0.009,0.167,2.9,0,0.275,81.2,0,3.2,5.9,385,0.63,1.14,1.13,34869
"Snacks, rice cakes, brown rice, multigrain",Snacks,8.5,0.021,0.252,3,0,0.294,80.1,0,3.5,6.3,387,0.56,1.16,1.44,34870
"Snacks, rice cakes, brown rice, multigrain, unsalted",Snacks,8.5,0.021,0.004,0,0,0.294,80.1,0,3.5,6.3,387,0.56,1.16,1.44,34871
"Snacks, rice cakes, brown rice, plain",Snacks,8.2,0.011,0.326,4.2,0,0.29,81.5,0.88,2.8,5.8,387,0.57,1.03,0.99,34872
"Snacks, rice cakes, brown rice, plain, unsalted",Snacks,8.2,0.011,0.026,4.2,0,0.29,81.5,0.88,2.8,5.8,387,0.57,1.03,0.99,34873
"Snacks, rice cakes, brown rice, rye",Snacks,8.1,0.021,0.11,4,0,0.311,79.9,0,3.8,6.8,386,0.58,1.31,1.55,34874
"Snacks, rice cakes, brown rice, sesame seed",Snacks,7.6,0.012,0.227,5.4,0.003,0.29,81.5,0,3.8,5.9,392,0.54,1.1,1.15,34875
"Snacks, rice cakes, brown rice, sesame seed, unsalted",Snacks,7.6,0.012,0.004,0,0.003,0.29,81.5,0,3.8,5.9,392,0.54,1.1,1.15,34876
"Snacks, sesame sticks, wheat-based, salted",Snacks,10.9,0.17,1.488,2.8,0,0.177,46.5,0.19,36.7,2,541,6.48,10.91,17.42,34877
"Snacks, sesame sticks, wheat-based, unsalted",Snacks,10.9,0.17,0.029,0,0,0.177,46.5,0,36.7,2,541,6.48,10.91,17.42,34878
"Snacks, soy chips or crisps, salted",Snacks,26.5,0.171,0.842,3.5,0,0.007,53.15,1.8,7.35,8.5,385,1.102,1.661,4.018,34879
"Snacks, sweet potato chips",Snacks,3.5,0.071,0.035,3.5,0,0.925,64.83,7,24.7,4,496,2.207,9.12,12.238,34880
"Snacks, taro chips",Snacks,2.3,0.06,0.342,7.2,0.005,0.755,68.1,3.82,24.9,2,498,6.43,4.43,12.88,34881
"Snacks, tortilla chips, light (baked with less oil)",Snacks,8.7,0.159,0.856,5.7,0.0002,0.272,73.4,0.53,15.2,1.3,465,2.837,6.341,5.024,34882
"Snacks, tortilla chips, low fat, made with olestra, nacho cheese",Snacks,8.44,0.128,0.602,6.4,0.0055,0.396,65.22,2.82,3.53,1.66,318,1.054,1.379,1.211,34883
"Snacks, tortilla chips, low fat, unsalted",Snacks,11,0.159,0.015,5.3,0.0002,0.272,80.1,0.85,5.7,1.7,416,0.85,1.66,2.87,34884
"Snacks, tortilla chips, nacho cheese",Snacks,8.15,0.148,0.615,4.6,0,0.238,61.74,3.79,26.07,1.5,514,3.936,7.067,13.624,34885
"Snacks, tortilla chips, nacho-flavor, made with enriched masa flour",Snacks,7.8,0.147,0.708,5.3,0.0018,0.216,62.4,0,25.6,1.7,498,4.9,15.09,3.54,34886
"Snacks, tortilla chips, nacho-flavor, reduced fat",Snacks,8.7,0.159,1.003,4.8,0.0002,0.272,71.6,0,15.2,1.3,445,2.91,8.95,2.1,34887
"Snacks, tortilla chips, plain, white corn",Snacks,7.79,0.174,0.421,5.3,0,0.215,65.56,1,23.36,1.85,489,2.81,6.789,11.248,34888
"Snacks, tortilla chips, plain, yellow corn",Snacks,7.18,0.094,0.281,4.7,0,0.22,67.31,0.97,21.57,2.3,492,2.462,10.3,7.09,34889
"Snacks, tortilla chips, ranch-flavor",Snacks,7.19,0.134,0.519,4,0,0.259,62.74,3.03,24.63,3.14,501,3.539,7.047,13.528,34890
"Snacks, tortilla chips, taco-flavor",Snacks,7.9,0.155,0.787,5.3,0.0009,0.217,63.1,0,24.2,1.6,480,4.64,14.29,3.36,34891
"Snacks, tortilla chips, unsalted, white corn",Snacks,7.79,0.174,0.015,5.3,0,0.215,65.32,1,23.36,2.09,503,2.458,9.52,9.956,34892
"Snacks, trail mix, regular",Snacks,13.8,0.078,0.229,0,0.0014,0.685,44.9,0,29.4,9.2,462,5.55,12.53,9.65,34893
"Snacks, trail mix, regular, unsalted",Snacks,13.8,0.078,0.01,0,0.0014,0.685,44.9,0,29.4,9.2,462,5.55,12.53,9.65,34894
"Snacks, trail mix, regular, with chocolate chips, salted nuts and seeds",Snacks,14.2,0.109,0.121,0,0.0013,0.648,44.9,0,31.9,6.6,484,6.1,13.54,11.29,34895
"Snacks, trail mix, regular, with chocolate chips, unsalted nuts and seeds",Snacks,14.2,0.109,0.027,0,0.0013,0.648,44.9,0,31.9,6.6,484,6.1,13.54,11.29,34896
"Snacks, trail mix, tropical",Snacks,6.3,0.057,0.01,0,0.0076,0.709,65.6,0,17.1,9,407,8.48,2.49,5.16,34897
"Snacks, vegetable chips, HAIN CELESTIAL GROUP, TERRA CHIPS",Snacks,4.13,0.079,0.246,10.9,0.0121,1.266,57.97,9.75,29.81,4.54,517,1.887,15.409,6.93,34898
"Snacks, vegetable chips, made from garden vegetables",Snacks,5.32,0.052,0.966,4.7,0.004900000000000001,0.838,60.43,4.04,23.3,6.31,473,1.762,14.566,6.602,34899
"Snacks, yucca (cassava) chips, salted",Snacks,1.34,0.052,0.296,3.7,0,0.868,69.23,3.36,25.91,2.8,515,8.689,7.443,6.912,34900
"Tortilla chips, low fat, baked without fat",Snacks,11,0.159,0.419,5.3,0.0002,0.272,80,0.67,5.7,1.7,415,0.85,1.66,2.87,34901
Acorn stew (Apache),Ethnic Foods,6.81,0.014,0.13,0.7,0,0.11,9.22,0.34,3.47,79.78,95,1.28,1.68,0.297,34902
"Agave, cooked (Southwest)",Ethnic Foods,0.99,0.46,0.013,10.6,0.0003,0.059,32,20.87,0.29,65.4,135,0,0,0,34903
"Agave, dried (Southwest)",Ethnic Foods,1.71,0.77,0.014,15.6,0.0003,0.767,81.98,50.7,0.69,11.64,341,0,0,0,34904
"Agave, raw (Southwest)",Ethnic Foods,0.52,0.417,0.014,6.6,0.004,0.127,16.23,2.58,0.15,81.83,68,0,0,0,34905
"Agutuk, fish with shortening (Alaskan ice cream) (Alaska Native)",Ethnic Foods,9,0,0.024,0,0,0.206,10.5,0,43.5,34,470,8.6,15.6,17.3,34906
"Agutuk, fish/berry with seal oil (Alaskan ice cream) (Alaska Native)",Ethnic Foods,3.4,0.008,0.021,0.5,0.0029,0.07,13.4,0,31.8,47.3,353,7.7,14.4,8.4,34907
"Agutuk, meat-caribou (Alaskan ice cream) (Alaska Native)",Ethnic Foods,21.7,0.016,0.095,0,0.0022,0.228,0.9,0,18.6,55.2,258,5.1,8.7,3.6,34908
Ascidians (tunughnak) (Alaska Native),Ethnic Foods,3.8,0.047,0.656,0,0,0.102,0,0,0.5,90.4,20,0.1,0.12,0.2,34909
"Bear, black, meat (Alaska Native)",Ethnic Foods,20.1,0,0,0,0,0,0,0,8.3,71.2,155,0,0,0,34910
"Bear, polar, meat, raw (Alaska Native)",Ethnic Foods,25.6,0.017,0,0,0.002,0.04,0,0,3.1,70.3,130,0.63,2,0.46,34911
"Blackberries, wild, raw (Alaska Native)",Ethnic Foods,0.84,0.005,0.006,3.2,0.0047,0.075,9.84,3.65,1.07,88.05,52,0,0,0,34912
"Blueberries, wild, frozen (Alaska Native)",Ethnic Foods,0.7,0.015,0,0,0.0022,0,10.4,0,0,88.7,44,0,0,0,34913
"Blueberries, wild, raw (Alaska Native)",Ethnic Foods,1.22,0.013,0.006,2.6,0.0183,0.075,12.31,6.46,0.76,85.48,61,0,0,0,34914
"Bread, blue corn, somiviki (Hopi)",Ethnic Foods,3.1,0.034,0.009,3.6,0,0.114,39.84,12.94,1.53,55.01,186,0.289,0.347,0.779,34915
"Bread, kneel down (Navajo)",Ethnic Foods,4.29,0.005,0.126,0,0,0.32,39.47,3.74,2.21,52.96,195,0.353,0.603,0.847,34916
"Buffalo, free range, top round steak, cooked (Shoshone Bannock)",Ethnic Foods,32.51,0.004,0.041,0,0,0.376,0,0,1.8,64.28,146,0.595,0.614,0.171,34917
"Buffalo, free range, top round steak, raw (Shoshone Bannock)",Ethnic Foods,21.44,0.003,0.043,0,0,0.355,0,0,1.3,75.96,97,0.418,0.425,0.098,34918
"Caribou, bone marrow, raw (Alaska Native)",Ethnic Foods,6.7,0,0,0,0,0,0,0,84.4,7.4,786,0,0,0,34919
"Caribou, eye, raw (Alaska Native)",Ethnic Foods,10.8,0.019,0,0,0,0,0,0,31.4,57.1,326,0,0,0,34920
"Caribou, hind quarter meat, raw (Alaska Native)",Ethnic Foods,22.63,0.005,0.052,0,0,0.32,0,0,3.37,72.6,122,1.27,0.94,0.56,34921
"Caribou, hind quarter, meat, cooked (Alaska Native)",Ethnic Foods,28.81,0.005,0.045,0,0,0.245,0,0,4.82,65.47,159,1.88,1.495,0.725,34922
"Caribou, liver, raw (Alaska Native)",Ethnic Foods,15,0.004,0,0,0,0,6.8,0,3.9,71.4,122,0,0,0,34923
"Caribou, rump meat, half dried (Alaska Native)",Ethnic Foods,52.06,0.014,0.39,0,0,0.71,0,0,5.23,38.04,255,1.55,1.19,0.63,34924
"Caribou, shoulder meat, dried (Alaska Native)",Ethnic Foods,59.38,0.014,0.95,0,0,0.81,0,0,3.67,33.81,271,1.586,1.201,0.37,34925
"Caribou, tongue, raw (Alaska Native)",Ethnic Foods,13.7,0.006,0,0,0,0,0,0,22.8,62.5,260,0,0,0,34926
"Cattail, Narrow Leaf Shoots (Northern Plains Indians)",Ethnic Foods,1.18,0.054,0.109,4.5,0.0007,0.309,5.14,0.22,0,92.65,25,0,0,0,34927
Chilchen (Red Berry Beverage) (Navajo),Ethnic Foods,0.81,0.007,0.015,0,0,0.028,8.68,2.6,0.63,89.69,44,0.075,0.135,0.151,34928
"Chiton, leathery, gumboots (Alaska Native)",Ethnic Foods,17.1,0.121,0,0,0,0,0,0,1.6,78.6,83,0,0,0,34929
"Chokecherries, raw, pitted (Northern Plains Indians)",Ethnic Foods,3.04,0.06,0.005,20,0.0055,0.379,33.62,9.37,1.69,60.72,162,0,0,0,34930
"Chokecherries, raw, pitted (Shoshone Bannock)",Ethnic Foods,2.89,0.04,0.002,17,0.0007,0.309,33.88,14.22,0.98,61.5,156,0,0,0,34931
"Cloudberries, raw (Alaska Native)",Ethnic Foods,2.4,0.018,0,0,0.158,0,8.6,0,0.8,87,51,0,0,0,34932
"Cockles, raw (Alaska Native)",Ethnic Foods,13.5,0.03,0,0,0,0,4.7,0,0.7,78.8,79,0,0,0,34933
"Corn, dried (Navajo)",Ethnic Foods,9.88,0.015,0.013,0,0,0.511,74.93,5.38,5.22,8.1,386,0.82,1.483,1.9,34934
"Corn, dried, yellow (Northern Plains Indians)",Ethnic Foods,14.48,0.025,0.004,20.5,0,0.775,66.27,21.32,10.64,6.36,419,1.974,3.75,4.543,34935
"Corn, white, steamed (Navajo)",Ethnic Foods,9.72,0.014,0.004,16.6,0,0.532,75.2,6.47,5.18,8.09,386,0.83,1.483,1.862,34936
Corned beef and potatoes in tortilla (Apache),Ethnic Foods,7.91,0.027,0.511,1.5,0,0.275,29.35,1.79,8.31,52.51,224,3.366,3.345,0.864,34937
"Cornmeal, blue (Navajo)",Ethnic Foods,10.4,0.005,0.007,8.7,0,0.393,76.93,1.81,5.44,5.7,398,0.886,1.679,2.465,34938
"Cornmeal, white (Navajo)",Ethnic Foods,10.99,0.011,0.004,10.4,0,0.443,77.14,1.46,5.04,5.42,398,0.853,1.532,2.078,34939
"Cornmeal, yellow (Navajo)",Ethnic Foods,9.85,0.006,0.004,9.4,0,0.322,72.9,1.56,5.88,10.15,384,1.043,2.143,2.352,34940
"Cranberries, wild, bush, raw (Alaska Native)",Ethnic Foods,1.1,0.02,0.026,6.7,0.015,0.14,12.3,0,0.2,86,55,0,0,0,34941
"Cranberry, low bush or lingenberry, raw (Alaska Native)",Ethnic Foods,0.4,0.026,0,0,0.021,0,12.2,0,0.5,86.7,55,0,0,0,34942
"Deer (venison), sitka, raw (Alaska Native)",Ethnic Foods,21.5,0.007,0,0,0,0,0,0,2.66,75,111,0.63,0.34,0.35,34943
"Duck, scoter, white-winged, meat (Alaska Native)",Ethnic Foods,20.2,0.008,0,0,0,0,0,0,0.4,79,84,0,0,0,34944
"Elk, free range, ground, cooked patties (Shoshone Bannock)",Ethnic Foods,29.45,0.005,0.056,0,0,0.379,0,0,2.8,67.78,143,1.138,0.952,0.239,34945
"Elk, free range, ground, raw (Shoshone Bannock)",Ethnic Foods,20.14,0.004,0.049,0,0,0.328,0,0,1.8,75.68,97,0.706,0.574,0.13,34946
"Elk, free range, roast, eye of round, cooked (Shoshone Bannock)",Ethnic Foods,30.56,0.004,0.05,0,0,0.369,0,0,2.85,64.58,148,1.125,1.353,0.137,34947
"Elk, free range, roast, eye of round, raw (Shoshone Bannock)",Ethnic Foods,19.74,0.004,0.043,0,0,0.367,0,0,2.1,76.54,98,0.828,0.897,0.122,34948
"Fireweed, young leaves, raw (Alaska Native)",Ethnic Foods,3,0.013,0.05,6,0.099,0.251,6.3,0,0.8,87.2,44,0,0,0,34949
"Fish, Salmon, Chum, raw (Alaska Native)",Ethnic Foods,20.69,0.007,0.059,0,0,0.37,0,0,3.67,72.34,116,0.547,0.94,0.77,34950
"Fish, blackfish, whole (Alaska Native)",Ethnic Foods,15.5,0.236,0,0,0,0,0,0,1.75,80.1,82,0,0,0,34951
"Fish, devilfish, meat (Alaska Native)",Ethnic Foods,11.7,0.012,0,0,0.003,0,0,0,5.3,80.8,97,0,0,0,34952
"Fish, halibut, cooked, with skin (Alaska Native)",Ethnic Foods,22.13,0.033,0.086,0,0,0.501,0,0,2.73,72.82,113,0.498,0.842,0.731,34953
"Fish, halibut, raw, with skin (Alaska Native)",Ethnic Foods,20.53,0.02,0.079,0,0,0.449,0,0,2.92,73.44,108,0.726,1.195,0.912,34954
"Fish, herring eggs on giant kelp, Pacific (Alaska Native)",Ethnic Foods,11.3,0.161,0.061,0,0,0,2.6,0,0.8,81.8,63,0,0,0,34955
"Fish, herring eggs, Pacific, dry (Alaska Native)",Ethnic Foods,60.4,0.029,0,0,0,0,2.8,0,6.6,27.3,312,0,0,0,34956
"Fish, herring eggs, Pacific, plain (Alaska Native)",Ethnic Foods,9.6,0.019,0.061,0,0.0006,0,4.47,0,1.93,81.2,74,0.43,0.42,0.71,34957
"Fish, herring, Pacific, flesh, air-dried, packed in oil (Alaska Native)",Ethnic Foods,44.5,0,0,0,0,0,0,0,34.6,18.2,489,0,0,0,34958
"Fish, lingcod, liver (Alaska Native)",Ethnic Foods,5.6,0.005,0,0,0,0,6,0,42,45.9,424,0,0,0,34959
"Fish, lingcod, meat, raw (Alaska Native)",Ethnic Foods,17.9,0,0.059,0,0,0.433,0,0,0.8,80,79,0,0,0,34960
"Fish, pike, northern, liver (Alaska Native)",Ethnic Foods,16.6,0.028,0,0,0,0,4.3,0,8,69.8,156,0,0,0,34961
"Fish, salmon, chum, dried (Alaska Native)",Ethnic Foods,62.09,0.155,0.19,0,0,0.91,0,0,14.38,22.79,378,2.395,5.139,2.323,34962
"Fish, salmon, coho (silver), raw (Alaska Native)",Ethnic Foods,22.56,0.005,0.058,0,0,0.38,0,0,5.57,69.38,140,0.904,1.79,2.41,34963
"Fish, salmon, king (chinook), raw (Alaska Native)",Ethnic Foods,20.25,0.042,0.048,0,0,0.37,0,0,11.73,65.96,187,1.87,4.09,1.599,34964
"Fish, salmon, king, chinook, kippered, canned (Alaska Native)",Ethnic Foods,30.7,0.038,0,0,0,0,0,0,15.9,51.2,266,0,0,0,34965
"Fish, salmon, king, chinook, liver (Alaska Native)",Ethnic Foods,16.6,0.028,0,0,0,0,4.3,0,8,69.8,156,0,0,0,34966
"Fish, salmon, king, chinook, smoked and canned (Alaska Native)",Ethnic Foods,23.2,0.06,0,0,0,0,0,0,5.9,66.7,150,0,0,0,34967
"Fish, salmon, king, chinook, smoked, brined (Alaska Native)",Ethnic Foods,39.9,0.023,0.693,0,0,0.7,0,0,30,23.6,430,6.97,16.9,0.35,34968
"Fish, salmon, king, with skin, kippered, (Alaska Native)",Ethnic Foods,23.19,0.055,0.87,0,0,0.39,0,0,12.95,61.52,209,2.44,5.86,2.09,34969
"Fish, salmon, red, (sockeye), canned, smoked (Alaska Native)",Ethnic Foods,35.19,0.069,0.6,0,0,0.47,0,0,7.26,56.49,206,1.48,2.29,2.21,34970
"Fish, salmon, red, (sockeye), kippered (Alaska Native)",Ethnic Foods,24.5,0.054,0.46,0,0,0.4,0,0,4.75,69.41,141,0.8,1.83,1.13,34971
"Fish, salmon, red, canned, bones removed (Alaska Native)",Ethnic Foods,27.31,0.028,0.39,0,0,0.37,0,0,5.76,65.82,161,0.92,2.29,1.61,34972
"Fish, salmon, sockeye (red), raw (Alaska Native)",Ethnic Foods,21.94,0.007,0.06,0,0,0.36,0,0,7.28,69.64,153,1.007,2.2,1.37,34973
"Fish, salmon, tipnuk, fermented (Alaska Native)",Ethnic Foods,15.9,0,0,0,0,0,0,0,10.6,68.8,159,0,0,0,34974
"Fish, sheefish, raw (Alaska Native)",Ethnic Foods,22.25,0.14,0.052,0,0,0.39,0,0,2.84,74.62,115,0.49,1.08,0.7,34975
"Fish, whitefish, broad, head, eyes, cheeks and soft bones (Alaska Native)",Ethnic Foods,18.6,0,0,0,0,0,0,0,3.6,76.3,107,0,0,0,34976
"Fish, whitefish, broad, liver (Alaska Native)",Ethnic Foods,11,0.053,0,0,0,0,5.1,0,4.4,76.1,104,0,0,0,34977
"Fish, whitefish, dried (Alaska Native)",Ethnic Foods,62.44,0.81,0.2,0,0,1.08,0,0,13.44,20.59,371,2.85,4.38,2.16,34978
"Fish, whitefish, eggs (Alaska Native)",Ethnic Foods,14.66,0.046,0.16,0,0.012,0.19,4.89,0,2.88,76.24,104,0.496,1.046,1.073,34979
"Fish, whitefish, mixed species, raw (Alaska Native)",Ethnic Foods,18.9,0,0.051,0,0,0.317,0,0,6.1,72.8,131,1.37,2.56,1.82,34980
"Frybread, made with lard (Apache)",Ethnic Foods,8.38,0.052,0.671,1.7,0,0.075,46.01,1.54,10.14,33.11,309,3.631,3.5,1.306,34981
"Frybread, made with lard (Navajo)",Ethnic Foods,6.69,0.057,0.329,0,0,0.077,48.26,2.03,12.22,31.57,330,4.621,4.411,1.055,34982
"Hazelnuts, beaked (Northern Plains Indians)",Ethnic Foods,14.89,0.441,0.002,9.8,0,0.738,22.98,0,52.99,5.92,628,0,0,0,34983
"Huckleberries, raw (Alaska Native)",Ethnic Foods,0.4,0.015,0.01,0,0.0028,0,8.7,0,0.1,90.7,37,0,0,0,34984
"Lambsquarters, raw (Northern Plains Indians)",Ethnic Foods,4.21,0.366,0.007,6.3,0,1.27,6.96,0,0.28,85.23,47,0,0,0,34985
"Lambsquarters, steamed (Northern Plains Indians)",Ethnic Foods,4.06,0.349,0.004,5.2,0.004900000000000001,1.07,7.47,0,0.16,85.02,48,0,0,0,34986
"Mashu roots, raw (Alaska Native)",Ethnic Foods,5.8,0,0,0,0.011,0,22.6,0,2.4,67.8,135,0,0,0,34987
"Melon, banana (Navajo)",Ethnic Foods,0.84,0.013,0.011,0.3,0.0081,0.14,4.06,3.36,0.2,94.66,21,0,0,0,34988
"Moose, liver, braised (Alaska Native)",Ethnic Foods,24.38,0.007,0.07,0,0.022600000000000002,0.235,3.43,0,4.89,66,155,0,0.65,1.07,34989
"Moose, meat, raw (Alaska Native)",Ethnic Foods,22.3,0.005,0.065,0,0.004,0.317,0,0,1.5,74.79,103,0.22,0.15,1.2,34990
"Mouse nuts, roots (Alaska Native)",Ethnic Foods,3.9,0.022,0,0,0.016,0,16.1,0,0.1,77.2,81,0,0,0,34991
"Mouse nuts, seedlings (Alaska Native)",Ethnic Foods,7.3,0.017,0,0,0.008,0,16.3,0,1.1,73,104,0,0,0,34992
"Mush, blue corn with ash (Navajo)",Ethnic Foods,0.66,0.096,0.008,1.1,0,0.058,11.74,0.19,0.49,86.67,54,0.074,0.109,0.208,34993
"Mutton, cooked, roasted (Navajo)",Ethnic Foods,33.43,0.01,0.135,0,0,0.409,0.08,0,11.09,54,234,5.142,4.459,0.814,34994
Octopus (Alaska Native),Ethnic Foods,12.3,0.035,0,0,0,0,0,0,0.8,84,56,0.2,0,0.2,34995
"Oil, bearded seal (Oogruk) (Alaska Native)",Ethnic Foods,0.56,0,0,0,0,0,0,0,99.6,0.02,899,10.926,47.08,33.001,34996
"Oil, beluga, whale (Alaska Native)",Ethnic Foods,0,0,0,0,0,0,0,0,100,0,900,14.49,54.19,10.8,34997
"Oil, spotted seal (Alaska Native)",Ethnic Foods,0,0,0,0,0,0,0,0,99.32,0.25,894,14.74,54.52,18.06,34998
"Oil, walrus (Alaska Native)",Ethnic Foods,0,0,0,0,0,0,0,0,100,0,900,0,0,0,34999
"Oil, whale, bowhead (Alaska Native)",Ethnic Foods,0,0,0,0,0,0,0,0,100,0,900,0,0,0,35000
"Oopah (tunicate), whole animal (Alaska Native)",Ethnic Foods,11.7,0.053,0,0,0,0,0,0,2.2,80.2,67,0,0,0,35001
"Owl, horned, flesh, raw (Alaska Native)",Ethnic Foods,22.7,0.016,0,0,0,0,0,0,5,71.3,136,0,0,0,35002
"Piki bread, made from blue cornmeal (Hopi)",Ethnic Foods,9.06,0.184,0.06,9.2,0,0.604,72.22,1.6,7.16,8.98,390,0.819,1.783,1.623,35003
"Pinon Nuts, roasted (Navajo)",Ethnic Foods,7.41,0.018,0.31,43.4,0,0.458,51.06,0,34.08,5,541,3.21,15.57,14.42,35004
"Plains Pricklypear, broiled (Northern Plains Indians)",Ethnic Foods,0.39,0,0,0,0.0062,0,21.57,0,0.31,75.83,91,0,0,0,35005
"Plains Pricklypear, raw (Northern Plains Indians)",Ethnic Foods,0.12,0.18,0.004,5.3,0.011,0.13,10.17,1.02,0.11,89.22,42,0,0,0,35006
"Plums, wild (Northern Plains Indians)",Ethnic Foods,0.43,0.011,0.004,6,0.0103,0.364,21.95,10.61,0.17,76.68,91,0,0,0,35007
"Prairie Turnips, boiled (Northern Plains Indians)",Ethnic Foods,1.64,0.103,0.004,7.2,0.002,0.108,29.99,4.74,0.32,67.68,129,0,0,0,35008
"Prairie Turnips, raw (Northern Plains Indians)",Ethnic Foods,2.62,0.13,0.005,8,0.0055,0.156,35.67,0.82,0.36,60.69,156,0,0,0,35009
"Raspberries, wild (Northern Plains Indians)",Ethnic Foods,1.12,0.036,0.004,7.5,0.0264,0.175,13.85,5.54,0.28,84.48,62,0,0,0,35010
"Rhubarb, wild, leaves (Alaska Native)",Ethnic Foods,4.2,0,0,0,0.033,0,9.9,0,0.5,83.5,61,0,0,0,35011
"Rose Hips, wild (Northern Plains Indians)",Ethnic Foods,1.6,0.169,0.004,24.1,0.426,0.429,38.22,2.58,0.34,58.66,162,0,0,0,35012
"Salmon, red (sockeye), filets with skin, smoked (Alaska Native)",Ethnic Foods,60.62,0.058,0.051,0,0,0.96,0,0,11.43,23.93,345,2.593,4.025,3.469,35013
"Salmonberries, raw (Alaska Native)",Ethnic Foods,0.85,0.013,0.014,1.9,0.0092,0.11,10.05,3.66,0.33,88.21,47,0,0,0,35014
"Sea cucumber, yane (Alaska Native)",Ethnic Foods,13,0.03,0,0,0,0,0,0,0.4,80.7,56,0,0,0,35015
"Sea lion, Steller, fat (Alaska Native)",Ethnic Foods,0.9,0,0,0,0,0,0,0,94,4.7,850,0,0,0,35016
"Sea lion, Steller, heart (Alaska Native)",Ethnic Foods,17,0.005,0.115,0,0,0.319,1,0,3.3,76.2,102,0,0,0,35017
"Sea lion, Steller, kidney (Alaska Native)",Ethnic Foods,18.1,0.009,0.231,0,0,0.253,1.4,0,1.7,77.4,93,0.3,0.3,0.3,35018
"Sea lion, Steller, liver (Alaska Native)",Ethnic Foods,22.9,0.006,0.088,0,0,0.291,0,0,5,71.27,137,1.3,0.9,0.2,35019
"Sea lion, Steller, meat (Alaska Native)",Ethnic Foods,25.85,0.006,0.062,0,0,0.406,0,0,1.88,71.2,120,0,0.1,0,35020
"Sea lion, Steller, meat with fat (Alaska Native)",Ethnic Foods,22.1,0.006,0.08,0,0,0.346,5.55,0,14.65,56.8,242,2.9,3.3,3,35021
"Seal, bearded (Oogruk), meat, dried (Alaska Native)",Ethnic Foods,82.6,0,0,0,0,0,0,0,2.3,11.6,351,0.6,1.33,0.37,35022
"Seal, bearded (Oogruk), meat, dried, in oil (Alaska Native)",Ethnic Foods,35.31,0.007,0.12,0,0,0.4,0,0,25.08,35.48,367,3.92,11.16,1.18,35023
"Seal, bearded (Oogruk), meat, low quadrant, raw (Alaska Native)",Ethnic Foods,23.63,0.004,0.057,0,0,0.21,0,0,10.17,63.35,186,0,0,0,35024
"Seal, bearded (Oogruk), meat, partially dried (Alaska Native)",Ethnic Foods,62.38,0.009,0.13,0,0,0.96,0,0,2.31,34.92,270,0,0,0,35025
"Seal, bearded (Oogruk), meat, raw (Alaska Native)",Ethnic Foods,26.7,0.01,0,0,0,0,0,0,0.4,69.6,110,0,0,0,35026
"Seal, ringed, liver (Alaska Native)",Ethnic Foods,18.6,0.013,0,0,0,0,2.9,0,3.3,74.2,116,1.17,1.1,1.03,35027
"Seal, ringed, meat (Alaska Native)",Ethnic Foods,28.4,0.005,0.11,0,0,0,0,0,3.2,67.4,142,0.82,1.72,0.06,35028
"Smelt, dried (Alaska Native)",Ethnic Foods,56.19,1.6,0.42,0,0,1,0,0,17.9,16.92,386,3.59,6.86,2.884,35029
"Soup, fish, homemade (Alaska Native)",Ethnic Foods,7.4,0.035,0.03,0,0.0003,0.128,5.6,0,2.2,84,72,0.5,0,0,35030
"Sourdock, young leaves (Alaska Native)",Ethnic Foods,2.3,0.002,0,0,0.068,0,6.5,0,0.7,89.7,42,0,0,0,35031
"Squash, Indian, cooked, boiled (Navajo)",Ethnic Foods,0.31,0,0,1.5,0,0,3.22,2.02,0.15,96.21,16,0,0,0,35032
"Squash, Indian, raw (Navajo)",Ethnic Foods,0.52,0.009,0.02,0,0.0045,0.205,5.64,3.54,0.2,93.35,26,0,0,0,35033
"Squirrel, ground, meat (Alaska Native)",Ethnic Foods,19.3,0,0,0,0,0,0,0,3.8,75.4,111,0,0,0,35034
"Steelhead trout, boiled, canned (Alaska Native)",Ethnic Foods,21.11,0.03,0.118,0,0,0.365,0,0,8.26,70.59,159,1.53,2.223,1.225,35035
"Steelhead trout, dried, flesh (Shoshone Bannock)",Ethnic Foods,77.27,0.085,2.85,0,0,1.72,0,0,8.06,6.49,382,0.829,1.228,1.739,35036
"Stew, dumpling with mutton (Navajo)",Ethnic Foods,8.71,0.014,0.046,0,0,0.082,8.03,0.21,3.78,79.21,101,1.709,1.533,0.274,35037
"Stew, hominy with mutton (Navajo)",Ethnic Foods,6.71,0.008,0.045,2,0,0.118,9.38,0.11,2.06,81.58,83,0.733,0.897,0.287,35038
"Stew, moose (Alaska Native)",Ethnic Foods,6.6,0.012,0.222,0.5,0.001,0.1,6,0,0.5,86.1,55,0.01,0.01,0.02,35039
"Stew, mutton, corn, squash (Navajo)",Ethnic Foods,8.62,0.038,0.049,1.7,0,0.199,7.27,0.61,4.34,76.13,103,1.735,1.748,0.383,35040
"Stew, pinto bean and hominy, badufsuki (Hopi)",Ethnic Foods,1.42,0.007,0.045,1.5,0,0.086,5.38,0,0.54,92.25,32,0.085,0.08,0.122,35041
"Stew, steamed corn (Navajo)",Ethnic Foods,8.82,0.022,0.104,2.3,0,0.177,10.8,0.51,3.75,76.03,112,1.603,1.497,0.392,35042
"Stew/soup, caribou (Alaska Native)",Ethnic Foods,3.8,0.007,0.072,0.1,0.0015,0.084,4.8,0,0.7,90,41,0.01,0,0.02,35043
"Stinging Nettles, blanched (Northern Plains Indians)",Ethnic Foods,2.71,0.481,0.004,6.9,0,0.334,7.49,0.25,0.11,87.67,42,0,0,0,35044
Tamales (Navajo),Ethnic Foods,6.28,0.029,0.427,3.1,0.0017,0.131,18.12,0.99,6.12,68.1,153,2.396,2.739,0.688,35045
"Tamales, masa and pork filling (Hopi)",Ethnic Foods,13.19,0.018,0.298,3.3,0,0.25,18.28,1.57,4.7,62.39,168,1.383,1.649,1.009,35046
"Tea, herbal, brewed, Hohoysi (Hopi)",Ethnic Foods,0.12,0.005,0.027,0,0,0.044,0,0,0.2,99.64,2,0,0,0,35047
"Tea, tundra, herb and laborador combination (Alaska Native)",Ethnic Foods,0,0.002,0.132,0,0.0003,0.009,0.2,0,0,99.7,1,0,0,0,35048
"Tennis Bread, plain (Apache)",Ethnic Foods,9,0.067,0.81,2.6,0,0.1,53.26,3.01,1.05,33.77,258,0.27,0.12,0.59,35049
"Tortilla, blue corn, Sakwavikaviki (Hopi)",Ethnic Foods,7.83,0.174,0.663,3.4,0,0.132,58.12,2.97,1.4,29.89,276,0.287,0.229,0.689,35050
"Tortilla, includes plain and from mutton sandwich (Navajo)",Ethnic Foods,7.28,0.07,0.482,2.4,0,0.105,49.94,2.75,0.95,39.96,237,0.296,0.185,0.391,35051
"Walrus, liver, raw (Alaska Native)",Ethnic Foods,21,0,0,0,0,0,3.5,0,3,71.5,125,0,0,0,35052
"Walrus, meat and subcutaneous fat raw (Alaska Native)",Ethnic Foods,16.3,0,0,0,0,0,0,0,24.1,58.9,282,0,0,0,35053
"Walrus, meat, dried (Alaska Native)",Ethnic Foods,57,0,0,0,0,0,0,0,2.6,38.8,251,0,0,0,35054
"Walrus, meat, raw (Alaska Native)",Ethnic Foods,19.2,0.018,0,0,0,0,0,0,13.6,65.1,199,2.57,8.42,2.6,35055
"Whale, beluga, eyes (Alaska Native)",Ethnic Foods,19.6,0,0,0,0,0,0,0,23.3,55.1,291,0,0,0,35056
"Whale, beluga, flipper, raw (Alaska Native)",Ethnic Foods,19,0.011,0,0,0,0,0,0,21.7,57.5,271,0,0,0,35057
"Whale, beluga, liver, raw (Alaska Native)",Ethnic Foods,18.4,0.011,0,0,0,0,2,0,3.9,74.6,117,0,0,0,35058
"Whale, beluga, meat, dried (Alaska Native)",Ethnic Foods,69.86,0.022,0.22,0,0,0.8,0,0,5.34,22.39,327,1.204,3.345,0.319,35059
"Whale, beluga, meat, raw (Alaska Native)",Ethnic Foods,26.5,0.007,0.078,0,0,0.283,0,0,0.5,72.5,111,0.092,0.337,0.025,35060
"Whale, bowhead, skin and subcutaneous fat (muktuk) (Alaska Native)",Ethnic Foods,12.6,0.005,0,0,0,0,0,0,46.1,40,465,6.56,28.12,7.97,35061
"Whale, bowhead, subcutaneous fat (blubber) (Alaska Native)",Ethnic Foods,0.4,0,0,0,0,0,0,0,96.5,2.9,870,0,0,0,35062
"Willow, leaves in oil (Alaska Native)",Ethnic Foods,2.6,0,0,0,0,0,8.1,0,61,28,592,0,0,0,35063
"Willow, young leaves, chopped (Alaska Native)",Ethnic Foods,6.1,0.13,0,0,0.19,0,20.7,0,1.6,68.7,122,0,0,0,35064
"Yellow pond lily, Wocas, dried seeds (Pacific Northwest Indians)",Ethnic Foods,7.9,0.027,0.025,19.2,0,0.553,80.02,0.43,1.07,9.36,361,0.147,0.196,0.316,35065
"Yellow pond lily, Wocas, tuber, cooked (Pacific Northwest Indians)",Ethnic Foods,0.69,0.023,0.01,2.3,0,0.187,7.06,0,0.35,91.41,34,0.041,0.016,0.049,35066
"APPLEBEE'S, 9 oz house sirloin steak",Restaurant Foods,25.63,0.017,0.549,0,0,0.361,0,0,8.19,64.11,176,3.244,3.743,0.575,35067
"APPLEBEE'S, Double Crunch Shrimp",Restaurant Foods,14.18,0.029,0.714,1.2,0,0.125,23.92,1.01,19,40.46,323,3.627,4.83,9.725,35068
"APPLEBEE'S, French fries",Restaurant Foods,3.39,0.021,0.521,3.3,0,0.542,37.41,0.25,13.37,43.28,284,2.484,3.844,6.085,35069
"APPLEBEE'S, KRAFT, Macaroni & Cheese, from kid's menu",Restaurant Foods,7.04,0.123,0.38,1.4,0,0.156,19.88,2.99,6.32,65.09,165,2.457,2.248,1.153,35070
"APPLEBEE'S, chicken fingers, from kids' menu",Restaurant Foods,19.06,0.015,0.555,1.4,0,0.314,15.64,0.52,14.03,48.89,265,2.532,3.484,6.691,35071
"APPLEBEE'S, mozzarella sticks",Restaurant Foods,15.01,0.328,0.793,1.9,0,0.101,23.93,2.77,18.24,39.49,320,6.542,4.402,4.695,35072
"CRACKER BARREL, chicken tenderloin platter, fried, from kid's menu",Restaurant Foods,19.21,0.011,0.684,0.7,0,0.328,17.87,0,15.07,45.26,284,2.736,3.562,7.39,35073
"CRACKER BARREL, country fried shrimp platter",Restaurant Foods,11.96,0.124,1.136,1.2,0,0.092,23.47,0,21.62,39.42,336,3.898,4.834,10.482,35074
"CRACKER BARREL, farm raised catfish platter",Restaurant Foods,22.94,0.043,0.414,1.6,0,0.434,5.31,0,17.05,52.32,266,3.249,4.577,7.612,35075
"CRACKER BARREL, grilled sirloin steak",Restaurant Foods,29.99,0.006,0.134,0,0,0.367,0.15,0,9.04,59.43,202,3.129,3.532,0.643,35076
"CRACKER BARREL, macaroni n' cheese plate, from kid's menu",Restaurant Foods,7.03,0.159,0.359,1.1,0,0.126,15.1,0,9.35,66.93,173,3.65,3.688,1.92,35077
"CRACKER BARREL, steak fries",Restaurant Foods,3.25,0.02,0.048,3,0,0.524,29.19,0,10.65,55.67,226,1.724,2.413,4.824,35078
"DENNY'S, French fries",Restaurant Foods,3.47,0.019,0.046,3.5,0,0.62,35.06,0,15.16,44.79,291,2.69,3.713,7.429,35079
"DENNY'S, MOONS & STARS chicken nuggets, from kid's menu",Restaurant Foods,18.02,0.024,0.656,1,0,0.268,10.57,0,22.41,46.58,316,4.915,7.165,8.304,35080
"DENNY'S, golden fried shrimp",Restaurant Foods,11.51,0.042,0.877,1,0,0.1,20.21,0,16.67,48.89,277,2.943,4.101,8.378,35081
"DENNY'S, macaroni & cheese, from kid's menu",Restaurant Foods,5.95,0.069,0.317,1.5,0,0.161,21.09,0,4.27,67.37,147,1.153,1.951,1.132,35082
"DENNY'S, mozzarella cheese sticks",Restaurant Foods,13.92,0.355,0.933,1.7,0,0.135,26.2,0,18.89,37.3,330,7.203,4.695,5.506,35083
"DENNY'S, top sirloin steak",Restaurant Foods,28.9,0.006,0.349,0,0,0.341,0.14,0,7.34,61.82,182,2.595,2.84,0.672,35084
"Restaurant, Chinese, beef and vegetables",Restaurant Foods,7.08,0.022,0.409,1.5,0.0116,0.204,7.29,2.41,5.3,78.82,105,0.978,1.217,2.13,35085
"Restaurant, Chinese, egg rolls, assorted",Restaurant Foods,8.28,0.04,0.468,2.6,0,0.165,27.29,0,11.94,50.6,250,2.116,3.036,5.601,35086
"Restaurant, Chinese, fried rice",Restaurant Foods,4.67,0.014,0.396,1.1,0,0.088,30.99,0.42,2.27,60.99,163,0.497,0.598,0.947,35087
"Restaurant, Chinese, general tso's chicken",Restaurant Foods,12.9,0.012,0.435,0.9,0.0016,0.201,23.99,11.6,16.36,45.15,295,2.76,3.879,7.501,35088
"Restaurant, Chinese, kung pao chicken",Restaurant Foods,9.76,0.02,0.402,1.5,0.0070999999999999995,0.218,6.87,3.03,6.98,74.78,129,1.352,2.173,3.02,35089
"Restaurant, Chinese, lemon chicken",Restaurant Foods,10.77,0.039,0.243,1.1,0.0025,0.155,19.15,8.67,11.76,57.08,226,1.912,2.567,5.768,35090
"Restaurant, Latino, Arroz con frijoles negros (rice and black beans)",Restaurant Foods,4.64,0.037,0.42,3.4,0,0.224,24.4,0.86,3.85,65.47,151,0.729,0.889,1.557,35091
"Restaurant, Latino, Arroz con grandules (rice and pigeonpeas)",Restaurant Foods,3.5,0.008,0.583,1.4,0,0.084,30.75,0,4.98,58.87,182,0.763,1.005,2.32,35092
"Restaurant, Latino, Arroz con habichuelas colorados (Rice And Red Beans)",Restaurant Foods,3.96,0.015,0.37,2.6,0,0.194,23.74,0.15,3.46,67.27,142,0.502,0.691,1.579,35093
"Restaurant, Latino, arepa (unleavened cornmeal bread)",Restaurant Foods,5.48,0.089,0.27,2.6,0,0.088,37.14,0.87,5.38,50.8,219,2.902,1.514,0.989,35094
"Restaurant, Latino, arroz con leche (rice pudding)",Restaurant Foods,3.2,0.09,0.106,0.5,0,0.142,24.92,15.26,3.69,67.39,146,1.899,1.018,0.443,35095
"Restaurant, Latino, black bean soup",Restaurant Foods,5.1,0.042,0.311,4.9,0,0.34,14.79,0.89,2.57,75.91,103,0.535,1.035,0.787,35096
"Restaurant, Latino, bunuelos (fried yeast bread)",Restaurant Foods,8.02,0.215,0.418,1.5,0,0.104,48.57,12.24,26.24,15.3,462,6.834,9.415,7.09,35097
"Restaurant, Latino, chicken and rice, entree, prepared",Restaurant Foods,12.02,0.018,0.518,1.2,0,0.184,20.03,0.55,5.06,61.12,174,1.101,1.799,1.564,35098
"Restaurant, Latino, empanadas, beef, prepared",Restaurant Foods,11.31,0.02,0.44,2,0,0.19,31.19,1.84,18.37,37.5,335,5.156,7.121,4.268,35099
"Restaurant, Latino, pupusas con frijoles (pupusas, bean)",Restaurant Foods,5.59,0.051,0.305,5.8,0,0.305,31.49,1.3,9.01,52.16,229,2.188,2.986,2.907,35100
"Restaurant, Latino, pupusas con queso (pupusas, cheese)",Restaurant Foods,11.72,0.325,0.4,2.9,0,0.12,22.39,1.2,13.25,50.36,256,6.479,3.411,1.555,35101
"Restaurant, Latino, pupusas del cerdo (pupusas, pork)",Restaurant Foods,11.51,0.049,0.426,2.6,0,0.255,23.02,1.45,10.43,53.12,232,3.166,3.964,2.205,35102
"Restaurant, Latino, tamale, corn",Restaurant Foods,3.48,0.024,0.277,3.2,0,0.186,26.68,7.42,7.21,61.32,186,2.629,2.437,1.548,35103
"Restaurant, Latino, tamale, pork",Restaurant Foods,7.35,0.075,0.473,2.4,0,0.152,15.75,0.46,9.04,66.03,174,2.692,3.244,2.052,35104
"Restaurant, Latino, tripe soup",Restaurant Foods,8.61,0.022,0.411,0,0,0.081,4.07,0,2.58,83.41,74,1.045,1.124,0.31,35105
"Restaurant, family style, French fries",Restaurant Foods,3.65,0.019,0.314,3.5,0,0.61,36.6,0.28,14.53,43.05,292,2.666,3.892,7.006,35106
"Restaurant, family style, chicken fingers, from kid's menu",Restaurant Foods,19.93,0.021,0.588,1,0,0.325,15.2,0.41,14.34,48.01,270,2.641,3.498,6.985,35107
"Restaurant, family style, fried mozzarella sticks",Restaurant Foods,14.89,0.348,0.794,1.8,0,0.115,24.89,2.22,18.92,37.95,329,6.983,4.709,5.176,35108
"Restaurant, family style, macaroni & cheese, from kids' menu",Restaurant Foods,6.47,0.116,0.368,1.2,0,0.144,18.12,2.87,6.1,67.72,153,2.21,2.225,1.382,35109
"Restaurant, family style, shrimp, breaded and fried",Restaurant Foods,12.85,0.063,0.853,1,0,0.096,20.01,0.74,18.1,46.31,294,3.325,4.344,9.033,35110
"Restaurant, family style, sirloin steak",Restaurant Foods,28.78,0.009,0.307,0,0,0.363,0.07,0,7.88,61.6,186,2.952,3.265,0.61,35111
"T.G.I. FRIDAY'S, FRIDAY'S Shrimp, breaded",Restaurant Foods,13.75,0.058,0.685,0.8,0,0.066,12.43,0.47,15.12,56.45,241,2.833,3.612,7.546,35112
"T.G.I. FRIDAY'S, French fries",Restaurant Foods,4.08,0.018,0.374,3.6,0,0.669,37.34,0.32,15.06,41.09,301,2.824,4.118,7.502,35113
"T.G.I. FRIDAY'S, Mac & Cheese, from kid's menu",Restaurant Foods,5.84,0.114,0.417,1,0,0.135,16.42,2.74,4.47,71.48,129,1.579,1.014,1.323,35114
"T.G.I. FRIDAY'S, chicken fingers, from kids' menu",Restaurant Foods,21.52,0.038,0.524,0.9,0,0.335,12.08,0.29,13.91,49.88,260,2.656,3.448,6.874,35115
"T.G.I. FRIDAY'S, classic sirloin steak",Restaurant Foods,30.61,0.007,0.194,0,0,0.382,0,0,6.95,61.04,185,2.839,2.945,0.551,35116
"T.G.I. FRIDAY'S, fried mozzarella",Restaurant Foods,15.73,0.362,0.656,1.9,0,0.11,24.55,1.66,19.63,37.05,338,7.204,5.031,5.328,35117`

export default csv