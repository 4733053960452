import React from 'react';

export default function Disclaimer() {
  return (
    <div style={{textAlign: 'center'}}>
      <p>If you find this tool useful, you might consider supporting its upkeep by making a one off or
      small recurring donation.</p>
      <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
      <input type="hidden" name="cmd" value="_s-xclick" />
      <input type="hidden" name="hosted_button_id" value="PSV322QFFBWSJ" />
      <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
      <img alt="" border="0" src="https://www.paypal.com/en_IE/i/scr/pixel.gif" width="1" height="1" />
      </form>
    </div>
  );
}
