import React from 'react';

export default function Disclaimer() {
  return (
    <div>
      <div className="Copyright-notice">{`Copyright © 2019, Cian Foley - Don't Eat for Winter®`}</div>
      {/* eslint-disable-next-line */}
      <a name="disclaimer" style={{ textAlign: 'center' }}>
        <h3>DISCLAIMER</h3>
      </a>
      <p>
        By using this website, you are agreeing to accept and acknowledge all
        parts of this disclaimer. If you do not agree to accept and acknowledge
        all parts, STOP now, close this site, and do not use it, or ANY of my
        other websites, blogs, e-mails, programs, information, services, or
        products.
      </p>
      <p>By using this site you agree to, and acknowledge the following:</p>
      <ul>
        <li>
          {' '}
          The author is neither a doctor nor a dietician. The information
          provided by the author, over all media channels, are simply opinions
          formed from personal experiences and research into nutrition; software
          engineering experience; and practical experiences as an amateur
          athlete. Any recommendations about training, nutrition,
          supplementation or lifestyle should be discussed with your doctor.
          Exercise and dieting involves risk. The information presented DOES NOT
          take the place of professional medical advice.
        </li>
        <li>
          {' '}
          This tool, and any information obtained from the author, via materials
          on this website, or from any other sources, including but not limited
          to social media, blogs, podcasts, publications etc. are for
          Informational Purposes Only.{' '}
        </li>
        <li>
          {' '}
          You acknowledge that your application of any of the information
          provided is voluntary, and that you alone are solely and personally
          responsible for your results. You acknowledge that you take full
          responsibility for your health, life and well-being, as well as the
          health, lives and well-being of your family and children (born and
          unborn, as applicable), and all decisions now or in the future.
        </li>
        <li>
          {' '}
          Before starting any new diet and exercise program, check with your
          doctor and clear any exercise and/or diet changes with them before
          beginning. The author is neither a doctor nor a registered dietitian
          and does not claim to help cure any condition or disease. The author
          does not provide medical aid or nutrition advice for the purpose of
          health or disease.
        </li>
        <li>
          {' '}
          Any recommendations provided are not intended to diagnose, treat,
          cure, or prevent any disease.{' '}
        </li>
        <li>
          {' '}
          The author expressly disclaims responsibility to any person or entity
          for any liability, loss, or damage caused or alleged to be caused
          directly or indirectly as a result of the use, application or
          interpretation of any and all information published.
        </li>
        <li>
          {' '}
          You accept that the author cannot and does not guarantee that you will
          attain a specific or particular results, and you accept that results
          differ for each individual. Health, fitness, and nutrition success
          depends on each individual’s background, dedication, desire, and
          motivation. As with any exercise/nutrition related service, your
          results will vary, and will be based on many variables, including but
          not limited to, your individual capacity, life experience, unique
          health and genetic profile, starting point, expertise, and level of
          commitment.
        </li>
        <li>
          {' '}
          Reference or links in this website, blog, e-mails, programs, services
          or products to any other business or entity’s information, opinions,
          advice, programs, services, or products do not constitute endorsement
          or recommendation. The author is not responsible for the contents of
          any off-site web pages, companies or persons linked or referenced.
        </li>
        <li>
          {' '}
          The information on this site is protected under copyright law. You
          agree to not to use this tool, or any of the information provided by
          the author, over any channel, for any reason, without prior consent.{' '}
        </li>
        <li>
          {' '}
          Don’t Eat for Winter is a registered trademark and any unauthorized
          use of the name, concepts, content or information presented, without
          permission directly from the author, is strictly prohibited.{' '}
        </li>
        <li>
          {' '}
          The software is intended as a companion tool for the author's book
          'Don't Eat For Winter', and is recommended reading before using this
          tool, in order to fully grasp the concept.
        </li>
        <li>
          {' '}
          The software is currently provided free, based on the goodwill of the
          author, and may be disabled or modified at any time, for any reason,
          without prior notice.
        </li>
        <li>
          {' '}
          This website, www.defowdiet.com, does not collect any personal data.
          In future, it may use cookies to analyse traffic etc. A Privacy Policy
          and Cookie Policy will be added in due course should it collect such
          data.{' '}
        </li>
      </ul>
      <p>
        THE SOFTWARE IS PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND, EXPRESS
        OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
        IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY
        CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT,
        TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE
        SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
      </p>
    </div>
  );
}
